import { styled, Typography, Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../api';
import CommonButton from '../../../../components/CommonButton';
import CommonModal from '../../../../components/CommonModal';
import { fetchClientInfo } from '../../../../redux/reducers/authSlice';

const CurationModeContent = styled(Typography)({
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 800
});
const ButtonContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0px'
});

const CurationModal = ({ open, handleClose, isCurateOn, categoryId, orientation, fetchLibrarysByCategory }) => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { client } = useSelector((state) => state.auth.user);

    const handleSubmit = async () => {
        setLoading(true);
        try {
            const { data } = await api.library.updateCurate({ category_id: categoryId, enable: Number(!isCurateOn) });
            if (data.success) {
                dispatch(fetchClientInfo(client.id)).then(() => {
                    let params = {
                        orientation: orientation,
                        category_id: categoryId,
                        status: 1
                    };
                    fetchLibrarysByCategory({ params });
                    handleClose();
                });
                setLoading(false);
                enqueueSnackbar(data.message, { variant: 'success' });
                handleClose();
            } else {
                setLoading(false);
                enqueueSnackbar(data.message, { variant: 'error' });
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <CommonModal open={open} onClose={handleClose} width={500} title={`${isCurateOn ? 'Disable' : 'Enable'} Curation Mode`}>
            {isCurateOn ? (
                <CurationModeContent>
                    Are you sure you want to disable curation for this category and make content selections yourself?
                </CurationModeContent>
            ) : (
                <>
                    <CurationModeContent>
                        Content will be selected by DSID on your behalf and frequently updated. You can disable at any time.
                    </CurationModeContent>
                    <CurationModeContent sx={{ mt: 1 }}>Would you like to proceed?</CurationModeContent>
                </>
            )}
            <ButtonContainer>
                <CommonButton label={'Yes'} variant="contained" sx={{ mr: 2 }} loading={loading} onClick={handleSubmit} />
                <CommonButton label={'No'} onClick={handleClose} variant="contained" />
            </ButtonContainer>
        </CommonModal>
    );
};

export default CurationModal;
