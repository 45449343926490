import { Box, Grid, Popover, styled, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ASPECT_RATIO, ORIENTATION, SERVER_URL } from '../../../../../utils/constants';
import { COLORS } from '../../../../../utils/theme';
import useFetch from '../../../../../hooks/useFetch';

const MainBox = styled(Box)({
    width: 740
});
const ImageGrid = styled(Grid)({
    padding: 0
});

const Title = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '1.25rem',
    marginBottom: '16px',
    textAlign: 'center'
});

const ImageMain = styled('img')({
    width: '100%',
    borderRadius: 4,
    objectFit: 'contain',
    cursor: 'pointer'
});
const StyledTab = styled(Tab)({
    textAlign: 'left',
    minHeight: 40,
    fontSize: 14,
    textTransform: 'capitalize'
});

export const BackgroundImagesPopup = ({ id, open, anchorEl, handleClose, handleSelectImage }) => {
    const theme = useTheme();
    const [activeCategory, setActiveCategory] = useState();
    const { orientation } = useSelector((state) => state.orientation);
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const { data, callFetch } = useFetch({
        initialUrl: `/get_theme_by_id`,
        skipOnStart: true
    });
    const { data: backgrounds } = useFetch({
        initialUrl: '/get_theme',
        config: { params: { orientation: orientation } }
    });
    const backgroundImages = data?.result.backgrounds;

    useEffect(() => {
        if (!activeCategory) {
            setActiveCategory(backgrounds?.result[0]);
        }
    }, [activeCategory, backgrounds]);

    useEffect(() => {
        if (activeCategory) {
            callFetch({ params: { id: activeCategory.id } });
        }
    }, [activeCategory, callFetch]);

    const handleChange = (event, newValue) => {
        let index = backgrounds?.result?.findIndex((item) => item.name === newValue);
        setActiveCategory(backgrounds?.result?.[index]);
    };

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <MainBox sx={{ padding: 2 }} >
                <Title>Image Background</Title>
                <Grid container>
                    <Grid md={3} item>
                        <Tabs
                            centered={false}
                            orientation={'vertical'}
                            value={activeCategory?.name}
                            onChange={handleChange}
                            textColor={'primary'}
                            indicatorColor={COLORS.primary.light}
                            sx={{ mr: 2 }}
                        >
                            {backgrounds?.result?.map?.((item, index) => {
                                return (
                                    <StyledTab
                                        key={item.id}
                                        value={item.name}
                                        label={item.name}
                                        sx={{
                                            backgroundColor: item.name !== activeCategory?.name ? COLORS.white : COLORS.primary.light,
                                            color: item.name !== activeCategory?.name ? COLORS.primary.light : `${COLORS.white}!important`,
                                            border: `1px solid ${COLORS.primary.light}`,
                                            borderRadius: 1,
                                            mb: backgrounds?.result?.length - 1 === index ? 0 : 1
                                        }}
                                    />
                                );
                            })}
                        </Tabs>
                    </Grid>
                    <Grid item md={9}>
                        <ImageGrid container spacing={1}>
                            {backgroundImages?.map?.((item) => {
                                return (
                                    <Grid key={item.id} item md={2}>
                                        <ImageMain
                                            onClick={() => handleSelectImage(item)}
                                            src={`${SERVER_URL}${item.image}`}
                                            sx={{
                                                height: orientation === ORIENTATION.LANDSCAPE ? !matchUpMd ? 60 : null : 100,
                                                aspectRatio: ASPECT_RATIO[orientation]
                                            }}
                                        />
                                    </Grid>
                                );
                            })}
                        </ImageGrid>
                    </Grid>
                </Grid>
            </MainBox>
        </Popover>
    );
};
