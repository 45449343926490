import React from 'react';
import './App.css';
import Routing from './routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import { store, persistor } from './redux';
import { theme } from './utils/theme';
import SnackbarRapper from './widgets/SnackbarRapper';
import 'flatpickr/dist/themes/material_blue.css';
import 'video.js/dist/video-js.css';

function App() {
    return (
        <SnackbarRapper>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <Routing />
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </SnackbarRapper>
    );
}

export default App;
