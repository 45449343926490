import { Box, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets';
import { CommonTable } from '../../../components/Table';
import { fetchBackground } from '../../../redux/reducers/backgroundSlice';
import { ORIENTATION } from '../../../utils/constants';
import { COLORS } from '../../../utils/theme';
import { PAGES } from '../../../utils/urls';
import { CommonHeader } from '../../../widgets/CommonHeader';
const GroupName = styled(Typography)({
    fontSize: 16,
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});

export const Backgrounds = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const dispatch = useDispatch();
    const { backgrounds } = useSelector((state) => state.background);

    const navigate = useNavigate();
    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchBackground({ params: { orientation: activeOrientation } })).then(() => {
            setIsLoading(false);
        });
    }, [dispatch, activeOrientation]);
    const themeColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Theme',
            render: ({ rowData }) => {
                return <GroupName>{rowData.name}</GroupName>;
            }
        },
        {
            id: 'backgroundCount',
            fieldName: 'backgroundCount',
            label: 'Images',
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'status',
            fieldName: 'status',
            label: 'Status',
            render: ({ rowData }) => {
                return (
                    <>
                        {Boolean(rowData.status) ? (
                            <ICONS.CheckCircleIcon variant="contained" style={{ color: 'green' }} />
                        ) : (
                            <ICONS.CancelIcon style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
            style: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];

    const onChangeOrientation = (value) => {
        setActiveOrientation(value);
    };
    return (
        <Box>
            <CommonHeader
                onClick={() => navigate(PAGES.ADD_BACKGROUND.url, { state: { orientation: activeOrientation } })}
                onChangeOrientation={onChangeOrientation}
                orientationFilter={true}
                buttonLabel={'Add Theme'}
                title={'Backgrounds'}
            />

            <Box sx={{ marginTop: '24px' }}>
                <CommonTable
                    handleRowDetail={(data) =>
                        navigate(`${PAGES.EDIT_BACKGROUND.url}/${data.id}`, { state: { orientation: activeOrientation } })
                    }
                    columns={themeColumns}
                    rows={backgrounds}
                    loading={isLoading}
                />
            </Box>
        </Box>
    );
};
