import { Box, styled } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../api';
import { ASPECT_RATIO, BASE_URL, CONTENT_UPLOAD, ORIENTATION, SERVER_URL } from '../../../utils/constants';
import { crop, passMultipleFilesIntoFormData } from '../../../utils/helpers';
import { DropZoneBox } from '../../../widgets/DropZoneBox';
import { BackgroundZoneView } from './components/BackgroundZoneView';
import { PlaceholderView } from './components/PlaceholderView';
import UploadHeader from './components/UploadHeader';
import useFetch from '../../../hooks/useFetch';

const MainBox = styled(Box)({});

export const Upload = () => {
    const [activeTab, setActiveTab] = useState('VIDEO');
    const [loading, setLoading] = useState(false);
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const [acceptedFiles, setAcceptedFiles] = useState('');
    const { enqueueSnackbar } = useSnackbar();
    const { client: reduxClient, token } = useSelector((state) => state.auth.user);
    const dispatch = useDispatch();
    // const [realtimeData, setRealtimeData] = useState(0);

    const { data: clientData, callFetch: fetchClientById } = useFetch({
        initialUrl: `/get_client_by_id`,
        skipOnStart: true
    });

    const client = clientData?.result;


    const Content =
        activeTab === 'VIDEO'
            ? CONTENT_UPLOAD.VIDEO
            : activeTab === 'BACKGROUND'
                ? CONTENT_UPLOAD.BACKGROUND
                : activeTab === 'PLACEHOLDER'
                    ? CONTENT_UPLOAD.PLACEHOLDER
                    : null;

    useEffect(() => {
        // dispatch(fetchClientInfo());
    }, [dispatch]);

    useEffect(() => {
        setFieldValue('isSuccess', true);
    }, [client]);

    useEffect(() => {
        if (reduxClient) {
            fetchClientById({ params: { client_id: reduxClient?.id } });
        }
    }, [reduxClient]);

    const { handleSubmit, values, setFieldValue, resetForm } = useFormik({
        initialValues: {
            videos: '',
            background: '',
            background_file: null,
            landscape: '',
            landscape_file: null,
            portrait: '',
            portrait_file: null,
            isSuccess: false,
            unsavedChanges: false
        },
        onSubmit: async (values) => {
            // setRealtimeData(0)
            let result;
            try {
                setLoading(true);
                const formData = new FormData();
                if (activeTab === 'VIDEO') {
                    if (acceptedFiles?.length > 0) {
                        passMultipleFilesIntoFormData(formData, acceptedFiles, 'upload');
                    }

                    const response = await fetch(`${BASE_URL}/upload_to_dropbox`, {
                        method: 'POST',
                        textStreaming: true,
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Client_id': reduxClient?.id
                        },
                        body: formData
                    })

                    const reader = response.body.getReader();

                    while (true) {
                        const { done, value } = await reader.read();
                        const chunkData = new TextDecoder().decode(value)
                        if (done) {
                            // setRealtimeData(100)
                            setLoading(false);
                            fetchClientById({ params: { client_id: reduxClient?.id } });
                            return
                        }
                        if (chunkData === 'success') {
                            // setRealtimeData(100)
                        } else {
                            // setRealtimeData(new TextDecoder().decode(value))
                        }
                    }
                } else if (activeTab === 'BACKGROUND') {
                    formData.append('id', client.id);
                    formData.append('bg_image', values.background_file);
                    const { data } = await api.client.updateBackground(formData);
                    result = data;
                } else {
                    let params = {
                        id: client.id
                    };
                    if (values.landscape) params.landscape = values.landscape;
                    if (values.portrait) params.portrait = values.portrait;
                    const { data } = await api.client.update(params);
                    setAcceptedFiles([]);
                    result = data;
                }
                if (result.success) {
                    setLoading(false);
                    fetchClientById({ params: { client_id: reduxClient?.id } });
                    // dispatch(fetchClientInfo());
                    setFieldValue('isSuccess', true);
                    enqueueSnackbar(result.message, { variant: 'success' });
                } else {
                    setLoading(false);
                    setFieldValue('isSuccess', false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                console.log('error', error)
                setLoading(false);
                setFieldValue('isSuccess', false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        setAcceptedFiles([]);
    }, [activeTab]);

    const handlePlaceholderImages = async () => {
        if (activeOrientation === ORIENTATION.LANDSCAPE) {
            let image = await crop(URL.createObjectURL(acceptedFiles[0]), ASPECT_RATIO[ORIENTATION.LANDSCAPE]);
            if (image) {
                setFieldValue('landscape_file', image);
                setFieldValue('landscape', image?.split(',')[1]);
                setFieldValue('unsavedChanges', true);
            }
        } else {
            let image = await crop(URL.createObjectURL(acceptedFiles[1] || acceptedFiles[0]), ASPECT_RATIO[ORIENTATION.PORTRAIT]);
            if (image) {
                setFieldValue('portrait_file', image);
                setFieldValue('portrait', image?.split(',')[1]);
                setFieldValue('unsavedChanges', true);
            }
        }
    };
    useEffect(() => {
        if (acceptedFiles.length) {
            if (activeTab === 'PLACEHOLDER') {
                handlePlaceholderImages();
            } else if (activeTab === 'BACKGROUND') {
                setFieldValue('background_file', acceptedFiles[0]);
            }
        }
    }, [acceptedFiles, activeTab, setFieldValue]);

    const handleReset = () => {
        setAcceptedFiles([]);
        resetForm();
    };

    const reload = () => {
        fetchClientById({ params: { client_id: reduxClient?.id } });
    };

    return (
        <Box>
            <UploadHeader
                activeTab={(value) => setActiveTab(value)}
                // currentOrientation={(value) => setActiveOrientation(value)}
                unsavedChanges={values.unsavedChanges}
            />
            <MainBox sx={{ marginTop: '24px' }}>
                {activeTab === 'VIDEO' && (
                    <DropZoneBox
                        loading={loading}
                        title={Content.title}
                        description={Content.description}
                        reset={values.isSuccess ? handleReset : null}
                        accept={Content?.accept}
                        validations={Content?.validations}
                        files={(val) => setAcceptedFiles(val)}
                        requirementText={Content?.requirementText}
                        maxFileSize={Content?.maxFileSize}
                        maxFiles={Content?.maxFiles}
                        uploadButton={true}
                        onUpload={handleSubmit}
                    // {...{ isShowProgressBar: true, progressBar: Number(realtimeData) }}

                    />
                )}
                {activeTab === 'BACKGROUND' && (
                    <DropZoneBox
                        loading={loading}
                        title={Content.title}
                        description={Content.description}
                        reset={values.isSuccess ? handleReset : null}
                        accept={Content?.accept}
                        validations={Content?.validations}
                        files={(val) => setAcceptedFiles(val)}
                        requirementText={Content?.requirementText}
                        maxFileSize={Content?.maxFileSize}
                        maxFiles={Content?.maxFiles}
                        uploadButton={true}
                        onUpload={handleSubmit}
                    />
                )}
                {activeTab === 'PLACEHOLDER' && (
                    <DropZoneBox
                        loading={loading}
                        title={Content.title}
                        description={Content.description}
                        reset={values.isSuccess ? handleReset : null}
                        accept={Content?.accept}
                        validations={Content?.landscapeValidations}
                        files={(val) => setAcceptedFiles(val)}
                        requirementText={Content?.requirementText}
                        maxFileSize={Content?.maxFileSize}
                        maxFiles={Content?.maxFiles}
                        uploadButton={true}
                        onUpload={handleSubmit}
                        landscapeAndPortariatInOne={true}
                        currentOrientation={(value) => setActiveOrientation(value)}
                    />
                )}
                <Box sx={{ my: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {activeTab === 'PLACEHOLDER' ? (
                        <PlaceholderView
                            landscapeImage={SERVER_URL + client?.cc_defaultwp}
                            portraitImage={SERVER_URL + client?.cc_defaultwp_portrait}
                            client={reduxClient}
                            reload={reload}
                        />
                    ) : activeTab === 'BACKGROUND' ? (
                        <BackgroundZoneView backgroundImage={client && client.bg_image} />
                    ) : null}
                </Box>
            </MainBox>
        </Box>
    );
};
