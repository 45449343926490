import React, { useEffect, useState } from 'react';
import { styled, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { api } from '../../../../api';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import { ACCOUNT_TYPES, DELETE_CONFIRMATION, UPDATE_CONFIRMATION } from '../../../../utils/constants';
import { accountTypesFormValidation } from '../../../../utils/validations';
import { UnsavedChanges, DeleteButton } from '../../../../widgets/CommonWidgets';
import FieldLabelView from '../../../../components/FieldLabelView';
import CommonModal from '../../../../components/CommonModal';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../../redux/reducers/accountTypesSlice';

const StyledInput = styled(CommonInput)({
    flex: 1
});
const AddButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10
});

const EditButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10
});

const RightActionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});
const AddEditAccountTypesModal = ({ type, open, handleClose, accountTypesData, accountTypes }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState({ submit: false, delete: false });

    const { handleBlur, handleChange, values, touched, errors, handleSubmit, setFieldValue, setValues, resetForm, validateForm } =
        useFormik({
            initialValues: {
                name: '',
                unSavedChange: false
            },
            validationSchema: accountTypesFormValidation,
            onSubmit: async (values) => {
                const payload = {
                    name: values.name
                };
                await handleAddAccountTypes(payload);
            }
        });

    const checkUnsaveChanges = () => {
        !values.unSavedChange && setFieldValue('unSavedChange', true);
    };
    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };
    const closeModal = () => {
        if (accountTypes?.id && accountTypes?.id === ACCOUNT_TYPES[0]?.id) {
            dispatch(fetchAccountTypeTerritory());
        } else if (accountTypes?.id && accountTypes?.id === ACCOUNT_TYPES[1]?.id) {
            dispatch(fetchAccountTypeSpecialty());
        }
        resetForm();
        handleClose();
    };

    useEffect(() => {
        if (accountTypesData && Object.keys(accountTypesData).length !== 0) {
            setValues({ ...accountTypesData });
        }
    }, [dispatch, accountTypesData, setValues]);

    const handleAddAccountTypes = async (payload) => {
        try {
            setLoading({ ...loading, submit: true });
            let result;
            if (accountTypesData) {
                const { data } = await api.version.update(payload);
                result = data;
            } else {
                if (accountTypes?.id === ACCOUNT_TYPES?.[0]?.id) {
                    const { data } = await api.accountTypes.addNewAccountTypeTerritory(payload);
                    result = data;
                } else if (accountTypes?.id === ACCOUNT_TYPES?.[1]?.id) {
                    const { data } = await api.accountTypes.addNewAccountTypeSpecialty(payload);
                    result = data;
                }
            }

            if (result?.success) {
                enqueueSnackbar(result?.message, { variant: 'success' });
            } else {
                enqueueSnackbar(result?.message, { variant: 'error' });
            }
            setLoading({ ...loading, submit: false });
            closeModal();
        } catch (error) {
            setLoading({ ...loading, submit: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const handleDeleteAccountTypes = async () => {
        try {
            setLoading({ ...loading, delete: true });
            let result;
            if (accountTypes?.id === ACCOUNT_TYPES?.[0]?.id) {
                const { data } = await api.accountTypes.deleteAccountTypeTerritory(accountTypesData?.id);
                result = data;
            } else if (accountTypes?.id === ACCOUNT_TYPES?.[1]?.id) {
                const { data } = await api.accountTypes.deleteAccountTypeSpecialty(accountTypesData?.id);
                result = data;
            }
            if (result?.success) {
                enqueueSnackbar(result?.message, { variant: 'success' });
            } else {
                enqueueSnackbar(result?.message, { variant: 'error' });
            }
            setLoading({ ...loading, delete: false });
            closeModal();
        } catch (error) {
            setLoading({ ...loading, delete: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <CommonModal
            open={open}
            title={type === 'add' ? `Add New ${accountTypes?.name}` : `Edit ${accountTypes?.name}`}
            width={500}
            onClose={closeModal}
        >
            <form onSubmit={handleSubmit}>
                <FieldLabelView sx={{ mt: 2, paddingBottom: 3 }} label={`${accountTypes?.name} Name`}>
                    <StyledInput
                        handleChange={checkHandlechange}
                        handleBlur={handleBlur}
                        error={touched.name && errors.name}
                        name={'name'}
                        value={values?.name}
                        type={'name'}
                    />
                </FieldLabelView>

                {type === 'add' ? (
                    <AddButtonContainer>
                        <CommonButton
                            key="create-Button"
                            id="create-Button"
                            onClick={handleSubmit}
                            loading={loading.submit}
                            variant="contained"
                            label={'Create'}
                            title={'User'}
                            type={'submit'}
                        />
                    </AddButtonContainer>
                ) : (
                    <EditButtonContainer>
                        {values.unSavedChange && <UnsavedChanges />}
                        <DeleteButton
                            sx={{ ml: 1 }}
                            onClick={handleDeleteAccountTypes}
                            loading={loading.delete}
                            confirmation={DELETE_CONFIRMATION}
                            label={`Delete ${accountTypes?.name}`}
                            section={accountTypes?.name}
                            title={accountTypes?.name}
                        />
                        <RightActionContainer>
                            <CommonButton
                                sx={{ ml: 2 }}
                                key="Update-Button"
                                id="Update-Button"
                                confirmation={UPDATE_CONFIRMATION}
                                validateForm={validateForm}
                                onClick={handleSubmit}
                                loading={loading.submit}
                                variant="contained"
                                label={'Update'}
                                title={accountTypes?.name}
                            />
                        </RightActionContainer>
                    </EditButtonContainer>
                )}
            </form>
        </CommonModal>
    );
};

export default AddEditAccountTypesModal;
