import { Box, styled } from '@mui/material';
import React from 'react';
import { ASPECT_RATIO, ORIENTATION } from '../../../../utils/constants';
import { getAspectRatioHeightFromWidth, getAspectRatioWidthFromHeight, getPlaceholderRenderHeight } from '../../../../utils/helpers';
import { COLORS } from '../../../../utils/theme';
import ImagePlaceholder from '../../../../widgets/ImagePlaceholder';

const MainBox = styled(Box)(({ theme }) => ({
    border: `1px solid ${COLORS.black}`,
    display: 'flex',
    width: 'auto',
    height: 'auto',
    maxHeight: 470,
    maxWidth: getAspectRatioWidthFromHeight(470, ASPECT_RATIO[ORIENTATION.LANDSCAPE]),
    [theme.breakpoints.down('md')]: {
        height: getAspectRatioHeightFromWidth(window.innerWidth - 20, ASPECT_RATIO[ORIENTATION.LANDSCAPE]),
        width: window.innerWidth - 20
    }
}));
const ContentBox = styled(Box)({
    width: '100%'
});
const ImageBox = styled(Box)({
    height: '100%',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
});
const ScrollingBar = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 50,
    marginBottom: 8,
    backgroundColor: COLORS.white,
    borderBottom: `2px solid ${COLORS.primary.light}`,
    [theme.breakpoints.down('sm')]: {
        height: 35
    }
}));

export function BackgroundZoneView({ backgroundImage }) {
    return (
        <MainBox>
            <ImageBox
                sx={{
                    backgroundImage: `url("${encodeURI(backgroundImage)}")`
                }}
            >
                <Box
                    sx={{
                        my: 1,
                        mx: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <ContentBox id={'placeholder-main'}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mx: 3 }}>
                            <ImagePlaceholder
                                isAddIcon={false}
                                style={{
                                    backgroundImage: `linear-gradient(${COLORS.black},${COLORS.primary.dark}, ${COLORS.primary.light})`
                                }}
                                boxDimensions={{
                                    width: getAspectRatioWidthFromHeight(
                                        getPlaceholderRenderHeight('placeholder-main', 12),
                                        ASPECT_RATIO[ORIENTATION.LANDSCAPE]
                                    ),
                                    height: getPlaceholderRenderHeight('placeholder-main', 12),
                                    objectFit: 'contain'
                                }}
                            />
                            <div style={{ marginLeft: 12 }}>
                                <ImagePlaceholder
                                    isAddIcon={false}
                                    style={{
                                        backgroundImage: `linear-gradient(${COLORS.black},${COLORS.primary.dark}, ${COLORS.primary.light})`
                                    }}
                                    boxDimensions={{
                                        width: getAspectRatioWidthFromHeight(
                                            getPlaceholderRenderHeight('placeholder-main', 12),
                                            ASPECT_RATIO[ORIENTATION.PORTRAIT]
                                        ),
                                        height: getPlaceholderRenderHeight('placeholder-main', 12),
                                        objectFit: 'contain'
                                    }}
                                />
                            </div>
                        </Box>
                    </ContentBox>
                </Box>
                <ScrollingBar />
            </ImageBox>
        </MainBox>
    );
}
