import { Box, CircularProgress, Grid, styled } from '@mui/material';
import React, { useState } from 'react';
import { COLORS } from '../../../../utils/theme';
import ImagePlaceholder from '../../../../widgets/ImagePlaceholder';
import { crop } from '../../../../utils/helpers';
import { ASPECT_RATIO, ORIENTATION } from '../../../../utils/constants';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';

const MainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const OrientationBoxView = styled(Box)({
    border: `1px solid ${COLORS.darkGray}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative'
});

const OrientationText = styled(Box)({
    fontSize: 20,
    color: COLORS.darkGray,
    textAlign: 'center'
});

const LoadingBox = styled(Box)({
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});
export function PlaceholderView({ landscapeImage, portraitImage, client, reload }) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState();

    const handleUpdatePlaceholder = async (key, image) => {
        setLoading({ key, loading: true });
        try {
            let params = {};
            params['id'] = client.id;
            params[key] = image;
            const { data } = await api.client.update(params);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                if (reload) {
                    reload();
                }
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading({ key, loading: false });
        } catch (error) {
            setLoading({ key, loading: false });
            enqueueSnackbar(error.message.error, { variant: 'error' });
        }
    };
    return (
        <MainGrid container spacing={6}>
            <Grid item>
                <OrientationBoxView style={{ width: 300, aspectRatio: 16 / 9 }}>
                    {!landscapeImage ? (
                        <OrientationText>Landscape Placeholder</OrientationText>
                    ) : (
                        <ImagePlaceholder
                            handleChange={async (event) => {
                                let image = await crop(URL.createObjectURL(event.target.files[0]), ASPECT_RATIO[ORIENTATION.LANDSCAPE]);
                                handleUpdatePlaceholder('landscape', image?.split(',')[1]);
                            }}
                            image={landscapeImage}
                            boxDimensions={{ height: '100%', width: '100%' }}
                        />
                    )}
                    {loading?.key === 'landscape' && loading?.loading && (
                        <LoadingBox>
                            <CircularProgress sx={{ color: 'white' }} size={32} />
                        </LoadingBox>
                    )}
                </OrientationBoxView>
            </Grid>
            <Grid item>
                <OrientationBoxView style={{ height: 300, aspectRatio: 9 / 16 }}>
                    {!portraitImage ? (
                        <OrientationText>Portrait Placeholder</OrientationText>
                    ) : (
                        <ImagePlaceholder
                            handleChange={async (event) => {
                                let image = await crop(URL.createObjectURL(event.target.files[0]), ASPECT_RATIO[ORIENTATION.PORTRAIT]);
                                handleUpdatePlaceholder('portrait', image?.split(',')[1]);
                            }}
                            image={portraitImage}
                            boxDimensions={{ height: '100%', width: '100%' }}
                        />
                    )}

                    {loading?.key === 'portrait' && loading?.loading && (
                        <LoadingBox>
                            <CircularProgress sx={{ color: 'white' }} size={32} />
                        </LoadingBox>
                    )}
                </OrientationBoxView>
            </Grid>
        </MainGrid>
    );
}
