import { styled, Box, Grid, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '../../../../api';
import { addRequestCategoryValidation } from '../../../../utils/validations';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import CommonModal from '../../../../components/CommonModal';
import FieldLabelView from '../../../../components/FieldLabelView';
import { IOSSwitch } from '../../../../widgets/IOSSwitch';
import { COLORS } from '../../../../utils/theme';
import { fetchRequestCategory } from '../../../../redux/reducers/requestSlice';
import { DeleteButton } from '../../../../widgets/CommonWidgets';
import { DELETE_CONFIRMATION } from '../../../../utils/constants';

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
});
const FieldLabel = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    fontWeight: '600'
});
const MainForm = styled('form')({});

const AddCategoryModal = ({ open, handleClose, categoryData }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState({ submit: false, delete: false });
    const handleCloseEvent = () => {
        if (handleClose) {
            resetForm();
            setLoading({ submit: false, delete: false });
            handleClose();
        }
    };
    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setValues, setFieldValue, resetForm } = useFormik({
        initialValues: {
            name: '',
            status: 1
        },
        validationSchema: addRequestCategoryValidation,
        onSubmit: async (values) => {
            try {
                setLoading({ ...loading, submit: true });
                var result;
                if (categoryData?.id) {
                    values.id = categoryData?.id;
                    const { data } = await api.request.editCategory(values);
                    result = data;
                } else {
                    const { data } = await api.request.addCategory(values);
                    result = data;
                }
                if (result.success) {
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(result.message, { variant: 'success' });
                    dispatch(fetchRequestCategory());
                    handleCloseEvent();
                } else {
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading({ ...loading, submit: false });
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });
    useEffect(() => {
        if (categoryData) {
            setValues({
                name: categoryData?.name,
                status: categoryData?.status
            });
        }
    }, [categoryData, setValues]);
    const handleDelete = async () => {
        setLoading({ ...loading, delete: true });
        try {
            const { data } = await api.request.deleteCategroy(categoryData?.id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading({ ...loading, delete: false });
            dispatch(fetchRequestCategory());
            handleCloseEvent();
        } catch (error) {
            setLoading({ ...loading, delete: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <>
            <CommonModal open={open} onClose={handleCloseEvent} width={800} title={'Add Entry'}>
                <MainForm onSubmit={handleSubmit}>
                    <Grid container alignItems={errors.name ? 'center' : 'flex-end'}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <FieldLabelView label={'Name'}>
                                <CommonInput
                                    name={'name'}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    value={values.name}
                                    error={touched.name && errors.name}
                                />
                            </FieldLabelView>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} justifyContent={'center'}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                                <FieldLabel>Client Side Status:</FieldLabel>
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={values?.status}
                                    onChange={(e) => {
                                        setFieldValue('status', +e.target.checked);
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <ButtonContainer>
                        {categoryData && (
                            <DeleteButton
                                onClick={handleDelete}
                                loading={loading.delete}
                                confirmation={DELETE_CONFIRMATION}
                                label={'Delete Category'}
                                section={'Category'}
                                title={'Category'}
                                sx={{ mr: 2 }}
                            />
                        )}
                        <CommonButton
                            label={`${categoryData?.id ? 'Update' : 'Add'}`}
                            loading={loading.submit}
                            onClick={handleSubmit}
                            variant="contained"
                        />
                    </ButtonContainer>
                </MainForm>
            </CommonModal>
        </>
    );
};

export default AddCategoryModal;
