import React, { useState } from 'react'
import { styled } from '@mui/material/styles';
import { Button, Menu, MenuItem, Divider, Box, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ICONS } from '../assets';
import { COLORS } from '../utils/theme';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 180,
        color: 'rgb(55, 65, 81)',
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },

    },
}));

const FilterButton = styled(Button)({
    background: COLORS.white,
    margin: '2px!important',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '0.425rem',
    fontSize: `14px !important`,
    color: `${COLORS.darkShadesGray}!important`,
    fontWeight: 100,
    '&.MuiButtonBase-root:hover': {
        '&:hover': {
            background: COLORS.white,
            opacity: 1
        }
    }
})

const ActionButton = styled(Button)(({ btnType }) => ({
    borderRadius: '0.425rem',
    fontSize: `13px !important`,
    fontWeight: 100,
    minWidth: 120
}))

const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '6px 16px'
})

const Badge = styled(Typography)({
    color: COLORS.white,
    borderRadius: '50%',
    backgroundColor: COLORS.primary.light,
    fontSize: 12,
    width: 16,
    height: 16,
    marginLeft: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
})

const CustomFilters = ({ id, label, sx, menuSx, options, handleChangeAction, handleApply, checked, count, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl);

    const handleApplyFilter = () => {
        handleApply(id)
        setAnchorEl(null)
    }

    return (
        <div>
            <FilterButton {...props} sx={sx}
                id={id}
                aria-controls={open ? id : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={(e) => setAnchorEl(e.currentTarget)}
                endIcon={open ? <ICONS.ArrowUp sx={{ color: COLORS.primary.light }} /> : <ICONS.ArrowDown sx={{ color: COLORS.primary.light }} />}
            >
                {label || ''} {count ? <Badge>{count}</Badge> : null}
            </FilterButton>
            <StyledMenu sx={menuSx}
                id={id}
                MenuListProps={{
                    'aria-labelledby': id,
                }}
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={() => setAnchorEl(null)}
            >
                {
                    options && options?.length ?
                        <div>
                            {
                                options?.map(item => <MenuItem onClick={() => handleChangeAction(item, id)} sx={{ fontSize: 14, padding: '0 16px' }} key={item.id} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }} >
                                        <Checkbox size='small' checked={checked?.includes(item?.id)} />
                                        {item?.label}
                                    </Box>
                                </MenuItem>
                                )
                            }
                            <Divider />
                            <ButtonContainer>
                                <ActionButton onClick={() => setAnchorEl(null)} variant='outlined' >Cancel</ActionButton>
                                <ActionButton onClick={() => handleApplyFilter()} variant='contained' sx={{ marginLeft: '8px' }} btnType='primary' >Apply</ActionButton>
                            </ButtonContainer>
                        </div> : <MenuItem sx={{ fontSize: 14 }} disableRipple disabled >Not found</MenuItem>
                }
            </StyledMenu>
        </div>

    )
}

export default CustomFilters