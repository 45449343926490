import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchVersions = createAsyncThunk('versions/fetchVersions', async (params) => {
    const response = await api.version.getAllVersion(params);
    return response.data.result;
});

const initialState = {
    versions: []
};

export const versionSlice = createSlice({
    name: 'version',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchVersions.fulfilled, (state, action) => {
            state.versions = action.payload;
        });
    }
});

export default versionSlice.reducer;