import { Box, styled } from '@mui/material';
import { useEffect, useMemo } from 'react';
import Ticker from 'react-ticker';
import useFetch from '../../../hooks/useFetch';
import { useParams } from 'react-router-dom';

const MainBox = styled(Box)`
    background-color: #000000;
`;

const HeadingTest = styled('h2')`
    color: #ffffff !important;
    font-family: MyriadWebPro-Bold;
    white-space: nowrap;
    background-color: #000000;
    margin-left: 12px;
    font-size: 40vh;
`;

export const RssTicker = () => {
    const { location_id } = useParams();
    const { data: locationData } = useFetch({
        initialUrl: '/get_location_by_id',
        config: {
            params: {
                location_id
            }
        }
    });

    const { data, callFetch: fetchAllMessage } = useFetch({
        initialUrl: '/get_all_messages',
        skipOnStart: true,
        config: {
            params: {
                location_id,
                status: 1
            }
        }
    });

    useEffect(() => {
        if (locationData) {
            let id = location_id;
            if (locationData?.result?.mirror_of_data) {
                id = locationData?.result?.mirror_of_data?.id;
            }
            fetchAllMessage({ params: { location_id: id, status: 1 } });
        }
    }, [fetchAllMessage, locationData, location_id]);

    const messages = useMemo(() => {
        if (data?.result) {
            return data?.result?.map((item) => item?.message);
        } else {
            return [];
        }
    }, [data?.result]);

    return (
        <MainBox sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
            {messages?.length && (
                <Ticker offset="run-in" speed={window.innerWidth * 0.006}>
                    {() => (
                        <div style={{ display: 'flex' }}>
                            {messages?.map((item) => {
                                return <HeadingTest>{item}</HeadingTest>;
                            })}
                        </div>
                    )}
                </Ticker>
            )}
        </MainBox>
    );
};
