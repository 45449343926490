const GOOGLE_BUSINESS_BASE_URL = 'https://mybusinessaccountmanagement.googleapis.com/v1/';
const GOOGLE_BUSINESS_BASE_URL_V4 = 'https://mybusiness.googleapis.com/v4/';

const FACEBOOK_BUSINESS_BASE_URL = 'https://graph.facebook.com/';

export const getGoogleAccounts = ({ access_token }) => {
    return new Promise((resolve, reject) => {
        fetch(`${GOOGLE_BUSINESS_BASE_URL}accounts`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getGoogleLocations = ({ access_token, account_id }) => {
    return new Promise((resolve, reject) => {
        fetch(`${GOOGLE_BUSINESS_BASE_URL}accounts/${account_id}/locations/?readMask=storeCode,name,title`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        })
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getGoogleReview = ({ access_token, account_id, location_id }) => {
    return new Promise((resolve, reject) => {
        fetch(`${GOOGLE_BUSINESS_BASE_URL_V4}accounts/${account_id}/locations/${location_id}/reviews`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        })
            .then((response) => response.text())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getFacebookAccounts = ({ user_id, access_token }) => {
    return new Promise((resolve, reject) => {
        fetch(`${FACEBOOK_BUSINESS_BASE_URL}${user_id}/accounts?fields=name,access_token&access_token=${access_token}`)
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getFacebookReviews = ({ page_id, access_token }) => {
    return new Promise((resolve, reject) => {
        fetch(
            `${FACEBOOK_BUSINESS_BASE_URL}${page_id}?fields=ratings{open_graph_story,recommendation_type,has_review}&access_token=${access_token}`
        )
            .then((response) => response.json())
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
