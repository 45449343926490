import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Autocomplete, Checkbox, FormHelperText, ListItemText, TextField, Typography } from '@mui/material';
import styled from '@emotion/styled';

import { ICONS } from '../../assets';
import { COLORS } from '../../utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import { stopPropagation } from '../../redux/reducers/editorSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    sx: {
        fontSize: 14,
        '&& .Mui-selected': {
            backgroundColor: COLORS.primary[10],
            '&: hover': {
                backgroundColor: COLORS.primary[10]
            }
        }
    },
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

const StyledSelect = styled(Select)({
    '& fieldset': {
        '& legend': {
            display: 'none'
        }
    }
});

const OutlineInput = styled(OutlinedInput)(({ outlineInputClass }) => ({
    fieldset: { ...outlineInputClass, top: 0 }
}));

const StyledAutoComplete = styled(Autocomplete)({
    '& div': {
        '& div': {
            height: 'unset !important'
        }
    }
});

const Parent = styled(ICONS.ArrowDown)`
    &.MuiSvgIcon-root {
        color: ${COLORS.primary.light};
    }
`;

export const CommonMultiSelect = ({
    options,
    value,
    handleChange,
    isApplyFontFamily,
    error,
    handleBlur,
    label,
    name,
    width,
    style,
    disabled,
    multiple,
    searchableMultiselect,
    outlineInputClass,
    recordNotFound,
    isPrevent,
    checkbox,
    ...rest
}) => {
    const dispatch = useDispatch();

    const handleDelete = (item) => {
        let updatedData = value.filter((e) => e !== item);
        handleChange({ target: { name, value: updatedData } });
    };

    const handleSearchbleDelete = (item) => {
        let updatedData = value.filter((e) => e !== item?.value);
        handleChange({ target: { name, value: updatedData } });
    };

    const handleSearchable = (event, newValue = []) => {
        handleChange({ target: { name, value: newValue?.map((item) => item?.value) } });
    };

    const searchableValue = React.useMemo(() => {
        return value && value?.map?.((item) => options.find((option) => option.value === item));
    }, [options, value]);

    const isAllSelected = options?.length > 0 && value?.length === options?.length;

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            handleChange({ target: { name, value: options.map((option) => option.value) } });
        } else {
            handleChange({ target: { name, value: [] } });
        }
    };

    return (
        <FormControl
            disabled={disabled}
            error={Boolean(error)}
            {...rest}
            sx={{ fontSize: '14px !important', width: style?.width ? style?.width : '100%', opacity: disabled ? 0.3 : 1, ...rest.sx }}
        >
            <InputLabel id="demo-multiple-chip-label">{label}</InputLabel>
            {searchableMultiselect ? (
                <StyledAutoComplete
                    multiple
                    id="chips-input"
                    options={options || []}
                    value={searchableValue || []}
                    onChange={(e, newValue) => {
                        handleSearchable(e, newValue);
                    }}
                    disabled={disabled}
                    name={name}
                    style={style}
                    onBlur={handleBlur}
                    getOptionLabel={(option) => option.name}
                    freeSolo
                    fullWidth
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    color="primary"
                                    onMouseDown={(event) => {
                                        event.stopPropagation();
                                    }}
                                    style={{
                                        fontSize: '14px !important',
                                        borderRadius: 4,
                                        marginLeft: 2,
                                        marginBottom: 2,
                                        height: 50,
                                        paddingTop: 6,
                                        paddingBottom: 6
                                    }}
                                    key={option?.value}
                                    label={option?.name}
                                    onDelete={() => handleSearchbleDelete(option)}
                                />
                            );
                        })
                    }
                    renderInput={(params) => <TextField error={error} sx={{ fontSize: 14 }} placeholder={label} {...params} />}
                />
            ) : (
                <StyledSelect
                    disabled={disabled}
                    IconComponent={Parent}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    name={name}
                    multiple={multiple}
                    style={style}
                    value={value || []}
                    onOpen={() => {
                        isPrevent && dispatch(stopPropagation(true));
                    }}
                    onClose={() => {
                        dispatch(stopPropagation(false));
                    }}
                    onChange={(e) => {
                        handleChange(e);
                    }}
                    onBlur={handleBlur}
                    input={<OutlineInput {...{ outlineInputClass, fontSize: 14 }} id="select-multiple-chip" label="Chip" />}
                    renderValue={(selected) => (
                        <>
                            {multiple ? (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected?.map((value) => (
                                        <Chip
                                            color="primary"
                                            onMouseDown={(event) => {
                                                event.stopPropagation();
                                            }}
                                            style={{ borderRadius: 4, fontSize: '14px !important' }}
                                            key={value}
                                            label={options && options.length !== 0 && options.find((item) => item.value === value)?.name}
                                            onDelete={() => handleDelete(value)}
                                        />
                                    ))}
                                </Box>
                            ) : (
                                <Typography sx={{ fontSize: 14 }}>{options?.find((item) => item.value === value)?.name}</Typography>
                            )}
                        </>
                    )}
                    MenuProps={MenuProps}
                >
                    {checkbox && multiple && (
                        <MenuItem value="all" sx={{ fontSize: '14px !important' }}>
                            <Checkbox
                                checked={isAllSelected}
                                indeterminate={value.length > 0 && value.length < options.length}
                                onChange={handleSelectAll}
                            />
                            <ListItemText
                                primary="Select All"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleSelectAll({ target: { checked: !isAllSelected } });
                                }}
                                sx={{ padding: '0.6rem 0' }}
                            />
                        </MenuItem>
                    )}
                    {options?.length && !recordNotFound ? (
                        options?.map?.((option) => (
                            <MenuItem
                                sx={
                                    isApplyFontFamily
                                        ? { fontFamily: `${option.value}!important`, fontSize: '14px !important' }
                                        : { fontSize: '14px !important' }
                                }
                                key={option.id}
                                value={option.value}
                                disabled={option.disabled}
                            >
                                {checkbox && multiple ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Checkbox checked={value.indexOf(option.value) > -1} />
                                        <ListItemText primary={option.name} />
                                    </Box>
                                ) : (
                                    option.name
                                )}
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem disabled>No results found</MenuItem>
                    )}
                </StyledSelect>
            )}

            {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
    );
};
