import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../api';
import { ICONS } from '../../../assets';
import CommonButton from '../../../components/CommonButton';
import { CommonTable } from '../../../components/Table';
import { ViewerPermission } from '../../../components/ViewerPermission';
import useFetch from '../../../hooks/useFetch';
import { fetchLocations, setActiveClientLocation } from '../../../redux/reducers/clientLocationSlice';
import { USER_ROLE } from '../../../utils/constants';
import { filterMirrorLocation, sortCategoryList } from '../../../utils/helpers';
import { COLORS } from '../../../utils/theme';
import { CommonHeader } from '../../../widgets/CommonHeader';
import AddMessageModal from './components/AddMessageModal';

const StyledButton = styled(CommonButton)({
    fontWeight: '300',
    fontSize: '16px',
    textAlign: 'center'
});
const Message = styled(Typography)({
    fontSize: 16,
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});
const FlexBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

export const ScrollingMessaging = () => {
    const [loading, setLoading] = useState(false);
    const [showMessageModal, setMessageModal] = useState({ isOpen: false, data: '' });
    const [unSavedChange, setUnsavedChanges] = useState(false);
    const { locations, activeLocation } = useSelector((state) => state.clientLocation);
    const { user } = useSelector((state) => state.auth);
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const locationList = filterMirrorLocation(sortCategoryList(locations));
    const { data, isLoading, callFetch: fetchScrollingMessages } = useFetch({
        initialUrl: '/get_all_messages',
        skipOnStart: true
    });
    const fetchScrollingMessage = useCallback(() => {
        if (activeLocation) {
            fetchScrollingMessages({ params: { location_id: activeLocation } });
            setUnsavedChanges(false);
        }
    }, [activeLocation]);

    useEffect(() => {
        dispatch(fetchLocations(user.client.id));
    }, [dispatch, user.client.id]);
    useEffect(() => {
        if (activeLocation) {
            fetchScrollingMessage();
        }
    }, [activeLocation, fetchScrollingMessage]);
    useEffect(() => {
        if (locationList.length && !activeLocation) {
            dispatch(setActiveClientLocation(locationList[0]?.id));
        } else {
            let ifExist = locationList.some((el) => el.id === activeLocation);
            if (!ifExist) dispatch(setActiveClientLocation(locationList[0]?.id));
        }
    }, [locationList, activeLocation, dispatch]);
    const handleChangedLocation = (e) => {
        dispatch(setActiveClientLocation(e.target.value));
    };

    useEffect(() => {
        if (data?.result) {
            setRows(data?.result);
        }
    }, [data?.result]);

    const columns = [
        {
            id: 'message',
            fieldName: 'message',
            label: 'Message',
            render: ({ rowData }) => {
                return (
                    <FlexBox>
                        <IconButton>
                            <ICONS.DragIndicatorIcon />
                        </IconButton>
                        <Message>{rowData.message}</Message>
                    </FlexBox>
                );
            }
        },
        {
            id: 'modified_message',
            fieldName: 'modified_message',
            label: 'Modified',
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'status',
            fieldName: 'status',
            label: 'Status',

            render: ({ rowData }) => {
                return (
                    <>
                        {rowData.status ? (
                            <ICONS.CheckCircleIcon variant="contained" style={{ color: 'green' }} />
                        ) : (
                            <ICONS.CancelIcon style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
            style: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];
    function handleDragOver(e) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    }
    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('text/plain', id);
    };
    const hanldeOnDrop = (e, id) => {
        e.preventDefault();
        const draggedId = e.dataTransfer.getData('text/plain');
        const targetId = id;

        // Find the dragged row and target row
        const draggedRow = rows.find((row, id) => row.id === Number(draggedId));
        const targetRow = rows.find((row, id) => row.id === Number(targetId));

        // Find the indices of the dragged row and target row
        const draggedRowIndex = rows.indexOf(draggedRow);
        const targetRowIndex = rows.indexOf(targetRow);

        // Create a new array with the updated order of rows
        const updatedRows = [...rows];
        const [removed] = updatedRows.splice(draggedRowIndex, 1);
        updatedRows.splice(targetRowIndex, 0, removed);
        // Update the state with the new order of rows
        setUnsavedChanges(true);
        setRows(
            updatedRows.map((item, index) => {
                return { ...item, order: index + 1 };
            })
        );
    };
    const saveRearrangedRowsData = async () => {
        setLoading(true);
        const order = rows.map((item) => {
            return { id: item.id, order: item.order };
        });
        try {
            const { data } = await api.scrollingMessage.edit({ order });
            if (data.success) {
                fetchScrollingMessage();
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    const handleRowDetail = (data) => {
        if (user.role !== USER_ROLE.VIEWER) {
            setMessageModal({ isOpen: true, data });
        }
    };

    return (
        <Grid container direction="column" justifyContent="flex-end">
            <CommonHeader
                title={'Scrolling Messaging'}
                buttonLabel={'Update'}
                loading={loading}
                dropdownValue={activeLocation}
                dropdownList={locationList}
                unsavedChanges={unSavedChange}
                outlineInputClass={{ top: 0 }}
                handleChangeDropdown={handleChangedLocation}
                onClick={saveRearrangedRowsData}
                isSaveButtonDisabled={!unSavedChange}
                componentBeforeSaveButton={
                    <ViewerPermission>
                        <StyledButton
                            sx={{ mr: 2 }}
                            onClick={() => setMessageModal({ isOpen: true, data: '' })}
                            variant="contained"
                            label={'Add New Message'}
                        />
                    </ViewerPermission>
                }
            />
            <Box sx={{ marginTop: '24px' }} ></Box>
            <Grid item spacing={3}>
                <CommonTable
                    handleRowDetail={handleRowDetail}
                    columns={columns}
                    rows={rows}
                    rowsPadding={1}
                    draggable={true}
                    isNotDefaultSorted
                    onDragStart={(e, id) => {
                        handleDragStart(e, id);
                    }}
                    onDrop={(e, id) => {
                        hanldeOnDrop(e, id);
                    }}
                    onDragOver={(e) => {
                        handleDragOver(e);
                    }}
                    loading={isLoading}
                />
            </Grid>
            {showMessageModal.isOpen && (
                <AddMessageModal
                    open={showMessageModal.isOpen}
                    handleClose={() => setMessageModal({ isOpen: false, data: '' })}
                    fetchLatestData={fetchScrollingMessage}
                    messageData={showMessageModal.data}
                    location_id={activeLocation}
                />
            )}
        </Grid>
    );
};
