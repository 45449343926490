import { Grid, Tabs, styled, Tab } from '@mui/material';
import { COLORS } from '../../utils/theme';
import React from 'react';

const BarMainGrid = styled(Grid)({
    height: 46,
    width: '100%',
    display: 'flex',
    backgroundColor: COLORS.white,
    borderTopLeftRadius: '0.425rem',
    borderTopRightRadius: '0.425rem',
    alignItems: 'center',
    position: 'relative'
});

const TabItemGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
});

const TabItemText = styled(Grid)({
    color: COLORS.black
});

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

export const ContentTabBar = ({
    tabArray,
    activeTab,
    handleChangeActiveTab,
    activeColor = COLORS.primary.light,
    borderColor = COLORS.border,
    height = 46,
    fontSize = 18,
    advanced,
    inactiveStyle,
    useOld = false,
    tabStyle = {},
    ...props
}) => {
    const getActiveIndicatorPosition = () => {
        let top = 0;
        let left = '0%';
        if (tabArray) {
            const activePossition = tabArray.findIndex((item) => item.id === activeTab);
            left = `${(activePossition / tabArray.length) * 100}%`;
        }
        return {
            top,
            left
        };
    };

    const handleChange = (event, newValue) => {
        const item = tabArray.find((item) => item?.id === newValue);
        handleChangeActiveTab(item);
    };

    return (
        <BarMainGrid
            style={{
                height: height
            }}
            container
        >
            {!useOld ? (
                <Tabs {...props}
                    sx={{ width: '100%', borderBottom: `1px solid ${COLORS.border}` }}
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    {tabArray &&
                        tabArray?.map((item) => {
                            return (
                                <Tab
                                    sx={{ maxWidth: '100%', flex: 1, fontSize: fontSize, ...tabStyle }}
                                    value={item?.id}
                                    label={item?.name}
                                    {...a11yProps(item.id)}
                                />
                            );
                        })}
                </Tabs>
            ) : (
                tabArray &&
                tabArray.map((item) => {
                    let styleCondition = item.inactive ? inactiveStyle : {};
                    return (
                        <TabItemGrid
                            onClick={() => {
                                if (!item?.inactive) {
                                    handleChangeActiveTab(item);
                                }
                            }}
                            style={
                                activeTab === item.id
                                    ? {
                                        border: `1px solid ${borderColor}`,
                                        height: height,
                                        ...styleCondition
                                    }
                                    : { border: `1px solid ${borderColor}`, height: height, ...styleCondition }
                            }
                            key={item.id}
                            item
                            md={12 / tabArray.length}
                            sm={12 / tabArray.length}
                            xs={advanced === true ? 12 : 12 / tabArray.length}
                        >
                            <TabItemText style={{ fontSize: fontSize }}>{item.name}</TabItemText>
                        </TabItemGrid>
                    );
                })
            )}

            {useOld && (
                <TabItemGrid
                    item
                    md={12 / tabArray.length}
                    sm={12 / tabArray.length}
                    xs={advanced === true ? 12 : 12 / tabArray.length}
                    style={{
                        position: 'absolute',
                        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        backgroundColor: activeColor,

                        height: height,
                        width: '100%',
                        opacity: 0.3,
                        ...getActiveIndicatorPosition()
                    }}
                />
            )}
        </BarMainGrid>
    );
};
