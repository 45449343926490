import { styled, Box, Typography, Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMAGES } from '../assets';
import CommonImage from '../components/CommonImage';
import CommonPopover from '../components/CommonPopover';
import { ViewerPermission } from '../components/ViewerPermission';
import { ASPECT_RATIO, CONTENT_DATA_TYPE, CONTENT_MODULE_TYPE, ORIENTATION, ORIENTATION_DATA_KEY, SERVER_URL } from '../utils/constants';
import { COLORS } from '../utils/theme';

const CollectionBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLORS.darkGray}`,
    backgroundColor: COLORS.white,
    borderRadius: '.425rem',
    boxShadow: COLORS.cardShadow,
    padding: 12
});

const CollectionImage = styled(CommonImage)({
    width: '100%',
    objectFit: 'cover'
});

const ContentBox = styled(Box)({
    padding: '8px 0px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
});

const ContentNameBox = styled(Box)({
    flex: 1
});

const ContentText = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: COLORS.black,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '260px',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '250px'
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '150px'
    }
}));

const ContentCategoryText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black,
    fontWeight: 600,
    'span': {
        fontWeight: 400
    }
});

const ActionText = styled(Typography)({
    cursor: 'pointer'
});
const StatusImage = styled('img')({
    height: 22,
    width: 22,
    marginRight: 1
});

export const LibraryCardWidgets = ({
    onClick,
    data,
    orientation = ORIENTATION.LANDSCAPE,
    handleRemoveVideo,
    loading,
    hasAuthStatus,
    isAuthorized,
    handleEdit,
    objectFit = 'cover'
}) => {
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const { categorys } = useSelector((state) => state.category);
    const { client } = useSelector((state) => state.auth);
    const adminCategoryCurationMode = categorys.find((item) => item.id === data?.category_id)?.curate_playlist;
    const clientCategoryCurationMode = useMemo(() => {
        return client?.curate_list[[data?.category_id]];
    }, [data?.category_id, client?.curate_list]);

    const handleOpenAction = (event) => {
        event.stopPropagation();
        setActionAnchorEl(event.currentTarget);
    };
    const handleCloseAction = (event) => {
        event.stopPropagation();
        setActionAnchorEl(null);
    };

    const handleDelete = (event) => {
        event.stopPropagation();
        setActionAnchorEl(false);
        handleRemoveVideo(data);
    };

    const handleClick = (data) => {
        onClick && onClick(data);
    };
    const handleEditNViewAction = () => {
        // if (data?.module_type === CONTENT_MODULE_TYPE.SOCIAL) {
        //     handleEdit && handleEdit(data);
        // } else {
        handleClick();
        // }
    };
    return (
        <CollectionBox
            key={data?.data_type + data?.data_type === 'video' ? data?.video_id : data?.collection_id}
            onClick={() => {
                handleClick(data);
            }}
            sx={
                !loading && {
                    ':hover': {
                        outline: `3px solid ${COLORS.primary.light}`,
                        cursor: 'pointer'
                    }
                }
            }
        >
            {loading ? (
                <Skeleton variant="rectangular" width={'100%'} height={orientation === ORIENTATION.LANDSCAPE ? 127 : 402} />
            ) : (
                <CollectionImage
                    style={{ width: '100%', aspectRatio: ASPECT_RATIO[orientation], objectFit }}
                    src={
                        data?.content_type === CONTENT_DATA_TYPE.IMAGE
                            ? data?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                            : `${SERVER_URL}${data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}`
                    }
                />
            )}
            <ContentBox>
                <ContentNameBox>
                    {loading ? <Skeleton variant="text" width={'120px'} /> : <ContentText>{data?.name}</ContentText>}
                    {loading ? (
                        <Skeleton variant="text" width={'150px'} />
                    ) : (
                        <ContentCategoryText>Category : <span>{data?.playlist?.name}</span></ContentCategoryText>
                    )}
                </ContentNameBox>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {hasAuthStatus && (isAuthorized ? <StatusImage src={IMAGES.CheckMark} /> : <StatusImage src={IMAGES.Attention_Icon} />)}
                    {/* <ViewerPermission>
                        <IconButton onClick={handleOpenAction} aria-label="close" edge="end" size="large" sx={{ color: COLORS.darkGray }}>
                            <ICONS.More color={COLORS.darkGray} />
                        </IconButton>
                    </ViewerPermission> */}
                </Box>
            </ContentBox>

            <CommonPopover anchorEl={actionAnchorEl} handleClose={handleCloseAction}>
                <ViewerPermission>
                    <Box sx={{ display: 'grid', padding: 1 }}>
                        {!adminCategoryCurationMode || !clientCategoryCurationMode ? (
                            <ActionText onClick={handleDelete}>Remove</ActionText>
                        ) : null}
                        <ActionText sx={{ pt: 1 }} onClick={handleEditNViewAction}>
                            {data?.module_type === CONTENT_MODULE_TYPE.SOCIAL ? 'Edit' : 'View'}
                        </ActionText>
                    </Box>
                </ViewerPermission>
            </CommonPopover>
        </CollectionBox>
    );
};
