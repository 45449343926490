import { Modal, styled, Box } from '@mui/material';
import React from 'react';
import { COLORS } from '../../utils/theme';
import { ModalHeader } from '../../widgets/CommonWidgets';

const ModalBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: COLORS.white,
    boxShadow: 24,
    borderRadius: '0.425rem',
    outline: 0
});
const ContentBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '10px 24px',
    [theme.breakpoints.down('sm')]: {
        padding: '10px 12px'
    }
}));

const CommonModal = ({ open, id, onClose, title, children, width, style, headerStyle }) => {
    return (
        <>
            <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <ModalBox
                    {...{ id }}
                    sx={{
                        width: { md: width, lg: width, xs: '90%', sm: '80%' },
                        maxHeight: { md: 'auto', lg: 'auto', sm: 'auto', xs: '100vh' },
                        overflowY: { xs: 'scroll', sm: 'auto', md: 'auto', lg: 'auto' }, //need to handle for landscape mode too
                        ...style
                    }}
                >
                    {title && <ModalHeader title={title} onClose={onClose} style={headerStyle} />}
                    <ContentBox>{children}</ContentBox>
                </ModalBox>
            </Modal>
        </>
    );
};

export default CommonModal;
