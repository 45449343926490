import React, { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation, useNavigate } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Sidebar from './Sidebar';
import { HEADER_HEIGHT, USER_ROLE } from '../../utils/constants';

import { useDispatch, useSelector } from 'react-redux';
import Header from './Header';
import { COLORS } from '../../utils/theme';
import { MENU_ITEM, PAGES } from '../../utils/urls';
import { handleDrawer, toggleDrawer } from '../../redux/reducers/commonSlice';
import { activePath, getAccessObjectFromArrayObject } from '../../utils/helpers';
import { fetchClientAccess, fetchUserInfo, updateUser } from '../../redux/reducers/authSlice';
import Loader from '../../components/Loader';
import useWindowSize from '../../hooks/useWindow';
import { setHeader } from '../../api/client';
import UpdateLocationLoop from '../../components/UpdateLocationLoopButton';

// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, width }) => ({
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
    }),
    minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
    flexGrow: 1,
    // padding: theme.breakpoints.down('sm') ? '16px' : '20px',
    backgroundColor: COLORS.background,
    marginTop: HEADER_HEIGHT,
    maxWidth: width,
    overflow: 'hidden',
    width: '100%'
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUplg = useMediaQuery(theme.breakpoints.up('lg'));
    const { width } = useWindowSize();
    const { user, access } = useSelector((state) => state.auth);
    const location = useLocation();
    const dispatch = useDispatch();
    const { isDrawerOpen } = useSelector((state) => state.common);
    const [loadingAccess, setLoadingAccess] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.client) {
            dispatch(fetchClientAccess({ params: { client_id: user?.client?.id } })).then(() => {
                setLoadingAccess(false);
            });
        } else {
            setLoadingAccess(false);
        }
    }, [dispatch, user?.client?.id, user?.client]);

    useEffect(() => {
        dispatch(fetchUserInfo({ params: { user_id: user?.id } })).then((response) => {
            if (response?.payload) {
                const { clients, ...otherData } = response?.payload;
                const isAlreadySelected = clients?.map((item) => item?.id).includes(user?.client?.id);
                if (isAlreadySelected) {
                    dispatch(updateUser({ ...user, ...otherData, clientList: clients }));
                } else {
                    setHeader('client_id', clients?.[0]?.id);
                    dispatch(updateUser({ ...user, ...otherData, client: clients?.[0], clientList: clients }));
                }
            }
        });
        // eslint-disable-next-line
    }, [dispatch, user?.client?.id, user?.id]);

    // Handle left drawer
    const handleLeftDrawerToggle = () => {
        dispatch(toggleDrawer());
    };

    useEffect(() => {
        matchUpMd && dispatch(handleDrawer(true));
    }, [dispatch, matchUpMd]);

    const checkPermissionOfChildrenItem = (item) => {
        let accessItem;
        if (access?.access) {
            accessItem = getAccessObjectFromArrayObject(access?.access, item.accessKey);
        }
        if (item.accessKey) {
            if (accessItem) {
                return accessItem.visibility;
            } else {
                return true;
            }
        }
        return true;
    };

    const checkPermissionOfSection = (item) => {
        if (item?.role?.includes?.(user?.role)) {
            let accessItem;
            if (access?.access) {
                accessItem = getAccessObjectFromArrayObject(access?.access, item.accessKey);
            }
            if (item.accessKey) {
                if (accessItem) {
                    return accessItem.visibility;
                } else {
                    return true;
                }
            }
            return true;
        }
        return false;
    };

    const getAvailableRoutFromPermission = () => {
        let filterdSection = MENU_ITEM?.filter((item) => checkPermissionOfSection(item))?.map((route) => {
            return {
                ...route,
                children: route?.children?.filter((c) => checkPermissionOfChildrenItem(c))
            };
        });

        filterdSection = filterdSection?.filter((item) => item?.children?.length !== 0);

        return filterdSection[0]?.children[0]?.url;
    };

    const checkForCurrentLocationIsAvailableOrNot = (url) => {
        let findedItem = null;
        MENU_ITEM?.forEach((section) => {
            if (checkPermissionOfSection(section)) {
                let item = section?.children?.find((item) => url.includes(item?.url));
                if (item) {
                    findedItem = item;
                    return true;
                }
            }
        });

        if (findedItem) {
            return checkPermissionOfChildrenItem(findedItem);
        } else {
            return false;
        }
    };

    const NavigateFromUser = () => {
        if (!user) {
            return <Navigate to={PAGES.LOGIN.url} />;
        } else if (!user.client && user?.role !== USER_ROLE.ADMIN) {
            return <Navigate to={PAGES.ACCOUNT_SELECTION.url} />;
        } else if (location.pathname === '/') {
            if (user?.role === USER_ROLE.MANAGER || user.role === USER_ROLE.VIEWER) {
                let availableRoute = getAvailableRoutFromPermission();

                return <Navigate to={availableRoute || PAGES.LOCATIONS.url} />;
            } else {
                return <Navigate to={PAGES.ACCOUNT.url} />;
            }
        } else {
            return (
                <Box>
                    {user?.role !== 'admin' && !matchUplg && activePath() !== PAGES.BROWSE.url ? (
                        <Box sx={{ width: '100%', display: 'flex', mb: 1, alignItems: 'center', justifyContent: 'center' }}>
                            <UpdateLocationLoop />
                        </Box>
                    ) : null}
                    <Outlet />
                </Box>
            );
        }
    };

    useEffect(() => {
        if (!checkForCurrentLocationIsAvailableOrNot(location?.pathname) && user?.role !== USER_ROLE.ADMIN && !loadingAccess) {
            let availableRoute = getAvailableRoutFromPermission();
            navigate(availableRoute || '/');
        }
    }, [access?.client_id, loadingAccess]);

    return (
        <Box sx={{ display: 'flex', backgroundColor: COLORS.white }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: isDrawerOpen ? theme.transitions.create('width') : 'none',
                    height: HEADER_HEIGHT,
                    borderBottom: `1px solid ${COLORS.border}`,
                    overflow: 'hidden',
                    boxShadow: '0 1px 4px -1px rgb(0 0 0 / 15%)'
                }}
            >
                <Toolbar style={{ padding: 0, backgroundColor: COLORS.white }}>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>
            {/* drawer */}
            <Sidebar drawerOpen={isDrawerOpen} drawerToggle={handleLeftDrawerToggle} />
            {/* main content */}
            <Main id="main-container" theme={theme} open={isDrawerOpen} width={width} sx={{ padding: { xs: '7px', md: 3 } }}>
                {!loadingAccess ? (
                    NavigateFromUser()
                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Loader isLoading={true} />
                    </Box>
                )}
            </Main>
        </Box>
    );
};

export default MainLayout;
