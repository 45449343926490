import { Box, IconButton, styled, TextField, Typography } from '@mui/material';
import { ICONS } from '../../../../assets';
import { CustomColorPicker } from '../../../../components/CustomColorPicker';
import { ColorBoxWidget } from '../../../../widgets/ColorBoxWidget';

const TitleBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});
const LabelText = styled(Typography)({
    fontSize: 14,
    fontWeight: '600'
});
const LineItemsContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});
const Labels = ({ labelData, onChange }) => {

    const addLabel = () => {
        if (labelData.length < 3) {
            onChange([...labelData, { id: labelData.length + 1, label: '', color: '' }]);
        }
    };

    const deleteLabel = (id) => {
        const labelsClone = [...labelData];
        const index = labelsClone.findIndex((item) => item.id === id);
        if (index !== -1) {
            labelsClone.splice(index, 1);
        }
        onChange(labelsClone);
    };
    const handleLabelData = ({ id, label, color }) => {
        const labelsClone = [...labelData];
        const index = labelsClone.findIndex((item) => item.id === id);
        if (index !== -1) {
            labelsClone[index].id = id;
            labelsClone[index].label = label;
            labelsClone[index].color = color;
        }
        onChange(labelsClone);
    };

    return (
        <>
            <TitleBox>
                <LabelText>{'Label(s)'}</LabelText>
                <IconButton onClick={addLabel}>
                    <ICONS.Add color={'success'} fontSize={'small'} />
                </IconButton>
            </TitleBox>
            <>
                {labelData?.map((item) => {
                    return (
                        <LineItemsContainer>
                            <IconButton onClick={() => deleteLabel(item.id)}>
                                <ICONS.Close color={'error'} fontSize={'small'} />
                            </IconButton>
                            <TextField value={item.label} onChange={(e) => handleLabelData({ ...item, label: e.target.value })} />
                            <CustomColorPicker
                                component={<ColorBoxWidget height={42} backgroundColor={item.color} sx={{ borderRadius: '0.425rem' }} />}
                                name={'color'}
                                currentcolor={item.color}
                                handleColorChange={(e) => {
                                    handleLabelData({ ...item, color: e.hex });
                                }}
                            />
                        </LineItemsContainer>
                    );
                })}
            </>
        </>
    );
};
export default Labels;
