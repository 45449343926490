import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchAllLocations = createAsyncThunk('locations/fetchAllLocations', async(params) => {
    const response = await api.location.getAll(params);
    return response.data.result;
})

const initialState = {
    locations: []
};

export const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllLocations.fulfilled, (state, action) => {
            state.locations = action.payload;
        });
    }
});

export default locationsSlice.reducer;