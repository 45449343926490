import { styled, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { api } from '../../../../api';
import { addMessageValidation } from '../../../../utils/validations';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import CommonModal from '../../../../components/CommonModal';
import { IOSSwitch } from '../../../../widgets/IOSSwitch';
import { DeleteButton } from '../../../../widgets/CommonWidgets';
import { DELETE_CONFIRMATION } from '../../../../utils/constants';

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
});
const BottomContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});
const MainForm = styled('form')({});

const AddMessageModal = ({ open, handleClose, messageData,fetchLatestData,location_id }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState({ submit: false, delete: false });
    const handleCloseEvent = () => {
        if (handleClose) {
            resetForm();
            setLoading({ submit: false, delete: false });
            handleClose();
        }
    };
    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setValues, setFieldValue, resetForm } = useFormik({
        initialValues: {
            message: '',
            status: 1
        },
        validationSchema: addMessageValidation,
        onSubmit: async (values) => {
            const params = {
                status: values.status,
            };
            try {
                setLoading({ ...loading, submit: true });
                var result;
                if (messageData?.id) {
                    if (messageData.message !== values.message) {
                        params.message = values.message;
                    }
                    params.id = messageData?.id;
                    const { data } = await api.scrollingMessage.edit(params);
                    result = data;
                } else {
                    values.location_id = location_id;
                    const { data } = await api.scrollingMessage.add(values);
                    result = data;
                }
                if (result.success) {
                    fetchLatestData();
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(result.message, { variant: 'success' });
                    handleCloseEvent();
                } else {
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading({ ...loading, submit: false });
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });
    useEffect(() => {
        if (messageData) {
            setValues({
                message: messageData?.message,
                status: messageData?.status
            });
        }
    }, [messageData, setValues]);
    const handleDelete = async () => {
        setLoading({ ...loading, delete: true });
        try {
            const { data } = await api.scrollingMessage.delete(messageData?.id);
            if (data.success) {
                fetchLatestData();
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading({ ...loading, delete: false });
            handleCloseEvent();
        } catch (error) {
            setLoading({ ...loading, delete: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <>
            <CommonModal open={open} onClose={handleCloseEvent} width={600} title={`${messageData?.id ? 'Edit' : 'Add'} Message`}>
                <MainForm onSubmit={handleSubmit}>
                    <CommonInput
                        name={'message'}
                        multiline={true}
                        minRows={4}
                        style={{ height: 'auto' }}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        value={values.message}
                        error={touched.message && errors.message}
                    />
                    <BottomContainer>
                        <IOSSwitch
                            sx={{ mt: 2 }}
                            checked={values?.status}
                            onChange={(e) => {
                                setFieldValue('status', +e.target.checked);
                            }}
                        />
                        <ButtonContainer>
                            {messageData?.id && (
                                <DeleteButton
                                    onClick={handleDelete}
                                    loading={loading.delete}
                                    confirmation={DELETE_CONFIRMATION}
                                    label={'Delete'}
                                    section={'Message'}
                                    title={'Message'}
                                    sx={{ mr: 2 }}
                                />
                            )}
                            <CommonButton
                                label={`${messageData?.id ? 'Save' : 'Add'}`}
                                loading={loading.submit}
                                onClick={handleSubmit}
                                variant="contained"
                            />
                        </ButtonContainer>
                    </BottomContainer>
                </MainForm>
            </CommonModal>
        </>
    );
};

export default AddMessageModal;
