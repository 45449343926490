import { Box, styled, Grid, Typography, Link, Button, IconButton, Breadcrumbs, Input, FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { ICONS } from '../assets';
import CommonButton from '../components/CommonButton';
import { CommonInput } from '../components/CommonInput';
import { CommonMultiSelect } from '../components/CommonMultiSelect';
import { ViewerPermission } from '../components/ViewerPermission';
import { ORIENTATION } from '../utils/constants';
import { COLORS } from '../utils/theme';
import { AlertDialog, UnsavedChanges } from './CommonWidgets';

const MainBox = styled(Grid)({
    padding: '16px 25px',
    width: '100%',
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem',
    alignItems: 'center'
});

const PageTitle = styled(Typography)({
    fontSize: 22,
    color: COLORS.black,
    fontWeight: '300'
});
const VrLine = styled(Box)({
    height: 50,
    backgroundColor: COLORS.dividerColor,
    border: '1px solid #d0d0d0'
});

const LeftContentView = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

const ActionContentView = styled(Grid)({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
});

const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: '300',
    height: '42px',
    fontSize: '16px',
    textAlign: 'center'
});

const SetContentView = styled(Box)({ display: 'flex', alignItems: 'center' });

const LandscapeBox = styled(Box)({
    height: 28,
    width: 46,
    border: `3px solid ${COLORS.black}`,
    borderRadius: 6,
    cursor: 'pointer'
});

const PortraitBox = styled(Box)({
    height: 46,
    width: 28,
    border: `3px solid ${COLORS.black}`,
    borderRadius: 6,
    cursor: 'pointer'
});

const BreadcrumbText = styled(Link)({
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
        color: COLORS.primary.light
    }
});
const CloseButton = styled(Button)({
    color: COLORS.black,
    padding: '6px 16px',
    borderRadius: '0.425rem',
    border: `1px solid ${COLORS.primary.gray}`,
    fontWeight: 500,
    marginRight: 8,
    height: 42,
    '&:hover': {
        backgroundColor: COLORS.primary.gray,
        color: COLORS.white
    }
});

const TrashButton = styled(IconButton)({
    margin: '0px 0px 0px 8px',
    background: COLORS.primary.danger,
    borderRadius: '0.425rem',
    '&:hover': {
        background: COLORS.hover.danger
    }
});

const ResetButton = styled(IconButton)({
    margin: 0,
    background: COLORS.primary.success,
    borderRadius: '0.425rem',
    '&:hover': {
        background: COLORS.hover.success
    }
});

const TrashIcon = styled(ICONS.Trash)({
    color: COLORS.white,
    width: 24
});

const ResetIcon = styled(ICONS.ResetLoop)({
    color: COLORS.white,
    width: 24,
    height: 24
});

const CurrentBreadcrumbText = styled(Typography)({
    fontSize: 14
});

const StyledEditTitleInput = styled(Input)({
    fontSize: 26,
    color: COLORS.black,
    textDecoration: 'none'
});

const ActionText = styled(Typography)({
    color: COLORS.darkGray,
    fontWeight: 400,
    fontSize: 16,
    marginLeft: 8
});

export const CommonHeader = ({
    title,
    buttonLabel,
    isNotShowRightCol,
    onClick,
    handleSearch,
    searchValue,
    loading,
    orientationFilter,
    onChangeOrientation,
    searchbar,
    breadcrumbText,
    unsavedChanges,
    onClose,
    inEdit,
    onDelete,
    dropdownList,
    dropdownName,
    dropdownValue,
    handleChangeDropdown,
    disableUnderlineColor,
    inputComponent,
    children,
    infoOutlineIcon,
    isXSset6,
    isActionContentViewHidden,
    backTo,
    sx,
    outlineInputClass,
    isSaveButtonDisabled,
    onReset,
    showCloseButton = true,
    isRequest,
    componentBeforeSaveButton,
    recordNotFound,
    onKeyDown,
    customAction,
    editTitleNameFlag = false,
    editTitleName,
    handleBlurTitle,
    handleChangeTitle,
    touched,
    errors,
    onDeleteIconVisible
}) => {
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const [discardChanges, setdiscardChanges] = useState(false);
    const [isNameDisable, setNameDisable] = useState(true);

    const handleChangeOrientation = (value) => {
        setActiveOrientation(value);
    };
    const checkDiscardChanges = () => {
        if (inEdit && unsavedChanges) {
            setdiscardChanges(true);
        } else {
            onClose();
        }
    };

    useEffect(() => {
        if (onChangeOrientation) {
            onChangeOrientation(activeOrientation);
        }
    }, [activeOrientation, onChangeOrientation]);

    return (
        <MainBox sx={disableUnderlineColor ? { borderBottom: `1px solid ${COLORS.darkGray}`, my: 0, ...sx } : { ...sx }}>
            {breadcrumbText && (
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: backTo && breadcrumbText && searchbar ? '1.5rem' : 0 }}>
                        <BreadcrumbText onClick={checkDiscardChanges} underline="hover" color="inherit">
                            {backTo}
                        </BreadcrumbText>
                        <CurrentBreadcrumbText color="text.primary">{breadcrumbText}</CurrentBreadcrumbText>
                    </Breadcrumbs>
                </>
            )}
            <Grid container sx={{ justifyContent: 'space-between' }}>
                <LeftContentView
                    item
                    md={6}
                    sm={6}
                    xs={isXSset6 ? 6 : 12}
                    sx={{ marginBottom: { xs: '10px', md: '0px', sm: '0px' }, justifyContent: { xs: 'space-between', sm: 'start' } }}
                >
                    {!editTitleNameFlag ? (
                        <PageTitle>{title}</PageTitle>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'baseline', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                {!isNameDisable ? (
                                    <StyledEditTitleInput
                                        name={editTitleName}
                                        disableUnderline={isNameDisable}
                                        onFocus={() => setNameDisable(false)}
                                        onBlur={(e) => {
                                            setNameDisable(true);
                                            handleBlurTitle(e);
                                        }}
                                        autoFocus={!isNameDisable}
                                        onChange={handleChangeTitle}
                                        value={title}
                                    />
                                ) : (
                                    <Box onClick={() => setNameDisable(false)}>
                                        <Typography sx={{ fontSize: 26 }}>{title}</Typography>
                                    </Box>
                                )}
                                {isNameDisable && <ActionText onClick={() => setNameDisable(false)}>{'(Click to edit title)'}</ActionText>}
                            </Box>
                            <Box>
                                {touched && errors && (
                                    <FormHelperText sx={{ marginLeft: '8px' }} error>
                                        {errors}
                                    </FormHelperText>
                                )}
                            </Box>
                        </Box>
                    )}
                    {inputComponent && children}
                    {searchbar || orientationFilter || (inEdit && unsavedChanges) || dropdownList ? <VrLine sx={{ mx: 2 }} /> : null}
                    {searchbar ? (
                        <>
                            <Grid item xs={6} lg={6} sx={{ alignSelf: 'center' }}>
                                <CommonInput
                                    value={searchValue}
                                    onKeyDown={onKeyDown}
                                    handleChange={(event) => handleSearch && handleSearch(event.target.value)}
                                    name={'search'}
                                    label={'Search...'}
                                    endAdornment={<ICONS.Search />}
                                />
                            </Grid>
                        </>
                    ) : null}
                    {orientationFilter && (
                        <SetContentView>
                            <LandscapeBox
                                onClick={() => handleChangeOrientation(ORIENTATION.LANDSCAPE)}
                                style={activeOrientation === ORIENTATION.LANDSCAPE ? { backgroundColor: COLORS.primary.light } : {}}
                            />
                            <PortraitBox
                                style={activeOrientation === ORIENTATION.PORTRAIT ? { backgroundColor: COLORS.primary.light } : {}}
                                onClick={() => handleChangeOrientation(ORIENTATION.PORTRAIT)}
                                sx={{ mx: 2 }}
                            />
                            {infoOutlineIcon && <ICONS.InfoOutlinedIcon sx={{ fontSize: '1.5rem' }} />}
                        </SetContentView>
                    )}
                    {inEdit && unsavedChanges && (
                        <ViewerPermission>
                            <UnsavedChanges />
                        </ViewerPermission>
                    )}
                    {dropdownList && (
                        <CommonMultiSelect
                            outlineInputClass={outlineInputClass}
                            handleChange={(e) => handleChangeDropdown(e)}
                            options={dropdownList}
                            label={dropdownName}
                            name={dropdownName}
                            recordNotFound={recordNotFound}
                            style={{ width: { lg: '50%', md: '50%', sm: '50%', xs: '100%' } }}
                            value={dropdownValue}
                        />
                    )}
                </LeftContentView>

                {!isNotShowRightCol && (
                    <ActionContentView
                        item
                        md={6}
                        sm={6}
                        xs={isXSset6 ? 6 : 12}
                        sx={{ display: isActionContentViewHidden ? 'none' : '', height: 50, alignSelf: 'center' }}
                    >
                        {isRequest && children}
                        {(breadcrumbText || children) && showCloseButton && <CloseButton onClick={checkDiscardChanges}>Close</CloseButton>}
                        {onReset && (
                            <ResetButton onClick={onReset}>
                                <ResetIcon />
                            </ResetButton>
                        )}
                        {dropdownList && unsavedChanges && (
                            <ViewerPermission>
                                <UnsavedChanges />
                            </ViewerPermission>
                        )}
                        {componentBeforeSaveButton && componentBeforeSaveButton}
                        {customAction && customAction()}
                        {buttonLabel && (
                            <ViewerPermission>
                                <StyledButton
                                    loading={loading}
                                    onClick={onClick}
                                    variant="contained"
                                    label={buttonLabel}
                                    sx={(breadcrumbText || dropdownList || disableUnderlineColor) && showCloseButton ? { width: 120 } : {}}
                                    disabled={isSaveButtonDisabled}
                                />
                            </ViewerPermission>
                        )}
                        {!onDeleteIconVisible && onDelete && inEdit && (
                            <ViewerPermission>
                                <TrashButton onClick={onDelete}>
                                    <TrashIcon />
                                </TrashButton>
                            </ViewerPermission>
                        )}
                    </ActionContentView>
                )}
            </Grid>
            {discardChanges ? (
                <AlertDialog
                    isOpen={discardChanges}
                    isClose={() => setdiscardChanges(false)}
                    handleSubmit={onClose}
                    title={'Unsaved Changes'}
                    description={'Are you sure you want to exit without saving?'}
                    isCancel={'No'}
                />
            ) : null}
        </MainBox>
    );
};
