import * as Yup from 'yup';
import { USER_ROLE } from './constants';
export const loginValidation = Yup.object().shape({
    email: Yup.string().email('Email is not valid').required('Email is required'),
    password: Yup.string().max(255).required('Password is required')
});

export const resetPasswordValidation = Yup.object().shape({
    email: Yup.string().email('Email is not valid').required('Email is required')
});

export const newPasswordValidation = Yup.object().shape({
    password: Yup.string()
        .required('Please enter your password')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])/, 'Must contain one uppercase, one lowercase')
        .matches(/^(?=.*[!@#$%^&*])/, 'Must contain one special character')
        .matches(/^.*[0-9]+.*$/, 'Must contain one number')
        .min(8, 'Must be longer than 8 characters')
        .max(64, 'Must not be longer than 64 characters'),
    confirmPassword: Yup.string().test('equal', 'Passwords do not match!', function (v) {
        const ref = Yup.ref('password');
        return v === this.resolve(ref);
    })
});

export const verifyCodeValidation = Yup.object().shape({
    code: Yup.string().max(255).required('Code is required')
});

export const locationNameValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required')
    // .matches(/^[a-zA-Z0-9 ]+$/, 'Account name cannot have special characters')
});

export const mirrorModelValidation = Yup.object().shape({
    set_mirror: Yup.string().max(255).required('Mirror is required')
});

export const userFormValidation = Yup.object().shape({
    name: Yup.mixed().required('Name is required'),
    email: Yup.string().email('Email is not valid').max(255).required('Email is required'),
    role: Yup.string().max(255).required('Role is required'),
    accounts: Yup.mixed().when(['role'], {
        is: (role) => {
            return role !== USER_ROLE?.ADMIN;
        },
        then: Yup.array().min(1, 'Accounts in required')
    }),
    logo: Yup.mixed().required('Logo is required')
});

export const userProfileValidation = Yup.object().shape({
    name: Yup.mixed().required('Name is required'),
    email: Yup.string().email('Email is not valid').max(255).required('Email is required')
});

export const versionFormValidation = Yup.object().shape({
    version_name: Yup.mixed().required('Version name is required'),
    locations: Yup.mixed().when(['isAllSelected'], {
        is: (isAllSelected) => {
            return !isAllSelected;
        },
        then: Yup.array().min(1, 'Location is required')
    })
});

export const userCollectionValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    duration: Yup.string()
        .max(8)
        .required('Duration is required')
        .matches(/^(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)$/, 'Please enter valid video duration'),
    category_id: Yup.string().max(255).required('Category is required'),
    portrait_link_url: Yup.string().max(255).required('Portriat link is required'),
    landscape_link_url: Yup.string().max(255).required('Landscape link is required'),
    description: Yup.string().max(255).required('Description is required'),
    portrait_thumbnail: Yup.mixed().required('Portrait Thumbnail is required'),
    landscape_thumbnail: Yup.mixed().required('Landscape Thumbnail is required'),
    territory_id: Yup.array().when('territory_status', {
        is: true,
        then: Yup.array().min(1, 'Territory is required'),
        otherwise: Yup.array().nullable().default([])
    }),
    speciality_id: Yup.array().when('speciality_status', {
        is: true,
        then: Yup.array().min(1, 'Specialty is required'),
        otherwise: Yup.array().nullable().default([])
    })
});

export const groupValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    category_id: Yup.string().max(255).required('Category is required'),
    description: Yup.string().max(255).required('Description is required'),
    portrait_thumbnail: Yup.mixed().required('Portrait Thumbnail is required'),
    landscape_thumbnail: Yup.mixed().required('Landscape Thumbnail is required'),
    territory_id: Yup.array().when('territory_status', {
        is: true,
        then: Yup.array().min(1, 'Territory is required'),
        otherwise: Yup.array().nullable().default([])
    }),
    speciality_id: Yup.array().when('speciality_status', {
        is: true,
        then: Yup.array().min(1, 'Specialty is required'),
        otherwise: Yup.array().nullable().default([])
    })
});

export const accountFormValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    // .matches(/^[a-zA-Z0-9 ]+$/, 'Account name cannot have special characters'),
    type: Yup.string().max(255).required('Type is required'),
    logo_file: Yup.mixed().required('Logo is required'),
    landscape_file: Yup.mixed().required('Landscape is required'),
    portrait_file: Yup.mixed().required('Portrait is required'),
    territory_id: Yup.string().max(255).required('Territory is required'),
    speciality_id: Yup.string().max(255).required('Specialty is required')
});

export const addCategoryFormValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    type: Yup.string().max(255).required('Type is required'),
    icon: Yup.mixed().required('Icon is required'),
    territory_id: Yup.array().when('territory_status', {
        is: true,
        then: Yup.array().min(1, 'Territory is required'),
        otherwise: Yup.array().nullable().default([])
    }),
    speciality_id: Yup.array().when('speciality_status', {
        is: true,
        then: Yup.array().min(1, 'Specialty is required'),
        otherwise: Yup.array().nullable().default([])
    })
});
export const videoFormValidation = Yup.object().shape({
    directory_hd: Yup.string().max(255).required('Directory is required'),
    name: Yup.string().max(255).required('Name is required'),
    description: Yup.string().max(255).required('Description is required'),
    category: Yup.string().max(255).required('Category is required'),
    portrait_thumbnail: Yup.string().max(255).required('Portrait Thumbnail is required'),
    landscape_thumbnail: Yup.string().max(255).required('Landscape Thumbnail is required'),
    portrait_video_hd: Yup.string().max(255).required('Portrait Video is required'),
    landscape_video_hd: Yup.string().max(255).required('Landscape Video is required'),
    territory_id: Yup.array().when('territory_status', {
        is: true,
        then: Yup.array().min(1, 'Territory is required'),
        otherwise: Yup.array().nullable().default([])
    }),
    speciality_id: Yup.array().when('speciality_status', {
        is: true,
        then: Yup.array().min(1, 'Specialty is required'),
        otherwise: Yup.array().nullable().default([])
    })
});
export const editVideoFormValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    description: Yup.string().max(255).required('Description is required'),
    category: Yup.string().max(255).required('Category is required'),
    territory_id: Yup.array().when('territory_status', {
        is: true,
        then: Yup.array().min(1, 'Territory is required'),
        otherwise: Yup.array().nullable().default([])
    }),
    speciality_id: Yup.array().when('speciality_status', {
        is: true,
        then: Yup.array().min(1, 'Specialty is required'),
        otherwise: Yup.array().nullable().default([])
    })
});

export const backgroundNameValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required')
});

export const layoutNameValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required')
});

export const adminUploadContentValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    sub_folder: Yup.string().max(255).required('Sub-Folder is required'),
    video_file: Yup.mixed().required('Video File is required'),
    thumbnail: Yup.mixed().required('Thumbnail is required'),
    directory: Yup.string().max(255).required('Directory is required'),
    category: Yup.string().max(255).required('Category is required')
});
export const adminEditContentValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    thumbnail: Yup.mixed().required('Thumbnail is required'),
    sub_folder: Yup.string().max(255).required('Sub-Folder is required'),
    directory: Yup.string().max(255).required('Directory is required')
});

export const schedulingValidation = Yup.object().shape({
    startDate: Yup.date().required('Start Date is required'),
    endDate: Yup.date().required('End Date is required'),
    startTime: Yup.date().required('Start Time is required'),
    endTime: Yup.date().required('End Time is required')
});

export const appValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    category_id: Yup.string().max(255).required('Category is required'),
    description: Yup.string().max(255).required('Description is required'),
    duration_increments: Yup.mixed().required('Duration Increments is required'),
    default_duration: Yup.string().when('duration_increments', {
        is: (value) => Array.isArray(value) && value.length > 0,
        then: Yup.string().required('Default duration is required when duration increments is selected'),
        otherwise: Yup.string()
    }),
    authorization_message: Yup.string().max(255).required('Authorization Message is required'),
    authorization_image: Yup.mixed().required('Authorization Image is required'),
    portrait_thumbnail: Yup.mixed().required('Portrait Thumbnail is required'),
    landscape_thumbnail: Yup.mixed().required('Landscape Thumbnail is required'),
    max_posts: Yup.number().positive('Value must be greater than 0').required('Required')
});
export const snappicValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    category_id: Yup.string().max(255).required('Category is required'),
    description: Yup.string().max(255).required('Description is required'),
    duration_increments: Yup.mixed().required('Duration Increments is required'),
    default_duration: Yup.string().when('duration_increments', {
        is: (value) => Array.isArray(value) && value.length > 0,
        then: Yup.string().required('Default duration is required when duration increments is selected'),
        otherwise: Yup.string()
    }),
    portrait_thumbnail: Yup.mixed().required('Portrait Thumbnail is required'),
    landscape_thumbnail: Yup.mixed().required('Landscape Thumbnail is required'),
    authorization_message: Yup.string().max(255).required('Activation Message is required'),
    authorization_image: Yup.mixed().required('Image is required'),
    monthly_cost: Yup.number().when('add_on_feature', {
        is: 1,
        then: Yup.number()
            .required('Monthly Cost is required when Add-On Feature is enabled')
            .typeError('Enter Monthly Cost must be a valid number')
            .positive('Monthly Cost value must be greater than 0'),
        otherwise: Yup.number().nullable(true)
    }),
    url: Yup.string().when('embedded_link', {
        is: 1,
        then: Yup.string().required('URL is required'),
        otherwise: Yup.string()
    })
});

export const facebookAuthorizedValidation = Yup.object().shape({
    page_id: Yup.string().max(255).required('Facebook page is required'),
    total_feed: Yup.number().required('Total feed is required'),
    each_feed_time: Yup.number().min(0).max(15).required('Seconds to show each post is required')
});
export const googleAuthorizedValidation = Yup.object().shape({
    page_id: Yup.string().max(255).required('Business profile is required'),
    review_filter: Yup.string().max(255).required('Review filter is required'),
    location_id: Yup.string().max(255).required('Location is required'),
    total_feed: Yup.number().required('Total feed is required'),
    each_feed_time: Yup.number().min(0).max(15).required('Seconds to show each reviews is required')
});
export const instagramAuthorizedValidation = Yup.object().shape({
    total_feed: Yup.number().required('Total feed is required'),
    each_feed_time: Yup.number().min(0).max(15).required('Seconds to show each post is required')
});

export const snappicAuthorizedValidation = Yup.object().shape({
    duration: Yup.string().max(255).required('Duration is required'),
    url: Yup.string().when('embedded_link', {
        is: 0,
        then: Yup.string().required('URL is required'),
        otherwise: Yup.string()
    })
});

export const addRequestCategoryValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required')
});
export const addMessageValidation = Yup.object().shape({
    message: Yup.string().max(255).required('Message is required')
});

export const requestFormValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    // labels: Yup.string().max(255).required('Label is required'),
    cost: Yup.number().max(10).required('Cost is required'),
    portrait_thumbnail: Yup.mixed().required('Portrait Thumbnail is required'),
    landscape_thumbnail: Yup.mixed().required('Landscape Thumbnail is required'),
    embeded_code: Yup.string().required('Embed Code is required')
});

export const feedbackFormValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    portrait_thumbnail: Yup.mixed().required('Portrait Thumbnail is required'),
    landscape_thumbnail: Yup.mixed().required('Landscape Thumbnail is required'),
    embeded_code: Yup.string().required('Embed Code is required')
});

export const messageFormValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    business_name: Yup.string().max(255).required('Practice/Business name is required'),
    email: Yup.string().email('Email is not valid').required('Email is required'),
    phone_no: Yup.string()
        .matches(/^[0-9]{10}$/, 'Invalid phone number')
        .required('Phone number is required'),
    message: Yup.string().required('Message is required')
});

export const loopValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required')
});

export const accountTypesFormValidation = Yup.object().shape({
    name: Yup.mixed().required('Name is required')
});

export const curationProfilesValidation = Yup.object().shape({
    name: Yup.string().max(255).required('Name is required'),
    category_id: Yup.string().max(255).required('Category is required'),
    territory_id: Yup.string()
        .max(255)
        .when('territory_status', {
            is: true,
            then: Yup.string().required('Territory is required'),
            otherwise: Yup.string().nullable().default('')
        }),
    speciality_id: Yup.string()
        .max(255)
        .when('speciality_status', {
            is: true,
            then: Yup.string().required('Specialty is required'),
            otherwise: Yup.string().nullable().default('')
        })
});
