import { styled, Box, Grid, Typography, IconButton } from '@mui/material';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICONS, IMAGES } from '../../../../assets';
import { CommonInput } from '../../../../components/CommonInput';
import NoDataFound from '../../../../components/NoDataFound';
import { editPlayList } from '../../../../redux/reducers/loopSlice';
import {
    ASPECT_RATIO,
    CONTENT_DATA_TYPE,
    CONTENT_MODULE_TYPE,
    ORIENTATION,
    ORIENTATION_DATA_KEY,
    SEQUENCE_MANUAL_TAB,
    SERVER_URL
} from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative'
});

const HeaderView = styled(Box)({
    padding: 12,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.border}`
});

const HeaderText = styled(Box)({
    fontSize: 16,
    color: COLORS.black
});

const AddedContentView = styled(Box)({
    padding: 22,
    borderRight: `1px solid ${COLORS.border}`
});

const ContentItemMain = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const ContentLeftView = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const ContentRightView = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const ContentImageBox = styled(Box)({
    width: 90,
    // border: `1px solid ${COLORS.darkGray}`,
    cursor: 'pointer'
});

const ContentNameText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});

const ContentDurationText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black
});

const AddIcon = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});
const ContentImag = styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'cover'
});

export const ManualContents = ({ onClick, orientation }) => {
    const dispatch = useDispatch();
    const { loopFormData } = useSelector((state) => state.loop);
    const activePlaylist = loopFormData.activePlaylist;
    const [search, setSearch] = useState('');

    const handleAddManualContent = (item) => {
        let selectedContents = [...activePlaylist.selectedContents, { ...item, uniqId: Math.random().toString(16).slice(2) }];
        dispatch(editPlayList({ uniqId: activePlaylist?.uniqId, key: 'selectedContents', value: selectedContents }));
    };

    const handleRemoveManualContent = (item) => {
        let selectedContents = activePlaylist.selectedContents.filter((e) => e.uniqId !== item.uniqId || e.unique_id !== item.unique_id);
        dispatch(editPlayList({ uniqId: activePlaylist?.uniqId, key: 'selectedContents', value: selectedContents }));
    };

    const ContentItem = ({ isAdded, item }) => {
        return (
            <ContentItemMain key={item.id}>
                <ContentLeftView>
                    <ContentImageBox
                        style={{ width: orientation === ORIENTATION.LANDSCAPE ? 90 : 40, aspectRatio: ASPECT_RATIO[orientation] }}
                        onClick={() => onClick(item)}
                    >
                        <ContentImag
                            src={
                                item?.content_type === CONTENT_DATA_TYPE.IMAGE
                                    ? item?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                                    : `${SERVER_URL}${item?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || item?.landscape_thumbnail}`
                            }
                            sx={item?.module_type === CONTENT_MODULE_TYPE.SOCIAL && { objectFit: 'contain' }}
                        />
                    </ContentImageBox>
                    <Box sx={{ ml: 1 }}>
                        <ContentNameText>{item?.name}</ContentNameText>
                        <ContentDurationText>{`Duration ${item?.duration}`}</ContentDurationText>
                    </Box>
                </ContentLeftView>
                <ContentRightView>
                    {isAdded ? (
                        <IconButton onClick={() => handleRemoveManualContent(item)}>
                            <AddIcon src={IMAGES.RemoveCircleIcon} />
                        </IconButton>
                    ) : (
                        <IconButton>
                            <AddIcon onClick={() => handleAddManualContent(item)} src={IMAGES.AddCircleIcon} />
                        </IconButton>
                    )}
                </ContentRightView>
            </ContentItemMain>
        );
    };
    const onChangeSearch = (e) => {
        setSearch(e.target.value);
    };
    const allContents = useMemo(() => {
        if (search) {
            return activePlaylist?.allContents.filter((content) => content.name.toLowerCase().includes(search.toLowerCase()));
        } else {
            return activePlaylist?.allContents;
        }
    }, [search, activePlaylist?.allContents]);

    return (
        <MainBox>
            <Grid container>
                <Grid item sm={12} xs={12} md={6}>
                    <HeaderView sx={{ borderRight: `1px solid ${COLORS.border}` }}>
                        <HeaderText>{SEQUENCE_MANUAL_TAB.ADDED}</HeaderText>
                    </HeaderView>
                    <AddedContentView>
                        {activePlaylist &&
                            activePlaylist?.selectedContents &&
                            activePlaylist?.selectedContents?.map?.((item) => {
                                return (
                                    <Box sx={{ mt: 2 }} key={item.uniqId}>
                                        <ContentItem isAdded={true} item={item} />
                                    </Box>
                                );
                            })}
                    </AddedContentView>
                </Grid>

                <Grid item sm={12} xs={12} md={6}>
                    <HeaderView>
                        <HeaderText>{SEQUENCE_MANUAL_TAB.LINEUP}</HeaderText>
                    </HeaderView>

                    <AddedContentView>
                        <CommonInput
                            name={'search'}
                            label={'Search...'}
                            value={search}
                            handleChange={onChangeSearch}
                            endAdornment={<ICONS.Search />}
                        />
                        {allContents.length ? (
                            allContents?.map?.((item) => {
                                return (
                                    <Box sx={{ mt: 2 }} key={item.uniqId}>
                                        <ContentItem isAdded={false} item={item} />
                                    </Box>
                                );
                            })
                        ) : (
                            <NoDataFound style={{ mt: 1 }} searchText={search} message={'No such data exist.'} />
                        )}
                    </AddedContentView>
                </Grid>
            </Grid>
        </MainBox>
    );
};
