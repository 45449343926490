import { styled, Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategory } from '../../../../redux/reducers/categorySlice';
import { addNewPlaylist } from '../../../../redux/reducers/loopSlice';
import PlaylistCategoryAdjustment from '../../../../components/PlaylistCategoryAdjustment';
import { COLORS } from '../../../../utils/theme';

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    padding: 18
});
const Text = styled(Typography)({
    fontSize: 14,
    textAlign: 'center',
    color: COLORS.black
});

export const PlayLists = ({ handleCloseCustomeMenu, onDeletePlayList }) => {
    const { categorys } = useSelector((state) => state.category);
    const { loopFormData } = useSelector((state) => state.loop);

    const dispatch = useDispatch();

    useEffect(() => {
        let params = {library_content:1,status: 1}
        dispatch(fetchCategory({params}));
    }, [dispatch]);

    const addPlayList = (item) => {
        dispatch(addNewPlaylist({ ...item, index: loopFormData.playList.length }));
    };

    return (
        <>
            <Text sx={{ mt: 1 }}>Adjust the number and category of Playlists included in the Loop</Text>
            <MainBox>
                {categorys?.map((item) => {
                    return (
                        <PlaylistCategoryAdjustment
                            item={item}
                            key={item.id}
                            addPlayList={addPlayList}
                            onDeletePlayList={onDeletePlayList}
                            disableBlankPlaylist={true}
                        />
                    );
                })}
            </MainBox>
        </>
    );
};
