import React, { useState } from 'react';
import { TableContainer, Table, TableBody, TableRow, TableCell, TablePagination, Box, styled, Checkbox } from '@mui/material';
import { CustomTableHead } from './components/CustomTableHead';
import { filter } from 'lodash';
import { COLORS } from '../../utils/theme';
import Loader from '../Loader';
import { toCapitalCase } from '../../utils/helpers';
import { ICONS } from '../../assets';
import NoDataFound from '../NoDataFound';

const TableBox = styled(Box)({
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.border}`,
    borderRadius: '0.425rem',
    padding: 20
});

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }

    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query && query.search) {
        return filter(array, (_user) =>
            query.fields.find((item) => _user[item]?.toString?.().toLowerCase().indexOf(query.search.toLowerCase()) !== -1)
        );
    }
    return stabilizedThis?.map((el) => el[0]);
}

export const CommonTable = ({
    columns,
    rows,
    handleRowDetail,
    search,
    searchableFields,
    loading,
    selectable,
    actions,
    rowsPadding,
    onDragStart,
    draggable,
    onDrop,
    onDragOver,
    onDragEnd,
    isNotDefaultSorted,
    rowsPerPageOptions,
    defaultRowsPerPage
}) => {
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState(isNotDefaultSorted ? '' : 'asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState(isNotDefaultSorted ? '' : 'name');

    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 10);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows?.map?.((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const handleSelectedRows = (id) => {
        let selectedClone = [...selected];
        let index = selectedClone.findIndex((item) => item === id);
        if (index > -1) {
            selectedClone.splice(index, 1);
        } else {
            selectedClone = [...selectedClone, id];
        }
        setSelected(selectedClone);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const filteredUsers = applySortFilter(rows, getComparator(order, orderBy), { fields: searchableFields || [], search: search });

    const isNotFound = !filteredUsers?.length && search;
    const isLoading = !rows?.length && loading;
    return (
        <TableContainer component={TableBox}>
            <Table sx={{ minWidth: 650 }}>
                <CustomTableHead {...{ isNotDefaultSorted }}
                    selectable={selectable}
                    actions={actions}
                    columns={columns}
                    order={order}
                    orderBy={orderBy}
                    rowCount={rows?.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                    {filteredUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                        return (
                            <TableRow
                                hover
                                key={row.id}
                                tabIndex={-1}
                                role="checkbox"
                                onClick={() => handleRowDetail && handleRowDetail(row)}
                                draggable={draggable}
                                onDragStart={(e) => onDragStart(e, row.id)}
                                onDrop={(e) => onDrop(e, row.id)}
                                onDragOver={onDragOver}
                                onDragEnd={onDragEnd}
                            >
                                {selectable && (
                                    <TableCell sx={{ px: 1, py: 0 }}>
                                        <Checkbox
                                            style={{ color: selected.includes(row.id) ? COLORS.primaryBlue : COLORS.border }}
                                            checked={selected.includes(row.id)}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSelectedRows(row.id);
                                            }}
                                        />
                                    </TableCell>
                                )}

                                {columns?.map((column) => {
                                    return (
                                        <TableCell align={column.align || 'left'} key={column.id} sx={{ px: rowsPadding || 4, py: 2 }}>
                                            {column.render
                                                ? column.render({ rowData: row, rowIndex: index })
                                                : row[column.fieldName]
                                                    ? toCapitalCase(`${row[column.fieldName]}`)
                                                    : '-'}
                                        </TableCell>
                                    );
                                })}
                                {actions && (
                                    <TableCell sx={{ px: 1, py: 0 }} align={'center'}>
                                        <ICONS.MoreVertSharpIcon style={{ height: 20, width: 20 }} />
                                    </TableCell>
                                )}
                            </TableRow>
                        );
                    })}
                </TableBody>

                {isNotFound && (
                    <TableBody>
                        <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 1 }}>
                                <NoDataFound searchText={search} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
            {isLoading && <Loader isLoading={isLoading} />}

            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions || [5, 10, 25]}
                component="div"
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    );
};
