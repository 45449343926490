import { Box, Button, Grid, styled, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { api } from '../../../api';
import { CommonInput } from '../../../components/CommonInput';
import { CommonMultiSelect } from '../../../components/CommonMultiSelect';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { LayoutBuilderWidget } from '../../../widgets/LayoutBuilderWidget';
import { COLORS } from '../.././../utils/theme';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import { layoutNameValidation } from '../../../utils/validations';
import { IMAGES } from '../../../assets';
import { INITIAL_ZONES } from '../../../utils/constants';

const ContaineBox = styled(Grid)({
    backgroundColor: COLORS.white,
    marginTop: 24,
    borderRadius: '0.425rem',
    border: `1px solid ${COLORS.border}`
});

const PropertiesGrid = styled(Grid)({
    padding: 12,
    borderRight: `1px solid ${COLORS.darkGray}`,
    flexDirection: 'column'
});

const ZonePreviewGrid = styled(Grid)({
    padding: 28
});

const PropertiesText = styled(Typography)({
    fontSize: 18,
    textAlign: 'center'
});
const Text = styled(Typography)({
    fontSize: 14,
    textAlign: 'center',
    fontStyle: 'italic',
    color: COLORS.darkGray
});

const StyledInput = styled(CommonInput)(({ theme }) => ({}));

const FieldView = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
});

const FieldLabel = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    color: COLORS.black
}));
const CategoryTitle = styled(Typography)(({ theme }) => ({
    fontSize: 16,
    color: COLORS.black,
    fontWeight: 'bold',
    marginTop: 22
}));
const CenteredLayoutBuilder = styled(Box)({
    display: 'flex',
    justifyContent: 'center'
});
const ZoneAddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});

const ZoneFieldLabel = styled(Typography)({
    fontSize: 16,
    fontWeight: 'bold',
    color: COLORS.black,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 8
});

const zoneTypes = [
    {
        id: 'background',
        name: 'Background',
        value: 'background'
    },
    {
        id: 'ticker',
        name: 'Ticker',
        value: 'ticker'
    },
    {
        id: 'content',
        name: 'Content',
        value: 'content'
    },
    {
        id: 'pass-through',
        name: 'Pass Through',
        value: 'pass-through'
    }
];

export const AddLayout = () => {
    const { id } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [zones, setZones] = useState([]);
    const [activeZone, setActiveZone] = useState();
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);

    const { data: layoutData } = useFetch({
        initialUrl: `/get_layout_admin_by_id`,
        config: { params: { id: id } },
        skipOnStart: id ? false : true
    });
    const editData = layoutData?.result;

    const zoneTypeList = useMemo(() => {
        if (zones.length > 0) {
            return zoneTypes.map((item => {
                let index = zones.findIndex(value => value.content === item.id)
                return { ...item, disabled: index < 0 ? false : true };
            }))
        } else {
            return zoneTypes;
        }
    }, [zones])

    useEffect(() => {
        if (editData) {
            setZones(editData?.zones);
            if (editData?.zones[0]) {
                setActiveZone(editData?.zones[0].id);
            }
            setValues({
                name: editData?.name,
                x_axis: editData?.zones[0]?.x_axis,
                y_axis: editData?.zones[0]?.y_axis,
                width: editData?.zones[0]?.width,
                height: editData?.zones[0]?.height,
                content: editData?.zones[0]?.content,
                status: Boolean(editData?.status)
            });
        }
    }, [editData]);

    const { handleBlur, handleChange, values, setValues, touched, errors, handleSubmit, setFieldValue } = useFormik({
        initialValues: { unSavedChange: false, status: true, name: '' },
        validationSchema: layoutNameValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                let result;
                let params = {
                    name: values.name,
                    orientation: state.orientation,
                    status: Number(values.status)
                };
                if (id) {
                    params['id'] = id;
                    params['zones'] = zones.map((item) => {
                        return {
                            id: item.id,
                            content: item.content,
                            height: item.height,
                            width: item.width,
                            x_axis: item.x_axis,
                            y_axis: item.y_axis
                        };
                    });
                    const { data } = await api.layout.edit(params);
                    result = data;
                } else {
                    params['zones'] = zones.map((item) => {
                        return { content: item.content, height: item.height, width: item.width, x_axis: item.x_axis, y_axis: item.y_axis };
                    });

                    const { data } = await api.layout.add(params);
                    result = data;
                }
                if (result.success) {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        const index = zones.findIndex((item) => item.id === activeZone);
        let tempZone = [...zones];
        tempZone[index] = { ...tempZone[index], ...values };
        setZones([...tempZone]);
    }, [values]);

    const checkUnsaveChanges = () => {
        !values.unSavedChange && setFieldValue('unSavedChange', true);
    };
    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };

    const handleDeleteZone = (id) => {
        let updatedZones = [...zones];
        updatedZones = updatedZones.filter((item) => item.id !== id);
        setZones([...updatedZones]);
        id && setFieldValue('unSavedChange', true);
    };

    const handleAddZone = () => {
        let type = zoneTypes.filter((e) => zones.findIndex((zone) => zone.content === e.value) === -1)[0]?.value;
        let initialZoneValue = INITIAL_ZONES[state.orientation].find((zone) => zone.content === type);
        let zoneObject = {
            id: zones.length + 1,
            height: initialZoneValue.height,
            width: initialZoneValue.width,
            x_axis: initialZoneValue.x_axis,
            y_axis: initialZoneValue.y_axis,
            content: type
        };

        setZones([...zones, zoneObject]);
        id && setFieldValue('unSavedChange', true);
    };

    const handleClickOnZone = (item) => {
        setActiveZone(item.id);
        setValues({ ...values, ...item });
    };

    const handleDelete = async () => {
        try {
            const { data } = await api.layout.deleteAdminLayout(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <Box>
            <CommonHeader
                loading={loading}
                title={'Layout'}
                onClick={handleSubmit}
                buttonLabel={'Save'}
                inEdit={id ? true : false}
                onDelete={() => setDeletePopup(true)}
                breadcrumbText={id ? 'Edit' : 'Add'}
                backTo={'Layouts'}
                onClose={() => navigate(-1)}
                unsavedChanges={values.unSavedChange}
            />
            <ContaineBox container>
                <PropertiesGrid item xs={12} md={4} lg={3}>
                    <PropertiesText>PROPERTIES</PropertiesText>
                    <CategoryTitle>Name:</CategoryTitle>
                    <FieldView>
                        <StyledInput
                            handleChange={checkHandlechange}
                            handleBlur={handleBlur}
                            name={'name'}
                            value={values.name}
                            error={touched.name && errors.name}
                            type={'name'}
                        />
                    </FieldView>
                    <CategoryTitle>Type:</CategoryTitle>
                    <CommonMultiSelect
                        handleBlur={handleBlur}
                        handleChange={checkHandlechange}
                        options={zoneTypeList}
                        error={touched.content && errors.content}
                        name={'content'}
                        value={values.content}
                        outlineInputClass={{ top: 0 }}
                        style={{ borderRadius: '0.425rem', marginTop: 8 }}
                    />
                    <CategoryTitle>Size:</CategoryTitle>
                    <Grid container spacing={1}>
                        <Grid item md={12} sm={6} xs={6}>
                            <FieldView>
                                <FieldLabel>Width(%)</FieldLabel>
                                <StyledInput
                                    type="number"
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    name={'width'}
                                    value={values.width}
                                />
                            </FieldView>
                        </Grid>
                        <Grid item md={12} sm={6} xs={6}>
                            <FieldView>
                                <FieldLabel>Height(%)</FieldLabel>
                                <StyledInput
                                    type="number"
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    name={'height'}
                                    value={values.height}
                                />
                            </FieldView>
                        </Grid>
                    </Grid>

                    <CategoryTitle>Position:</CategoryTitle>

                    <Grid container spacing={1}>
                        <Grid item md={12} sm={6} xs={6}>
                            <FieldView>
                                <FieldLabel>Y-Axis(%)</FieldLabel>
                                <StyledInput
                                    type="number"
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    name={'y_axis'}
                                    value={values.y_axis}
                                />
                            </FieldView>
                        </Grid>

                        <Grid item md={12} sm={6} xs={6}>
                            <FieldView>
                                <FieldLabel>X-Axis(%)</FieldLabel>
                                <StyledInput
                                    type="number"
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    name={'x_axis'}
                                    value={values.x_axis}
                                />
                            </FieldView>
                        </Grid>
                    </Grid>
                    <FieldView sx={{ flexDirection: 'row', mt: 3 }}>
                        <CategoryTitle sx={{ mt: 0 }}>Client Side Status:</CategoryTitle>
                        <IOSSwitch
                            sx={{ mx: 1 }}
                            onChange={(e) => {
                                setFieldValue('status', e.target.checked);
                                id && setFieldValue('unSavedChange', true);
                            }}
                            checked={values.status}
                        />
                    </FieldView>
                    <Box sx={{ mt: 4, display: 'flex', flexDirection: { xs: 'column', xxl: 'row' }, alignItems: 'start' }}>
                        <Button
                            onClick={() => {
                                handleAddZone();
                            }}
                            disabled={zones.length === 4}
                            sx={{ opacity: zones.length === 4 ? 0.3 : 1 }}
                        >
                            <ZoneAddImage src={IMAGES.AddCircleIcon} />
                            <ZoneFieldLabel>Add Zone</ZoneFieldLabel>
                        </Button>
                        <Button
                            disabled={!activeZone}
                            sx={{ ml: { xs: 0, xxl: 3 }, opacity: activeZone ? 1 : 0.3 }}
                            onClick={() => {
                                handleDeleteZone(activeZone);
                            }}
                        >
                            <ZoneAddImage src={IMAGES.RemoveCircleIcon} />
                            <ZoneFieldLabel>Remove Zone</ZoneFieldLabel>
                        </Button>
                    </Box>
                    {zones.length === 4 && <Text sx={{ mt: 3 }}>Zone limit reached. No more than 4 zones permitted.</Text>}
                </PropertiesGrid>

                <ZonePreviewGrid item xs={12} md={8} lg={9} alignSelf={'center'} justifySelf={'center'}>
                    <CenteredLayoutBuilder>
                        <LayoutBuilderWidget
                            activeZone={activeZone}
                            handleClickOnZone={handleClickOnZone}
                            zones={zones}
                            orientation={state.orientation}
                            showName={true}
                        />
                    </CenteredLayoutBuilder>
                </ZonePreviewGrid>
            </ContaineBox>
            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={handleDelete}
                    title={'Delete Layout'}
                    description={'Are you sure you want to delete this Layout ?'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
