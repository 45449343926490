import { Box, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets';
import { LoopPreview } from '../../../components/LoopPreview/LoopViewWithDiv';
import { CommonTable } from '../../../components/Table';
import { fetchLoopTemplate } from '../../../redux/reducers/loopSlice';
import { COLORS } from '../../../utils/theme';
import { PAGES } from '../../../utils/urls';
import { CommonHeader } from '../../../widgets/CommonHeader';

const AccountName = styled(Typography)({
    fontSize: 16,
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left',
    width: '100%'
});

export const Loops = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { adminTemplate } = useSelector((state) => state.loop);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchLoopTemplate()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);
    const loopColumns = [
        {
            id: 'templates',
            fieldName: 'templates',
            label: 'Templates',
            render: ({ rowData }) => {
                return <AccountName>{rowData.name}</AccountName>;
            }
        },
        {
            id: 'loop_categories',
            fieldName: 'loop_categories',
            label: 'Playlists',
            style: {
                justifyContent: 'flex-end'
            },
            render: ({ rowData }) => {
                return (
                    <LoopPreview
                        chartData={rowData?.loop_categories?.map?.((item) => {
                            return {
                                color: item?.playlist?.color,
                                value: 1,
                                label: 'PL'
                            };
                        })}
                    />
                );
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'status',
            fieldName: 'status',
            label: 'Status',
            render: ({ rowData }) => {
                return (
                    <>
                        {rowData.status ? (
                            <ICONS.CheckCircleIcon variant="contained" style={{ color: 'green' }} />
                        ) : (
                            <ICONS.CancelIcon style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
            style: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];

    return (
        <Box>
            <CommonHeader title={'Loops'} isXSset6={true} buttonLabel={'Add Template'} onClick={() => navigate(PAGES.ADD_LOOP.url)} />
            <Box sx={{ marginTop: '24px' }}>
                <CommonTable
                    handleRowDetail={(data) => navigate(`${PAGES.EDIT_LOOP.url}/${data.id}`)}
                    columns={loopColumns}
                    rows={adminTemplate}
                    searchableFields={['templates', 'playlists', 'status']}
                    loading={isLoading}
                />
            </Box>
        </Box>
    );
};
