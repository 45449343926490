import { Box, Grid, Button, styled, useMediaQuery, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'
import AddVideoModal from './components/AddVideoModal';
import EditVideoModal from './components/EditVideoModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { ORIENTATION, ORIENTATION_DATA_KEY, durations, specialty, topics } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import NoDataFound from '../../../components/NoDataFound';
import { CollectionCardWidgets } from '../../../widgets/CollectionCardWidgets';
import { COLORS } from '../../../utils/theme';
import CustomFilters from '../../../widgets/CustomFilters';
import { changeCase } from '../../../utils/helpers';
import { fetchCategory } from '../../../redux/reducers/categorySlice';
import { fetchVideos, clearFilterData, handleFilter, handleVideosEnabled } from '../../../redux/reducers/videoSlice';
import Loader from '../../../components/Loader';

const FilterContainer = styled(Box)({
    margin: '12px 0px',
    display: 'flex',
    flexWrap: 'wrap'
});
const VideoHeaderComponent = styled(Box)(({ matchUpMd }) => ({
    position: 'fixed',
    paddingTop: 24,
    left: matchUpMd ? 268 : 0,
    top: 54,
    width: `calc(100% - ${matchUpMd ? '284px' : '0px'})`,
    background: COLORS.background,
    paddingLeft: '6px',
    paddingRight: '6px',
    zIndex: 999
}))
const ClearButton = styled(Button)({
    background: COLORS.white,
    display: 'flex',
    textDecoration: 'underline',
    alignItems: 'center',
    // border: `1px solid ${COLORS.border}`,
    borderRadius: '0.425rem',
    fontSize: `14px !important`,
    color: `${COLORS.primary.light}!important`,
    fontWeight: 100,
    '&.MuiButtonBase-root:hover': {
        '&:hover': {
            textDecoration: 'underline',
            background: COLORS.white,
            opacity: 1
        }
    }
})
const LoadVideoContainer = styled(Box)(({ matchUpMd }) => ({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `calc(100vh - ${matchUpMd ? '110px' : '150px'})`
}))
const LoadVideosButton = styled(Box)({
    fontWeight: 500,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: COLORS.primary.light,
    fontSize: `18px !important`,
    background: COLORS.background,
    '&:hover': {
        textDecoration: 'underline',
        opacity: 1
    }

})

const SearchText = styled(Typography)({
    display: 'flex',
    margin: '0 4px',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 400,
    '& span': {
        marginLeft: 4,
        fontWeight: 600,
    }
})

const CollectionVideoGrid = styled(Grid)(({ matchUplg, matchUpMd }) => ({
    paddingTop: matchUplg ? 116 : matchUpMd ? 116 : window.innerWidth <= 507 ? 234 : 194
}));

const initialState = {
    category: [],
    topic: [],
    duration: [],
    specialty: []
}

export const Videos = () => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUplg = useMediaQuery(theme.breakpoints.up('lg'));
    const [openVideoModal, setVideoModal] = useState({ isOpen: false, type: '' });
    const [counts, setCounts] = useState(initialState);
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const { categorys } = useSelector((state) => state.category);
    const { videos, responseCount, videoFilter, videosEnabled } = useSelector((state) => state.video);
    const [paginate, setPaginate] = useState({ page: 1, isCleared: true });
    const [loaders, setLoaders] = useState({
        mainLoader: true,
        subLoader: true
    });
    const activeOrientation = ORIENTATION.LANDSCAPE

    const fetchAllVideos = useCallback(async () => {
        let params = {
            video: 1,
            check_host_url: 1,
            limit: 72
        }
        const isFilteredExist = Object.keys(videoFilter)?.length
        if (isFilteredExist) {
            const filterParams = { ...videoFilter?.[activeOrientation] }
            if (filterParams['category'] && filterParams['category']?.length) {
                params['multiple_category'] = JSON.stringify(filterParams['category'] || [])
            }
            if (filterParams['search'] && filterParams['search']?.length) {
                params['search'] = filterParams['search']
            }
            if (filterParams['duration'] && filterParams['duration']?.length) {
                params['multiple_duration'] = JSON.stringify(filterParams['duration'] || [])
            }
            if (filterParams?.['specialty']?.length || filterParams?.['topic']?.length) {
                params['multiple_tag'] = JSON.stringify([...filterParams?.['specialty'], ...filterParams?.['topic']])
            }
        }
        if (videosEnabled) {
            setLoaders({ mainLoader: paginate.isCleared, subLoader: !paginate.isCleared })
            await dispatch(fetchVideos({ ...params, page: paginate.page, isExec: paginate.isCleared })).then(() => {
                setLoaders({
                    mainLoader: false, subLoader: false
                })
            })
        }
    // eslint-disable-next-line
    }, [dispatch, activeOrientation, videoFilter, paginate, videosEnabled])

    useEffect(() => {
        fetchAllVideos().then(() => {
            setSearchValue(videoFilter?.[activeOrientation]?.search || '')
        })      
    },[activeOrientation, fetchAllVideos, videoFilter])

    const handlePagination = () => {
        if (loaders.mainLoader || loaders.subLoader) return
        setPaginate({
            ...paginate, page: paginate.page + 1, isCleared: false
        })
    }

    useEffect(() => {
        dispatch(fetchCategory({ params: { status: 1 } }));
    }, [dispatch]);

    const categories = useMemo(() => {
        return categorys?.map(({ id, name }) => ({ id, label: name }));
    }, [categorys]);

    const updateCategories = useMemo(() => {
        const clone = [...categories]
        return clone?.filter(item => changeCase(item?.label) !== 'CREATOR')
    }, [categories])

    const handleData = (value, data = []) => {
        const array = [...data];
        if (array.includes(value)) {
            const index = array.findIndex((val) => val === value);
            array.splice(index, 1);
        } else array.push(value);
        return array;
    };

    const handleChangeAction = (item, mainId) => {
        const { id } = item;
        setCounts({
            ...counts,
            [mainId]: handleData(id, counts[mainId])
        });
    };

    const isApplyFilters = useMemo(() => {
        const data = { ...videoFilter?.[activeOrientation] }
        return Boolean(Object.values(data)?.map(val => val?.length)?.reduce((value, prev) => value + prev, 0))
    }, [videoFilter, activeOrientation])

    const clearFilter = useCallback(() => {
        dispatch(clearFilterData({}))
        setPaginate({ ...paginate, page: 1, isCleared: true })
        setCounts({ ...initialState })
        setSearchValue('')
    }, [dispatch, paginate])

    const handleApply = useCallback((id) => {
        dispatch(handleFilter({
            ...videoFilter, [activeOrientation]: {
                ...initialState, ...videoFilter?.[activeOrientation], [id]: counts[id] || [],
            }
        }))
        setPaginate({
            ...paginate, page: 1, isCleared: true
        })
        dispatch(handleVideosEnabled())
    }, [dispatch, videoFilter, activeOrientation, counts, paginate])

    const handleKeyPress = e => {
        if(e.charCode === 13 || e.key === 'Enter'){
            dispatch(handleFilter({
                ...videoFilter, [activeOrientation]: {
                    ...videoFilter[activeOrientation], search: searchValue
                }
            }))
            setPaginate({
                ...paginate, page: 1, isCleared: true
            })
            dispatch(handleVideosEnabled())
        }
    }

    return (
        <Box sx={{ position: 'relative' }} >
            <VideoHeaderComponent matchUpMd={matchUpMd} >
                <CommonHeader
                    searchbar={true}
                    title={'Videos'}
                    buttonLabel={'Add Video'}
                    onClick={() => setVideoModal({ isOpen: true, type: 'add' })}
                    searchValue={searchValue}
                    handleSearch={(search) => {
                        setSearchValue(search)
                    }}
                    onKeyDown={handleKeyPress}
                />
                <FilterContainer>
                    <CustomFilters
                        {...{ handleChangeAction, handleApply, checked: counts['category'], options: updateCategories }}
                        id={'category'}
                        count={videoFilter?.[activeOrientation]?.['category']?.length}
                        label={'Category'}
                    />
                    <CustomFilters
                        {...{ handleChangeAction, handleApply, checked: counts['specialty'], options: specialty }}
                        id={'specialty'}
                        count={videoFilter?.[activeOrientation]?.['specialty']?.length}
                        sx={{ ml: 1 }}
                        label={'Specialty'}
                    />
                    <CustomFilters
                        {...{ handleChangeAction, handleApply, checked: counts['topic'], options: topics }}
                        id={'topic'}
                        count={videoFilter?.[activeOrientation]?.['topic']?.length}
                        sx={{ ml: 1 }}
                        label={'Topic'}
                    />
                    <CustomFilters
                        {...{ handleChangeAction, handleApply, checked: counts['duration'], options: durations }}
                        id={'duration'}
                        sx={{ ml: 1 }}
                        count={videoFilter?.[activeOrientation]?.['duration']?.length}
                        label={'Duration'}
                    />
                    { videoFilter?.[activeOrientation]?.search ? <SearchText>
                        Search for <span>"{videoFilter?.[activeOrientation]?.search}"</span>
                    </SearchText> : null }
                    {isApplyFilters ? <ClearButton onClick={() => clearFilter(activeOrientation)} sx={{ ml: 1 }} >Clear Filters</ClearButton> : null}
                </FilterContainer>
            </VideoHeaderComponent>
            <Box sx={{ marginTop: '24px' }} />
            {
                videosEnabled ? <CollectionVideoGrid {...{ matchUpMd, matchUplg }} container spacing={3}>
                    {
                        loaders.mainLoader ? Array(12).fill(1).map((skeleton) => {
                            return (
                                <Grid item key={skeleton} xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <CollectionCardWidgets key={skeleton} hideToggleSwitch={true} hasStatus={true} loading={loaders.mainLoader} />
                                </Grid>
                            );
                        }) : null
                    }
                    {videos?.length && !loaders.mainLoader ? videos?.map((video) => {
                        return (
                            <Grid key={video.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <CollectionCardWidgets
                                    key={video.id}
                                    hideToggleSwitch={true}
                                    hasStatus={true}
                                    onClick={() =>
                                        setVideoModal({
                                            isOpen: true,
                                            type: 'edit',
                                            id: video?.id,
                                            fileExist: {
                                                landscape: video?.landscape_file_exist,
                                                portrait: video?.portrait_file_exist
                                            }
                                        })
                                    }
                                    value={video.status}
                                    data={video}
                                    availibilityCheck={{
                                        file_exist:
                                            (video[ORIENTATION_DATA_KEY?.file_exist?.[ORIENTATION.LANDSCAPE]] ||
                                                video[ORIENTATION_DATA_KEY?.file_exist_hd?.[ORIENTATION.LANDSCAPE]]) &&
                                            (video[ORIENTATION_DATA_KEY?.file_exist?.[ORIENTATION.PORTRAIT]] ||
                                                video[ORIENTATION_DATA_KEY?.file_exist_hd?.[ORIENTATION.PORTRAIT]])
                                    }}
                                />
                            </Grid>
                        );
                    }) : null}
                    {!videos?.length && !loaders.mainLoader && <Grid item xs={12}>
                        <NoDataFound searchText={searchValue} message={'No such data exist.'} />
                    </Grid>}
                    {
                        loaders.subLoader ? <Grid sx={{ paddingLeft: '24px' }} xs={12}>
                            <Loader sx={{ width: 80, height: 80 }} isLoading={loaders.subLoader} />
                        </Grid>
                            : responseCount === 72 && !loaders.subLoader && !loaders.mainLoader && <Grid item xs={12}>
                                <LoadVideosButton sx={{ display: 'flex', justifyContent: 'center' }} onClick={handlePagination} >Load Videos</LoadVideosButton>
                            </Grid>
                    }
                </CollectionVideoGrid> : <LoadVideoContainer item xs={12}>
                    <LoadVideosButton onClick={() => {
                         dispatch(handleVideosEnabled())
                    }} >Load Videos</LoadVideosButton>
                </LoadVideoContainer>
            }
            <AddVideoModal
                {...{ paginate, setPaginate }}
                open={openVideoModal.isOpen && openVideoModal.type === 'add'}
                type={openVideoModal.type}
                handleClose={() => setVideoModal({ isOpen: false, type: '' })}
            />
            {openVideoModal.isOpen && openVideoModal.type === 'edit' && (
                <EditVideoModal {...{ paginate, setPaginate }}
                    open={openVideoModal.isOpen && openVideoModal.type === 'edit'}
                    type={openVideoModal.type}
                    handleClose={() => setVideoModal({ isOpen: false, type: '' })}
                    id={openVideoModal?.id}
                    fileExist={openVideoModal.fileExist}
                />
            )}
        </Box>
    );
};
