import { Slider } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { stopPropagation } from '../../redux/reducers/editorSlice';

const CommonRangePicker = ({ size, handleChange, ...props }) => {
    const dispatch = useDispatch();
    return (
        <Slider
            {...props}
            onChange={(e) => {
                handleChange(e);
                e.stopPropagation();
                e.stopImmediatePropagation();
            }}
            size={size || 'small'}
            defaultValue={props.value}
            aria-label="range-picker"
            valueLabelDisplay="auto"
        />
    );
};

export default CommonRangePicker;
