import { Box, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ASPECT_RATIO, SERVER_URL } from '../../utils/constants';
import YouTube from 'react-youtube';
import { IMAGES } from '../../assets';
import { getYouTubeIdFromUrl } from '../../utils/helpers';
import Loader from '../Loader';

const MainBox = styled(Box)({
    position: 'relative'
});

const PlaceHolderImageVier = styled(Box)({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0
});

const ThumbnailImage = styled('img')({
    height: '100%',
    width: '100%',
    position: 'absolute'
});

const PlayButtonView = styled(Box)({
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.3)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
});
const PlayIcon = styled('img')({
    height: 40,
    width: 40
});

export const YoutubePlayer = ({ orientation, thumbnail, videoUrl, width }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [playerOptions, setPlayerOptions] = useState();
    const [playerEvent, setPlayerEvent] = useState();
    const playerRef = useRef();
    const youtubeId = getYouTubeIdFromUrl(videoUrl);

    useEffect(() => {
        const mainBox = document.getElementById('main-vimeo-box');
        const options = {
            width: mainBox.offsetWidth,
            height: mainBox.offsetHeight,
            playerVars: {
                autoplay: 1
            },
            autoplay: true
        };

        setPlayerOptions(options);
    }, []);

    const handlePlayVideo = () => {
        setIsPlaying(true);
        playerEvent.target.playVideo();
    };

    const onReady = (event) => {
        event.target.pauseVideo();
        setPlayerEvent(event);
    };
    const onEnd = () => {
        setIsPlaying(false);
    };
    return (
        <MainBox id="main-vimeo-box" style={{ width: width || '100%', aspectRatio: ASPECT_RATIO[orientation] }}>
            {playerOptions && <YouTube ref={playerRef} videoId={youtubeId} opts={playerOptions} onReady={onReady} onEnd={onEnd} />}

            {!isPlaying && (
                <PlaceHolderImageVier onClick={handlePlayVideo}>
                    {playerEvent ? (
                        <>
                            <ThumbnailImage src={SERVER_URL + thumbnail} />
                            <PlayButtonView>
                                <PlayIcon src={IMAGES.PlayIcon} alt="play" />
                            </PlayButtonView>
                        </>
                    ) : (
                        <Loader isLoading={true} />
                    )}
                </PlaceHolderImageVier>
            )}
        </MainBox>
    );
};
