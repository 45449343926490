import React, { useState } from 'react';
import { Card, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import { COLORS } from '../../../utils/theme';
import { CommonInput } from '../../../components/CommonInput';
import CommonButton from '../../../components/CommonButton';
import { PublicLayout } from '../../../layout/PublicLayout';
import { api } from '../../../api';
import { resetPasswordValidation } from '../../../utils/validations';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { PAGES } from '../../../utils/urls';
import FieldLabelView from '../../../components/FieldLabelView';
import { ICONS } from '../../../assets';

const MainCard = styled(Card)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '2vh',
    padding: '40px 0px',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: 20,
    borderWidth: 5,
    borderColor: 'red'
});

const MainForm = styled('form')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});

const LinkButton = styled(Link)({
    color: COLORS.vividYellow,
    fontWeight: 'normal',
    cursor: 'pointer',
    textDecoration: 'none',
    fontSize: 14
})

const LabelText = styled(Typography)(({ theme }) => ({
    padding: '12px 18px',
    textAlign: 'center',
    color: COLORS.darkShadesGray,
    fontSize: '0.85rem'
}));
const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}));
const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: 100,
    borderRadius: 0,
    width: '50%'
});
const HeaderText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: COLORS.black,
    fontWeight: '400',
    fontSize: '1.7rem'
}));
const HorizontalSpacing = styled(Box)(({ theme }) => ({
    width: '80%'
}));
const StyledInput = styled(CommonInput)(({ theme }) => ({
    marginBottom: 15
}));

const InputBox = styled(Box)({
    width: '100%'
});

const ResetPasswordForm = () => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { handleBlur, handleChange, handleSubmit, values, touched, errors } = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: resetPasswordValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const orgin = window.origin;
                const { data } = await api.auth.resetPassword({
                    params: { email: values.email, resetUrl: `${orgin}${PAGES.NEW_PASSWORD.url}` }
                });

                if (data.success) {
                    enqueueSnackbar(data.message, { variant: 'success' });
                    navigate(PAGES.LOGIN.url);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message || error.message, { variant: 'error' });
            }
        }
    });

    return (
        <PublicLayout>
            <MainCard>
                <MainForm onSubmit={handleSubmit}>
                    <HorizontalSpacing>
                        <HeaderText>Reset your password</HeaderText>
                        <LabelText>Enter the email address associated with your account.</LabelText>
                        <FieldLabelView sx={{ mt: 2 }}>
                            <InputBox>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    name={'email'}
                                    value={values?.email}
                                    placeholder="Email"
                                    error={touched.email && errors.email}
                                    type={'email'}
                                    startAdornment={
                                        <ICONS.Email sx={{ color: `${COLORS.primary.light}`, fontSize: '20px', marginRight: '4px' }} />
                                    }
                                />
                            </InputBox>
                        </FieldLabelView>
                        <ButtonContainer>
                            <StyledButton
                                sx={{ mt: 2 }}
                                key="resetpassword-Button"
                                id="resetpassword-Button"
                                onClick={handleSubmit}
                                loading={loading}
                                variant="contained"
                                label={'Submit'}
                                title={'resetpassword'}
                                type={'submit'}
                            />
                        </ButtonContainer>
                    </HorizontalSpacing>
                </MainForm>
            </MainCard>
            <ButtonContainer sx={{ width: '100%', justifyContent: 'space-between' }}>
                <CommonButton
                    sx={{ color: COLORS.vividYellow, fontWeight: 'normal' }}
                    key="backToLogin-Button"
                    id="backToLogin-Button"
                    label={'Back to sign in'}
                    title={'backToLogin'}
                    onClick={() => navigate(PAGES.LOGIN.url)}
                />
                <LinkButton target='_blank' to={'https://help.dsid.com/article/57-account-access'}
                >Help</LinkButton>
            </ButtonContainer>
        </PublicLayout>
    );
};
export default ResetPasswordForm;
