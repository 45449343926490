import React, { useEffect } from 'react';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { MENU_ITEM } from '../../../../utils/urls';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientAccess } from '../../../../redux/reducers/authSlice';
import { getAccessObjectFromArrayObject } from '../../../../utils/helpers';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { user, access } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.client) {
            dispatch(fetchClientAccess({ params: { client_id: user?.client?.id } }));
        }
    }, [user?.client, dispatch]);

    const checkGroupVisibility = (accessItem, navItem) => {
        if (accessItem) {
            if (accessItem[navItem?.accessKey]) {
                return accessItem[navItem?.accessKey]?.visibility && accessItem?.[navItem?.accessKey]?.[navItem?.globalAccessKey];
            } else return accessItem[navItem?.accessKey]?.visibility;
        }
        return false;
    };

    const checkPermission = (item) => {
        if (item?.role.includes(user?.role)) {
            let accessItem;
            if (access?.access) {
                accessItem = getAccessObjectFromArrayObject(access?.access, item.accessKey);
            }
            if (item.accessKey) {
                if (accessItem) {
                    return item.accessKey === 'integrations'
                        ? checkGroupVisibility(
                              access?.access?.find((val) => val.hasOwnProperty('scrolling_messaging')),
                              item?.children?.find((val) => val.accessKey === 'scrolling_messaging')
                          )
                        : accessItem.visibility;
                } else {
                    return true;
                }
            }
            return true;
        }
        return false;
    };

    const navItems = MENU_ITEM.map((item, index) => {
        switch (item.type) {
            case 'group':
                return checkPermission(item) ? <NavGroup key={`menu_group_${index}`} item={item} /> : <div key={`menu_group_${index}`} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
