import {styled, Box } from '@mui/material';
import React from 'react';
import { ASPECT_RATIO } from '../../../../utils/constants';
import CommonModal from '../../../../components/CommonModal';

const ContentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width:'100%',
    margin: '14px 0px'
});

const ImageViewModal = ({ open, handleClose, image, orientation }) => {
    return (
        <CommonModal open={open} onClose={handleClose} width={500}>
            <ContentBox>
                <img src={image} alt="" style={{ width: '100%', aspectRatio: ASPECT_RATIO[orientation] }} />
            </ContentBox>
        </CommonModal>
    );
};

export default ImageViewModal;
