import { styled, Typography, Box, Grid } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CommonContentPopup from '../../../../components/CommonContentPopup';
import { ContentTabBar } from '../../../../components/ContentTabBar';
import { fetchLocations } from '../../../../redux/reducers/clientLocationSlice';
import { editPlayList, syncAutoPlayListContent } from '../../../../redux/reducers/loopSlice';
import { CONTENT_DATA_TYPE, PLAYBACK_METHOD, PLAYLIST_MODE, PLAYLIST_MODE_TAB, SERVER_URL } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';
import { UnsavedChanges } from '../../../../widgets/CommonWidgets';
import CreatorContentPopUp from '../../Librarys/components/CreatorContentPopUp';
import { AutoContents } from './AutoContents';
import { ManualContents } from './ManualContents';

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    backgroundColor: COLORS.white,
    marginTop: 24,
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem'
});

const HeaderView = styled(Box)({
    display: 'flex',
    padding: 12,
    width: '100%'
});

const HeaderContentMainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between'
});

const HeaderLeftGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

const HeaderRightGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

const PlayListBox = styled(Box)({
    height: 38,
    width: 38,
    backgroundColor: 'pink',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const ModeTabBox = styled(Box)({
    width: 160,
    display: 'flex',
    alignItems: 'center'
});

const PlayListNameText = styled(Typography)({
    fontSize: 18
});
const EditableText = styled(Typography)({
    color: COLORS.primary.light,
    fontSize: 14,
    borderBottom: `1px solid ${COLORS.primary.light}`,
    cursor: 'pointer'
});
const Image = styled('img')({
    height: 25,
    width: 30,
    objectFit: 'contain'
});

export const PlayListDetails = ({ orientation }) => {
    const [showContentModal, setshowContentModal] = useState({ isOpen: false, content: null });
    const dispatch = useDispatch();
    const { loopFormData } = useSelector((state) => state.loop);
    const activePlaylist = loopFormData.activePlaylist;
    const { user } = useSelector((state) => state.auth);

    useEffect(() => {
        dispatch(fetchLocations()).then(() => { });
    }, [dispatch, user.client]);

    const handleChangeModeTab = (item) => {
        dispatch(editPlayList({ uniqId: activePlaylist?.uniqId, key: 'mode', value: item.id }));
    };
    const handleChangePlaybackMethod = (item) => {
        dispatch(editPlayList({ uniqId: activePlaylist?.uniqId, key: 'playback_method', value: item }));
    };

    useEffect(() => {
        dispatch(syncAutoPlayListContent());
    }, [activePlaylist.mode, dispatch]);

    const playbackMethod = useMemo(() => {
        return activePlaylist?.playback_method === PLAYBACK_METHOD.SINGLE ? PLAYBACK_METHOD.SINGLE : PLAYBACK_METHOD.GROUPED;
    }, [activePlaylist.playback_method]);

    return (
        <MainBox>
            <HeaderView>
                <HeaderContentMainGrid container>
                    <HeaderLeftGrid item>
                        <PlayListBox style={{ backgroundColor: activePlaylist?.color }}>
                            <Image src={`${SERVER_URL}${activePlaylist?.icon}`} alt="" />
                        </PlayListBox>
                        <PlayListNameText sx={{ ml: 1 }}>{`${activePlaylist?.name} Playlist ${activePlaylist.index + 1
                            } Sequence`}</PlayListNameText>
                    </HeaderLeftGrid>

                    <HeaderRightGrid item>
                        <Box sx={{ mx: 4, display: 'flex' }}>
                            <UnsavedChanges title={'Playback Method: '} />
                            <EditableText
                                onClick={() =>
                                    handleChangePlaybackMethod(
                                        activePlaylist?.playback_method === PLAYBACK_METHOD.SINGLE
                                            ? PLAYBACK_METHOD.COMPLETE
                                            : PLAYBACK_METHOD.SINGLE
                                    )
                                }
                            >
                                {playbackMethod}
                            </EditableText>
                        </Box>
                        <ModeTabBox>
                            <ContentTabBar
                                fontSize={14}
                                borderColor={COLORS.primary.light}
                                tabArray={PLAYLIST_MODE_TAB}
                                activeTab={activePlaylist?.mode}
                                handleChangeActiveTab={handleChangeModeTab}
                                height={28}
                                useOld={true}
                            />
                        </ModeTabBox>
                    </HeaderRightGrid>
                </HeaderContentMainGrid>
            </HeaderView>
            {activePlaylist?.mode === PLAYLIST_MODE.AUTO ? (
                <AutoContents
                    contents={activePlaylist?.selectedContents}
                    onClick={(data) => setshowContentModal({ isOpen: true, content: data })}
                    orientation={orientation}
                />
            ) : (
                <ManualContents onClick={(data) => setshowContentModal({ isOpen: true, content: data })} orientation={orientation} />
            )}
            {showContentModal.isOpen && showContentModal.content.content_type === CONTENT_DATA_TYPE.VIDEO && (
                <CommonContentPopup isHide
                    open={showContentModal.isOpen}
                    handleClose={() => setshowContentModal({ isOpen: false, content: null })}
                    data={showContentModal.content}
                    orientation={orientation}
                />
            )}
            {showContentModal.isOpen && showContentModal.content.content_type === CONTENT_DATA_TYPE.IMAGE && (
                <CreatorContentPopUp isHide
                    open={showContentModal.isOpen}
                    handleClose={() => setshowContentModal({ isOpen: false, content: null })}
                    data={showContentModal.content}
                    orientation={orientation}
                />
            )}
        </MainBox>
    );
};
