import React from 'react';

const AudIcon = () => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 32.000000 32.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                <path
                    d="M193 250 c-31 -39 -29 -51 18 -94 44 -42 48 -71 9 -71 -18 0 -26 6
   -28 23 -2 12 -8 22 -13 22 -17 0 -8 -35 16 -63 l25 -28 25 28 c34 39 31 54
   -16 97 -43 41 -48 69 -11 74 17 3 25 -2 29 -17 6 -23 23 -29 23 -8 -1 11 -45
   68 -52 66 -2 0 -13 -13 -25 -29z"
                />
                <path
                    d="M71 223 c-5 -21 -15 -58 -21 -83 -6 -25 -13 -53 -16 -62 -3 -10 -1
   -18 5 -18 6 0 13 11 17 25 5 19 13 25 34 25 21 0 29 -6 34 -25 4 -14 11 -25
   17 -25 6 0 8 8 5 18 -3 9 -9 35 -15 57 -35 142 -43 153 -60 88z m29 -35 c0 -7
   3 -23 6 -35 5 -19 2 -23 -16 -23 -18 0 -21 4 -16 23 3 12 6 28 6 35 0 6 5 12
   10 12 6 0 10 -6 10 -12z"
                />
            </g>
        </svg>
    );
};

export default AudIcon;
