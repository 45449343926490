import { Box, Button, Grid, styled, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { COLORS } from '../../utils/theme';
import CommonModal from '../../components/CommonModal';
import VideoPlayer from '../../components/VideoPlayer';
import { CONTENT_MODULE_TYPE, ORIENTATION, ORIENTATION_DATA_KEY } from '../../utils/constants';
import { isVimeoUrl, isYouTubeVideoUrl } from '../../utils/helpers';
import { IMAGES } from '../../assets';
import { useSelector } from 'react-redux';
import { VimeoPlayer } from '../../components/VimeoPlayer';
import { YoutubePlayer } from '../../components/YoutubePlayer';
import { ShareButton } from '../../widgets/ShareButton';
import { ViewerPermission } from '../ViewerPermission';

const ButtonStyle = styled(Box)({
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
});

const VideoAddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});

const VideoFieldLabel = styled(Typography)({
    fontSize: 20,
    fontWeight: 'bold',
    color: COLORS.black,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 8
});

const MainContentBox = styled(Grid)({
    padding: '14px 0px'
});

const VedioContent = styled(Grid)({});

const Text = styled(Typography)({
    padding: '8px 0px',
    fontSize: 18,
    fontStyle: 'italic',
    color: COLORS.darkGray
});

const VedioTitle = styled(Typography)({
    fontSize: 32,
    fontWeight: 'bold'
});
const VedioDetail = styled(Box)({
    display: 'flex'
});
const VedioDescription = styled(Typography)({
    fontSize: 20,
    wordWrap: 'break-word'
});

const CommonContentPopup = ({ open, handleClose, data, orientation, onRemove, isHide }) => {
    const { categorys } = useSelector((state) => state.category);
    const { client } = useSelector((state) => state.auth);
    const adminCategoryCurationMode = categorys.find((item) => item.id === data?.category_id)?.curate_playlist;
    const clientCategoryCurationMode = useMemo(() => {
        return client?.curate_list?.[[data?.category_id]];
    }, [data?.category_id, client?.curate_list]);

    const handleWidth = useCallback((isGroup, isLandscapeOnly) => {
        if (isLandscapeOnly) {
            return isGroup ? '70%' : 1140;
        } else return isGroup ? '70%' : 800;
    }, []);

    return (
        <CommonModal
            id="video-content"
            open={open}
            onClose={handleClose}
            style={{
                width: {
                    xs: '90%',
                    sm: '80%',
                    md: 900,
                    lg: handleWidth(data?.module_type === CONTENT_MODULE_TYPE.GROUP, orientation === ORIENTATION.LANDSCAPE)
                },
                height: data?.module_type === CONTENT_MODULE_TYPE.GROUP ? 'calc(100vh - 268px)' : 'auto'
            }}
        >
            <MainContentBox container sx={{ py: '14px' }}>
                <Grid
                    item
                    xs={12}
                    md={data?.module_type === CONTENT_MODULE_TYPE.GROUP ? (orientation === ORIENTATION.LANDSCAPE ? 4 : 5) : 12}
                >
                    <Grid container spacing={2}>
                        <VedioContent order={{ xs: 2, md: 1 }} xs={12} md={data?.module_type === CONTENT_MODULE_TYPE.GROUP ? 12 : 5} item>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                                <Box>
                                    <VedioTitle>{data?.name}</VedioTitle>
                                    <VedioDetail sx={{ mt: 1 }}>
                                        <Typography
                                            sx={{ fontSize: '17px', marginRight: '20px' }}
                                        >{`Category: ${data?.playlist?.name}`}</Typography>
                                        <Typography sx={{ fontSize: '17px' }}>{`Duration: ${data?.duration}`}</Typography>
                                    </VedioDetail>
                                    <VedioDescription sx={{ mt: 1 }}>{data?.description}</VedioDescription>
                                    {onRemove && adminCategoryCurationMode && clientCategoryCurationMode ? (
                                        <Text>
                                            {data?.playlist?.name} content is currently being curated on your behalf. Disable curation for
                                            this category in your Lineup if you would like to manage this content.
                                        </Text>
                                    ) : null}
                                </Box>
                                <Box>
                                    <ViewerPermission>
                                        <ButtonStyle>
                                            {onRemove && (!adminCategoryCurationMode || !clientCategoryCurationMode) ? (
                                                <Button onClick={onRemove}>
                                                    <VideoAddImage src={IMAGES.RemoveCircleIcon} />
                                                    <VideoFieldLabel>REMOVE</VideoFieldLabel>
                                                </Button>
                                            ) : null}

                                            {data?.landscape_share_url ? (
                                                <ShareButton
                                                    {...{ isHide }}
                                                    iconFontSize={18}
                                                    textFontSize={18}
                                                    shareUrl={
                                                        data?.[ORIENTATION_DATA_KEY?.share_url?.[orientation]] ||
                                                        data?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                                                    }
                                                />
                                            ) : null}
                                        </ButtonStyle>
                                    </ViewerPermission>
                                </Box>
                            </Box>
                        </VedioContent>
                        <Grid item order={{ xs: 1, md: 2 }} xs={12} md={data?.module_type === CONTENT_MODULE_TYPE.GROUP ? 12 : 7}>
                            <Box>
                                {isVimeoUrl(data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video) ? (
                                    <VimeoPlayer
                                        videoUrl={data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video}
                                        thumbnail={data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}
                                        orientation={orientation}
                                    />
                                ) : isYouTubeVideoUrl(data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video) ? (
                                    <YoutubePlayer
                                        videoUrl={data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video}
                                        thumbnail={data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}
                                        orientation={orientation}
                                    />
                                ) : (
                                    <VideoPlayer
                                        videoUrl={
                                            data?.[ORIENTATION_DATA_KEY?.video_hd?.[orientation]] ||
                                            data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] ||
                                            data?.landscape_video
                                        }
                                        thumbnail={data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}
                                        orientation={orientation}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContentBox>
        </CommonModal>
    );
};
export default CommonContentPopup;
