import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { CommonTable } from '../../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../../../utils/theme';
import { ICONS } from '../../../assets';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { DELETE_CONFIRMATION } from '../../../utils/constants';
import { fetchGetAllCurationProfiles } from '../../../redux/reducers/curationProfiles';
import { api } from '../../../api';
import { PAGES } from '../../../utils/urls';

const CurationProfilesName = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});

const TrashButton = styled(IconButton)({
    margin: '0px 0px 0px 8px',
    background: COLORS.primary.danger,
    borderRadius: '0.425rem',
    '&:hover': {
        background: COLORS.hover.danger
    }
});

const TrashIcon = styled(ICONS.Trash)({
    color: COLORS.white,
    width: 16
});

const CurationProfiles = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [searchValue, setSearchValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [curationProfileData, setCurationProfileData] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { curationProfiles, isPending } = useSelector(({ curationProfiles }) => curationProfiles);

    const initialAPICallingHanlder = () => {
        dispatch(fetchGetAllCurationProfiles());
    };

    const NameElement = ({ item }) => {
        return <CurationProfilesName>{item?.name}</CurationProfilesName>;
    };

    const ActionsContainer = ({ item }) => {
        return (
            <TrashButton
                onClick={(event) => {
                    event.stopPropagation();
                    setCurationProfileData(item);
                    setIsOpen(true);
                }}
            >
                <TrashIcon />
            </TrashButton>
        );
    };

    const curationProfilesColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: (row) => {
                return <NameElement item={row?.rowData} />;
            }
        },
        {
            id: 'category',
            fieldName: 'category',
            label: 'Category',
            render: (row) => row?.rowData?.playlist?.name,
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'territory',
            fieldName: 'territory',
            label: 'Territory',
            render: (row) => row?.rowData?.territory?.name ?? '-',
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'speciality',
            fieldName: 'speciality',
            label: 'Specialty',
            render: (row) => row?.rowData?.speciality?.name ?? '-',
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'id',
            fieldName: 'id',
            label: 'Actions',
            align: 'center',
            render: (row) => {
                return <ActionsContainer item={row.rowData} />;
            },
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];

    const handleDeleteCurationProfile = async () => {
        try {
            const { data } = await api.curationProfiles.deleteCurationProfile(curationProfileData?.id);
            if (data?.success) {
                enqueueSnackbar(data?.message, { variant: 'success' });
                initialAPICallingHanlder();
            } else {
                enqueueSnackbar(data?.message, { variant: 'error' });
            }
            setIsOpen(false);
            setCurationProfileData({});
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    useEffect(() => {
        initialAPICallingHanlder();
    }, [dispatch]);

    return (
        <Box>
            <CommonHeader
                title={'Curation Profiles'}
                buttonLabel={`Add Profiles`}
                onClick={() => navigate(PAGES.ADD_CURATION_PROFILES.url)}
                searchbar={true}
                searchValue={searchValue}
                handleSearch={(search) => setSearchValue(search)}
            />
            <Grid sx={{ marginTop: '24px' }} container>
                <CommonTable
                    search={searchValue}
                    loading={isPending}
                    columns={curationProfilesColumns}
                    rows={curationProfiles}
                    searchableFields={['name']}
                    handleRowDetail={(data) => navigate(`${PAGES.EDIT_CURATION_PROFILES.url}/${data.id}`)}
                />
            </Grid>
            {isOpen && (
                <AlertDialog
                    isOpen={isOpen}
                    isClose={() => {
                        setIsOpen(false);
                        setCurationProfileData({});
                    }}
                    handleSubmit={handleDeleteCurationProfile}
                    title={DELETE_CONFIRMATION.title}
                    description={DELETE_CONFIRMATION.description + 'Curation Profile' + '?'}
                    isCancel={'No'}
                />
            )}
        </Box>
    );
};

export default CurationProfiles;
