import { Box, FormHelperText, Grid, styled, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { CommonInput } from '../../../../../components/CommonInput';
import ImagePlaceholder from '../../../../../widgets/ImagePlaceholder';
import CommonButton from '../../../../../components/CommonButton';
import { adminUploadContentValidation } from '../../../../../utils/validations';
import { api } from '../../../../../api';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import FieldLabelView from '../../../../../components/FieldLabelView';
import CommonModal from '../../../../../components/CommonModal';
import { getFileResolution, sortCategoryList, splitTrimStringifyTags } from '../../../../../utils/helpers';
import { CONTENT_DATA_TYPE, CONTENT_MODULE_TYPE, ORIENTATION } from '../../../../../utils/constants';
import { CommonMultiSelect } from '../../../../../components/CommonMultiSelect';
import useFetch from '../../../../../hooks/useFetch';

const StyledInput = styled(CommonInput)({
    flex: 1,
    marginBottom: 12
});

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
});

export default function UploadContent({ open, type, directories, handleClose, orientation, fetchMediaContent }) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    const { data } = useFetch({
        initialUrl: '/get_all_category'
    });

    const categoryList = () => {
        return data?.result ? sortCategoryList(data?.result) : []
    }

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, resetForm, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            category: '',
            directory: '',
            landscapeURL: '',
            directory_hd: '',
            sub_folder: '',
            sub_folder_hd: '',
            video_file: '',
            video_file_hd: '',
            video_file_url: '',
            video_file_url_hd: '',
            thumbnail: '',
            tags: '',
            resolution: '',
            resolution_hd: '',
            description: ''
        },
        validationSchema: adminUploadContentValidation,
        onSubmit: async (values) => {
            let tagsArray = splitTrimStringifyTags(values.tags);
            setLoading(true);
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('category_id', values.category || Number(process.env.REACT_APP_MARKETING_CATEGORY_ID));
            formData.append('orientation', orientation);
            formData.append('sub_folder', values.sub_folder);
            formData.append('content_type', CONTENT_DATA_TYPE.VIDEO);
            formData.append('client_id', id);
            formData.append('module_type', CONTENT_MODULE_TYPE.CUSTOM_CONTENT);
            formData.append('tags', tagsArray);
            formData.append('media_server', values.directory);
            if(values.sub_folder_hd){
                formData.append('sub_folder_hd', values.sub_folder_hd);
            }
            if(values.directory_hd){
                formData.append('media_server_hd', values.directory_hd);
            }
            
            if(values.video_file_hd){
                formData.append('video_hd', values.video_file_hd);
                formData.append('resolution_hd', values.resolution_hd);
            }
            if (values.video_file) {
                formData.append('video', values.video_file);
                formData.append('resolution', values.resolution);
            }
            if (values.thumbnail_file) {
                formData.append('thumbnail', values.thumbnail_file);
            }
            if (values.description) {
                formData.append('description', values.description);
            }
            try {
                const { data } = await api.mediaContent.add(formData);
                if (data?.success) {
                    setLoading(false);
                    enqueueSnackbar(data?.message, { variant: 'success' });
                    fetchMediaContent({
                        params: {
                            orientation: orientation,
                            client_id: id,
                            check_host_url: 1,
                            custom_content: 1,
                            library_status: 1,
                            content_type: CONTENT_DATA_TYPE.VIDEO
                        }
                    });
                    handleCloseEvent();
                } else {
                    setLoading(false);
                    enqueueSnackbar(data?.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error?.message, { variant: 'error' });
            }
        }
    });
    const handleCloseEvent = () => {
        resetForm();
        handleClose();
    };

    const orientationObj = useMemo(() => {
        return orientation === ORIENTATION.LANDSCAPE ? { width: 200, height: 120 } : { width: 85, height: 120 }
    }, [orientation])

    return (
        <CommonModal open={open} title={'Upload Content'} onClose={handleCloseEvent} width={950}>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}>
                    <Grid item md={7} sm={6} xs={12}>
                        <FieldLabelView label={'Name'}>
                            <StyledInput sx={{mb: '4px'}}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.name && errors.name}
                                name={'name'}
                                value={values?.name}
                            />
                        </FieldLabelView>
                        <FieldLabelView label={'Category'} sx={{ width: '50%', mt: '4px' }}>
                        <CommonMultiSelect
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                options={categoryList()}
                                error={touched.category && errors.category}
                                name={'category'}
                                value={values.category}
                            />
                        </FieldLabelView>
                        <FieldLabelView label={'Share URL'} sx={{ mt: 1 }}>
                            <StyledInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.landscapeURL && errors.landscapeURL}
                                name={'landscapeURL'}
                                value={values.landscapeURL}
                            />
                        </FieldLabelView>
                        <FieldLabelView label={'Tags'}>
                            <StyledInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.tags && errors.tags}
                                name={'tags'}
                                value={values?.tags}
                                style={{ height: 'auto' }}
                                multiline={true}
                                minRows={5}
                            />
                            <Typography sx={{ fontSize: 14 }}>Separate tags with a comma.</Typography>
                        </FieldLabelView>
                        <FieldLabelView label={'Description'} sx={{ mt: 1 }} >
                            <StyledInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.description && errors.description}
                                name={'description'}
                                value={values?.description}
                                style={{ height: 'auto' }}
                                multiline={true}
                                minRows={6}
                            />
                        </FieldLabelView>
                    </Grid>
                    <Grid item sm={5} xs={12} md={5}>
                        <Box sx={{ display: 'flex' }} >
                            <FieldLabelView label={'Thumbnail'} >
                                <Box>
                                    <ImagePlaceholder
                                        boxDimensions={orientationObj}
                                        handleChange={(event) => {
                                            setFieldValue('thumbnail', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('thumbnail_file', event.target.files[0]);
                                        }}
                                        image={values.thumbnail}
                                        isAddIcon={values.thumbnail ? false : true}
                                    />
                                    {touched.thumbnail && errors.thumbnail && <FormHelperText error>{errors.thumbnail}</FormHelperText>}
                                </Box>
                            </FieldLabelView>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }} >
                            <FieldLabelView label={'4K Video File'}>
                                <Box sx={{ width: 180 }} >
                                    <ImagePlaceholder sx={{ marginRight: '4px' }}
                                        boxDimensions={orientationObj}
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('video_file_url', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('video_file', event.target.files[0]);
                                            setFieldValue('resolution', `${fileInfo?.width}x${fileInfo?.height}`);
                                        }}
                                        accept="video/mp4"
                                        image={values.video_file_url}
                                        isAddIcon={values.video_file_url ? false : true}
                                    />
                                    {touched.video_file && errors.video_file && <FormHelperText error>{errors.video_file}</FormHelperText>}
                                </Box>
                            </FieldLabelView>
                            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }} >
                                <FieldLabelView label={'Directory'}>
                                    <CommonMultiSelect
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        options={directories || []}
                                        error={touched.directory && errors.directory}
                                        name={'directory'}
                                        value={values.directory}
                                    />
                                </FieldLabelView>
                                <Box sx={{ marginBottom: '12px' }} />
                                <FieldLabelView label={'Sub-Folder'}>
                                    <StyledInput sx={{ mb: 0, ml: 1 }}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        error={touched.sub_folder && errors.sub_folder}
                                        name={'sub_folder'}
                                        value={values?.sub_folder}
                                    />
                                </FieldLabelView>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }} >
                            <FieldLabelView label={'HD Video File'}>
                                <Box sx={{ width: 180 }} >
                                    <ImagePlaceholder sx={{ marginRight: '4px' }}
                                        boxDimensions={orientationObj}
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('video_file_url_hd', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('video_file_hd', event.target.files[0]);
                                            setFieldValue('resolution_hd', `${fileInfo?.width}x${fileInfo?.height}`);
                                        }}
                                        accept="video/mp4"
                                        image={values.video_file_url_hd}
                                        isAddIcon={values.video_file_url_hd ? false : true}
                                    />
                                    {touched.video_file_hd && errors.video_file_hd && <FormHelperText error>{errors.video_file_hd}</FormHelperText>}
                                </Box>
                            </FieldLabelView>
                            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center' }} >
                                <FieldLabelView label={'Directory'}>
                                    <CommonMultiSelect
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        options={directories || []}
                                        error={touched.directory_hd && errors.directory_hd}
                                        name={'directory_hd'}
                                        value={values.directory_hd}
                                    />
                                </FieldLabelView>
                                <Box sx={{ marginBottom: '12px' }} />
                                <FieldLabelView label={'Sub-Folder'}>
                                    <StyledInput sx={{ mb: 0, ml: 1 }}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        error={touched.sub_folder_hd && errors.sub_folder_hd}
                                        name={'sub_folder_hd'}
                                        value={values?.sub_folder_hd}
                                    />
                                </FieldLabelView>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <ButtonContainer>
                    <CommonButton onClick={handleSubmit} loading={loading} variant="contained" label={'Submit'} type={'submit'} />
                </ButtonContainer>
            </form>
        </CommonModal>
    );
}