import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchClines = createAsyncThunk('users/fetchClines', async (params) => {
    const response = await api.client.get(params);
    return response.data.result;
});

const initialState = {
    clients: []
};

export const clientLocationSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClines.fulfilled, (state, action) => {
            state.clients = action.payload;
        });
    }
});

export default clientLocationSlice.reducer;
