import { Box, styled, Typography, Grid, Button } from '@mui/material';
import { COLORS } from '../../../../utils/theme';
import { ORIENTATION, UPLOAD_IMAGE_MENU } from '../../../../utils/constants';
import { useState } from 'react';
import { AccessControlledComponent } from '../../../../components/AccessControlledComponent';

const MainBox = styled(Box)({
    padding: '16px 25px',
    width: '100%',
    backgroundColor: COLORS.white,
    alignItems: 'center',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem'
});

const PageTitle = styled(Typography)({
    fontSize: 22,
    color: COLORS.black,
    fontWeight: '300'
});
const VrLine = styled(Box)({
    height: 50,
    backgroundColor: COLORS.dividerColor,
    border: '1px solid #d0d0d0'
});

const LeftContentView = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

// const LandscapeBox = styled(Box)({
//     height: 28,
//     width: 46,
//     border: `3px solid ${COLORS.black}`,
//     borderRadius: 6,
//     cursor: 'pointer'
// });

// const PortraitBox = styled(Box)({
//     height: 46,
//     width: 28,
//     border: `3px solid ${COLORS.black}`,
//     borderRadius: 6,
//     cursor: 'pointer'
// });

// const SetContentView = styled(Box)({ display: 'flex', alignItems: 'center' });

// const RightContentView = styled(Grid)({
//     display: 'flex',
//     width: '100%',
//     justifyContent: 'flex-end'
// });
const MainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between'
});

const TabIcon = styled(Box)({
    height: 20,
    width: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const TabButton = styled(Button)(({ isActive }) => ({
    color: isActive ? COLORS.white : COLORS.black,
    padding: '6px 16px',
    borderRadius: '0.425rem',
    backgroundColor: isActive ? COLORS.primary.light : 'transparent',
    border: `1px solid ${isActive ? COLORS.primary.light : COLORS.border}`,
    fontWeight: 500,
    marginRight: 8,
    height: 42,
    '&:hover': {
        backgroundColor: COLORS.primary.light,
        border: `1px solid ${COLORS.primary.light}`,
        'p': { color: COLORS.white },
        svg: { color: COLORS.white }
    }
}));

export default function UploadHeader({ activeTab, currentOrientation, unsavedChanges }) {
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const [selectedTab, setSelectedTab] = useState('VIDEO');

    const handleChangeOrientation = (value) => {
        setActiveOrientation(value);
        currentOrientation(value);
    };
    const handleChangedTab = (value) => {
        setSelectedTab(value);
        activeTab(value);
    };
    return (
        <MainBox>
            <MainGrid container>
                <LeftContentView item md={6} sm={6} sx={{ justifyContent: { xs: 'space-between', sm: 'flex-start', md: 'flex-start' } }}>
                    <PageTitle>Upload</PageTitle>
                    <VrLine sx={{ mx: 2 }} />
                    <Box sx={{ display: 'flex' }}>
                        {
                            UPLOAD_IMAGE_MENU?.map(({ icon: ICON, ...val }) => <AccessControlledComponent key={val?.id} accessValue={val?.id}>
                                <TabButton
                                    onClick={() => handleChangedTab(String(val?.id)?.toUpperCase())}
                                    sx={selectedTab === String(val?.id)?.toUpperCase() ? { backgroundColor: COLORS.primary.light, border: `1px solid ${COLORS.primary.light}`, } : {}}
                                >
                                    <TabIcon>
                                        <ICON style={{ width: 18, height: 18 }} color={selectedTab === String(val?.id)?.toUpperCase() ? COLORS.white : '#000'} />
                                    </TabIcon>
                                    <Typography fontSize={14} marginLeft='4px' color={selectedTab === String(val?.id)?.toUpperCase() ? 'white' : COLORS.black} >{val?.name}</Typography>
                                </TabButton>
                            </AccessControlledComponent>)
                        }
                        {/* <AccessControlledComponent accessValue={'background'}>
                            <FileIconBox
                                onClick={() => handleChangedTab('BACKGROUND')}
                                sx={selectedTab === 'BACKGROUND' ? { backgroundColor: COLORS.primary.light } : {}}
                            >
                                <Image src={IMAGES.BACKGROUND_FILE_ICON} />
                            </FileIconBox>
                        </AccessControlledComponent> */}
                    </Box>
                </LeftContentView>
                {/* <RightContentView
                    item
                    md={4}
                    sm={6}
                    sx={{
                        justifyContent: { xs: 'space-between', sm: 'flex-end', md: 'flex-end' },
                        marginTop: { xs: '10px', sm: '0px', md: '0px' }
                    }}
                >
                    {selectedTab === 'PLACEHOLDER' ? (
                        <SetContentView>
                            <LandscapeBox
                                onClick={() => handleChangeOrientation(ORIENTATION.LANDSCAPE)}
                                style={activeOrientation === ORIENTATION.LANDSCAPE ? { backgroundColor: COLORS.primary.light } : {}}
                            />
                            <PortraitBox
                                style={activeOrientation === ORIENTATION.PORTRAIT ? { backgroundColor: COLORS.primary.light } : {}}
                                onClick={() => handleChangeOrientation(ORIENTATION.PORTRAIT)}
                                sx={{ mx: 2 }}
                            />
                            {unsavedChanges && <UnsavedChanges />}
                        </SetContentView>
                    ) : null}
                </RightContentView> */}
            </MainGrid>
        </MainBox >
    );
}
