import React from 'react';

import { Box, Typography, styled } from '@mui/material';
import { COLORS } from '../../../utils/theme';
import CommonButton from '../../../components/CommonButton';
import MessageForm from './components/MessageForm';
import { EMAIL_URL } from '../../../utils/urls';

const MainBox = styled(Box)`
    padding: 25px 16px;
    width: 100%;
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.border};
    border-radius: 0.425rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
`;

const MainTitle = styled(Typography)`
    font-size: 30px;
    font-weight: 600;
`;

const SectionTitle = styled(Typography)`
    font-size: 25px;
`;

const SectionDescription = styled(Typography)`
    font-size: 16px;
`;

const SectionNote = styled(Typography)`
    font-size: 14px;
`;

const SectionContainer = styled(Box)`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;
`;

const VisitButton = styled(CommonButton)`
    height: 42px;
    font-weight: 300;
    font-size: 16px;
`;

const ContactButton = styled(CommonButton)`
    height: 42px;
    width: 252px;
    font-weight: 300;
    font-size: 16px;
`;

export default function Support() {
    const handleRedirection = (url) => {
        window.open(url, '_blank');
    };
    return (
        <MainBox>
            <MainTitle>Need help?</MainTitle>
            <SectionContainer>
                <SectionTitle>Visit our help site</SectionTitle>
                <SectionDescription>
                    There's a good chance you'll quickly find your <br /> answer in one of our help articles or video tutorials.
                </SectionDescription>
                <VisitButton variant="contained" label="Visit help site" onClick={() => handleRedirection('https://help.dsid.com')} />
            </SectionContainer>
            <SectionContainer>
                <SectionTitle>Contact support</SectionTitle>
                <SectionDescription>
                    If you'd prefer to speak to someone, there are <br /> several channels of support available:
                </SectionDescription>
                <ContactButton variant="contained" label="Email: support@dsid.com" onClick={() => handleRedirection(EMAIL_URL)} />
                <ContactButton
                    onClick={() => window.open(`tel:${'1-800-407-9188'}`, '_self')}
                    variant="contained"
                    label="Call or Text: 1-800-407-9188"
                />
                <ContactButton variant="contained" label="Chat" />
                <SectionNote>
                    Our support team is available <br /> Monday-Friday, 9AM-5PM EST.
                </SectionNote>
            </SectionContainer>
            <SectionContainer>
                <SectionTitle>Send a message</SectionTitle>
                <MessageForm />
            </SectionContainer>
        </MainBox>
    );
}
