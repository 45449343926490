import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentCategory:'',
};

export const requestCategorySlice = createSlice({
    name: 'requestCategory',
    initialState,
    reducers: {
        setCurrentRequestCategory: (state, action) => {
            state.currentCategory = action.payload;
        }
    }
});

export const { setCurrentRequestCategory } = requestCategorySlice.actions;
export default requestCategorySlice.reducer;
