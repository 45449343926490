import { styled, Box, Grid, Typography, CircularProgress } from '@mui/material';
import React, { useState } from 'react';
import { ICONS } from '../../../../../assets';
import AddLocationModel from '../../../../../components/Modal/AddLocationModel';
import useFetch from '../../../../../hooks/useFetch';
import { DELETE_CONFIRMATION, ORIENTATION, SkeletonArray } from '../../../../../utils/constants';
import ImagePlaceholder from '../../../../../widgets/ImagePlaceholder';
import LocationCard from '../../../../../widgets/LocationCard';
import EditLocationModal from './EditLocationModal';
import CommonPopover from '../../../../../components/CommonPopover';
import MirrorModal from '../../../../client/Locations/components/MirrorModal';
import { api } from '../../../../../api';
import { useSnackbar } from 'notistack';
import { AlertDialog } from '../../../../../widgets/CommonWidgets';
import { getOriantationByLayout } from '../../../../../utils/helpers';
import { useDispatch } from 'react-redux';
import { syncAllLoopByLibrary } from '../../../../../redux/reducers/loopSlice';

const MainBox = styled(Box)({
    width: '100%'
});

const ActionText = styled(Typography)({
    cursor: 'pointer'
});

const Locations = ({ clientId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = useState(null);
    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [showLocationModal, setShowLocationModal] = useState(false);
    const [showMirrorModal, setShowMirrorModal] = useState(false);
    const [showCofirm, setShowConfirm] = useState(false);
    const [loadingRefresh, setLoadingRefresh] = useState(false);

    const {
        data: locationData,
        callFetch,
        isLoading
    } = useFetch({
        initialUrl: `/get_location_by_client_id?client_id=${clientId}`,
        config: { headers: { client_id: clientId } },
        onFailure: true
    });

    const handleClick = (event, item) => {
        setAnchorEl(event.currentTarget);
        setSelectedLocation(item);
    };

    const refreshLocation = async () => {
        try {
            let orientation = '';
            if (locationData?.result?.length !== 0) {
                let activeLocationData = locationData?.result?.find((item) => item?.id === selectedLocation?.id);
                orientation = getOriantationByLayout(activeLocationData?.layout);
            } else {
                orientation = ORIENTATION.LANDSCAPE;
            }
            if (orientation) {
                setLoadingRefresh(true);

                const response = await api.loop.getLoopByLocation({
                    params: {
                        location_id: selectedLocation?.id
                    }
                });
                const libraryResponse = await api.library.get({ params: { orientation: orientation, status: 1, client_id: clientId } });
                setLoadingRefresh(false);
                setAnchorEl(null);

                dispatch(syncAllLoopByLibrary({ loops: response?.data?.result, libraryContents: libraryResponse?.data?.result }));
            }
        } catch (error) {
            return error;
        }
    };

    const checkForActiveSetMirror = () => {
        if (locationData?.result?.findIndex((item) => item?.mirror_of_data?.id === selectedLocation?.id) === -1) {
            return true;
        }
        return false;
    };

    const rebootLocation = async () => {
        try {
            await api.location.rebootDevice({ params: { location_id: selectedLocation?.id } });
            setAnchorEl(null);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            return error;
        }
    };

    const handleDelete = async () => {
        try {
            await api.location.delete(selectedLocation.id);
            callFetch();
            enqueueSnackbar('Location delete successfully', { variant: 'success' });
            setShowLocationModal(false);
            setAnchorEl(null);
            setShowConfirm(false);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <MainBox sx={{ mt: 3 }}>
            <Grid container spacing={3}>
                {isLoading
                    ? SkeletonArray.map((item) => {
                        return (
                            <Grid item key={item} xs={12} sm={4} lg={3} xxl={2}>
                                <LocationCard Icon={ICONS.MoreVertSharpIcon} loading={isLoading} />
                            </Grid>
                        );
                    })
                    : locationData &&
                    locationData?.result?.map((item) => {
                        return (
                            <Grid item key={item.id} xs={12} sm={4} lg={3} xxl={2}>
                                <LocationCard
                                    handleEdit={(e) => handleClick(e, item)}
                                    Icon={ICONS.MoreVertSharpIcon}
                                    data={item}
                                    reload={() => {
                                        callFetch();
                                    }}
                                />
                            </Grid>
                        );
                    })}
                <Grid item xs={12} sm={4} lg={3} xxl={2}>
                    <ImagePlaceholder
                        onClick={() => {
                            setShowAddLocationModal(true);
                        }}
                        isAddIcon={true}
                        minHeight={280}
                        style={{ borderRadius: '0.425rem' }}
                        boxDimensions={{ height: '100%', width: '100%' }}
                        addIconDimension={{ height: 50, width: 50 }}
                    />
                </Grid>
            </Grid>

            <EditLocationModal
                locationData={selectedLocation}
                callFetch={callFetch}
                open={showLocationModal}
                handleClose={() => {
                    setShowLocationModal(false);
                    setAnchorEl(null);
                }}
            />

            <CommonPopover
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Box sx={{ display: 'grid', padding: 1 }}>
                    <ActionText onClick={() => setShowLocationModal(true)}>Rename</ActionText>
                    <ActionText
                        sx={{ pt: 1, opacity: checkForActiveSetMirror() ? 1 : 0.3 }}
                        onClick={() => checkForActiveSetMirror() && setShowMirrorModal(true)}
                    >
                        {selectedLocation?.mirror_of_data ? 'Edit Mirror' : 'Set as Mirror'}
                    </ActionText>
                    <div style={{ opacity: loadingRefresh ? 0.3 : 1 }}>
                        <ActionText onClick={() => !loadingRefresh && refreshLocation()} sx={{ pt: 1 }}>
                            Refresh
                            {loadingRefresh && <CircularProgress sx={{ ml: 1 }} color="primary" size={16} />}
                        </ActionText>
                    </div>
                    <ActionText onClick={() => rebootLocation()} sx={{ pt: 1 }}>
                        Reboot
                    </ActionText>
                    <ActionText onClick={() => setShowConfirm(true)} sx={{ pt: 1 }}>
                        Remove
                    </ActionText>
                </Box>
            </CommonPopover>

            <AddLocationModel
                clientId={clientId}
                callFetch={callFetch}
                open={showAddLocationModal}
                handleClose={() => setShowAddLocationModal(false)}
            />

            {showMirrorModal && (
                <MirrorModal
                    clientEdit={false}
                    locationData={selectedLocation}
                    locations={locationData?.result || []}
                    fetchLocations={callFetch}
                    open={showMirrorModal}
                    handleClose={() => {
                        setShowMirrorModal(false);
                        setSelectedLocation({});
                        setAnchorEl(false);
                    }}
                />
            )}

            <AlertDialog
                isOpen={showCofirm}
                isClose={() => {
                    setShowConfirm(false);
                }}
                handleSubmit={handleDelete}
                title={DELETE_CONFIRMATION.title}
                description={DELETE_CONFIRMATION.description + 'Location'}
                isCancel={'No'}
            />
        </MainBox>
    );
};

export default Locations;
