import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import React from 'react';
import { ICONS } from '../assets';
import { COLORS } from '../utils/theme';

const ImagePlaceholder = ({
    style,
    boxDimensions,
    addIconDimension,
    isAddIcon,
    image,
    onClick,
    handleChange,
    accept,
    sx,
    onLoad,
    minHeight = 0,
    backgroundColor,
    imageSize = { height: '100%', width: '100%' }
}) => {
    const PlaceHolderBox = styled(Grid)(({ theme }) => ({
        width: boxDimensions.width,
        height: boxDimensions.height,
        minHeight: minHeight,
        border: image ? 'none' : `1px solid ${COLORS.border}`,
        backgroundColor: backgroundColor,
        // borderRadius: '.425rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        objectFit: boxDimensions.objectFit
    }));
    const { height, width } = addIconDimension ? addIconDimension : { height: 30, width: 30 };
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };
    return (
        <PlaceHolderBox onClick={handleClick} aria-label="upload picture" component="label" sx={[{ borderRadius: 0, padding: 0, ...sx }, style]}>
            {onClick ? null : <input onChange={handleChange} onLoad={onLoad} hidden accept={accept ? accept : 'image/*'} type="file" />}
            {isAddIcon ? (
                <ICONS.Add
                    sx={{
                        height: height,
                        width: width,
                        backgroundColor: COLORS.darkGray,
                        marginLeft: 0,
                        borderRadius: 20,
                        color: COLORS.white
                    }}
                />
            ) : image && !accept ? (
                <img
                    src={image}
                    style={{ height: imageSize.height, width: imageSize.width, objectFit: boxDimensions.objectFit }}
                    alt={'thumbnail'}
                />
            ) : (
                <video src={image} style={{ height: 'inherit', width: 'inherit' }}></video>
            )}
        </PlaceHolderBox>
    );
};

export default ImagePlaceholder;
