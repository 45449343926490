import { Box, Typography, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../api';
import CommonButton from '../../../../components/CommonButton';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import {
    fetchDeleteGoogleInfo,
    fetchGoogleAccounts,
    fetchGoogleInfo,
    fetchGoogleLocations
} from '../../../../redux/reducers/socialMediaSlice';
import { GOOGLE_LOGIN_URL } from '../../../../utils/urls';

export const GoogleAuthForm = () => {
    const [googleCode, setGoogleCode] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { googleInfo, googleAccounts, googleLocations } = useSelector((state) => state.socialMedia);

    const accountList =
        useMemo(() => {
            return googleAccounts?.map?.((item) => {
                return {
                    name: item.accountName,
                    value: item.name.split('/')[item.name.split('/').length - 1],
                    id: item.name.split('/')[item.name.split('/').length - 1]
                };
            });
        }, [googleAccounts]) || [];

    const locationsList =
        useMemo(() => {
            return googleLocations?.map?.((item) => {
                return {
                    name: item.title,
                    value: item.name.split('/')[item.name.split('/').length - 1],
                    id: item.name.split('/')[item.name.split('/').length - 1]
                };
            });
        }, [googleLocations]) || [];

    const getGoogleAccessTokenFromServer = useCallback(async () => {
        if (googleCode) {
            await api.social.googleLogin({ params: { code: googleCode } });
            dispatch(fetchGoogleInfo());
        }
    }, [googleCode, dispatch]);

    useEffect(() => {
        getGoogleAccessTokenFromServer();
    }, [getGoogleAccessTokenFromServer]);

    useEffect(() => {
        if (googleInfo) {
            dispatch(fetchGoogleAccounts(googleInfo));
        }
    }, [googleInfo, dispatch]);

    useEffect(() => {
        dispatch(fetchGoogleInfo());
    }, [dispatch]);

    const handleGoogleLogin = () => {
        let googleWindow = window.open(GOOGLE_LOGIN_URL, 'Google Login', 'popup');
        const interval = setInterval(async () => {
            if (googleWindow.location.origin === window.location.origin) {
                const urlSearchParams = new URLSearchParams(googleWindow.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                setGoogleCode(params.code);
                googleWindow.close();
                clearInterval(interval);
            }
        }, 100);
    };

    const handleRemoveGoogleLogin = () => {
        dispatch(fetchDeleteGoogleInfo());
    };

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setValues } = useFormik({
        initialValues: {
            account_id: '',
            location_id: '',
            facebook_account_id: ''
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const { data } = await api.social.updateSocialDetails(values);
                setLoading(false);
                if (data.success) {
                    enqueueSnackbar(data.message, { variant: 'success' });
                } else {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        if (googleInfo) {
            setValues(googleInfo);
        }
    }, [googleInfo, setValues]);

    useEffect(() => {
        if (values.account_id && googleInfo) {
            dispatch(fetchGoogleLocations({ account_id: values.account_id, access_token: googleInfo.access_token }));
        }
    }, [values.account_id, dispatch, googleInfo]);
    return (
        <Box>
            <Typography variant="h3">Google</Typography>
            <CommonButton disabled={Boolean(googleInfo)} onClick={handleGoogleLogin} label={'Google Login'} variant="outlined" />
            {googleInfo && (
                <Box sx={{ mt: 1 }}>
                    <Typography sx={{ color: 'green' }}>Authorized!</Typography>{' '}
                    <Grid container spacing={3}>
                        <Grid item md={2}>
                            <CommonMultiSelect
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                options={accountList}
                                error={touched.account_id && errors.account_id}
                                name={'account_id'}
                                value={values.account_id}
                                style={{ borderRadius: 0 }}
                            />
                        </Grid>

                        <Grid item md={2}>
                            <CommonMultiSelect
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                options={locationsList}
                                error={touched.location_id && errors.location_id}
                                name={'location_id'}
                                value={values.location_id}
                                style={{ borderRadius: 0 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item>
                            <CommonButton loading={loading} onClick={handleSubmit} label={'Save'} variant="outlined" />
                        </Grid>

                        <Grid item sx={{ ml: 1 }}>
                            <CommonButton onClick={handleRemoveGoogleLogin} label={'Remove'} variant="outlined" color="error" />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};
