import { ICONS } from '../assets';
import { USER_ROLE } from './constants';

export const PAGES = {
    LOGIN: {
        id: 1,
        url: '/login',
        name: 'Login'
    },
    RESET_PASSWORD: {
        id: 2,
        url: '/reset-password',
        name: 'Reset Password'
    },
    ACCOUNT_SELECTION: {
        id: 3,
        url: '/account-selection',
        name: 'Account Selection'
    },
    ACCOUNT: {
        id: 4,
        url: '/account',
        name: 'Accounts'
    },
    USERS: {
        id: 5,
        url: '/users',
        name: 'Users'
    },
    LAYOUTS: {
        id: 6,
        url: '/layout',
        name: 'Layout'
    },
    ADD_LAYOUTS: {
        id: 7,
        url: '/layout/add',
        name: 'Add Layout'
    },

    LOOPS: {
        id: 8,
        url: '/loop',
        name: 'Loops'
    },

    ADD_LOOP: {
        id: 9,
        url: '/loop/add',
        name: 'Add Loop'
    },
    EDIT_LOOP: {
        id: 10,
        url: '/loop/edit',
        name: 'Edit Loop'
    },

    VIDEOS: {
        id: 12,
        url: '/videos',
        name: 'Videos'
    },
    COLLECTIONS: {
        id: 13,
        url: '/collection',
        name: 'Collections'
    },
    ADD_COLLECTIONS: {
        id: 14,
        url: '/collection/add',
        name: 'Add Collection'
    },
    EDIT_COLLECTIONS: {
        id: 15,
        url: '/collection/edit',
        name: 'Edit Collection'
    },
    GROUPS: {
        id: 16,
        url: '/group',
        name: 'Groups'
    },
    ADD_GROUPS: {
        id: 17,
        url: '/group/add',
        name: 'Add Group'
    },
    EDIT_GROUPS: {
        id: 18,
        url: '/group/edit',
        name: 'Edit Group'
    },
    FEEDBACK_TOOL: {
        id: 19,
        url: '/feedback-tool',
        name: 'Feedback Tool'
    },
    SYSTEM_VERSIONS: {
        id: 20,
        url: '/versions',
        name: 'Versions'
    },
    ACCOUNT_TYPES: {
        id: 20,
        url: '/account-types',
        name: 'Account Types'
    },
    CURATION_PROFILES: {
        id: 48,
        url: '/curation-profiles',
        name: 'Curation Profiles'
    },
    ADD_CURATION_PROFILES: {
        id: 49,
        url: '/curation-profiles/add',
        name: 'Add Curation Profiles'
    },
    EDIT_CURATION_PROFILES: {
        id: 50,
        url: '/curation-profiles/edit',
        name: 'Edit Curation Profiles'
    },
    MASTER_ACCESS: {
        id: 21,
        url: '/master-access',
        name: 'Master Access'
    },
    FEEDBACK_FORM_EDIT: {
        id: 20,
        url: '/feedback-tool/edit',
        name: 'Edit Feedback Form'
    },
    FEEDBACK_FORM_ADD: {
        id: 21,
        url: '/feedback-tool/add',
        name: 'Add Feedback Form'
    },

    //Clients routes
    LINEUP: {
        id: 19,
        url: '/client/lineup',
        name: 'Lineup'
    },
    PLAYLISTS: {
        id: 20,
        url: '/playlist-category',
        name: 'Playlists'
    },
    ADD_PLAYLIST_CATEGORY: {
        id: 21,
        url: '/playlist-category/add',
        name: 'Add Playlist'
    },
    EDIT_PLAYLIST_CATEGORY: {
        id: 22,
        url: '/playlist-category/edit',
        name: 'Edit Playlist'
    },
    BACKGROUNDS: {
        id: 23,
        url: '/background',
        name: 'Backgrounds'
    },
    ADD_BACKGROUND: {
        id: 24,
        url: '/background/add',
        name: 'Add Background'
    },
    EDIT_BACKGROUND: {
        id: 25,
        url: '/background/edit',
        name: 'Edit Background'
    },
    LOCATIONS: {
        id: 26,
        url: '/client/locations',
        name: 'Locations'
    },
    CLIENT_LAYOUT: {
        id: 'client-layout',
        url: '/client/layout',
        name: 'Layout'
    },
    BROWSE: {
        id: 27,
        url: '/client/browse',
        name: 'Browse'
    },
    CREATE_CUSTOM: {
        id: 28,
        url: '/client/custom-content',
        name: 'Create'
    },
    ADD_CUSTOM_CONTENT: {
        id: 29,
        url: '/client/custom-content/add',
        name: 'Create Custom Content'
    },
    EDIT_CUSTOM_CONTENT: {
        id: 29,
        url: '/client/custom-content/edit',
        name: 'Edit Custom Content'
    },
    CONTENT_UPLOAD: {
        id: 30,
        url: '/client/upload',
        name: 'Upload'
    },
    NEW_PASSWORD: {
        id: 31,
        url: '/new-password',
        name: 'New Password'
    },
    CLIENT_LOOPS: {
        id: 32,
        url: '/client/loop',
        name: 'Loops'
    },
    CLIENT_LOOP_TEMPLATE: {
        id: 33,
        url: '/client/loop/template',
        name: 'Loop Template'
    },

    CLIENT_LOOP_CUSTOM_CREATE: {
        id: 33,
        url: '/client/loop/custom/create',
        name: 'Custom Loop'
    },

    CLIENT_LOOP_CUSTOM_EDIT: {
        id: 33,
        url: '/client/loop/custom',
        name: 'Custom Loop'
    },

    CLIENT_LOOP_TEMPLATE_EDIT: {
        id: 33,
        url: '/client/loop/template/edit',
        name: 'Custom Loop'
    },

    CLIENT_SOCIAL_MEDIA: {
        id: 34,
        url: '/client/socialmedia',
        name: 'Social Media'
    },
    GOOGLE_REVIEW: {
        id: 35,
        url: '/reviews/:client_id/google',
        name: 'Google Review'
    },
    FACEBOOK_REVIEW: {
        id: 36,
        url: '/socialV3/:client_id/facebook',
        name: 'Facebook Review'
    },
    INSTAGRAM_REVIEW: {
        id: 36,
        url: '/socialV2/:client_id/instagram',
        name: 'Facebook Review'
    },
    EDIT_LAYOUT: {
        id: 37,
        url: '/layout/edit',
        name: 'Edit Layout'
    },
    APPS: {
        id: 38,
        url: '/apps',
        name: 'Apps'
    },
    ADD_APPS: {
        id: 51,
        url: '/apps/add',
        name: 'Add Apps'
    },
    EDIT_APPS: {
        id: 39,
        url: '/apps/edit',
        name: 'Edit Apps'
    },
    CLIENT_APPS: {
        id: 40,
        url: '/client/apps',
        name: 'Apps'
    },
    REQUEST_TOOL: {
        id: 41,
        url: '/request-tool',
        name: 'Request Tool'
    },
    REQUEST_TOOL_DETAILS_OVERVIEW: {
        id: 42,
        url: '/request-tool/overview',
        name: 'Request tool details overview'
    },
    REQUEST_TOOL_ADD_FORM: {
        id: 43,
        url: '/request-tool/overview',
        name: 'Add Request Form'
    },
    REQUEST_TOOL_EDIT_FORM: {
        id: 44,
        url: '/request-tool/overview',
        name: 'Edit Request Form'
    },
    REQUEST: {
        id: 45,
        url: '/request',
        name: 'Request'
    },
    REQUEST_SUBMISSION: {
        id: 46,
        url: '/request/submission',
        name: 'Request Submission'
    },
    SUPPORT: {
        id: 43,
        url: '/client/support',
        name: 'Support'
    },
    FEEDBACK: {
        id: 49,
        url: '/feedback',
        name: 'Feedback'
    },
    FEEDBACK_SUBMISSION: {
        id: 50,
        url: '/feedback/submission',
        name: 'Feedback Submission'
    },
    RSS_TICKER: {
        id: 43,
        url: '/RSS/:client_id/:location_id',
        name: 'RSS'
    },
    SCROLLING_MESSAGING: {
        id: 47,
        url: '/scrolling-messaging',
        name: 'Scrolling Messaging'
    }
};

export const MENU_ITEM = [
    {
        id: 'clients',
        title: 'CLIENTS',
        type: 'group',
        role: [USER_ROLE.ADMIN],
        children: [
            {
                id: 'accounts',
                title: 'Accounts',
                type: 'item',
                url: PAGES.ACCOUNT.url,
                breadcrumbs: false,
                Icon: ICONS.Accounts
            },
            {
                id: 'users',
                title: 'Users',
                type: 'item',
                url: PAGES.USERS.url,
                breadcrumbs: false,
                Icon: ICONS.Users
            }
        ]
    },
    {
        id: 'presentations',
        title: 'PRESENTATIONS',
        type: 'group',
        role: [USER_ROLE.ADMIN],
        children: [
            {
                id: 'layout',
                title: 'Layouts',
                type: 'item',
                url: PAGES.LAYOUTS.url,
                breadcrumbs: false,
                Icon: ICONS.Layout
            },
            {
                id: 'loop',
                title: 'Loops',
                type: 'item',
                url: PAGES.LOOPS.url,
                breadcrumbs: false,
                Icon: ICONS.Loop
            }
        ]
    },
    {
        id: 'client-presentation',
        accessKey: 'presentation',
        title: 'PRESENTATION',
        type: 'group',
        role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
        children: [
            {
                id: 'client-loop',
                title: 'Loops',
                accessKey: 'loops',
                type: 'item',
                url: PAGES.CLIENT_LOOPS.url,
                breadcrumbs: false,
                Icon: ICONS.Loop
            },
            {
                id: 'library',
                title: 'Lineup',
                accessKey: 'library',
                type: 'item',
                url: PAGES.LINEUP.url,
                breadcrumbs: false,
                Icon: ICONS.Library
            },
            {
                id: 'locations',
                accessKey: 'locations',
                title: 'Locations',
                type: 'item',
                url: PAGES.LOCATIONS.url,
                breadcrumbs: false,
                Icon: ICONS.Locations
            },
            {
                id: 'client-layout',
                title: 'Layout',
                accessKey: 'layout',
                type: 'item',
                url: PAGES.CLIENT_LAYOUT.url,
                breadcrumbs: false,
                Icon: ICONS.Layout
            }
        ]
    },
    {
        id: 'content',
        title: 'CONTENT',
        type: 'group',
        role: [USER_ROLE.ADMIN],
        children: [
            {
                id: 'videos',
                title: 'Videos',
                type: 'item',
                url: PAGES.VIDEOS.url,
                breadcrumbs: false,
                Icon: ICONS.Videos
            },
            {
                id: 'collections',
                title: 'Collections',
                type: 'item',
                url: PAGES.COLLECTIONS.url,
                breadcrumbs: false,
                Icon: ICONS.Collections
            },
            {
                id: 'groups',
                title: 'Groups',
                type: 'item',
                url: PAGES.GROUPS.url,
                breadcrumbs: false,
                Icon: ICONS.Groups
            },
            {
                id: 'playlist',
                title: 'Playlists',
                type: 'item',
                url: PAGES.PLAYLISTS.url,
                breadcrumbs: false,
                Icon: ICONS.Platlist
            },
            {
                id: 'backgrounds',
                title: 'Backgrounds',
                type: 'item',
                url: PAGES.BACKGROUNDS.url,
                breadcrumbs: false,
                Icon: ICONS.Background
            },
            {
                id: 'requestTools',
                title: 'Request Tool',
                type: 'item',
                url: PAGES.REQUEST_TOOL.url,
                breadcrumbs: false,
                Icon: ICONS.PanToolAltIcon
            },
            {
                id: 'apps',
                title: 'Integrations',
                type: 'item',
                url: PAGES.APPS.url,
                breadcrumbs: false,
                Icon: ICONS.Puzzle
            }
        ]
    },
    {
        id: 'client-content',
        title: 'CONTENT',
        accessKey: 'content',
        type: 'group',
        role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
        children: [
            {
                id: 'browse',
                title: 'Browse',
                type: 'item',
                accessKey: 'browser',
                url: PAGES.BROWSE.url,
                breadcrumbs: false,
                Icon: ICONS.Collections
            },
            {
                id: 'create-custom',
                title: 'Create',
                type: 'item',
                accessKey: 'content_creator',
                url: PAGES.CREATE_CUSTOM.url,
                breadcrumbs: false,
                Icon: ICONS.PaintBrush
            },
            {
                id: 'upload',
                title: 'Upload',
                accessKey: 'upload',
                type: 'item',
                url: PAGES.CONTENT_UPLOAD.url,
                breadcrumbs: false,
                Icon: ICONS.Upload
            },
            {
                id: 'request',
                title: 'Request',
                accessKey: 'request',
                type: 'item',
                url: PAGES.REQUEST.url,
                breadcrumbs: false,
                Icon: ICONS.PanToolAltIcon
            },
            {
                id: 'apps',
                title: 'Integrate',
                accessKey: 'apps',
                type: 'item',
                url: PAGES.CLIENT_APPS.url,
                breadcrumbs: false,
                Icon: ICONS.Puzzle
            }
        ]
    },
    {
        id: 'integration',
        accessKey: 'integrations',
        title: 'ADVANCED',
        type: 'group',
        globalAccessKey: 'sidebar_visible',
        role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
        children: [
            {
                id: 'scrolling_messaging',
                title: 'Scrolling Messaging',
                accessKey: 'scrolling_messaging',
                type: 'item',
                globalAccessKey: 'sidebar_visible',
                url: PAGES.SCROLLING_MESSAGING.url,
                breadcrumbs: false,
                Icon: ICONS.Message
            }
        ]
    },
    {
        id: 'help',
        title: 'HELP',
        accessKey: 'help',
        type: 'group',
        role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
        children: [
            {
                id: 'support',
                title: 'Support',
                accessKey: 'support',
                type: 'item',
                url: PAGES.SUPPORT.url,
                breadcrumbs: false,
                Icon: ICONS.Headset
            },
            {
                id: 'feedback',
                title: 'Feedback',
                accessKey: 'feedback',
                type: 'item',
                url: PAGES.FEEDBACK.url,
                breadcrumbs: false,
                Icon: ICONS.Feedback
            }
        ]
    },
    {
        id: 'help',
        accessKey: 'help',
        title: 'HELP',
        type: 'group',
        role: [USER_ROLE.ADMIN],
        children: [
            {
                id: 'feedback-tool',
                title: 'Feedback Tool',
                accessKey: 'feedback',
                type: 'item',
                url: PAGES.FEEDBACK_TOOL.url,
                breadcrumbs: false,
                Icon: ICONS.Feedback
            }
        ]
    },
    {
        id: 'settings',
        accessKey: 'settings',
        title: 'SETTINGS',
        type: 'group',
        role: [USER_ROLE.ADMIN],
        children: [
            {
                id: 'versions',
                title: 'Versions',
                accessKey: 'versions',
                type: 'item',
                url: PAGES.SYSTEM_VERSIONS.url,
                breadcrumbs: false,
                Icon: ICONS.settings
            },
            {
                id: 'account_types',
                title: 'Account Types',
                accessKey: 'account_types',
                type: 'item',
                url: PAGES.ACCOUNT_TYPES.url,
                breadcrumbs: false,
                Icon: ICONS.settings
            },
            {
                id: 'curation_profiles',
                title: 'Curation Profiles',
                accessKey: 'curation_profiles',
                type: 'item',
                url: PAGES.CURATION_PROFILES.url,
                breadcrumbs: false,
                Icon: ICONS.settings
            }
            // {
            //     id: 'master_access',
            //     title: 'Master Access',
            //     accessKey: 'versions',
            //     type: 'item',
            //     url: PAGES.MASTER_ACCESS.url,
            //     breadcrumbs: false,
            //     Icon: ICONS.Public
            // },
        ]
    }
];

export const FACEBOOK_APP_ID = '120696787771552';
export const FACEBOOK_APP_SECRET = '6b0103e9d4387297f8c00bdc3152307d';

export const INSTAGRAM_APP_ID = '2152946615072923';
export const INSTAGRAM_APP_SECRET = 'db1110b7ee1548a7fa282d4f0270d3d8';

const redirectUrl = `${window.location.origin}/client/apps/`;
export const GOOGLE_LOGIN_URL = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=139794034208-ivm6pkeahnegpkcm0aqv02c5p5tmfoq2.apps.googleusercontent.com&response_type=code&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fplus.business.manage&redirect_uri=${redirectUrl}&access_type=offline&prompt=consent&service=lso&o2v=1&flowName=GeneralOAuthFlow`;
export const INSTAGRAM_LOGIN_URL = `https://api.instagram.com/oauth/authorize?response_type=code&client_id=${INSTAGRAM_APP_ID}&scope=user_profile,user_media&state=_instagram&redirect_uri=https://api.instagram.com/oauth/authorize?response_type=code&client_id=${INSTAGRAM_APP_ID}&scope=user_profile,user_media&state=_instagram&redirect_uri=${window.location.origin}/client/apps/`;

export const EMAIL_URL = `https://mail.google.com/mail/u/0/?fs=1&to=support@dsid.com.com&tf=cm`;
