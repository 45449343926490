import { styled, Box, Typography, IconButton, Skeleton } from '@mui/material';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ICONS, IMAGES } from '../../../../assets';
import CommonImage from '../../../../components/CommonImage';
import CommonPopover from '../../../../components/CommonPopover';
import { ViewerPermission } from '../../../../components/ViewerPermission';
import { ASPECT_RATIO, ORIENTATION, ORIENTATION_DATA_KEY, USER_ROLE } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';

const CollectionBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLORS.darkGray}`,
    backgroundColor: COLORS.white,
    borderRadius: '.425rem',
    boxShadow: COLORS.cardShadow,
    padding: 12
});

const CollectionImage = styled(CommonImage)({
    width: '100%',
    objectFit: 'cover'
});

const ContentBox = styled(Box)({
    padding: '8px 0px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
});

const ContentNameBox = styled(Box)({
    flex: 1
});

const ContentText = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: COLORS.black,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '250px'
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '150px'
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: '122px'
    }
}));

const ContentCategoryText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black
});

const ActionText = styled(Typography)({
    cursor: 'pointer'
});

const StatusCircle = styled(Box)({
    height: 22,
    width: 22,
    borderRadius: 22
});
const VideoAddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});

export const LandscapeContentItem = ({ content, onDelete, loading, value, addSingleToLibrary, removeSingleToLibrary, onClick }) => {
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const { orientation } = useSelector((state) => state.orientation);
    const [hoverOverCircle, setHoverOverCircle] = useState(false);
    const { user } = useSelector((state) => state.auth);

    const duration = useMemo(() => {
        if (content?.duration) {
            return Number(content.duration?.split(':')?.[content.duration?.split(':')?.length - 1]);
        }
    }, [content?.duration]);

    const handleOpenAction = (event) => {
        event.stopPropagation();
        setActionAnchorEl(event.currentTarget);
    };

    const handleDelete = (e) => {
        e.stopPropagation();
        setActionAnchorEl(null);
        if (onDelete) {
            onDelete();
        }
    };

    const handleHoverEffect = (value) => {
        if (user.role !== USER_ROLE.VIEWER) {
            setHoverOverCircle(value);
        }
    };
    return (
        <CollectionBox
            onClick={onClick && onClick}
            sx={
                !loading && {
                    ':hover': {
                        outline: `3px solid ${COLORS.primary.light}`,
                        cursor: 'pointer'
                    }
                }
            }
        >
            {loading ? (
                <Skeleton variant="rectangular" width={'100%'} height={orientation === ORIENTATION.LANDSCAPE ? 127 : 402} />
            ) : (
                <CollectionImage
                    style={{ width: '100%', aspectRatio: ASPECT_RATIO[orientation] }}
                    src={content?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || content?.landscape_thumbnail}
                />
            )}
            <ContentBox>
                <ContentNameBox>
                    {loading ? <Skeleton /> : <ContentText>{content?.name}</ContentText>}
                    {loading ? <Skeleton /> : <ContentCategoryText sx={{ fontWeight: 600 }} >Duration : <span style={{ fontWeight: 400 }} >{duration} seconds</span></ContentCategoryText>}
                </ContentNameBox>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <StatusCircle onMouseEnter={() => handleHoverEffect(true)} onMouseLeave={() => handleHoverEffect(false)}>
                        {!hoverOverCircle ? (
                            <StatusCircle style={{ backgroundColor: value ? COLORS.primary.light : COLORS.statusGray }} />
                        ) : value ? (
                            <VideoAddImage src={IMAGES.RemoveHyphenIcon} onClick={removeSingleToLibrary} />
                        ) : (
                            <VideoAddImage src={IMAGES.AddCircleIcon} onClick={addSingleToLibrary} />
                        )}
                    </StatusCircle>
                    <ViewerPermission>
                        <IconButton onClick={handleOpenAction} aria-label="close" edge="end" size="large" sx={{ color: COLORS.darkGray }}>
                            <ICONS.More color={COLORS.darkGray} />
                        </IconButton>
                    </ViewerPermission>
                </Box>
            </ContentBox>
            <CommonPopover
                anchorEl={actionAnchorEl}
                handleClose={(event) => {
                    event.stopPropagation();
                    setActionAnchorEl(null);
                }}
            >
                <Box sx={{ display: 'grid', padding: 1 }}>
                    <ActionText
                        onClick={(e) => {
                            setActionAnchorEl(null);
                            if (value) {
                                removeSingleToLibrary(e);
                            } else {
                                addSingleToLibrary(e);
                            }
                        }}
                    >
                        {value ? 'Remove from Lineup' : 'Add to Lineup'}
                    </ActionText>
                    <ActionText onClick={handleDelete}> Delete</ActionText>
                </Box>
            </CommonPopover>
        </CollectionBox>
    );
};
