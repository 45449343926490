import { Box, Typography, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { api } from '../../../../api';
import CommonButton from '../../../../components/CommonButton';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import { fetchDeleteFacebookInfo, fetchFacebookAccounts, fetchFacebookInfo } from '../../../../redux/reducers/socialMediaSlice';

const REDIRECT_URI = 'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';

export const FacebookAuthForm = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { facebookAccounts, facebookInfo } = useSelector((state) => state.socialMedia);

    const facebookAccountList =
        useMemo(() => {
            return facebookAccounts?.map?.((item) => {
                return {
                    name: item.name,
                    value: item.id,
                    id: item.id
                };
            });
        }, [facebookAccounts]) || [];

    useEffect(() => {
        if (facebookInfo) {
            const resData = { access_token: facebookInfo?.access_token, user_id: facebookInfo?.user_id };
            dispatch(fetchFacebookAccounts(resData));
        }
    }, [dispatch, facebookInfo, facebookInfo?.access_token, facebookInfo?.user_id]);

    useEffect(() => {
        dispatch(fetchFacebookInfo());
    }, [dispatch]);

    const handleFacebookLogin = async (data) => {
        const reqData = {
            access_token: data?.accessToken,
            name: data?.name,
            email: data?.email,
            user_id: data?.userID,
            expires_in: data?.expiresIn
        };

        await api.social.facebookLogin(reqData);
        dispatch(fetchFacebookInfo());
    };

    const handleRemoveFacebookLogin = () => {
        dispatch(fetchDeleteFacebookInfo());
    };

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setValues } = useFormik({
        initialValues: {
            page_id: ''
        },
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const reqData = {
                    page_id: values.page_id
                };
                const { data } = await api.social.updateSocialDetails(reqData);
                setLoading(false);
                if (data.success) {
                    enqueueSnackbar(data.message, { variant: 'success' });
                } else {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        if (facebookInfo) {
            setValues(facebookInfo);
        }
    }, [facebookInfo, setValues]);

    return (
        <Box sx={{ mt: 5 }}>
            <Typography variant="h3">Facebok</Typography>
            {!Boolean(facebookInfo) && (
                <LoginSocialFacebook
                    isDisabled={Boolean(facebookInfo)}
                    appId={'934952227492163' || ''}
                    fieldsProfile={'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'}
                    redirect_uri={REDIRECT_URI}
                    onResolve={({ provider, data }) => {
                        handleFacebookLogin(data);
                    }}
                    onReject={(err) => {
                        console.log(err);
                    }}
                >
                    <CommonButton disabled={Boolean(facebookInfo)} sx={{ ml: 3 }} label={'Facebook Login'} variant="outlined" />
                </LoginSocialFacebook>
            )}

            {facebookInfo && (
                <Box sx={{ mt: 1 }}>
                    <Typography sx={{ color: 'green' }}>Authorized!</Typography>
                    <Grid container spacing={3}>
                        <Grid item md={2}>
                            <CommonMultiSelect
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                options={facebookAccountList}
                                error={touched.page_id && errors.page_id}
                                name={'page_id'}
                                value={values.page_id}
                                style={{ borderRadius: 0 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item>
                            <CommonButton loading={loading} onClick={handleSubmit} label={'Save'} variant="outlined" />
                        </Grid>

                        <Grid item sx={{ ml: 1 }}>
                            <CommonButton onClick={handleRemoveFacebookLogin} label={'Remove'} variant="outlined" color="error" />
                        </Grid>
                    </Grid>
                </Box>
            )}
        </Box>
    );
};
