import { Box, styled, Typography, Grid } from '@mui/material';
import { COLORS } from '../../../../utils/theme';
import { ORIENTATION } from '../../../../utils/constants';
import { CommonInput } from '../../../../components/CommonInput';
import { ICONS } from '../../../../assets';
import { ViewerPermission } from '../../../../components/ViewerPermission';
import CommonButton from '../../../../components/CommonButton';

const MainBox = styled(Box)({
    padding: '16px 25px',
    width: '100%',
    backgroundColor: COLORS.white,
    alignItems: 'center',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem'
});

const PageTitle = styled(Typography)({
    fontSize: 22,
    color: COLORS.black,
    fontWeight: '300'
});
const VrLine = styled(Box)({
    height: 50,
    backgroundColor: COLORS.dividerColor,
    border: '1px solid #d0d0d0'
});

const LeftContentView = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

const LandscapeBox = styled(Box)({
    height: 28,
    width: 46,
    border: `3px solid ${COLORS.black}`,
    borderRadius: 6,
    cursor: 'pointer'
});

const PortraitBox = styled(Box)({
    height: 46,
    width: 28,
    border: `3px solid ${COLORS.black}`,
    borderRadius: 6,
    cursor: 'pointer'
});

const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: '300',
    height: '42px',
    fontSize: '16px',
    textAlign: 'center'
});

const SetContentView = styled(Box)({ display: 'flex', alignItems: 'center' });

const RightContentView = styled(Grid)({
    display: 'flex',
    width: '100%'
});
const MainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between'
});
export default function BrowserHeader({
    title,
    isNotShowRightCol,
    onChangeOrientation,
    onChangeSearch,
    customAction,
    orientationVisiblity = { isLandscapeOnly: false, isPortraitOnly: false },
    orientation = ORIENTATION.LANDSCAPE,
    onlySearch, buttonLabel, loading, onClick, actionStyle, isSaveButtonDisabled
}) {

    return (
        <MainBox>
            <MainGrid container>
                <LeftContentView item md={6} sx={{ marginBottom: { xs: '10px', sm: '10px', md: '0px', lg: '0px' } }}>
                    <PageTitle>{title}</PageTitle>
                    {!onlySearch && (
                        <>
                            <VrLine sx={{ mx: 2 }} />
                            <SetContentView>
                                {!orientationVisiblity.isPortraitOnly && (
                                    <LandscapeBox
                                        onClick={() => onChangeOrientation(ORIENTATION.LANDSCAPE)}
                                        style={orientation === ORIENTATION.LANDSCAPE ? { backgroundColor: COLORS.primary.light } : {}}
                                    />
                                )}
                                {!orientationVisiblity.isLandscapeOnly && (
                                    <PortraitBox
                                        style={orientation === ORIENTATION.PORTRAIT ? { backgroundColor: COLORS.primary.light } : {}}
                                        onClick={() => onChangeOrientation(ORIENTATION.PORTRAIT)}
                                        sx={{ mx: 2 }}
                                    />
                                )}
                            </SetContentView>
                        </>
                    )}
                </LeftContentView>
                {!isNotShowRightCol && <RightContentView sx={actionStyle} item md={4}>
                    {onChangeSearch && (
                        <CommonInput handleChange={onChangeSearch} name={'search'} label={'Search...'} endAdornment={<ICONS.Search sx={{ marginTop: '5px' }} />} />
                    )}
                    {customAction ? customAction() : null}
                    {buttonLabel ?
                        <ViewerPermission>
                            <StyledButton
                                loading={loading}
                                onClick={onClick}
                                variant="contained"
                                label={buttonLabel}
                                disabled={isSaveButtonDisabled}
                            />
                        </ViewerPermission> : null}
                </RightContentView>}
            </MainGrid>
        </MainBox>
    );
}
