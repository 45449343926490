import { styled, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { DeleteButton } from '../../../../widgets/CommonWidgets';
import CommonButton from '../../../../components/CommonButton';
import { mirrorModelValidation } from '../../../../utils/validations';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import { api } from '../../../../api';
import CommonModal from '../../../../components/CommonModal';
import FieldLabelView from '../../../../components/FieldLabelView';
import { DELETE_MIRROR_CONFIRMATION } from '../../../../utils/constants';

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
});

const MirrorModal = ({ open, handleClose, locationData, locations = [], fetchLocations, clientEdit }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const locationList = useMemo(() => {
        let data = locations.filter((item) => item.id !== locationData.id && !item?.mirror_of_data);
        data = data.map((item) => {
            return {
                id: item.id,
                name: item.name,
                value: item.id
            };
        });
        return data;
    }, [locationData.id, locations]);

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            set_mirror: ''
        },
        validationSchema: mirrorModelValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const data = {
                    id: values.set_mirror,
                    set_mirror: locationData.id
                };
                await api.location.setMirror(data);
                handleClose();
                fetchLocations && fetchLocations()
                enqueueSnackbar(`${locationData.name} set as Mirror Successfully`, { variant: 'success' });
                setLoading(false);
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    const handleDelete = async () => {
        try {
            setDeleteLoading(true);
            const data = {
                mirror_id: locationData.id
            };
            await api.location.removeMirror(data);
            handleClose();
            fetchLocations && fetchLocations()
            enqueueSnackbar(`${locationData.name} remove as Mirror Successfully`, { variant: 'success' });
            setDeleteLoading(false);
        } catch (error) {
            setDeleteLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    useEffect(() => {
        if (locationData?.mirror_of_data) {
            setFieldValue('set_mirror', locationData?.mirror_of_data?.id);
        }
    }, [locationData, setFieldValue]);

    return (
        <CommonModal open={open} onClose={handleClose} width={500} title={locationData?.mirror_of_data ? 'Edit Mirror' : 'Set as Mirror'}>
            <FieldLabelView label={`${locationData.name} will be a mirror of:`}>
                <CommonMultiSelect
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    options={locationList}
                    error={touched.set_mirror && errors.set_mirror}
                    name={'set_mirror'}
                    value={values.set_mirror}
                />
            </FieldLabelView>
            <ButtonContainer>
                {locationData?.mirror_of_data && (
                    <DeleteButton
                        sx={{ mx: 2 }}
                        onClick={handleDelete}
                        loading={deleteLoading}
                        confirmation={DELETE_MIRROR_CONFIRMATION}
                        label={'Disable'}
                        title={'Mirror Location'}
                    />
                )}
                <CommonButton label={'Save'} loading={loading} onClick={handleSubmit} variant="contained" />
            </ButtonContainer>
        </CommonModal>
    );
};

export default MirrorModal;
