import React from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '../utils/theme';

const MainView = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));
const HeaderText = styled(Typography)(({ theme }) => ({
    color: COLORS.darkText,
    fontSize: '32px',
    fontWeight: '300',
    fontFamily: "Comfortaa !important",
    [theme.breakpoints.down('sm')]: {
        fontSize: '25px'
    }
}));
const VersionText = styled(Typography)(({ theme }) => ({
    marginLeft: 10,
    color: COLORS.black,
    fontSize: '1.5rem',
    fontWeight: 100,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem'
    }
}));
const LogoBox = styled(Box)(({ theme }) => ({
    display: 'flex'
}));
export default function LoopOSHeaderLogo() {
    return (
        <MainView>
            <LogoBox>
                <HeaderText>Loop</HeaderText>
                <HeaderText sx={{ color: COLORS.primary.light }}>OS</HeaderText>
            </LogoBox>
            <VersionText>v1.0</VersionText>
        </MainView>
    );
}
