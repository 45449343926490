import React, { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { COLORS } from '../../../utils/theme';
import AddAccountModal from './components/AddAccountModal';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClines } from '../../../redux/reducers/clientsSlice';
import { SERVER_URL } from '../../../utils/constants';
import { CommonTable } from '../../../components/Table';
import { CommonHeader } from '../../../widgets/CommonHeader';

const NameElementBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const AccountImage = styled('img')({
    height: 32,
    width: 32,
    borderRadius: 4
});

const AccountName = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.primary.light,
    marginLeft: 12,
    cursor: 'pointer'
});

const Accounts = () => {
    const [modal, setModal] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { clients } = useSelector((state) => state.client);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchClines()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const NameElement = ({ item }) => {
        return (
            <NameElementBox>
                <AccountImage src={SERVER_URL + item.logo} />
                <AccountName>{item.name}</AccountName>
            </NameElementBox>
        );
    };
    const accountColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: (row) => {
                return <NameElement item={row.rowData} />;
            }
        },
        {
            id: 'id',
            fieldName: 'id',
            label: 'Client ID',
            align: 'center'
        }
    ];

    return (
        <Box>
            <CommonHeader
                title={'Accounts'}
                buttonLabel={'Add Account'}
                searchbar={true}
                searchValue={searchValue}
                outlineInputClass={{ top: 0 }}
                handleSearch={(search) => setSearchValue(search)}
                onClick={() => setModal(true)}
            />
            <Box sx={{ marginTop: '24px' }}>
                <CommonTable
                    loading={isLoading}
                    search={searchValue}
                    defaultRowsPerPage={100}
                    rowsPerPageOptions={[10, 50, 100]}
                    handleRowDetail={(data) => navigate(`/account/${data.id}`)}
                    columns={accountColumns}
                    rows={clients}
                    searchableFields={['name', 'id']}
                />
            </Box>
            {modal && <AddAccountModal open={modal} handleClose={() => setModal(false)} />}
        </Box>
    );
};

export default Accounts;
