import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { COLORS } from '../../../utils/theme';
import { useNavigate } from 'react-router-dom';
import { ACCOUNT_TYPES } from '../../../utils/constants';
import { CommonTable } from '../../../components/Table';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { PAGES } from '../../../utils/urls';

const AccountName = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});

const AccountTypes = () => {
    const navigate = useNavigate();

    const NameElement = ({ item }) => {
        return <AccountName>{item?.name}</AccountName>;
    };

    const accountTypesColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: (row) => {
                return <NameElement item={row?.rowData} />;
            }
        }
    ];

    return (
        <Box>
            <CommonHeader title={'Account Types'} outlineInputClass={{ top: 0 }} />
            <Box sx={{ marginTop: '24px' }}>
                <CommonTable
                    defaultRowsPerPage={10}
                    rowsPerPageOptions={[10, 50, 100]}
                    handleRowDetail={(data) => navigate(`${PAGES.ACCOUNT_TYPES.url}/${data.id}`)}
                    columns={accountTypesColumns}
                    rows={ACCOUNT_TYPES}
                />
            </Box>
        </Box>
    );
};

export default AccountTypes;
