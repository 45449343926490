import { Box, Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets';
import { fetchLayouts } from '../../../redux/reducers/layoutSlice';
import { ORIENTATION } from '../../../utils/constants';
import { COLORS } from '../../../utils/theme';
import { PAGES } from '../../../utils/urls';
import { CommonHeader } from '../../../widgets/CommonHeader';
import ImagePlaceholder from '../../../widgets/ImagePlaceholder';
import { LayoutBuilderWidget } from '../../../widgets/LayoutBuilderWidget';

const CardTypography = styled(Typography)({
    fontSize: '1rem',
    color: COLORS.black,
    display: 'flex'
});
const InfoText = styled(Typography)({
    fontSize: '1rem',
    fontWeight: 'bold',
    color: COLORS.black,
    marginLeft: 4
});

const CardBox = styled(Box)({
    backgroundColor: COLORS.white,
    padding: '40px 32px 15px 32px',
    borderRadius: '.425rem',
    display: 'flex',
    flexDirection: 'column'
});
export const Layout = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isShown, setIsShown] = useState(false);
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);

    const { layouts } = useSelector((state) => state.layout);
    useEffect(() => {
        dispatch(fetchLayouts({ params: { orientation: activeOrientation } }));
    }, [dispatch, activeOrientation]);

    return (
        <Box>
            <CommonHeader
                isActionContentViewHidden={true}
                orientationFilter={true}
                title={'Layouts'}
                onChangeOrientation={(value) => setActiveOrientation(value)}
            />
            <Box sx={{ marginTop: '24px' }} />
            <Grid container spacing={3}>
                {layouts?.map((item) => {
                    return (
                        <Grid item
                            onMouseEnter={() => setIsShown({ visibility: true, id: item.id })}
                            onMouseLeave={() => setIsShown({ visibility: false, id: item.id })}
                            elevation={0}
                            key={item.id}
                            xs={12}
                            sm={4}
                            lg={3}
                            xxl={2}
                            alignItems={'center'}
                            justifyContent={'center'}
                        >
                            <CardBox
                                spacing={{ xs: 1, sm: 3, md: 4, lg: 5 }}
                                sx={{
                                    border:
                                        isShown.visibility && item.id === isShown.id
                                            ? `1px solid ${COLORS.primary.light}`
                                            : `1px solid ${COLORS.border}`
                                }}
                                onClick={() =>
                                    navigate(`${PAGES.EDIT_LAYOUT.url}/${item.id}`, { state: { orientation: activeOrientation } })
                                }
                            >
                                <LayoutBuilderWidget zones={item.zones} orientation={activeOrientation} />
                                <CardTypography sx={{ mt: 2 }} gutterBottom variant="h5" component="div">
                                    Name: <InfoText>{item.name || 'N/A'}</InfoText>
                                </CardTypography>
                                <CardTypography gutterBottom variant="h5" component="div">
                                    Zones: <InfoText>{item?.zones?.length}</InfoText>
                                </CardTypography>
                                <CardTypography gutterBottom variant="h5" component="div">
                                    Aspect Ratio: <InfoText>{activeOrientation === 'landscape' ? '16:9' : '9:16'}</InfoText>
                                </CardTypography>
                                <CardTypography gutterBottom variant="h5" component="div">
                                    Status:&nbsp;
                                    {item?.status === 1 ? (
                                        <ICONS.Eye sx={{ color: 'green' }} />
                                    ) : (
                                        <ICONS.EyeOff sx={{ color: 'red' }} />
                                    )}
                                </CardTypography>
                            </CardBox>
                        </Grid>
                    );
                })}
                <Grid item xs={12} sm={4} md={4} lg={3} xxl={2}>
                    <ImagePlaceholder style={{ borderRadius: '0.425rem' }}
                        onClick={() => {
                            navigate(PAGES.ADD_LAYOUTS.url, { state: { orientation: activeOrientation } });
                        }}
                        isAddIcon={true}
                        minHeight={300}
                        boxDimensions={{ height: '100%', width: '100%' }}
                        addIconDimension={{ height: 50, width: 50 }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};
