import { Grid, styled } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CollectionCardWidgets } from '../../../widgets/CollectionCardWidgets';
import NoDataFound from '../../../components/NoDataFound';
import FeedbackHeader from './components/FeedbackHeader';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../../utils/urls';
import { fetchAllForms } from '../../../redux/reducers/feedbackSlice';

const CenteredNoDataFound = styled(Box)({
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center'
});

export const Feedback = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const feedbackData = useSelector(({ feedback }) => feedback?.feedbackForms);
    const { activeOrientation } = useSelector((state) => state.orientation);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchAllForms({ params: { status: 1 } })).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const handleClickContent = (data) => {
        navigate(`${PAGES.FEEDBACK_SUBMISSION.url}/${data.id}`, { state: { title: data.name } });
    };

    return (
        <Box>
            <FeedbackHeader title={'Feedback'} />
            <Box sx={{ marginTop: '24px' }} />
            <Grid container spacing={3}>
                {isLoading ? (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <CollectionCardWidgets
                            orientation={activeOrientation}
                            hideToggleSwitch={true}
                            hasStatus={true}
                            loading={isLoading}
                        />
                    </Grid>
                ) : (
                    feedbackData &&
                    feedbackData?.map?.((item, index) => {
                        return (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                                <CollectionCardWidgets
                                    orientation={activeOrientation}
                                    hideToggleSwitch={true}
                                    hasStatus={false}
                                    onClick={handleClickContent}
                                    data={item}
                                    isRequest={true}
                                />
                            </Grid>
                        );
                    })
                )}
            </Grid>
            {!isLoading && !feedbackData?.length && (
                <CenteredNoDataFound>
                    <NoDataFound message={feedbackData?.message} />
                </CenteredNoDataFound>
            )}
        </Box>
    );
};
