import React, { useState } from 'react';
import { Card, Typography, Box, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../../utils/theme';
import { CommonInput } from '../../../components/CommonInput';
import CommonButton from '../../../components/CommonButton';
import { PAGES } from '../../../utils/urls';
import { PublicLayout } from '../../../layout/PublicLayout';
import { useFormik } from 'formik';
import { newPasswordValidation } from '../../../utils/validations';
import { api } from '../../../api';
import { USER_ROLE } from '../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import FieldLabelView from '../../../components/FieldLabelView';
import { ICONS } from '../../../assets';
import { setHeader } from '../../../api/client';
import { updateUser } from '../../../redux/reducers/authSlice';

const MainCard = styled(Card)({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '2vh',
    padding: '40px 0px',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: 20,
    borderWidth: 5,
    borderColor: 'red'
});

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    color: COLORS.black,
    fontSize: 30,
    fontWeight: '400',
    padding: '10px'
}));
const HorizontalSpacing = styled(Box)(({ theme }) => ({
    width: '100%'
}));

const StyledInput = styled(CommonInput)(({ theme }) => ({
    color: COLORS.vividYellow,
    fontSize: 18,
    marginBottom: 15
}));

const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: 100,
    borderRadius: 0,
    width: '60%'
});
const MainForm = styled('form')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});

const InputBox = styled(Box)({
    width: '100%'
});

const NewPasswordForm = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector((state) => state.auth);
    const token = new URLSearchParams(window.location.search).get('token');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const isNewUser = new URLSearchParams(window.location.search).get('isNewUser');

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const { handleBlur, handleChange, handleSubmit, values, touched, errors } = useFormik({
        initialValues: {
            password: '',
            confirmPassword: ''
        },
        validationSchema: newPasswordValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const { data } = await api.auth.newPassword({
                    password: values.password,
                    token
                });
                if (data.success) {
                    enqueueSnackbar(data.message, { variant: 'success' });
                    if (isNewUser) {
                        if (user.role === USER_ROLE.MANAGER || user.role === USER_ROLE.VIEWER) {
                            api.auth
                                .getMyClient()
                                .then((response) => {
                                    const client = response.data.result?.clients;
                                    if (client.length > 1) {
                                        navigate(PAGES.ACCOUNT_SELECTION.url);
                                    } else {
                                        setHeader('client_id', client[0]?.id);
                                        dispatch(updateUser({ ...user, client: client?.[0], clientList: client }));
                                        navigate(PAGES.LOCATIONS.url);
                                    }
                                })
                                .catch((err) => {
                                    enqueueSnackbar(err.message, { variant: 'error' });
                                });
                        } else {
                            navigate(PAGES.ACCOUNT.url);
                        }
                    } else {
                        navigate(PAGES.LOGIN.url);
                    }
                }

                setLoading(false);
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message.message, { variant: 'error' });
            }
        }
    });

    return (
        <PublicLayout>
            <MainCard sx={{ width: '100%', px: isNewUser && 2 }}>
                {/* <HRLine /> */}
                <MainForm onSubmit={handleSubmit}>
                    <HorizontalSpacing>
                        <HeaderText>{isNewUser ? 'Update your password' : 'Set your password'}</HeaderText>
                        <FieldLabelView sx={{ mt: 2 }}>
                            <InputBox>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    name={'password'}
                                    value={values?.password}
                                    placeholder="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    error={touched.password && errors.password}
                                    startAdornment={
                                        <ICONS.Lock sx={{ color: `${COLORS.primary.light}`, fontSize: '20px', marginRight: '4px' }} />
                                    }
                                    endAdornment={
                                        <IconButton
                                            onMouseDown={() => setShowPassword(true)}
                                            onMouseUp={() => setShowPassword(false)}
                                            sx={{ marginRight: '-8px' }}
                                        >
                                            {showPassword ? (
                                                <ICONS.Eye sx={{ color: `${COLORS.darkGray}`, fontSize: '20px' }} />
                                            ) : (
                                                <ICONS.EyeOff sx={{ color: `${COLORS.darkGray}`, fontSize: '20px' }} />
                                            )}
                                        </IconButton>
                                    }
                                />
                            </InputBox>
                        </FieldLabelView>
                        <FieldLabelView>
                            <InputBox>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    name={'confirmPassword'}
                                    value={values?.confirmPassword}
                                    placeholder="Confirm Password"
                                    error={touched.confirmPassword && errors.confirmPassword}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    startAdornment={
                                        <ICONS.Lock sx={{ color: `${COLORS.primary.light}`, fontSize: '20px', marginRight: '4px' }} />
                                    }
                                    endAdornment={
                                        <IconButton
                                            onMouseDown={() => setShowConfirmPassword(true)}
                                            onMouseUp={() => setShowConfirmPassword(false)}
                                            sx={{ marginRight: '-8px' }}
                                        >
                                            {showConfirmPassword ? (
                                                <ICONS.Eye sx={{ color: `${COLORS.darkGray}`, fontSize: '20px' }} />
                                            ) : (
                                                <ICONS.EyeOff sx={{ color: `${COLORS.darkGray}`, fontSize: '20px' }} />
                                            )}
                                        </IconButton>
                                    }
                                />
                            </InputBox>
                        </FieldLabelView>
                        <ButtonContainer>
                            <StyledButton
                                sx={{ mt: 2 }}
                                key="changepassword-Button"
                                id="changepassword-Button"
                                onClick={handleSubmit}
                                loading={loading}
                                variant="contained"
                                label={'Change Password'}
                                title={'changepassword'}
                                type={'submit'}
                            />
                        </ButtonContainer>
                    </HorizontalSpacing>
                </MainForm>
            </MainCard>
        </PublicLayout>
    );
};

export default NewPasswordForm;
