import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

// material-ui
import { Box, IconButton, Link, Menu, MenuItem, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { DRAWER_WIDTH, HEADER_HEIGHT, SERVER_URL } from '../../../utils/constants';
import { COLORS } from '../../../utils/theme';
import Avatar from '@mui/material/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import LoopOSHeaderLogo from '../../../widgets/LoopOSHeaderLogo';
import { logout, updateUser } from '../../../redux/reducers/authSlice';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../../utils/urls';
import { ICONS } from '../../../assets';
import { sortByProperty, sortCategoryList, toCapitalCase } from '../../../utils/helpers';
import Logo from '../../../widgets/Logo';
import { CommonMultiSelect } from '../../../components/CommonMultiSelect';
import { setHeader } from '../../../api/client';
import EditProfile from '../../../containers/client/User/EditProfile';
import UpdateLocationLoop from '../../../components/UpdateLocationLoopButton';

const LeftIconView = styled(Box)({
    backgroundColor: COLORS.primary.light,
    width: DRAWER_WIDTH,
    display: 'flex',
    minHeight: HEADER_HEIGHT,
    alignItems: 'center',
    justifyContent: 'center',
    borderRight: `1px solid ${COLORS.border}`,
    BorderBottom: `1px solid ${COLORS.border}`
});

const ContentMainRow = styled(Box)({
    display: 'flex',
    minHeight: HEADER_HEIGHT,
    flex: 1
});

const ContentBoxView = styled(Box)({
    display: 'flex',
    width: '100%',
    backgroundColor: COLORS.white,
    justifyContent: 'space-between',
    alignItems: 'center'
});

const AvatarProfileView = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});
const AvatarLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
        cursor: 'pointer'
    }
}));

const UserNameView = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
        marginLeft: 10
    },
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    }
}));

const NameText = styled(Typography)({
    fontSize: 14,
    fontWeight: 'bold',
    color: COLORS.black
});

const SubNameText = styled(Link)({
    fontSize: 12,
    color: 'gray',
    textDecoration: 'none',
    cursor: 'pointer'
});

const ProfileActionView = styled(Box)({
    display: 'flex'
});
const Divider = styled(Box)({
    height: 12,
    margin: '0px 5px',
    border: `1px solid ${COLORS.darkGray}`
});
const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
});

const AccountElementBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const AccountImage = styled('img')({
    height: 40,
    width: 40,
    borderRadius: '50%',
    border: `1px solid ${COLORS.border}`,
    objectFit: 'contain'
});

const VrLine = styled(Box)(({ theme }) => ({
    height: 40,
    backgroundColor: COLORS.dividerColor,
    border: '1px solid #d0d0d0',
    margin: '0px 16px',
    [theme.breakpoints.down('sm')]: {
        margin: '0px 8px'
    }
}));
const LeftFlexBox = styled(Box)({
    display: 'flex'
});

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchDnSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchUplg = useMediaQuery(theme.breakpoints.up('lg'));
    const { user } = useSelector((state) => state.auth);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElList, setAnchorElList] = useState(null);
    const [modal, setModal] = useState({ isOpen: false, type: 'Edit Profile' });
    const openMenu = Boolean(anchorEl);
    const openList = Boolean(anchorElList);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleImageClick = (event) => {
        setAnchorElList(event.currentTarget);
    };
    const handleLogout = () => {
        setAnchorEl(null);
        dispatch(logout());
        navigate(PAGES.LOGIN.url);
    };

    const handleClientAccount = (clientId) => {
        let currentClient = user?.clientList.find((item) => item.id === clientId);
        setHeader('client_id', currentClient.id);
        dispatch(updateUser({ ...user, client: currentClient }));
        setAnchorElList(null);
    };

    
    const clientLists = useMemo(() => {
        return user?.clientList?.length ? sortByProperty(sortCategoryList(user?.clientList), 'name') : []
    }, [user?.clientList])

    return (
        <>
            {matchUpMd && (
                <LeftIconView>
                    <Logo />
                </LeftIconView>
            )}
            <ContentMainRow>
                <ContentBoxView
                    sx={{
                        margin: { xs: '7px', md: '16px' },
                        paddingLeft: matchUpMd ? '22px' : '8px',
                        paddingRight: matchUpMd ? '22px' : '8px'
                    }}
                >
                    <LeftFlexBox>
                        {!matchUpMd && (
                            <IconButton onClick={handleLeftDrawerToggle}>
                                <ICONS.Menu />
                            </IconButton>
                        )}
                        <LoopOSHeaderLogo />
                    </LeftFlexBox>
                    {matchUplg && <UpdateLocationLoop />}
                    <Box sx={{ display: 'flex' }} >
                        {user?.clientList?.length ? (
                            <>
                                <AccountElementBox>
                                    <AccountImage
                                        id="select-image"
                                        src={SERVER_URL + user.client.logo}
                                        aria-controls={openList ? 'select-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={openList ? 'true' : undefined}
                                        onClick={matchDnSM ? handleImageClick : () => { }}
                                    />

                                    {user?.clientList?.length > 1
                                        ? !matchDnSM && (
                                            <CommonMultiSelect
                                                outlineInputClass={{ top: 0 }}
                                                handleChange={(e) => handleClientAccount(e.target.value)}
                                                options={clientLists || []}
                                                name={'Accounts'}
                                                value={user.client.id}
                                                style={{ marginLeft: 8, height: '40px' }}
                                            />
                                        )
                                        : !matchDnSM && <Typography sx={{ ml: 1 }}>{user?.clientList[0].name}</Typography>}
                                    <VrLine />
                                </AccountElementBox>
                            </>
                        ) : null}
                        {matchDnSM && (
                            <Menu
                                id="select-menu"
                                anchorEl={anchorElList}
                                open={openList}
                                onClose={() => setAnchorElList(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'select-image'
                                }}
                            >
                                {clientLists &&
                                    clientLists?.map((listItem, index) => (
                                        <MenuItem key={listItem.id} onClick={() => handleClientAccount(listItem.id)}>
                                            <AccountImage sx={{ height: 30, width: 30 }} src={SERVER_URL + listItem.logo} />
                                            {listItem.name}
                                        </MenuItem>
                                    ))}
                            </Menu>
                        )}
                        <FieldView>
                            <AvatarProfileView>
                                <AvatarLink
                                    id="basic-button"
                                    aria-controls={openMenu ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openMenu ? 'true' : undefined}
                                    onClick={matchDnSM ? handleClick : () => { }}
                                >
                                    <Avatar src={user?.profile_photo ? `${SERVER_URL}${user?.profile_photo}` : null}>{user?.name?.[0]}</Avatar>
                                </AvatarLink>
                                <UserNameView>
                                    <NameText>{user && toCapitalCase(user?.name ? user?.name : user?.role)}</NameText>
                                    <ProfileActionView
                                        sx={{
                                            flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                                            alignItems: { sm: 'flex-start', md: 'center' }
                                        }}
                                    >
                                        <SubNameText onClick={() => setModal({ isOpen: true, type: 'Edit Profile' })} >Edit Profile</SubNameText>
                                        <Divider sx={{ display: { xs: 'none', sm: 'none', md: 'inline', lg: 'inline' } }} />
                                        <SubNameText onClick={handleLogout}>Sign out</SubNameText>
                                    </ProfileActionView>
                                </UserNameView>
                            </AvatarProfileView>
                        </FieldView>
                        {matchDnSM && (
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button'
                                }}
                            >
                                <MenuItem onClick={() => setModal({ isOpen: true, type: 'Edit Profile' })} >Edit Profile</MenuItem>
                                <MenuItem onClick={handleLogout}>Sign out</MenuItem>
                            </Menu>
                        )}
                    </Box>
                </ContentBoxView>
            </ContentMainRow>
            <EditProfile open={modal.isOpen} closeModal={() => setModal({ type: '', isOpen: false })} type={modal.type} />
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
