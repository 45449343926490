import { styled, Box, Typography, Skeleton } from '@mui/material';
import { IMAGES } from '../assets';
import CommonImage from '../components/CommonImage';
import { ASPECT_RATIO, CONTENT_DATA_TYPE, ORIENTATION, ORIENTATION_DATA_KEY, SERVER_URL, SOCIAL_MEDIA } from '../utils/constants';
import { COLORS } from '../utils/theme';
import { IOSSwitch } from './IOSSwitch';
import { isAddedInterationApp } from '../utils/helpers';

const CollectionBox = styled(Box)({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    border: `1px solid ${COLORS.darkGray}`,
    backgroundColor: COLORS.white,
    borderRadius: '.425rem',
    boxShadow: COLORS.cardShadow,
    padding: 12,
    cursor: 'pointer',
    position: 'relative',
    ':hover': {
        outline: `3px solid ${COLORS.primary.light}`
    }
});

const CollectionImage = styled(CommonImage)({
    width: '100%',
    objectFit: 'cover'
});

const ContentBox = styled(Box)(({ theme, isRequest }) => ({
    padding: '8px 0px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
        flexDirection: isRequest ? 'column' : 'row',
        alignItems: isRequest ? 'start' : 'center'
    },
    [theme.breakpoints.up('lg')]: {
        flexDirection: 'row'
    }
}));

const ContentNameBox = styled(Box)({
    flex: 1
});

const ContentText = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: COLORS.black,
    fontWeight: 600,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '260px',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '250px'
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '150px'
    },
    [theme.breakpoints.up('xl')]: {
        maxWidth: '96px'
    },
    [theme.breakpoints.up('xxl')]: {
        maxWidth: '150px'
    }
}));

const ContentCategoryText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black,
    fontWeight: 600,
    span: {
        fontWeight: 400
    }
});

const StatusCircle = styled('div')({
    height: 22,
    width: 22,
    borderRadius: 22
});
const StatusImage = styled('img')({
    height: 22,
    width: 22,
    marginRight: 4
});
const CostBox = styled(Box)({
    padding: '0px 6px',
    backgroundColor: COLORS.primary.light,
    borderRadius: 4
});
const Text = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: COLORS.white,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: 40,
    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%'
    }
}));

const AddOnBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: COLORS.purple,
    borderRadius: 8,
    padding: '4px 8px',
    boxShadow: COLORS.cardShadow
}));

const AddOnText = styled(Typography)(({ theme }) => ({
    fontSize: 10,
    color: COLORS.white,
    fontWeight: 'bold',
    textAlign: 'center'
}));

export const CollectionCardWidgets = ({
    onClick,
    handleChangeSwitch,
    value,
    data,
    hideToggleSwitch = false,
    hasStatus,
    orientation = ORIENTATION.LANDSCAPE,
    loading,
    availibilityCheck,
    key,
    hasAuthStatus,
    isAuthorized,
    isRequest,
    objectFit = 'cover',
    activation_status
}) => {
    let addedIntergationApp = isAddedInterationApp(data?.name_alias);
    return (
        <CollectionBox
            key={key}
            sx={availibilityCheck && { border: availibilityCheck.file_exist ? `1px solid green` : `1px solid ${COLORS.error}` }}
            onClick={() => {
                if (onClick) {
                    onClick(data);
                }
            }}
        >
            {!loading && addedIntergationApp && Boolean(data?.add_on_feature) && (
                <AddOnBox>
                    <AddOnText>ADD-ON</AddOnText>
                </AddOnBox>
            )}
            {!loading ? (
                <CollectionImage
                    style={{ width: '100%', aspectRatio: ASPECT_RATIO[orientation], objectFit }}
                    src={
                        data?.content_type === CONTENT_DATA_TYPE.IMAGE
                            ? data?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                            : `${SERVER_URL}${data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}`
                    }
                />
            ) : (
                <Skeleton variant="rectangular" style={{ width: '100%', height: orientation === ORIENTATION.LANDSCAPE ? 127 : 402 }} />
            )}
            <ContentBox {...{ isRequest }}>
                <ContentNameBox>
                    {loading ? <Skeleton width={'120px'} /> : <ContentText>{data?.name}</ContentText>}
                    {loading ? (
                        <Skeleton width={'150px'} />
                    ) : (
                        !isRequest && (
                            <ContentCategoryText>
                                Category : <span>{data?.playlist?.name}</span>
                            </ContentCategoryText>
                        )
                    )}
                </ContentNameBox>
                {hasAuthStatus ? isAuthorized ? <StatusImage src={IMAGES.CheckMark} /> : <StatusImage src={IMAGES.Attention_Icon} /> : null}
                {!hideToggleSwitch && <IOSSwitch checked={value} onChange={(e) => handleChangeSwitch(data, e)} />}
                {activation_status && addedIntergationApp ? <StatusImage src={IMAGES.CheckMark} /> : null}
                {hasStatus && <StatusCircle style={{ backgroundColor: value ? COLORS.primary.light : COLORS.statusGray }} />}
                {isRequest && (
                    <Box sx={{ display: 'flex' }}>
                        {data?.labels
                            ?.filter((val) => !!val?.label)
                            ?.map((item) => (
                                <CostBox key={item?.id} sx={{ backgroundColor: item?.color }}>
                                    {item?.label ? <Text>{item?.label || ''}</Text> : null}
                                </CostBox>
                            ))}
                        {data?.cost ? (
                            <CostBox sx={{ ml: data?.labels?.filter((val) => !!val?.label)?.length ? 1 : 0 }}>
                                <Text>{`${data?.cost}C`}</Text>
                            </CostBox>
                        ) : null}
                    </Box>
                )}
            </ContentBox>
        </CollectionBox>
    );
};
