import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { AccountDetailsHeader } from './components/AccountDetailsHeader';
import General from './components/General/General';
import Locations from './components/Location/Locations';
import Access from './components/Access/Access';
import Content from './components/Content/Content';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACCOUNT_DETAILS_TAB } from '../../../utils/constants';
import useFetch from '../../../hooks/useFetch';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import Loader from '../../../components/Loader';

const AccountsDetails = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [discardChanges, setDiscardChanges] = useState(false);
    const pathName = useLocation().pathname;
    const clientId = pathName.split('/')[pathName.split('/').length - 1];
    const { data, callFetch, isLoading } = useFetch({
        initialUrl: '/get_client_by_id',
        config: { params: { client_id: clientId } }
    });
    const clientData = data?.result;
    const navigate = useNavigate();
    const handleTab = (key) => {
        switch (key) {
            case 1:
                return (
                    <General clientData={clientData} callFetch={callFetch} isUnSavedChangesThere={(value) => setUnsavedChanges(value)} />
                );
            case 2:
                return <Locations clientId={clientId} />;
            case 3:
                return <Access clientId={clientId} />;
            case 4:
                return <Content clientId={clientId} />;
            default:
                return null;
        }
    };

    const handleChangeActiveTab = (id) => {
        setActiveTab(id);
    };

    const handleClose = () => {
        if (unsavedChanges) {
            setDiscardChanges(true);
        } else {
            navigate(-1);
        }
    };

    return (
        <Grid container direction="column" justifyContent="flex-end">
            <AccountDetailsHeader
                onClose={handleClose}
                clientData={clientData}
                tabArray={ACCOUNT_DETAILS_TAB}
                activeTab={activeTab}
                handleChangeActiveTab={handleChangeActiveTab}
            />
            {isLoading && <Loader isLoading={isLoading} />}

            {clientData && !isLoading && handleTab(activeTab)}
            {discardChanges ? (
                <AlertDialog
                    isOpen={discardChanges}
                    isClose={() => setDiscardChanges(false)}
                    handleSubmit={() => navigate(-1)}
                    title={'Unsaved Changes'}
                    description={'Are you sure you want to exit without saving?'}
                    isCancel={'No'}
                />
            ) : null}
        </Grid>
    );
};

export default AccountsDetails;
