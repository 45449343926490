import React, { useEffect, useMemo, useState } from 'react';
import { styled, Box, IconButton, Grid, Collapse, Typography } from '@mui/material';
import { CommonHeader } from '../../../widgets/CommonHeader'
import { COLORS } from '../../../utils/theme';
import useFetch from '../../../hooks/useFetch';
import { getAccessIdForPlaylistCategory, getAccessObjectFromArrayObject } from '../../../utils/helpers';
import { ICONS, IMAGES } from '../../../assets';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MasterAccessModel } from './components/MasterAccessModel';
import { ACCESS_TYPE, initialAccessList } from '../../../utils/constants';

const MainBox = styled(Box)({
    width: '100%'
});

const MainSectionRowView = styled(Grid)({
    border: `1px solid ${COLORS.border}`,
    marginTop: 12,
    borderRadius: '.425rem',
    boxShadow: COLORS.cardShadow,
    backgroundColor: COLORS.white
});
const MainSectionRowItem = styled(Grid)({
    cursor: 'pointer',
    borderTopLeftRadius: '.425rem',
    borderTopRightRadius: '.425rem',
    '& button': {
        display: 'none'
    },
    ':hover': {
        '& button': {
            display: 'flex'
        }
    }
});

const SubItemSectionRowView = styled(Grid)({
    paddingLeft: 12,
    paddingRight: 0,
    cursor: 'pointer',
    '& button': {
        display: 'none'
    },
    ':hover': {
        '& button': {
            display: 'flex'
        }
    }
});
const SectionNameGrid = styled(Grid)({
    display: 'flex',
    padding: 10
});

const StatusActionGrid = styled(Grid)({
    display: 'flex'
});

const SectionNameText = styled(Typography)({
    fontSize: 16
});

const StatusImage = styled('img')({
    height: 22,
    width: 22
});


const MasterAccess = () => {

    const [showEditModel, setShowEditModel] = useState(false);
    const [accessList, setAccessList] = useState(initialAccessList);
    const [editAccessData, setEditAccessData] = useState();
    const [isShownEditIcon, setIsShownEditIcon] = useState(false);

    const { data: accessData, callFetch: fetchMasterAccess } = useFetch({ initialUrl: `/get_master_access` });

    const clientAccess = accessData?.result;

    const { data: categoryData } = useFetch({
        initialUrl: `/get_all_category`
    });

    const lineUpItems = useMemo(() => {
        if (categoryData?.result?.length) {
            return categoryData?.result?.map((item) => {
                return {
                    id: getAccessIdForPlaylistCategory(item.id),
                    type: ACCESS_TYPE.ITEM,
                    name: item.name,
                    visibility: 1
                };
            });
        } else {
            return [];
        }
    }, [categoryData?.result]);

    useEffect(() => {
        if (lineUpItems.length) {
            const accessListClone = [...accessList];
            const presentationItemsClone = accessList[0].items;
            const indexOfLibrary = accessList[0].items?.findIndex((item) => item.id === 'library');
            presentationItemsClone[indexOfLibrary].items = lineUpItems;
            accessListClone[0].items = presentationItemsClone;
            setAccessList(accessListClone);
        }
        // eslint-disable-next-line
    }, [lineUpItems]);

    const handleCollapseSections = (mainSection, item) => {
        let tempList = [...accessList];
        const mainSectionIndex = tempList.findIndex((e) => e.id === mainSection.id);
        if (!item) {
            tempList[mainSectionIndex] = { ...tempList[mainSectionIndex], isExpand: tempList[mainSectionIndex]?.isExpand ? false : true };
        } else {
            const subSectionIndex = tempList[mainSectionIndex].items.findIndex((e) => e.id === item.id);
            tempList[mainSectionIndex].items[subSectionIndex] = {
                ...tempList[mainSectionIndex].items[subSectionIndex],
                isExpand: tempList[mainSectionIndex].items[subSectionIndex]?.isExpand ? false : true
            };
        }
        setAccessList([...tempList]);
    };

    const ActionBarCommon = ({ item }) => {
        const visibility = clientAccess ? getAccessObjectFromArrayObject(clientAccess, item.id).visibility : item.visibility;
        const status = clientAccess ? getAccessObjectFromArrayObject(clientAccess, item.id).status : item.visibility;
        return (
            <>
                <StatusActionGrid item md={2} sm={2} xs={2} lg={1} alignItems={'center'}>
                    {visibility ? <Visibility sx={{ color: 'green' }} /> : <VisibilityOff sx={{ color: 'red' }} />}
                    {item?.hasStatus && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditAccessData(item);
                                setShowEditModel(true);
                            }}
                        >
                            {status ? <StatusImage src={IMAGES.CheckMark} /> : <StatusImage src={IMAGES.RemoveCircleIcon} />}
                        </IconButton>
                    )}

                    {/* {isShownEditIcon.visibility && isShownEditIcon.id === item.id && ( */}
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setEditAccessData(item);
                            setShowEditModel(true);
                        }}
                    >
                        <ICONS.Edit />
                    </IconButton>
                    {/* )} */}
                </StatusActionGrid>
            </>
        );
    };

    return (
        <Box>
            <CommonHeader title={'Master Access'} isXSset6={true} />
            <Box sx={{ marginTop: '24px' }}>
                <MainBox sx={{ py: 2 }}>
                    <Grid container justifyContent={'space-between'}>
                        <SectionNameGrid item md={10} lg={11} sm={10} xs={7}>
                            <SectionNameText>NAME</SectionNameText>
                        </SectionNameGrid>
                        <SectionNameGrid item md={2} lg={1} sm={2} xs={3}>
                            <SectionNameText>STATUS</SectionNameText>
                        </SectionNameGrid>
                    </Grid>
                    {accessList.map((section, mainIndex) => {
                        return (
                            <MainSectionRowView key={section.id} container>
                                <MainSectionRowItem
                                    // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: section.id })}
                                    // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: section.id })}
                                    sx={{
                                        backgroundColor:
                                            isShownEditIcon.visibility && isShownEditIcon.id === section.id
                                                ? COLORS.border
                                                : section.isExpand && COLORS.primary.light
                                    }}
                                    onClick={() => {
                                        handleCollapseSections(section);
                                    }}
                                    container
                                >
                                    <SectionNameGrid item md={10} lg={11} sm={10} xs={10}>
                                        {section.isExpand ? <ICONS.ExpandLess /> : <ICONS.ExpandMore />}
                                        <SectionNameText sx={section.isExpand && { color: COLORS.white }}>{section.name}</SectionNameText>
                                    </SectionNameGrid>
                                    <ActionBarCommon item={section} />
                                </MainSectionRowItem>
                                <Collapse sx={{ width: '100%' }} in={section.isExpand}>
                                    {section.items.map((subSection, subIndex) => {
                                        if (subSection.type === ACCESS_TYPE.ITEM) {
                                            return (
                                                <Grid key={subSection.id} item md={12}>
                                                    <SubItemSectionRowView
                                                        // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: subSection.id })}
                                                        // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: subSection.id })}
                                                        sx={{
                                                            backgroundColor:
                                                                isShownEditIcon.visibility && isShownEditIcon.id === subSection.id
                                                                    ? COLORS.border
                                                                    : '',
                                                            borderTop: subIndex === 0 && `1px solid ${COLORS.border}`,
                                                            borderBottom:
                                                                subIndex === section.items.length - 1
                                                                    ? `1px solid ${COLORS.white}`
                                                                    : `1px solid ${COLORS.border}`
                                                        }}
                                                        container
                                                    >
                                                        <SectionNameGrid sx={{ paddingLeft: 4 }} item md={10} lg={11} sm={10} xs={10}>
                                                            <SectionNameText>{subSection.name}</SectionNameText>
                                                        </SectionNameGrid>
                                                        <ActionBarCommon item={subSection} />
                                                    </SubItemSectionRowView>
                                                </Grid>
                                            );
                                        }

                                        return (
                                            <Grid key={subSection.id} item md={12}>
                                                <SubItemSectionRowView
                                                    // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: subSection.id })}
                                                    // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: subSection.id })}
                                                    sx={{
                                                        backgroundColor:
                                                            isShownEditIcon.visibility && isShownEditIcon.id === subSection.id ? COLORS.border : '',
                                                        borderBottom:
                                                            subIndex === section.items.length - 1
                                                                ? `1px solid ${COLORS.white}`
                                                                : `1px solid ${COLORS.border}`
                                                    }}
                                                    onClick={() => {
                                                        handleCollapseSections(section, subSection);
                                                    }}
                                                    container
                                                >
                                                    <SectionNameGrid item md={10} lg={11} sm={10} xs={10}>
                                                        {subSection.isExpand ? (
                                                            <ICONS.ExpandLess sx={{ color: COLORS.primary.light }} />
                                                        ) : (
                                                            <ICONS.ExpandMore sx={{ color: COLORS.primary.light }} />
                                                        )}
                                                        <SectionNameText sx={{ color: COLORS.primary.light }}>{subSection.name}</SectionNameText>
                                                    </SectionNameGrid>
                                                    <ActionBarCommon item={subSection} isShownEditIcon={isShownEditIcon} />
                                                </SubItemSectionRowView>
                                                <Collapse sx={{ width: '100%' }} in={subSection.isExpand}>
                                                    {subSection.items.map((item, itemIndex) => {
                                                        return (
                                                            <Grid key={item.id} item md={12}>
                                                                <SubItemSectionRowView
                                                                    container
                                                                    // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: item.id })}
                                                                    // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: item.id })}
                                                                    sx={{
                                                                        backgroundColor:
                                                                            isShownEditIcon.visibility && isShownEditIcon.id === item.id
                                                                                ? COLORS.border
                                                                                : '',
                                                                        borderBottom:
                                                                            itemIndex === subSection.items.length - 1
                                                                                ? `1px solid ${COLORS.white}`
                                                                                : `1px solid ${COLORS.border}`
                                                                    }}
                                                                >
                                                                    <SectionNameGrid sx={{ paddingLeft: 6 }} item md={10} lg={11} sm={10} xs={9}>
                                                                        <SectionNameText>{item?.name}</SectionNameText>
                                                                    </SectionNameGrid>
                                                                    <ActionBarCommon item={item} />
                                                                </SubItemSectionRowView>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Collapse>
                                            </Grid>
                                        );
                                    })}
                                </Collapse>
                            </MainSectionRowView>
                        );
                    })}
                    {showEditModel && (
                        <MasterAccessModel
                            open={showEditModel}
                            handleClose={() => {
                                setShowEditModel(false);
                            }}
                            access={editAccessData}
                            clientAccess={clientAccess}
                            defaultAccess={accessList}
                            fetchClientAccess={fetchMasterAccess}
                        />
                    )}
                </MainBox>
            </Box>
        </Box>
    )
}

export default MasterAccess