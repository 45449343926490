import { Box, styled } from '@mui/material';
import React from 'react';
import { CommonInput } from '../../../../components/CommonInput';
import FieldLabelView from '../../../../components/FieldLabelView';
import CommonButton from '../../../../components/CommonButton';
import { messageFormValidation } from '../../../../utils/validations';
import { useFormik } from 'formik';
import { COLORS } from '../../../../utils/theme';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { USER_ROLE } from '../../../../utils/constants';

const SubmitButton = styled(CommonButton)`
    margin-top: 20px;
    height: 42px;
    font-weight: 300;
    font-size: 16px;
`;

const MessageFromContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    text-align: left;
    ${({ theme }) => theme.breakpoints.up('sm')} {
        width: 525px;
    }
`;

const StyledInput = styled(CommonInput)`
    & .MuiOutlinedInput-root {
        min-height: 50px;
    }
`;

export default function MessageForm() {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { handleChange, handleBlur, handleSubmit, values, touched, errors, resetForm } = useFormik({
        initialValues: {
            name: '',
            business_name: '',
            email: '',
            phone_no: '',
            message: ''
        },
        validationSchema: messageFormValidation,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                let result;
                const { data } = await api.support.sendSupportMessage(values);
                result = data;
                if (result.success) {
                    enqueueSnackbar(result.message, { variant: 'success' });
                } else {
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
            resetForm();
        }
    });

    return (
        <>
            <MessageFromContainer>
                <FieldLabelView label="Your name">
                    <StyledInput
                        name="name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.name}
                        error={touched.name && errors.name}
                    />
                </FieldLabelView>
                <FieldLabelView label="Practice/Business name">
                    <StyledInput
                        name="business_name"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.business_name}
                        error={touched.business_name && errors.business_name}
                    />
                </FieldLabelView>
                <FieldLabelView label="Email address">
                    <StyledInput
                        name="email"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.email}
                        error={touched.email && errors.email}
                    />
                </FieldLabelView>
                <FieldLabelView label="Phone number">
                    <StyledInput
                        name="phone_no"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.phone_no}
                        error={touched.phone_no && errors.phone_no}
                    />
                </FieldLabelView>
                <FieldLabelView label="Your message">
                    <StyledInput
                        style={{ height: 'auto' }}
                        multiline={true}
                        minRows={8}
                        name="message"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.message}
                        error={touched.message && errors.message}
                    />
                </FieldLabelView>
            </MessageFromContainer>
            <SubmitButton variant="contained" loading={loading} label="Submit" onClick={handleSubmit} disabled={user?.role === USER_ROLE.VIEWER}/>
        </>
    );
}
