import { Box, styled, Grid } from '@mui/material';
import { ContentTabBar } from '../../../../components/ContentTabBar';
import { FRAME_RATE, ORIENTATION, RESOLUTION_FORMAT_TAB, RESOLUTION_TAB } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';

const MainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const ItemTitleText = styled(Grid)({
    fontSize: 16,
    color: COLORS.black
});

export const Resolution = ({ defaultValue, setFieldValue, orientation }) => {
    const handleChangeFormatActiveTab = (item) => {
        setFieldValue('resolutionInfo', { ...defaultValue, format: item.id });
    };

    const handleChangeResolutionActiveTab = (item) => {
        setFieldValue('resolutionInfo', { ...defaultValue, resolution: item.id });
    };

    const handleChangeFrameRateActiveTab = (item) => {
        setFieldValue('resolutionInfo', { ...defaultValue, frame_rate: item.id });
    };

    const resolutions = RESOLUTION_TAB?.filter((item) => (orientation === ORIENTATION?.PORTRAIT ? item?.id !== '4k' : true));

    const framRates = FRAME_RATE[defaultValue.resolution]?.filter((item) => item?.format === defaultValue?.format);

    return (
        <Box sx={{ padding: 8, backgroundColor: COLORS.white }}>
            <MainGrid container>
                <Grid sx={{}} item xs={12} sm={12} md={12} xl={7} lg={7}>
                    <ItemTitleText sx={{ mb: 1 }}>FORMAT</ItemTitleText>
                    <ContentTabBar
                        tabArray={RESOLUTION_FORMAT_TAB}
                        activeTab={defaultValue.format}
                        handleChangeActiveTab={handleChangeFormatActiveTab}
                        useOld={true}
                    />
                    <ItemTitleText sx={{ mb: 1, mt: 4 }}>RESOLUTION</ItemTitleText>
                    <ContentTabBar
                        tabArray={resolutions}
                        activeTab={defaultValue.resolution}
                        handleChangeActiveTab={handleChangeResolutionActiveTab}
                        useOld={true}
                    />
                    <ItemTitleText sx={{ mb: 1, mt: 4 }}>FRAME RATE</ItemTitleText>
                    <ContentTabBar
                        tabArray={framRates}
                        activeTab={defaultValue.frame_rate}
                        handleChangeActiveTab={handleChangeFrameRateActiveTab}
                        useOld={true}
                    />
                </Grid>
            </MainGrid>
        </Box>
    );
};
