import React, { useMemo } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/theme';
import {
    ASPECT_RATIO,
    CONTENT_DATA_TYPE,
    CONTENT_MODULE_TYPE,
    ORIENTATION,
    ORIENTATION_DATA_KEY,
    SERVER_URL
} from '../../../../utils/constants';
import { useSelector } from 'react-redux';
import { getOriantationByLayout } from '../../../../utils/helpers';

const MainBox = styled(Box)({
    backgroundColor: COLORS.white,
    padding: '12px 0px'
});

const Title = styled(Typography)({
    width: '75%',
    fontSize: 16,
    padding: '0px 12px 12px 12px',
    color: COLORS.black,
    BorderBottom: `1px solid ${COLORS.border}`,
    wordWrap: 'break-word'
});
const ContentBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px'
});
const TitleView = styled(Box)({
    display: 'flex',
    borderBottom: `1px solid ${COLORS.darkGray}`
});
const ContentNameText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 150
});

const ContentImageBox = styled(Box)({
    width: 90,
    cursor: 'pointer'
});
const ContentImag = styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'cover'
});
const ModeBox = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 12
});
const PlaylistDetailsTooltip = ({ data }) => {
    const { locations, activeLocation } = useSelector((state) => state.clientLocation);

    const orientation = useMemo(() => {
        if (locations.length !== 0) {
            let activeLocationData = locations.find((item) => item?.id === activeLocation);
            return getOriantationByLayout(activeLocationData?.layout);
        }
        return ORIENTATION.LANDSCAPE;
    }, [locations, activeLocation]);

    const renderContent = () => {
        let width = orientation === ORIENTATION.LANDSCAPE ? 90 : 40;
        return (
            <>
                <TitleView>
                    <Title>{data?.name}</Title>
                </TitleView>
                <Box sx={{ overflowY: 'auto', maxHeight: (width / ASPECT_RATIO[orientation] + 24) * 6 }}>
                    {data?.loop_category_contents?.map((item) => {
                        return (
                            <ContentBox key={item.unique_id}>
                                <ContentImageBox style={{ width: width, aspectRatio: ASPECT_RATIO[orientation] }}>
                                    <ContentImag
                                        src={
                                            item?.content_type === CONTENT_DATA_TYPE.IMAGE
                                                ? item?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                                                : `${SERVER_URL}${
                                                      item?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || item?.landscape
                                                  }`
                                        }
                                        sx={item?.module_type === CONTENT_MODULE_TYPE.SOCIAL && { objectFit: 'contain' }}
                                    />
                                </ContentImageBox>
                                <Box sx={{ ml: 1 }}>
                                    <ContentNameText>{item?.name}</ContentNameText>
                                </Box>
                            </ContentBox>
                        );
                    })}
                </Box>

                <ModeBox sx={{ pt: 1 }}>
                    <Typography sx={{ fontSize: 12 }}>{`${data?.playback_method} | ${data?.mode}`}</Typography>
                </ModeBox>
            </>
        );
    };
    return (
        <MainBox>{data?.loop_category_contents.length > 0 ? renderContent() : <Typography sx={{ px: 2 }}>No content</Typography>}</MainBox>
    );
};

export default PlaylistDetailsTooltip;
