import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchAllForms = createAsyncThunk('feedback/fetchAllForms', async (params) => {
    const response = await api.feedback.getAllForms(params);
    return response.data.result;
});

const initialState = {
    feedbackForms: [],
};

export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllForms.fulfilled, (state, action) => {
            state.feedbackForms = action.payload;
        })
    }
});

export default feedbackSlice.reducer;