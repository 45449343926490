import { Box } from '@mui/material';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { GoogleAuthForm } from './components/GoogleAuthForm';
import { FacebookAuthForm } from './components/FacebookAuthForm';

export const SocialMedia = () => {
    return (
        <Box>
            <CommonHeader title={'Social Media'} isXSset6={true} />
            <GoogleAuthForm />
            <FacebookAuthForm />
        </Box>
    );
};
