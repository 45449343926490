import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fontSize: 80,
    activeColor: '#000000',
    opacity: 100,
    fontStyle: 'normal',
    fontWeight: 400,
    activeTextAlignment: 'center',
    actionFontFamily: 'Open Sans',
    gradient: 'vertical',
    firstGradientColor: '#d8d8d8',
    secondGradientColor: '#d8d8d8',
    isStopPropagation: false,
};

export const editorActionSlice = createSlice({
    name: 'editorAction',
    initialState,
    reducers: {
        handleChangeAction: (state, action) => {
            const { name, value } = action.payload
            return {
                ...state, [name]: value
            }
        },
        modifyEditorConfig: (state, action) => {
            return {
                ...state, ...action.payload
            }
        },
        stopPropagation: (state, action) => {
            return { ...state, isStopPropagation: action.payload }
        }
    }
})

export const { handleChangeAction, modifyEditorConfig, stopPropagation } = editorActionSlice.actions

export default editorActionSlice.reducer;