import { Box, Grid, styled } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../../../utils/theme';
import UploadContent from './UploadContent';
import { CONTENT_DATA_TYPE, ORIENTATION, ORIENTATION_DATA_KEY, SkeletonArray } from '../../../../../utils/constants';
import CommonButton from '../../../../../components/CommonButton';
import { CollectionCardWidgets } from '../../../../../widgets/CollectionCardWidgets';
import useFetch from '../../../../../hooks/useFetch';
import EditContent from './EditContent';
import { useParams } from 'react-router-dom';
import NoDataFound from '../../../../../components/NoDataFound';
import { useDispatch, useSelector } from 'react-redux';
import { confirmationUpload, fetchAllMediaServers } from '../../../../../redux/reducers/videoSlice';
const MainBox = styled(Box)({
    padding: '24px 0px',
    width: '100%'
});

const ContentAccessHeader = styled(Box)({
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
    alignItems: 'center',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem'
});
const LandscapeBox = styled(Box)({
    height: 28,
    width: 46,
    border: `3px solid ${COLORS.black}`,
    borderRadius: 6,
    cursor: 'pointer'
});

const PortraitBox = styled(Box)({
    height: 46,
    width: 28,
    border: `3px solid ${COLORS.black}`,
    borderRadius: 6,
    cursor: 'pointer'
});
const OrientationButtonBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});
const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: 100,
    borderRadius: 0
});

const CollectionVideoGrid = styled(Grid)({
    padding: '24px 0'
});

const UploadConfirmation = styled(Box)({
    marginRight: 16,
    display: 'flex',
    alignItems: 'start'
})
const ConfirmationTitle = styled('span')({
    color: COLORS.primary.light,
    cursor: 'pointer',
    fontSize: 16,
    "&:hover": {
        textDecoration: 'underline'
    }
})

const LastConfirmation = styled('span')({
    color: COLORS.primary.gray,
    fontSize: 12
})

const StyledProgressView = styled(CircularProgress)({
    marginLeft: 8,
    marginTop: 4
});

const Content = ({ clientId }) => {
    const dispatch = useDispatch()
    const mediaDirectories = useSelector(({ video }) => video?.mediaDirectories)
    const [showUploadContentModal, setShowUploadContentModal] = useState(false);
    const [showEditContentModal, setEditContentModal] = useState({ isOpen: false, id: '' });
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const [isUploadLoading, setUploadLoading] = useState(false);
    const { id } = useParams();

    const {
        data: mediaContent,
        callFetch: fetchMediaContent,
        isLoading
    } = useFetch({
        initialUrl: `/get_all_media`,
        skipOnStart: true
    });

    const { data, callFetch, isLoading: isClientLoading } = useFetch({
        initialUrl: '/get_client_by_id',
        config: { params: { client_id: clientId } }
    });

    useEffect(() => {
        dispatch(fetchAllMediaServers());
    }, [dispatch])

    useEffect(() => {
        const params = {
            orientation: activeOrientation,
            client_id: id,
            custom_content: 1,
            check_host_url: 1,
            library_status: 1,
            content_type: CONTENT_DATA_TYPE.VIDEO
        };
        fetchMediaContent({ params });
    }, [fetchMediaContent, activeOrientation, id]);

    const handleChangeOrientation = (value) => {
        setActiveOrientation(value);
    };

    const directories = useMemo(() => {
        return mediaDirectories?.map(({ id, mediaUrl }) => ({ id, name: mediaUrl, value: id }))
    }, [mediaDirectories])

    const handleUpload = async () => {
        if (isUploadLoading || isClientLoading) return
        setUploadLoading(true)
        dispatch(confirmationUpload({ client_id: clientId })).then(() => {
            callFetch()
            setUploadLoading(false)
        })
    }


    return (
        <MainBox>
            <ContentAccessHeader>
                <OrientationButtonBox>
                    <LandscapeBox
                        onClick={() => handleChangeOrientation(ORIENTATION.LANDSCAPE)}
                        style={activeOrientation === ORIENTATION.LANDSCAPE ? { backgroundColor: COLORS.primary.light } : {}}
                    />
                    <PortraitBox
                        style={activeOrientation === ORIENTATION.PORTRAIT ? { backgroundColor: COLORS.primary.light } : {}}
                        onClick={() => handleChangeOrientation(ORIENTATION.PORTRAIT)}
                        sx={{ mx: 2 }}
                    />
                </OrientationButtonBox>

                <Box sx={{ display: 'flex', justifyItems: 'center' }} >
                    <UploadConfirmation>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', }} >
                            <ConfirmationTitle onClick={() => handleUpload()} >Send Upload Confirmation</ConfirmationTitle>
                            <LastConfirmation>{(isUploadLoading || isClientLoading) ? 'Loading...' : data?.result?.last_uploaded_message ? `Last sent ${data?.result?.last_uploaded_message}` : null}</LastConfirmation>
                        </Box>
                        {isUploadLoading || isClientLoading ? <StyledProgressView size={18} /> : null}
                    </UploadConfirmation>
                    <StyledButton
                        onClick={() => {
                            setShowUploadContentModal(!showUploadContentModal);
                        }}
                        variant="contained"
                        label={'Upload'}
                    />
                </Box>
            </ContentAccessHeader>
            <CollectionVideoGrid container spacing={3}>
                {isLoading ? (
                    SkeletonArray.map((skeleton) => {
                        return (
                            <Grid key={skeleton} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                <CollectionCardWidgets
                                    orientation={activeOrientation}
                                    loading={isLoading}
                                    hasStatus={true}
                                    hideToggleSwitch={true}
                                />
                            </Grid>
                        );
                    })
                ) : mediaContent && mediaContent?.result.length ? (
                    mediaContent?.result?.map((item) => {
                        return (
                            <Grid item key={item.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                                <CollectionCardWidgets
                                    orientation={activeOrientation}
                                    hideToggleSwitch={true}
                                    hasStatus={true}
                                    onClick={() => {
                                        setEditContentModal({ isOpen: true, id: item.id });
                                    }}
                                    value={item.library_status}
                                    data={item}
                                    availibilityCheck={{ file_exist: item[ORIENTATION_DATA_KEY?.file_exist?.[activeOrientation]] }}
                                />
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <NoDataFound message={'Content Not Found.'} />
                    </Grid>
                )}
            </CollectionVideoGrid>
            <UploadContent {...{ directories }}
                orientation={activeOrientation}
                open={showUploadContentModal}
                fetchMediaContent={fetchMediaContent}
                handleClose={() => {
                    setShowUploadContentModal(!showUploadContentModal);
                }}
            />
            {
                showEditContentModal.isOpen && showEditContentModal.id && (
                    <EditContent {...{ directories }}
                        fetchMediaContent={fetchMediaContent}
                        contentId={showEditContentModal?.id}
                        orientation={activeOrientation}
                        open={showEditContentModal.isOpen}
                        handleCloseEvent={() => {
                            setEditContentModal({ isOpen: false, id: '' });
                        }}
                    />
                )
            }
        </MainBox >
    );
};

export default Content;
