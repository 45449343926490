import { Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ASPECT_RATIO, SERVER_URL } from '../../utils/constants';
import Vimeo from '@vimeo/player';
import { IMAGES } from '../../assets';

const MainBox = styled(Box)({
    position: 'relative'
});

const PlaceHolderImageVier = styled(Box)({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0
});

const ThumbnailImage = styled('img')({
    height: '100%',
    width: '100%',
    position: 'absolute'
});

const PlayButtonView = styled(Box)({
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.3)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
});
const PlayIcon = styled('img')({
    height: 40,
    width: 40
});

export const VimeoPlayer = ({ orientation, thumbnail, videoUrl, width }) => {
    const [player, setPlayer] = useState();
    const [isPlaying, setIsPlaying] = useState(false);
    // const videoId = getVimeoIdFromUrl(videoUrl);

    useEffect(() => {
        const mainBox = document.getElementById('vimeo-player');

        const options = {
            id: videoUrl, // replace with your Vimeo video ID
            // id: 'https://player.vimeo.com/video/76979871?h=8272103f6e', // replace with your Vimeo video ID
            width: mainBox.offsetWidth,
            height: mainBox.offsetHeight,
            autoplay: false
        };

        let vimeoPlayer = new Vimeo('vimeo-player', options);
        vimeoPlayer.pause();
        // vimeoPlayer.setLoop(false);
        setPlayer(vimeoPlayer);
    }, [videoUrl]);

    const handlePlayVideo = () => {
        setIsPlaying(true);
        player.play();
    };
    return (
        <MainBox id="main-vimeo-box" style={{ width: width || '100%', aspectRatio: ASPECT_RATIO[orientation] }}>
            <div style={{ height: '100%', width: '100%' }} id="vimeo-player"></div>

            {/* <VideoBox controls={true} src={videoUrl}></VideoBox> */}
            {!isPlaying && (
                <PlaceHolderImageVier onClick={handlePlayVideo}>
                    <ThumbnailImage src={SERVER_URL + thumbnail} />
                    <PlayButtonView>
                        <PlayIcon src={IMAGES.PlayIcon} alt="play" />
                    </PlayButtonView>
                </PlaceHolderImageVier>
            )}
        </MainBox>
    );
};
