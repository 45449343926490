import { Box, Grid, styled, Typography, Button } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { COLORS } from '../../../../utils/theme';
import VideoPlayer from '../../../../components/VideoPlayer';
import { ASPECT_RATIO, CONTENT_MODULE_TYPE, ORIENTATION, ORIENTATION_DATA_KEY, SERVER_URL } from '../../../../utils/constants';
import { IMAGES } from '../../../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddVideoToLibrary, fetchRemoveVideoToLibrary } from '../../../../redux/reducers/librarySlice';
import CommonModal from '../../../../components/CommonModal';
import { checkAllVideoStatus, checkContentStatusInLibrary, isVimeoUrl, isYouTubeVideoUrl } from '../../../../utils/helpers';
import { VimeoPlayer } from '../../../../components/VimeoPlayer';
import { YoutubePlayer } from '../../../../components/YoutubePlayer';
import { ShareButton } from '../../../../widgets/ShareButton';
import { ViewerPermission } from '../../../../components/ViewerPermission';

const FieldLabel = styled(Typography)({
    fontSize: 20,
    fontWeight: 'bold',
    color: COLORS.black,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 8
});

const MainContent = styled(Grid)({
    padding: '14px 0px'
});

const VedioContent = styled(Grid)({});
const VedioTitle = styled(Typography)({
    fontSize: 32,
    fontWeight: 'bold'
});
const VedioDetail = styled(Box)({
    display: 'flex'
});
const VedioDescription = styled(Typography)({
    fontSize: 20,
    wordWrap: 'break-word'
});
const ButtonStyle = styled(Box)({
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
});

const ThumbnailImage = styled('img')({
    width: '100%'
});

const MainAddImage = styled('img')({
    height: 20,
    width: 20,
    objectFit: 'contain'
});
const VideoAddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});

const Text = styled(Typography)({
    padding: '8px 0px',
    fontSize: 18,
    fontStyle: 'italic',
    color: COLORS.darkGray
});

const GroupContainer = styled(Box)({
    paddingLeft: '0',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto'
});

const GroupItem = styled(Box)({
    padding: '16px 32px'
});

export default function VideoContentPopUp({ open, handleClose, data, orientation, isHide }) {
    const dispatch = useDispatch();
    const { librarys } = useSelector((state) => state.library);
    const { categorys } = useSelector((state) => state.category);
    const { client } = useSelector((state) => state.auth);
    const adminCategoryCurationMode = categorys.find((item) => item.id === data?.category_id)?.curate_playlist;
    const clientCategoryCurationMode = useMemo(() => {
        return client?.curate_list?.[[data?.category_id]];
    }, [data?.category_id, client?.curate_list]);

    const addAllToLibrary = () => {
        dispatch(fetchAddVideoToLibrary(data?.contents));
    };

    const removeAllToLibrary = () => {
        dispatch(fetchRemoveVideoToLibrary(data?.contents));
    };

    const addSingleToLibrary = (item) => {
        dispatch(fetchAddVideoToLibrary([item]));
    };

    const removeSingleToLibrary = (item) => {
        dispatch(fetchRemoveVideoToLibrary([item]));
    };

    const handleWidth = useCallback((isGroup, isLandscapeOnly) => {
        if (isLandscapeOnly) {
            return isGroup ? '70%' : 1140;
        } else return isGroup ? '70%' : 800;
    }, []);

    const handleGridSize = useCallback(
        (video) => {
            if (video) {
                return orientation === ORIENTATION.LANDSCAPE ? 6 : 4;
            } else return orientation === ORIENTATION.LANDSCAPE ? 6 : 8;
        },
        [orientation]
    );

    return (
        <CommonModal
            id="video-content"
            open={open}
            onClose={handleClose}
            style={{
                width: {
                    xs: '90%',
                    sm: '80%',
                    md: 900,
                    lg: handleWidth(data?.module_type === CONTENT_MODULE_TYPE.GROUP, orientation === ORIENTATION.LANDSCAPE)
                },
                height: data?.module_type === CONTENT_MODULE_TYPE.GROUP ? 'calc(100vh - 268px)' : 'auto'
            }}
        >
            <MainContent container>
                <Grid
                    item
                    xs={12}
                    md={data?.module_type === CONTENT_MODULE_TYPE.GROUP ? (orientation === ORIENTATION.LANDSCAPE ? 4 : 5) : 12}
                >
                    <Grid container spacing={2}>
                        <VedioContent order={{ xs: 2, md: 1 }} xs={12} md={data?.module_type === CONTENT_MODULE_TYPE.GROUP ? 12 : 5} item>
                            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}>
                                <Box>
                                    <VedioTitle>{data?.name}</VedioTitle>
                                    <VedioDetail sx={{ mt: 1 }}>
                                        <Typography
                                            sx={{ fontSize: '17px', marginRight: '20px' }}
                                        >{`Category: ${data?.playlist?.name}`}</Typography>
                                        {data?.module_type !== CONTENT_MODULE_TYPE.GROUP && (
                                            <Typography sx={{ fontSize: '17px' }}>{`Duration: ${data?.duration}`}</Typography>
                                        )}
                                    </VedioDetail>
                                    <VedioDescription sx={{ mt: 1 }}>{data?.description}</VedioDescription>
                                    {adminCategoryCurationMode && clientCategoryCurationMode ? (
                                        <Text>
                                            {data?.playlist?.name} content is currently being curated on your behalf. Disable curation for
                                            this category in your Lineup if you would like to manage this content.
                                        </Text>
                                    ) : null}
                                </Box>
                                <Box>
                                    <ViewerPermission>
                                        <ButtonStyle>
                                            {data?.module_type !== CONTENT_MODULE_TYPE.GROUP
                                                ? checkContentStatusInLibrary(data, librarys)
                                                    ? (!adminCategoryCurationMode || !clientCategoryCurationMode) && (
                                                          <Button onClick={() => removeSingleToLibrary(data)}>
                                                              <VideoAddImage src={IMAGES.RemoveCircleIcon} />
                                                              <FieldLabel>LINEUP</FieldLabel>
                                                          </Button>
                                                      )
                                                    : (!adminCategoryCurationMode || !clientCategoryCurationMode) && (
                                                          <Button onClick={() => addSingleToLibrary(data)}>
                                                              <VideoAddImage src={IMAGES.AddCircleIcon} />
                                                              <FieldLabel>LINEUP</FieldLabel>
                                                          </Button>
                                                      )
                                                : null}

                                            {data?.module_type !== CONTENT_MODULE_TYPE.GROUP && data?.landscape_share_url && (
                                                <ShareButton
                                                    iconFontSize={18}
                                                    textFontSize={18}
                                                    {...{ isHide }}
                                                    shareUrl={
                                                        data?.[ORIENTATION_DATA_KEY?.share_url?.[orientation]] ||
                                                        data?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                                                    }
                                                />
                                            )}
                                        </ButtonStyle>
                                    </ViewerPermission>
                                </Box>
                            </Box>
                        </VedioContent>
                        <Grid item order={{ xs: 1, md: 2 }} xs={12} md={data?.module_type === CONTENT_MODULE_TYPE.GROUP ? 12 : 7}>
                            <Box>
                                {data?.module_type !== CONTENT_MODULE_TYPE.GROUP ? (
                                    isVimeoUrl(data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video) ? (
                                        <VimeoPlayer
                                            videoUrl={data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video}
                                            thumbnail={data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}
                                            orientation={orientation}
                                        />
                                    ) : isYouTubeVideoUrl(data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video) ? (
                                        <YoutubePlayer
                                            videoUrl={data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || data?.landscape_video}
                                            thumbnail={data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}
                                            orientation={orientation}
                                        />
                                    ) : (
                                        <VideoPlayer
                                            videoUrl={
                                                data?.[ORIENTATION_DATA_KEY?.video_hd?.[orientation]] ||
                                                data?.[ORIENTATION_DATA_KEY?.video?.[orientation]] ||
                                                data?.landscape_video
                                            }
                                            thumbnail={data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}
                                            orientation={orientation}
                                        />
                                    )
                                ) : (
                                    <Box style={{ width: '100%', aspectRatio: ASPECT_RATIO[orientation] }}>
                                        <ThumbnailImage
                                            style={{ width: '100%', height: '100%' }}
                                            src={SERVER_URL + data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]]}
                                        />
                                    </Box>
                                )}
                                {data.module_type === CONTENT_MODULE_TYPE.GROUP ? (
                                    <ButtonStyle sx={{ mt: 2 }}>
                                        {checkAllVideoStatus(data?.contents, librarys)
                                            ? (!adminCategoryCurationMode || !clientCategoryCurationMode) &&
                                              data?.contents.length > 0 && (
                                                  <Button onClick={removeAllToLibrary}>
                                                      <VideoAddImage src={IMAGES.RemoveCircleIcon} />
                                                      <FieldLabel>ALL FROM LINEUP</FieldLabel>
                                                  </Button>
                                              )
                                            : (!adminCategoryCurationMode || !clientCategoryCurationMode) &&
                                              data?.contents.length > 0 && (
                                                  <Button onClick={addAllToLibrary}>
                                                      <VideoAddImage src={IMAGES.AddCircleIcon} />
                                                      <FieldLabel>ALL TO LINEUP</FieldLabel>
                                                  </Button>
                                              )}
                                    </ButtonStyle>
                                ) : null}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {data?.module_type === CONTENT_MODULE_TYPE.GROUP ? (
                    <Grid
                        item
                        xs={12}
                        md={data?.module_type === CONTENT_MODULE_TYPE.GROUP ? (orientation === ORIENTATION.LANDSCAPE ? 8 : 7) : 12}
                    >
                        <GroupContainer>
                            <Grid container spacing={2}>
                                {data?.contents.map?.((item) => {
                                    return (
                                        <Grid item xs={12} key={item?.id}>
                                            <GroupItem sx={{ padding: { xs: '16px 0px 0px 0px', md: '0px 0px 0px 32px' } }}>
                                                <Box sx={{ border: `1px solid ${COLORS.border}`, borderRadius: '0.425rem' }}>
                                                    <Grid container padding={2} spacing={2}>
                                                        <VedioContent xs={handleGridSize()} item>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    height: '100%',
                                                                    justifyContent: 'space-between'
                                                                }}
                                                            >
                                                                <Box>
                                                                    <VedioTitle sx={{ fontSize: 20 }}>{item?.name}</VedioTitle>
                                                                    <VedioDetail sx={{ mt: 1 }}>
                                                                        <Typography
                                                                            sx={{ fontSize: '14px', marginRight: '20px' }}
                                                                        >{`Category: ${data?.playlist?.name}`}</Typography>

                                                                        <Typography
                                                                            sx={{ fontSize: '14px' }}
                                                                        >{`Duration: ${item?.duration}`}</Typography>
                                                                    </VedioDetail>
                                                                    <VedioDescription sx={{ mt: 1, fontSize: 16 }}>
                                                                        {item?.description}
                                                                    </VedioDescription>
                                                                    {adminCategoryCurationMode && clientCategoryCurationMode ? (
                                                                        <Text>
                                                                            {data?.playlist?.name} content is currently being curated on
                                                                            your behalf. Disable curation for this category in your Lineup
                                                                            if you would like to manage this content.
                                                                        </Text>
                                                                    ) : null}
                                                                </Box>
                                                                <Box>
                                                                    <ViewerPermission>
                                                                        <ButtonStyle>
                                                                            {checkContentStatusInLibrary(item, librarys)
                                                                                ? (!adminCategoryCurationMode ||
                                                                                      !clientCategoryCurationMode) && (
                                                                                      <Button
                                                                                          onClick={() => removeSingleToLibrary(item)}
                                                                                          sx={{ paddingBottom: 0 }}
                                                                                      >
                                                                                          <MainAddImage src={IMAGES.RemoveCircleIcon} />
                                                                                          <FieldLabel sx={{ fontSize: 17 }}>
                                                                                              LINEUP
                                                                                          </FieldLabel>
                                                                                      </Button>
                                                                                  )
                                                                                : (!adminCategoryCurationMode ||
                                                                                      !clientCategoryCurationMode) && (
                                                                                      <Button
                                                                                          onClick={() => addSingleToLibrary(item)}
                                                                                          sx={{ paddingBottom: 0 }}
                                                                                      >
                                                                                          <MainAddImage src={IMAGES.AddCircleIcon} />
                                                                                          <FieldLabel sx={{ fontSize: 17 }}>
                                                                                              LINEUP
                                                                                          </FieldLabel>
                                                                                      </Button>
                                                                                  )}
                                                                            {item?.landscape_share_url ? (
                                                                                <ShareButton
                                                                                    {...{ isHide }}
                                                                                    iconFontSize={17}
                                                                                    textFontSize={17}
                                                                                    shareUrl={
                                                                                        item?.[
                                                                                            ORIENTATION_DATA_KEY?.share_url?.[orientation]
                                                                                        ] ||
                                                                                        item?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                                                                                    }
                                                                                />
                                                                            ) : null}
                                                                        </ButtonStyle>
                                                                    </ViewerPermission>
                                                                </Box>
                                                            </Box>
                                                        </VedioContent>
                                                        <Grid xs={handleGridSize(true)} item>
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    justifyContent: orientation === ORIENTATION.PORTRAIT ? 'end' : 'unset'
                                                                }}
                                                            >
                                                                {isVimeoUrl(
                                                                    item?.[ORIENTATION_DATA_KEY?.video?.[orientation]] ||
                                                                        item?.landscape_video
                                                                ) ? (
                                                                    <VimeoPlayer
                                                                        width={orientation === ORIENTATION.PORTRAIT ? 140 : '100%'}
                                                                        videoUrl={
                                                                            item?.[ORIENTATION_DATA_KEY?.video?.[orientation]] ||
                                                                            item?.landscape_video
                                                                        }
                                                                        thumbnail={
                                                                            item?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] ||
                                                                            item?.landscape_thumbnail
                                                                        }
                                                                        orientation={orientation}
                                                                    />
                                                                ) : isYouTubeVideoUrl(
                                                                      item?.[ORIENTATION_DATA_KEY?.video?.[orientation]] ||
                                                                          item?.landscape_video
                                                                  ) ? (
                                                                    <YoutubePlayer
                                                                        width={orientation === ORIENTATION.PORTRAIT ? 140 : '100%'}
                                                                        videoUrl={
                                                                            item?.[ORIENTATION_DATA_KEY?.video?.[orientation]] ||
                                                                            item?.landscape_video
                                                                        }
                                                                        thumbnail={
                                                                            item?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] ||
                                                                            item?.landscape_thumbnail
                                                                        }
                                                                        orientation={orientation}
                                                                    />
                                                                ) : (
                                                                    <VideoPlayer
                                                                        width={orientation === ORIENTATION.PORTRAIT ? 140 : '100%'}
                                                                        videoUrl={
                                                                            item?.[ORIENTATION_DATA_KEY?.video_hd?.[orientation]] ||
                                                                            item?.[ORIENTATION_DATA_KEY?.video?.[orientation]] ||
                                                                            item?.landscape_video
                                                                        }
                                                                        thumbnail={
                                                                            item?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] ||
                                                                            item?.landscape_thumbnail
                                                                        }
                                                                        orientation={orientation}
                                                                    />
                                                                )}
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </GroupItem>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </GroupContainer>
                    </Grid>
                ) : null}
            </MainContent>
        </CommonModal>
    );
}
