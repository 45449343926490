import React from 'react'
import { Box, Popover, styled, Typography } from '@mui/material';
import { CustomColorPicker } from '../../../../CustomColorPicker'
import { BACKGROUND_ACTION_TYPE, BG_COLORS } from '../../../../../utils/constants';
import { ICONS } from '../../../../../assets';

const MainBox = styled(Box)({
    width: 300,
    padding: 12
});

const ColorBox = styled(Box)({
    width: '100%',
    marginTop: 16,
    padding: '0.5rem 1.10rem'
});

const Title = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textAlign: 'center'
});

const ColorsBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
});

const ColorCodeBox = styled(Box)({
    width: 32,
    height: 32,
    cursor: 'pointer',
    margin: '8px',
    border: '3px solid black',
    borderRadius: 4
});

export const BackgroundColorPopup = ({ id, open, anchorEl, handleClose, handleSelectBackgrounds, ...props }) => {
    return (
        <Popover
            {...props}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <MainBox>
                <Title>Color Background</Title>
                <ColorBox>
                    <Typography sx={{ fontWeight: 'bold', ml: 1 }}>Solid Colors</Typography>
                    <ColorsBox>
                        {BG_COLORS?.map((value) => (
                            <ColorCodeBox
                                onClick={() => handleSelectBackgrounds({ type: BACKGROUND_ACTION_TYPE.COLOR, value })}
                                key={value}
                                sx={{ background: value }}
                            />
                        ))}
                        <CustomColorPicker handleColorChange={(e) => handleSelectBackgrounds({ type: BACKGROUND_ACTION_TYPE.COLOR, value: e.hex })} sx={{ padding: 0, minWidth: 'unset' }} component={
                            <ColorCodeBox>
                                <ICONS.Add sx={{ color: 'black' }} />
                            </ColorCodeBox>
                        } anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left'
                        }} />
                    </ColorsBox>
                </ColorBox>
            </MainBox>
        </Popover>
    );
};
