import { Box, Grid, styled, Typography, Checkbox, FormHelperText } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { COLORS } from '../../../../../utils/theme';
import { useFormik } from 'formik';
import { CommonInput } from '../../../../../components/CommonInput';
import ImagePlaceholder from '../../../../../widgets/ImagePlaceholder';
import { DeleteButton } from '../../../../../widgets/CommonWidgets';
import CommonButton from '../../../../../components/CommonButton';
import useFetch from '../../../../../hooks/useFetch';
import { useSnackbar } from 'notistack';
import { api } from '../../../../../api';
import { adminEditContentValidation } from '../../../../../utils/validations';
import {
    CONTENT_DATA_TYPE,
    CONTENT_MODULE_TYPE,
    DELETE_CONFIRMATION,
    ORIENTATION,
    ORIENTATION_DATA_KEY,
    SERVER_URL,
    UPDATE_CONFIRMATION
} from '../../../../../utils/constants';
import { useParams } from 'react-router-dom';
import FieldLabelView from '../../../../../components/FieldLabelView';
import CommonModal from '../../../../../components/CommonModal';
import { extractDomain, getFileResolution, getSubFolders, sortCategoryList, splitTrimStringifyTags } from '../../../../../utils/helpers';
import { CommonMultiSelect } from '../../../../../components/CommonMultiSelect';

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 5
});
const Text = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    fontWeight: 600,
    marginRight: 5
});

const StyledInput = styled(CommonInput)({
    flex: 1
});
const ThumnailBox = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        paddingBottom: '10px',
        display: 'flex',
        alignItems: 'flex-start'
    }
}));

const ThumbnailImage = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-between'
    }
}));
const DetailBox = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginLeft: 16
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: 10
    }
}));
const ButtonWrap = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
});

const CategoryDurationBox = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'space-evenly'
    }
}));
const StyledButton = styled(CommonButton)(({ theme }) => ({
    color: COLORS.black,
    fontWeight: 100
}));
const TextBox = styled(Box)({
    display: 'flex',
    flexDirection: 'row'
});

export default function EditContent({ open, directories, handleCloseEvent, contentId, orientation, fetchMediaContent }) {
    const [isDisabled, setDisabledOrNot] = useState({ hostURLChecked: false, hostURLCheckedForHD: false, vimeoURLChecked: false, durationChecked: false });
    const [loading, setLoading] = useState({ submit: false, delete: false });
    const { id } = useParams();

    const { enqueueSnackbar } = useSnackbar();
    const { data } = useFetch({
        initialUrl: '/get_media_by_id',
        config: { params: { id: Number(contentId) } }
    });
    const contentData = data?.result;

    const { data: categoryData } = useFetch({
        initialUrl: '/get_all_category'
    });

    const categoryList = () => {
        return categoryData?.result ? sortCategoryList(categoryData?.result) : []
    }

    const { handleBlur, handleChange, values, touched, errors, setValues, setFieldValue, handleSubmit, validateForm } = useFormik({
        initialValues: {
            name: '',
            category: '',
            resolution: '',
            resolution_hd: '',
            directory: '',
            description: '',
            directory_hd: '',
            duration: '',
            hostUrl: '',
            hostUrlHD: '',
            vimeoUrl: '',
            isDuration: false,
            isHostUrl: false,
            isHostUrlHD: false,
            isVimeoUrl: false,
            uploaded_date: '',
            thumbnail: '',
            thumbnail_file: '',
            sub_folder: '',
            sub_folder_hd: '',
            tags: '',
            video: '',
            video_hd: '',
            video_url: '',
            video_url_hd: '',
        },
        validationSchema: adminEditContentValidation,

        onSubmit: async (values) => {
            let tagsArray = splitTrimStringifyTags(values.tags);
            setLoading({ ...loading, submit: true });
            const formData = new FormData();
            formData.append('id', contentId);
            formData.append('name', values.name);
            formData.append('category_id', values.category || Number(process.env.REACT_APP_MARKETING_CATEGORY_ID));
            formData.append('sub_folder', values.sub_folder);
            formData.append('media_server', values.directory);
            formData.append('orientation', orientation);
            formData.append('module_type', CONTENT_MODULE_TYPE.CUSTOM_CONTENT);
            formData.append('content_type', CONTENT_DATA_TYPE.VIDEO);
            formData.append('tags', tagsArray);
            formData.append('description', values.description);
            if(values.sub_folder_hd){
                formData.append('sub_folder_hd', values.sub_folder_hd);
            }
            if (values.thumbnail_file) {
                formData.append('thumbnail', values.thumbnail_file);
            }
            if (contentData?.duration !== values.duration) {
                formData.append('duration', values.duration);
            }
            if (contentData?.[ORIENTATION_DATA_KEY?.video?.[orientation]] !== values.hostUrl) {
                formData.append('host_url', decodeURI(values.hostUrl));
            }
            if (contentData?.[ORIENTATION_DATA_KEY?.video_hd?.[orientation]] !== values.hostUrlHD) {
                formData.append('host_url_hd', decodeURI(values.hostUrlHD));
            }
            if (contentData?.vimeo_url !== values.vimeoUrl) {
                formData.append('vimeo_url', values.vimeoUrl);
            }
            if (values.directory_hd) {
                formData.append('media_server_hd', values.directory_hd);
            }
            if (values.video_hd) {
                formData.append('video_hd', values.video_hd);
                formData.append('resolution_hd', values.resolution_hd);
            }
            if (values.video) {
                formData.append('video', values.video);
                formData.append('resolution', values.resolution);
            }
            try {
                const { data } = await api.mediaContent.edit(formData);
                if (data.success) {
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(data.message, { variant: 'success' });
                    fetchMediaContent({
                        params: {
                            orientation: orientation,
                            client_id: id,
                            check_host_url: 1,
                            custom_content: 1,
                            library_status: 1,
                            content_type: CONTENT_DATA_TYPE.VIDEO
                        }
                    });
                    handleCloseEvent();
                } else {
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading({ ...loading, submit: false });
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    const getDirectory = (data = [], value = '', url='') => {
        const cloneData = data?.find(val => value ? val.name === value : extractDomain(url))
        return cloneData ? cloneData?.value : null
    }

    useEffect(() => {
        let tagsArray = contentData?.tags?.map((item) => item?.tag_names?.name);
        if (contentData) {
            setValues({
                name: contentData?.name,
                sub_folder: contentData?.sub_folder ? contentData?.sub_folder : getSubFolders(contentData?.[ORIENTATION_DATA_KEY?.video?.[orientation]]),
                sub_folder_hd: contentData?.sub_folder_hd ? contentData?.sub_folder_hd : getSubFolders(contentData?.[ORIENTATION_DATA_KEY?.video_hd?.[orientation]]),
                category: contentData?.category_id,
                duration: contentData?.duration,
                directory_hd: getDirectory(directories, contentData?.directory_hd, contentData?.[ORIENTATION_DATA_KEY?.video_hd?.[orientation]]),
                directory: getDirectory(directories, contentData?.directory, contentData?.[ORIENTATION_DATA_KEY?.video?.[orientation]]),
                hostUrl: contentData?.[ORIENTATION_DATA_KEY?.video?.[orientation]] || '',
                hostUrlHD: contentData?.[ORIENTATION_DATA_KEY?.video_hd?.[orientation]] || '',
                vimeoUrl: contentData?.vimeo_url,
                uploaded_date: new Date(contentData?.uploaded_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                }),
                video_url: orientation === ORIENTATION.LANDSCAPE ? contentData?.landscape_video : contentData?.portrait_video,
                video_url_hd: orientation === ORIENTATION.LANDSCAPE ? contentData?.landscape_video_hd : contentData?.portrait_video_hd,
                thumbnail: SERVER_URL + (contentData?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || (orientation === ORIENTATION.LANDSCAPE ? contentData?.landscape_thumbnail : contentData?.portrait_thumbnail)),
                tags: tagsArray.join(','),
                description: contentData?.description || '',
                resolution: contentData?.resolution
                    ? contentData?.resolution
                    : ORIENTATION.LANDSCAPE === orientation
                        ? '1920x1080'
                        : '1080x1920'
            });
        }
    }, [contentData, setValues]);

    const handleCheckBoxValue = (e, name) => {
        let checked = { ...isDisabled, [name]: e.target.checked };
        setDisabledOrNot(checked);
    };
    const handleDelete = async () => {
        setLoading({ ...loading, delete: true });
        try {
            const { data } = await api.mediaContent.delete(contentId);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                fetchMediaContent({
                    params: {
                        orientation: orientation,
                        client_id: id,
                        check_host_url: 1,
                        custom_content: 1,
                        library_status: 1,
                        content_type: CONTENT_DATA_TYPE.VIDEO
                    }
                });
                setLoading({ ...loading, delete: false });
                handleCloseEvent();
            }
        } catch (error) {
            setLoading({ ...loading, delete: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const orientationObj = useMemo(() => {
        return orientation === ORIENTATION.LANDSCAPE ? { width: 150, height: 100 } : { width: 85, height: 120 }
    }, [orientation])

    return (
        <CommonModal
            open={open}
            title={'Edit Content'}
            width={950}
            onClose={handleCloseEvent}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start' }}>
                    <Grid item md={7} sm={6} xs={12}>
                        <FieldLabelView label={'Name'}>
                            <StyledInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.name && errors.name}
                                name={'name'}
                                value={values.name}
                            />
                        </FieldLabelView>
                        <CategoryDurationBox>
                            <FieldView>
                                <FieldLabelView label={'Category'} sx={{ width: '70%', mt: 1 }}>
                                    <CommonMultiSelect
                                        handleBlur={handleBlur}
                                        handleChange={handleChange}
                                        options={categoryList()}
                                        error={touched.category && errors.category}
                                        name={'category'}
                                        value={values.category}
                                    />
                                </FieldLabelView>
                            </FieldView>
                            <FieldLabelView label={'Duration'} sx={{ width: '70%', mt: 1 }}>
                                <FieldView>
                                    <StyledInput
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        error={touched.duration && errors.duration}
                                        name={'duration'}
                                        value={values.duration}
                                        disabled={!isDisabled.durationChecked}
                                    />
                                    <Checkbox
                                        name="isDuration"
                                        checked={isDisabled.durationChecked}
                                        onChange={(e) => handleCheckBoxValue(e, 'durationChecked')}
                                    />
                                </FieldView>
                            </FieldLabelView>
                        </CategoryDurationBox>
                        <FieldLabelView label={'4K Host URL'}>
                            <FieldView>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    error={touched.hostUrl && errors.hostUrl}
                                    name={'hostUrl'}
                                    value={values.hostUrl}
                                    disabled={!isDisabled.hostURLChecked}
                                />
                                <Checkbox
                                    name="isHostUrl"
                                    checked={isDisabled.hostURLChecked}
                                    onChange={(e) => handleCheckBoxValue(e, 'hostURLChecked')}
                                />
                            </FieldView>
                        </FieldLabelView>
                        <FieldLabelView label={'HD Host URL'}>
                            <FieldView>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    error={touched.hostUrlHD && errors.hostUrlHD}
                                    name={'hostUrlHD'}
                                    value={values.hostUrlHD}
                                    disabled={!isDisabled.hostURLCheckedForHD}
                                />
                                <Checkbox
                                    name="isHostUrlHD"
                                    checked={isDisabled.hostURLCheckedForHD}
                                    onChange={(e) => handleCheckBoxValue(e, 'hostURLCheckedForHD')}
                                />
                            </FieldView>
                        </FieldLabelView>
                        <FieldLabelView label={'Vimeo URL'}>
                            <FieldView>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    error={touched.vimeoUrl && errors.vimeoUrl}
                                    name={'vimeoUrl'}
                                    value={values.vimeoUrl}
                                    disabled={!isDisabled.vimeoURLChecked}
                                />
                                <Checkbox
                                    name="isVimeoUrl"
                                    checked={isDisabled.vimeoURLChecked}
                                    onChange={(e) => handleCheckBoxValue(e, 'vimeoURLChecked')}
                                />
                            </FieldView>
                        </FieldLabelView>
                        <FieldLabelView label={'Tags'}>
                            <StyledInput
                                style={{ height: 'auto' }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.tags && errors.tags}
                                name={'tags'}
                                value={values.tags}
                                multiline={true}
                                minRows={5}
                            />
                            <Typography sx={{ fontSize: 14 }}>Separate tags with a comma.</Typography>
                        </FieldLabelView>
                        <FieldLabelView label={'Description'} sx={{ mt: 1 }} >
                            <StyledInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.description && errors.description}
                                name={'description'}
                                value={values?.description}
                                style={{ height: 'auto' }}
                                multiline={true}
                                minRows={5}
                            />
                        </FieldLabelView>
                    </Grid>
                    <Grid item sm={5} xs={12} md={5}>
                        <ThumnailBox container justifyContent={'space-between'}>
                            <ThumbnailImage item>
                                <FieldLabelView label={'Thumbnail'}>
                                    <ImagePlaceholder
                                        boxDimensions={orientationObj}
                                        addIconDimension={{ height: 50, width: 50 }}
                                        handleChange={(event) => {
                                            setFieldValue('thumbnail', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('thumbnail_file', event.target.files[0]);
                                        }}
                                        image={values.thumbnail}
                                        isAddIcon={values.thumbnail ? false : true}
                                    />
                                </FieldLabelView>
                            </ThumbnailImage>
                            <DetailBox item>
                                <TextBox>
                                    <Text>Upload Date: </Text>
                                    <Text sx={{ fontWeight: 'normal' }}>{values?.uploaded_date}</Text>
                                </TextBox>
                                <TextBox>
                                    <Text>Resolution: </Text>
                                    <Text sx={{ fontWeight: 'normal' }}>{values?.resolution}</Text>
                                </TextBox>
                            </DetailBox>
                        </ThumnailBox>
                        <Box sx={{ mt: 1 }} >
                            <FieldLabelView label={'4K Video File'}>
                                <Box>
                                    <ImagePlaceholder sx={{ marginRight: '4px' }}
                                        boxDimensions={orientationObj}
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('resolution', `${fileInfo.width}x${fileInfo.height}`);
                                            setFieldValue('video', event.target.files[0]);
                                            setFieldValue(
                                                'video_url',
                                                URL.createObjectURL(event.target.files[0])
                                            );
                                        }}
                                        accept="video/mp4"
                                        image={values.video_url}
                                        isAddIcon={values.video_url ? false : true}
                                    />
                                    {touched.video_url && errors.video_url && <FormHelperText error>{errors.video_url}</FormHelperText>}
                                </Box>
                            </FieldLabelView>
                        </Box>
                        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <FieldLabelView label={'Directory'}>
                                <CommonMultiSelect
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    options={directories || []}
                                    error={touched.directory && errors.directory}
                                    name={'directory'}
                                    value={values.directory}
                                />
                            </FieldLabelView>
                            <Box sx={{ marginBottom: '12px' }} />
                            <FieldLabelView label={'Sub-Folder'}>
                                <StyledInput sx={{ mb: 0 }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    error={touched.sub_folder && errors.sub_folder}
                                    name={'sub_folder'}
                                    value={values?.sub_folder}
                                />
                            </FieldLabelView>
                        </Box>
                        <Box sx={{ mt: 1 }} >
                            <FieldLabelView label={'HD Video File'}>
                                <Box>
                                    <ImagePlaceholder sx={{ marginRight: '4px' }}
                                        boxDimensions={orientationObj}
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('video_url_hd', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('video_hd', event.target.files[0]);
                                            setFieldValue('resolution_hd', `${fileInfo?.width}x${fileInfo?.height}`);
                                        }}
                                        accept="video/mp4"
                                        image={values.video_url_hd}
                                        isAddIcon={values.video_url_hd ? false : true}
                                    />
                                    {touched.video_url_hd && errors.video_url_hd && <FormHelperText error>{errors.video_url_hd}</FormHelperText>}
                                </Box>
                            </FieldLabelView>
                        </Box>
                        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                            <FieldLabelView label={'Directory'}>
                                <CommonMultiSelect
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    options={directories || []}
                                    error={touched.directory_hd && errors.directory_hd}
                                    name={'directory_hd'}
                                    value={values.directory_hd}
                                />
                            </FieldLabelView>
                            <Box sx={{ marginBottom: '12px' }} />
                            <FieldLabelView label={'Sub-Folder'}>
                                <StyledInput sx={{ mb: 0 }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    error={touched.sub_folder_hd && errors.sub_folder_hd}
                                    name={'sub_folder_hd'}
                                    value={values?.sub_folder_hd}
                                />
                            </FieldLabelView>
                        </Box>
                    </Grid>
                </Grid>
                <ButtonWrap>
                    <DeleteButton
                        onClick={handleDelete}
                        loading={loading.delete}
                        confirmation={DELETE_CONFIRMATION}
                        label={'Delete'}
                        section={'video'}
                        title={'Content'}
                    />
                    <StyledButton
                        sx={{ ml: 2 }}
                        loading={loading.submit}
                        onClick={handleSubmit}
                        confirmation={UPDATE_CONFIRMATION}
                        validateForm={validateForm}
                        variant="contained"
                        label={'Update'}
                        title={'Content'}
                    />
                </ButtonWrap>
            </form>
        </CommonModal>
    );
}