import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchAccountTypeTerritory = createAsyncThunk('accountTypes/fetchAccountTypeTerritory', async () => {
    const response = await api.accountTypes.getAllAccountTypeTerritory();
    return response.data.result;
});
export const fetchAccountTypeSpecialty = createAsyncThunk('accountTypes/fetchAccountTypeSpecialty', async () => {
    const response = await api.accountTypes.getAllAccountTypeSpecialty();
    return response.data.result;
});

const initialState = {
    territory: [],
    Specialty: [],
    isPending: false
};

export const accountTypesSlice = createSlice({
    name: 'accountTypes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAccountTypeTerritory.fulfilled, (state, action) => {
            state.territory = action.payload;
            state.isPending = false;
        });
        builder.addCase(fetchAccountTypeTerritory.pending, (state) => {
            state.isPending = true;
        });
        builder.addCase(fetchAccountTypeTerritory.rejected, (state) => {
            state.territory = [];
            state.isPending = false;
        });

        builder.addCase(fetchAccountTypeSpecialty.fulfilled, (state, action) => {
            state.Specialty = action.payload;
            state.isPending = false;
        });
        builder.addCase(fetchAccountTypeSpecialty.pending, (state, action) => {
            state.isPending = true;
        });
        builder.addCase(fetchAccountTypeSpecialty.rejected, (state, action) => {
            state.Specialty = [];
            state.isPending = false;
        });
    }
});

export default accountTypesSlice.reducer;
