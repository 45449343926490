import { styled, Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonButton from '../../../../../components/CommonButton';
import { CommonInput } from '../../../../../components/CommonInput';
import { useFormik } from 'formik';
import { UnsavedChanges } from '../../../../../widgets/CommonWidgets';
import { api } from '../../../../../api';
import { useSnackbar } from 'notistack';
import { locationNameValidation } from '../../../../../utils/validations';
import FieldLabelView from '../../../../../components/FieldLabelView';
import { UPDATE_CONFIRMATION } from '../../../../../utils/constants';
import CommonModal from '../../../../../components/CommonModal';

const EditButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
});
const StyledInput = styled(CommonInput)({
    flex: 1
});

const EditLocationModal = ({ open, handleClose, locationData, callFetch }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState({ edit: false });

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setValues, setFieldValue, validateForm } = useFormik({
        initialValues: {
            name: '',
            unsavedChange: false
        },
        handleChange: (val) => {},
        validationSchema: locationNameValidation,
        onSubmit: async (values) => {
            try {
                setLoading({ edit: true });
                const data = {
                    ...values,
                    id: locationData.id
                };
                await api.location.update(data);
                callFetch();
                enqueueSnackbar('Location Renamed Successfully', { variant: 'success' });
                setLoading({ edit: false });
                handleClose();
            } catch (error) {
                setLoading({ edit: false });
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        setValues({
            name: locationData?.name
        });
    }, [locationData?.name, setValues]);

    const handleUnsavedChanges = (data) => {
        !values.unsavedChange && setFieldValue('unsavedChange', true);
        handleChange(data);
    };

    return (
        <CommonModal open={open} title={'Edit Location'} width={500} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <FieldLabelView label={'Location Name'}>
                    <StyledInput
                        handleChange={handleUnsavedChanges}
                        handleBlur={handleBlur}
                        error={touched.name && errors.name}
                        name={'name'}
                        value={values?.name}
                    />
                </FieldLabelView>
                <EditButtonContainer>
                    {values.unsavedChange && <UnsavedChanges />}
                    <CommonButton
                        loading={loading.edit}
                        onClick={handleSubmit}
                        variant="contained"
                        label={'Update'}
                        sx={{ ml: 2 }}
                        confirmation={UPDATE_CONFIRMATION}
                        validateForm={validateForm}
                    />
                </EditButtonContainer>
            </form>
        </CommonModal>
    );
};

export default EditLocationModal;
