import React, { useEffect, useState } from 'react';
import { Box, FormHelperText, styled, Chip } from '@mui/material';
import { COLORS } from '../../utils/theme';
import { useRef } from 'react';

const InputBox = styled(Box)({
    border: `1px solid ${COLORS.border}`,
    height: 'auto',
    padding: '14px',
    width: 'auto',
    borderRadius: '0.425rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
});

const TagsInput = ({ handleChange, setFieldValue, name, error, values, ...props }) => {
    const [tags, setTags] = useState([]);
    const [fieldError, setError] = useState('');
    const inputRef = useRef();
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (values?.length > 0) {
            setTags(values);
        } else {
            setTags([]);
        }
    }, [values]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleInputKeyPress = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault();
            addTag();
        }
    };

    const addTag = () => {
        if (inputValue.trim() !== '' && !tags.includes(inputValue) && Number.isInteger(Number(inputValue))) {
            let latestTags = [...tags, inputValue];
            setTags(latestTags);
            setFieldValue(name, latestTags);
            setInputValue('');
            setError('');
        } else if (tags.includes(inputValue)) {
            setError(`${inputValue} already exists.`);
        } else {
            setError('Please enter number');
        }
    };

    const removeTag = (tag) => {
        let updatedTags = tags.filter((t) => t !== tag);
        setTags(updatedTags);
        setFieldValue(name, updatedTags);
    };
    return (
        <>
            <InputBox
                onClick={() => inputRef.current.focus()}
                sx={[tags.length && { p: 1 }, error || (fieldError && { borderColor: COLORS.error })]}
            >
                {tags.map((tag) => (
                    <Chip key={tag} onDelete={() => removeTag(tag)} style={{ borderRadius: 4, margin: '4px' }} label={tag} color="primary" />
                ))}
                <input
                    value={inputValue}
                    name={name}
                    ref={inputRef}
                    onChange={handleInputChange}
                    onKeyPress={handleInputKeyPress}
                    style={{ border: 'none', outline: 'none', fontSize: '16px' }}
                    {...props}
                />
            </InputBox>
            {fieldError && <FormHelperText error>{fieldError}</FormHelperText>}
        </>
    );
};

export default TagsInput;
