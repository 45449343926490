import { styled, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '../../api';
import CommonButton from '../../components/CommonButton';
import { CommonInput } from '../../components/CommonInput';
import { fetchLocations } from '../../redux/reducers/clientLocationSlice';
import { UPDATE_CONFIRMATION } from '../../utils/constants';
import { locationNameValidation } from '../../utils/validations';
import { UnsavedChanges } from '../../widgets/CommonWidgets';
import CommonModal from '../CommonModal';
import FieldLabelView from '../FieldLabelView';

const StyledInput = styled(CommonInput)({
    marginTop: 12
});

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
});

const MainForm = styled('form')({});

const AddLocationNameModel = ({ open, handleClose, callFetch, locationData, clientEdit, clientId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [deviceName, setDeviceName] = useState('');

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setValues, setFieldValue, validateForm } = useFormik({
        initialValues: {
            name: '',
            unsavedChange: false
        },
        validationSchema: locationNameValidation,
        onSubmit: async (values) => await handleSubmitLocation(values)
    });

    useEffect(() => {
        setValues({
            name: locationData?.name || ''
        });
        setDeviceName(locationData?.name || '')
    }, [locationData]);

    const handleSubmitLocation = useCallback(async (values) => {
        try {
            setLoading(true);
            const data = {
                ...values,
                id: locationData?.id
            };
            if (!clientEdit) data['send_mail'] = true
            await api.location.update(data);
            handleClose();
            if (callFetch) {
                callFetch();
            } else {
                dispatch(fetchLocations({ params: { client_id: clientId } }));
            }
            enqueueSnackbar('Location Renamed Successfully', { variant: 'success' });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }, [callFetch, clientEdit, clientId, dispatch, enqueueSnackbar, handleClose, locationData])


    const handleUnsavedChanges = (data) => {
        !values.unsavedChange && setFieldValue('unsavedChange', true);
        handleChange(data);
    };

    const handleCloseLocationModal = useCallback(async (editByClient) => {
        if (!editByClient) {
            await handleSubmitLocation({ ...values, name: deviceName })
        }
        await handleClose()
    }, [deviceName, handleClose, handleSubmitLocation, values])

    return (
        <CommonModal open={open} width={500} onClose={() => handleCloseLocationModal(clientEdit)} title={clientEdit ? 'Rename Location' : 'Name Location'}>
            <MainForm onSubmit={handleSubmit}>
                <FieldLabelView label={clientEdit ? 'Location Name' : 'Enter Location Name'}>
                    <StyledInput
                        name={'name'}
                        handleBlur={handleBlur}
                        handleChange={handleUnsavedChanges}
                        value={values.name}
                        error={touched.name && errors.name}
                    />
                </FieldLabelView>
                <ButtonContainer>
                    {values.unsavedChange && <UnsavedChanges />}
                    <CommonButton
                        label={'Save'}
                        loading={loading}
                        onClick={handleSubmit}
                        variant="contained"
                        confirmation={UPDATE_CONFIRMATION}
                        validateForm={validateForm}
                    />
                </ButtonContainer>
            </MainForm>
        </CommonModal>
    );
};

export default AddLocationNameModel;
