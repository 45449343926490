import { Fragment } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { COLORS } from '../../../../utils/theme';
import { LayoutBuilderWidget } from '../../../../widgets/LayoutBuilderWidget';
import Loader from '../../../../components/Loader';

const MainGrid = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const ItemTitleText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});

const ZoneItemMainGrid = styled(Box)({
    cursor: 'pointer',
    width: '230px',
    margin: '0px 2rem'
});

export const Zones = ({ defaultValue, setFieldValue, orientation, layouts, isLoading }) => {
    const handleZoneSelection = (item) => {
        setFieldValue('layout_id', item.id);
    };

    return (
        <Box
            sx={{
                paddingLeft: { md: 12, xs: 2, sm: 2 },
                paddingRight: { md: 12, xs: 2, sm: 2 },
                paddingTop: 4,
                paddingBottom: 6,
                backgroundColor: COLORS.white
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ width: '100%' }} >
                    <MainGrid>
                        {!isLoading ? <>
                            {layouts?.map((item) => {
                                return (
                                    <ZoneItemMainGrid sx={{ alignItems: orientation === 'portrait' ? 'center' : 'start' }} key={item.id} onClick={() => handleZoneSelection(item)}>
                                        <ItemTitleText>{item.name}</ItemTitleText>
                                        <LayoutBuilderWidget
                                            showName={false}
                                            zones={item.zones}
                                            title={item.name}
                                            orientation={orientation}
                                            border={defaultValue === item.id}
                                        />
                                    </ZoneItemMainGrid>
                                );
                            })}
                        </> : <Loader isLoading={isLoading} />}
                    </MainGrid>
                </Box>
            </Box>
        </Box>
    );
};
