import { styled, Box } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { api } from '../../api';
import CommonButton from '../../components/CommonButton';
import { CommonInput } from '../../components/CommonInput';
import { fetchLocations } from '../../redux/reducers/clientLocationSlice';
import { verifyCodeValidation } from '../../utils/validations';
import CommonModal from '../CommonModal';
import FieldLabelView from '../FieldLabelView';
import AddLocationNameModel from './AddLocationNameModel';

const StyledInput = styled(CommonInput)({
    marginTop: 12
});

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
});
const MainForm = styled('form')({});

const AddLocationModel = ({ open, handleClose, callFetch, clientId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [showLocationNameModel, setShowLocationNameModel] = useState(false);
    const [locationData, setLocationData] = useState();

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            code: ''
        },
        validationSchema: verifyCodeValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const request = {
                    data: values
                };
                if (clientId) {
                    request.params = {
                        client_id: clientId
                    };
                }
                const { data } = await api.location.verifyCode(request);
                setLoading(false);
                if (callFetch) {
                    callFetch();
                } else {
                    dispatch(fetchLocations({ params: { client_id: clientId } }));
                }
                setLocationData(data.result);
                setFieldValue('code', '');
                setShowLocationNameModel(true);
                handleClose();
                enqueueSnackbar('Location Paired Successfully', { variant: 'success' });
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    return (
        <>
            <CommonModal open={open} onClose={handleClose} width={500} title={'Add Location'}>
                <MainForm onSubmit={handleSubmit}>
                    <FieldLabelView label={'Enter Pairing Code'}>
                        <StyledInput
                            name={'code'}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            value={values.code}
                            error={touched.code && errors.code}
                        />
                    </FieldLabelView>
                    <ButtonContainer>
                        <CommonButton label={'Add'} loading={loading} onClick={handleSubmit} variant="contained" />
                    </ButtonContainer>
                </MainForm>
            </CommonModal>
            <AddLocationNameModel clientId={clientId}
                locationData={locationData}
                open={showLocationNameModel}
                callFetch={callFetch}
                handleClose={() => setShowLocationNameModel(false)}
            />
        </>
    );
};

export default AddLocationModel;
