import * as React from 'react';
import Typography from '@mui/material/Typography';

import { Box, CircularProgress, IconButton, Skeleton, styled } from '@mui/material';
import { SERVER_URL, USER_ROLE } from '../utils/constants';
import { COLORS } from '../utils/theme';
import { useSelector } from 'react-redux';
import CommonImage from '../components/CommonImage';
import { api } from '../api';
import { useSnackbar } from 'notistack';

const MainCard = styled(Box)({
    backgroundColor: COLORS.white,
    textDecoration: 'none',
    cursor: 'pointer',
    position: 'relative',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem'
});

const MainCardMedia = styled(CommonImage)({
    width: '100%',
    position: 'relative',
    flex: 1,
    objectFit: 'contain'
});
const LocationImageBox = styled(Box)({
    backgroundSize: 'contain',
    marginBottom: 8,
    backgroundColor: COLORS.white,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
});

const SignalIcon = styled(Box)({
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: 'red'
});

const SignalContainer = styled(Box)({
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    right: 16,
    bottom: 8
});

const MirrorLocationNameText = styled(Typography)({
    fontSize: 14,
    color: COLORS.primary.light
});

const CardTypography = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    display: 'flex'
});
const InfoText = styled(Typography)({
    fontSize: 14,
    fontWeight: 'bold',
    color: COLORS.black,
    marginLeft: 4
});

const EditIconButton = styled(IconButton)({
    position: 'absolute',
    right: 12,
    top: 0,
    zIndex: 999999
});

const UpdateVersion = styled(Box)({
    padding: 12,
    color: COLORS.primary.activeTab,
    textAlign: 'center',
    borderTop: `1px solid ${COLORS.border}`,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

export default function LocationCard({ onClick, data, handleEdit, Icon, loading, reload }) {
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useSelector((state) => state.auth);
    const [updating, setUpdating] = React.useState(false);
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    const onUpdatePartnerApp = async () => {
        try {
            setUpdating(true);
            let response = await api.location.update({
                id: data?.id,
                version_id: data?.latest_version
            });
            if (response) {
                enqueueSnackbar(response?.data.message, { variant: 'success' });
                if (reload) {
                    reload();
                }
                setUpdating(false);
            }
        } catch (error) {
            setUpdating(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <MainCard elevation={0} onClick={handleClick}>
            <Box sx={{ width: '100%', position: 'relative', padding: '16px', marginBottom: 1 }}>
                {user?.role !== USER_ROLE?.VIEWER && (
                    <EditIconButton aria-label="close" edge="end" size="large" sx={{ color: COLORS.darkGray }} onClick={handleEdit}>
                        <Icon color={COLORS.white} />
                    </EditIconButton>
                )}
                {loading ? (
                    <>
                        <Skeleton height={250} width={'100%'} variant="rectangular" />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
                ) : (
                    <>
                        <LocationImageBox>
                            <MainCardMedia src={SERVER_URL + data?.image} title={data?.name} />
                            {data?.mirror_of_data && (
                                <MirrorLocationNameText>{`MIRROR of ${data?.mirror_of_data?.name}`}</MirrorLocationNameText>
                            )}
                        </LocationImageBox>
                        <Box sx={{ mb: 2 }}>
                            <CardTypography gutterBottom variant="h5" component="div">
                                Location: <InfoText>{data?.name || 'N/A'}</InfoText>
                            </CardTypography>
                            <CardTypography gutterBottom variant="h5" component="div">
                                System: <InfoText>{data?.system}</InfoText>
                            </CardTypography>
                            <CardTypography gutterBottom variant="h5" component="div">
                                Model: <InfoText>{data?.model}</InfoText>
                            </CardTypography>
                            <CardTypography gutterBottom variant="h5" component="div">
                                Serial: <InfoText>{data?.serial_number}</InfoText>
                            </CardTypography>
                            <CardTypography gutterBottom variant="h5" component="div">
                                Time Zone: <InfoText>{data?.timezone}</InfoText>
                            </CardTypography>

                            <CardTypography gutterBottom variant="h5" component="div">
                                Firmware: <InfoText>{data?.firmware_version || 'N/A'}</InfoText>
                            </CardTypography>

                            <CardTypography gutterBottom variant="h5" component="div">
                                OS Version: <InfoText>{data?.version?.name || 'N/A'}</InfoText>
                            </CardTypography>
                        </Box>
                    </>
                )}
                {!loading ? (
                    <SignalContainer>
                        <Typography
                            sx={{ textTransform: 'uppercase', color: data?.status === 'online' ? 'green' : 'red' }}
                            fontSize={10}
                            marginRight={'4px'}
                            color="red"
                        >
                            {data?.status || 'offline'}
                        </Typography>
                        <SignalIcon sx={{ backgroundColor: data?.status === 'online' ? 'green' : 'red' }} />
                    </SignalContainer>
                ) : null}
            </Box>
            {/* {data?.is_update_available ? (
                <UpdateVersion
                    onClick={() => {
                        if (data?.is_update_available && !updating) {
                            onUpdatePartnerApp();
                        }
                    }}
                    sx={{ opacity: data?.is_update_available && !updating ? 1 : 0.3 }}
                >
                    Update available
                    {updating && <CircularProgress sx={{ ml: 1 }} color="primary" size={18} />}
                </UpdateVersion>
            ) : null} */}
        </MainCard>
    );
}
