import { Box, FormHelperText, Grid, Input, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import { useFormik } from 'formik';
import { LoopViewWithDiv } from '../../../components/LoopViewWithDiv/LoopViewWithDiv';
import { COLORS } from '../../../utils/theme';
import { useNavigate, useParams } from 'react-router-dom';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCategory } from '../../../redux/reducers/categorySlice';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { api } from '../../../api';
import { useSnackbar } from 'notistack';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../../components/Loader';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { playlistAutoPositionRule } from '../../../utils/helpers';
import PlaylistCategoryAdjustment from '../../../components/PlaylistCategoryAdjustment';
import { loopValidation } from '../../../utils/validations';

const ContaineBox = styled(Grid)({
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.border}`,
    borderRadius: '0.425rem',
    marginTop: 28
});

const LoopChartGrid = styled(Grid)({
    padding: 12,
    borderRight: `1px solid ${COLORS.border}`,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const TemplateHeadGrid = styled(Grid)({
    padding: 28,
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${COLORS.border}`
});

const PlayListHeader = styled(Box)({
    padding: 12,
    borderBottom: `1px solid ${COLORS.border}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const PlayListNameText = styled(Typography)({
    fontSize: 18,
    color: COLORS.black
});
const Text = styled(Typography)({
    fontSize: 14,
    textAlign: 'center',
    color: COLORS.black
});

const PlayListMain = styled(Box)({
    padding: 18
});

const LoopListGrid = styled(Grid)({});

const StyledInput = styled(Input)({
    fontSize: 26,
    color: COLORS.black,
    textDecoration: 'none'
});

const ActionText = styled(Typography)({
    color: COLORS.darkGray,
    fontWeight: 400,
    fontSize: 16,
    marginLeft: 8
});

export const AddLoop = () => {
    const { id } = useParams();
    const isInEdit = Boolean(id);
    const dispatch = useDispatch();
    const [showDeletePopup, setDeletePopup] = useState(false);
    const [showResetPopup, setResetPopup] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [isNameDisable, setNameDisable] = useState(true);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(true);
    const { enqueueSnackbar } = useSnackbar();
    const { categorys } = useSelector((state) => state.category);
    const theme = useTheme();
    const matchDowmMd = useMediaQuery(theme.breakpoints.down('md'));

    const { data: loopData, isLoading } = useFetch({
        initialUrl: `/get_loop_template_by_id`,
        config: { params: { loop_template_id: id } },
        skipOnStart: id ? false : true
    });
    const editData = loopData?.result;

    const loopOptions = {
        OUTSIDE_BACKGROUND_WIDTH: matchDowmMd ? 280 : 350,
        MAIN_ROUNT_WIDTH: matchDowmMd ? 210 : 270,
        CHART_WIDTH: matchDowmMd ? 140 : 180,
        INSIDE_WHITE_WIDTH: matchDowmMd ? 120 : 160
    };

    const navigate = useNavigate();

    const addPlayList = (item) => {
        setUnsavedChanges(true);
        let tempChartData = [...chartData];
        const color = item.color;
        const value = 1;
        const label = 'PL';
        tempChartData.push({
            color,
            value,
            label,
            name: item.name,
            id: item.id,
            icon: item.icon,
            uniqId: Math.random().toString(16).slice(2)
        });
        let autoPositionList = playlistAutoPositionRule(tempChartData);
        setChartData([...autoPositionList]);
    };

    const onRemoveFromPlaylist = (item) => {
        const index = chartData.findIndex((e) => e.id === item.id);
        if (index > -1) {
            onDeletePlayList(index);
        }
    };

    const onDeletePlayList = (index) => {
        setUnsavedChanges(true);
        let tempChartData = [...chartData];
        tempChartData.splice(index, 1);
        let autoPositionList = playlistAutoPositionRule(tempChartData);
        setChartData([...autoPositionList]);
    };

    const onSave = async (values) => {
        setLoading(true);
        if (chartData.length > 0) {
            try {
                let reqData = {
                    name: values?.name,
                    status: status,
                    category_id: chartData?.map((item) => item.id)
                };
                let result;
                if (isInEdit) {
                    reqData.id = id;
                    const { data } = await api.loop.editTemplate(reqData);
                    result = data;
                } else {
                    const { data } = await api.loop.addTemplate(reqData);
                    result = data;
                }

                if (result.success) {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        } else {
            enqueueSnackbar('A Loop must contain a minimum of one Playlist.', { variant: 'error' });
        }
    };

    const handleDelete = async () => {
        try {
            const { data } = await api.loop.deleteTemplate(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const handleResetLoop = () => {
        setUnsavedChanges(id ? true : false);
        setChartData([]);
        setResetPopup(false);
    };

    const { handleBlur, handleChange, values, touched, errors, handleSubmit, setValues } = useFormik({
        initialValues: {
            name: 'Loop Template Name'
        },
        validationSchema: loopValidation,
        onSubmit: async (values) => {
            onSave(values);
        }
    });

    useEffect(() => {
        dispatch(fetchCategory());
    }, [dispatch]);

    useEffect(() => {
        if (editData && isInEdit && categorys?.length) {
            setStatus(Boolean(editData.status));
            setChartData(
                editData?.loop_categories?.map?.((item) => {
                    return {
                        color: item?.playlist?.color,
                        value: 1,
                        label: 'PL',
                        name: item?.playlist?.name,
                        id: item?.playlist?.id,
                        icon: item?.playlist?.icon
                    };
                })
            );
            setValues({
                name: editData?.name
            });
        }
    }, [editData, isInEdit, categorys, setValues]);

    return (
        <Box>
            <CommonHeader
                title={'Loop Template'}
                buttonLabel={'Save'}
                backTo={'Loops'}
                breadcrumbText={id ? 'Edit' : 'Add'}
                breadcrumb={true}
                unsavedChanges={unsavedChanges}
                onClick={handleSubmit}
                inEdit={isInEdit ? isInEdit : false}
                onClose={() => navigate(-1)}
                loading={loading}
                isXSset6={true}
                onDelete={() => setDeletePopup(true)}
            />
            {isLoading && <Loader isLoading={isLoading} />}
            {!isLoading && (
                <ContaineBox container>
                    <TemplateHeadGrid item md={12} sx={{ width: '100%', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                {!isNameDisable ? (
                                    <StyledInput
                                        name={'name'}
                                        disableUnderline={isNameDisable}
                                        onFocus={() => setNameDisable(false)}
                                        onBlur={(e) => {
                                            setNameDisable(true);
                                            handleBlur(e);
                                        }}
                                        autoFocus={!isNameDisable}
                                        onChange={(e) => {
                                            setUnsavedChanges(true);
                                            handleChange(e);
                                        }}
                                        value={values?.name}
                                    />
                                ) : (
                                    <Box onClick={() => setNameDisable(false)}>
                                        <Typography sx={{ fontSize: 26 }}>{values?.name}</Typography>
                                    </Box>
                                )}
                                {isNameDisable && <ActionText onClick={() => setNameDisable(false)}>{'(Click to edit title)'}</ActionText>}
                            </Box>
                            <Box>{touched?.name && errors?.name && <FormHelperText error>{errors?.name}</FormHelperText>}</Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Text sx={{ mr: 1 }}>Client Side Status:</Text>
                            <IOSSwitch
                                checked={status}
                                onChange={(e) => {
                                    setUnsavedChanges(true);
                                    setStatus(e.target.checked);
                                }}
                                defaultChecked
                            />
                        </Box>
                    </TemplateHeadGrid>
                    <LoopChartGrid sx={{ width: '100%' }} item xs={12} sm={6} md={6}>
                        <LoopViewWithDiv
                            onDeleteItem={onDeletePlayList}
                            options={loopOptions}
                            chartData={chartData}
                            onReset={() => setResetPopup(true)}
                            reset={chartData.length > 0}
                        />
                    </LoopChartGrid>

                    <LoopListGrid item xs={12} sm={6} md={6}>
                        <PlayListHeader>
                            <PlayListNameText>Playlists</PlayListNameText>
                        </PlayListHeader>
                        <Text sx={{ mt: 1 }}>Adjust the number and category of Playlists included in the Loop</Text>
                        <PlayListMain>
                            {categorys?.map((item) => {
                                return (
                                    <PlaylistCategoryAdjustment
                                        item={item}
                                        key={item.id}
                                        addPlayList={addPlayList}
                                        onDeletePlayList={onRemoveFromPlaylist}
                                    />
                                );
                            })}
                        </PlayListMain>
                    </LoopListGrid>
                </ContaineBox>
            )}
            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={handleDelete}
                    title={'Delete Loop'}
                    description={'Are you sure you want to delete this Loop ?'}
                    isCancel={'No'}
                />
            ) : null}
            {showResetPopup ? (
                <AlertDialog
                    isOpen={showResetPopup}
                    isClose={() => setResetPopup(false)}
                    handleSubmit={handleResetLoop}
                    title={'Reset Loop'}
                    description={'Are you sure you want to reset this Loop? All Playlists will be removed from the Loop.'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
