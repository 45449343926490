import { Box, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { LoginSocialFacebook } from 'reactjs-social-login';
import { api } from '../../../../api';
import { IMAGES } from '../../../../assets';
import CommonButton from '../../../../components/CommonButton';
import CommonModal from '../../../../components/CommonModal';
import { ORIENTATION, ORIENTATION_DATA_KEY, SERVER_URL, SOCIAL_MEDIA } from '../../../../utils/constants';
import { FACEBOOK_APP_ID, GOOGLE_LOGIN_URL, INSTAGRAM_LOGIN_URL } from '../../../../utils/urls';
import { useSelector } from 'react-redux';
import { COLORS } from '../../../../utils/theme';
import { isAddedInterationApp } from '../../../../utils/helpers';

const Container = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 15
});
const Logo = styled('img')({
    height: 60,
    width: 60
});
const Title = styled(Typography)({
    fontSize: 20,
    margin: '10px 0px',
    fontWeight: 'bold'
});
const AuthorizationMessage = styled(Typography)({
    textAlign: 'center',
    fontSize: 14,
    margin: '5px 0px'
});
const StyledButton = styled(CommonButton)({
    fontWeight: '300',
    fontSize: '16px',
    textAlign: 'center',
    margin: '15px 0px'
});
const AuthorizationImage = styled('img')({
    margin: '10px 0px',
    width: '80%',
    aspectRatio: 1.77
});

const PendingMessage = styled(Typography)({
    textAlign: 'center',
    fontSize: 16,
    margin: '5px 0px',
    color: COLORS.primary.activeTab
});

const MonthlyCostBox = styled(Box)({
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
});

const MonthlyCostTitle = styled(Typography)({
    fontSize: 16,
    marginTop: '10px',
    fontWeight: 'bold'
});

const MonthlyCostDetails = styled(Typography)({
    fontSize: 16,
    color: COLORS.primary.gray
});

const REDIRECT_URI = 'https://looposstaging.dsid.com/client/apps/';

const logos = { facebook: IMAGES.FacebookLogo, instagram: IMAGES.InstagramLogo, google: IMAGES.GoogleLogo };

const UnauthorizedPopup = ({ open, handleClose, data, fetchAllSocialDetails, orientation = ORIENTATION.LANDSCAPE }) => {
    const [loading, setLoading] = useState(false);
    const [requestActivation, setRequestActivation] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { locations } = useSelector((state) => state.clientLocation);
    let addedIntergationApp = isAddedInterationApp(data?.name_alias);

    const handleFacebookLogin = async (data) => {
        const reqData = {
            access_token: data?.accessToken,
            name: data?.name,
            email: data?.email,
            user_id: data?.userID,
            expires_in: data?.expiresIn
        };
        handleAuthorizationProcess(api.social.facebookLogin(reqData));
    };

    const handleActiveApplication = () => {
        const reqData = {
            name_alias: data?.name_alias,
            integration_title: data?.name
        };
        handleAuthorizationProcess(api.social.ActiveIntegratedApp(reqData));
    };

    const handleAuthorizationProcess = async (apiCall) => {
        let result;
        setLoading(true);
        try {
            const { data } = await apiCall;
            result = data;
            if (result.success) {
                setLoading(false);
                setRequestActivation(!requestActivation);
                enqueueSnackbar(result.message, { variant: 'success' });
            } else {
                setLoading(false);
                enqueueSnackbar(result.message, { variant: 'error' });
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const handleGoogleLogin = () => {
        let googleWindow = window.open(GOOGLE_LOGIN_URL, 'Google Login', 'popup');
        const interval = setInterval(async () => {
            if (googleWindow.location.origin === window.location.origin) {
                const urlSearchParams = new URLSearchParams(googleWindow.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                googleWindow.close();
                clearInterval(interval);
                if (params.code) {
                    handleAuthorizationProcess(api.social.googleLogin({ params: { code: params.code } }));
                }
            }
        }, 100);
    };
    const handleInstagramLogin = () => {
        let instargramWindow = window.open(INSTAGRAM_LOGIN_URL, 'Google Login', 'popup');
        const interval = setInterval(async () => {
            if (instargramWindow.location.origin === window.location.origin) {
                const urlSearchParams = new URLSearchParams(instargramWindow.location.search);
                const params = Object.fromEntries(urlSearchParams.entries());
                instargramWindow.close();
                clearInterval(interval);
                clearInterval(interval);
                if (params.code) {
                    handleAuthorizationProcess(api.social.instagramLogin({ params: { code: params.code } }));
                }
            }
        }, 100);
    };

    const renderAuthorizeButton = () => {
        switch (data?.name_alias) {
            case SOCIAL_MEDIA.FACEBOOK:
                return (
                    <LoginSocialFacebook
                        appId={FACEBOOK_APP_ID || ''}
                        scope="email,pages_show_list,business_management,pages_read_engagement,pages_read_user_content,public_profile"
                        fieldsProfile={'id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'}
                        redirect_uri={REDIRECT_URI}
                        onResolve={({ provider, data }) => {
                            setLoading(true);
                            handleFacebookLogin(data);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <StyledButton onClick={() => {}} variant="contained" loading={loading} label={'Authorize'} />
                    </LoginSocialFacebook>
                );
            case SOCIAL_MEDIA.INSTAGRAM:
                return <StyledButton onClick={handleInstagramLogin} label={'Authorize'} variant="contained" />;
            case SOCIAL_MEDIA.GOOGLE:
                return <StyledButton onClick={handleGoogleLogin} label={'Authorize'} variant="contained" />;
            default:
                return <StyledButton onClick={() => {}} label={'Authorize'} variant="contained" />;
        }
    };

    const renderTitle = (data) => {
        if (Boolean(data?.add_on_feature)) {
            return `${data?.name} (Add-On)`;
        } else {
            return data?.name;
        }
    };

    const getKeyObjectFromArrayObject = (data) => {
        let active_status = false;
        if ((data.hasOwnProperty('activation_status') || !data?.activation_status) && data?.activation_status === 'pending') {
            active_status = false;
        } else {
            active_status = true;
        }

        return active_status;
    };

    const closeModel = () => {
        if (requestActivation) {
            try {
                fetchAllSocialDetails({ params: { status: 1, search: '', social: 1 } });
                handleClose();
            } catch (error) {
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        } else handleClose();
    };
    return (
        <CommonModal open={open} onClose={closeModel} width={600}>
            {!addedIntergationApp ? (
                <Container>
                    <Logo src={logos[[data?.name_alias]]} />
                    <Title>{data?.name}</Title>
                    <AuthorizationMessage>{data?.authorization_message}</AuthorizationMessage>
                    {renderAuthorizeButton()}
                    {data?.authorization_image && <AuthorizationImage src={SERVER_URL + data?.authorization_image} />}
                </Container>
            ) : (
                <Container>
                    <Logo src={`${SERVER_URL}${data?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || data?.landscape_thumbnail}`} />
                    <Title>{renderTitle(data)}</Title>
                    <AuthorizationMessage>{data?.authorization_message}</AuthorizationMessage>
                    {getKeyObjectFromArrayObject(data) && !requestActivation ? (
                        <StyledButton onClick={handleActiveApplication} label={'Activate'} variant="contained" loading={loading} />
                    ) : (
                        <PendingMessage>Activation Pending</PendingMessage>
                    )}
                    {data?.authorization_image && <AuthorizationImage src={SERVER_URL + data?.authorization_image} />}
                    {Boolean(data?.add_on_feature) && (
                        <MonthlyCostBox>
                            <MonthlyCostTitle> {`+$${data?.monthly_cost}/month/location`}</MonthlyCostTitle>
                            <MonthlyCostDetails>{`( ${locations?.length} locations X $${data?.monthly_cost} = $${
                                data?.monthly_cost * locations?.length
                            }/month )`}</MonthlyCostDetails>
                        </MonthlyCostBox>
                    )}
                </Container>
            )}
        </CommonModal>
    );
};

export default UnauthorizedPopup;
