import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ICONS } from '../../../assets';
import { CommonTable } from '../../../components/Table';
import { fetchAllForms } from '../../../redux/reducers/requestSlice';
import { COLORS } from '../../../utils/theme';
import { PAGES } from '../../../utils/urls';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { useSnackbar } from 'notistack';
import { api } from '../../../api';
const Name = styled(Typography)({
    fontSize: 16,
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});
const FlexBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});
const LabelBox = styled(Box)({
    marginRight: 8,
    padding: '0px 8px',
    borderRadius: 4
});

export const RequestCategoryOverview = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [rows, setRows] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { requestForms } = useSelector((state) => state.request);
    const { currentCategory } = useSelector((state) => state.requestCategory);

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchAllForms({ params: { request_category_id: +id } })).then(() => {
            setIsLoading(false);
        });
    }, [dispatch, id]);

    useEffect(() => {
        if (requestForms) {
            setRows(requestForms)
        }
    }, [requestForms])

    const categoryColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: ({ rowData }) => {
                return (
                    <FlexBox>
                        <IconButton>
                            <ICONS.DragIndicatorIcon />
                        </IconButton>
                        <Name>{rowData.name}</Name>
                    </FlexBox>
                );
            }
        },
        {
            id: 'labels',
            fieldName: 'labels',
            label: 'Label',
            align: 'center',
            cellStyle: {
                width: 400
            },
            render: ({ rowData }) => {
                return (
                    <FlexBox sx={{ justifyContent: 'center' }}>
                        {rowData.labels.map((label) => {
                            return (
                                <LabelBox key={label.id} sx={{ backgroundColor: label.color || COLORS.white }}>
                                    {label.label ? (
                                        <Typography sx={{ color: COLORS.white }}>{label.label}</Typography>
                                    ) : (
                                        <Typography>{'-'}</Typography>
                                    )}
                                </LabelBox>
                            );
                        })}
                    </FlexBox>
                );
            }
        },
        {
            id: 'cost',
            fieldName: 'cost',
            label: 'Cost',
            align: 'center',
            cellStyle: {
                width: 140
            },
            render: ({ rowData }) => {
                return <Typography>{`${rowData.cost}C`}</Typography>;
            }
        },
        {
            id: 'status',
            fieldName: 'status',
            label: 'Status',
            cellStyle: {
                width: 140
            },
            render: ({ rowData }) => {
                return (
                    <>
                        {rowData.status ? (
                            <ICONS.CheckCircleIcon variant="contained" style={{ color: 'green' }} />
                        ) : (
                            <ICONS.CancelIcon style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
            style: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            align: 'center'
        }
    ];

    const saveRearrangedRowsData = async (data = []) => {
        setIsLoading(true);
        const order = data.map((item) => {
            return { id: item.id, order: item.order };
        }).sort((a, b) => a.order - b.order);
        try {
            const formData = new FormData()
            formData.append('order', JSON.stringify(order))
            const { data } = await api.request.editForm(formData);
            if (data.success) {
                dispatch(fetchAllForms({ params: { request_category_id: +id } }))
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }

    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('text/plain', id);
    };

    const handleDragOver = e => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    }

    const hanldeOnDrop = (e, id) => {
        e.preventDefault();
        const draggedId = e.dataTransfer.getData('text/plain');
        const targetId = id;

        // Find the dragged row and target row
        const draggedRow = rows.find((row, id) => row.id === Number(draggedId));
        const targetRow = rows.find((row, id) => row.id === Number(targetId));

        // Find the indices of the dragged row and target row
        const draggedRowIndex = rows.indexOf(draggedRow);
        const targetRowIndex = rows.indexOf(targetRow);

        // Create a new array with the updated order of rows
        const updatedRows = [...rows];
        const [removed] = updatedRows.splice(draggedRowIndex, 1);
        updatedRows.splice(targetRowIndex, 0, removed);
        // Update the state with the new order of rows
        const update = updatedRows.map((item, index) => {
            return { ...item, order: index + 1 };
        })
        setRows(update);
        saveRearrangedRowsData(update)
    }

    return (
        <Grid container direction="column" justifyContent="flex-end">
            <CommonHeader
                title={currentCategory}
                backTo={'Requests'}
                breadcrumbText={'Overview'}
                buttonLabel={'Add Entry'}
                onClose={() => navigate(-1)}
                showCloseButton={false}
                searchbar={true}
                searchValue={searchValue}
                handleSearch={(text) => setSearchValue(text)}
                onClick={() => navigate(`${PAGES.REQUEST_TOOL_ADD_FORM.url}/${id}/add-form`)}
            />
            <Grid sx={{ marginTop: '24px' }} container>
                <CommonTable
                    handleRowDetail={(data) => {
                        navigate(`${PAGES.REQUEST_TOOL_EDIT_FORM.url}/${id}/edit-form/${data.id}`);
                    }}
                    isNotDefaultSorted
                    columns={categoryColumns}
                    rowsPadding={1}
                    draggable={true}
                    rows={rows}
                    loading={isLoading}
                    search={searchValue}
                    onDragStart={(e, id) => {
                        handleDragStart(e, id);
                    }}
                    onDrop={(e, id) => {
                        hanldeOnDrop(e, id);
                    }}
                    onDragOver={(e) => {
                        handleDragOver(e);
                    }}
                />
            </Grid>
        </Grid>
    );
};
