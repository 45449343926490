import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchRequestCategory = createAsyncThunk('get_request_category', async (params) => {
    const response = await api.request.getAllCategory(params);
    return response.data.result;
});
export const fetchAllForms = createAsyncThunk('get_request_forms', async (params) => {
    const response = await api.request.getAllForms(params);
    return response.data.result;
});

const initialState = {
    categories: [],
    requestForms: [],
};

export const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRequestCategory.fulfilled, (state, action) => {
            state.categories = action.payload;
        });
        builder.addCase(fetchAllForms.fulfilled, (state, action) => {
            state.requestForms = action.payload;
        });
    }
});

export default requestSlice.reducer;
