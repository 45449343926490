import React from 'react';
import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import { COLORS } from '../utils/theme';
import { IMAGES } from '../assets';

const Body = styled(Grid)(({ theme }) => ({
    minHeight: '100vh',
    minWidth: '100vw',

    backgroundImage: `linear-gradient(100deg,${COLORS.primary.light}, ${COLORS.primary.dark})`
}));
const MainView = styled(Grid)(({ theme }) => ({
    mb: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
}));
const DSIDImage = styled('img')({
    height: 210,
    objectFit: 'cover'
});
const ImageBox = styled(Box)({
    marginBottom: 30
});
export const PublicLayout = ({ children }) => {
    return (
        <Body container direction="column" justifyContent="flex-end">
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
                    <MainView
                        item
                        sx={{
                            m: { xs: 1, sm: 3 }
                        }}
                    >
                        <ImageBox sx={{ width: { xs: '300px', sm: '350px', md: '360px', lg: '360px' } }}>
                            <DSIDImage
                                src={IMAGES.DSID_LOGO}
                                alt={'DSID_Logo'}
                                loading="lazy"
                                sx={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        </ImageBox>
                        {children}
                    </MainView>
                </Grid>
            </Grid>
        </Body>
    );
};
