import { styled, useMediaQuery, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { ORIENTATION } from '../utils/constants';
import { COLORS } from '../utils/theme';

const PlaneOutlineBox = styled(Box)({
    width: '100%',
    border: `1px solid ${COLORS.darkGray}`,
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    alignSelf: 'center',
});

const ZoneItem = styled(Box)({
    position: 'absolute',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const ZoneTypeText = styled(Box)({
    fontSize: 14,
    color: COLORS.darkGray
});

export const LayoutBuilderWidget = ({ zones, orientation, handleClickOnZone, activeZone, showName, minimizePortrait, border }) => {
    const theme = useTheme();
    const matchDownSm = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <PlaneOutlineBox
            style={{
                aspectRatio: orientation === ORIENTATION.PORTRAIT ? 9 / 16 : 16 / 9,
                width: orientation === ORIENTATION.PORTRAIT ? minimizePortrait ? "20%" : !matchDownSm&&"35%" :minimizePortrait ? "50%":"100%",
                ...(border) && {
                    border: `2px solid ${COLORS.primary.light}`,
                },
                marginLeft: 0
            }}
        >
            {zones?.map((item) => {
                return (
                    <ZoneItem
                        key={item.id}
                        onClick={() => {
                            if (handleClickOnZone) {
                                handleClickOnZone(item);
                            }
                        }}
                        style={{
                            height: item.height + '%',
                            width: item.width + '%',
                            top: item.y_axis + '%',
                            left: item.x_axis + '%',
                            border: `1px solid ${activeZone === item.id ? COLORS.primary.light : COLORS.darkGray}`
                        }}
                    >
                        {showName && <ZoneTypeText>{item.content}</ZoneTypeText>}
                    </ZoneItem>
                );
            })}
        </PlaneOutlineBox>
    );
};
