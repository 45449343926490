import { Box, Grid, IconButton, Skeleton, styled, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { CommonInput } from '../../../components/CommonInput';
import { COLORS } from '../../../utils/theme';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DropZoneBox } from '../../../widgets/DropZoneBox';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { api } from '../../../api';
import useFetch from '../../../hooks/useFetch';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { ASPECT_RATIO, BACKGROUND_UPLOAD, ORIENTATION, SERVER_URL, SkeletonArray } from '../../../utils/constants';
import { backgroundNameValidation } from '../../../utils/validations';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { ICONS } from '../../../assets';
import ImageViewModal from './components/ImageViewModal';
import { passMultipleFilesIntoFormData } from '../../../utils/helpers';
import FieldLabelView from '../../../components/FieldLabelView';
import CommonImage from '../../../components/CommonImage';

const FormBox = styled(Box)({
    width: '100%',
    padding: 22,
    backgroundColor: COLORS.white,
    marginTop: 24,
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem'
});

const ImageGrid = styled(Grid)({
    paddingTop: 24
});

const FieldLabel = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 4,
    fontWeight: 600
});

const StyledInput = styled(CommonInput)({
    flex: 1
});

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%'
});
const FileBox = styled(Box)({
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
});
const DragAndDropBox = styled(Grid)({
    width: '100%',
    marginTop: '24px'
});

const ActionBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    justifyContent: 'space-evenly',
    alignItems: 'center'
});

export const AddEditBackground = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const [acceptedFiles, setAcceptedFiles] = useState('');
    const [showDeletePopup, setDeletePopup] = useState({ open: false, type: 'theme' });
    const [loading, setLoading] = useState(false);
    const [isShown, setIsShown] = useState(false);
    const [showImageView, setImageView] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { data, callFetch, isLoading } = useFetch({
        initialUrl: '/get_theme_by_id',
        config: { params: { id: Number(id) } },
        skipOnStart: id ? false : true
    });
    const themeData = data?.result;

    const { handleBlur, handleChange, values, touched, errors, handleSubmit, setFieldValue, setValues } = useFormik({
        initialValues: {
            name: '',
            client_status: true,
            unSavedChange: false
        },
        validationSchema: backgroundNameValidation,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('status', +values.client_status);
                formData.append('orientation', state.orientation);
                let result;
                if (acceptedFiles?.length > 0) {
                    passMultipleFilesIntoFormData(formData, acceptedFiles, 'bg_image');
                }
                if (id) {
                    formData.append('theme_id', id);
                    const { data } = await api.background.edit(formData);
                    result = data;
                } else {
                    const { data } = await api.background.add(formData);
                    result = data;
                }
                if (result.success) {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });
    useEffect(() => {
        if (themeData) {
            setValues({
                name: themeData?.name,
                client_status: Boolean(themeData?.status)
            });
        }
    }, [themeData, setValues]);

    const checkUnsaveChanges = () => {
        id && !values.unSavedChange && setFieldValue('unSavedChange', true);
    };
    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };
    const handleDelete = async () => {
        try {
            if (showDeletePopup.type === 'theme') {
                const { data } = await api.background.delete(id);
                if (data.success) {
                    enqueueSnackbar(data.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            } else {
                const { data } = await api.background.imageDelete(showDeletePopup?.imageId);
                if (data.success) {
                    enqueueSnackbar(data.message, { variant: 'success' });
                    callFetch({ params: { id: Number(id) } });
                } else {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            }
            setDeletePopup({ open: false, type: 'theme' });
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <Box>
            <CommonHeader
                title={'Theme'}
                buttonLabel={'Save'}
                backTo={'Backgrounds'}
                breadcrumbText={id ? 'Edit' : 'Add'}
                unsavedChanges={values.unSavedChange}
                onClick={handleSubmit}
                inEdit={id ? true : false}
                onClose={() => navigate(-1)}
                onDelete={() => setDeletePopup({ open: true, type: 'theme' })}
                loading={loading}
                isXSset6={true}
            />
            <FormBox>
                <Grid container spacing={3}>
                    <Grid item md={12}>
                        <Grid container spacing={2} sx={{ alignItems: 'flex-end' }}>
                            <Grid item md={9}>
                                <FieldLabelView label={'Name'}>
                                    <StyledInput
                                        handleChange={checkHandlechange}
                                        handleBlur={handleBlur}
                                        error={touched.name && errors.name}
                                        name={'name'}
                                        value={values.name}
                                    />
                                </FieldLabelView>
                            </Grid>
                            <Grid item md={3}>
                                <FieldView md={3} sx={{ justifyContent: 'end' }}>
                                    <FieldLabel>Client Side Status:</FieldLabel>
                                    <IOSSwitch
                                        sx={{ m: 1 }}
                                        checked={values?.client_status}
                                        onChange={(e) => {
                                            setFieldValue('client_status', e.target.checked);
                                        }}
                                    />
                                </FieldView>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <DragAndDropBox>
                    <DropZoneBox
                        accept={BACKGROUND_UPLOAD?.accept}
                        validations={
                            state.orientation === ORIENTATION.LANDSCAPE
                                ? BACKGROUND_UPLOAD?.landscapeValidations
                                : BACKGROUND_UPLOAD?.portraitValidations
                        }
                        files={(val) => setAcceptedFiles(val)}
                        requirementText={BACKGROUND_UPLOAD?.requirementText}
                        maxFileSize={BACKGROUND_UPLOAD?.maxFileSize}
                        maxFiles={BACKGROUND_UPLOAD?.maxFiles}
                    />
                </DragAndDropBox>
            </FormBox>

            <ImageGrid container columns={{ xs: 4, sm: 8, md: 6, lg: 12 }} spacing={2}>
                {isLoading
                    ? SkeletonArray.map((skeleton) => {
                        return (
                            <Grid key={skeleton} item xs={6} sm={4} md={2} lg={2}>
                                <Skeleton
                                    variant="rectangular"
                                    style={{ width: '100%', height: state.orientation === ORIENTATION.LANDSCAPE ? 150 : 450 }}
                                />
                            </Grid>
                        );
                    })
                    : themeData &&
                    themeData?.backgrounds?.map((item) => {
                        return (
                            <Grid
                                key={item?.id}
                                item
                                xs={6}
                                sm={4}
                                md={2}
                                lg={2}
                                onMouseEnter={() => setIsShown({ visibility: true, id: item.id })}
                                onMouseLeave={() => setIsShown({ visibility: false, id: item.id })}
                            >
                                <FileBox>
                                    <CommonImage
                                        src={`${SERVER_URL}${item?.image}`}
                                        alt="background"
                                        style={{
                                            width: '100%',
                                            aspectRatio: ASPECT_RATIO[state.orientation],
                                            objectFit: 'cover',
                                            border: `1px solid ${COLORS.border}`,
                                            borderRadius: '0.425rem'
                                        }}
                                    />
                                    {isShown.visibility && isShown.id === item.id && (
                                        <ActionBox>
                                            <IconButton
                                                onClick={() => setImageView({ isOpen: true, image: `${SERVER_URL}${item?.image}` })}
                                            >
                                                <ICONS.Eye color={'primary'} />
                                            </IconButton>
                                            <IconButton onClick={() => setDeletePopup({ open: true, type: 'image', imageId: item.id })}>
                                                <ICONS.Delete color={'error'} />
                                            </IconButton>
                                        </ActionBox>
                                    )}
                                </FileBox>
                            </Grid>
                        );
                    })}
            </ImageGrid>
            {showDeletePopup?.open && (
                <AlertDialog
                    isOpen={showDeletePopup.open}
                    isClose={() => setDeletePopup({ open: false, type: 'theme' })}
                    handleSubmit={handleDelete}
                    title={showDeletePopup.type === 'theme' ? 'Delete Theme' : 'Delete Image'}
                    description={`Are you sure you want to delete this ${showDeletePopup.type === 'theme' ? 'Theme' : 'Image'} ?`}
                    isCancel={'No'}
                />
            )}
            {showImageView.isOpen && (
                <ImageViewModal
                    open={showImageView.isOpen}
                    handleClose={() => setImageView({ isOpen: false, image: '' })}
                    image={showImageView.image}
                    orientation={state.orientation}
                />
            )}
        </Box>
    );
};
