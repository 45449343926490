/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ListItemButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { COLORS } from '../../../../../utils/theme';
import { useDispatch } from 'react-redux';
import { handleActivePath, toggleDrawer } from '../../../../../redux/reducers/commonSlice';

const ItemText = styled(Typography)({
    fontSize: 14,
    fontWeight: '500',
    marginLeft: 10
});

const ItemButtonMain = styled(ListItemButton)({
    padding: '8px 24px'
});

const NavItem = ({ item }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const activePath = location.pathname;
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const isActive = activePath.includes(item.url);

    const itemHandler = (item) => {
        dispatch(handleActivePath(item.accessKey))
        navigate(item.url);
        if (!matchUpMd) {
            dispatch(toggleDrawer());
        }
    };

    useEffect(() => {
        const currentIndex = document.location.pathname
            ?.toString?.()
            ?.split('/')
            ?.findIndex((id) => id === item.id);
        if (currentIndex > -1) {
        }
    }, [item.id]);

    return (
        <ItemButtonMain
            sx={{
                borderRight: `3px solid ${isActive ? COLORS.vividYellow : COLORS.light}`,
                backgroundColor: isActive && COLORS.primary.activeTab
            }}
            disabled={item.disabled}
            onClick={() => itemHandler(item)}
        >
            {item.Icon && <item.Icon style={{ height: '17px', width: '17px', color: isActive ? COLORS.vividYellow : COLORS.white }} />}
            <ItemText
                style={{
                    color: isActive ? COLORS.vividYellow : COLORS.white
                }}
            >
                {item.title}
            </ItemText>
        </ItemButtonMain>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
