import React from 'react';

const CommonImage = ({ alt,...rest }) => {
    return (
        <>
            <img
                onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src =
                        'https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg';
                }}
                alt={alt}
                {...rest}
            />
        </>
    );
};

export default CommonImage;
