import { Box, styled, Grid } from '@mui/material';
import { ContentTabBar } from '../../../../components/ContentTabBar';
import { FORCE_RESOLUTION_TAB, MONITOR_OVERSCAN_TAB } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';
import { CustomColorPicker } from '../../../../components/CustomColorPicker';
import { ColorBoxWidget } from '../../../../widgets/ColorBoxWidget';

const MainGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

const ItemTitleText = styled(Grid)({
    fontSize: 16,
    color: COLORS.black
});

export const Advanced = ({ defaultValue, setFieldValue }) => {
    const handleChangeMonitorOverscanActiveTab = (item) => {
        setFieldValue('advanceInfo', { ...defaultValue, monitor_overscan: item.id });
    };

    const handleChangeForceResolutionActiveTab = (item) => {
        setFieldValue('advanceInfo', { ...defaultValue, force_resolution: item.id });
    };
    const handleColorChange = (color) => {
        setFieldValue('advanceInfo', { ...defaultValue, bg_color: color.hex });
    };

    return (
        <Box sx={{ padding: { xs: 2, sm: 3, md: 8, lg: 9 }, backgroundColor: COLORS.white }}>
            <MainGrid container spacing={2}>
                <Grid item md={12}>
                    <ItemTitleText sx={{ mb: 1 }}>BACKGROUND COLOR</ItemTitleText>
                    <CustomColorPicker
                        currentcolor={defaultValue?.bg_color}
                        handleColorChange={handleColorChange}
                        component={<ColorBoxWidget backgroundColor={defaultValue.bg_color} />}
                    />
                    <ItemTitleText sx={{ mb: 1, mt: 4 }}>MONITOR OVERSCAN</ItemTitleText>
                    <ContentTabBar
                        tabArray={MONITOR_OVERSCAN_TAB}
                        activeTab={defaultValue.monitor_overscan}
                        handleChangeActiveTab={handleChangeMonitorOverscanActiveTab}
                        advanced={true}
                        useOld={true}
                    />
                </Grid>
                <Grid item md={4} sx={{ marginTop: { xs: '100px', md: '0px', sm: '0px' } }}>
                    <ItemTitleText>FORCE RESOLUTION</ItemTitleText>
                    <ContentTabBar
                        tabArray={FORCE_RESOLUTION_TAB}
                        activeTab={defaultValue.force_resolution}
                        handleChangeActiveTab={handleChangeForceResolutionActiveTab}
                        useOld={true}
                    />
                </Grid>
            </MainGrid>
        </Box>
    );
};
