import { Box, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../api';
import { IMAGES } from '../../../assets';
import NoDataFound from '../../../components/NoDataFound';
import WeeklyCalendar from '../../../components/WeeklyCalendar';
import useFetch from '../../../hooks/useFetch';
import { fetchLocations, setActiveClientLocation } from '../../../redux/reducers/clientLocationSlice';
import { CLEAR_SCHEDULE_CONFIRMATION, LOOP_CREATE_TYPE, LOOP_SCHEDULE_TAB, ORIENTATION, TIME_ZONES } from '../../../utils/constants';
import {
    filterMirrorLocation,
    formateLoopDataToCalenderEvents,
    getAccessObjectFromArrayObject,
    getOriantationByLayout,
    sortCategoryList
} from '../../../utils/helpers';
import { COLORS } from '../../../utils/theme';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { DeleteButton } from '../../../widgets/CommonWidgets';
import { LoopChart } from './components/LoopChart';
import { ContentTabBar } from '../../../components/ContentTabBar';
import { LoopTemplates } from './components/LoopTemplates';
import { AccessControlledComponent } from '../../../components/AccessControlledComponent';
import { fetchAllContent, fetchAllLoopByLocation } from '../../../redux/reducers/loopSlice';

const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
});

const TimeZoneBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const TimeIcon = styled('img')({
    height: 26,
    width: 26
});

const TimeZoneText = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    marginLeft: 12
});
const ScheduleBox = styled(Box)({
    backgroundColor: COLORS.white,
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem'
});
const DeleteScheduleBox = styled(Box)({
    margin: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
});

export const ClientLoops = () => {
    const dispatch = useDispatch();
    const { locations, activeLocation } = useSelector((state) => state.clientLocation);
    const { id } = useSelector((state) => state.auth.user.client);
    const { allContents, syncingLoopStatus, loops } = useSelector((state) => state.loop);
    const locationList = filterMirrorLocation(sortCategoryList(locations));
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [activeLoopTab, setActiveLoopTab] = useState(1);
    const [isLoopSync, setIsLoopSync] = useState(false);
    const [fatchingLibrary, setFatchingLibrary] = useState(true);
    const { access } = useSelector((state) => state.auth);

    const {
        data: currentLoop,
        isLoading,
        callFetch: fetchCurrentLoopByLocation
    } = useFetch({
        initialUrl: `/get_current_loop`,
        skipOnStart: true,
        onFailure: true
    });

    useEffect(() => {
        if (locationList.length && !activeLocation) {
            dispatch(setActiveClientLocation(locationList[0]?.id));
        } else {
            let ifExist = locationList.some((el) => el.id === activeLocation);
            if (!ifExist) dispatch(setActiveClientLocation(locationList[0]?.id));
        }
    }, [activeLocation, dispatch, locationList]);

    useEffect(() => {
        let params = {
            location_id: activeLocation
        };
        if (activeLocation && syncingLoopStatus?.totalSyncFound === 0) {
            dispatch(fetchAllLoopByLocation({ params }));
            fetchCurrentLoopByLocation({ params });
        }
    }, [fetchCurrentLoopByLocation, activeLocation, dispatch, syncingLoopStatus?.totalSyncFound]);

    useEffect(() => {
        dispatch(fetchLocations(id));
    }, [dispatch, id]);

    const orientation = useMemo(() => {
        if (locations.length !== 0) {
            let activeLocationData = locations.find((item) => item?.id === activeLocation);
            return getOriantationByLayout(activeLocationData?.layout);
        }
        return ORIENTATION.LANDSCAPE;
    }, [locations, activeLocation]);

    useEffect(() => {
        if (activeLocation) {
            let params = {
                orientation: orientation,
                status: 1
            };
            dispatch(fetchAllContent({ params })).then((res) => {
                setFatchingLibrary(false);
            });
        }
    }, [dispatch, activeLocation, locations, orientation]);

    useEffect(() => {
        if (allContents.length && loops?.length && !isLoopSync && !fatchingLibrary) {
            // setIsLoopSync(true);
            // dispatch(syncAllLoopByLibrary({ loops: loops, libraryContents: allContents }));
        }
    }, [dispatch, allContents, loops, isLoopSync, fatchingLibrary]);

    const timeZone = useMemo(() => {
        let currentLocation = locations?.find((location) => location.id === activeLocation);
        return currentLocation?.timezone;
    }, [activeLocation, locations]);

    const handleChangedLocation = (e) => {
        dispatch(setActiveClientLocation(e.target.value));
    };
    const handleClearSchedule = async () => {
        setLoading(true);
        try {
            const { data } = await api.loop.clearSchedule(activeLocation);
            if (data.success) {
                fetchCurrentLoopByLocation({
                    params: {
                        location_id: activeLocation
                    }
                });
                dispatch(
                    fetchAllLoopByLocation({
                        params: {
                            location_id: activeLocation
                        }
                    })
                );
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setLoading(false);
    };
    const loopTemplatesTab = useMemo(() => {
        let result = LOOP_SCHEDULE_TAB.map((item) => {
            return { ...item, inactive: !getAccessObjectFromArrayObject(access.access, item?.name?.toLowerCase()).visibility };
        });
        return result;
    }, [access]);

    const handleChangeLoopTab = (item) => {
        setActiveLoopTab(item.id);
    };

    return (
        <Box>
            <CommonHeader isNotShowRightCol
                title={'Loops'}
                dropdownValue={activeLocation}
                dropdownList={locationList}
                handleChangeDropdown={handleChangedLocation}
                outlineInputClass={{ top: 0 }}
                isSaveButtonDisabled={!locationList?.length}
                recordNotFound={!locationList?.length}

            />
            <Box sx={{ marginTop: '24px' }}>
                {activeLocation ? (
                    <MainBox>
                        <LoopChart selectedLocation={activeLocation} isLoading={isLoading} data={currentLoop} />
                        <ScheduleBox sx={{ marginTop: '24px' }}>
                            <ContentTabBar
                                tabArray={loopTemplatesTab}
                                activeTab={activeLoopTab}
                                inactiveStyle={{ opacity: 0.3 }}
                                handleChangeActiveTab={handleChangeLoopTab}
                            />
                            {activeLoopTab === 1 ? (
                                <LoopTemplates
                                    selectedLocation={activeLocation}
                                    templateAccess={'templates'}
                                    customAccess={'custom'}
                                    customLoopData={loops?.filter((item) => item.loop_type === LOOP_CREATE_TYPE.CUSTOM)}
                                />
                            ) : (
                                <AccessControlledComponent accessValue={'schedule'}>
                                    <WeeklyCalendar
                                        scheduleList={formateLoopDataToCalenderEvents(loops)}
                                        selectedLocation={activeLocation}
                                        timeZone={timeZone}
                                        primaryLoop={loops?.find((item) => Boolean(item.is_primary))}
                                    />
                                    <DeleteScheduleBox>
                                        <TimeZoneBox>
                                            <TimeIcon src={IMAGES.Timezone} />
                                            <TimeZoneText>{TIME_ZONES[timeZone]}</TimeZoneText>
                                        </TimeZoneBox>
                                        <Box>
                                            <DeleteButton
                                                loading={loading}
                                                disabled={
                                                    getAccessObjectFromArrayObject(access.access, 'schedule')?.visibility ? false : true
                                                }
                                                onClick={handleClearSchedule}
                                                confirmation={CLEAR_SCHEDULE_CONFIRMATION}
                                                label={'Clear Schedule'}
                                            />
                                        </Box>
                                    </DeleteScheduleBox>
                                </AccessControlledComponent>
                            )}
                        </ScheduleBox>
                    </MainBox>
                ) : (
                    <NoDataFound message={'Location Not Found.'} />
                )}
            </Box>
        </Box>
    );
};
