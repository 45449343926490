import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchLocations = createAsyncThunk('users/fetchLocations', async (params) => {
    const response = await api.location.get(params);
    return response.data.result;
});

const initialState = {
    locations: [],
    isPending: false,
    activeLocation: ''
};

export const clientLocationSlice = createSlice({
    name: 'clientLocation',
    initialState,
    reducers: {
        setActiveClientLocation: (state, action) => {
            state.activeLocation = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLocations.fulfilled, (state, action) => {
            state.locations = action.payload;
            state.isPending = false
        });
        builder.addCase(fetchLocations.pending, (state, action) => {
            state.isPending = true;
        });
        builder.addCase(fetchLocations.rejected, (state, action) => {
            state.locations = [];
            state.isPending = false
        });
    }
});

export const { setActiveClientLocation } = clientLocationSlice.actions;
export default clientLocationSlice.reducer;
