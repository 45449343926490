import { styled, Typography, Box, Button, Grid, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { api } from '../../../../api';
import { ICONS } from '../../../../assets';
import { AccessControlledComponent } from '../../../../components/AccessControlledComponent';
import { LoopPreview } from '../../../../components/LoopPreview/LoopViewWithDiv';
import { addSheduledLoopData, fetchAllLoopByLocation, updateLoopFormDataByKey } from '../../../../redux/reducers/loopSlice';
import { LOOP_SCHEDULE_TYPE, SCHEDULE_SLOTS } from '../../../../utils/constants';

import { COLORS } from '../../../../utils/theme';
import { AlertDialog } from '../../../../widgets/CommonWidgets';
import { CustomSchedule } from './CustomSchedule';

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '22px 0px'
});

const ScheduleButton = styled(Button)({
    height: 95,
    width: '100%',
    padding: 5,
    marginBottom: 10
});

const ScheduleText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});

const CustomButtonText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black
});

const Name = styled(Typography)({
    fontSize: 16,
    height: 32,
    color: COLORS.black,
    textAlign: 'center',
    inlineSize: 110,
    overflowWrap: 'break-word',
    lineHeight: 1,
    marginTop: 5
});
const LoopBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 10,
    width: '100%'
});
const ActionBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    height: 95,
    width: 120,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    justifyContent: 'space-evenly',
    alignItems: 'center'
});
const ScheduleIconContainer = styled(Box)(({ icon }) => ({
    position: 'absolute',
    bottom: icon === 'clear' ? 4 : 'unset',
    top: icon === 'edit' ? 4 : 'unset',
    width: 22,
    height: 22,
    borderRadius: '50%',
    right: 4,
    '& svg': {
        width: 22,
        height: 22
    },
    '&:hover': {
        background: COLORS.primary.dark
    }
}));

export const LoopSchedule = () => {
    const [showCustomSchedule, setShowCustomeSchedule] = useState();
    const [showTimeSlotConflictPopup, setTimeSlotConflictPopup] = useState({ isOpen: false, conflictLoopName: '', type: '' });
    const [showPrimaryConflictPopup, setPrimaryConflictPopup] = useState({ isOpen: false, conflictLoopName: '', isPrimary: false });
    const [selectedTimeSlot, setTimeSlot] = useState();
    const [showCloseIcon, setCloseIcon] = useState({ id: null, show: false });
    const { loops } = useSelector((state) => state.loop);
    const { loopFormData, scheduledLoopData } = useSelector((state) => state.loop);
    const dispatch = useDispatch();
    const { activeLocation } = useSelector((state) => state.clientLocation);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const pathName = useLocation().pathname;
    const isInCustomEdit = pathName.includes('custom') && id;
    const isInTemplateEdit = pathName.includes('edit') && !isInCustomEdit;

    useEffect(() => {
        let params = {
            location_id: activeLocation
        };
        if (activeLocation && loops.length === 0) {
            dispatch(fetchAllLoopByLocation({ params }));
        }
    }, [activeLocation, dispatch, loops.length]);

    const checkIsExists = (type) => {
        if (loopFormData.schedule.length) {
            let index = loopFormData.schedule.findIndex((schedule) => schedule.type === type);
            return index > -1 ? true : false;
        }
    };
    const handleScheduleClick = (slot) => {
        if (checkIsExists(slot)) return
        if (slot === LOOP_SCHEDULE_TYPE.CUSTOM) {
            setShowCustomeSchedule(true);
        } else {
            fetchExistedLoop(slot);
        }
    };

    const handleClearSchedule = (slot) => {
        if (checkIsExists(slot)) {
            let selectedSlotClone = [...loopFormData.schedule];
            let index = selectedSlotClone.findIndex((e) => e.type === slot);
            selectedSlotClone.splice(index, 1);
            dispatch(updateLoopFormDataByKey({ key: 'schedule', value: selectedSlotClone }));
        }
    }

    const handleEditCustomSchedule = (slot) => {
        if (slot === LOOP_SCHEDULE_TYPE.CUSTOM) {
            setShowCustomeSchedule(true);
        }
    }

    const fetchExistedLoop = async (slot, checkforPrimary, scheduleData) => {
        setLoading(true);
        const params = {
            location_id: activeLocation,
            schedule: {
                type: slot
            }
        };
        if (isInTemplateEdit || isInCustomEdit) {
            params.id = loopFormData.id;
        }
        if (slot === LOOP_SCHEDULE_TYPE.ALL_DAY || checkforPrimary) {
            params['check_for_primary'] = 1;
        }
        if (slot === LOOP_SCHEDULE_TYPE.CUSTOM) {
            if (scheduleData.length) {
                params.schedule = {
                    type: scheduleData[0]?.type,
                    event_type: scheduleData[0]?.eventType,
                    start_date_time: scheduleData[0]?.startDateTime,
                    end_date_time: scheduleData[0]?.endDateTime,
                    recurrence_pattern: scheduleData[0]?.recurrencePattern,
                    loop_schedules: scheduleData[0]?.loop_schedules,
                    days: scheduleData[0]?.days
                };
            } else {
                setLoading(false);
                return false;
            }
        }
        var result;
        try {
            const { data } = await api.loop.checkScheduleExits(params);
            result = data;
            if (result.success && result?.result) {
                const response = result?.result;
                let existedPrimaryLoop = response.filter((item) => item.is_primary);
                let existedConflictLoops = response.filter((item) => !item.is_primary);
                if (slot === LOOP_SCHEDULE_TYPE.ALL_DAY) {
                    setPrimaryConflictPopup({
                        isOpen: true,
                        conflictLoopName: existedPrimaryLoop?.[0]?.name,
                        isPrimary: slot === LOOP_SCHEDULE_TYPE.ALL_DAY
                    });
                    handleScheduleLoopData(slot, checkforPrimary, existedPrimaryLoop?.[0]);
                } else if (existedPrimaryLoop.length && !existedConflictLoops.length && slot !== LOOP_SCHEDULE_TYPE.ALL_DAY) {
                    setPrimaryConflictPopup({
                        isOpen: true,
                        conflictLoopName: existedPrimaryLoop?.[0]?.name,
                        isPrimary: false
                    });
                    handleScheduleLoopData(slot, checkforPrimary, existedPrimaryLoop?.[0]);
                } else {
                    setTimeSlotConflictPopup({ isOpen: true, conflictLoopName: existedConflictLoops?.[0]?.name, type: slot });
                    handleScheduleLoopData(slot, checkforPrimary, existedConflictLoops?.[0]);
                }
            } else {
                handleSelectScheduleType(slot, scheduleData);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setLoading(false);
        }
    };
    const handleScheduleLoopData = (slot, checkforPrimary, loopData) => {
        let loopArray = [...scheduledLoopData];
        const index = loopArray.findIndex((loop) => loop.type === slot);
        if (index === -1 && !checkforPrimary) {
            loopArray = [
                ...loopArray,
                {
                    type: slot,
                    name: loopData?.name,
                    id: loopData?.loop_type === 'template' ? loopData?.template_id : loopData?.id,
                    chartData: loopData?.loop_categories?.map?.((item) => {
                        return {
                            color: item?.playlist?.color,
                            value: 1,
                            label: 'PL'
                        };
                    })
                }
            ];
        }
        dispatch(addSheduledLoopData(loopArray));
    };

    const handlePrepopulateScheduledLoop = () => {
        let loopArray = [];

        for (let i = 0; i < loops.length; i++) {
            if (loops[i]?.loop_schedule && loops[i]?.loop_schedule?.length !== 0) {
                if (loopArray?.findIndex((loop) => loop.type === loops[i]?.loop_schedule[0]?.type) === -1) {
                    loopArray.push({
                        type: loops[i]?.loop_schedule[0]?.type,
                        name: loops[i]?.name,
                        id: loops[i]?.loop_type === 'template' ? loops[i]?.template_id : loops[i]?.id,
                        chartData: loops[i]?.loop_categories?.map?.((item) => {
                            return {
                                color: item?.playlist?.color,
                                value: 1,
                                label: 'PL'
                            };
                        })
                    });
                }
            }
            if (Boolean(loops[i]?.is_primary)) {
                loopArray.push({
                    type: 'allDay',
                    name: loops[i]?.name,
                    id: loops[i]?.loop_type === 'template' ? loops[i]?.template_id : loops[i]?.id,
                    chartData: loops[i]?.loop_categories?.map?.((item) => {
                        return {
                            color: item?.playlist?.color,
                            value: 1,
                            label: 'PL'
                        };
                    })
                });
            }
        }
        dispatch(addSheduledLoopData(loopArray));
    };
    const handleSelectScheduleType = (slot, scheduleData) => {
        let selectedSlotClone = [...loopFormData.schedule];
        if (slot === LOOP_SCHEDULE_TYPE.ALL_DAY) {
            selectedSlotClone = [{ type: slot }];
            dispatch(updateLoopFormDataByKey({ key: 'is_primary', value: true }));
        } else if (slot === LOOP_SCHEDULE_TYPE.CUSTOM) {
            selectedSlotClone = scheduleData || loopFormData.schedule;
            dispatch(updateLoopFormDataByKey({ key: 'is_primary', value: false }));
        } else if (checkIsExists(LOOP_SCHEDULE_TYPE.ALL_DAY) || checkIsExists(LOOP_SCHEDULE_TYPE.CUSTOM)) {
            selectedSlotClone = [{ type: slot }];
            dispatch(updateLoopFormDataByKey({ key: 'is_primary', value: false }));
        } else {
            selectedSlotClone = [...selectedSlotClone, { type: slot }];
        }
        dispatch(updateLoopFormDataByKey({ key: 'schedule', value: selectedSlotClone }));
        if (showPrimaryConflictPopup.isOpen) {
            setPrimaryConflictPopup({ isOpen: false, conflictLoopName: '', isPrimary: false });
        }
        if (showTimeSlotConflictPopup.isOpen) {
            setTimeSlotConflictPopup({ isOpen: false, conflictLoopName: '', type: '' });
        }

        setLoading(false);
    };

    useEffect(() => {
        handlePrepopulateScheduledLoop();
    }, [loops]);

    return (
        <AccessControlledComponent accessValue={'schedule'}>
            <MainBox>
                {!showCustomSchedule && (
                    <Grid container spacing={{ xs: 1 }} justifyContent={'center'}>
                        {SCHEDULE_SLOTS?.map((slot) => {
                            const isSelected =
                                loopFormData.schedule?.length > 0 ? checkIsExists(slot.id) : loopFormData.schedule?.[0] === slot.id;
                            const loop_data = scheduledLoopData?.find((item) => item.type === slot.id);

                            return (
                                <Grid item xs={6} lg={3} xxl={2} key={slot.id} justifyContent={'center'} alignItems={'center'}>
                                    <ScheduleButton
                                        onClick={() => {
                                            setTimeSlot(slot.id)
                                            handleScheduleClick(slot.id)
                                        }}
                                        sx={{
                                            backgroundColor: isSelected ? COLORS.primary.light : 'transparent',
                                            position: 'relative'
                                        }}
                                        variant="outlined"
                                        disabled={loading}
                                        onMouseEnter={() => {
                                            if (checkIsExists(slot.id)) setCloseIcon({ id: slot.id, show: true });
                                        }}
                                        onMouseLeave={() => {
                                            if (checkIsExists(slot.id)) setCloseIcon({ id: slot.id, show: false });
                                        }}
                                    >
                                        <Box>
                                            <ScheduleText>{slot.title}</ScheduleText>
                                            {slot.id === LOOP_SCHEDULE_TYPE.CUSTOM ? (
                                                loopFormData?.schedule?.[0]?.type === LOOP_SCHEDULE_TYPE.CUSTOM ? (
                                                    <CustomButtonText>{`${loopFormData?.schedule[0]?.startDateTime} To ${loopFormData?.schedule[0]?.endDateTime}`}</CustomButtonText>
                                                ) : (
                                                    <CustomButtonText>{slot.time}</CustomButtonText>
                                                )
                                            ) : (
                                                <ScheduleText>{slot.time}</ScheduleText>
                                            )}
                                            {loading && slot.id === selectedTimeSlot && (
                                                <ActionBox>
                                                    <CircularProgress />
                                                </ActionBox>
                                            )}
                                            {showCloseIcon.show && slot.id === LOOP_SCHEDULE_TYPE.CUSTOM && checkIsExists(slot.id) && (
                                                <ScheduleIconContainer onClick={() => handleEditCustomSchedule(slot.id)} icon='edit' >
                                                    <ICONS.Edit fontSize="small" sx={{ color: COLORS.vividYellow }} />
                                                </ScheduleIconContainer>
                                            )}
                                            {showCloseIcon.show && slot.id === showCloseIcon.id && checkIsExists(slot.id) && (
                                                <ScheduleIconContainer onClick={() => handleClearSchedule(slot.id)} icon='clear' >
                                                    <ICONS.Close fontSize="small" sx={{ color: COLORS.vividYellow }} />
                                                </ScheduleIconContainer>
                                            )}
                                        </Box>
                                    </ScheduleButton>
                                    {loop_data && loop_data?.chartData && (
                                        <LoopBox sx={{ opacity: Number(loop_data?.id) === Number(id) ? 1 : 0.5 }}>
                                            <LoopPreview chartData={loop_data.chartData} size={90} barWidth={20} />
                                            <Name>{loop_data.name}</Name>
                                        </LoopBox>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
                {showCustomSchedule && (
                    <CustomSchedule
                        handleCloseCustomeMenu={(data) => {
                            setShowCustomeSchedule(false);
                            fetchExistedLoop(selectedTimeSlot, false, data);
                        }}
                    />
                )}
                {showTimeSlotConflictPopup.isOpen && showTimeSlotConflictPopup.type !== LOOP_SCHEDULE_TYPE.CUSTOM ? (
                    <AlertDialog
                        isOpen={showTimeSlotConflictPopup.isOpen}
                        isClose={() => {
                            setTimeSlotConflictPopup({ isOpen: false, conflictLoopName: '', type: '' });
                            setLoading(false);
                        }}
                        handleSubmit={() => {
                            handleSelectScheduleType(selectedTimeSlot);
                        }}
                        title={'Schedule Conflict'}
                        description={
                            <Typography>
                                The Loop{' '}
                                <b>
                                    <i>{showTimeSlotConflictPopup?.conflictLoopName}</i>
                                </b>{' '}
                                is already scheduled for the {selectedTimeSlot} time slot. Do you want to override and replace it with the
                                loop{' '}
                                <b>
                                    <i>{loopFormData.name}</i>
                                </b>
                                ?
                            </Typography>
                        }
                        isCancel={'No'}
                    />
                ) : null}
                {showTimeSlotConflictPopup.isOpen && showTimeSlotConflictPopup.type === LOOP_SCHEDULE_TYPE.CUSTOM ? (
                    <AlertDialog
                        isOpen={showTimeSlotConflictPopup.isOpen}
                        isClose={() => {
                            setTimeSlotConflictPopup({ isOpen: false, conflictLoopName: '', type: '' });
                            dispatch(updateLoopFormDataByKey({ key: 'schedule', value: [] }));
                            setLoading(false);
                        }}
                        title={'Schedule Conflict'}
                        description={
                            <Typography>
                                The Loop{' '}
                                <b>
                                    <i>{showTimeSlotConflictPopup?.conflictLoopName}</i>
                                </b>{' '}
                                has already been scheduled for this timeframe. Please adjust your date and/or time.
                            </Typography>
                        }
                        isCancel={'Ok'}
                    />
                ) : null}
                {showPrimaryConflictPopup.isOpen ? (
                    <AlertDialog
                        isOpen={showPrimaryConflictPopup.isOpen}
                        isClose={() => {
                            setPrimaryConflictPopup({ isOpen: false, conflictLoopName: '', isPrimary: false });
                            setLoading(false);
                        }}
                        handleSubmit={() => {
                            handleSelectScheduleType(selectedTimeSlot);
                        }}
                        title={'Schedule Conflict'}
                        description={
                            showPrimaryConflictPopup.isPrimary ? (
                                'Are you sure you want to make this the Primary Loop? The current Primary Loop will be removed from the schedule.'
                            ) : (
                                <Typography>
                                    The loop{' '}
                                    <b>
                                        <i>{showPrimaryConflictPopup.conflictLoopName}</i>
                                    </b>{' '}
                                    is scheduled as a Primary loop. It will remain the Primary loop, but the loop{' '}
                                    <b>
                                        <i>{loopFormData.name}</i>
                                    </b>{' '}
                                    will be scheduled in place of it for the {selectedTimeSlot} time slot. Do you want to proceed?{' '}
                                </Typography>
                            )
                        }
                        isCancel={'No'}
                    />
                ) : null}
            </MainBox>
        </AccessControlledComponent>
    );
};
