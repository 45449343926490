import { styled, Typography, Box, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoopPreview } from '../../../../components/LoopPreview/LoopViewWithDiv';
import { fetchLoopTemplate } from '../../../../redux/reducers/loopSlice';

import { COLORS } from '../../../../utils/theme';
import { PAGES } from '../../../../utils/urls';
import { LOOP_CREATE_TYPE, LOOP_SCHEDULE_TYPE, LOOP_TEMPLATES_TAB } from '../../../../utils/constants';
import { HorizontalScrollWithButton } from '../../../../widgets/HorizontalScrollWithButton';
import { toCapitalCase } from '../../../../utils/helpers';
import { ICONS } from '../../../../assets';
import { ViewerPermission } from '../../../../components/ViewerPermission';
import { AccessControlledComponent } from '../../../../components/AccessControlledComponent';

const MainBox = styled(Box)({
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
});

const TemplateItemRow = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer',
    margin: 10,
    padding: 15,
    minHeight: 188
});
const TitleContainer = styled(Box)({
    padding: 10,
    borderBottom: `1px solid ${COLORS.border}`
});
const TemplateName = styled(Typography)({
    fontSize: 18,
    color: COLORS.black
});
const Text = styled(Typography)({
    fontSize: 12,
    color: COLORS.black
});
const TemplateNameText = styled(Typography)({
    fontSize: 16,
    height: 32,
    color: COLORS.black,
    textAlign: 'center',
    inlineSize: 110,
    overflowWrap: 'break-word',
    lineHeight: 1
});

const AddLoopButton = styled(IconButton)({
    border: `10px solid ${COLORS.darkGray}`,
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px'
});
const AddCircleRoundedIcon = styled(ICONS.AddCircleRoundedIcon)({
    color: COLORS.darkGray,
    fontSize: 40,
    cursor: 'pointer'
});

export const LoopTemplates = ({ selectedLocation, templateAccess, customAccess, customLoopData }) => {
    const { adminTemplate } = useSelector((state) => state.loop);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(fetchLoopTemplate({ params: { status: 1, location_id: selectedLocation } }));
    }, [dispatch, selectedLocation]);

    const handleNavigation = (loop_type, id, template_id) => {
        if (loop_type === LOOP_CREATE_TYPE.CUSTOM) {
            navigate(PAGES.CLIENT_LOOP_CUSTOM_EDIT.url + `/${id}`, { state: { location_id: selectedLocation } });
        } else if (template_id) {
            navigate(PAGES.CLIENT_LOOP_TEMPLATE_EDIT.url + `/${id}`, { state: { location_id: selectedLocation, template_id } });
        } else {
            navigate(PAGES.CLIENT_LOOP_TEMPLATE.url + `/${id}`, { state: { location_id: selectedLocation } });
        }
    };
    const LoopItem = ({ data, isTemplate }) => {
        const scheduleList = isTemplate ? data.loops?.[0]?.loop_schedules : data?.loop_schedule;
        const template_id = isTemplate && data.loops?.[0]?.id;
        const isPrimary = isTemplate ? data.loops?.[0]?.is_primary : data?.is_primary;
        return (
            <TemplateItemRow
                key={data.id}
                item
                xs={6}
                sm={3}
                md={3}
                lg={1.7}
                onClick={() => {
                    handleNavigation(isTemplate || data.loop_type, data.id, template_id);
                }}
                sx={[
                    {
                        ':hover': {
                            outline: `3px solid ${COLORS.primary.light}`
                        }
                    }
                ]}
            >
                <TemplateNameText>{data.name}</TemplateNameText>
                <LoopPreview
                    chartData={data?.loop_categories?.map?.((item) => {
                        return {
                            color: item?.playlist?.color,
                            value: 1,
                            label: 'PL'
                        };
                    })}
                    size={90}
                    barWidth={20}
                />
                <>
                    {isPrimary ? (
                        <Text>{isPrimary ? 'PRIMARY' : ''}</Text>
                    ) : (
                        <>
                            <Text>{scheduleList?.length ? 'SCHEDULED' : ''}</Text>
                            {Array.isArray(scheduleList) &&
                                scheduleList?.map(({ type }) => {
                                    return (
                                        <Text>
                                            {type
                                                ? type === LOOP_SCHEDULE_TYPE.CUSTOM
                                                    ? toCapitalCase(type)
                                                    : `${toCapitalCase(type)}s`
                                                : ''}
                                        </Text>
                                    );
                                })}
                        </>
                    )}
                </>
            </TemplateItemRow>
        );
    };
    return (
        <MainBox>
            <AccessControlledComponent accessValue={templateAccess}>
                <>
                    <TitleContainer>
                        <TemplateName>{LOOP_TEMPLATES_TAB.LOOP_TEMPLATES}</TemplateName>
                    </TitleContainer>
                    <HorizontalScrollWithButton>
                        {adminTemplate?.map((item) => {
                            return <LoopItem data={item} isTemplate={true} key={item.id} />;
                        })}
                    </HorizontalScrollWithButton>
                </>
            </AccessControlledComponent>
            <AccessControlledComponent accessValue={customAccess}>
                <>
                    <TitleContainer sx={{ borderTop: `1px solid ${COLORS.border}` }}>
                        <TemplateName>{LOOP_TEMPLATES_TAB.CUSTOM_LOOPS}</TemplateName>
                    </TitleContainer>
                    <HorizontalScrollWithButton>
                        <ViewerPermission>
                            <TemplateItemRow
                                onClick={() => {
                                    navigate(PAGES.CLIENT_LOOP_CUSTOM_CREATE.url, { state: { location_id: selectedLocation } });
                                }}
                            >
                                <TemplateNameText>{'Create Loop'}</TemplateNameText>
                                <AddLoopButton>
                                    <AddCircleRoundedIcon />
                                </AddLoopButton>
                            </TemplateItemRow>
                        </ViewerPermission>
                        {customLoopData?.map((item) => {
                            return <LoopItem data={item} key={item.id} />;
                        })}
                    </HorizontalScrollWithButton>
                </>
            </AccessControlledComponent>
        </MainBox>
    );
};
