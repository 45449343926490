import { Button, Grid, Skeleton } from '@mui/material';
import { styled } from '@mui/system';
import { SkeletonArray } from '../../utils/constants';
import { COLORS } from '../../utils/theme';

const BarMainGrid = styled(Grid)({
    width: '100%',
    display: 'flex'
});

const TabItemGrid = styled(Grid)({
    border: `1px solid ${COLORS.darkGray}`,
    fontSize: 14,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    margin: '4px 5px',
    padding: '4px 10px',
    borderRadius: '.425rem',
    '& :hover': {
        backgroundColor: COLORS.primary.light,
    }
});

const TabButton = styled(Button)(({ isActive, theme }) => ({
    color: isActive ? COLORS.white : COLORS.black,
    padding: '6px 16px',
    borderRadius: '0.425rem',
    backgroundColor: isActive ? COLORS.primary.light : 'transparent',
    border: `1px solid ${isActive ? COLORS.primary.light : COLORS.border}`,
    fontWeight: 500,
    marginRight: 8,
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
        marginBottom: 8
    },
    height: 'auto',
    '&:hover': {
        backgroundColor: COLORS.primary.light,
        color: COLORS.white,
        border: `1px solid ${COLORS.primary.light}`
    }
}));

export const OutlinedTabBar = ({ tabArray, activeTab, handleChangeActiveTab, hasAll, loading, showOnlyTab = false, usingButton, sx }) => {
    return (
        <BarMainGrid sx={sx} container>
            {loading ? (
                SkeletonArray.map((skeleton) => {
                    return (
                        <TabItemGrid key={skeleton} item sx={{ p: 0 }}>
                            <Skeleton height={usingButton ? 36 : 27} width={usingButton ? 80 : 60} variant="rounded" />
                        </TabItemGrid>
                    );
                })
            ) : (
                <>
                    {hasAll && (
                        <TabItemGrid {...{ showOnlyTab }}
                            key={'all'}
                            onClick={() => handleChangeActiveTab({ name: 'All', id: 'All' })}
                            sx={
                                activeTab?.id === 'All'
                                    ? {
                                        backgroundColor: COLORS.primary.light,
                                        border: `1px solid ${COLORS.primary.light}`,
                                        color: `${COLORS.white}!important`
                                    }
                                    : {
                                        '&:hover': {
                                            backgroundColor: COLORS.primary.light,
                                            border: `1px solid ${COLORS.primary.light}`,
                                            color: `${COLORS.white}!important`
                                        }
                                    }
                            }
                            id={'All'}
                            item
                        >
                            <span sx={activeTab?.id === 'All' ? { color: COLORS.white } : {}}>{'All'}</span>
                        </TabItemGrid>
                    )}
                    {tabArray &&
                        tabArray.map((item) => {
                            return usingButton ? <TabButton key={item.id} isActive={activeTab?.id === item.id} onClick={() => handleChangeActiveTab(item)} >{item?.name || ''}</TabButton> :
                                <TabItemGrid {...{ showOnlyTab }}
                                    key={item.id}
                                    onClick={() => handleChangeActiveTab(item)}
                                    sx={
                                        activeTab?.id === item.id
                                            ? {
                                                backgroundColor: COLORS.primary.light,
                                                border: `1px solid ${COLORS.primary.light}`,
                                                color: `${COLORS.white}!important`
                                            }
                                            : {
                                                '&:hover': {
                                                    backgroundColor: COLORS.primary.light,
                                                    border: `1px solid ${COLORS.primary.light}`,
                                                    color: `${COLORS.white}!important`
                                                }
                                            }
                                    }
                                    id={item.id}
                                    item
                                >
                                    {item.name}
                                </TabItemGrid>

                        })}
                </>
            )}
        </BarMainGrid>
    );
};
