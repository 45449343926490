import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchLibrary = createAsyncThunk('users/fetchLibrary', async () => {
    const response = await api.library.get();
    return response.data.result;
});

export const fetchAddVideoToLibrary = createAsyncThunk('users/fetchAddVideoToLibrary', async (data, { dispatch }) => {
    dispatch(addToLibrary(data));
    const reqData = {
        contents: data?.map((item) => item.id)
    };
    await api.library.addVideo(reqData);
});

export const fetchRemoveVideoToLibrary = createAsyncThunk('users/fetchAddVideoToLibrary', async (data, { dispatch }) => {
    dispatch(removeToLibrary(data));
    const reqData = {
        contents: data?.map((item) => item.id)
    };
    await api.library.removeVideo(reqData);
});

const initialState = {
    librarys: []
};

export const librarySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        addToLibrary: (state, action) => {
            let previousData = [...state.librarys];
            previousData = [...previousData, ...action.payload];
            state.librarys = previousData;
        },
        removeToLibrary: (state, action) => {
            let previousData = [...state.librarys];
            previousData = previousData.filter((item) => action.payload.findIndex((e) => e.id === item.id) === -1);
            state.librarys = previousData;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLibrary.fulfilled, (state, action) => {
            state.librarys = action.payload;
        });
    }
});

export const { addToLibrary, removeToLibrary } = librarySlice.actions;

export default librarySlice.reducer;
