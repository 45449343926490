import { Tooltip, styled, tooltipClasses } from '@mui/material';
import React from 'react'

const MainTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {

    },
    [`& .${tooltipClasses.tooltip}`]: {

    },
}));

const CustomTooltip = ({ children, ...props }) => {
    return (
        <MainTooltip {...props} >{children}</MainTooltip>
    )
}

export default CustomTooltip