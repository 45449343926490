import { Box, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../assets';
import { LOOP_CREATE_TYPE } from '../../utils/constants';
import { shortColorFromHighToLow } from '../../utils/helpers';
import { COLORS } from '../../utils/theme';
import { PAGES } from '../../utils/urls';
import { LoopViewWithDiv } from '../LoopViewWithDiv/LoopViewWithDiv';

const TIME_COLUMN_WIDTH = 40;

const MainBox = styled(Box)({
    width: '100%'
});

const TimeBoxView = styled(Box)({
    width: '100%',
    height: TIME_COLUMN_WIDTH,
    borderTop: `1px solid ${COLORS.border}`,
    borderRight: `1px solid ${COLORS.border}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const DayTimeBoxView = styled(Box)({
    width: '100%',
    height: TIME_COLUMN_WIDTH,
    borderTop: `1px solid ${COLORS.border}`,
    borderRight: `1px solid ${COLORS.border}`,
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    overflow: 'hidden'
});

const EventBoxInTime = styled(Box)({
    width: '90%',
    height: TIME_COLUMN_WIDTH,
    borderRadius: 20,
    padding: 4,
    overflow: 'hidden',
    flexDirection: 'row',
    display: 'flex',
    position: 'absolute',
    cursor: 'pointer',
    transform: 'scale(1)',

    transitionDuration: '0.2s',
    ':hover': {
        transform: 'scale(1.05)'
    }
});

const DayNameBox = styled(Box)({
    height: 64,
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

const DayNameText = styled(Typography)({
    fontSize: 20
});

const DateNameText = styled(Typography)({
    fontSize: 12
});

const TimeText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black
});
const Loopname = styled(Typography)({
    fontSize: 14,
    color: COLORS.white,
    fontWeight: 'bold',
    padding: '0px 8px',
    position: 'absolute',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%'
});

const DayTimeColumnMainBox = styled(Box)({
    width: '100%',
    borderLeft: `1px solid ${COLORS.border}`,
    borderBottom: `1px solid ${COLORS.border}`,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative'
});

const HeaderView = styled(Box)({
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${COLORS.border}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 12
});

const WeekActionView = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const DateRangeNameText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});
const timeArray = [
    {
        id: 10,
        time: '00:00',
        start: '00:00:00',
        end: '01:59:59'
    },
    {
        id: 11,
        time: '02:00',
        start: '02:00:00',
        end: '03:59:59'
    },
    {
        id: 12,
        time: '04:00',
        start: '04:00:00',
        end: '05:59:59'
    },
    {
        id: 1,
        time: '06:00',
        start: '06:00:00',
        end: '07:59:59'
    },
    {
        id: 2,
        time: '08:00',
        start: '08:00:00',
        end: '09:59:59'
    },
    {
        id: 3,
        time: '10:00',
        start: '10:00:00',
        end: '11:59:59'
    },
    {
        id: 4,
        time: '12:00',
        start: '12:00:00',
        end: '13:59:59'
    },
    {
        id: 5,
        time: '14:00',
        start: '14:00:00',
        end: '15:59:59'
    },
    {
        id: 6,
        time: '16:00',
        start: '16:00:00',
        end: '17:59:59'
    },
    {
        id: 7,
        time: '18:00',
        start: '18:00:00',
        end: '19:59:59'
    },
    {
        id: 8,
        time: '20:00',
        start: '20:00:00',
        end: '21:59:59'
    },
    {
        id: 9,
        time: '22:00',
        start: '22:00:00',
        end: '23:59:59'
    }
];

const WeeklyCalendar = ({ scheduleList, selectedLocation, primaryLoop }) => {
    const initialDate = moment()._d;
    const weekDays = 7;
    const [weekData, setWeekData] = useState([]);
    const [startDate, setStartDate] = useState(initialDate);
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDowmMd = useMediaQuery(theme.breakpoints.down('md'));

    const loopOptions = {
        OUTSIDE_BACKGROUND_WIDTH: matchDowmMd ? 280 : 350,
        MAIN_ROUNT_WIDTH: matchDowmMd ? 210 : 270,
        CHART_WIDTH: matchDowmMd ? 140 : 180,
        INSIDE_WHITE_WIDTH: matchDowmMd ? 120 : 160
    };

    const checkIsInBetween = (compareDate, startDate, endDate) => {
        if (
            moment(compareDate).isBetween(
                startDate,

                endDate
            ) ||
            moment(compareDate).isSame(startDate) ||
            moment(compareDate).isSame(endDate)
        ) {
            return true;
        }
        return false;
    };

    const checkTimeArrayPossition = useCallback((compareDate, startDate, endDate, schedule) => {
        let timeArrayPossitions = [];

        for (let i = 0; i < timeArray.length; i++) {
            const compareFirstDate = `${moment(compareDate).format('YYYY-MM-DD')} ${timeArray[i].start}`;
            const compareLastDate = `${moment(compareDate).format('YYYY-MM-DD')} ${timeArray[i].end}`;
            const totldDurationInSecond = moment(compareLastDate).format('X') - moment(compareFirstDate).format('X');
            if (checkIsInBetween(startDate, compareFirstDate, compareLastDate)) {
                let startDiffranceInSecond = moment(startDate).format('X') - moment(compareFirstDate).format('X');
                const top = (startDiffranceInSecond * TIME_COLUMN_WIDTH) / totldDurationInSecond;
                timeArrayPossitions.push({ ...timeArray[i], index: i, isFirst: true, schedule, top });
            } else {
                if (moment(compareFirstDate).format('X') >= moment(startDate).format('X')) {
                    let readyForBreak = false;
                    let bottom;
                    if (moment(endDate).format('X') < moment(compareLastDate).format('X')) {
                        readyForBreak = true;
                    }
                    let endDiffranceInSecond = moment(compareLastDate).format('X') - moment(endDate).format('X');
                    bottom = (endDiffranceInSecond * TIME_COLUMN_WIDTH) / totldDurationInSecond;
                    timeArrayPossitions.push({ ...timeArray[i], index: i, isLast: readyForBreak, schedule, bottom });
                    if (readyForBreak) {
                        break;
                    }
                }
            }
        }

        let topPosition = 0;
        let lastTimeItem = timeArrayPossitions[timeArrayPossitions?.length - 1];
        let firstTimeItem = timeArrayPossitions[0];
        if (!firstTimeItem?.isFirst) {
            topPosition = 0;
        } else {
            topPosition = timeArrayPossitions[0]?.index * TIME_COLUMN_WIDTH + timeArrayPossitions[0]?.top;
        }

        let renderHeight = 0;

        if (!lastTimeItem?.isLast) {
            renderHeight = timeArrayPossitions.length * TIME_COLUMN_WIDTH - (timeArrayPossitions[0]?.top || 0);
        } else {
            renderHeight = timeArrayPossitions.length * TIME_COLUMN_WIDTH - lastTimeItem?.bottom - (timeArrayPossitions[0]?.top || 0);
        }

        return {
            topPosition,
            renderHeight,
            schedule
        };
    }, []);

    const checkForSchedule = useCallback(
        (date) => {
            let schedule = [];
            for (let i = 0; i < scheduleList.length; i++) {
                if (
                    checkIsInBetween(
                        moment(date).format('YYYY-MM-DD'),
                        moment(scheduleList?.[i]?.startDate).format('YYYY-MM-DD'),
                        moment(scheduleList?.[i]?.endDate).format('YYYY-MM-DD')
                    ) ||
                    scheduleList?.[i]?.type === 'daily'
                ) {
                    schedule.push(scheduleList[i]);
                }
            }
            return schedule;
        },
        [scheduleList]
    );

    const getWeekDayDataWithEvent = useCallback(() => {
        let dateArray = [];
        for (let i = 0; i < weekDays; i++) {
            const date = dateArray.length === 0 ? startDate : moment(dateArray[i - 1].date).add(1, 'days')._d;
            const isToday = moment(moment(date).format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD'));
            const schedules = checkForSchedule(date);
            let timeArray = [];
            let schedulesWithPossition = [];
            for (let i = 0; i < schedules.length; i++) {
                let startDate =
                    schedules?.[i]?.type === 'daily'
                        ? `${moment(date).format('YYYY-MM-DD')} ${schedules?.[i]?.startTime}`
                        : schedules?.[i]?.startDate;
                let endDate =
                    schedules?.[i]?.type === 'daily'
                        ? `${moment(date).format('YYYY-MM-DD')} ${schedules?.[i]?.endTime}`
                        : schedules?.[i]?.endDate;

                let position = checkTimeArrayPossition(date, startDate, endDate, schedules[i]);
                schedulesWithPossition.push(position);
            }

            let dayObject = {
                date,
                isToday,
                schedules,
                timeArray: timeArray,
                schedulesWithPossition
            };
            dateArray.push(dayObject);
        }
        setWeekData([...dateArray]);
    }, [checkForSchedule, checkTimeArrayPossition, startDate]);

    useEffect(() => {
        getWeekDayDataWithEvent();
    }, [getWeekDayDataWithEvent]);

    const handleNextWeek = () => {
        setStartDate(moment(startDate).add(7, 'days')._d);
    };

    const handlePreviousWeek = () => {
        setStartDate(moment(startDate).subtract(7, 'days')._d);
    };

    return (
        <MainBox>
            <HeaderView>
                <WeekActionView>
                    <DateRangeNameText sx={{ mx: 2 }}>
                        {`${moment(weekData[0]?.date).format('MMM DD')} - ${moment(weekData[weekData.length - 1]?.date).format(
                            'MMM DD'
                        )}, ${moment(weekData[0]?.date).format('YYYY')}`}
                    </DateRangeNameText>
                    <IconButton onClick={handlePreviousWeek}>
                        <ICONS.ArrowBack />
                    </IconButton>
                    <IconButton onClick={handleNextWeek}>
                        <ICONS.ArrowForward />
                    </IconButton>
                </WeekActionView>
            </HeaderView>

            <Box sx={{ overflowX: 'auto', overflowY: 'hidden', maxWidth: '100%', display: 'flex' }}>
                <Box sx={{ width: '4%', minWidth: '80px', paddingRight: 0.5 }} sm={1.5} md={1.5}>
                    <DayNameBox />
                    {timeArray.map((item, index) => {
                        return (
                            <TimeBoxView
                                key={item.id}
                                sx={{ borderBottom: timeArray.length === index + 1 ? `1px solid ${COLORS.border}` : 0 }}
                            >
                                <TimeText>{item.time}</TimeText>
                            </TimeBoxView>
                        );
                    })}
                </Box>
                {weekData.map((day) => {
                    return (
                        <Box sx={{ minWidth: '180px', width: '13.72%', paddingRight: 0.5, position: 'relative' }} key={day.date}>
                            <DayNameBox>
                                <DayNameText sx={{ color: day.isToday ? COLORS.primary.light : COLORS.black }}>
                                    {moment(day.date).format('ddd')}
                                </DayNameText>
                                <DateNameText sx={{ color: day.isToday ? COLORS.primary.light : COLORS.black }}>
                                    {moment(day.date).format('MMM DD')}
                                </DateNameText>
                            </DayNameBox>
                            <DayTimeColumnMainBox>
                                {timeArray.map((item) => {
                                    return (
                                        <DayTimeBoxView
                                            key={item.id}
                                            sx={Boolean(primaryLoop) && { backgroundColor: COLORS.primary[50] }}
                                        />
                                    );
                                })}
                                {Boolean(primaryLoop) && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            width: '100%',
                                            height:25,
                                            paddingRight: 0.5,
                                            opacity: 0.5,
                                            display:"flex",
                                          
                                        }}
                                    >
                                         <Loopname>{primaryLoop?.name || ''}</Loopname>
                                         {primaryLoop.loop_categories?.map((playlistData) => {
                                                    return (
                                                        <Box
                                                            key={playlistData?.unique_id}
                                                            sx={{ backgroundColor: playlistData.playlist?.color, flex: 1 }}
                                                        ></Box>
                                                    );
                                                })}
                                    </Box>
                                )}
                                {day?.schedulesWithPossition?.map((scheduleItem) => {
                                    let playlists = scheduleItem?.schedule?.loop_categories.map((item) => {
                                        return { ...item.playlist, unique_id: item.unique_id };
                                    });
                                    playlists = shortColorFromHighToLow(playlists);

                                    return (
                                        <EventBoxInTime
                                            key={scheduleItem?.schedule?.id}
                                            onClick={() => {
                                                if (scheduleItem?.schedule?.loop_type === LOOP_CREATE_TYPE.CUSTOM) {
                                                    navigate(PAGES.CLIENT_LOOP_CUSTOM_EDIT.url + `/${scheduleItem?.schedule?.id}`, {
                                                        state: { location_id: selectedLocation }
                                                    });
                                                } else {
                                                    navigate(
                                                        PAGES.CLIENT_LOOP_TEMPLATE_EDIT.url + `/${scheduleItem?.schedule?.template_id}`,
                                                        {
                                                            state: {
                                                                location_id: selectedLocation,
                                                                template_id: scheduleItem?.schedule?.id
                                                            }
                                                        }
                                                    );
                                                }
                                            }}
                                            style={{
                                                position: 'absolute',
                                                top: scheduleItem?.topPosition,
                                                height: scheduleItem?.renderHeight,
                                                padding: scheduleItem?.renderHeight ? 4 : 0
                                            }}
                                        >
                                            <>
                                                <Loopname>{scheduleItem?.schedule?.name}</Loopname>
                                                {playlists?.map((playlistData) => {
                                                    return (
                                                        <Box
                                                            key={playlistData?.unique_id}
                                                            sx={{ backgroundColor: playlistData?.color, flex: 1 }}
                                                        ></Box>
                                                    );
                                                })}
                                            </>
                                        </EventBoxInTime>
                                    );
                                })}
                            </DayTimeColumnMainBox>
                        </Box>
                    );
                })}
            </Box>
        </MainBox>
    );
};

export default WeeklyCalendar;
