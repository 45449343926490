import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchCollection = createAsyncThunk('users/fetchCollection', async () => {
    const response = await api.mediaContent.get({
        params: {
            collection: 1
        }
    });
    return response.data.result;
});

const initialState = {
    collections: []
};

export const collectionSlice = createSlice({
    name: 'collection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCollection.fulfilled, (state, action) => {
            state.collections = action.payload;
        });
    }
});

export default collectionSlice.reducer;
