import { Box, Button, styled, Typography } from '@mui/material';
import React from 'react';
import { COLORS } from '../../../../utils/theme';
import CommonModal from '../../../../components/CommonModal';
import CommonImage from '../../../../components/CommonImage';
import { ORIENTATION, ORIENTATION_DATA_KEY } from '../../../../utils/constants';
import { IMAGES } from '../../../../assets';
import { checkContentStatusInLibrary } from '../../../../utils/helpers';
import { useSelector } from 'react-redux';
import { ShareButton } from '../../../../widgets/ShareButton';
import { ViewerPermission } from '../../../../components/ViewerPermission';

const PageTitle = styled(Typography)({
    fontSize: '1.5rem',
    color: COLORS.black
});

const FieldLabel = styled(Typography)({
    fontSize: 16,
    fontWeight: 'bold',
    color: COLORS.black,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 8
});
const ButtonStyle = styled(Box)({
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between'
});
const VideoAddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});

const MainContainer = styled(Box)({
    padding: '14px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});
const MetricesHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));

export default function CreatorContentPopUp({ data, open, handleClose, orientation, addSingleToLibrary, removeSingleToLibrary, isHide }) {
    const { librarys } = useSelector((state) => state.library);

    return (
        <CommonModal open={open} onClose={handleClose} width={800}>
            <MainContainer>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row' },
                        width: '100%'
                    }}
                >
                    <PageTitle sx={{ fontWeight: 'bold' }}>{data?.name}</PageTitle>
                    <PageTitle>Duration : {data?.duration}</PageTitle>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 3 }}>
                    <CommonImage
                        style={{
                            width: '80%',
                            alignSelf: 'center'
                        }}
                        alt={'thumbnail'}
                        src={
                            data?.[ORIENTATION_DATA_KEY?.video?.[ORIENTATION.LANDSCAPE]] ??
                            data?.[ORIENTATION_DATA_KEY?.video?.[ORIENTATION.PORTRAIT]]
                        }
                    />
                </Box>
                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', width: '70%', mb: 1 }}>
                    <ViewerPermission>
                        <ButtonStyle sx={{ justifyContent: addSingleToLibrary || removeSingleToLibrary ? 'space-between' : 'center' }}>
                            {(addSingleToLibrary || removeSingleToLibrary) &&
                                (checkContentStatusInLibrary(data, librarys) ? (
                                    <Button onClick={() => removeSingleToLibrary(data)}>
                                        <VideoAddImage src={IMAGES.RemoveCircleIcon} />
                                        <FieldLabel>REMOVE</FieldLabel>
                                    </Button>
                                ) : (
                                    <Button onClick={() => addSingleToLibrary(data)}>
                                        <VideoAddImage src={IMAGES.AddCircleIcon} />
                                        <FieldLabel>LINEUP</FieldLabel>
                                    </Button>
                                ))}

                            {data?.landscape_share_url ? <ShareButton {...{isHide}}
                                shareUrl={
                                    data?.[ORIENTATION_DATA_KEY?.share_url?.[orientation]] ||
                                    data?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                                }
                            /> : null}
                        </ButtonStyle>
                    </ViewerPermission>
                </Box>
                {/* <Box>
                    <Typography sx={{ textAlign: 'left' }}>Metrices</Typography>
                    <Box
                        style={{
                            backgroundColor: COLORS.darkGray,
                            display: 'flow-root',
                            justifyContent: 'center',
                            padding: '15px'
                        }}
                    >
                        <MetricesHeader>
                            <Typography>Added to Lineup FEB 23, 2022</Typography>
                            <Typography>00 Total plays</Typography>
                        </MetricesHeader>

                        <Box
                            sx={{
                                backgroundColor: COLORS.white,
                                padding: '24px',
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            Possible area of playback frequency graph metrics: number of plays in last 60 days
                        </Box>
                    </Box>
                </Box> */}
            </MainContainer>
        </CommonModal>
    );
}
