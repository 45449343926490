import { Box, Popover, styled, Button } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { ColorPicker, useColor } from 'react-color-palette';
import 'react-color-palette/lib/css/styles.css';
import CustomTooltip from '../../widgets/CustomTooltip';
import { debounced, hexToHSV, hexToRGB, rgbToHex } from '../../utils/helpers';
import { stopPropagation } from '../../redux/reducers/editorSlice';
import { useDispatch } from 'react-redux';
import colornames from 'colornames'
import { useCallback } from 'react';

const ActionIconButton = styled(Button)({
    width: 58,
    height: 58,
    '&: hover': {
        backgroundColor: 'transparent'
    }
});

const ColorPickerButton = styled(Button)({
    padding: 0,
    margin: 0,
    '&: hover': {
        backgroundColor: 'transparent'
    }
});

const PickerButton = styled(Box)({
    width: 32,
    height: 32,
    border: '3px solid black',
    borderRadius: 4
});

export const CustomColorPicker = ({ anchorOrigin, withTooltip, handleColorChange, currentcolor, component, isPrevent, ...props }) => {
    const [anchorColorPopup, setAnchorColorePopupr] = useState();

    const handleColorCode = useCallback((value) => {
        if (!value) return '#000000'
        if (String(value)?.includes('#')) return value
        if (String(value).includes('rgb')) {
            const rgb = value?.match(/[\d\.]+/g)
            return rgbToHex(rgb?.[0], rgb?.[1], rgb?.[2])
        } else return colornames(value)
    }, [])

    const [color, setColor] = useColor('hex', handleColorCode(currentcolor));
    const dispatch = useDispatch();

    const openImagePicker = (e) => {
        setAnchorColorePopupr(e.currentTarget);
    };

    useEffect(() => {
        currentcolor && setColor({
            hex: handleColorCode(currentcolor),
            rgb: hexToRGB(handleColorCode(currentcolor)),
            hsv: hexToHSV(handleColorCode(currentcolor))
        });
        // eslint-disable-next-line
    }, [currentcolor, handleColorCode]);

    useEffect(() => {
        dispatch(stopPropagation(Boolean(anchorColorPopup)));
    }, [anchorColorPopup, dispatch]);

    return (
        <Box>
            {component ? (
                withTooltip ? (
                    <CustomTooltip title="Change color" placement="bottom" arrow>
                        <ColorPickerButton
                            disableRipple
                            {...props}
                            onClick={(e) => {
                                openImagePicker(e);
                                isPrevent && e.stopPropagation();
                            }}
                        >
                            {component}
                        </ColorPickerButton>
                    </CustomTooltip>
                ) : (
                    <ColorPickerButton
                        disableRipple
                        {...props}
                        onClick={(e) => {
                            openImagePicker(e);
                            isPrevent && e.stopPropagation();
                        }}
                    >
                        {component}
                    </ColorPickerButton>
                )
            ) : (
                <ActionIconButton
                    disableRipple
                    {...props}
                    onClick={(e) => {
                        openImagePicker(e);
                        isPrevent && e.stopPropagation();
                    }}
                >
                    <PickerButton style={{ backgroundColor: color.hex }}></PickerButton>
                </ActionIconButton>
            )}

            <Popover
                id={Boolean(anchorColorPopup) ? 'color-popup' : null}
                open={Boolean(anchorColorPopup)}
                anchorEl={anchorColorPopup}
                onClose={() => setAnchorColorePopupr(null)}
                anchorOrigin={
                    anchorOrigin || {
                        vertical: 'bottom',
                        horizontal: 'center'
                    }
                }
            >
                <ColorPicker
                    width={456}
                    height={228}
                    color={color}
                    onChange={(e) => {
                        setColor(e);
                        debounced(() => {
                            handleColorChange(e);
                        }, 100);
                    }}
                    hideHSV
                    dark
                />
            </Popover>
        </Box>
    );
};
