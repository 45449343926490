import { Grid, styled } from '@mui/material';
import { Box } from '@mui/system';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useFetch from '../../../hooks/useFetch';
import { CollectionCardWidgets } from '../../../widgets/CollectionCardWidgets';
import NoDataFound from '../../../components/NoDataFound';
import RequestHeader from './components/RequestHeader';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../../utils/urls';

const CenteredNoDataFound = styled(Box)({
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center'
});

export const Request = () => {
    const [activeTab, setActiveTab] = useState();
    const { user } = useSelector((state) => state.auth);
    const { activeOrientation } = useSelector((state) => state.orientation);
    const navigate = useNavigate();
    const {
        data: formData,
        callFetch: fetchAllRequestForms,
        isLoading: formsLoading
    } = useFetch({
        initialUrl: `/get_all_forms`,
        skipOnStart: true
    });
    const requestForms = formData?.result || [];
    const { data: requestData, isLoading: categoryLoading } = useFetch({
        initialUrl: `/get_all_request_category`,
        config: {
            params: {
                status: 1
            }
        }
    });
    const requestCategories = useMemo(() => {
        return requestData?.result || [];
    }, [requestData?.result]);

    useEffect(() => {
        if (requestCategories) {
            setActiveTab(requestCategories?.[0]);
        }
    }, [requestCategories]);

    useEffect(() => {
        if (activeTab?.id) {
            let params = {
                status: 1,
                request_category_id: activeTab.id
            };

            fetchAllRequestForms({ params });
        }
    }, [activeTab?.id, fetchAllRequestForms, user?.client]);

    const handleClickContent = (data) => {
        navigate(`${PAGES.REQUEST_SUBMISSION.url}/${data.id}`, { state: { title: data.name } });
    };

    return (
        <Box>
            <RequestHeader
                usingButton
                title={'Request'}
                tabArray={requestCategories}
                loading={categoryLoading}
                activeTab={activeTab}
                handleChangeActiveTab={(item) => setActiveTab(item)}
            />
            <Box sx={{ marginTop: '24px' }} />
            <Grid container spacing={3}>
                {formsLoading ? (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <CollectionCardWidgets
                            orientation={activeOrientation}
                            hideToggleSwitch={true}
                            hasStatus={true}
                            loading={formsLoading}
                        />
                    </Grid>
                ) : (
                    requestForms &&
                    requestForms?.map?.((item, index) => {
                        return (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                                <CollectionCardWidgets
                                    orientation={activeOrientation}
                                    hideToggleSwitch={true}
                                    hasStatus={false}
                                    onClick={handleClickContent}
                                    data={item}
                                    isRequest={true}
                                />
                            </Grid>
                        );
                    })
                )}
            </Grid>
            {!formsLoading && !requestForms?.length && (
                <CenteredNoDataFound>
                    <NoDataFound message={requestForms?.message} />
                </CenteredNoDataFound>
            )}
        </Box>
    );
};
