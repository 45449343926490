import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { getFacebookAccounts, getGoogleAccounts, getGoogleLocations } from '../../api/external';
import { SOCIAL_MEDIA } from '../../utils/constants';
///remove this code
export const fetchGoogleInfo = createAsyncThunk('socialMedia/fetchGoogleInfo', async () => {
    const response = await api.social.getSocialDetails({ params: { type: SOCIAL_MEDIA.GOOGLE } });
    return response.data.result;
});

export const fetchFacebookInfo = createAsyncThunk('socialMedia/fetchFacebookInfo', async () => {
    const response = await api.social.getSocialDetails({ params: { type: SOCIAL_MEDIA.FACEBOOK } });
    return response.data.result;
});
export const fetchInstagramInfo = createAsyncThunk('socialMedia/fetchInstagramInfo', async () => {
    const response = await api.social.getSocialDetails({ params: { type: SOCIAL_MEDIA.INSTAGRAM } });
    return response.data.result;
});
///
export const fetchSocialDetails = createAsyncThunk('socialMedia/fetchInstagramInfo', async (params) => {
    const response = await api.social.getSocialDetails({ params });
    return response.data.result;
});
export const fetchDeleteGoogleInfo = createAsyncThunk('socialMedia/fetchDeleteGoogleInfo', async (id) => {
    const response = await api.social.deleteSocialAccount(id);
    return response.data.result;
});

export const fetchDeleteFacebookInfo = createAsyncThunk('socialMedia/fetchDeleteFacebookInfo', async (id) => {
    const response = await api.social.deleteSocialAccount(id);
    return response.data.result;
});

export const fetchGoogleAccounts = createAsyncThunk('socialMedia/fetchGoogleAccounts', async (params) => {
    const { access_token } = params;
    const response = await getGoogleAccounts({ access_token });
    return response.accounts;
});

export const fetchGoogleLocations = createAsyncThunk('socialMedia/fetchGoogleLocations', async (params) => {
    const { access_token, account_id } = params;
    const response = await getGoogleLocations({ access_token, account_id });
    return response.locations;
});

export const fetchFacebookAccounts = createAsyncThunk('socialMedia/fetchFacebookAccounts', async (params) => {
    const { access_token, user_id } = params;
    const response = await getFacebookAccounts({ access_token, user_id });
    return response.data;
});
export const fetchAllSocialDetails = createAsyncThunk('socialMedia/fetchAllSocialDetails', async (params) => {
    const response = await api.mediaContent.get({ params: { social: 1 } });
    return response.data.result;
});

const initialState = {
    googleInfo: null,
    googleAccounts: [],
    googleLocations: [],
    facebookInfo: null,
    facebookAccounts: [],
    socialDetails: [],
    instagramInfo: null,
    socialInfo: null
};

export const socialMediaSlice = createSlice({
    name: 'socialMedia',
    initialState,
    reducers: {
        setGoogleTokenInfo: (state, action) => {
            state.googleInfo = action.payload;
        }
    },
    extraReducers: (builder) => {
        // builder.addCase(fetchGoogleInfo.fulfilled, (state, action) => {
        //     state.googleInfo = action.payload;
        // });
        builder.addCase(fetchDeleteGoogleInfo.fulfilled, (state, action) => {
            state.googleInfo = null;
        });
        builder.addCase(fetchDeleteFacebookInfo.fulfilled, (state, action) => {
            state.facebookInfo = null;
        });
        builder.addCase(fetchGoogleAccounts.fulfilled, (state, action) => {
            state.googleAccounts = action.payload;
        });
        builder.addCase(fetchGoogleLocations.fulfilled, (state, action) => {
            state.googleLocations = action.payload;
        });
        builder.addCase(fetchFacebookAccounts.fulfilled, (state, action) => {
            state.facebookAccounts = action.payload;
        });
        // builder.addCase(fetchFacebookInfo.fulfilled, (state, action) => {
        //     state.facebookInfo = action.payload;
        // });
        builder.addCase(fetchAllSocialDetails.fulfilled, (state, action) => {
            state.socialDetails = action.payload;
        });
        // builder.addCase(fetchInstagramInfo.fulfilled, (state, action) => {
        //     state.instagramInfo = action.payload;
        // });
        builder.addCase(fetchSocialDetails.fulfilled, (state, action) => {
            state.socialInfo = action.payload;
        });
        builder.addCase(fetchSocialDetails.rejected, (state, action) => {
            state.socialInfo = null;
        });
    }
});

export const { setGoogleTokenInfo } = socialMediaSlice.actions;
export default socialMediaSlice.reducer;
