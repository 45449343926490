import React, { useCallback, useEffect, useState } from 'react';
import { Box, IconButton, styled, Typography } from '@mui/material';
import muxjs from 'mux.js';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { IMAGES } from '../assets';
import CommonButton from '../components/CommonButton';
import VideoPlayer from '../components/VideoPlayer';
import { ViewerPermission } from '../components/ViewerPermission';
import { ORIENTATION } from '../utils/constants';
import { COLORS } from '../utils/theme';
import { checkResolution, readFileAsArrayBuffer } from '../utils/helpers';
// import CommonProgressBar from '../components/CommonProgressBar';

const MainContainer = styled(Box)({
    display: 'flex',
    backgroundColor: COLORS.white,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    alignSelf: 'center',
    padding: 40,
    cursor: 'pointer',
    borderRadius: '.425rem',
    border: `1px solid ${COLORS.border}`
});
const Image = styled('img')({
    height: 50,
    width: 40
});
const FlexBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    margin: '8px 0',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
    }
}));
const Container = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
});
const HeadText = styled(Typography)({
    fontSize: 26,
    color: COLORS.black
});
const ImageContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 20,
    flexWrap: 'wrap'
});
const BorderedBox = styled(Box)({
    border: `1px dashed ${COLORS.black}`,
    margin: 10,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});
const FileImage = styled('img')({
    height: 100,
    objectFit: 'contain'
});
const DeleteImage = styled('img')({
    height: 25,
    width: 25
});
const DeleteButton = styled(IconButton)({
    padding: 0,
    position: 'absolute',
    right: 0,
    top: 0
});

function isFileImage(file) {
    return file && file['type'].split('/')[0] === 'image';
}

function isFileVideo(file) {
    return file && file['type'].split('/')[0] === 'video';
}

export function DropZoneBox(props) {
    const {
        accept,
        requirementText,
        validations = [],
        files,
        loading,
        uploadButton,
        onUpload,
        maxFiles,
        reset,
        title,
        description
    } = props;
    const [myAccepted, setMyAccepted] = useState([]);
    const [myRejected, setRejected] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (myAccepted.length !== 0) {
            files(myAccepted);
        }
    }, [files, myAccepted]);

    useEffect(() => {
        if (reset) {
            setMyAccepted([]);
            reset();
        }
    }, [reset]);

    const getFileInfo = (file) => {
        return new Promise((resolve, reject) => {
            if (isFileImage(file)) {
                const image = new window.Image();
                let url;
                image.onload = function () {
                    file.width = image.width;
                    file.height = image.height;
                    resolve(file);
                };
                url = URL.createObjectURL(file);
                image.src = url;
            } else if (isFileVideo(file)) {
                const video = document.createElement('video');
                video.onload = function () {
                    file.width = video.width;
                    file.height = video.height;
                    resolve(file);
                };
                video.addEventListener('loadedmetadata', async (event) => {
                    const validateVideoCodec = async (file) => {
                        if (file) {
                            const arrayBuffer = await readFileAsArrayBuffer(file);
                            try {
                                const uint8Array = new Uint8Array(arrayBuffer);

                                const mp4Box = muxjs.mp4.probe.tracks(uint8Array);
                                const videoTrack = mp4Box.find((track) => track.type === 'video');
                                return videoTrack.codec;
                            } catch (error) {
                                console.error('Error decoding video:', error);
                            }
                        }
                    };

                    file.width = video.videoWidth;
                    file.height = video.videoHeight;
                    file.duration = video.duration;
                    file.codec = await validateVideoCodec(file);
                    resolve(file);
                });
                video.src = URL.createObjectURL(file);
            } else {
                resolve(file);
            }
        });
    };

    const resolutionValidator = (validation, fileInfo) => {
        let isValid = true;
        let fileWidth = fileInfo.width;
        let fileHeight = fileInfo.height;

        if (props.currentOrientation) {
            if (fileHeight > fileWidth) {
                props.currentOrientation(ORIENTATION.PORTRAIT);
            } else {
                props.currentOrientation(ORIENTATION.LANDSCAPE);
            }
        }
        if (fileHeight > fileWidth && props.landscapeAndPortariatInOne) {
            fileWidth = fileInfo.height;
            fileHeight = fileInfo.width;
        }

        if (validation.width !== fileWidth || validation.height !== fileHeight) {
            enqueueSnackbar(validation.error, { variant: 'error' });
            isValid = false;
        }
        return isValid;
    };

    const sizeValidator = (validation, fileInfo) => {
        let isValid = true;
        if (fileInfo.size > validation.value) {
            enqueueSnackbar(validation.error, { variant: 'error' });
            isValid = false;
        }
        return isValid;
    };

    const formateValidator = (validation, fileInfo) => {
        let isValid = true;
        if (!validation.value.includes(fileInfo['type'].split('/')[1])) {
            enqueueSnackbar(validation.error, { variant: 'error' });
            isValid = false;
        }

        return isValid;
    };

    const durationValidator = (validation, fileInfo) => {
        let isValid = true;
        if (fileInfo.duration > validation.value) {
            enqueueSnackbar(validation.error, { variant: 'error' });
            isValid = false;
        }

        return isValid;
    };

    const codecValidation = (validation, fileInfo) => {
        if (fileInfo.height === 0 && fileInfo.width === 0) {
            if (fileInfo.codec === 'hvc1') {
                return true;
            }
        }
        let isValid = true;
        const isCodecSupported = validation.value?.some((codec) => fileInfo.codec?.includes(codec));
        if (!isCodecSupported) {
            enqueueSnackbar(validation.error, { variant: 'error' });
            isValid = false;
        }
        return isValid;
    };

    const checkFileValidation = useCallback(async (files, rejectedFiles) => {
        if (files.length) {
            for (let i = 0; i < files.length; i++) {
                const fileInfo = await getFileInfo(files[i]);
                let isValid = true;
                for (let j = 0; j < validations.length; j++) {
                    if (validations[j].type === 'resolution') {
                        if (!resolutionValidator(validations[j], fileInfo)) {
                            isValid = false;
                        }
                    } else if (validations[j].type === 'format') {
                        if (!formateValidator(validations[j], fileInfo)) {
                            isValid = false;
                        }
                    } else if (validations[j].type === checkResolution({ width: fileInfo.width, height: fileInfo.height })) {
                        if (!codecValidation(validations[j], fileInfo)) {
                            isValid = false;
                        }
                    } else if (validations[j].type === 'duration') {
                        if (!durationValidator(validations[j], fileInfo)) {
                            isValid = false;
                        }
                    } else if (validations[j].type === 'size') {
                        if (!sizeValidator(validations[j], fileInfo)) {
                            isValid = false;
                        }
                    } else {
                        isValid = true;
                    }
                }
                if (isValid) {
                    setMyAccepted((prevAccepted) => [...prevAccepted, files[i]]);
                } else {
                    setRejected((prevRejected) => [...prevRejected, files[i]]);
                }
            }
        } else {
            enqueueSnackbar(rejectedFiles[0].errors[0].message, { variant: 'error' });
        }
    }, []);

    const renderFileComponent = (file) => {
        const src = URL.createObjectURL(file);
        switch (file['type'].split('/')[0]) {
            case 'image':
                return <FileImage src={src} sx={{ height: 100, width: 176 }} />;
            case 'video':
                return (
                    <Box sx={{ height: 100, width: 176 }}>
                        <VideoPlayer videoUrl={src} stopPropagation={true} orientation={ORIENTATION.LANDSCAPE} />
                    </Box>
                );
            default:
                return null;
        }
    };

    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            if (validations && validations.length !== 0) {
                checkFileValidation(acceptedFiles, rejectedFiles);
            } else {
                setMyAccepted(acceptedFiles);
            }
        },
        [checkFileValidation, validations]
    );
    const removeFiles = (file) => {
        const myAcceptedClone = [...myAccepted];
        const index = myAcceptedClone.findIndex((item) => isEqual(item, file));
        if (index > -1) {
            myAcceptedClone.splice(index, 1);
        }
        setMyAccepted(myAcceptedClone);
    };
    const { getRootProps, getInputProps, inputRef } = useDropzone({
        accept,
        onDrop,
        maxFiles
    });
    const accptedFileItems = myAccepted.map((file, key) => {
        return (
            <Box sx={{ position: 'relative' }} key={key}>
                <BorderedBox>
                    {renderFileComponent(file)}
                    <DeleteButton
                        onClick={(e) => {
                            e.stopPropagation();
                            removeFiles(file);
                        }}
                    >
                        <DeleteImage src={IMAGES.RemoveCircleIcon} />
                    </DeleteButton>
                </BorderedBox>
            </Box>
        );
    });
    return (
        <MainContainer {...getRootProps()}>
            <input {...getInputProps()} />
            <>
                {title || description ? (
                    <Container sx={{ mb: 4 }}>
                        <HeadText textAlign="center" sx={{ fontSize: 30, margin: 'auto', fontWeight: 600 }}>
                            {title}
                        </HeadText>
                        <HeadText width="80%" textAlign="center" sx={{ fontSize: 16, margin: '0.25rem auto' }}>
                            {description}
                        </HeadText>
                    </Container>
                ) : null}
                <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: description || title ? 4 : 0 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Image src={IMAGES.UPLOAD_ICON} alt="upload_icon" />
                        <FlexBox>
                            <HeadText sx={{ fontSize: 30, margin: 'auto', fontWeight: 600 }}>Drag &amp; Drop or </HeadText>
                            <HeadText sx={{ color: COLORS.primary.light, ml: 1, fontWeight: 600, fontSize: 30 }}>Browse</HeadText>
                        </FlexBox>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mt: 2 }}>
                        <Typography>{requirementText}</Typography>
                        <ul style={{ marginTop: '4px' }}>
                            {validations &&
                                validations?.filter((val) => val?.label)?.map((item, key) => <li key={item.label}>{item.label}</li>)}
                        </ul>
                        {accptedFileItems.length > 0 ? (
                            <>
                                <Typography>Accepted files</Typography>
                                <ImageContainer>{accptedFileItems}</ImageContainer>
                                {uploadButton ? (
                                    <ViewerPermission>
                                        <CommonButton
                                            sx={{ borderRadius: '0.425rem' }}
                                            loading={loading}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                inputRef.current.value = null;
                                                onUpload();
                                            }}
                                            label="Upload"
                                            variant="outlined"
                                        />
                                        {/* {isShowProgressBar && <CommonProgressBar value={props.progressBar || 0} />} */}
                                    </ViewerPermission>
                                ) : null}
                            </>
                        ) : null}
                    </Box>
                </Box>
            </>
        </MainContainer>
    );
}
