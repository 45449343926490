import React, { useEffect, useMemo, useState } from 'react';
import { styled, Box, IconButton, Grid, Collapse } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { COLORS } from '../../../../../utils/theme';
import { ICONS, IMAGES } from '../../../../../assets';
import useFetch from '../../../../../hooks/useFetch';
import { EditAccessModel } from './EditAccessModel';
import {
    getAccessIdForPlaylistCategory,
    getAccessObjectFromArrayObject,
    getAccessObjectFromArrayObjectIntegrationApp,
    isAddedInterationApp
} from '../../../../../utils/helpers';
import { ACCESS_TYPE, initialAccessList } from '../../../../../utils/constants';

const MainBox = styled(Box)({
    width: '100%'
});

const MainSectionRowView = styled(Grid)({
    border: `1px solid ${COLORS.border}`,
    marginTop: 12,
    borderRadius: '.425rem',
    boxShadow: COLORS.cardShadow,
    backgroundColor: COLORS.white
});
const MainSectionRowItem = styled(Grid)({
    cursor: 'pointer',
    borderTopLeftRadius: '.425rem',
    borderTopRightRadius: '.425rem',
    '& button': {
        display: 'none'
    },
    ':hover': {
        '& button': {
            display: 'flex'
        }
    }
});

const SubItemSectionRowView = styled(Grid)({
    paddingLeft: 12,
    paddingRight: 0,
    cursor: 'pointer',
    '& button': {
        display: 'none'
    },
    ':hover': {
        '& button': {
            display: 'flex'
        }
    }
});
const SectionNameGrid = styled(Grid)({
    display: 'flex',
    padding: 10
});

const StatusActionGrid = styled(Grid)({
    display: 'flex'
});

const SectionNameText = styled(Typography)({
    fontSize: 16
});

const StatusImage = styled('img')({
    height: 22,
    width: 22
});

const Access = ({ clientId }) => {
    const [showEditModel, setShowEditModel] = useState(false);
    const [accessList, setAccessList] = useState(initialAccessList);
    const [editAccessData, setEditAccessData] = useState();
    const [isShownEditIcon, setIsShownEditIcon] = useState(false);

    const { data: accessData, callFetch: fetchClientAccess } = useFetch({
        initialUrl: `/get_client_access`,
        config: {
            params: {
                client_id: clientId
            }
        }
    });

    const clientAccess = accessData?.result.access;
    const { data: categoryData } = useFetch({
        initialUrl: `/get_all_category`,
        config: {
            params: {
                client_id: clientId
            }
        }
    });

    const lineUpItems = useMemo(() => {
        if (categoryData?.result?.length) {
            return categoryData?.result?.map((item) => {
                return {
                    id: getAccessIdForPlaylistCategory(item.id),
                    type: ACCESS_TYPE.ITEM,
                    name: item.name,
                    visibility: 1
                };
            });
        } else {
            return [];
        }
    }, [categoryData?.result]);

    useEffect(() => {
        if (lineUpItems.length) {
            const accessListClone = [...accessList];
            const presentationItemsClone = accessList[0].items;
            const indexOfLibrary = accessList[0].items?.findIndex((item) => item.id === 'library');
            presentationItemsClone[indexOfLibrary].items = lineUpItems;
            accessListClone[0].items = presentationItemsClone;
            setAccessList(accessListClone);
        }
        // eslint-disable-next-line
    }, [lineUpItems]);

    const { data: integrationAppData } = useFetch({
        initialUrl: `/get_client_integrations`
    });

    const integrationApp = useMemo(() => {
        if (integrationAppData?.result?.length) {
            return integrationAppData?.result?.map((item) => {
                return {
                    id: item.name_alias,
                    type: ACCESS_TYPE.ITEM,
                    name: item.name,
                    hasStatus: true,
                    visibility: isAddedInterationApp(item?.name_alias) ? 0 : 1
                };
            });
        } else {
            return [];
        }
    }, [integrationAppData?.result]);

    useEffect(() => {
        if (integrationApp.length) {
            const accessListClone = [...accessList];
            const presentationItemsClone = accessList[1].items;
            const indexOfLibrary = accessList[1].items?.findIndex((item) => item.id === 'apps');
            presentationItemsClone[indexOfLibrary].items = integrationApp;
            accessListClone[1].items = presentationItemsClone;
            setAccessList(accessListClone);
        }
        // eslint-disable-next-line
    }, [integrationApp]);

    const handleCollapseSections = (mainSection, item) => {
        let tempList = [...accessList];
        const mainSectionIndex = tempList.findIndex((e) => e.id === mainSection.id);
        if (!item) {
            tempList[mainSectionIndex] = { ...tempList[mainSectionIndex], isExpand: tempList[mainSectionIndex]?.isExpand ? false : true };
        } else {
            const subSectionIndex = tempList[mainSectionIndex].items.findIndex((e) => e.id === item.id);
            tempList[mainSectionIndex].items[subSectionIndex] = {
                ...tempList[mainSectionIndex].items[subSectionIndex],
                isExpand: tempList[mainSectionIndex].items[subSectionIndex]?.isExpand ? false : true
            };
        }
        setAccessList([...tempList]);
    };

    const ActionBarCommon = ({ item }) => {
        const visibility = clientAccess ? getAccessObjectFromArrayObject(clientAccess, item.id).visibility : item.visibility;
        const status = clientAccess ? getAccessObjectFromArrayObjectIntegrationApp(clientAccess, item.id).status : item.visibility;
        return (
            <>
                <StatusActionGrid item md={2} sm={2} xs={2} lg={1} alignItems={'center'}>
                    {visibility ? <Visibility sx={{ color: 'green' }} /> : <VisibilityOff sx={{ color: 'red' }} />}
                    {item?.hasStatus && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
                                setEditAccessData(item);
                                setShowEditModel(true);
                            }}
                        >
                            {status ? <StatusImage src={IMAGES.CheckMark} /> : <StatusImage src={IMAGES.RemoveCircleIcon} />}
                        </IconButton>
                    )}
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setEditAccessData(item);
                            setShowEditModel(true);
                        }}
                    >
                        <ICONS.Edit />
                    </IconButton>
                </StatusActionGrid>
            </>
        );
    };

    return (
        <MainBox sx={{ py: 2 }}>
            <Grid container justifyContent={'space-between'}>
                <SectionNameGrid item md={10} lg={11} sm={10} xs={7}>
                    <SectionNameText>NAME</SectionNameText>
                </SectionNameGrid>
                <SectionNameGrid item md={2} lg={1} sm={2} xs={3}>
                    <SectionNameText>STATUS</SectionNameText>
                </SectionNameGrid>
            </Grid>
            {accessList.map((section, mainIndex) => {
                return (
                    <MainSectionRowView key={section.id} container>
                        <MainSectionRowItem
                            // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: section.id })}
                            // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: section.id })}
                            sx={{
                                backgroundColor:
                                    isShownEditIcon.visibility && isShownEditIcon.id === section.id
                                        ? COLORS.border
                                        : section.isExpand && COLORS.primary.light
                            }}
                            onClick={() => {
                                handleCollapseSections(section);
                            }}
                            container
                        >
                            <SectionNameGrid item md={10} lg={11} sm={10} xs={10}>
                                {section.isExpand ? (
                                    <ICONS.ExpandLess sx={{ color: COLORS.white }} />
                                ) : (
                                    <ICONS.ExpandMore sx={{ color: COLORS.black }} />
                                )}
                                <SectionNameText sx={section.isExpand && { color: COLORS.white }}>{section.name}</SectionNameText>
                            </SectionNameGrid>
                            <ActionBarCommon item={section} />
                        </MainSectionRowItem>
                        <Collapse sx={{ width: '100%' }} in={section.isExpand}>
                            {section.items.map((subSection, subIndex) => {
                                if (subSection.type === ACCESS_TYPE.ITEM) {
                                    return (
                                        <Grid key={subSection.id} item md={12}>
                                            <SubItemSectionRowView
                                                // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: subSection.id })}
                                                // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: subSection.id })}
                                                sx={{
                                                    backgroundColor:
                                                        isShownEditIcon.visibility && isShownEditIcon.id === subSection.id
                                                            ? COLORS.border
                                                            : '',
                                                    borderTop: subIndex === 0 && `1px solid ${COLORS.border}`,
                                                    borderBottom:
                                                        subIndex === section.items.length - 1
                                                            ? `1px solid ${COLORS.white}`
                                                            : `1px solid ${COLORS.border}`
                                                }}
                                                container
                                            >
                                                <SectionNameGrid sx={{ paddingLeft: 4 }} item md={10} lg={11} sm={10} xs={10}>
                                                    <SectionNameText>{subSection.name}</SectionNameText>
                                                </SectionNameGrid>
                                                <ActionBarCommon item={subSection} />
                                            </SubItemSectionRowView>
                                        </Grid>
                                    );
                                }

                                return (
                                    <Grid key={subSection.id} item md={12}>
                                        <SubItemSectionRowView
                                            // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: subSection.id })}
                                            // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: subSection.id })}
                                            sx={{
                                                backgroundColor:
                                                    isShownEditIcon.visibility && isShownEditIcon.id === subSection.id ? COLORS.border : '',
                                                borderBottom:
                                                    subIndex === section.items.length - 1
                                                        ? `1px solid ${COLORS.white}`
                                                        : `1px solid ${COLORS.border}`
                                            }}
                                            onClick={() => {
                                                handleCollapseSections(section, subSection);
                                            }}
                                            container
                                        >
                                            <SectionNameGrid item md={10} lg={11} sm={10} xs={10}>
                                                {subSection.isExpand ? (
                                                    <ICONS.ExpandLess sx={{ color: COLORS.primary.light }} />
                                                ) : (
                                                    <ICONS.ExpandMore sx={{ color: COLORS.primary.light }} />
                                                )}
                                                <SectionNameText sx={{ color: COLORS.primary.light }}>{subSection.name}</SectionNameText>
                                            </SectionNameGrid>
                                            <ActionBarCommon item={subSection} isShownEditIcon={isShownEditIcon} />
                                        </SubItemSectionRowView>
                                        <Collapse sx={{ width: '100%' }} in={subSection.isExpand}>
                                            {subSection.items.map((item, itemIndex) => {
                                                return (
                                                    <Grid key={item.id} item md={12}>
                                                        <SubItemSectionRowView
                                                            container
                                                            // onMouseEnter={() => setIsShownEditIcon({ visibility: true, id: item.id })}
                                                            // onMouseLeave={() => setIsShownEditIcon({ visibility: false, id: item.id })}
                                                            sx={{
                                                                backgroundColor:
                                                                    isShownEditIcon.visibility && isShownEditIcon.id === item.id
                                                                        ? COLORS.border
                                                                        : '',
                                                                borderBottom:
                                                                    itemIndex === subSection.items.length - 1
                                                                        ? `1px solid ${COLORS.white}`
                                                                        : `1px solid ${COLORS.border}`
                                                            }}
                                                        >
                                                            <SectionNameGrid sx={{ paddingLeft: 6 }} item md={10} lg={11} sm={10} xs={9}>
                                                                <SectionNameText>{item?.name}</SectionNameText>
                                                            </SectionNameGrid>
                                                            <ActionBarCommon item={item} />
                                                        </SubItemSectionRowView>
                                                    </Grid>
                                                );
                                            })}
                                        </Collapse>
                                    </Grid>
                                );
                            })}
                        </Collapse>
                    </MainSectionRowView>
                );
            })}
            {showEditModel && (
                <EditAccessModel
                    open={showEditModel}
                    handleClose={() => {
                        setShowEditModel(false);
                    }}
                    access={editAccessData}
                    clientAccess={clientAccess}
                    defaultAccess={accessList}
                    clientId={clientId}
                    fetchClientAccess={fetchClientAccess}
                />
            )}
            {/* <EditAccess
                open={modalAccess}
                type={'edit'}
                handleCloseEvent={() => {
                    setmodalAccess(!modalAccess);
                }}
            /> */}
        </MainBox>
    );
};

export default Access;
