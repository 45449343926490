import { Box, Grid, IconButton, styled } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ICONS } from '../assets';

const MainBox = styled(Box)({
    display: 'flex',
    width: '100%',
    alignItems: 'center'
});
const LoopsContainer = styled(Grid)({
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',
    scrollBehavior: 'smooth',
    '&::-webkit-scrollbar': {
        display: 'none'
    }
});

export const HorizontalScrollWithButton = ({ children }) => {
    const containerRef = useRef(null);
    const [scrollLeftDisabled, setScrollLeftDisabled] = useState(true);
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            checkScrollPosition(container);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleScroll = (event) => {
        const container = event.target;
        checkScrollPosition(container);
    };

    const checkScrollPosition = (container) => {
        const scrollLeftDisabled = container.scrollLeft === 0;

        setScrollLeftDisabled(scrollLeftDisabled);
    };
    const scrollLeft = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: -200,
                behavior: 'smooth'
            });
        }
    };

    const scrollRight = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({
                left: 200,
                behavior: 'smooth'
            });
        }
    };

    return (
        <MainBox>
          {!scrollLeftDisabled &&  <IconButton onClick={scrollLeft}>
                <ICONS.ArrowBack color={'primary'} />
            </IconButton>}
            <LoopsContainer ref={containerRef}>{children}</LoopsContainer>
            <IconButton onClick={scrollRight}>
                <ICONS.ArrowForward color={'primary'} />
            </IconButton>
        </MainBox>
    );
};
