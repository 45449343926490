import { Box, styled, Typography, Grid } from '@mui/material';
import { COLORS } from '../../../../utils/theme';
import { OutlinedTabBar } from '../../../../components/OutlinedTabBar';

const MainBox = styled(Box)({
    padding: '16px 25px',
    width: '100%',
    backgroundColor: COLORS.white,
    alignItems: 'center',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem',
    justifyContent: 'center'
});

const PageTitle = styled(Typography)({
    fontSize: 22,
    color: COLORS.black,
    fontWeight: '300'
});
const VrLine = styled(Box)({
    height: 50,
    backgroundColor: COLORS.dividerColor,
    border: '1px solid #d0d0d0'
});

const LeftContentView = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

const MainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between'
});
export default function RequestHeader({ title, tabArray, activeTab, handleChangeActiveTab, usingButton, loading }) {
    return (
        <MainBox>
            <MainGrid container>
                <LeftContentView item sx={{ marginBottom: { xs: '10px', sm: '10px', md: '0px', lg: '0px' } }}>
                    <PageTitle>{title}</PageTitle>
                    <VrLine sx={{ mx: 2 }} />
                    <Grid container alignItems={'center'}>
                        <OutlinedTabBar
                            hasAll={false}
                            usingButton={usingButton}
                            tabArray={tabArray}
                            loading={loading}
                            activeTab={activeTab}
                            handleChangeActiveTab={handleChangeActiveTab}
                        />
                    </Grid>
                </LeftContentView>
            </MainGrid>
        </MainBox>
    );
}
