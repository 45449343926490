import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { CommonTable } from '../../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../../../utils/theme';
import AddEditAccountTypesModal from './components/AddEditAccountTypesModal';
import { ICONS } from '../../../assets';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { ACCOUNT_TYPES, DELETE_CONFIRMATION } from '../../../utils/constants';
import { api } from '../../../api';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../redux/reducers/accountTypesSlice';

const AccountName = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.primary.light,
    textAlign: 'left'
});

const TrashButton = styled(IconButton)({
    margin: '0px 0px 0px 8px',
    background: COLORS.primary.danger,
    borderRadius: '0.425rem',
    '&:hover': {
        background: COLORS.hover.danger
    }
});

const TrashIcon = styled(ICONS.Trash)({
    color: COLORS.white,
    width: 16
});

export const AccountTypesDetails = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [modal, setModal] = useState({ isOpen: false, type: '', data: {} });
    const [searchValue, setSearchValue] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [accountTypesData, setAccountTypesData] = useState({});
    const { territory, Specialty, isPending } = useSelector(({ accountTypes }) => accountTypes);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { typeId } = useParams();

    const AccountType = ACCOUNT_TYPES?.find((type) => type?.id === typeId);

    const initialAPICallingHanlder = () => {
        if (AccountType?.id && AccountType?.id === ACCOUNT_TYPES[0]?.id) {
            dispatch(fetchAccountTypeTerritory());
        } else if (AccountType?.id && AccountType?.id === ACCOUNT_TYPES[1]?.id) {
            dispatch(fetchAccountTypeSpecialty());
        }
    };

    const NameElement = ({ item }) => {
        return <AccountName>{item?.name}</AccountName>;
    };

    const ActionsContainer = ({ item }) => {
        return (
            <TrashButton
                onClick={() => {
                    setAccountTypesData(item);
                    setIsOpen(true);
                }}
            >
                <TrashIcon />
            </TrashButton>
        );
    };

    const accountTypesColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: (row) => {
                return <NameElement item={row?.rowData} />;
            }
        },
        {
            id: 'assigned',
            fieldName: 'assigned',
            label: 'Assigned',
            style: {
                justifyContent: 'flex-end'
            },
            render: (row) => {
                return <span> {row.rowData?.assigned ? row.rowData?.assigned : 0}</span>;
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'id',
            fieldName: 'id',
            label: 'Actions',
            align: 'center',
            render: (row) => {
                return <ActionsContainer item={row.rowData} />;
            },
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];

    const handleDeleteAccountTypes = async () => {
        try {
            let result;
            if (AccountType?.id === ACCOUNT_TYPES?.[0]?.id) {
                const { data } = await api.accountTypes.deleteAccountTypeTerritory(accountTypesData?.id);
                result = data;
            } else if (AccountType?.id === ACCOUNT_TYPES?.[1]?.id) {
                const { data } = await api.accountTypes.deleteAccountTypeSpecialty(accountTypesData?.id);
                result = data;
            }
            if (result?.success) {
                enqueueSnackbar(result?.message, { variant: 'success' });
                initialAPICallingHanlder();
            } else {
                enqueueSnackbar(result?.message, { variant: 'error' });
            }
            setIsOpen(false);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    useEffect(() => {
        initialAPICallingHanlder();
    }, [dispatch, AccountType]);

    return (
        <Box>
            <CommonHeader
                title={AccountType?.name}
                backTo={'Account Types'}
                breadcrumbText={AccountType?.id === ACCOUNT_TYPES[0]?.id ? ACCOUNT_TYPES[0]?.name : ACCOUNT_TYPES[1]?.name}
                buttonLabel={`Add ${AccountType?.name}`}
                onClick={() => setModal({ isOpen: true, type: 'add' })}
                searchbar={true}
                searchValue={searchValue}
                handleSearch={(search) => setSearchValue(search)}
                onClose={() => navigate(-1)}
                loading={isPending}
                showCloseButton={false}
            />
            <Grid sx={{ marginTop: '24px' }} container>
                <CommonTable
                    search={searchValue}
                    loading={isPending}
                    columns={accountTypesColumns}
                    rows={AccountType?.id === ACCOUNT_TYPES[0]?.id ? territory : Specialty}
                    searchableFields={['name']}
                />
            </Grid>
            {modal?.isOpen && (
                <AddEditAccountTypesModal
                    open={modal.isOpen}
                    type={modal.type}
                    accountTypesData={modal.data}
                    handleClose={() => setModal({ isOpen: false, type: '', data: {} })}
                    accountTypes={AccountType}
                />
            )}
            {isOpen && (
                <AlertDialog
                    isOpen={isOpen}
                    isClose={() => {
                        setIsOpen(false);
                    }}
                    handleSubmit={handleDeleteAccountTypes}
                    title={DELETE_CONFIRMATION.title}
                    description={DELETE_CONFIRMATION.description + AccountType?.name + '?'}
                    isCancel={'No'}
                />
            )}
        </Box>
    );
};
