import { FormHelperText, Grid, Box, Typography, styled } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import { COLORS } from '../../../../utils/theme';
import { CommonStyle } from '../../../../utils/commonStyle';
import ImagePlaceholder from '../../../../widgets/ImagePlaceholder';
import { useFormik } from 'formik';
import { IOSSwitch } from '../../../../widgets/IOSSwitch';
import { videoFormValidation } from '../../../../utils/validations';
import useFetch from '../../../../hooks/useFetch';
import { useSnackbar } from 'notistack';
import { api } from '../../../../api';
import { getFileResolution, sortCategoryList, splitTrimStringifyTags } from '../../../../utils/helpers';
import FieldLabelView from '../../../../components/FieldLabelView';
import CommonModal from '../../../../components/CommonModal';
import { CONTENT_DATA_TYPE, CONTENT_MODULE_TYPE } from '../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllMediaServers } from '../../../../redux/reducers/videoSlice';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../../redux/reducers/accountTypesSlice';

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
});
const FieldLabel = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 4,
    fontWeight: '600'
});

const StyledInput = styled(CommonInput)({
    flex: 1
});
const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginBottom: 10
});

const AddVideoModal = ({ open, handleClose, paginate, setPaginate }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const mediaDirectories = useSelector(({ video }) => video?.mediaDirectories);
    const { territory, Specialty } = useSelector(({ accountTypes }) => accountTypes);

    const { data } = useFetch({
        initialUrl: '/get_all_category'
    });
    const categoryList = data?.result && sortCategoryList(data?.result);

    const directories = useMemo(() => {
        return mediaDirectories?.map(({ id, mediaUrl }) => ({ id, name: mediaUrl, value: id }));
    }, [mediaDirectories]);

    useEffect(() => {
        dispatch(fetchAllMediaServers());
        dispatch(fetchAccountTypeTerritory());
        dispatch(fetchAccountTypeSpecialty());
    }, [dispatch]);

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, resetForm, setFieldValue, setTouched } = useFormik({
        initialValues: {
            name: '',
            category: '',
            directory: '',
            directory_hd: '',
            directoryName: '',
            directoryName_hd: '',
            landscapeURL: '',
            client_status: true,
            description: '',
            tags: '',
            portrait_thumbnail: '',
            landscape_thumbnail: '',
            portrait_video: '',
            portrait_video_hd: '',
            landscape_video: '',
            landscape_video_hd: '',
            portrait_thumbnail_file: '',
            landscape_thumbnail_file: '',
            portrait_video_file: '',
            portrait_video_file_hd: '',
            landscape_video_file: '',
            landscape_video_file_hd: '',
            landscape_video_resolution: '',
            landscape_video_resolution_hd: '',
            portrait_video_resolution: '',
            portrait_video_resolution_hd: '',
            territory_status: false,
            territory_id: [],
            speciality_status: false,
            speciality_id: []
        },
        validationSchema: videoFormValidation,
        onSubmit: async (values) => {
            let tagsArray = splitTrimStringifyTags(values.tags);
            setLoading(true);
            let result;
            try {
                const formData = new FormData();
                formData.append('media_server', values.directory);
                formData.append('media_server_hd', values.directory_hd);
                formData.append('sub_folder', values.directoryName ? values.directoryName : '');
                formData.append('sub_folder_hd', values.directoryName_hd ? values.directoryName_hd : '');
                formData.append('name', values.name);
                formData.append('module_type', CONTENT_MODULE_TYPE.VIDEO);
                formData.append('content_type', CONTENT_DATA_TYPE.VIDEO);
                formData.append('status', +values.client_status);
                formData.append('description', values.description);
                if (values.landscape_video_resolution && values.portrait_video_resolution) {
                    formData.append('resolution', `${values?.landscape_video_resolution}, ${values?.portrait_video_resolution}`);
                }
                if (values?.landscape_video_resolution_hd && values?.portrait_video_resolution_hd) {
                    formData.append('resolution_hd', `${values?.landscape_video_resolution_hd}, ${values?.portrait_video_resolution_hd}`);
                }
                if (values.landscapeURL) {
                    formData.append('landscape_share_url', values.landscapeURL);
                }
                if (values.landscape_thumbnail_file) {
                    formData.append('landscape_thumbnail', values.landscape_thumbnail_file);
                }
                if (values.portrait_thumbnail_file) {
                    formData.append('portrait_thumbnail', values.portrait_thumbnail_file);
                }
                if (values.landscape_video_file) {
                    formData.append('landscape_video', values.landscape_video_file);
                }
                if (values.landscape_video_file_hd) {
                    formData.append('landscape_video_hd', values.landscape_video_file_hd);
                }
                if (values.portrait_video_file) {
                    formData.append('portrait_video', values.portrait_video_file);
                }
                if (values.portrait_video_file_hd) {
                    formData.append('portrait_video_hd', values.portrait_video_file_hd);
                }
                formData.append('tags', tagsArray);
                formData.append('category_id', values.category);

                if (values.territory_status) {
                    formData.append('territory_id', JSON.stringify(values.territory_id));
                }
                if (values.speciality_status) {
                    formData.append('speciality_id', JSON.stringify(values.speciality_id));
                }

                const { data } = await api.mediaContent.add(formData);
                result = data;
                if (result.success) {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'success' });
                    setPaginate({ ...paginate, page: 1, isCleared: true });
                    handleCloseEvent();
                } else {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    const handleCloseEvent = () => {
        if (handleClose) {
            resetForm();
            setLoading(false);
            handleClose();
        }
    };

    return (
        <CommonModal
            open={open}
            onClose={handleCloseEvent}
            title={'Upload Video'}
            width={900}
            style={{ maxHeight: 750 }}
            headerStyle={{ position: 'sticky', zIndex: 1, top: 0 }}
        >
            <form onSubmit={handleSubmit}>
                <Grid container spacing={4} sx={{ ...CommonStyle.rowSpaceBetween, alignItems: 'start' }}>
                    <Grid item md={7} sm={6} xs={12}>
                        <FieldLabelView label={'Name'}>
                            <StyledInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.name && errors.name}
                                name={'name'}
                                value={values.name}
                            />
                        </FieldLabelView>
                        <FieldLabelView label={'Category'} sx={{ width: '50%', mt: 1 }}>
                            <CommonMultiSelect
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                options={categoryList}
                                error={touched.category && errors.category}
                                name={'category'}
                                value={values.category}
                            />
                        </FieldLabelView>

                        <FieldLabelView label={'Share URL'} sx={{ mt: 1 }}>
                            <StyledInput
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.landscapeURL && errors.landscapeURL}
                                name={'landscapeURL'}
                                value={values.landscapeURL}
                            />
                        </FieldLabelView>

                        <FieldView sx={{ marginBottom: '0px !important', marginTop: 2 }}>
                            <FieldLabel>Client Side Status:</FieldLabel>
                            <IOSSwitch
                                sx={{ m: 1 }}
                                checked={values?.client_status}
                                onChange={(e) => {
                                    setFieldValue('client_status', e.target.checked);
                                }}
                            />
                        </FieldView>

                        <FieldView sx={{ marginBottom: '0px !important', marginTop: 2 }}>
                            <FieldLabel>Territory:</FieldLabel>
                            <IOSSwitch
                                sx={{ m: 1 }}
                                checked={values?.territory_status}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        setFieldValue('territory_id', []);
                                        setTouched({ ...touched, ['territory_id']: false });
                                    }
                                    setFieldValue('territory_status', e.target.checked);
                                }}
                            />
                        </FieldView>
                        {values?.territory_status && (
                            <FieldLabelView label={''} sx={{ mt: 1 }}>
                                <CommonMultiSelect
                                    name={'territory_id'}
                                    error={touched.territory_id && errors.territory_id}
                                    value={values.territory_id}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    options={territory?.map((cv) => {
                                        return { id: cv?.id, name: cv?.name, value: cv?.id };
                                    })}
                                    multiple={true}
                                    style={{ height: 'auto' }}
                                    searchableMultiselect={false}
                                    checkbox={true}
                                />
                            </FieldLabelView>
                        )}

                        <FieldView sx={{ marginBottom: '0px !important', marginTop: 2 }}>
                            <FieldLabel>Specialty:</FieldLabel>
                            <IOSSwitch
                                sx={{ m: 1 }}
                                checked={values?.speciality_status}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        setFieldValue('speciality_id', []);
                                        setTouched({ ...touched, ['speciality_id']: false });
                                    }
                                    setFieldValue('speciality_status', e.target.checked);
                                }}
                            />
                        </FieldView>
                        {values?.speciality_status && (
                            <FieldLabelView label={''} sx={{ mt: 1 }}>
                                <CommonMultiSelect
                                    name={'speciality_id'}
                                    error={touched.speciality_id && errors.speciality_id}
                                    value={values.speciality_id}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    options={Specialty?.map((cv) => {
                                        return { id: cv?.id, name: cv?.name, value: cv?.id };
                                    })}
                                    multiple={true}
                                    style={{ height: 'auto' }}
                                    searchableMultiselect={false}
                                    checkbox={true}
                                />
                            </FieldLabelView>
                        )}

                        <FieldLabelView label={'Tags'} sx={{ mt: 1 }}>
                            <StyledInput
                                style={{ height: 'auto' }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.tags && errors.tags}
                                name={'tags'}
                                value={values.tags}
                                multiline={true}
                                minRows={4}
                            />
                            <Typography sx={{ fontSize: 14 }}>Separate tags with a comma.</Typography>
                        </FieldLabelView>
                        <FieldLabelView label={'Description'} sx={{ mt: 1 }}>
                            <StyledInput
                                style={{ height: 'auto' }}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={touched.description && errors.description}
                                name={'description'}
                                value={values.description}
                                multiline={true}
                                minRows={6}
                            />
                        </FieldLabelView>
                    </Grid>
                    <Grid item sm={5} xs={12} md={5}>
                        <FieldLabelView label={'4K Video Files'}>
                            <Grid style={{ width: '100%' }} container spacing={1}>
                                <Grid item sm={9} xs={9} md={9}>
                                    <ImagePlaceholder
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('landscape_video_resolution', `${fileInfo.width}x${fileInfo.height}`);
                                            setFieldValue('landscape_video', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('landscape_video_file', event.target.files[0]);
                                        }}
                                        accept="video/mp4"
                                        boxDimensions={{ height: 132, width: '100%' }}
                                        image={values.landscape_video}
                                        isAddIcon={values.landscape_video ? false : true}
                                    />
                                    {touched.landscape_video && errors.landscape_video && (
                                        <FormHelperText error>{errors.landscape_video}</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item sm={3} xs={3} md={3}>
                                    <ImagePlaceholder
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('portrait_video_resolution', `${fileInfo.width}x${fileInfo.height}`);
                                            setFieldValue('portrait_video', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('portrait_video_file', event.target.files[0]);
                                        }}
                                        accept="video/mp4"
                                        image={values.portrait_video}
                                        isAddIcon={values.portrait_video ? false : true}
                                        boxDimensions={{ height: 132, width: '100%' }}
                                    />
                                    {touched.portrait_video && errors.portrait_video && (
                                        <FormHelperText error>{errors.portrait_video}</FormHelperText>
                                    )}
                                </Grid>
                            </Grid>
                        </FieldLabelView>
                        <FieldLabelView label={'Thumbnails'} sx={{ mt: 1 }}>
                            <Grid style={{ width: '100%' }} container spacing={1}>
                                <Grid item sm={9} xs={9} md={9}>
                                    <ImagePlaceholder
                                        boxDimensions={{ height: 132, width: '100%' }}
                                        handleChange={(event) => {
                                            setFieldValue('landscape_thumbnail', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('landscape_thumbnail_file', event.target.files[0]);
                                        }}
                                        image={values.landscape_thumbnail}
                                        isAddIcon={values.landscape_thumbnail ? false : true}
                                    />
                                    {touched.landscape_thumbnail && errors.landscape_thumbnail && (
                                        <FormHelperText error>{errors.landscape_thumbnail}</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item sm={3} xs={3} md={3}>
                                    <ImagePlaceholder
                                        boxDimensions={{ height: 132, width: '100%' }}
                                        handleChange={(event) => {
                                            setFieldValue('portrait_thumbnail', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('portrait_thumbnail_file', event.target.files[0]);
                                        }}
                                        image={values.portrait_thumbnail}
                                        isAddIcon={values.portrait_thumbnail ? false : true}
                                    />
                                    {touched.portrait_thumbnail && errors.portrait_thumbnail && (
                                        <FormHelperText error>{errors.portrait_thumbnail}</FormHelperText>
                                    )}
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FieldLabelView label={'Directory'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            options={directories || []}
                                            error={touched.directory && errors.directory}
                                            name={'directory'}
                                            value={values.directory}
                                        />
                                    </FieldLabelView>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FieldLabelView sx={{ marginLeft: '8px', width: 'calc(100% - 8px)' }} label={'Sub Folder'}>
                                        <StyledInput
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={touched.directoryName && errors.directoryName}
                                            name={'directoryName'}
                                            value={values.directoryName}
                                        />
                                    </FieldLabelView>
                                </Grid>
                            </Grid>
                        </FieldLabelView>

                        <FieldLabelView label={'HD Video Files'} sx={{ mt: 1 }}>
                            <Grid style={{ width: '100%' }} container spacing={1}>
                                <Grid item sm={9} xs={9} md={9}>
                                    <ImagePlaceholder
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('landscape_video_resolution_hd', `${fileInfo.width}x${fileInfo.height}`);
                                            setFieldValue('landscape_video_hd', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('landscape_video_file_hd', event.target.files[0]);
                                        }}
                                        accept="video/mp4"
                                        boxDimensions={{ height: 132, width: '100%' }}
                                        image={values.landscape_video_hd}
                                        isAddIcon={values.landscape_video_hd ? false : true}
                                    />
                                    {touched.landscape_video_hd && errors.landscape_video_hd && (
                                        <FormHelperText error>{errors.landscape_video_hd}</FormHelperText>
                                    )}
                                </Grid>
                                <Grid item sm={3} xs={3} md={3}>
                                    <ImagePlaceholder
                                        handleChange={async (event) => {
                                            let fileInfo = await getFileResolution(
                                                URL.createObjectURL(event.target.files[0]),
                                                event.target.files[0].name,
                                                'video/mp4'
                                            );
                                            setFieldValue('portrait_video_resolution_hd', `${fileInfo.width}x${fileInfo.height}`);
                                            setFieldValue('portrait_video_hd', URL.createObjectURL(event.target.files[0]));
                                            setFieldValue('portrait_video_file_hd', event.target.files[0]);
                                        }}
                                        accept="video/mp4"
                                        image={values.portrait_video_hd}
                                        isAddIcon={values.portrait_video_hd ? false : true}
                                        boxDimensions={{ height: 132, width: '100%' }}
                                    />
                                    {touched.portrait_video_hd && errors.portrait_video_hd && (
                                        <FormHelperText error>{errors.portrait_video_hd}</FormHelperText>
                                    )}
                                </Grid>

                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FieldLabelView label={'Directory'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            options={directories || []}
                                            error={touched.directory_hd && errors.directory_hd}
                                            name={'directory_hd'}
                                            value={values.directory_hd}
                                        />
                                    </FieldLabelView>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                    <FieldLabelView sx={{ marginLeft: '8px', width: 'calc(100% - 8px)' }} label={'Sub Folder'}>
                                        <StyledInput
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={touched.directoryName_hd && errors.directoryName_hd}
                                            name={'directoryName_hd'}
                                            value={values.directoryName_hd}
                                        />
                                    </FieldLabelView>
                                </Grid>
                            </Grid>
                        </FieldLabelView>
                    </Grid>
                </Grid>

                <ButtonContainer>
                    <CommonButton loading={loading} variant="contained" onClick={handleSubmit} label={'Upload'} type={'submit'} />
                </ButtonContainer>
            </form>
        </CommonModal>
    );
};

export default AddVideoModal;
