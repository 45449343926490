import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isDrawerOpen: true,
    isActivePath: null
};

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        toggleDrawer: (state, action) => {
            state.isDrawerOpen = !state.isDrawerOpen;
        },
        handleDrawer: (state, action) => {
            state.isDrawerOpen = action.payload
        },
        handleActivePath: (state, action) => {
            state.isActivePath = action.payload
        }
    }
});

export const { toggleDrawer, handleDrawer, handleActivePath } = commonSlice.actions;

export default commonSlice.reducer;
