import { Box, Button, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { ICONS, IMAGES } from '../assets';
import CommonPopover from '../components/CommonPopover';
import { copyShareURL } from '../utils/helpers';
import { COLORS } from '../utils/theme';

const RoundBox = styled(Box)({
    backgroundColor: COLORS.orange,
    borderRadius: 20,
    padding: 2,
    display: 'flex',
    alignItems: 'center'
});
const FieldLabel = styled(Typography)({
    fontSize: 16,
    fontWeight: 'bold',
    color: COLORS.black,
    alignItems: 'center',
    display: 'flex',
    marginLeft: 8
});
const ActionText = styled(Typography)({
    cursor: 'pointer',
    color: COLORS.black,
    fontSize: 14,
    marginLeft: 5
});

const MenuIconBox = styled(Box)({
    height: 23,
    width: 23,
    display: 'flex',
    padding: '4px',
    backgroundColor: COLORS.copyLink,
    marginRight: '3px'
});
const ShareIcon = styled(ICONS.Share)({
    fontSize: '20px',
    color: COLORS.white
});
const MenuIcon = styled('img')({
    height: 25,
    width: 25
});
export const ShareButton = ({ iconFontSize, textFontSize, shareUrl, isHide }) => {
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const { enqueueSnackbar } = useSnackbar();

    const handleOpenAction = (event) => {
        event.stopPropagation();
        setActionAnchorEl(event.currentTarget);
    };
    const handleCloseAction = (event) => {
        event.stopPropagation();
        setActionAnchorEl(null);
    };
    const handleShare = async () => {
        copyShareURL(shareUrl)
            .then(() => {
                enqueueSnackbar('Share URL copied!', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e, { variant: 'success' });
            });
        setActionAnchorEl(null);
    };
    return (
        <>
            <Button onClick={handleOpenAction} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 0 }}>
                <RoundBox>
                    <ShareIcon sx={iconFontSize && { fontSize: iconFontSize }} />
                </RoundBox>
                <FieldLabel sx={textFontSize && { fontSize: textFontSize }}>SHARE</FieldLabel>
            </Button>
            <CommonPopover
                anchorEl={actionAnchorEl}
                handleClose={handleCloseAction}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <Box sx={{ display: 'grid', padding: 1.5 }}>
                    {!isHide && <Box pt={1} sx={{ display: 'flex' }}>
                        <MenuIcon src={IMAGES.Facebook} sx={{ mr: '3px' }} />
                        <ActionText>FACEBOOK</ActionText>
                    </Box>}
                    <Box pt={1} sx={{ display: 'flex' }}>
                        <MenuIconBox>
                            <MenuIcon src={IMAGES.CopyLink} sx={{ height: 15, width: 15 }} />
                        </MenuIconBox>
                        <ActionText onClick={handleShare}>COPY LINK</ActionText>
                    </Box>
                </Box>
            </CommonPopover>
        </>
    );
};
