import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { reorder_categories } from '../../utils/helpers';

export const fetchCategory = createAsyncThunk('users/fetchCategory', async (params) => {
    const response = await api.category.getAllCategory(params);
    return response.data.result;
});

const initialState = {
    categorys: [],
    reorders: []
};

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCategory.fulfilled, (state, action) => {
            state.categorys = action.payload;
            state.reorders = reorder_categories(action.payload)
        });
    }
});

export default categorySlice.reducer;
