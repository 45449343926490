import React from 'react';
import { IconButton } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { ICONS } from '../assets';

const SnackbarCloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)} color="inherit">
            <ICONS.Close />
        </IconButton>
    );
};

const SnackbarWrapper = ({ children }) => {
    return (
        <SnackbarProvider maxSnack={3} action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}>
            {children}
        </SnackbarProvider>
    );
};

export default SnackbarWrapper;
