import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchGroups = createAsyncThunk('fetchGroups', async (params) => {
    const response = await api.group.get(params);
    return response.data.result;
});

const initialState = {
    groups: []
};

export const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGroups.fulfilled, (state, action) => {
            state.groups = action.payload;
        });
    }
});

export default groupSlice.reducer;
