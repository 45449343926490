import React from 'react';
import { TableRow, TableCell, TableSortLabel, TableHead, styled, Typography, Box, Checkbox } from '@mui/material';
import { COLORS } from '../../../utils/theme';
import { ICONS } from '../../../assets';

export const CustomTableHead = ({
    columns,
    numSelected,
    rowCount,
    onSelectAllClick,
    orderBy,
    order,
    visuallyHidden,
    onRequestSort,
    selectable,
    actions,
    isNotDefaultSorted
}) => {
    const createSortHandler = (event, property) => {
        onRequestSort(event, property);
    };
    const Header = styled(Typography)({
        fontWeight: 'bold',
        fontSize: '0.875rem',
        color: COLORS.black,
        paddingLeft: 16
    });
    return (
        <TableHead>
            <TableRow>
                {selectable && (
                    <TableCell padding="checkbox" align={'center'}>
                        <Checkbox
                            style={{ color: numSelected === rowCount ? COLORS.primaryBlue : COLORS.border }}
                            checked={numSelected === rowCount}
                            onClick={onSelectAllClick}
                        />
                    </TableCell>
                )}
                {columns?.map?.((headCell, index) => {
                    return (
                        <TableCell
                            key={headCell.id}
                            style={headCell.cellStyle}
                            align={headCell.align || 'left'}
                            sx={{ p: 0, py: 2 }}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            {isNotDefaultSorted ? <Header sx={{
                                width: '100%',
                                display: 'flex',
                                px: 2,
                                justifyContent: headCell.align ? headCell.align : 'start',
                                borderLeft: index !== 0 && `1px solid ${COLORS.border}`
                            }} >{headCell.label}</Header> : < TableSortLabel
                                // hideSortIcon
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={(e) => createSortHandler(e, headCell.fieldName)}
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    px: 2,
                                    justifyContent: headCell.align ? headCell.align : 'start',
                                    borderLeft: index !== 0 && `1px solid ${COLORS.border}`
                                }}
                                IconComponent={() => {
                                    return (
                                        <Box sx={{ display: 'flex' }}>
                                            <ICONS.ArrowDropUp
                                                style={{
                                                    color: orderBy === headCell.id && order === 'asc' ? COLORS.primary.light : COLORS.border
                                                }}
                                            />
                                            <ICONS.ArrowDropDownIcon
                                                style={{
                                                    color:
                                                        orderBy === headCell.id && order === 'desc' ? COLORS.primary.light : COLORS.border
                                                }}
                                            />
                                        </Box>
                                    );
                                }}
                            >
                                <Header>{headCell.label}</Header>
                            </TableSortLabel>}
                        </TableCell>
                    );
                })}
                {actions && (
                    <TableCell align={'center'}>
                        <Header>ACTION</Header>
                    </TableCell>
                )}
            </TableRow>
        </TableHead >
    );
};
