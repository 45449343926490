import { Box, styled, Grid, Link, Grow } from '@mui/material';
import { ORIENTATION, PORTRAIT_BOTTOM } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';

const MainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const OrientationBox = styled(Link)({
    border: `1px solid ${COLORS.black}`,
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex'
});
const OrientationBoxView = styled(Box)({
    width: '100%',
    height: '100%',
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});

const PortraitBox = styled(Grow)({
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    border: `1px solid ${COLORS.black}`
});

const OrientationText = styled(Box)({
    fontSize: 16,
    color: COLORS.black
});

export const Orientation = ({ defaultValue, setFieldValue, portraitBottom }) => {
    const portrait = defaultValue === ORIENTATION.PORTRAIT;
    const toggleOrientation = (orientation) => {
        setFieldValue('orientation', orientation);
    };

    return (
        <Box sx={{ padding: 8, backgroundColor: COLORS.white }}>
            <MainGrid container spacing={6}>
                <Grid item>
                    <OrientationBox style={{ width: 300, aspectRatio: 16 / 9 }} onClick={() => toggleOrientation(ORIENTATION.LANDSCAPE)}>
                        <OrientationBoxView
                            sx={{ border: defaultValue === ORIENTATION.LANDSCAPE ? `4px solid ${COLORS.primary.light}` : '' }}
                        >
                            <OrientationText>LANDSCAPE</OrientationText>
                        </OrientationBoxView>
                    </OrientationBox>
                </Grid>
                {portrait && (
                    <Grid item>
                        <PortraitBox
                            title="Bottom of screen on right."
                            in={portrait}
                            timeout={300}
                            style={{ height: 200, aspectRatio: 9 / 16, borderRight: '4px solid red' }}
                            onClick={() => {
                                setFieldValue('portraitBottom', PORTRAIT_BOTTOM.LEFT);
                            }}
                        >
                            <OrientationBoxView
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 1,
                                    backgroundColor: portraitBottom === PORTRAIT_BOTTOM.LEFT ? `${COLORS.primary.light}` : ''
                                }}
                            ></OrientationBoxView>
                        </PortraitBox>
                    </Grid>
                )}
                <Grid sx={{}} item>
                    <OrientationBox style={{ height: 300, aspectRatio: 9 / 16 }} onClick={() => toggleOrientation(ORIENTATION.PORTRAIT)}>
                        <OrientationBoxView
                            sx={{
                                width: '100%',
                                height: '100%',
                                border: defaultValue === ORIENTATION.PORTRAIT ? `4px solid ${COLORS.primary.light}` : '',
                                padding: 1
                            }}
                        >
                            <OrientationText>PORTRAIT</OrientationText>
                        </OrientationBoxView>
                    </OrientationBox>
                </Grid>
                {portrait && (
                    <Grid item>
                        <PortraitBox
                            title="Bottom of screen on left."
                            in={portrait}
                            timeout={300}
                            style={{ height: 200, aspectRatio: 9 / 16, borderLeft: '4px solid red' }}
                            onClick={() => {
                                setFieldValue('portraitBottom', PORTRAIT_BOTTOM.RIGHT);
                            }}
                        >
                            <OrientationBoxView
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    padding: 1,
                                    backgroundColor: portraitBottom === PORTRAIT_BOTTOM.RIGHT ? `${COLORS.primary.light}` : ''
                                }}
                            ></OrientationBoxView>
                        </PortraitBox>
                    </Grid>
                )}
            </MainGrid>
        </Box>
    );
};
