import { Box, styled } from '@mui/material';
import { COLORS } from '../utils/theme';

const MainBox = styled(Box)({
    border: `1px solid ${COLORS.darkGray}`
});

export const ColorBoxWidget = ({ height = 44, width = 72, backgroundColor = COLORS.primary.light, sx }) => {
    return <MainBox sx={{ height: height, width: width, backgroundColor: backgroundColor, ...sx }}></MainBox>;
};
