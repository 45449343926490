import { Box, styled, Typography } from '@mui/material';
import React from 'react';

const MainBox = styled(Box)({
    display: 'flex',
    width: '100%',
    flexDirection: 'column'
});

const LabelText = styled(Typography)({
    fontSize: 14,
    fontWeight: '600',
    marginBottom: 6,
});

const FieldLabelView = ({ label, children, fontSize, ...rest }) => {
    return (
        <MainBox {...rest}>
            <LabelText sx={{ fontSize: fontSize }}>{label}</LabelText>
            {children}
        </MainBox>
    );
};

export default FieldLabelView;
