import { Box, styled } from '@mui/material';
import React from 'react';
import CustomTooltip from '../../widgets/CustomTooltip';

const ButtonElement = styled(Box)({
    padding: '0px 12px',
    border: '1px solid gray',
    fontSize: '1.25rem',
    cursor: 'pointer'
});

const CommonIncreaseDecrease = ({ sx, handleClick, withTooltip, ...props }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...sx }} {...props}>
            <CustomTooltip id="increase" title='Increase size' arrow placement='bottom' >
                <ButtonElement onClick={(e) => {
                    handleClick('increase')
                    e.stopPropagation()
                }} sx={{ borderTopLeftRadius: 8, borderBottomLeftRadius: 8 }}>
                    +
                </ButtonElement>
            </CustomTooltip>
            <CustomTooltip title='Decrease size' arrow placement='bottom'>
                <ButtonElement onClick={(e) => {
                    handleClick('decrease')
                    e.stopPropagation()
                }} sx={{ borderTopRightRadius: 8, borderBottomRightRadius: 8 }}>
                    -
                </ButtonElement>
            </CustomTooltip>
        </Box>
    );
};

export default CommonIncreaseDecrease;
