import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { formateTagsData } from '../../utils/helpers';

export const fetchTags = createAsyncThunk('users/fetchTags', async (params) => {
    const response = await api.tag.get(params);
    return response.data.result;
});

const initialState = {
    tags: []
};

export const tagsSlice = createSlice({
    name: 'tag',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTags.fulfilled, (state, action) => {
            state.tags = formateTagsData(action.payload);
        });
    }
});

export default tagsSlice.reducer;
