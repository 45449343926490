import React, { useCallback, useMemo, useState } from 'react';
import { ViewerPermission } from '../ViewerPermission';
import { getOriantationByLayout } from '../../utils/helpers';
import { api } from '../../api';
import { Box, styled } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import { syncAllLoopByLibrary } from '../../redux/reducers/loopSlice';
import { COLORS } from '../../utils/theme';
import CommonButton from '../CommonButton';

const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: '300',
    height: '42px',
    fontSize: '16px',
    textAlign: 'center'
});

const UpdateLoopComponent = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

const UpdateLocationLoop = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { syncingLoopStatus } = useSelector((state) => state.loop);
    const { locations } = useSelector((state) => state.clientLocation);
    const { isActivePath } = useSelector((state) => state.common);
    const [loadingRefresh, setLoadingRefresh] = useState(false);

    const refreshLocation = useCallback(
        async (locationData) => {
            try {
                let orientation = getOriantationByLayout(locationData?.layout);
                if (orientation) {
                    setLoadingRefresh(true);
                    const response = await api.loop.getLoopByLocation({
                        params: {
                            location_id: locationData?.id
                        }
                    });
                    const libraryResponse = await api.library.get({ params: { orientation: orientation, status: 1 } });

                    return dispatch(
                        syncAllLoopByLibrary({ loops: response?.data?.result, libraryContents: libraryResponse?.data?.result })
                    );
                }
            } catch (error) {
                setLoadingRefresh(false);
            }
        },
        [dispatch]
    );

    const syncLoopByLocation = useCallback(() => {
        setLoadingRefresh(true);
        try {
            locations?.forEach(async (locationData) => {
                if (locationData) {
                    Promise.allSettled([refreshLocation(locationData)]).then((response) => {
                        setLoadingRefresh(false);
                    });
                }
            });
        } catch (error) {
            setLoadingRefresh(false);
        }
    }, [locations, refreshLocation]);

    const updateLoopWithSync = useCallback(
        (isActive) => {
            syncLoopByLocation();
        },
        [syncLoopByLocation]
    );

    const syncLoopData = useMemo(() => {
        return isActivePath === 'loops' ? syncingLoopStatus?.totalSyncFound !== 0 : loadingRefresh || syncingLoopStatus?.totalSyncFound;
    }, [isActivePath, loadingRefresh, syncingLoopStatus?.totalSyncFound]);

    const SyncingStatus = () => {
        return (
            <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', mr: 1, fontSize: 14 }}>
                {syncingLoopStatus?.totalSyncFound !== 0 && (
                    <span
                        style={{ fontSize: '12px' }}
                    >{`${syncingLoopStatus?.currentSync?.name} is syncing... (${syncingLoopStatus?.syncDone}/${syncingLoopStatus?.totalSyncFound})`}</span>
                )}
            </Box>
        );
    };

    return (
        <>
            {user?.role !== 'admin' && (
                <UpdateLoopComponent>
                    <ViewerPermission>
                        <StyledButton
                            loading={syncLoopData}
                            onClick={() => updateLoopWithSync(isActivePath)}
                            variant="contained"
                            label="Update"
                            sx={{ width: 120 }}
                            disabled={syncLoopData}
                        />
                    </ViewerPermission>
                    {SyncingStatus()}
                </UpdateLoopComponent>
            )}
        </>
    );
};

export default UpdateLocationLoop;
