import { Button, styled } from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { COLORS } from '../../utils/theme';
import { AlertDialog } from '../../widgets/CommonWidgets';
import { checkFormError } from '../../utils/helpers';

const StyledProgressView = styled(CircularProgress)({
    marginLeft: 8
});

const CommonButton = ({
    label,
    variant,
    color,
    size,
    startIcon,
    endIcon,
    isUpload,
    isMultiple,
    style,
    loading,
    confirmation,
    onClick,
    validateForm,
    section,
    title,
    disabled,
    sx,
    ...rest
}) => {
    const [showCofirm, setShowConfirm] = useState(false);

    const handleClick = async (e) => {
        if (confirmation) {
            if (validateForm) {
                let validation = await validateForm();
                if (checkFormError(validation)) {
                    if (onClick) onClick(e);
                } else {
                    setShowConfirm(true);
                }
            } else {
                setShowConfirm(true);
            }
        } else {
            if (onClick) onClick(e);
        }
    };

    const handleConfirm = () => {
        setShowConfirm(false);
        if (onClick) onClick();
    };
    return (
        <>
            <Button
                disabled={loading || disabled}
                color={color}
                size={size}
                startIcon={startIcon}
                endIcon={endIcon}
                sx={{ borderRadius: 0, borderColor: COLORS.primary.light, height: 42, ...sx }}
                variant={variant}
                onClick={handleClick}
                {...rest}
            >
                {label}
                {loading ? <StyledProgressView size={18} /> : null}
                {isUpload ? <input hidden accept="image/*" multiple={isMultiple} type="file" /> : null}
            </Button>
            {confirmation && showCofirm && (
                <AlertDialog
                    isOpen={showCofirm}
                    isClose={() => {
                        setShowConfirm(false);
                    }}
                    handleSubmit={handleConfirm}
                    title={title ? confirmation?.title + `${title}` : confirmation?.title}
                    description={section ? confirmation?.description + `${section}?` : confirmation?.description}
                    isCancel={'No'}
                />
            )}
        </>
    );
};

export default CommonButton;
