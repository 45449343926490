import { createSlice } from "@reduxjs/toolkit"

const initialState = {}

export const browseFilterSlice = createSlice({
    name: 'browseFilter',
    initialState,
    reducers: {
        handleFilter: (state, action) => {
            return {
                ...state, ...action.payload
            }
        },
        clearFilterData: (state, action) => {
            const { orientation, initial } = action.payload
            return {
                ...state, [orientation]: {
                    ...initial
                }
            }
        }
    }
})

export const { handleFilter, clearFilterData } = browseFilterSlice.actions;


export default browseFilterSlice.reducer;