import { Box, CircularProgress, Grid, Link, Typography, styled, Tooltip, ClickAwayListener } from '@mui/material';
import LibraryCategories from './components/LibraryCategories';
import { useEffect, useMemo, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { useSnackbar } from 'notistack';
import useFetch from '../../../hooks/useFetch';
import {
    CLEAR_CONFIRMATION,
    CONTENT_DATA_TYPE,
    CONTENT_MODULE_TYPE,
    ORIENTATION,
    SkeletonArray,
    USER_ROLE
} from '../../../utils/constants';
import { LibraryCardWidgets } from '../../../widgets/LibraryCardWidgets';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLibrary, fetchRemoveVideoToLibrary, removeToLibrary } from '../../../redux/reducers/librarySlice';
import CurationModal from './components/CurationModal';
import { COLORS } from '../../../utils/theme';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../../utils/urls';
import { fetchClientInfo } from '../../../redux/reducers/authSlice';
import CommonContentPopup from '../../../components/CommonContentPopup';
import CreatorContentPopUp from './components/CreatorContentPopUp';
import BrowserHeader from '../Browse/components/BrowserHeader';
import { fetchLocations } from '../../../redux/reducers/clientLocationSlice';
import EditAuthorizedContentPopup from '../../../components/EditAuthorizedContentPopup';
import { fetchSocialDetails } from '../../../redux/reducers/socialMediaSlice';
import { ViewerPermission } from '../../../components/ViewerPermission';
import CommonModal from '../../../components/CommonModal';
import CommonButton from '../../../components/CommonButton';
import { getOriantationByLayout } from '../../../utils/helpers';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import { IMAGES } from '../../../assets';
import { api } from '../../../api';

const LibraryContent = styled(Box)({
    margin: '10px 0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const NonCurateLibraryContent = styled(Box)({
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
});

const LibraryContentText = styled(Box)({
    fontSize: '14px',
    color: COLORS.black,
    fontWeight: 300
});

const LoaderBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex'
});

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
});

const StyledInfoIcon = styled(InfoIcon)({
    color: COLORS.gray,
    marginRight: 15,
    cursor: 'pointer'
});

const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: '600',
    fontSize: '16px',
    textAlign: 'center',
    backgroundColor: 'transparent',
    '&:hover': {
        backgroundColor: 'transparent'
    },
    '& .css-xerw2a-MuiButtonBase-root-MuiButton-root:hover': {
        backgroundColor: 'transparent'
    }
});

const AddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});

export const Librarys = () => {
    const [activeCategory, setActiveCategory] = useState();
    const [search, setSearch] = useState('');
    const [showCurationModal, setShowCurationModal] = useState(false);
    const [showVideoContentModal, setShowVideoContentModal] = useState(false);
    const [showAuthorizedModalForEdit, setAuthorizedModalForEdit] = useState(false);
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const [selectedContent, setSelectedContent] = useState();
    const [fetching, setFetched] = useState(false);
    const [isOpenTooltip, setOpenTooltip] = useState(false);
    const [loading, setLoading] = useState(false);
    const { categorys } = useSelector((state) => state.category);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const { client, user } = useSelector((state) => state.auth);
    const { locations } = useSelector((state) => state.clientLocation);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const {
        data: libraryData,
        callFetch: fetchLibrarysByCategory,
        isLoading
    } = useFetch({
        initialUrl: `/get_library`,
        skipOnStart: true
    });

    useEffect(() => {
        dispatch(fetchLocations()).then(() => {});
    }, [dispatch, user.client]);

    useEffect(() => {
        let params = {
            orientation: activeOrientation
        };
        dispatch(fetchLibrary({ params }));
    }, [activeOrientation, dispatch]);

    const libraryListByCategory = useMemo(() => {
        if (libraryData?.result) {
            return libraryData?.result;
        }
        return [];
    }, [libraryData]);

    const isCurateOn = useMemo(() => {
        return client?.curate_list?.[[activeCategory]];
    }, [activeCategory, client?.curate_list]);

    const isCurationProfileFound = useMemo(() => {
        return categorys?.find((item) => item.id === activeCategory)?.is_curation_profile_found;
    }, [activeCategory, categorys]);

    const activeCategoryName = useMemo(() => {
        return categorys?.find((item) => item.id === activeCategory)?.name;
    }, [activeCategory, categorys]);

    const orientationVisiblity = useMemo(() => {
        if (locations.length !== 0) {
            let isLandscapeOnly = false;
            let isPortraitOnly = false;
            isLandscapeOnly =
                locations.filter((item) => getOriantationByLayout(item?.layout) === ORIENTATION.LANDSCAPE).length === locations.length;
            isPortraitOnly =
                locations.filter((item) => getOriantationByLayout(item?.layout) === ORIENTATION.PORTRAIT).length === locations.length;

            return {
                isLandscapeOnly,
                isPortraitOnly
            };
        }
        return {
            isLandscapeOnly: false,
            isPortraitOnly: false
        };
    }, [locations]);

    useEffect(() => {
        if (activeCategory) {
            let params = {
                orientation: activeOrientation,
                category_id: activeCategory,
                status: 1,
                search: search
            };
            fetchLibrarysByCategory({ params });
        }
    }, [activeCategory, fetchLibrarysByCategory, activeOrientation, search, user?.client]);
    useEffect(() => {
        if (orientationVisiblity.isPortraitOnly) {
            setActiveOrientation(ORIENTATION.PORTRAIT);
        } else {
            setActiveOrientation(ORIENTATION.LANDSCAPE);
        }
    }, [orientationVisiblity]);
    useEffect(() => {
        dispatch(fetchClientInfo(user?.client?.id));
    }, [dispatch, user?.client]);

    const onChangeOrientation = (value) => {
        if (!orientationVisiblity.isLandscapeOnly && !orientationVisiblity.isPortraitOnly) {
            setActiveOrientation(value);
        }
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
    };

    const handleRemoveVideo = (item) => {
        setShowDeletePopup(!!item);
    };

    const handleCloseCurationModal = () => {
        setShowCurationModal(false);
    };

    const handleCloseVideoContentModal = () => {
        setShowVideoContentModal(false);
    };
    const handleClickContent = (data) => {
        setSelectedContent(data);
        if (data?.module_type === CONTENT_MODULE_TYPE.SOCIAL) {
            setFetched(true);
            dispatch(fetchSocialDetails({ type: data?.name_alias })).then(() => {
                setFetched(false);
                setAuthorizedModalForEdit(true);
            });
        } else {
            setShowVideoContentModal(true);
        }
    };

    const handleClose = () => {
        setShowDeletePopup(false);
    };

    const handleRemove = (item) => {
        dispatch(fetchRemoveVideoToLibrary(item)).then(() => {
            let params = {
                orientation: activeOrientation,
                category_id: activeCategory,
                search: search,
                status: 1
            };
            fetchLibrarysByCategory({ params });
            handleCloseVideoContentModal();
            handleClose();
        });
    };

    const handleRemoveAllContent = async () => {
        setLoading(true);
        try {
            dispatch(removeToLibrary(libraryListByCategory));
            const reqData = {
                contents: libraryListByCategory?.map((item) => item.id)
            };
            const { data } = await api.library.removeVideo(reqData);

            if (data.success) {
                enqueueSnackbar('All contents have been successfully removed.', { variant: 'success' });
                let params = {
                    orientation: activeOrientation,
                    category_id: activeCategory,
                    search: search,
                    status: 1
                };
                fetchLibrarysByCategory({ params });
                setLoading(false);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            setLoading(false);
        }
    };

    return (
        <Box>
            <BrowserHeader
                isNotShowRightCol
                onChangeOrientation={onChangeOrientation}
                title={'Lineup'}
                actionStyle={{ alignItems: 'center', justifyContent: 'end' }}
                orientation={activeOrientation}
                orientationVisiblity={orientationVisiblity}
            />
            <LibraryCategories isCurateOn={isCurateOn} onChangeSearch={onChangeSearch} onChangeCategory={(id) => setActiveCategory(id)} />
            <ViewerPermission>
                {categorys.find((item) => item.id === activeCategory)?.curate_playlist ? (
                    <LibraryContent>
                        <Box>
                            <LibraryContentText sx={{ display: 'flex', alignItems: 'center' }}>
                                <IOSSwitch
                                    sx={{ mr: 1, cursor: user.role !== USER_ROLE.VIEWER ? 'pointer' : 'not-allowed' }}
                                    onChange={(e) => setShowCurationModal(!showCurationModal)}
                                    checked={isCurateOn}
                                    disabled={user.role === USER_ROLE.VIEWER || (!isCurateOn && !isCurationProfileFound)}
                                />
                                {!isCurationProfileFound && isCurateOn ? (
                                    <Typography fontSize={14}>No curation profile can be found.</Typography>
                                ) : (
                                    <Typography fontSize={14}>
                                        Curation for this category is currently {isCurateOn ? 'enabled.' : 'disabled.'}
                                    </Typography>
                                )}

                                <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                                    <Tooltip
                                        title={
                                            !isCurationProfileFound && isCurateOn
                                                ? `When Curation profile can be found, relevant content is selected and added to your Lineup by our team on your behalf.`
                                                : `When Curation is enabled, relevant content is selected and added to your Lineup by our team on your behalf.`
                                        }
                                        open={isOpenTooltip}
                                        onClose={() => setOpenTooltip(false)}
                                        PopperProps={{
                                            disablePortal: true
                                        }}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <StyledInfoIcon onClick={() => setOpenTooltip(!isOpenTooltip)} />
                                    </Tooltip>
                                </ClickAwayListener>
                            </LibraryContentText>
                        </Box>
                        {!isCurateOn && Boolean(libraryListByCategory.length) && (
                            <Box>
                                <StyledButton
                                    onClick={handleRemoveAllContent}
                                    label={'REMOVE ALL'}
                                    loading={loading}
                                    startIcon={<AddImage src={IMAGES.RemoveCircleIcon} />}
                                    confirmation={{
                                        ...CLEAR_CONFIRMATION,
                                        description:
                                            CLEAR_CONFIRMATION.description + ' all content from the ' + activeCategoryName + ' Lineup?'
                                    }}
                                />
                            </Box>
                        )}
                    </LibraryContent>
                ) : Boolean(libraryListByCategory.length) ? (
                    <NonCurateLibraryContent>
                        <StyledButton
                            onClick={handleRemoveAllContent}
                            label={'REMOVE ALL'}
                            loading={loading}
                            startIcon={<AddImage src={IMAGES.RemoveCircleIcon} />}
                            confirmation={{
                                ...CLEAR_CONFIRMATION,
                                description: CLEAR_CONFIRMATION.description + ' all content from the ' + activeCategoryName + ' Lineup?'
                            }}
                        />
                    </NonCurateLibraryContent>
                ) : null}
            </ViewerPermission>
            {!libraryListByCategory.length && (
                <LibraryContentText sx={{ marginTop: '1rem' }}>
                    This Lineup category is currently empty. Use the{' '}
                    <Link sx={{ cursor: 'pointer' }} onClick={() => navigate(PAGES.BROWSE.url)}>
                        Content Browser
                    </Link>{' '}
                    to add content to your Lineup.
                </LibraryContentText>
            )}
            {showCurationModal && (
                <CurationModal
                    open={showCurationModal}
                    handleClose={handleCloseCurationModal}
                    isCurateOn={isCurateOn}
                    categoryId={activeCategory}
                    orientation={activeOrientation}
                    fetchLibrarysByCategory={fetchLibrarysByCategory}
                />
            )}
            <Grid container sx={{ mt: 0 }} spacing={3}>
                {libraryListByCategory && isLoading
                    ? SkeletonArray.map((item) => {
                          return (
                              <Grid item key={item} xs={12} sm={6} md={4} lg={3} xl={2}>
                                  <LibraryCardWidgets orientation={activeOrientation} loading={isLoading} />
                              </Grid>
                          );
                      })
                    : libraryListByCategory?.map((item) => {
                          return (
                              <Grid
                                  item
                                  key={item.id}
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={3}
                                  xl={2}
                                  sx={fetching && item.id === selectedContent.id && { opacity: 0.6, position: 'relative' }}
                              >
                                  <LibraryCardWidgets
                                      orientation={activeOrientation}
                                      handleRemoveVideo={(data) => handleRemoveVideo([data])}
                                      data={item}
                                      objectFit={item?.module_type === CONTENT_MODULE_TYPE.SOCIAL ? 'contain' : 'cover'}
                                      hasAuthStatus={item?.module_type === CONTENT_MODULE_TYPE.SOCIAL}
                                      isAuthorized={item?.is_authorized || false}
                                      //   handleEdit={item?.module_type === CONTENT_MODULE_TYPE.SOCIAL ? handleClickContent : false}
                                      //   onClick={item?.module_type !== CONTENT_MODULE_TYPE.SOCIAL ? handleClickContent : false}
                                      onClick={handleClickContent}
                                  />
                                  {fetching && item.id === selectedContent.id && (
                                      <LoaderBox>
                                          <CircularProgress size={18} />
                                      </LoaderBox>
                                  )}
                              </Grid>
                          );
                      })}
            </Grid>
            {showVideoContentModal && selectedContent.content_type !== CONTENT_DATA_TYPE.IMAGE && (
                <CommonContentPopup
                    isHide
                    open={showVideoContentModal}
                    handleClose={handleCloseVideoContentModal}
                    data={selectedContent}
                    orientation={activeOrientation}
                    onRemove={() => handleRemoveVideo([selectedContent])}
                />
            )}
            {showVideoContentModal && selectedContent.content_type === CONTENT_DATA_TYPE.IMAGE && (
                <CreatorContentPopUp
                    isHide
                    open={showVideoContentModal}
                    handleClose={handleCloseVideoContentModal}
                    data={selectedContent}
                    orientation={activeOrientation}
                    removeSingleToLibrary={() => handleRemoveVideo([selectedContent])}
                />
            )}
            {showAuthorizedModalForEdit && (
                <EditAuthorizedContentPopup
                    open={showAuthorizedModalForEdit}
                    handleClose={() => setAuthorizedModalForEdit(false)}
                    data={selectedContent}
                    fetchLatestData={() =>
                        fetchLibrarysByCategory({
                            params: {
                                orientation: activeOrientation,
                                category_id: activeCategory,
                                status: 1,
                                search: search
                            }
                        })
                    }
                />
            )}
            {
                // render delete modal
                showDeletePopup ? (
                    <CommonModal open={showDeletePopup} onClose={handleClose} width={500} title={'Delete Creator Content'}>
                        <Box>
                            <Typography sx={{ textAlign: 'center' }}>
                                Are you sure you want to remove this item from your Lineup?{' '}
                            </Typography>
                        </Box>
                        <ButtonContainer>
                            <CommonButton
                                label={'Yes'}
                                sx={{ marginRight: '1rem' }}
                                onClick={() => handleRemove([selectedContent])}
                                variant="contained"
                            />
                            <CommonButton label={'No'} onClick={handleClose} variant="contained" />
                        </ButtonContainer>
                    </CommonModal>
                ) : null
            }
        </Box>
    );
};
