import DashboardIcon from '@mui/icons-material/Dashboard';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import Face4Icon from '@mui/icons-material/Face4';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import page404Logo from '../assets/images/illustration_404.svg';
import KeyIcon from '@mui/icons-material/Key';
import ProductIcon from '@mui/icons-material/Inventory2';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import Shop2Icon from '@mui/icons-material/Shop2';
import CategoryIcon from '@mui/icons-material/Category';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EuroIcon from '@mui/icons-material/Euro';
import AudIcon from './images/AudIcon';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RemoveIcon from '@mui/icons-material/Remove';
import FoodBankIcon from '@mui/icons-material/FoodBank';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PublicIcon from '@mui/icons-material/Public';

import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DSID_LOGO from './images/DSID_Logo_2022.png';
import WAYFINDER from './images/wayFinderOrthodontics.png';
import SMALL_LOGO from './images/smallLogo.png';
import LogoutIcon from '@mui/icons-material/Logout';
import UPLOAD_ICON from './images/Upload_Icon.png';
import CURATED_ICON from './images/Curated_Icon_2.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import LayersIcon from '@mui/icons-material/Layers';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Share from '@mui/icons-material/Share';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import AddImage from './images/Add_Image.png';
import Background from './images/background.png';
import CenterAlignIcon from './images/Center_Align_Icon.png';
import Reset from './images/reset.png';
import LeftAlignIcon from './images/Left_Align_Icon.png';
import RightAlignIcon from './images/Right_Align_Icon.png';
import Text from './images/Text.png';
import AddText from './images/Add_Text.png';
import Fonts from './images/fonts.png';

import AddCircleIcon from './images/Add_Icon.png';
import RemoveCircleIcon from './images/Remove_Icon.png';
import DuplicatedIcon from './images/duplicate-icon.svg';
import SendBackwardIcon from './images/Send_Backward_Icon.svg';
import BringForwardIcon from './images/Bring_Forward_Icon.svg';

import BACKGROUND_FILE_ICON from './images/Background_File_Icon.png';
import PLACEHOLDER_FILE_ICON from './images/Placeholder_File_Icon.png';
import CREATOR_CONTENT from './images/CreatorContent.jpeg';
import OptionsIcon from './images/Options_Icon.png';
import Delete_Icon from './images/Delete_Icon.png';
import CheckMark from './images/Check_Mark.png';
import Timezone from './images/Timezone_Icon.png';
import Loader from './images/Loader.gif';
import { ChevronRight, Circle, Rectangle } from '@mui/icons-material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import LoopIcon from '@mui/icons-material/Loop';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SpeakerGroupIcon from '@mui/icons-material/SpeakerGroup';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import PlaceIcon from '@mui/icons-material/Place';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import PlayIcon from '../assets/images/Video_Play_Button.png';
import RemoveHyphenIcon from '../assets/images/Remove_Icon_2.png';
import CopyLink from '../assets/images/linked.png';
import Facebook from '../assets/images/facebook.webp';
import Reset_Icon from '../assets/images/Reset_Icon.png';
import Reset_Icon2 from '../assets/images/reset_icon_2.png';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Attention_Icon from '../assets/images/Attention_Icon.png';
import GoogleLogo from '../assets/images/Google_Logo.png';
import FacebookLogo from '../assets/images/Facebook_Logo.png';
import InstagramLogo from '../assets/images/Instagram_Logo.png';
import Facebook_Integration_UI_2023_Landscape from '../assets/images/Facebook_Integration_UI_2023_Landscape.jpg';
import SnappicBooth from '../assets/images/snappic-booth.webp';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import HeadsetMicRoundedIcon from '@mui/icons-material/HeadsetMicRounded';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import OpacityIcon from '../assets/images/opacity-icon.jpg';
import { ReactComponent as Trash } from '../assets/images/trash.svg';
import { ReactComponent as ResetLoop } from '../assets/images/arrow-repeat.svg';
import { ReactComponent as VideoFile } from '../assets/images/video-file-icon.svg';
import { ReactComponent as PlaceholderFile } from '../assets/images/placeholder-file-icon.svg';
import { ReactComponent as PaintBrush } from '../assets/images/brush.svg';
import { ReactComponent as Puzzle } from '../assets/images/puzzle.svg';

export const FONTS = {};

export const ICONS = {
    home: MapsHomeWorkIcon,
    profile: Face4Icon,
    key: KeyIcon,
    product: ProductIcon,
    Menu: MenuIcon,
    Search: SearchIcon,
    settings: SettingsIcon,
    bell: NotificationsActiveIcon,
    cart: ShoppingCartIcon,
    Close: CloseIcon,
    order: Shop2Icon,
    ruppe: CurrencyRupeeIcon,
    Delete: DeleteIcon,
    Edit: EditIcon,
    usd: AttachMoneyIcon,
    eur: EuroIcon,
    aud: AudIcon,
    Share: Share,
    Restaurant: RestaurantIcon,
    More: MoreVertIcon,
    Add: AddIcon,
    remove: RemoveIcon,
    foodCategory: FoodBankIcon,
    CheckCircleIcon: CheckCircleIcon,
    errorRoundedIcon: HighlightOffRoundedIcon,
    Back: ArrowBackIosIcon,
    CancelIcon: CancelIcon,
    InfoOutlinedIcon: InfoOutlinedIcon,
    LogoutIcon: LogoutIcon,
    ExpandMore: ChevronRight,
    ExpandLess: ExpandMoreIcon,
    Layers: LayersIcon,
    FormatColorText: FormatColorTextIcon,
    InsertPhoto: InsertPhotoIcon,
    ArrowBack: ArrowBackIosNewIcon,
    ArrowForward: ArrowForwardIosIcon,
    PlayCircle: PlayCircleOutlineIcon,
    MoreVertSharpIcon: MoreVertSharpIcon,
    Eye: VisibilityIcon,
    EyeOff: VisibilityOff,
    ArrowDown: KeyboardArrowDownIcon,
    ArrowUp: KeyboardArrowUpIcon,
    ArrowDropUp: ArrowDropUp,
    ArrowDropDownIcon: ArrowDropDownIcon,
    Accounts: NewspaperIcon,
    Users: PeopleAltIcon,
    Layout: DashboardIcon,
    Loop: LoopIcon,
    Videos: VideoSettingsIcon,
    Collections: VideoLibraryIcon,
    Groups: SpeakerGroupIcon,
    Platlist: CategoryIcon,
    Background: WallpaperIcon,
    Locations: PlaceIcon,
    Library: BookmarksIcon,
    Browser: BrowserUpdatedIcon,
    Create: CreateNewFolderIcon,
    Upload: DriveFolderUploadIcon,
    Social: ConnectWithoutContactIcon,
    Email: MailOutlineIcon,
    Lock: LockIcon,
    AddCircleRoundedIcon: AddCircleRoundedIcon,
    RemoveCircleRoundedIcon: RemoveCircleRoundedIcon,
    ThumbUp: ThumbUpIcon,
    PanToolAltIcon: PanToolAltIcon,
    Headset: HeadsetMicRoundedIcon,
    DragIndicatorIcon: DragIndicatorIcon,
    Message: ChatBubbleIcon,
    CircleShape: Circle,
    RectangleShape: Rectangle,
    Feedback: QuestionAnswerIcon,
    Public: PublicIcon,
    Trash, ResetLoop, PlaceholderFile, VideoFile, PaintBrush, Puzzle
};

export const IMAGES = {
    page404Logo,
    DSID_LOGO,
    WAYFINDER,
    SMALL_LOGO,
    UPLOAD_ICON,
    CURATED_ICON,
    AddImage,
    AddText,
    Background,
    CenterAlignIcon,
    Reset,
    LeftAlignIcon,
    RightAlignIcon,
    Text,
    Fonts,
    AddCircleIcon,
    RemoveCircleIcon,
    DuplicatedIcon,
    SendBackwardIcon,
    BringForwardIcon,
    BACKGROUND_FILE_ICON,
    PLACEHOLDER_FILE_ICON,
    CREATOR_CONTENT,
    OptionsIcon,
    Delete_Icon,
    CheckMark,
    Timezone,
    Loader,
    PlayIcon,
    RemoveHyphenIcon,
    CopyLink,
    Facebook,
    Reset_Icon,
    Reset_Icon2,
    Attention_Icon,
    GoogleLogo,
    FacebookLogo,
    InstagramLogo,
    Facebook_Integration_UI_2023_Landscape,
    SnappicBooth,
    OpacityIcon
};
