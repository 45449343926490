import React from 'react';
import { Box, Popover, Typography, styled } from '@mui/material';

const MainBox = styled(Box)({
    width: 240,
    padding: 12
});

const Title = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textAlign: 'center'
});

const ShapeBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: '0.7rem'
});

const Reactangle = styled('div')({
    width: 30 * 1.5,
    height: 30,
    backgroundColor: "black",
})

const Circle = styled('div')({
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: "black",
})

const Triangle = styled('div')({
    width: 0,
    height: 0,
    borderLeft: '18px solid transparent',
    borderRight: '18px solid transparent',
    borderBottom: '30px solid black'
})

const SHAPE_LIST = [
    {
        id: 1,
        name: 'Rectangle',
        value: 'rectangle',
        Component: Reactangle,
    },
    {
        id: 2,
        name: 'Circle',
        value: 'circle',
        Component: Circle,
    },
    {
        id: 3,
        name: 'Triangle',
        value: 'triangle',
        Component: Triangle,
    },
];

export const ShapePopup = ({ id, open, anchorEl, handleClose, handleSelectShape }) => {


    const handleShapeAction = (item) => {
        handleSelectShape(item)
    }

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <MainBox>
                <Title>Add Shape</Title>
                <ShapeBox>
                    {SHAPE_LIST.map((item) => {
                        return (
                            <Box onClick={() => handleShapeAction(item)} key={item.id} item md={12}>
                                <item.Component sx={{ cursor: 'pointer' }} />
                            </Box>
                        );
                    })}
                </ShapeBox>
            </MainBox>
        </Popover>
    );
};
