import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchClientAccess = createAsyncThunk('users/fetchClientAccess', async (params, { dispatch }) => {
    dispatch(updateLoadingAccessStatus(true));
    const response = await api.auth.clientAccess(params);
    return response.data.result;
});

export const fetchClientInfo = createAsyncThunk('users/fetchClientInfo', async (params) => {
    const response = await api.client.getById(params);
    return response.data.result;
});

export const fetchUserInfo = createAsyncThunk('users/fetchUserInfo', async (params) => {
    const response = await api.auth.userInfo(params);
    return response.data.result;
});

const initialState = {
    user: null,
    access: null,
    client: null,
    clientList: [],
    loadingAccess: true
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        updateClient: (state, action) => {
            state.client = action.payload;
        },
        createUser: (state, action) => {
            // state.user = action.payload;
        },
        updateLoadingAccessStatus: (state, action) => {
            state.loadingAccess = action.payload;
        },
        logout: (state, action) => {
            state.user = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchClientAccess.fulfilled, (state, action) => {
            state.loadingAccess = false;
            state.access = action.payload;
        });
        builder.addCase(fetchClientInfo.fulfilled, (state, action) => {
            state.client = action.payload;
        });
        builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
            const { clients, ...data } = action.payload
            return {
                ...state, user: {
                    ...state.user, ...data
                }
            }
        });
    }
});

// Action creators are generated for each case reducer function
export const { updateUser, createUser, logout, updateClient, updateLoadingAccessStatus } = authSlice.actions;

export default authSlice.reducer;
