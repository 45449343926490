import { Box, styled, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { CommonInput } from '../../../../../components/CommonInput';
import { CommonMultiSelect } from '../../../../../components/CommonMultiSelect';
import { COLORS } from '../../../../../utils/theme';
import { DeleteButton, UnsavedChanges } from '../../../../../widgets/CommonWidgets';
import ImagePlaceholder from '../../../../../widgets/ImagePlaceholder';
import CommonButton from '../../../../../components/CommonButton';
import { ASPECT_RATIO, DELETE_CONFIRMATION, SERVER_URL, UPDATE_CONFIRMATION, ORIENTATION } from '../../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../../../redux/reducers/userSlice';
import { api } from '../../../../../api';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { accountFormValidation } from '../../../../../utils/validations';
import FieldLabelView from '../../../../../components/FieldLabelView';
import { crop, getAspectRatioWidthFromHeight, getPlaceholderRenderHeight } from '../../../../../utils/helpers';
import { fetchAccountTypeTerritory, fetchAccountTypeSpecialty } from '../../../../../redux/reducers/accountTypesSlice';

const MainBox = styled(Box)({
    padding: 16,
    width: '100%',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem',
    backgroundColor: COLORS.white
});

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: 18
});

const StyledInput = styled(CommonInput)(({ theme }) => ({
    flex: 1
}));
const StyledButton = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: 100,
    borderRadius: 0
});

const UpdateGrid = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end'
}));
const ButtonWrap = styled(Box)({
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
});

export default function General({ clientData, callFetch, isUnSavedChangesThere }) {
    const [loading, setLoading] = useState({ submit: false, delete: false });
    const { users } = useSelector((state) => state.user);
    const { territory, Specialty } = useSelector(({ accountTypes }) => accountTypes);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const userList = useMemo(() => {
        return users.map((item) => {
            return {
                id: item.id,
                name: item.email,
                value: item.id
            };
        });
    }, [users]);

    const { handleBlur, handleChange, values, touched, errors, setValues, setFieldValue, handleSubmit, validateForm } = useFormik({
        initialValues: {
            name: '',
            type: '',
            users: [],
            logo_file: null,
            landscape_file: null,
            portrait_file: null,
            logo: null,
            landscape: null,
            portrait: null,
            isModal: false,
            modalType: '',
            title: '',
            description: '',
            unSavedChange: false,
            territory_id: '',
            speciality_id: ''
        },
        validationSchema: accountFormValidation,
        onSubmit: async (values) => {
            setLoading({ ...loading, submit: true });
            try {
                let params = {};
                params['id'] = clientData.id;
                params['name'] = values.name;
                params['type'] = values.type;
                params['users'] = values.users;

                if (values.territory_id) params['territory_id'] = values.territory_id;
                if (values.speciality_id) params['speciality_id'] = values.speciality_id;
                if (values.logo) params['logo'] = values.logo;
                if (values.landscape) params['landscape'] = values.landscape;
                if (values.portrait) params['portrait'] = values.portrait;
                const { data } = await api.client.update(params);
                if (data.success) {
                    callFetch();
                    enqueueSnackbar(data.message, { variant: 'success' });
                } else {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
                setLoading({ ...loading, submit: false });
            } catch (error) {
                setLoading({ ...loading, submit: false });
                enqueueSnackbar(error.message.error, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        dispatch(fetchUsers());
        dispatch(fetchAccountTypeTerritory());
        dispatch(fetchAccountTypeSpecialty());
    }, [dispatch]);

    useEffect(() => {
        isUnSavedChangesThere(values.unSavedChange);
    }, [values.unSavedChange]);

    useEffect(() => {
        if (clientData) {
            setValues({
                ...values,
                name: clientData?.name,
                type: clientData?.type,
                users: clientData.users.map((item) => item.id),
                logo_file: SERVER_URL + clientData.logo,
                landscape_file: SERVER_URL + clientData.cc_defaultwp,
                portrait_file: SERVER_URL + clientData.cc_defaultwp_portrait,
                territory_id: clientData?.territory_id || '',
                speciality_id: clientData?.speciality_id || ''
            });
        }
    }, [clientData, setValues]);

    const handleDeleteAccount = async () => {
        setLoading({ ...loading, delete: true });
        const { data } = await api.client.delete(clientData.id);
        if (data.success) {
            callFetch();
            enqueueSnackbar(data.message, { variant: 'success' });
            navigate(-1);
        } else {
            enqueueSnackbar(data.message, { variant: 'error' });
        }
        setLoading({ ...loading, delete: false });
    };

    const checkUnsaveChanges = () => {
        clientData.id && !values.unSavedChange && setFieldValue('unSavedChange', true);
    };
    const checkHandlechange = (data, value) => {
        checkUnsaveChanges();
        handleChange(data);
    };

    return (
        <MainBox sx={{ mt: 3 }}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={7} sm={12}>
                    <FieldLabelView label={'Name'}>
                        <StyledInput
                            handleChange={checkHandlechange}
                            handleBlur={handleBlur}
                            error={touched.name && errors.name}
                            name={'name'}
                            value={values.name}
                        />
                    </FieldLabelView>
                    <Grid item xs={12} container columnSpacing={3} rowSpacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <FieldLabelView label={'Territory'}>
                                <CommonMultiSelect
                                    name={'territory_id'}
                                    error={touched.territory_id && errors.territory_id}
                                    value={values.territory_id}
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    options={territory?.map((cv) => {
                                        return { id: cv?.id, name: cv?.name, value: cv?.id };
                                    })}
                                />
                            </FieldLabelView>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6}>
                            <FieldLabelView label={'Specialty'}>
                                <CommonMultiSelect
                                    name={'speciality_id'}
                                    error={touched.speciality_id && errors.speciality_id}
                                    value={values.speciality_id}
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    options={Specialty?.map((cv) => {
                                        return { id: cv?.id, name: cv?.name, value: cv?.id };
                                    })}
                                />
                            </FieldLabelView>
                        </Grid>
                    </Grid>

                    <FieldLabelView sx={{ mt: 2 }} label={'Type'}>
                        <StyledInput
                            handleChange={checkHandlechange}
                            handleBlur={handleBlur}
                            error={touched.type && errors.type}
                            name={'type'}
                            value={values.type}
                        />
                    </FieldLabelView>

                    <FieldLabelView sx={{ mt: 2 }} label={'Users'}>
                        <CommonMultiSelect
                            handleBlur={handleBlur}
                            handleChange={checkHandlechange}
                            options={userList}
                            error={touched.users && errors.users}
                            searchableMultiselect={true}
                            name={'users'}
                            multiple={true}
                            value={values.users}
                            style={{ height: 'auto' }}
                        />
                    </FieldLabelView>

                    <FieldLabelView id={'account-placeholder-main'} sx={{ mt: 2 }} label={'Placeholders'}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <ImagePlaceholder
                                handleChange={async (event) => {
                                    checkUnsaveChanges();
                                    let image = await crop(URL.createObjectURL(event.target.files[0]), ASPECT_RATIO[ORIENTATION.LANDSCAPE]);

                                    setFieldValue('landscape_file', image);
                                    setFieldValue('landscape', image?.split(',')[1]);
                                }}
                                image={values.landscape_file}
                                isAddIcon={values.landscape_file ? false : true}
                                boxDimensions={{
                                    width: getAspectRatioWidthFromHeight(
                                        getPlaceholderRenderHeight('account-placeholder-main', 12),
                                        16 / 9
                                    ),
                                    height: getPlaceholderRenderHeight('account-placeholder-main', 12),
                                    objectFit: 'contain'
                                }}
                                addIconDimension={{ height: 50, width: 50 }}
                            />
                            <div style={{ marginLeft: 12 }}>
                                <ImagePlaceholder
                                    handleChange={async (event) => {
                                        checkUnsaveChanges();
                                        setFieldValue('portrait_file', event.target.files[0]);
                                        let image = await crop(
                                            URL.createObjectURL(event.target.files[0]),
                                            ASPECT_RATIO[ORIENTATION.PORTRAIT]
                                        );
                                        setFieldValue('portrait_file', image);
                                        setFieldValue('portrait', image?.split(',')[1]);
                                    }}
                                    image={values.portrait_file}
                                    isAddIcon={values.portrait_file ? false : true}
                                    boxDimensions={{
                                        width: getAspectRatioWidthFromHeight(
                                            getPlaceholderRenderHeight('account-placeholder-main', 12),
                                            9 / 16
                                        ),
                                        height: getPlaceholderRenderHeight('account-placeholder-main', 12),
                                        objectFit: 'contain'
                                    }}
                                    addIconDimension={{ height: 50, width: 50 }}
                                />
                            </div>
                        </div>
                    </FieldLabelView>
                </Grid>
                <Grid item md={5} sm={4} xs={12}>
                    <Grid container item md={12} sm={12} xs={12}>
                        <Grid
                            item
                            md={12}
                            lg={12}
                            xs={12}
                            sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-end', lg: 'flex-end' } }}
                        >
                            <Box sx={{ width: 'max-content' }}>
                                <FieldLabelView label={'Logo'}>
                                    <ImagePlaceholder
                                        handleChange={async (event) => {
                                            checkUnsaveChanges();
                                            let image = await crop(URL.createObjectURL(event.target.files[0]), ASPECT_RATIO['LOGO']);
                                            setFieldValue('logo_file', image);
                                            setFieldValue('logo', image?.split(',')[1]);
                                        }}
                                        image={values.logo_file}
                                        isAddIcon={values.logo_file ? false : true}
                                        boxDimensions={{ width: '150px', height: '150px', objectFit: 'contain' }}
                                    />
                                </FieldLabelView>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <ButtonWrap sx={{ mt: 2 }}>
                    <FieldView sx={{ width: { md: '100%', sm: '0%', xs: '0%' } }} md={5} lg={5}></FieldView>
                    <UpdateGrid item sm={12} xs={12} md={7} lg={7}>
                        {values.unSavedChange && <UnsavedChanges />}
                        <DeleteButton
                            sx={{ mr: 2 }}
                            onClick={handleDeleteAccount}
                            loading={loading.delete}
                            confirmation={DELETE_CONFIRMATION}
                            label={'Delete Account'}
                            section={'Account'}
                            title={'Account'}
                        />
                        <StyledButton
                            key="Update-Button"
                            id="Update-Button"
                            confirmation={UPDATE_CONFIRMATION}
                            validateForm={validateForm}
                            onClick={handleSubmit}
                            loading={loading.submit}
                            variant="contained"
                            label={'Update'}
                            title={'Account'}
                        />
                    </UpdateGrid>
                </ButtonWrap>
            </Grid>
        </MainBox>
    );
}
