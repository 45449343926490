import { Box, FormHelperText, Grid, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CommonInput } from '../../../components/CommonInput';
import { COLORS } from '../../../utils/theme';
import { CommonMultiSelect } from '../../../components/CommonMultiSelect';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import ImagePlaceholder from '../../../widgets/ImagePlaceholder';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomColorPicker } from '../../../components/CustomColorPicker';
import { addCategoryFormValidation } from '../../../utils/validations';
import { useSnackbar } from 'notistack';
import { api } from '../../../api';
import useFetch from '../../../hooks/useFetch';
import { SERVER_URL } from '../../../utils/constants';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import Loader from '../../../components/Loader';
import { ColorBoxWidget } from '../../../widgets/ColorBoxWidget';
import FieldLabelView from '../../../components/FieldLabelView';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../redux/reducers/accountTypesSlice';

const typeList = [
    {
        id: 'video',
        name: 'Video',
        value: 'video'
    },
    {
        id: 'image',
        name: 'Image',
        value: 'image'
    },
    {
        id: 'html',
        name: 'HTML',
        value: 'HTML'
    }
];

const FormBox = styled(Box)({
    width: '100%',
    padding: 22,
    backgroundColor: COLORS.white,
    marginTop: 24,
    borderRadius: '.425rem',
    border: `1px solid ${COLORS.border}`
});

const FieldLabel = styled(Typography)({
    fontSize: 14,
    fontWeight: 600,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 4
});

const StyledInput = styled(CommonInput)({
    flex: 1
});

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
});

export const AddEditPlaylistCategory = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const dispatch = useDispatch();

    const { data, isLoading } = useFetch({
        initialUrl: `/get_category/${id}`,
        skipOnStart: id ? false : true
    });
    const EditData = data?.result;

    const [loading, setLoading] = useState(false);

    const [showDeletePopup, setDeletePopup] = useState(false);
    const { territory, Specialty } = useSelector(({ accountTypes }) => accountTypes);

    const { handleBlur, handleChange, values, touched, errors, setFieldValue, handleSubmit, setValues, setTouched } = useFormik({
        initialValues: {
            name: '',
            type: '',
            icon: '',
            color: COLORS.white,
            icon_file: null,
            client_side_status: true,
            curate_playlist: false,
            search: '',
            unSavedChange: false,
            contents: [],
            territory_status: false,
            territory_id: [],
            speciality_status: false,
            speciality_id: []
        },
        validationSchema: addCategoryFormValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('type', values.type);
                formData.append('description', values.description);
                formData.append('color', values.color);
                formData.append('client_side_status', Number(values.client_side_status));
                formData.append('curate_playlist', Number(values.curate_playlist));
                if (values.curate_playlist) {
                    formData.append('contents', JSON.stringify(values.contents));
                }
                if (values.icon_file) {
                    formData.append('icon', values.icon_file);
                }
                if (values.territory_status) {
                    formData.append('territory_id', JSON.stringify(values.territory_id));
                }
                if (values.speciality_status) {
                    formData.append('speciality_id', JSON.stringify(values.speciality_id));
                }

                let result;
                if (id) {
                    formData.append('id', id);
                    const { data } = await api.category.updateCategoryById(formData);
                    result = data;
                } else {
                    const { data } = await api.category.addCategory(formData);
                    result = data;
                }
                if (result.success) {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error?.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        if (EditData) {
            setValues({
                ...values,
                name: EditData?.name,
                type: EditData?.type,
                description: EditData?.description,
                color: EditData?.color,
                icon: SERVER_URL + EditData.icon,
                client_side_status: Boolean(EditData?.client_side_status),
                curate_playlist: Boolean(EditData?.curate_playlist),
                territory_id: EditData?.account_type_terriotary_accesses?.length
                    ? EditData?.account_type_terriotary_accesses?.map((item) => item?.territory_id)
                    : [],
                speciality_id: EditData?.account_type_speciality_accesses?.length
                    ? EditData?.account_type_speciality_accesses?.map((item) => item?.speciality_id)
                    : [],
                territory_status: EditData?.account_type_terriotary_accesses?.length ? true : false,
                speciality_status: EditData?.account_type_speciality_accesses?.length ? true : false
            });
        }
    }, [EditData, setValues]);

    const handleDelete = async () => {
        try {
            const { data } = await api.category.delete(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const checkUnsaveChanges = () => {
        id && !values.unSavedChange && setFieldValue('unSavedChange', true);
    };

    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };

    useEffect(() => {
        dispatch(fetchAccountTypeTerritory());
        dispatch(fetchAccountTypeSpecialty());
    }, [dispatch]);

    return (
        <Box>
            <CommonHeader
                title={'Category'}
                buttonLabel={'Save'}
                backTo={'Playlists'}
                breadcrumbText={id ? 'Edit' : 'Add'}
                unsavedChanges={values.unSavedChange}
                onClick={handleSubmit}
                inEdit={id ? true : false}
                onClose={() => navigate(-1)}
                onDelete={() => setDeletePopup(true)}
                loading={loading}
                isXSset6={true}
            />
            {isLoading && <Loader isLoading={isLoading} />}
            {!isLoading && (
                <FormBox>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}>
                        <Grid item sm={9} xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12} md={12}>
                                    <FieldLabelView label={'Name'}>
                                        <StyledInput
                                            handleChange={checkHandlechange}
                                            handleBlur={handleBlur}
                                            error={touched.name && errors.name}
                                            name={'name'}
                                            value={values.name}
                                        />
                                    </FieldLabelView>
                                </Grid>

                                <Grid item sm={6} xs={12} md={6}>
                                    <FieldLabelView label={'Type'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={checkHandlechange}
                                            options={typeList}
                                            name={'type'}
                                            error={touched.type && errors.type}
                                            value={values.type}
                                        />
                                    </FieldLabelView>
                                </Grid>

                                <Grid item sm={12} xs={12} md={12} container>
                                    <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Grid item sm={6} xs={12} md={6} lg={6}>
                                            <FieldView sx={{ justifyContent: 'start' }} mt={1}>
                                                <FieldLabel>Territory:</FieldLabel>
                                                <IOSSwitch
                                                    sx={{ mx: 1 }}
                                                    checked={values?.territory_status}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setFieldValue('territory_id', []);
                                                            setTouched({ ...touched, ['territory_id']: false });
                                                        }
                                                        checkUnsaveChanges();
                                                        setFieldValue('territory_status', e.target.checked);
                                                    }}
                                                />
                                            </FieldView>
                                            {values?.territory_status && (
                                                <FieldLabelView label={''}>
                                                    <CommonMultiSelect
                                                        name={'territory_id'}
                                                        error={touched.territory_id && errors.territory_id}
                                                        value={values.territory_id}
                                                        handleChange={checkHandlechange}
                                                        handleBlur={handleBlur}
                                                        options={territory?.map((cv) => {
                                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                                        })}
                                                        multiple={true}
                                                        style={{ height: 'auto' }}
                                                        searchableMultiselect={false}
                                                        checkbox={true}
                                                    />
                                                </FieldLabelView>
                                            )}
                                        </Grid>
                                        <Grid item sm={6} xs={12} md={6} lg={6}>
                                            <FieldView sx={{ justifyContent: 'start' }} mt={1}>
                                                <FieldLabel>Specialty:</FieldLabel>
                                                <IOSSwitch
                                                    sx={{ mx: 1 }}
                                                    checked={values?.speciality_status}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setFieldValue('speciality_id', []);
                                                            setTouched({ ...touched, ['speciality_id']: false });
                                                        }
                                                        checkUnsaveChanges();
                                                        setFieldValue('speciality_status', e.target.checked);
                                                    }}
                                                />
                                            </FieldView>
                                            {values?.speciality_status && (
                                                <FieldLabelView label={''}>
                                                    <CommonMultiSelect
                                                        name={'speciality_id'}
                                                        error={touched.speciality_id && errors.speciality_id}
                                                        value={values.speciality_id}
                                                        handleChange={checkHandlechange}
                                                        handleBlur={handleBlur}
                                                        options={Specialty?.map((cv) => {
                                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                                        })}
                                                        multiple={true}
                                                        style={{ height: 'auto' }}
                                                        searchableMultiselect={false}
                                                        checkbox={true}
                                                    />
                                                </FieldLabelView>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sm={6} xs={12} md={6} sx={{ mt: 2 }}>
                                <FieldLabelView label={'Description'}>
                                    <StyledInput
                                        style={{ height: 'auto' }}
                                        handleChange={checkHandlechange}
                                        handleBlur={handleBlur}
                                        error={touched.description && errors.description}
                                        name={'description'}
                                        value={values.description}
                                        multiline={true}
                                        minRows={3}
                                    />
                                </FieldLabelView>
                            </Grid>
                        </Grid>

                        <Grid item md={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Grid sx={{ width: '100%' }} container columnSpacing={{ sm: 2, md: 4 }}>
                                <Grid item md={6} lg={4} sm={6}>
                                    <FieldLabelView label={'Icon'}>
                                        <ImagePlaceholder
                                            handleChange={(event) => {
                                                checkUnsaveChanges();
                                                setFieldValue('icon_file', event.target.files[0]);
                                                setFieldValue('icon', URL.createObjectURL(event.target.files[0]));
                                            }}
                                            image={values?.icon}
                                            backgroundColor={values.color}
                                            imageSize={{ height: 50, width: 50 }}
                                            boxDimensions={{ height: 80, width: 80, objectFit: 'contain' }}
                                            isAddIcon={values.icon ? false : true}
                                        />
                                    </FieldLabelView>
                                    {touched.icon && errors.icon && <FormHelperText error>{errors.icon}</FormHelperText>}
                                </Grid>
                                <Grid item md={6} lg={4} sm={6}>
                                    <FieldLabelView label={'Color'}>
                                        <CustomColorPicker
                                            component={<ColorBoxWidget backgroundColor={values.color} />}
                                            name={'color'}
                                            currentcolor={values.color}
                                            handleColorChange={(e) => {
                                                setFieldValue('color', e.hex);
                                                checkUnsaveChanges();
                                            }}
                                        />
                                    </FieldLabelView>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item md={12} sx={{ display: 'flex', mt: 2 }}>
                            <Grid container>
                                <Grid item sm={6} xs={12} md={6}>
                                    <FieldView>
                                        <FieldLabel>Client Side Status:</FieldLabel>
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            onChange={(e) => {
                                                setFieldValue('client_side_status', e.target.checked);
                                                checkUnsaveChanges();
                                            }}
                                            checked={values.client_side_status}
                                        />
                                    </FieldView>
                                </Grid>

                                <Grid item sm={6} xs={12} md={6}>
                                    {id ? (
                                        <FieldView sx={{ justifyContent: { md: 'flex-end', xs: 'flex-start', sm: 'flex-end' } }}>
                                            <FieldLabel>Curate Playlist:</FieldLabel>
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                onChange={(e) => {
                                                    setFieldValue('curate_playlist', e.target.checked);
                                                    checkUnsaveChanges();
                                                }}
                                                checked={values.curate_playlist}
                                            />
                                        </FieldView>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </FormBox>
            )}

            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={handleDelete}
                    title={'Delete Category'}
                    description={'Are you sure you want to delete this Category?'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
