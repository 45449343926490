import { Box, Grid, styled, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { CommonInput } from '../../../components/CommonInput';
import { COLORS } from '../../../utils/theme';
import { CommonMultiSelect } from '../../../components/CommonMultiSelect';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import { ICONS } from '../../../assets';
import { CollectionCardWidgets } from '../../../widgets/CollectionCardWidgets';
import { useNavigate, useParams } from 'react-router-dom';
import { curationProfilesValidation } from '../../../utils/validations';
import { useSnackbar } from 'notistack';
import { api } from '../../../api';
import useFetch from '../../../hooks/useFetch';
import { ORIENTATION, SkeletonArray } from '../../../utils/constants';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import Loader from '../../../components/Loader';
import FieldLabelView from '../../../components/FieldLabelView';
import NoDataFound from '../../../components/NoDataFound';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../redux/reducers/accountTypesSlice';

const sortCategoryList = (data) => {
    let sortData = data.map((item) => {
        return { id: item.id, name: item.name, value: item.id };
    });

    return sortData;
};

const FormBox = styled(Box)({
    width: '100%',
    padding: 22,
    backgroundColor: COLORS.white,
    marginTop: 24,
    borderRadius: '.425rem',
    border: `1px solid ${COLORS.border}`
});

const GridBox = styled(Grid)({
    padding: '16px 0'
});

const FieldLabel = styled(Typography)({
    fontSize: 14,
    fontWeight: 600,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 4
});

const StyledInput = styled(CommonInput)({
    flex: 1
});

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
});

const FilterCheckboxContainer = styled(Box)({
    display: 'flex',
    marginTop: '1rem',
    justifyContent: 'start',
    alignItems: 'center'
});

export const AddEditCurationProfile = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const dispatch = useDispatch();

    const { data, isLoading } = useFetch({
        initialUrl: `/curation_profile/${id}`,
        skipOnStart: id ? false : true
    });
    const EditData = data?.result;
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({
        video: 1,
        collection: 1
    });

    const [showDeletePopup, setDeletePopup] = useState(false);
    const { territory, Specialty } = useSelector(({ accountTypes }) => accountTypes);

    const { data: categoryData } = useFetch({
        initialUrl: '/get_all_category'
    });

    const categoryList = sortCategoryList(categoryData?.result || []);

    const {
        data: videoData,
        isLoading: isMediaLoading,
        callFetch: fetchVideosByCategory
    } = useFetch({
        initialUrl: `/get_all_media`,
        config: { params },
        skipOnStart: true
    });

    const videos =
        useMemo(() => {
            if (videoData?.result) {
                return videoData?.result;
            }
        }, [videoData]) || [];

    const { handleBlur, handleChange, values, touched, errors, setFieldValue, handleSubmit, setValues, setTouched } = useFormik({
        initialValues: {
            name: '',
            category_id: '',
            curate_playlist: false,
            search: '',
            unSavedChange: false,
            contents: [],
            territory_status: false,
            territory_id: '',
            speciality_status: false,
            speciality_id: ''
        },
        validationSchema: curationProfilesValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);

                let params = {};
                params['name'] = values.name;
                params['playlist_id'] = values.category_id;

                if (values.territory_status) {
                    params['territory_id'] = values.territory_id;
                }
                if (values.speciality_status) {
                    params['speciality_id'] = values.speciality_id;
                }
                if (Boolean(categoryData?.result.find((item) => item.id === values.category_id)?.curate_playlist)) {
                    params['contents'] = JSON.stringify(values.contents);
                }
                let result;
                if (id) {
                    const { data } = await api.curationProfiles.updateCurationProfileById({ params, id });
                    result = data;
                } else {
                    const { data } = await api.curationProfiles.addCurationProfile(params);
                    result = data;
                }
                if (result.success) {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error?.message, { variant: 'error' });
            }
        }
    });

    const handleCheckBoxValue = (e) => {
        const { checked, name } = e.target;
        setParams({
            ...params,
            [name]: Number(checked)
        });
    };

    const curate_playlist = useMemo(() => {
        if (categoryData?.result?.length) {
            return Boolean(categoryData?.result.find((item) => item.id === values.category_id)?.curate_playlist);
        }
    }, [categoryData?.result, values?.category_id]);

    useEffect(() => {
        if (curate_playlist) {
            fetchVideosByCategory({
                params: {
                    ...params,
                    search: values.search,
                    category_id: values?.category_id,
                    media_by_admin: true
                }
            });
        }
    }, [fetchVideosByCategory, params, values.search, curate_playlist, values?.category_id]);

    useEffect(() => {
        if (EditData) {
            setValues({
                name: EditData?.name,
                contents: EditData?.curation_profile_contents?.map((item) => item.content_id),
                category_id: EditData?.playlist_id,
                territory_id: EditData?.territory_id ? EditData?.territory_id : '',
                speciality_id: EditData?.speciality_id ? EditData?.speciality_id : '',
                territory_status: EditData?.territory_id ? true : false,
                speciality_status: EditData?.speciality_id ? true : false
            });
        }
    }, [EditData, setValues]);

    const handleDelete = async () => {
        try {
            const { data } = await api.category.delete(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const handleToggleMedia = async (item) => {
        checkUnsaveChanges();
        const index = values.contents.findIndex((e) => e === item.id);
        let currentVideos = [...values.contents];
        if (index === -1) {
            currentVideos.push(item.id);
        } else {
            currentVideos.splice(index, 1);
        }
        setFieldValue('contents', currentVideos);
    };
    const checkUnsaveChanges = () => {
        id && !values.unSavedChange && setFieldValue('unSavedChange', true);
    };

    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };

    useEffect(() => {
        dispatch(fetchAccountTypeTerritory());
        dispatch(fetchAccountTypeSpecialty());
    }, [dispatch]);

    return (
        <Box>
            <CommonHeader
                title={'Curation Profile'}
                buttonLabel={'Save'}
                backTo={'Curation'}
                breadcrumbText={id ? 'Edit' : 'Add'}
                unsavedChanges={values.unSavedChange}
                onClick={handleSubmit}
                inEdit={id ? true : false}
                onClose={() => navigate(-1)}
                onDelete={() => setDeletePopup(true)}
                loading={loading}
                isXSset6={true}
            />
            {isLoading && <Loader isLoading={isLoading} />}
            {!isLoading && (
                <FormBox>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}>
                        <Grid item sm={9} xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12} md={12}>
                                    <FieldLabelView label={'Name'}>
                                        <StyledInput
                                            handleChange={checkHandlechange}
                                            handleBlur={handleBlur}
                                            error={touched.name && errors.name}
                                            name={'name'}
                                            value={values.name}
                                        />
                                    </FieldLabelView>
                                </Grid>

                                <Grid item sm={12} xs={12} md={6} lg={6} xl={4}>
                                    <FieldLabelView label={'Category'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={(data) => {
                                                checkUnsaveChanges();
                                                setFieldValue('contents', []);
                                                handleChange(data);
                                            }}
                                            options={categoryList}
                                            error={touched.category_id && errors.category_id}
                                            name={'category_id'}
                                            value={values.category_id}
                                        />
                                    </FieldLabelView>
                                </Grid>

                                <Grid item sm={12} xs={12} md={12} container>
                                    <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Grid item sm={6} xs={12} md={6} lg={6}>
                                            <FieldView sx={{ justifyContent: 'start' }} mt={1}>
                                                <FieldLabel>Territory:</FieldLabel>
                                                <IOSSwitch
                                                    sx={{ mx: 1 }}
                                                    checked={values?.territory_status}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setFieldValue('territory_id', '');
                                                            setTouched({ ...touched, ['territory_id']: false });
                                                        }
                                                        checkUnsaveChanges();
                                                        setFieldValue('territory_status', e.target.checked);
                                                    }}
                                                />
                                            </FieldView>
                                            {values?.territory_status && (
                                                <FieldLabelView label={''}>
                                                    <CommonMultiSelect
                                                        name={'territory_id'}
                                                        error={touched.territory_id && errors.territory_id}
                                                        value={values.territory_id}
                                                        handleChange={checkHandlechange}
                                                        handleBlur={handleBlur}
                                                        options={territory?.map((cv) => {
                                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                                        })}
                                                    />
                                                </FieldLabelView>
                                            )}
                                        </Grid>
                                        <Grid item sm={6} xs={12} md={6} lg={6}>
                                            <FieldView sx={{ justifyContent: 'start' }} mt={1}>
                                                <FieldLabel>Specialty:</FieldLabel>
                                                <IOSSwitch
                                                    sx={{ mx: 1 }}
                                                    checked={values?.speciality_status}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setFieldValue('speciality_id', '');
                                                            setTouched({ ...touched, ['speciality_id']: false });
                                                        }
                                                        checkUnsaveChanges();
                                                        setFieldValue('speciality_status', e.target.checked);
                                                    }}
                                                />
                                            </FieldView>
                                            {values?.speciality_status && (
                                                <FieldLabelView label={''}>
                                                    <CommonMultiSelect
                                                        name={'speciality_id'}
                                                        error={touched.speciality_id && errors.speciality_id}
                                                        value={values.speciality_id}
                                                        handleChange={checkHandlechange}
                                                        handleBlur={handleBlur}
                                                        options={Specialty?.map((cv) => {
                                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                                        })}
                                                    />
                                                </FieldLabelView>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        {curate_playlist ? (
                            <Grid item md={12} xs={12}>
                                <FieldLabel sx={{ marginBottom: 2 }}>Selections</FieldLabel>
                                <CommonInput
                                    name={'search'}
                                    label={'Search...'}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    endAdornment={<ICONS.Search />}
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                </FormBox>
            )}

            {curate_playlist ? (
                <>
                    {curate_playlist ? (
                        <FilterCheckboxContainer>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', mr: 2, fontSize: 14 }}>
                                <Checkbox
                                    sx={{ padding: '4px' }}
                                    size="small"
                                    name="video"
                                    onChange={(e) => handleCheckBoxValue(e)}
                                    checked={Boolean(params.video)}
                                />
                                Videos
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', fontSize: 14 }}>
                                <Checkbox
                                    sx={{ padding: '4px' }}
                                    size="small"
                                    name="collection"
                                    onChange={(e) => handleCheckBoxValue(e)}
                                    checked={Boolean(params.collection)}
                                />
                                Collections
                            </Box>
                        </FilterCheckboxContainer>
                    ) : null}
                    <GridBox container spacing={2}>
                        {isMediaLoading ? (
                            [...SkeletonArray, 5, 6].map((skeleton) => {
                                return (
                                    <Grid key={skeleton} item xs={12} sm={6} md={4} lg={3} xl={2}>
                                        <CollectionCardWidgets
                                            orientation={ORIENTATION.LANDSCAPE}
                                            loading={isMediaLoading}
                                            hasStatus={true}
                                            hideToggleSwitch={true}
                                        />
                                    </Grid>
                                );
                            })
                        ) : (
                            <React.Fragment>
                                {videos.length ? (
                                    videos?.map?.((item) => {
                                        return (
                                            <Grid
                                                item
                                                key={item?.data_type === 'collection' ? item?.collection_id : item?.video_id}
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                xl={2}
                                            >
                                                <CollectionCardWidgets
                                                    data={item}
                                                    handleChangeSwitch={handleToggleMedia}
                                                    value={values?.contents?.findIndex((e) => e === item.id) !== -1}
                                                />
                                            </Grid>
                                        );
                                    })
                                ) : (
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={'center'} alignItems={'center'}>
                                        {!isLoading && (
                                            <NoDataFound
                                                searchText={values.search}
                                                message={`No content found for ${values.name} category.`}
                                            />
                                        )}
                                    </Grid>
                                )}
                            </React.Fragment>
                        )}
                    </GridBox>
                </>
            ) : null}
            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={handleDelete}
                    title={'Delete Category'}
                    description={'Are you sure you want to delete this Category?'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
