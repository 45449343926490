import { Box, Skeleton, styled, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/Loader';
import useFetch from '../../../hooks/useFetch';
import { COLORS } from '../../../utils/theme';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { AlertDialog } from '../../../widgets/CommonWidgets';

const CreditContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginRight: '2rem'
});
const Text = styled(Typography)({
    fontWeight: 600,
    fontSize: 14
});
const CostBox = styled(Box)({
    padding: '5px 20px',
    border: `1px solid ${COLORS.border}`,
    borderRadius: 8
});

export const FormSubmissionPage = ({ fixHeight, ...props }) => {
    const [showResetPopup, setResetPopup] = useState(false);
    const [showClosePopup, setClosePopup] = useState(false);
    const { state } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: formData, isLoading } = useFetch({
        initialUrl: props.for !== 'feedback' ? 'get_form_by_id' : '/get_feedback_by_id',
        config: { params: { id: Number(id) } }
    });

    const renderJotForm = () => {
        let element = document.getElementById('source_code');
        element.style.height = `calc(100vh - 224px)`;
        element.innerHTML = formData?.result?.embeded_code;
        let iFrameEle = document.querySelector('iFrame');
        iFrameEle?.setAttribute('scrolling', 'yes');
        iFrameEle?.setAttribute('style', 'min-width:100%;height:inherit;max-width:100%;border:none;');
    };
    useEffect(() => {
        if (formData?.result?.embeded_code) {
            renderJotForm();
        }
        // eslint-disable-next-line
    }, [formData]);

    const handleRenderContainer = useCallback((isApply) => {
        const mainDiv = document.getElementById('main-container');
        mainDiv.style.height = isApply ? `calc(100vh - 68px)` : 'auto';
    }, []);

    useEffect(() => {
        fixHeight && handleRenderContainer(true);
        return () => handleRenderContainer(false);
    }, [fixHeight, handleRenderContainer]);

    const handleSubmit = () => { };
    const handleReset = () => {
        renderJotForm();
        setResetPopup(false);
    };
    const handleClose = () => {
        navigate(-1);
    };
    return (
        <Box>
            <CommonHeader
                title={state?.title}
                // buttonLabel={'Submit'}
                onClick={handleSubmit}
                inEdit={true}
                onClose={() => handleClose()}
                breadcrumbText={'Submission'}
                backTo={props.for !== 'feedback' ? 'Request' : 'Feedback'}
                onReset={() => setResetPopup(true)}
                isRequest={true}
            >
                {props.for !== 'feedback' && (
                    <CreditContainer>
                        <Text>Credit Cost:</Text>
                        <CostBox sx={{ mx: 1 }}>
                            {isLoading ? (
                                <Skeleton variant="text" width={30} />
                            ) : (
                                <Text sx={{ fontSize: 20 }}>{formData?.result && `${formData?.result?.cost}C`}</Text>
                            )}
                        </CostBox>
                    </CreditContainer>
                )}
            </CommonHeader>
            {isLoading ? (
                <Loader isLoading={isLoading} />
            ) : (
                <Box>
                    <div id="source_code"></div>
                </Box>
            )}
            {showResetPopup ? (
                <AlertDialog
                    isOpen={showResetPopup}
                    isClose={() => setResetPopup(false)}
                    handleSubmit={handleReset}
                    title={'Reset Form'}
                    description={'All data will be cleared from this form. Are you sure you want to reset?'}
                    isCancel={'No'}
                />
            ) : null}
            {showClosePopup ? (
                <AlertDialog
                    isOpen={showClosePopup}
                    isClose={() => setClosePopup(false)}
                    handleSubmit={handleClose}
                    title={'Close Form'}
                    description={'Are you sure you want to close this form without submitting?'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
