import { useState } from 'react';
import { Box, ClickAwayListener, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { ICONS } from '../../assets';
import InfoIcon from '@mui/icons-material/Info';
import { COLORS } from '../../utils/theme';
import { SERVER_URL } from '../../utils/constants';

const PlayListItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%'
});
const PlayListBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
});

const PlayListColorBox = styled(Box)({
    height: 42,
    width: 42,
    borderRadius: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const PlayListName = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    marginLeft: 12
});

const StyledInfoIcon = styled(InfoIcon)({
    color: COLORS.statusGray,
    marginRight: 15,
    cursor: 'pointer'
});
const Image = styled('img')({
    height: 25,
    width: 30,
    objectFit: 'contain'
});
const AddCircleRoundedIcon = styled(ICONS.AddCircleRoundedIcon)({
    color: COLORS.primary.success,
    fontSize: 25,
    cursor: 'pointer'
});
const RemoveCircleRoundedIcon = styled(ICONS.RemoveCircleRoundedIcon)({
    color: COLORS.primary.danger,
    fontSize: 25,
    cursor: 'pointer'
});

const PlaylistCategoryAdjustment = ({ item, addPlayList, onDeletePlayList, disableBlankPlaylist }) => {
    const [activeTooltip, setActiveTooltip] = useState();
    const closeTooltip = (item) => {
        if (item.id === activeTooltip) setActiveTooltip(null);
    };
    const isDisabled = disableBlankPlaylist && !item.library_content;
    const cursor = isDisabled ? 'not-allowed' : 'pointer';
    return (
        <Box
            sx={[{ display: 'flex', alignItems: 'center', padding: 1 }, isDisabled && { cursor: cursor, opacity: 0.4 }]}
            key={item.id}
        >
            <ClickAwayListener onClickAway={() => closeTooltip(item)}>
                <Tooltip
                    title={item.description}
                    open={item.id === activeTooltip}
                    onClose={() => closeTooltip(item)}
                    PopperProps={{
                        disablePortal: true
                    }}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                >
                    <StyledInfoIcon
                        onClick={() => {
                            setActiveTooltip(item.id);
                        }}
                    />
                </Tooltip>
            </ClickAwayListener>
            <PlayListItem>
                <PlayListBox
                    onClick={() => {
                        if (!isDisabled) addPlayList(item);
                    }}
                    sx={{ cursor: cursor }}
                >
                    <PlayListColorBox style={{ backgroundColor: item.color }}>
                        <Image src={`${SERVER_URL}${item?.icon}`} alt="" />
                    </PlayListColorBox>
                    <PlayListName>{item.name}</PlayListName>
                </PlayListBox>
            </PlayListItem>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: cursor }}>
                <IconButton onClick={() => addPlayList(item)} disabled={isDisabled}>
                    <AddCircleRoundedIcon />
                </IconButton>
                <IconButton
                    onClick={() => {
                        onDeletePlayList(item);
                    }}
                    disabled={isDisabled}
                >
                    <RemoveCircleRoundedIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default PlaylistCategoryAdjustment;
