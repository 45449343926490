import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, styled, useMediaQuery } from '@mui/material';

// project imports
import MenuList from './MenuList';
import { DRAWER_WIDTH, HEADER_HEIGHT } from '../../../utils/constants';
import { COLORS } from '../../../utils/theme';

const DrawerMainView = styled('div')({
    backgroundImage: `linear-gradient(${COLORS.primary.light}, ${COLORS.primary.dark})`,
    // backgroundColor: COLORS.white,
    height: '100%'
});

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const drawer = (
        <DrawerMainView>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}></Box>
            </Box>
            {matchUpSm ? (
                <Box sx={{ paddingTop: 2 }}>
                    <MenuList />
                </Box>
            ) : (
                <div
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : `calc(100vh - ${HEADER_HEIGHT}px)`
                    }}
                >
                    <MenuList />
                </div>
            )}
        </DrawerMainView>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? DRAWER_WIDTH : 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: DRAWER_WIDTH,
                        background: COLORS.primary.dark,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: HEADER_HEIGHT - 1
                        },
                        maxHeight: matchUpMd ? 'calc(100% - 67px)' : '100vh',
                        overflowY: 'scroll',
                        scrollbarWidth: 'none'
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    );
};

export default Sidebar;
