import { FormControlLabel, FormHelperText, Link, styled, Box, Radio, RadioGroup } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { api } from '../../../../api';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import { fetchClines } from '../../../../redux/reducers/clientsSlice';
import { fetchUsers } from '../../../../redux/reducers/userSlice';
import { DELETE_CONFIRMATION, SERVER_URL, UPDATE_CONFIRMATION, USER_ROLE } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';
import { userFormValidation } from '../../../../utils/validations';
import { UnsavedChanges, DeleteButton } from '../../../../widgets/CommonWidgets';
import ImagePlaceholder from '../../../../widgets/ImagePlaceholder';
import { PAGES } from '../../../../utils/urls';
import FieldLabelView from '../../../../components/FieldLabelView';
import CommonModal from '../../../../components/CommonModal';

const StyledInput = styled(CommonInput)({
    flex: 1
});
const AddButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10
});

const EditButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10
});
const StyledProgressView = styled(CircularProgress)({
    marginLeft: 8
});
const PasswordResetLink = styled(Link)(({ theme }) => ({
    color: COLORS.primaryBlue,
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
        fontSize: '15px'
    }
}));
const RightActionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});
const AddEditUser = ({ type, open, handleClose, userData }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { clients } = useSelector((state) => state.client);
    const [loading, setLoading] = useState({ submit: false, delete: false });

    const accountList = useMemo(() => {
        return clients.map((item) => {
            return {
                id: item.id,
                name: item.name,
                value: item.id
            };
        });
    }, [clients]);

    const {
        handleBlur,
        handleChange,
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
        validateForm,
    } = useFormik({
        initialValues: {
            name: '',
            email: '',
            role: 'manager',
            logo: null,
            accounts: [],
            resetPasswordLoading: false,
            logo_file: null,
            unSavedChange: false
        },
        validationSchema: userFormValidation,
        onSubmit: async (values) => {
            handleAddUser();
        }
    });
    const checkUnsaveChanges = () => {
        !values.unSavedChange && setFieldValue('unSavedChange', true);
    };
    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };
    const closeModal = () => {
        dispatch(fetchUsers());
        resetForm();
        handleClose();
    };
    useEffect(() => {
        dispatch(fetchClines());
        if (userData && Object.keys(userData).length !== 0) {
            let accounts = USER_ROLE?.ADMIN === userData?.role ? [] : userData?.clients?.map((item) => item.id);
            setValues({
                name: userData?.name,
                email: userData.email,
                role: userData.role,
                accounts,
                logo: SERVER_URL + userData.profile_photo
            });
        }
    }, [dispatch, userData, setValues]);

    const handleAddUser = async () => {
        try {
            let accounts = values.role === USER_ROLE.ADMIN ? [] : values.accounts;
            setLoading({ ...loading, submit: true });
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('email', values.email);
            // if (USER_ROLE?.ADMIN !== userData?.role) {
            formData.append('role', values.role);
            // }
            if (values.logo_file) {
                formData.append('logo', values.logo_file);
            }
            formData.append('clients', JSON.stringify(accounts));
            let result;
            if (userData) {
                formData.append('id', userData?.id);
                const { data } = await api.user.update(formData);
                result = data;
            } else {
                const { data } = await api.user.add(formData);
                result = data;
            }

            if (result.success) {
                enqueueSnackbar(result.message, { variant: 'success' });
            } else {
                enqueueSnackbar(result.message, { variant: 'error' });
            }
            setLoading({ ...loading, submit: false });
            closeModal();
        } catch (error) {
            setLoading({ ...loading, submit: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    const handleResetPassword = async () => {
        try {
            setFieldValue('resetPasswordLoading', true);
            const orgin = window.origin;
            const { data } = await api.auth.resetPassword({
                params: { email: values.email, resetUrl: `${orgin}${PAGES.NEW_PASSWORD.url}` }
            });

            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setFieldValue('resetPasswordLoading', false);
        } catch (error) {
            setFieldValue('resetPasswordLoading', false);
            enqueueSnackbar(error.message.message || error.message, { variant: 'error' });
        }
    };

    const handleDeleteUser = async () => {
        try {
            setLoading({ ...loading, delete: true });

            const { data } = await api.user.delete(userData.id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading({ ...loading, delete: false });
            closeModal();
        } catch (error) {
            setLoading({ ...loading, delete: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <CommonModal open={open} title={type === 'add' ? 'Add User' : 'Edit User'} width={500} onClose={closeModal}>
            <form onSubmit={handleSubmit}>
                <FieldLabelView label={'Name'}>
                    <StyledInput
                        handleChange={checkHandlechange}
                        handleBlur={handleBlur}
                        error={touched.name && errors.name}
                        name={'name'}
                        value={values?.name}
                        type={'name'}
                    />
                </FieldLabelView>
                <FieldLabelView label={'Email'} sx={{ mt: 2 }}>
                    <StyledInput
                        handleChange={checkHandlechange}
                        handleBlur={handleBlur}
                        error={touched.email && errors.email}
                        name={'email'}
                        value={values?.email}
                        type={'email'}
                    />
                    {type === 'edit' ? (
                        <Box>
                            <PasswordResetLink onClick={handleResetPassword}>Send password reset link</PasswordResetLink>
                            {values.resetPasswordLoading && <StyledProgressView size={15} />}
                        </Box>
                    ) : null}
                </FieldLabelView>

                <FieldLabelView sx={{ mt: 2 }} label={'Role'}>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="role"
                        value={values.role}
                        onChange={checkHandlechange}
                    >
                        <FormControlLabel value={USER_ROLE.MANAGER} control={<Radio />} label="Manager" />
                        <FormControlLabel value={USER_ROLE.VIEWER} control={<Radio />} label="Viewer" />
                        <FormControlLabel value={USER_ROLE.ADMIN} control={<Radio />} label="Admin" />
                    </RadioGroup>
                    {touched.role && errors.role && <FormHelperText error>{errors.role}</FormHelperText>}
                </FieldLabelView>

                <FieldLabelView label={'Profile'} sx={{ mt: 2 }}>
                    <ImagePlaceholder
                        handleChange={(event) => {
                            checkUnsaveChanges();
                            setFieldValue('logo_file', event.target.files[0]);
                            setFieldValue('logo', URL.createObjectURL(event.target.files[0]));
                        }}
                        image={values.logo}
                        isAddIcon={values.logo ? false : true}
                        boxDimensions={{ height: 80, width: 80 }}
                        addIconDimension={{ height: 30, width: 30 }}
                    />
                    {touched.logo && errors.logo && <FormHelperText error>{errors.logo}</FormHelperText>}
                </FieldLabelView>
                {values.role !== USER_ROLE.ADMIN && (
                    <FieldLabelView sx={{ mt: 2, paddingBottom: 3 }} label={'Accounts'}>
                        <CommonMultiSelect
                            outlineInputClass={{ top: 0 }}
                            multiple={true}
                            disabled={values.role === USER_ROLE.ADMIN}
                            handleBlur={handleBlur}
                            handleChange={checkHandlechange}
                            options={accountList}
                            error={touched.accounts && errors.accounts}
                            name={'accounts'}
                            value={values.accounts}
                            style={{ height: 'auto' }}
                        />
                    </FieldLabelView>
                )}
                {type === 'add' ? (
                    <AddButtonContainer>
                        <CommonButton
                            key="create-Button"
                            id="create-Button"
                            onClick={handleSubmit}
                            loading={loading.submit}
                            variant="contained"
                            label={'Create'}
                            title={'User'}
                            type={'submit'}
                        />
                    </AddButtonContainer>
                ) : (
                    <EditButtonContainer>
                        {values.unSavedChange && <UnsavedChanges />}
                        <DeleteButton
                            sx={{ ml: 1 }}
                            onClick={handleDeleteUser}
                            loading={loading.delete}
                            confirmation={DELETE_CONFIRMATION}
                            label={'Delete User'}
                            section="User"
                            title={'User'}
                        />
                        <RightActionContainer>
                            <CommonButton
                                sx={{ ml: 2 }}
                                key="Update-Button"
                                id="Update-Button"
                                confirmation={UPDATE_CONFIRMATION}
                                validateForm={validateForm}
                                onClick={handleSubmit}
                                loading={loading.submit}
                                variant="contained"
                                label={'Update'}
                                title={'User'}
                            // type={'submit'}
                            />
                        </RightActionContainer>
                    </EditButtonContainer>
                )}
            </form>
        </CommonModal>
    );
};

export default AddEditUser;
