import { FormControlLabel, styled, Box, Checkbox } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../api';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import { DELETE_CONFIRMATION, UPDATE_CONFIRMATION } from '../../../../utils/constants';
import { versionFormValidation } from '../../../../utils/validations';
import { UnsavedChanges, DeleteButton } from '../../../../widgets/CommonWidgets';
import FieldLabelView from '../../../../components/FieldLabelView';
import CommonModal from '../../../../components/CommonModal';
import { fetchAllLocations } from '../../../../redux/reducers/locationsSlice';
import { fetchVersions } from '../../../../redux/reducers/versionSlice';

const StyledInput = styled(CommonInput)({
    flex: 1
});
const AddButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10
});

const EditButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10
});

const RightActionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});
const AddEditVersionModal = ({ type, open, handleClose, versionData }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const locations = useSelector(({ locations }) => locations?.locations)
    const [loading, setLoading] = useState({ submit: false, delete: false });

    const locationsList = useMemo(() => {
        return locations.map((item) => {
            return {
                id: item.id,
                name: item.name,
                value: item.id
            };
        });
    }, [locations]);

    const {
        handleBlur,
        handleChange,
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setValues,
        resetForm,
        validateForm,
    } = useFormik({
        initialValues: {
            version_name: '',
            isAllSelected: false,
            locations: [],
            unSavedChange: false
        },
        validationSchema: versionFormValidation,
        onSubmit: async (values) => {
            const payload = {
                name: values.version_name,
                all_location: values.isAllSelected,
                locations: values.locations
            }
            await handleAddVersion(payload);
        }
    });
    const checkUnsaveChanges = () => {
        !values.unSavedChange && setFieldValue('unSavedChange', true);
    };
    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };
    const closeModal = () => {
        dispatch(fetchVersions());
        resetForm();
        handleClose();
    };
    useEffect(() => {
        dispatch(fetchAllLocations());
        if (versionData && Object.keys(versionData).length !== 0) {
            setValues({...versionData});
        }
    }, [dispatch, versionData, setValues]);

    const handleAddVersion = async (payload) => {
        try {
            setLoading({ ...loading, submit: true });
            let result;
            if (versionData) {
                const { data } = await api.version.update(payload);
                result = data;
            } else {
                const { data } = await api.version.addNewVersion(payload);
                result = data;
            }

            if (result.success) {
                enqueueSnackbar(result.message, { variant: 'success' });
            } else {
                enqueueSnackbar(result.message, { variant: 'error' });
            }
            setLoading({ ...loading, submit: false });
            closeModal();
        } catch (error) {
            setLoading({ ...loading, submit: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };


    const handleDeleteUser = async () => {
        try {
            setLoading({ ...loading, delete: true });

            const { data } = await api.user.delete(versionData.id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading({ ...loading, delete: false });
            closeModal();
        } catch (error) {
            setLoading({ ...loading, delete: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <CommonModal open={open} title={type === 'add' ? 'Add New Version' : 'Edit Version'} width={500} onClose={closeModal}>
            <form onSubmit={handleSubmit}>
                <FieldLabelView sx={{ mt: 2 }} label={'Version Name'}>
                    <StyledInput
                        handleChange={checkHandlechange}
                        handleBlur={handleBlur}
                        error={touched.version_name && errors.version_name}
                        name={'version_name'}
                        value={values?.version_name}
                        type={'name'}
                    />
                </FieldLabelView>

                <FormControlLabel sx={{ mt: 2, marginLeft: 0 }} control={<Checkbox
                    name={'isAllSelected'}
                    onBlur={handleBlur}
                    onChange={checkHandlechange}
                    value={values.isAllSelected}
                    checked={values.isAllSelected}
                />} label="Push to all Locations" />

                {!values.isAllSelected && (
                    <FieldLabelView sx={{ mt: 2, paddingBottom: 3 }} label={'Locations'}>
                        <CommonMultiSelect
                            multiple={true}
                            disabled={values.isAllSelected}
                            handleBlur={handleBlur}
                            handleChange={checkHandlechange}
                            options={locationsList}
                            error={touched.locations && errors.locations}
                            name={'locations'}
                            searchableMultiselect
                            value={values.locations}
                            style={{ height: 'auto' }}
                        />
                    </FieldLabelView>
                )}
                {type === 'add' ? (
                    <AddButtonContainer>
                        <CommonButton
                            key="create-Button"
                            id="create-Button"
                            onClick={handleSubmit}
                            loading={loading.submit}
                            variant="contained"
                            label={'Create'}
                            title={'User'}
                            type={'submit'}
                        />
                    </AddButtonContainer>
                ) : (
                    <EditButtonContainer>
                        {values.unSavedChange && <UnsavedChanges />}
                        <DeleteButton
                            sx={{ ml: 1 }}
                            onClick={handleDeleteUser}
                            loading={loading.delete}
                            confirmation={DELETE_CONFIRMATION}
                            label={'Delete User'}
                            section="User"
                            title={'User'}
                        />
                        <RightActionContainer>
                            <CommonButton
                                sx={{ ml: 2 }}
                                key="Update-Button"
                                id="Update-Button"
                                confirmation={UPDATE_CONFIRMATION}
                                validateForm={validateForm}
                                onClick={handleSubmit}
                                loading={loading.submit}
                                variant="contained"
                                label={'Update'}
                                title={'User'}
                            // type={'submit'}
                            />
                        </RightActionContainer>
                    </EditButtonContainer>
                )}
            </form>
        </CommonModal>
    );
};

export default AddEditVersionModal;
