import { useSelector } from 'react-redux';
import { USER_ROLE } from '../../utils/constants';

export const ViewerPermission = ({ children }) => {
    const { user } = useSelector((state) => state.auth);
    if (user?.role === USER_ROLE.VIEWER) {
        return null;
    } else {
        return children;
    }
};
