import React, { useEffect, useState } from 'react';

// material-ui
import { Grid, Box, styled, Typography } from '@mui/material';
import { COLORS } from '../../../utils/theme';
import AddEditUserModal from './components/AddEditUserModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsers } from '../../../redux/reducers/userSlice';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { CommonTable } from '../../../components/Table';

const AccountName = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});

const Users = () => {
    const [modal, setModal] = useState({ isOpen: false, type: '', data: {} });
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { users } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchUsers()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const NameElement = ({ item }) => {
        return <AccountName>{item.email}</AccountName>;
    };
    const usersColumns = [
        {
            id: 'email',
            fieldName: 'email',
            label: 'Email',
            render: (row) => {
                return <NameElement item={row.rowData} />;
            }
        },
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            align: 'center',

            cellStyle: {
                width: 240
            }
        },
        {
            id: 'accounts',
            fieldName: 'accounts',
            label: 'Accounts',
            align: 'center',
            render: (row) => {
                return <span> {row.rowData?.clients?.length}</span>;
            },
            cellStyle: {
                width: 140
            }
        },
        {
            id: 'role',
            fieldName: 'role',
            label: 'Role',
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];
    return (
        <Box>
            <CommonHeader
                title={'Users'}
                searchbar={true}
                buttonLabel={'Add User'}
                searchValue={searchValue}
                handleSearch={(search) => setSearchValue(search)}
                onClick={() => setModal({ isOpen: true, type: 'add' })}
            />
            <Grid sx={{ marginTop: '24px' }} container>
                <CommonTable
                    search={searchValue}
                    handleRowDetail={(data) => setModal({ isOpen: true, type: 'edit', data })}
                    columns={usersColumns}
                    rows={users}
                    loading={isLoading}
                    searchableFields={['email', 'name']}
                />
            </Grid>
            <AddEditUserModal
                open={modal.isOpen}
                type={modal.type}
                userData={modal.data}
                handleClose={() => setModal({ isOpen: false, type: '', data: {} })}
            />
        </Box>
    );
};

export default Users;
