import { Box, FormHelperText, Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { CommonInput } from '../../../components/CommonInput';
import { COLORS } from '../../../utils/theme';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import ImagePlaceholder from '../../../widgets/ImagePlaceholder';
import { useNavigate, useParams } from 'react-router-dom';
import { feedbackFormValidation } from '../../../utils/validations';
import { useSnackbar } from 'notistack';
import { api } from '../../../api';
import useFetch from '../../../hooks/useFetch';
import { SERVER_URL } from '../../../utils/constants';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import Loader from '../../../components/Loader';
import FieldLabelView from '../../../components/FieldLabelView';
import { useSelector } from 'react-redux';
import Labels from './components/Labels';

const FormBox = styled(Box)({
    width: '100%',
    padding: 22,
    backgroundColor: COLORS.white,
    marginTop: 24,
    borderRadius: '.425rem',
    border: `1px solid ${COLORS.border}`
});

const FieldLabel = styled(Typography)({
    fontSize: 14,
    fontWeight: 600,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 4
});

const StyledInput = styled(CommonInput)({
    flex: 1
});

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
});

export const AddEditFeedbackForm = () => {
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const { currentCategory } = useSelector((state) => state.requestCategory);

    const { data, isLoading } = useFetch({
        initialUrl: `/get_feedback_by_id`,
        config: { params: { id } },
        skipOnStart: id ? false : true
    });

    const EditData = data?.result;

    const { handleBlur, handleChange, values, touched, errors, setFieldValue, handleSubmit, setValues } = useFormik({
        initialValues: {
            name: '',
            labels: [{ id: 1, label: '', color: '' }],
            status: 1,
            portrait_thumbnail: '',
            landscape_thumbnail: '',
            portrait_thumbnail_file: null,
            landscape_thumbnail_file: null,
            embeded_code: ''
        },
        validationSchema: feedbackFormValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('status', values.status);
                formData.append('embeded_code', values.embeded_code);
                formData.append('labels', JSON.stringify(values.labels));
                if (values.landscape_thumbnail_file) {
                    formData.append('landscape_thumbnail', values.landscape_thumbnail_file);
                }
                if (values.portrait_thumbnail_file) {
                    formData.append('portrait_thumbnail', values.portrait_thumbnail_file);
                }
                let result;
                if (id) {
                    formData.append('id', +id);
                    const { data } = await api.feedback.editForm(formData);
                    result = data;
                } else {
                    const { data } = await api.feedback.addForm(formData);
                    result = data;
                }
                if (result.success) {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    setLoading(false);
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error?.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        if (EditData) {
            setValues({
                name: EditData?.name,
                labels: EditData?.labels,
                status: EditData?.status,
                cost: EditData?.cost,
                embeded_code: EditData?.embeded_code,
                portrait_thumbnail: SERVER_URL + EditData.portrait_thumbnail,
                landscape_thumbnail: SERVER_URL + EditData.landscape_thumbnail
            });
        }
    }, [EditData, setValues]);

    const handleDelete = async () => {
        try {
            const { data } = await api.feedback.deleteForm(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const checkUnsaveChanges = () => {
        id && !values.unSavedChange && setFieldValue('unSavedChange', true);
    };

    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };
    return (
        <Box>
            <CommonHeader
                title={'Content Request Form'}
                buttonLabel={'Save'}
                backTo={currentCategory}
                breadcrumbText={id ? 'Edit' : 'Add'}
                unsavedChanges={values.unSavedChange}
                onClick={handleSubmit}
                inEdit={id ? true : false}
                onClose={() => navigate(-1)}
                loading={loading}
                isXSset6={true}
                onDelete={() => setDeletePopup(true)}
            />
            {isLoading && <Loader isLoading={isLoading} />}
            {!isLoading && (
                <FormBox>
                    <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5 }}>
                        <Grid item sm={9} xs={12} md={9}>
                            <Grid container spacing={2} md={10} justifyContent={'space-between'}>
                                <Grid item sm={8} xs={12} md={8}>
                                    <FieldLabelView label={'Name'}>
                                        <StyledInput
                                            handleChange={checkHandlechange}
                                            handleBlur={handleBlur}
                                            error={touched.name && errors.name}
                                            name={'name'}
                                            value={values.name}
                                        />
                                    </FieldLabelView>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{ mt: 1 }} justifyContent={'space-between'}>
                                <Grid item sm={8} xs={12} md={8}>
                                    <Labels onChange={(values) => setFieldValue('labels', values)} labelData={values.labels} />
                                </Grid>

                                <Grid item sm={4} xs={6} md={4}>
                                    <FieldView>
                                        <FieldLabel>Client Side Status:</FieldLabel>
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            onChange={(e) => {
                                                setFieldValue('status', +e.target.checked);
                                                id && setFieldValue('unSavedChange', true);
                                            }}
                                            checked={values.status}
                                        />
                                    </FieldView>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} md={10} justifyContent={'space-between'}>
                                <Grid item sm={8} xs={12} md={8}>
                                    <FieldLabelView label={'Embed Code'}>
                                        <StyledInput
                                            handleChange={checkHandlechange}
                                            handleBlur={handleBlur}
                                            error={touched.embeded_code && errors.embeded_code}
                                            name={'embeded_code'}
                                            value={values.embeded_code}
                                            multiline
                                            minRows={10}
                                            style={{ height: 'auto' }}
                                        />
                                    </FieldLabelView>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item sm={5} xs={12} md={3}>
                            <FieldLabelView label={'Thumbnails'}>
                                <Grid style={{ width: '100%' }} container spacing={1}>
                                    <Grid item sm={9} xs={9} md={9}>
                                        <ImagePlaceholder
                                            boxDimensions={{ height: 132, width: '100%' }}
                                            handleChange={(event) => {
                                                checkUnsaveChanges();
                                                setFieldValue('landscape_thumbnail_file', event.target.files[0]);
                                                setFieldValue('landscape_thumbnail', URL.createObjectURL(event.target.files[0]));
                                            }}
                                            image={values.landscape_thumbnail}
                                            isAddIcon={values.landscape_thumbnail ? false : true}
                                        />
                                        {touched.landscape_thumbnail && errors.landscape_thumbnail && (
                                            <FormHelperText error>{errors.landscape_thumbnail}</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sm={3} xs={3} md={3}>
                                        <ImagePlaceholder
                                            handleChange={(event) => {
                                                checkUnsaveChanges();
                                                setFieldValue('portrait_thumbnail_file', event.target.files[0]);
                                                setFieldValue('portrait_thumbnail', URL.createObjectURL(event.target.files[0]));
                                            }}
                                            image={values.portrait_thumbnail}
                                            isAddIcon={values.portrait_thumbnail ? false : true}
                                            boxDimensions={{ height: 132, width: '100%' }}
                                        />
                                        {touched.portrait_thumbnail && errors.portrait_thumbnail && (
                                            <FormHelperText error>{errors.portrait_thumbnail}</FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </FieldLabelView>
                        </Grid>
                    </Grid>
                </FormBox>
            )}
            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={handleDelete}
                    title={'Delete From'}
                    description={'Are you sure you want to delete this Form?'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
