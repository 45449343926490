import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchBackground = createAsyncThunk('get_background', async (params) => {
    const response = await api.background.get(params);
    return response.data.result;
});

const initialState = {
    backgrounds: [],
};

export const backgroundSlice = createSlice({
    name: 'background',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBackground.fulfilled, (state, action) => {
            state.backgrounds = action.payload;
        });
    }
});

export default backgroundSlice.reducer;
