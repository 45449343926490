import { Checkbox, Grid, Box, styled, Typography, FormHelperText } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ICONS } from '../../../../assets';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import { COLORS } from '../../../../utils/theme';
import { CommonStyle } from '../../../../utils/commonStyle';
import ImagePlaceholder from '../../../../widgets/ImagePlaceholder';
import { useFormik } from 'formik';
import { IOSSwitch } from '../../../../widgets/IOSSwitch';
import { DeleteButton } from '../../../../widgets/CommonWidgets';
import { useSnackbar } from 'notistack';
import useFetch from '../../../../hooks/useFetch';
import { useDispatch, useSelector } from 'react-redux';
import { getFileResolution, getVideoDuration, secondTohhmmss, sortCategoryList, splitTrimStringifyTags } from '../../../../utils/helpers';
import { api } from '../../../../api';
import { CONTENT_DATA_TYPE, DELETE_CONFIRMATION, SERVER_URL } from '../../../../utils/constants';
import { editVideoFormValidation } from '../../../../utils/validations';
import FieldLabelView from '../../../../components/FieldLabelView';
import CommonModal from '../../../../components/CommonModal';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../../redux/reducers/accountTypesSlice';

const MainBox = styled(Box)({
    margin: '0px 20px 10px 20px'
});
const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});
const FieldLabel = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 6,
    fontWeight: '600'
}));

const StyledInput = styled(CommonInput)(({ theme }) => ({
    flex: 1
}));
const StyledButton = styled(CommonButton)(({ theme }) => ({
    color: COLORS.black,
    fontWeight: 100,
    borderRadius: 0
}));

const VideoResolution = styled(Box)(({ theme }) => ({
    marginTop: '0px'
}));
const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between'
    }
}));
const Text = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    fontWeight: 600,
    marginRight: 5
});

const DurationCheckBox = styled(Checkbox)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginLeft: -16
    },
    color: COLORS.darkGray
}));

const DurationTextBox = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginLeft: '8px'
    }
}));

const DeleteButtonClick = styled(DeleteButton)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        marginRight: '30px'
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: '10px'
    }
}));
const TextBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const EditVideoModal = ({ open, handleClose, id, fileExist, paginate, setPaginate }) => {
    const [loading, setLoading] = useState({ submit: false, delete: false });
    const mediaDirectories = useSelector(({ video }) => video?.mediaDirectories);
    const { enqueueSnackbar } = useSnackbar();
    const [resolutions, setResolutions] = useState(['1920x1080', '1080x1920']);
    const [isEnabled, setDisabledOrNot] = useState({
        landscapeURLChecked: false,
        portraitURLChecked: false,
        landscapeURLCheckedHD: false,
        portraitURLCheckedHD: false,
        durationChecked: false
    });
    const { territory, Specialty } = useSelector(({ accountTypes }) => accountTypes);
    const dispatch = useDispatch();

    const { data } = useFetch({
        initialUrl: '/get_all_category'
    });
    const categoryList = data?.result && sortCategoryList(data?.result);

    const videoData = useFetch({
        initialUrl: `/get_media_by_id/?id=${id}`
    });
    const videoDetails = videoData?.data?.result;

    const handleCheckBoxValue = (e, name) => {
        let checked = { ...isEnabled, [name]: e.target.checked };
        setDisabledOrNot(checked);
    };

    const directories = useMemo(() => {
        return mediaDirectories?.map(({ id, mediaUrl }) => ({ id, name: mediaUrl, value: id }));
    }, [mediaDirectories]);

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, resetForm, setFieldValue, setValues, setTouched } = useFormik({
        initialValues: {
            name: '',
            category: '',
            duration: '',
            landscapeURL: '',
            landscapeURL_hd: '',
            portraitURL: '',
            portraitURL_hd: '',
            portrait_video: '',
            portrait_video_hd: '',
            landscape_video: '',
            landscape_video_hd: '',
            resolution: '',
            resolution_hd: '',
            client_status: videoDetails?.status ? videoDetails?.status : true,
            description: '',
            tags: [],
            portraitThumbnail: '',
            landscapeThumbnail: '',
            portraitThumbnail_file: '',
            landscapeThumbnail_file: '',
            uploaded_date: '',
            landscape_share_url: '',
            originalDuration: '',
            portrait_video_file: '',
            portrait_video_file_hd: '',
            landscape_video_file: '',
            landscape_video_file_hd: '',
            portrait_video_resolution: '',
            portrait_video_resolution_hd: '',
            landscape_video_resolution: '',
            landscape_video_resolution_hd: '',
            directory_hd: '',
            directoryName_hd: '',
            territory_status: true,
            territory_id: [],
            speciality_status: true,
            speciality_id: []
        },
        validationSchema: editVideoFormValidation,
        onSubmit: async (values) => {
            let tagsArray = splitTrimStringifyTags(values.tags);
            setLoading({ ...loading, submit: true });
            let result;
            try {
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('status', +values.client_status);
                formData.append('description', values.description);
                formData.append('tags', tagsArray);
                formData.append('duration', !isEnabled.durationChecked ? values.originalDuration : values.duration);
                formData.append('is_duration_updated', +isEnabled.durationChecked);
                formData.append('content_type', CONTENT_DATA_TYPE.VIDEO);
                if (values.landscape_video_file || values.portrait_video_file) {
                    formData.append(
                        'resolution',
                        `${values?.landscape_video_resolution || resolutions?.[0]}, ${
                            values?.portrait_video_resolution || resolutions?.[1]
                        }`
                    );
                }
                if (values.landscape_video_file_hd || values.portrait_video_file_hd) {
                    formData.append(
                        'resolution_hd',
                        `${values?.landscape_video_resolution_hd || resolutions?.[0]}, ${
                            values?.portrait_video_resolution_hd || resolutions?.[1]
                        }`
                    );
                }
                values.category && formData.append('category_id', values.category);
                formData.append('id', id);
                if (values.landscapeThumbnail_file) {
                    formData.append('landscape_thumbnail', values.landscapeThumbnail_file);
                }
                if (values.portraitThumbnail_file) {
                    formData.append('portrait_thumbnail', values.portraitThumbnail_file);
                }
                if (isEnabled.landscapeURLChecked) {
                    formData.append('landscape_host_video', values.landscapeURL);
                }
                if (isEnabled.landscapeURLCheckedHD) {
                    formData.append('landscape_host_video_hd', values.landscapeURL_hd);
                }
                if (isEnabled.portraitURLChecked) {
                    formData.append('portrait_host_video', values.portraitURL);
                }
                if (isEnabled.portraitURLCheckedHD) {
                    formData.append('portrait_host_video_hd', values.portraitURL_hd);
                }
                if (values.landscape_video_file) {
                    formData.append('landscape_video', values.landscape_video_file);
                }
                if (values.landscape_video_file_hd) {
                    formData.append('landscape_video_hd', values.landscape_video_file_hd);
                }
                if (values.portrait_video_file) {
                    formData.append('portrait_video', values.portrait_video_file);
                }
                if (values.portrait_video_file_hd) {
                    formData.append('portrait_video_hd', values.portrait_video_file_hd);
                }
                if (values.portrait_video_file_hd || values.landscape_video_file_hd) {
                    formData.append('media_server_hd', values.directory_hd);
                    formData.append('sub_folder_hd', values.directoryName_hd ? values.directoryName_hd : '');
                }
                formData.append('landscape_share_url', values.landscape_share_url);
                if (values.territory_status) {
                    formData.append('territory_id', JSON.stringify(values.territory_id));
                }
                if (values.speciality_status) {
                    formData.append('speciality_id', JSON.stringify(values.speciality_id));
                }
                const { data } = await api.mediaContent.edit(formData);
                result = data;
                if (result.success) {
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(result.message, { variant: 'success' });
                    setPaginate({ ...paginate, page: 1, isCleared: true });
                    handleCloseEvent();
                } else {
                    setLoading({ ...loading, submit: false });
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading({ ...loading, submit: false });
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    const getVideoDirectory = (data = [], value = '') => {
        const cloneData = data.find((val) => val.name === value);
        return cloneData ? cloneData?.value : null;
    };

    const fetchOriginalDuration = useCallback(
        async (videoDetails) => {
            let videoDurationData = await getVideoDuration(videoDetails?.landscape_video || videoDetails.landscape_video_hd);
            setFieldValue('originalDuration', secondTohhmmss(videoDurationData?.duration));
        },
        [setFieldValue]
    );
    useEffect(() => {
        let tagsArray = videoDetails?.tags?.map((item) => item?.tag_names?.name);
        if (videoDetails) {
            fetchOriginalDuration(videoDetails);
            setValues({
                ...values,
                name: videoDetails?.name,
                category: videoDetails?.category_id,
                duration: videoDetails?.duration,
                description: videoDetails?.description,
                directoryName_hd: videoDetails?.sub_folder_hd,
                directory_hd: getVideoDirectory(directories, videoDetails?.directory_hd),
                landscapeURL: videoDetails?.landscape_video,
                landscapeURL_hd: videoDetails?.landscape_video_hd,
                portraitURL: videoDetails?.portrait_video,
                portraitURL_hd: videoDetails?.portrait_video_hd,
                landscape_video: videoDetails?.landscape_video,
                landscape_video_hd: videoDetails?.landscape_video_hd,
                portrait_video: videoDetails?.portrait_video,
                portrait_video_hd: videoDetails?.portrait_video_hd,
                landscapeThumbnail: SERVER_URL + videoDetails?.landscape_thumbnail,
                portraitThumbnail: SERVER_URL + videoDetails?.portrait_thumbnail,
                uploaded_date: new Date(videoDetails?.uploaded_date).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                }),
                client_status: Boolean(videoDetails?.status),
                resolution: videoDetails?.resolution ? videoDetails?.resolution : '1920x1080, 1080x1920',
                resolution_hd: videoDetails?.resolution_hd ? videoDetails?.resolution_hd : '1920x1080, 1080x1920',
                tags: tagsArray.join(','),
                landscape_file_exist: fileExist?.landscape,
                landscape_file_exist_hd: fileExist?.landscape_hd,
                portrait_file_exist: fileExist?.portrait,
                portrait_file_exist_hd: fileExist?.portrait_hd,
                landscape_share_url: videoDetails?.landscape_share_url ? videoDetails?.landscape_share_url : '',
                territory_id: videoDetails?.account_type_terriotary_accesses?.length
                    ? videoDetails?.account_type_terriotary_accesses?.map((item) => item?.territory_id)
                    : [],
                speciality_id: videoDetails?.account_type_speciality_accesses?.length
                    ? videoDetails?.account_type_speciality_accesses?.map((item) => item?.speciality_id)
                    : [],
                territory_status: videoDetails?.account_type_terriotary_accesses?.length ? true : false,
                speciality_status: videoDetails?.account_type_speciality_accesses?.length ? true : false
            });
            setDisabledOrNot({ ...isEnabled, durationChecked: videoDetails?.is_duration_updated });
            setResolutions(videoDetails?.resolution?.split(',')?.map((val) => val?.trim()));
        }
    }, [videoDetails, fileExist]);

    const checkVideoExistInLocal = useCallback(async () => {
        if (values.landscapeURL) {
            let response = await api.video.checkVideoExist({ params: { url: values.landscapeURL } });
            setFieldValue('landscape_file_exist', response?.data?.result);
        }
        if (values.portraitURL) {
            let response = await api.video.checkVideoExist({ params: { url: values.portraitURL } });
            setFieldValue('portrait_file_exist', response?.data?.result);
        }
        if (values.landscapeURL_hd) {
            let response = await api.video.checkVideoExist({ params: { url: values.landscapeURL_hd } });
            setFieldValue('landscape_file_exist_hd', response?.data?.result);
        }
        if (values.portraitURL_hd) {
            let response = await api.video.checkVideoExist({ params: { url: values.portraitURL_hd } });
            setFieldValue('portrait_file_exist_hd', response?.data?.result);
        }
    }, [setFieldValue, values.landscapeURL, values.landscapeURL_hd, values.portraitURL, values.portraitURL_hd]);

    useEffect(() => {
        checkVideoExistInLocal();
    }, [checkVideoExistInLocal]);

    useEffect(() => {
        dispatch(fetchAccountTypeTerritory());
        dispatch(fetchAccountTypeSpecialty());
    }, [dispatch]);

    const handleCloseEvent = () => {
        if (handleClose) {
            resetForm();
            setLoading({ submit: false, delete: false });
            handleClose();
        }
    };
    const handleDelete = async () => {
        setLoading({ ...loading, delete: true });
        try {
            const { data } = await api.mediaContent.delete(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading({ ...loading, delete: false });
            setPaginate({ ...paginate, page: 1, isCleared: true });
            handleCloseEvent();
        } catch (error) {
            setLoading({ ...loading, delete: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    return (
        <CommonModal
            open={open}
            onClose={handleCloseEvent}
            title={'Edit Video'}
            style={{ maxWidth: 1200, maxHeight: 855 }}
            headerStyle={{ position: 'sticky', zIndex: 1, top: 0 }}
        >
            <form onSubmit={handleSubmit}>
                <MainBox>
                    <Grid container sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} spacing={4}>
                        <Grid item xs={12} sm={8} md={8} lg={8} container>
                            <Grid item xs={12} md={12}>
                                <Grid sx={{ width: '100%' }} container>
                                    <Grid item xs={12} sm={4}>
                                        <FieldLabelView label={'Thumbnails'}>
                                            <Grid style={{ width: '100%' }} container spacing={1}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid style={{ width: '100%' }} container spacing={1}>
                                                        <Grid item xs={8}>
                                                            <ImagePlaceholder
                                                                boxDimensions={{ height: 100, width: '100%' }}
                                                                handleChange={(event) => {
                                                                    setFieldValue('landscapeThumbnail_file', event.target.files[0]);
                                                                    setFieldValue(
                                                                        'landscapeThumbnail',
                                                                        URL.createObjectURL(event.target.files[0])
                                                                    );
                                                                }}
                                                                image={values?.landscapeThumbnail}
                                                                isAddIcon={values.landscapeThumbnail ? false : true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <ImagePlaceholder
                                                                boxDimensions={{ height: 100, width: '100%' }}
                                                                handleChange={(event) => {
                                                                    setFieldValue('portraitThumbnail_file', event.target.files[0]);
                                                                    setFieldValue(
                                                                        'portraitThumbnail',
                                                                        URL.createObjectURL(event.target.files[0])
                                                                    );
                                                                }}
                                                                image={values?.portraitThumbnail}
                                                                isAddIcon={values.portraitThumbnail ? false : true}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </FieldLabelView>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FieldLabelView label={'4K Video Files'}>
                                            <Grid style={{ width: '100%' }} container spacing={1}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid style={{ width: '100%' }} container spacing={1}>
                                                        <Grid item xs={8}>
                                                            <ImagePlaceholder
                                                                handleChange={async (event) => {
                                                                    let fileInfo = await getFileResolution(
                                                                        URL.createObjectURL(event.target.files[0]),
                                                                        event.target.files[0].name,
                                                                        'video/mp4'
                                                                    );
                                                                    setFieldValue(
                                                                        'landscape_video_resolution',
                                                                        `${fileInfo.width}x${fileInfo.height}`
                                                                    );
                                                                    setFieldValue(
                                                                        'landscape_video',
                                                                        URL.createObjectURL(event.target.files[0])
                                                                    );
                                                                    setFieldValue('landscape_video_file', event.target.files[0]);
                                                                }}
                                                                accept="video/mp4"
                                                                boxDimensions={{ height: 100, width: '100%' }}
                                                                image={values.landscape_video}
                                                                isAddIcon={values.landscape_video ? false : true}
                                                            />
                                                            {touched.landscape_video && errors.landscape_video && (
                                                                <FormHelperText error>{errors.landscape_video}</FormHelperText>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <ImagePlaceholder
                                                                handleChange={async (event) => {
                                                                    let fileInfo = await getFileResolution(
                                                                        URL.createObjectURL(event.target.files[0]),
                                                                        event.target.files[0].name,
                                                                        'video/mp4'
                                                                    );
                                                                    setFieldValue(
                                                                        'portrait_video_resolution',
                                                                        `${fileInfo.width}x${fileInfo.height}`
                                                                    );
                                                                    setFieldValue(
                                                                        'portrait_video',
                                                                        URL.createObjectURL(event.target.files[0])
                                                                    );
                                                                    setFieldValue('portrait_video_file', event.target.files[0]);
                                                                }}
                                                                accept="video/mp4"
                                                                image={values.portrait_video}
                                                                isAddIcon={values.portrait_video ? false : true}
                                                                boxDimensions={{ height: 100, width: '100%' }}
                                                            />
                                                            {touched.portrait_video && errors.portrait_video && (
                                                                <FormHelperText error>{errors.portrait_video}</FormHelperText>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </FieldLabelView>
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <FieldLabelView label={'HD Video Files'}>
                                            <Grid style={{ width: '100%' }} container spacing={1}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <Grid style={{ width: '100%' }} container spacing={1}>
                                                        <Grid item xs={8}>
                                                            <ImagePlaceholder
                                                                handleChange={async (event) => {
                                                                    let fileInfo = await getFileResolution(
                                                                        URL.createObjectURL(event.target.files[0]),
                                                                        event.target.files[0].name,
                                                                        'video/mp4'
                                                                    );
                                                                    setFieldValue(
                                                                        'landscape_video_resolution_hd',
                                                                        `${fileInfo.width}x${fileInfo.height}`
                                                                    );
                                                                    setFieldValue(
                                                                        'landscape_video_hd',
                                                                        URL.createObjectURL(event.target.files[0])
                                                                    );
                                                                    setFieldValue('landscape_video_file_hd', event.target.files[0]);
                                                                }}
                                                                accept="video/mp4"
                                                                boxDimensions={{ height: 100, width: '100%' }}
                                                                image={values.landscape_video_hd}
                                                                isAddIcon={values.landscape_video_hd ? false : true}
                                                            />
                                                            {touched.landscape_video_hd && errors.landscape_video_hd && (
                                                                <FormHelperText error>{errors.landscape_video_hd}</FormHelperText>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <ImagePlaceholder
                                                                handleChange={async (event) => {
                                                                    let fileInfo = await getFileResolution(
                                                                        URL.createObjectURL(event.target.files[0]),
                                                                        event.target.files[0].name,
                                                                        'video/mp4'
                                                                    );
                                                                    setFieldValue(
                                                                        'portrait_video_resolution_hd',
                                                                        `${fileInfo.width}x${fileInfo.height}`
                                                                    );
                                                                    setFieldValue(
                                                                        'portrait_video_hd',
                                                                        URL.createObjectURL(event.target.files[0])
                                                                    );
                                                                    setFieldValue('portrait_video_file_hd', event.target.files[0]);
                                                                }}
                                                                accept="video/mp4"
                                                                image={values.portrait_video_hd}
                                                                isAddIcon={values.portrait_video_hd ? false : true}
                                                                boxDimensions={{ height: 100, width: '100%' }}
                                                            />
                                                            {touched.portrait_video_hd && errors.portrait_video_hd && (
                                                                <FormHelperText error>{errors.portrait_video_hd}</FormHelperText>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </FieldLabelView>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                                <Grid item xs={12} sm={12} md={7} container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FieldLabelView label={'Name'} sx={{ mt: 2 }}>
                                            <StyledInput
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                error={touched.name && errors.name}
                                                name={'name'}
                                                value={values.name}
                                            />
                                        </FieldLabelView>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <Grid container spacing={3} sx={{ alignItems: 'flex-end' }}>
                                            <Grid item xs={12} lg={5}>
                                                <FieldLabelView label={'Category'} sx={{ mt: 1 }}>
                                                    <CommonMultiSelect
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        options={categoryList}
                                                        error={touched.category && errors.category}
                                                        name={'category'}
                                                        value={values.category}
                                                    />
                                                </FieldLabelView>
                                            </Grid>
                                            <Grid container item xs={10} lg={7} sx={{ alignItems: 'flex-end' }}>
                                                <Grid item xs={1} sm={2} md={2}>
                                                    <DurationCheckBox
                                                        checked={isEnabled.durationChecked}
                                                        onChange={(e) => handleCheckBoxValue(e, 'durationChecked')}
                                                    />
                                                </Grid>
                                                <DurationTextBox item xs={10} sm={10} md={10}>
                                                    <FieldLabelView label={'Duration'} sx={{ mt: 1 }}>
                                                        <StyledInput
                                                            handleChange={handleChange}
                                                            handleBlur={handleBlur}
                                                            error={touched.duration && errors.duration}
                                                            name={'duration'}
                                                            disabled={!isEnabled.durationChecked}
                                                            value={!isEnabled.durationChecked ? values.originalDuration : values.duration}
                                                        />
                                                    </FieldLabelView>
                                                </DurationTextBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FieldView sx={{ mt: 1 }}>
                                            <FieldLabel>4K Host URL (Landscape)</FieldLabel>
                                            {values?.landscape_file_exist ? (
                                                <ICONS.CheckCircleIcon
                                                    variant="contained"
                                                    style={{ color: 'green', height: 20, width: 20 }}
                                                />
                                            ) : (
                                                <ICONS.CancelIcon
                                                    variant="contained"
                                                    style={{ color: COLORS.error, height: 20, width: 20 }}
                                                />
                                            )}
                                        </FieldView>
                                        <FieldView sx={{ mt: 1 }}>
                                            <Checkbox
                                                sx={{ padding: 0, marginLeft: -4, marginRight: 1, color: COLORS.darkGray }}
                                                checked={isEnabled.landscapeURLChecked}
                                                onChange={(e) => handleCheckBoxValue(e, 'landscapeURLChecked')}
                                            />
                                            <StyledInput
                                                style={{
                                                    border: values?.landscape_file_exist ? '1px solid green' : '1px solid red'
                                                }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                error={touched.landscapeURL && errors.landscapeURL}
                                                name={'landscapeURL'}
                                                value={values.landscapeURL}
                                                disabled={!isEnabled.landscapeURLChecked}
                                                urlInput
                                            />
                                        </FieldView>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FieldView sx={{ mt: 1 }}>
                                            <FieldLabel>4K Host URL (Portrait)</FieldLabel>
                                            {values?.portrait_file_exist ? (
                                                <ICONS.CheckCircleIcon
                                                    variant="contained"
                                                    style={{ color: 'green', height: 20, width: 20 }}
                                                />
                                            ) : (
                                                <ICONS.CancelIcon
                                                    variant="contained"
                                                    style={{ color: COLORS.error, height: 20, width: 20 }}
                                                />
                                            )}
                                        </FieldView>
                                        <FieldView sx={{ mt: 1 }}>
                                            <Checkbox
                                                sx={{ padding: 0, marginLeft: -4, marginRight: 1, color: COLORS.darkGray }}
                                                checked={isEnabled.portraitURLChecked}
                                                onChange={(e) => handleCheckBoxValue(e, 'portraitURLChecked')}
                                            />
                                            <StyledInput
                                                style={{
                                                    border: values?.portrait_file_exist ? '1px solid green' : '1px solid red'
                                                }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                error={touched.portraitURL && errors.portraitURL}
                                                name={'portraitURL'}
                                                value={values.portraitURL}
                                                disabled={!isEnabled.portraitURLChecked}
                                                urlInput
                                            />
                                        </FieldView>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FieldView sx={{ mt: 1 }}>
                                            <FieldLabel>HD Host URL (Landscape)</FieldLabel>
                                            {values?.landscape_file_exist_hd ? (
                                                <ICONS.CheckCircleIcon
                                                    variant="contained"
                                                    style={{ color: 'green', height: 20, width: 20 }}
                                                />
                                            ) : (
                                                <ICONS.CancelIcon
                                                    variant="contained"
                                                    style={{ color: COLORS.error, height: 20, width: 20 }}
                                                />
                                            )}
                                        </FieldView>
                                        <FieldView sx={{ mt: 1 }}>
                                            <Checkbox
                                                sx={{ padding: 0, marginLeft: -4, marginRight: 1, color: COLORS.darkGray }}
                                                checked={isEnabled.landscapeURLCheckedHD}
                                                onChange={(e) => handleCheckBoxValue(e, 'landscapeURLCheckedHD')}
                                            />
                                            <StyledInput
                                                style={{
                                                    border: values?.landscape_file_exist_hd ? '1px solid green' : '1px solid red'
                                                }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                error={touched.landscapeURL_hd && errors.landscapeURL_hd}
                                                name={'landscapeURL_hd'}
                                                value={values.landscapeURL_hd}
                                                disabled={!isEnabled.landscapeURLCheckedHD}
                                                urlInput
                                            />
                                        </FieldView>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FieldView sx={{ mt: 1 }}>
                                            <FieldLabel>HD Host URL (Portrait)</FieldLabel>
                                            {values?.portrait_file_exist_hd ? (
                                                <ICONS.CheckCircleIcon
                                                    variant="contained"
                                                    style={{ color: 'green', height: 20, width: 20 }}
                                                />
                                            ) : (
                                                <ICONS.CancelIcon
                                                    variant="contained"
                                                    style={{ color: COLORS.error, height: 20, width: 20 }}
                                                />
                                            )}
                                        </FieldView>
                                        <FieldView sx={{ mt: 1 }}>
                                            <Checkbox
                                                sx={{ padding: 0, marginLeft: -4, marginRight: 1, color: COLORS.darkGray }}
                                                checked={isEnabled.portraitURLCheckedHD}
                                                onChange={(e) => handleCheckBoxValue(e, 'portraitURLCheckedHD')}
                                            />
                                            <StyledInput
                                                style={{
                                                    border: values?.portrait_file_exist_hd ? '1px solid green' : '1px solid red'
                                                }}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                error={touched.portraitURL_hd && errors.portraitURL_hd}
                                                name={'portraitURL_hd'}
                                                value={values.portraitURL_hd}
                                                disabled={!isEnabled.portraitURLCheckedHD}
                                                urlInput
                                            />
                                        </FieldView>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <FieldLabelView label={'Share URL'} sx={{ mt: 1 }}>
                                            <StyledInput
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                error={touched.landscape_share_url && errors.landscape_share_url}
                                                name={'landscape_share_url'}
                                                value={values.landscape_share_url}
                                            />
                                        </FieldLabelView>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={5}>
                                    <FieldLabelView sx={{ mt: 2 }} label={'Directory'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            options={directories || []}
                                            error={touched.directory_hd && errors.directory_hd}
                                            name={'directory_hd'}
                                            value={values.directory_hd}
                                        />
                                    </FieldLabelView>
                                    <FieldLabelView label={'Sub Folder'} sx={{ mt: 2 }}>
                                        <StyledInput
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={touched.directoryName_hd && errors.directoryName_hd}
                                            name={'directoryName_hd'}
                                            value={values.directoryName_hd}
                                        />
                                    </FieldLabelView>

                                    <FieldLabelView label={'Description'} sx={{ mt: 1 }}>
                                        <StyledInput
                                            style={{ height: 'auto' }}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            error={touched.description && errors.description}
                                            name={'description'}
                                            value={values.description}
                                            multiline={true}
                                            minRows={5}
                                        />
                                    </FieldLabelView>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                            <VideoResolution>
                                <TextBox>
                                    <Text>Upload Date: </Text>
                                    <Text sx={{ fontWeight: 'normal !important' }}>{values?.uploaded_date}</Text>
                                </TextBox>
                                <TextBox sx={{ marginTop: '4px' }}>
                                    <Text>Resolution: </Text>
                                    <Text sx={{ fontWeight: 'normal  !important' }}>{values?.resolution}</Text>
                                </TextBox>
                                <FieldView sx={{ justifyContent: 'start' }}>
                                    <FieldLabel>Client Side Status:</FieldLabel>
                                    <IOSSwitch
                                        sx={{ margin: '12px 0px 8px 8px' }}
                                        checked={values?.client_status}
                                        onChange={(e) => {
                                            setFieldValue('client_status', e.target.checked);
                                        }}
                                    />
                                </FieldView>
                            </VideoResolution>

                            <FieldView sx={{ justifyContent: 'start' }}>
                                <FieldLabel>Territory:</FieldLabel>
                                <IOSSwitch
                                    sx={{ margin: '12px 0px 8px 8px' }}
                                    checked={values?.territory_status}
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            setFieldValue('territory_id', []);
                                            setTouched({ ...touched, ['territory_id']: false });
                                        }
                                        setFieldValue('territory_status', e.target.checked);
                                    }}
                                />
                            </FieldView>
                            {values?.territory_status && (
                                <FieldLabelView label={''}>
                                    <CommonMultiSelect
                                        name={'territory_id'}
                                        error={touched.territory_id && errors.territory_id}
                                        value={values.territory_id}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        options={territory?.map((cv) => {
                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                        })}
                                        multiple={true}
                                        style={{ height: 'auto' }}
                                        searchableMultiselect={false}
                                        checkbox={true}
                                    />
                                </FieldLabelView>
                            )}

                            <FieldView sx={{ justifyContent: 'start' }} mt={1}>
                                <FieldLabel>Specialty:</FieldLabel>
                                <IOSSwitch
                                    sx={{ margin: '12px 0px 8px 8px' }}
                                    checked={values?.speciality_status}
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            setFieldValue('speciality_id', []);
                                            setTouched({ ...touched, ['speciality_id']: false });
                                        }
                                        setFieldValue('speciality_status', e.target.checked);
                                    }}
                                />
                            </FieldView>
                            {values?.speciality_status && (
                                <FieldLabelView label={''}>
                                    <CommonMultiSelect
                                        name={'speciality_id'}
                                        error={touched.speciality_id && errors.speciality_id}
                                        value={values.speciality_id}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                        options={Specialty?.map((cv) => {
                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                        })}
                                        multiple={true}
                                        style={{ height: 'auto' }}
                                        searchableMultiselect={false}
                                        checkbox={true}
                                    />
                                </FieldLabelView>
                            )}
                            <FieldLabelView label={'Tags'} mt={2}>
                                <StyledInput
                                    style={{ height: 'auto' }}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    error={touched.tags && errors.tags}
                                    name={'tags'}
                                    value={values.tags}
                                    multiline={true}
                                    minRows={3}
                                />
                                <Typography sx={{ fontSize: 14 }}>Separate tags with a comma.</Typography>
                            </FieldLabelView>
                        </Grid>
                    </Grid>
                    <ButtonContainer>
                        <DeleteButtonClick
                            onClick={handleDelete}
                            loading={loading.delete}
                            confirmation={DELETE_CONFIRMATION}
                            label={'Delete Video'}
                            section={'Video'}
                            title={'Video'}
                        />
                        <StyledButton
                            loading={loading.submit}
                            onClick={handleSubmit}
                            variant="contained"
                            label={'Update'}
                            type={'submit'}
                        />
                    </ButtonContainer>
                </MainBox>
            </form>
        </CommonModal>
    );
};

export default EditVideoModal;
