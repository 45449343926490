import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchAllContents = createAsyncThunk('users/fetchAllContents', async (params) => {
    const response = await api.content.get(params);
    return response.data.result;
});

const initialState = {
    contents: []
};

export const contentSlice = createSlice({
    name: 'content',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAllContents.fulfilled, (state, action) => {
            state.contents = action.payload;
        });
    }
});

export default contentSlice.reducer;
