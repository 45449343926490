import React from 'react';
import { FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { COLORS } from '../../utils/theme';

export const CommonInput = ({
    label,
    handleBlur,
    handleChange,
    onKeyDown,
    error,
    value,
    name,
    type = 'text',
    endAdornment = null,
    id,
    multiline,
    style,
    placeholder,
    sx,
    minRows,
    startAdornment,
    urlInput,
    errorProps,
    ...rest
}) => {
    const theme = useTheme();
    return (
        <FormControl
            {...rest}
            fullWidth
            error={Boolean(error)}
            sx={!multiline ? { ...theme.typography.customInput, ...sx } : { ...theme.typography.customMulti, ...sx }}
        >
            {label ? <InputLabel>{label}</InputLabel> : null}
            <OutlinedInput
                id={id || name}
                type={type}
                value={value}
                name={name}
                onKeyPress={(e) => onKeyDown && onKeyDown(e)}
                onBlur={handleBlur && handleBlur}
                onChange={handleChange && handleChange}
                label={label}
                inputProps={{}}
                endAdornment={endAdornment}
                startAdornment={startAdornment}
                multiline={multiline}
                sx={{
                    ...style,
                    input: {
                        '&::placeholder': {
                            opacity: 0.8,
                            color: COLORS.darkShadesGray
                        }
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: urlInput ? COLORS.border : COLORS.primary.light,
                    },
                    '&:hover fieldset': {
                        borderColor: `${urlInput ? COLORS.border : COLORS.primary.light} !important`
                    }
                }}
                placeholder={placeholder}
                minRows={minRows}
                // onPast={() => false}
                // onDrop={() => false}
            />
            {error && <FormHelperText {...{sx: errorProps}} error>{error}</FormHelperText>}
        </FormControl>
    );
};
