import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchVideos = createAsyncThunk('get_videos', async ({isExec, ...params}) => {
    const response = await api.mediaContent.get({ params });
    return {
        data: response.data.result, count: response.data?.count || 0, isExec
    };
});

export const fetchAllMediaServers = createAsyncThunk('get_media_servers', async (params) => {
    const response = await api.mediaContent.getMediaServer({ params })
    return response.data.result;
})

export const confirmationUpload = createAsyncThunk('confirmationUpload', async (params) => {
    const response = await api.mediaContent.sendConfirmationMail({ params })
    return response.data.result;
})

const initialState = {
    videos: [],
    mediaDirectories: [],
    responseCount: 0,
    videoFilter: {},
    videosEnabled: false
};

export const videoSlice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        handleFilter: (state, action) => {
            return {
                ...state, videoFilter: {  ...action.payload } 
            }
        },
        clearFilterData: (state, action) => {
            return {
                ...state, videoFilter : { ...action.payload }
            }
        },
        handleVideosEnabled: (state) => {
            state.videosEnabled = true
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchVideos.fulfilled, (state, action) => {
            const { payload } = action
            return {
                ...state, videos: payload?.isExec ? [...payload.data] : [...state.videos, ...payload.data],
                responseCount: payload.data?.length || 0
            }
        });

        builder.addCase(fetchAllMediaServers.fulfilled, (state, action) => {
            state.mediaDirectories = action.payload;
        });
    }
});

export const { handleFilter, clearFilterData, handleVideosEnabled } = videoSlice.actions;
export default videoSlice.reducer;
