import { styled, Typography, Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import Loader from '../../../../components/Loader';
import { LoopViewWithDiv } from '../../../../components/LoopViewWithDiv/LoopViewWithDiv';
import { LOOP_CREATE_TYPE } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';
import { useNavigate } from 'react-router-dom';
import { PAGES } from '../../../../utils/urls';

const MainContainerGrid = styled(Grid)({
    position: 'relative'
});

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem',
    backgroundColor: COLORS.white
});

const LoopNameText = styled(Typography)({
    fontSize: 18,
    color: COLORS.black
});

const LoopTypeText = styled(Typography)({
    fontSize: 14,
    color: COLORS.primary.light
});

const TemplatesHeaderBox = styled(Box)({
    width: '100%',
    borderBottom: `1px solid ${COLORS.border}`,
    padding: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
});

const TemplatesHeaderTitleText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});

export const LoopChart = ({ selectedLocation, isLoading, data }) => {
    const [showRightSideBar, setShowRightSideBar] = useState(false);
    const theme = useTheme();
    const matchDowmMd = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const loopOptions = {
        OUTSIDE_BACKGROUND_WIDTH: matchDowmMd ? 280 : 350,
        MAIN_ROUNT_WIDTH: matchDowmMd ? 210 : 270,
        CHART_WIDTH: matchDowmMd ? 140 : 180,
        INSIDE_WHITE_WIDTH: matchDowmMd ? 120 : 160
    };

    const handleNavigationOnEdit = () => {
        const currentLoopData = data?.result;
        if (currentLoopData?.loop_type === LOOP_CREATE_TYPE.CUSTOM) {
            navigate(PAGES.CLIENT_LOOP_CUSTOM_EDIT.url + `/${currentLoopData.id}`, { state: { location_id: selectedLocation } });
        } else {
            navigate(PAGES.CLIENT_LOOP_TEMPLATE_EDIT.url + `/${currentLoopData.template_id}`, {
                state: { location_id: selectedLocation, template_id: currentLoopData.id }
            });
        }
    };

    return (
        <MainContainerGrid container>
            {(matchDowmMd ? !showRightSideBar : true) && (
                <Grid item xs={12} sm={12} md={12}>
                    <MainBox>
                        <TemplatesHeaderBox>
                            <TemplatesHeaderTitleText>Current Loop</TemplatesHeaderTitleText>
                        </TemplatesHeaderBox>
                        <LoopNameText sx={{ mt: 2 }}>{data?.result?.name}</LoopNameText>
                        <LoopTypeText>{data?.result?.is_primary ? 'PRIMARY' : null}</LoopTypeText>
                        <Box sx={{ width: '50%' }}>
                            <Box sx={{ mt: 2, width: '100%' }}>
                                {isLoading ? (
                                    <Loader isLoading={isLoading} />
                                ) : (
                                    <LoopViewWithDiv
                                        chartData={data?.result?.loop_categories?.map?.((item) => {
                                            return {
                                                color: item?.playlist?.color,
                                                value: 1,
                                                label: 'PL',
                                                name: item?.playlist?.name,
                                                id: item?.playlist?.id,
                                                icon: item?.playlist?.icon,
                                                loop_category_contents: item?.loop_category_contents,
                                                playback_method: item?.playback_method,
                                                mode: item?.mode
                                            };
                                        })}
                                        options={loopOptions}
                                        selectable={true}
                                        reset={false}
                                        showTooltip={true}
                                        canDeletePlaylist={false}
                                        edit={data?.result?.id ? true : false}
                                        accessValue={data?.result?.loop_type === 'template' ? 'templates' : data?.result?.loop_type}
                                        onEditClick={handleNavigationOnEdit}
                                    />
                                )}
                            </Box>
                        </Box>
                    </MainBox>
                </Grid>
            )}
        </MainContainerGrid>
    );
};
