import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from '../../../api';
import { Editor } from '../../../components/Editor';
import useFetch from '../../../hooks/useFetch';
import { fetchAddVideoToLibrary, fetchLibrary, fetchRemoveVideoToLibrary } from '../../../redux/reducers/librarySlice';
import { CONTENT_DATA_TYPE, CONTENT_MODULE_TYPE } from '../../../utils/constants';
import { checkContentStatusInLibrary, loadFont } from '../../../utils/helpers';
import useWindowSize from '../../../hooks/useWindow';

export const ContentEditor = () => {
    const [loading, setLoading] = useState(false);
    const { width, height } = useWindowSize();
    const { orientation } = useSelector((state) => state.orientation);
    const { client } = useSelector((state) => state.auth.user);
    const { librarys } = useSelector((state) => state.library);

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();

    useEffect(() => {
        loadFont();
        dispatch(fetchLibrary());
    }, [dispatch]);

    const { data: editorData } = useFetch({
        initialUrl: '/get_media_by_id',
        config: { params: { id: Number(id) } },
        skipOnStart: id ? false : true
    });
    const handleCustomContent = async (name, json, image, duration, libraryStatus) => {
        const baseImage = image?.split(',')[1];
        try {
            setLoading(true);
            let result;
            const formData = new FormData();

            if (id) {
                formData.append('name', name);
                formData.append('orientation', orientation);
                formData.append('duration', duration);
                formData.append('image_json', JSON.stringify(json));
                formData.append('image', baseImage);
                formData.append('id', id);
                formData.append('content_type', CONTENT_DATA_TYPE.IMAGE);
                formData.append('module_type', CONTENT_MODULE_TYPE.CUSTOM_CONTENT);

                const { data } = await api.mediaContent.edit(formData);
                result = data;
            } else {
                formData.append('name', name);
                formData.append('category_id', 18);
                formData.append('duration', duration);
                formData.append('orientation', orientation);
                formData.append('image_json', JSON.stringify(json));
                formData.append('image', baseImage);
                formData.append('client_id', client.id);
                formData.append('content_type', CONTENT_DATA_TYPE.IMAGE);
                formData.append('module_type', CONTENT_MODULE_TYPE.CUSTOM_CONTENT);
                const { data } = await api.mediaContent.add(formData);
                result = data;
            }

            if (result.success) {
                setLoading(false);
                enqueueSnackbar(result.message, { variant: 'success' });
                handleContentForLibrary(id ? editorData?.result : result.result, libraryStatus);
                // handleContentForLibrary(result.result,libraryStatus)
                navigate(-1);
            } else {
                setLoading(false);
                enqueueSnackbar(result.message, { variant: 'error' });
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const handleContentForLibrary = (data, libraryStatus) => {
        if (libraryStatus && !checkContentStatusInLibrary(data, librarys)) {
            dispatch(fetchAddVideoToLibrary([data]));
        } else if (!libraryStatus && checkContentStatusInLibrary(data, librarys)) {
            dispatch(fetchRemoveVideoToLibrary([data]));
        } else {
        }
    };

    return (
        <Box sx={{ overflow: 'auto' }}>
            {editorData?.result?.image_json && id && (
                <Editor
                    loading={loading} {...{width, height}}
                    onSave={handleCustomContent}
                    contentName={editorData?.result?.name}
                    initialData={editorData?.result?.image_json ? editorData?.result?.image_json : ''}
                    content={editorData?.result}
                    libraryStatus={checkContentStatusInLibrary(editorData?.result, librarys)}
                />
            )}
            {!id && <Editor loading={loading} {...{width, height}} onSave={handleCustomContent} libraryStatus={false} />}
        </Box>
    );
};
