import { IconButton, styled } from '@mui/material';
import { Box } from '@mui/system';
import { BACKGROUND_ACTION_TYPE, HEADER_MENU_TYPE, SERVER_URL, SHAPE_ACTION_TYPE, TEXT_ACTION_TYPE } from '../../../../utils/constants';
import { useRef, useState } from 'react';
import { BackgroundImagesPopup } from './component/BackgroundImagesPopup';
import UploadIcon from '@mui/icons-material/Upload';
import { FontsPopup } from './component/FontsPopup';
import { ShapePopup } from './component/ShapePopup';
// import { CustomColorPicker } from '../../../CustomColorPicker';
import { IMAGES } from '../../../../assets';
import { BackgroundColorPopup } from './component/BackgroundColorPopup';
import { BackgroundGradientColorPopup } from './component/BackgroundGradientColorPopup';
import CustomTooltip from '../../../../widgets/CustomTooltip';

const demoImage =
    'https://thumbs.dreamstime.com/b/summer-sunny-forest-trees-green-grass-nature-wood-sunlight-background-instant-toned-image-53353502.jpg';

const MainBox = styled(Box)({
    width: 58,
    paddingLeft: 6,
    height: '100%'
});

const ActionIconButton = styled(IconButton)({
    width: 58,
    height: 58
});

const GradientActionBox = styled(Box)({
    width: 32,
    height: 32,
    border: '3px solid black',
    borderRadius: 4,
    backgroundImage: 'linear-gradient(180deg, black, white)'
});

const ImageActionBox = styled(Box)({
    width: 32,
    height: 32,
    border: '3px solid black',
    borderRadius: 4,
    backgroundImage: `url(${demoImage})`
});

const ColorActionBox = styled(Box)({
    width: 32,
    height: 32,
    border: '3px solid black',
    borderRadius: 4,
    background: '#d8d8d8'
});

const ActionIconImage = styled('img')({
    height: 28,
    width: 28,
    objectFit: 'contain'
});

const Triangle = styled('div')({
    width: 0,
    height: 0,
    borderLeft: '16px solid transparent',
    borderRight: '16px solid transparent',
    borderBottom: '26px solid black'
})

const BACKGROUND_ACTION_LIST = [
    {
        id: 'plane',
        type: BACKGROUND_ACTION_TYPE.COLOR,
        Component: ColorActionBox,
        tooltip: 'Color background'
    },
    {
        id: 'gradient',
        type: BACKGROUND_ACTION_TYPE.GRADIENT_COLOR,
        tooltip: 'Gradient background',
        gradientColors: [
            {
                value: 0,
                color: 'black'
            },
            {
                value: 1,
                color: 'white'
            }
        ],
        Component: GradientActionBox
    },
    {
        id: 'background-image',
        type: BACKGROUND_ACTION_TYPE.IMAGE,
        Component: ImageActionBox,
        tooltip: 'Image background'
    }
];

const TEXT_ACTION_LIST = [
    {
        id: 'add-text-action',
        type: TEXT_ACTION_TYPE.ADD_TEXT,
        image: IMAGES.AddText,
        tooltip: 'Add text'
    }
    // {
    //     id: 'font-family',
    //     type: TEXT_ACTION_TYPE.FONT_FAMILY,
    //     image: IMAGES.Fonts
    // },
    // {
    //     id: 'align-left',
    //     type: TEXT_ACTION_TYPE.ALIGNMENT,
    //     value: 'left',
    //     image: IMAGES.LeftAlignIcon
    // },
    // {
    //     id: 'align-right',
    //     type: TEXT_ACTION_TYPE.ALIGNMENT,
    //     value: 'right',
    //     image: IMAGES.RightAlignIcon
    // },
    // {
    //     id: 'align-center',
    //     type: TEXT_ACTION_TYPE.ALIGNMENT,
    //     value: 'center',
    //     image: IMAGES.CenterAlignIcon
    // }
];

const SHAPE_ACTION_LIST = [
    {
        id: 'upload-image',
        type: SHAPE_ACTION_TYPE.UPLOAD_IMAGE,
        Component: UploadIcon,
        image: IMAGES.UPLOAD_ICON,
        tooltip: 'Upload image'
    },
    {
        id: 'shape-selector',
        type: SHAPE_ACTION_TYPE.SHAPE_SELECTOR,
        Component: Triangle,
        tooltip: 'Add shape'
    }
];

export const EditorRightActions = ({ actionHeaderItem, handleBackgroundChangeAction, handleChangeTextAction, handleChangeShapeAction, ...rest }) => {
    const fileInputRef = useRef();
    const [anchorElement, setAnchorElement] = useState({
        anchorImagePopup: null,
        anchorFontPopup: null,
        anchorShapePopup: null,
        anchorColorPopup: null,
        anchorGradientPopup: null
    })
    const [currentBackground, setCurrentBackground] = useState('#d8d8d8');

    const handleOpenPopup = (e, name) => {
        setAnchorElement({
            ...anchorElement, [name]: e?.currentTarget
        })
    }

    const handleClosePopup = (name) => {
        setAnchorElement({
            ...anchorElement, [name]: null
        })
    }

    const handleActionClick = (item, e) => {
        switch (item.type) {
            case BACKGROUND_ACTION_TYPE.IMAGE:
                return handleOpenPopup(e, 'anchorImagePopup');
            case BACKGROUND_ACTION_TYPE.COLOR:
                return handleOpenPopup(e, 'anchorColorPopup');
            case BACKGROUND_ACTION_TYPE.GRADIENT_COLOR:
                return handleOpenPopup(e, 'anchorGradientPopup');
            default: return handleBackgroundChangeAction(item);
        }
    };

    const handleTextHandleClick = (item, e) => {
        if (item.type === TEXT_ACTION_TYPE.FONT_FAMILY) {
            handleOpenPopup(e, 'anchorFontPopup');
        } else {
            handleChangeTextAction(item);
        }
    };

    const handleShapeHandleClick = (item, e) => {
        if (item.type === SHAPE_ACTION_TYPE.SHAPE_SELECTOR) {
            handleOpenPopup(e, 'anchorShapePopup');
        } else if (item.type === SHAPE_ACTION_TYPE.UPLOAD_IMAGE) {
            fileInputRef.current.click();
        }
    };

    const handleSelectImage = (item) => {
        handleClosePopup('anchorImagePopup');
        const actionData = {
            type: BACKGROUND_ACTION_TYPE.IMAGE,
            image: SERVER_URL + item.image
        };
        handleBackgroundChangeAction(actionData);
    };

    const handleSelectFontFamily = (item) => {
        handleClosePopup('anchorFontPopup');
        const actionData = {
            type: TEXT_ACTION_TYPE.FONT_FAMILY,
            font: item.value
        };
        handleChangeTextAction(actionData);
    };

    const handleSelectShape = (item) => {
        handleClosePopup('anchorShapePopup');
        const actionData = {
            type: SHAPE_ACTION_TYPE.SHAPE_SELECTOR,
            shape: item.value
        };
        handleChangeShapeAction(actionData);
    };

    const handleSelectBackgroundColor = (item) => {
        const actionData = {
            type: BACKGROUND_ACTION_TYPE.COLOR,
            color: item.value
        };
        setCurrentBackground(item.value);
        handleBackgroundChangeAction(actionData);
    };

    const handleSelectFile = (e) => {
        const file = e.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const actionData = {
                type: SHAPE_ACTION_TYPE.UPLOAD_IMAGE,
                image: reader.result
            };
            handleChangeShapeAction(actionData);
        };
        reader.onerror = function (error) { };
    };

    const BackgroundActionMenu = () => {
        return (
            <div>
                {BACKGROUND_ACTION_LIST.map(({ Component, ...item }) => {
                    return (

                        <ActionIconButton
                            id={item.id}
                            key={item.id}
                            onClick={(e) => handleActionClick(item, e)}
                            color="primary"
                            aria-label="upload picture"
                        >
                            <CustomTooltip arrow placement="right" title={item?.tooltip || ''} >
                                <Component sx={BACKGROUND_ACTION_TYPE.COLOR === item.type ? { background: currentBackground } : {}} />
                            </CustomTooltip>
                        </ActionIconButton>
                    );
                })
                }
            </div >
        );
    };

    const TextActionMenu = () => {
        return (
            <div>
                {TEXT_ACTION_LIST.map((item) => {
                    return (
                        <ActionIconButton
                            key={item.id}
                            onClick={(e) => handleTextHandleClick(item, e)}
                            color="primary"
                            aria-label="upload picture"
                        >
                            {/* <item.Component /> */}
                            {item.image ? <CustomTooltip arrow placement="right" title={item?.tooltip || ''} >
                                <ActionIconImage src={item.image} />
                            </CustomTooltip> : null}
                        </ActionIconButton>
                    );
                })}
            </div>
        );
    };

    const ShapeActionMenu = () => {
        return (
            <div>
                {SHAPE_ACTION_LIST.map(({ Component, ...item }) => {
                    return (
                        <ActionIconButton
                            key={item.id}
                            onClick={(e) => handleShapeHandleClick(item, e)}
                            color="primary"
                            aria-label="upload picture"
                        >
                            <CustomTooltip arrow placement="right" title={item?.tooltip || ''} >
                                {item.image ? <ActionIconImage src={item.image} /> : <Component />}
                            </CustomTooltip>
                        </ActionIconButton>
                    );
                })}
            </div>
        );
    };

    const ActionMenus = () => {
        switch (actionHeaderItem) {
            case HEADER_MENU_TYPE.BACKGROUND:
                return BackgroundActionMenu();
            case HEADER_MENU_TYPE.TEXT:
                return TextActionMenu();
            case HEADER_MENU_TYPE.SHAPE:
                return ShapeActionMenu();
            default:
                return null;
        }
    };

    return (
        <MainBox>
            {ActionMenus()}

            <BackgroundImagesPopup
                open={Boolean(anchorElement.anchorImagePopup)}
                anchorEl={anchorElement.anchorImagePopup}
                handleClose={() => handleClosePopup('anchorImagePopup')}
                id={Boolean(anchorElement.anchorImagePopup) ? 'image-popup' : null}
                handleSelectImage={handleSelectImage}
            />

            <FontsPopup
                open={Boolean(anchorElement.anchorFontPopup)}
                anchorEl={anchorElement.anchorFontPopup}
                handleClose={() => handleClosePopup('anchorFontPopup')}
                id={Boolean(anchorElement.anchorFontPopup) ? 'font-popup' : null}
                handleSelectFontFamily={handleSelectFontFamily}
            />

            <ShapePopup
                open={Boolean(anchorElement.anchorShapePopup)}
                anchorEl={anchorElement.anchorShapePopup}
                handleClose={() => handleClosePopup('anchorShapePopup')}
                id={Boolean(anchorElement.anchorShapePopup) ? 'shape-popup' : null}
                handleSelectShape={handleSelectShape}
            />

            <BackgroundColorPopup
                open={Boolean(anchorElement.anchorColorPopup)}
                anchorEl={anchorElement.anchorColorPopup}
                handleClose={() => handleClosePopup('anchorColorPopup')}
                id={Boolean(anchorElement.anchorColorPopup) ? 'background-popup' : null}
                handleSelectBackgrounds={handleSelectBackgroundColor}
            />

            <BackgroundGradientColorPopup
                open={Boolean(anchorElement.anchorGradientPopup)}
                anchorEl={anchorElement.anchorGradientPopup}
                handleClose={() => handleClosePopup('anchorGradientPopup')}
                id={Boolean(anchorElement.anchorGradientPopup) ? 'background-gradient-popup' : null}
                handleSelectBackgrounds={handleBackgroundChangeAction}
            />

            <input onChange={handleSelectFile} multiple={false} ref={fileInputRef} style={{ display: 'none' }} type={'file'} />
        </MainBox>
    );
};
