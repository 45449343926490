import React, { useMemo } from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// assets
import { COLORS } from '../../../utils/theme';
import { useNavigate } from 'react-router-dom';
import { PublicLayout } from '../../../layout/PublicLayout';
import { setHeader } from '../../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../../redux/reducers/authSlice';
import { SERVER_URL } from '../../../utils/constants';
import useFetch from '../../../hooks/useFetch';
import { sortByProperty } from '../../../utils/helpers';

const HRLine = styled(Box)({
    backgroundColor: COLORS.vividYellow,
    height: 5,
    width: '65%',
    marginBottom: '2vh'
});
const Text = styled(Typography)(({ theme }) => ({
    color: COLORS.white,
    textAlign: 'center',
    fontSize: 20,
    marginBottom: 30,
    padding: 10
}));

const FlexRowBox = styled(Box)(({ theme }) => ({
    width: '100%',
    flexWrap: 'wrap',
    maxWidth: '1280px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

const AccountBox = styled(Box)({
    cursor: 'pointer',
    margin: 8,
    padding: 6,
    backgroundColor: COLORS.white,
    height: 180,
    width: 180
});
const AccountSelection = () => {
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data: accountData } = useFetch({
        initialUrl: '/get_my_clients'
    });
    
    const accountsData = useMemo(() => {
        return sortByProperty(accountData?.result?.clients, 'name')
    }, [accountData?.result?.clients])

    const ImageBox = ({ source, data }) => {
        return (
            <AccountBox onClick={() => handleSelectAccount(data)} sx={{ backgroundColor: 'white' }}>
                <img src={source} alt={'DSID_Accounts'} loading="lazy" style={{ width: '180px', height: '180px', objectFit: 'fill' }} />
            </AccountBox>
        );
    };

    const handleSelectAccount = (item) => {
        setHeader('client_id', item?.id);
        dispatch(updateUser({ ...user, client: item, clientList: accountsData }));
        navigate('/');
    };
    return (
        <PublicLayout>
            <HRLine />
            <Text>Select which Account you want to manage.</Text>
            <FlexRowBox sx={{ flexDirection: { xs: 'column', sm: 'row', md: 'row' } }}>
                {accountsData &&
                    accountsData?.map?.((item) => {
                        return <ImageBox data={item} key={item.id} source={SERVER_URL + item.logo} />;
                    })}
            </FlexRowBox>
        </PublicLayout>
    );
};
export default AccountSelection;
