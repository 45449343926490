import React, { useEffect, useState } from 'react'
import CommonModal from '../../../components/CommonModal'
import { useFormik } from 'formik'
import { useTheme } from '@mui/material/styles';
import { userProfileValidation } from '../../../utils/validations'
import { CommonInput } from '../../../components/CommonInput';
import { Box, CircularProgress, Link, styled } from '@mui/material';
import FieldLabelView from '../../../components/FieldLabelView';
import { useDispatch, useSelector } from 'react-redux';
import { COLORS } from '../../../utils/theme';
import { api } from '../../../api';
import { PAGES } from '../../../utils/urls';
import { useSnackbar } from 'notistack';
import ImagePlaceholder from '../../../widgets/ImagePlaceholder';
import { SERVER_URL, UPDATE_CONFIRMATION } from '../../../utils/constants';
import CommonButton from '../../../components/CommonButton';
import { UnsavedChanges } from '../../../widgets/CommonWidgets';
import { fetchUserInfo } from '../../../redux/reducers/authSlice';

const StyledInput = styled(CommonInput)({
    flex: 1
});

const EditButtonContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 10
});

const RightActionContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
});

const PasswordResetLink = styled(Link)(({ theme }) => ({
    color: COLORS.primaryBlue,
    fontSize: 16,
    cursor: 'pointer'
}));

const StyledProgressView = styled(CircularProgress)({
    marginLeft: 8
});

const EditProfile = ({ open, type, closeModal }) => {
    const theme = useTheme();
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState({ submit: false, delete: false });
    const user = useSelector(({ auth }) => auth?.user)

    const { handleBlur,
        handleChange,
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setValues,
        validateForm } = useFormik({
            initialValues: {
                name: '',
                email: '',
                resetPasswordLoading: false,
                logo_file: null,
                logo: null,
                unSavedChange: false
            },
            validationSchema: userProfileValidation,
            onSubmit: async (values) => {
                await handleEditProfile()
            }
        })

    const handleEditProfile = async () => {
        setLoading({ ...loading, submit: true });
        try {
            const formData = new FormData();
            formData.append('id', user?.id);
            formData.append('name', values.name);
            formData.append('email', values.email);

            if (values.logo_file) {
                formData.append('logo', values.logo_file);
            }

            const { data } = await api.user.update(formData);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                dispatch(fetchUserInfo({ params: { user_id: user?.id } }))
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading({ ...loading, submit: false });
            closeModal();
        } catch (error) {
            setLoading({ ...loading, submit: false });
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    }

    useEffect(() => {
        setValues({
            ...values,
            name: user?.name || '',
            email: user?.email || '',
            logo: SERVER_URL + user?.profile_photo
        })
    }, [user])

    const checkUnsaveChanges = () => {
        !values.unSavedChange && setFieldValue('unSavedChange', true);
    };

    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };

    const handleResetPassword = async () => {
        try {
            setFieldValue('resetPasswordLoading', true);
            const orgin = window.origin;
            const { data } = await api.auth.resetPassword({
                params: { email: values.email, resetUrl: `${orgin}${PAGES.NEW_PASSWORD.url}` }
            });

            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setFieldValue('resetPasswordLoading', false);
        } catch (error) {
            setFieldValue('resetPasswordLoading', false);
            enqueueSnackbar(error.message.message || error.message, { variant: 'error' });
        }
    };

    return (
        <CommonModal open={open} title={type || 'Edit Profile'} width={500} onClose={closeModal}>
            <form onSubmit={handleSubmit}>
                <FieldLabelView label={'Name'}>
                    <StyledInput
                        handleChange={checkHandlechange}
                        handleBlur={handleBlur}
                        error={touched.name && errors.name}
                        name={'name'}
                        value={values?.name}
                        type={'name'}
                    />
                </FieldLabelView>
                <FieldLabelView label={'Email'} sx={{ mt: 3 }}>
                    <StyledInput
                        handleChange={checkHandlechange}
                        handleBlur={handleBlur}
                        error={touched.email && errors.email}
                        name={'email'}
                        value={values?.email}
                        type={'email'}
                    />
                </FieldLabelView>
                <FieldLabelView label={'Password'} sx={{ mt: 3 }}>
                    <Box>
                        <PasswordResetLink onClick={handleResetPassword}>Email password reset link</PasswordResetLink>
                        {values.resetPasswordLoading && <StyledProgressView size={15} />}
                    </Box>
                </FieldLabelView>
                <FieldLabelView label={'Profile Image'} sx={{ mt: 3 }}>
                    <ImagePlaceholder
                        handleChange={(event) => {
                            checkUnsaveChanges();
                            setFieldValue('logo_file', event.target.files[0]);
                            setFieldValue('logo', URL.createObjectURL(event.target.files[0]));
                        }}
                        image={values.logo}
                        isAddIcon={values.logo ? false : true}
                        boxDimensions={{ height: 80, width: 80 }}
                        addIconDimension={{ height: 30, width: 30 }}
                    />
                </FieldLabelView>
                <EditButtonContainer>
                    {values.unSavedChange && <UnsavedChanges />}
                    <RightActionContainer>
                        <CommonButton
                            sx={{ ml: 2 }}
                            key="Update-Button"
                            id="Update-Button"
                            confirmation={UPDATE_CONFIRMATION}
                            validateForm={validateForm}
                            onClick={handleSubmit}
                            loading={loading.submit}
                            variant="contained"
                            label={'Update'}
                            title={'User'}
                        />
                    </RightActionContainer>
                </EditButtonContainer>
            </form>
        </CommonModal>
    )
}

export default EditProfile