import React from 'react';
import { Typography, Box, styled } from '@mui/material';
import image from '../../assets/images/nodatafound.gif';

const NoDataFoundImage = styled('img')({
    height: 'auto',
    width: 'auto'
});
const Text = styled(Typography)({
    textAlign: 'center'
});
const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

const NoDataFound = (props) => {
    const { searchText, message, style } = props;
    return (
        <MainBox sx={style}>
            <Text variant="h4" paragraph sx={{ fontWeigth: 'bold' }}>
                Not Found
            </Text>
            <NoDataFoundImage src={image} />
            {searchText ? (
                <Text variant="h6" sx={{ py: 1 }}>
                    No results found for &nbsp;
                    <strong>&quot;{searchText}&quot;</strong>.
                    <br />
                    Try checking for typos or using complete words.
                </Text>
            ) : (
                <Text variant="h6" sx={{ py: 1 }}>
                  {message}
                </Text>
            )}
        </MainBox>
    );
};

export default NoDataFound;
