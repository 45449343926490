import { Box, Grid, Skeleton, styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { COLORS } from '../../../../utils/theme';
import { CommonInput } from '../../../../components/CommonInput';
import { ICONS, IMAGES } from '../../../../assets';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategory } from '../../../../redux/reducers/categorySlice';
import { SERVER_URL, SkeletonArray } from '../../../../utils/constants';
import { useState } from 'react';
import { AccessControlledComponent } from '../../../../components/AccessControlledComponent';
import { getAccessIdForPlaylistCategory, getAccessObjectFromArrayObject } from '../../../../utils/helpers';

const MainBox = styled(Box)({
    marginTop: 24,
    padding: 36,
    width: '100%',
    border: `1px solid ${COLORS.border}`,
    backgroundColor: COLORS.white,
    alignItems: 'center',
    borderRadius: '0.425rem'
});

const FieldLabel = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: COLORS.black,
    marginTop: 2
}));

const CategoryImageView = styled(Box)(({ theme }) => ({
    maxWidth: '150px',
    position: 'relative',
    width: '100%',
    height: '100px',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    [theme.breakpoints.up('xs')]: {
        width: '120px',
        height: '100px'
    }
}));

const BorderedBox = styled(Box)({
    height: '100%',
    width: '100%',
    borderRadius: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});
const CategoryImageMain = styled('img')({
    height: '52%',
    width: '52%',
    objectFit: 'contain'
});

const Image = styled('img')({
    height: 26,
    width: 26,
    position: 'absolute',
    bottom: '-4px',
    right: '-4px'
});

const CategoryList = styled(Grid)({

});

const CategoryItem = styled(Grid)({
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    cursor: 'pointer'
});
export default function LibraryCategories({ onChangeCategory, onChangeSearch }) {
    const dispatch = useDispatch();
    const { categorys } = useSelector((state) => state.category);
    const [activeCategory, setActiveCategory] = useState();
    const [loading, setLoading] = useState(false);
    const { client, access } = useSelector((state) => state.auth);

    useEffect(() => {
        setLoading(true);
        dispatch(fetchCategory({ params: { status: 1 } })).then(() => {
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        if (categorys && categorys.length !== 0) {
            const data = categorys.find((item) => {
                let accessObject = getAccessObjectFromArrayObject(access.access, getAccessIdForPlaylistCategory(item.id));
                if (accessObject?.visibility && accessObject?.status) {
                    return true;
                }
                return false;
            });
            if (data) {
                setActiveCategory(data.id);
            }
        }
    }, [access.access, categorys]);

    useEffect(() => {
        if (onChangeCategory) {
            onChangeCategory(activeCategory);
        }
    }, [activeCategory, onChangeCategory]);

    const handleSelectCategory = (item) => {
        setActiveCategory(item.id);
    };

    return (
        <MainBox>
            {loading ? (
                <CategoryList container>
                    {SkeletonArray.map((skeleton) => {
                        return (
                            <CategoryItem key={skeleton} item xs={6} sm={3} lg={1.7}>
                                <CategoryImageView>
                                    <Skeleton
                                        variant="rounded"
                                        height={84}
                                        width={'100%'}
                                        sx={{
                                            borderRadius: 3
                                        }}
                                    />
                                </CategoryImageView>
                                <Skeleton width={'50%'} />
                            </CategoryItem>
                        );
                    })}
                </CategoryList>
            ) : (
                <CategoryList container spacing={1}>
                    {categorys.map((item) => {
                        return (
                            <AccessControlledComponent
                                accessValue={getAccessIdForPlaylistCategory(item.id)}
                                style={{ display: 'contents' }}
                            >
                                <CategoryItem
                                    onClick={() => handleSelectCategory(item)}
                                    key={item.id}
                                    item
                                    xs={6}
                                    sm={3}
                                    lg={1.7}
                                    sx={
                                        !getAccessObjectFromArrayObject(access.access, getAccessIdForPlaylistCategory(item.id))
                                            ?.visibility && {
                                            opacity: 0.3,
                                            cursor: 'not-allowed !important'
                                        }
                                    }
                                >
                                    <CategoryImageView
                                        sx={
                                            activeCategory === item.id && {
                                                outline: `2px solid  ${COLORS.black}`
                                            }
                                        }
                                    >
                                        <BorderedBox
                                            sx={{
                                                backgroundColor: item.color
                                            }}
                                        >
                                            <CategoryImageMain alt={item.name} src={SERVER_URL + item?.icon} />
                                        </BorderedBox>
                                        {client?.curate_list?.[item.id] ? <Box>
                                            <Image src={IMAGES.CURATED_ICON} alt="curated" />
                                        </Box> : null}
                                    </CategoryImageView>
                                    <FieldLabel>{item.name}</FieldLabel>
                                </CategoryItem>
                            </AccessControlledComponent>
                        );
                    })}
                </CategoryList>
            )}
            <CommonInput handleChange={onChangeSearch} name={'search'} label={'Search...'} endAdornment={<ICONS.Search />} />
        </MainBox>
    );
}
