import React, { useState, useEffect, useMemo } from 'react';
import { CircularProgress, Grid, styled } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import useFetch from '../../../hooks/useFetch';
import { CollectionCardWidgets } from '../../../widgets/CollectionCardWidgets';
import BrowserHeader from '../Browse/components/BrowserHeader';
import NoDataFound from '../../../components/NoDataFound';
import UnauthorizedPopup from './components/UnauthorizedPopup';
import { SkeletonArray, USER_ROLE } from '../../../utils/constants';
import { fetchSocialDetails } from '../../../redux/reducers/socialMediaSlice';
import EditAuthorizedContentPopup from '../../../components/EditAuthorizedContentPopup';
import { getAccessObjectFromArrayObject, isAddedInterationApp } from '../../../utils/helpers';
import { fetchLocations } from '../../../redux/reducers/clientLocationSlice';

const CenteredNoDataFound = styled(Box)({
    marginTop: '24px',
    display: 'flex',
    justifyContent: 'center'
});

const LoaderBox = styled(Box)({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex'
});

export const ClientApps = () => {
    const [showUnauthorizedPopup, setUnauthorizedPopup] = useState(false);
    const [showAauthorizedPopup, setAuthorizedPopup] = useState(false);
    const [selectedContent, setSelectedContent] = useState();
    const [fetching, setFetched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { activeOrientation } = useSelector((state) => state.orientation);
    const { access } = useSelector((state) => state.auth);

    const [search, setSearch] = useState('');
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const {
        data: socialData,
        callFetch: fetchAllSocialDetails,
        isLoading: mediaLoading
    } = useFetch({
        initialUrl: `/get_all_media`,
        skipOnStart: true
    });

    useEffect(() => {
        let params = {
            social: 1,
            status: 1,
            search: search
        };

        fetchAllSocialDetails({ params });
    }, [fetchAllSocialDetails, search, user?.client]);

    const handleClickContent = async (data) => {
        if (user.role !== USER_ROLE.VIEWER) {
            let addedIntergationApp = isAddedInterationApp(data?.name_alias);
            setFetched(true);
            if (
                (data.is_authorized && !addedIntergationApp) ||
                Boolean(
                    !data?.is_default_and_authorizable &&
                        addedIntergationApp &&
                        ((data?.activation_status !== 'deactive' &&
                            data?.activation_status !== 'pending' &&
                            data?.activation_status === 'active') ||
                            !data?.hasOwnProperty('activation_status') ||
                            !data?.activation_status) &&
                        data?.is_authorized
                )
            ) {
                dispatch(fetchSocialDetails({ type: data?.name_alias })).then(() => {
                    setFetched(false);
                    setAuthorizedPopup(true);
                });
            } else {
                setUnauthorizedPopup(true);
            }
        }
    };

    const filterVisibleSocialData = useMemo(() => {
        if (socialData?.result?.length) {
            let result = socialData?.result
                ?.map((item) => {
                    const accessObject = getAccessObjectFromArrayObject(access?.access, item?.name_alias);
                    if (accessObject?.visibility) {
                        return item;
                    }
                })
                ?.filter((app) => app);
            return { ...socialData, result };
        } else {
            return {};
        }
    }, [socialData?.result]);

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchLocations()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch, user.client]);
    return (
        <Box>
            <BrowserHeader title={'Integrations'} onlySearch={true} />
            <Box sx={{ marginTop: '24px' }} />
            <Grid container spacing={3}>
                {mediaLoading || isLoading
                    ? SkeletonArray.map((skeleton) => (
                          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={skeleton}>
                              <CollectionCardWidgets
                                  orientation={activeOrientation}
                                  hideToggleSwitch={true}
                                  hasStatus={true}
                                  loading={Boolean(mediaLoading || isLoading)}
                              />
                          </Grid>
                      ))
                    : filterVisibleSocialData &&
                      filterVisibleSocialData?.result?.map?.((item) => {
                          return (
                              <Grid
                                  item
                                  key={item.id}
                                  xs={12}
                                  sm={6}
                                  md={4}
                                  lg={3}
                                  xl={2}
                                  sx={fetching && item.id === selectedContent.id && { opacity: 0.6, position: 'relative' }}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                              >
                                  <CollectionCardWidgets
                                      orientation={activeOrientation}
                                      hideToggleSwitch={true}
                                      hasStatus={true}
                                      value={item.library_status}
                                      onClick={(data) => {
                                          setSelectedContent(data);
                                          handleClickContent(data);
                                      }}
                                      data={item}
                                      hasAuthStatus={Boolean(item.is_default_and_authorizable)}
                                      isAuthorized={item.is_authorized}
                                      objectFit={'contain'}
                                      access_visibility={item.access_visibility}
                                      activation_status={
                                          ((item?.activation_status !== 'deactive' &&
                                              item?.activation_status !== 'pending' &&
                                              item?.activation_status === 'active') ||
                                              !item.hasOwnProperty('activation_status') ||
                                              !item?.activation_status) &&
                                          item?.is_authorized
                                      }
                                  />
                                  {fetching && item.id === selectedContent.id && (
                                      <LoaderBox>
                                          <CircularProgress size={18} />
                                      </LoaderBox>
                                  )}
                              </Grid>
                          );
                      })}
            </Grid>
            {!mediaLoading && !isLoading && !filterVisibleSocialData?.result?.length && (
                <CenteredNoDataFound>
                    <NoDataFound searchText={search} message={filterVisibleSocialData?.message} />
                </CenteredNoDataFound>
            )}
            {showUnauthorizedPopup && (
                <UnauthorizedPopup
                    data={selectedContent}
                    open={showUnauthorizedPopup}
                    handleClose={() => {
                        setUnauthorizedPopup(false);
                        setFetched(false);
                    }}
                    fetchAllSocialDetails={fetchAllSocialDetails}
                    orientation={activeOrientation}
                />
            )}
            {showAauthorizedPopup && (
                <EditAuthorizedContentPopup
                    open={showAauthorizedPopup}
                    handleClose={() => {
                        setAuthorizedPopup(false);
                        setFetched(false);
                    }}
                    data={selectedContent}
                    fetchLatestData={() =>
                        fetchAllSocialDetails({
                            params: {
                                social: 1,
                                status: 1,
                                search: search
                            }
                        })
                    }
                />
            )}
        </Box>
    );
};
