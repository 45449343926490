import { IconButton, styled, Box, Typography, Input, Button } from '@mui/material';
import { EDITOR_HEADER_MENU } from '../../../utils/constants';
import { COLORS } from '../../../utils/theme';
import { IMAGES } from '../../../assets';
import { useEffect, useState } from 'react';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { checkContentStatusInLibrary } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { api } from '../../../api';
import { fetchAllContents } from '../../../redux/reducers/contentSlice';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { CommonMultiSelect } from '../../CommonMultiSelect';
import ApperianceProvider from './ApperianceProvider';

const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

const ActionIconButton = styled(IconButton)({
    width: 70,
    height: 64,
    borderRadius: '0.425rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 0px',
    marginRight: '8px',
    '&:hover': {
        backgroundColor: COLORS.primary.light,
        img: {
            filter: 'invert()'
        },
        p: {
            color: COLORS.white,
            fontWeight: 500
        }
    }
});

const ActionControlsView = styled(Box)({
    alignItems: 'center',
    padding: '16px',
    justifyContent: 'space-between',
    borderTop: `1px solid ${COLORS.border}`,
    borderLeft: `1px solid ${COLORS.border}`,
    borderRight: `1px solid ${COLORS.border}`,
    borderTopLeftRadius: '0.425rem',
    borderTopRightRadius: '0.425rem',
    background: COLORS.white,
    display: 'flex',
    width: '100%'
});

const ActionIconImage = styled("img")(({ isActive }) => ({
    height: 28,
    width: 28,
    objectFit: 'contain',
    filter: isActive ? 'invert()' : 'unset'
}));

const EditorMenuText = styled(Typography)(({ isActive }) => ({
    color: isActive ? COLORS.white : COLORS.black,
    fontWeight: isActive ? 500 : 600,
    fontSize: 10
}));

const StyledInput = styled(Input)({
    fontSize: 26,
    color: COLORS.black,
    textDecoration: 'none'
});

const DurationText = styled(Typography)({
    fontSize: 14,
    color: COLORS.black
});

const VideoAddImage = styled('img')({
    height: 22,
    width: 22,
    objectFit: 'contain'
});
const VideoFieldLabel = styled(Typography)({
    fontSize: 16,
    fontWeight: 'bold',
    color: COLORS.black,
    alignItems: 'center',
    display: 'flex'
});
const HeaderBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
});
const ActionText = styled(Typography)({
    color: COLORS.darkGray,
    fontWeight: 400,
    fontSize: 16,
    marginLeft: 8
});

export const EditorHeader = ({
    unSavedChange,
    inEdit,
    onClose,
    actionHeaderItem,
    handleSelectHeaderItem,
    onSave,
    loading,
    content,
    libraryStatus,
    setUnSavedChange,
    handleChangeTextAction,
    actionProvider,
    handleChangeShapesAction
}) => {
    const durationArray = [
        {
            id: '10',
            name: '10',
            value: 10
        },
        {
            id: '15',
            name: '15',
            value: 15
        },
        {
            id: '20',
            name: '20',
            value: 20
        },
        {
            id: '25',
            name: '25',
            value: 25
        },
        {
            id: '30',
            name: '30',
            value: 30
        },
        {
            id: '35',
            name: '35',
            value: 35
        },
        {
            id: '40',
            name: '40',
            value: 40
        }
    ];
    const [isNameDisable, setNameDisable] = useState(true);
    const [duration, setDuration] = useState(15);
    const [name, setName] = useState('My Custom Creation');
    const [status, setLibraryStatus] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { client } = useSelector((state) => state.auth.user);
    const { orientation } = useSelector((state) => state.orientation);
    const { librarys } = useSelector((state) => state.library);

    useEffect(() => {
        if (content) {
            setName(content.name);
            setDuration(Number(content?.duration.split(':')[content?.duration.split(':').length - 1]));
        }
    }, [content]);
    useEffect(() => {
        if (libraryStatus) {
            setLibraryStatus(libraryStatus);
        }
    }, [libraryStatus]);

    const handleSave = () => {
        if (onSave) {
            onSave(name, duration, status);
        }
    };

    const handleDelete = async () => {
        try {
            const { data } = await api.mediaContent.delete(content.id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                const params = { client_id: client.id, orientation: orientation };
                dispatch(fetchAllContents({ params }));
                setDeletePopup(false);
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    const handleLibraryStatus = (value) => {
        setLibraryStatus(value);
        setUnSavedChange(true);
    };
    return (
        <MainBox>
            <CommonHeader sx={{ backgroundColor: COLORS.white, mb: 3 }}
                loading={loading}
                unsavedChanges={unSavedChange}
                onClick={handleSave}
                inEdit={inEdit}
                buttonLabel={'Save'}
                inputComponent={true}
                outlineInputClass={{ top: 0 }}
                onClose={onClose}
                onDelete={() => setDeletePopup(true)}
                breadcrumbText={'Creator'}
                backTo={'Create'}
            >
                <HeaderBox>
                    {!isNameDisable ? (
                        <StyledInput
                            name={'name'}
                            disableUnderline={isNameDisable}
                            onFocus={() => setNameDisable(false)}
                            onBlur={() => setNameDisable(true)}
                            onChange={(e) => {
                                setUnSavedChange(true);
                                setName(e.target.value);
                            }}
                            value={name}
                            autoFocus={!isNameDisable}
                        />
                    ) : (
                        <div onClick={() => setNameDisable(false)}>
                            <Typography sx={{ fontSize: 26 }}>{name}</Typography>
                        </div>
                    )}
                    {isNameDisable && <ActionText>{'(Click to edit title)'}</ActionText>}
                </HeaderBox>
            </CommonHeader>
            <ActionControlsView>
                <Box sx={{ display: 'flex' }}>
                    {EDITOR_HEADER_MENU.map((item) => {
                        return (
                            <ActionIconButton
                                key={item.id}
                                sx={{ backgroundColor: item.id === actionHeaderItem ? COLORS.primary.light : 'transparent' }}
                                onClick={() => handleSelectHeaderItem(item)}
                                color="primary"
                                aria-label="upload picture"
                            >
                                <>
                                    <ActionIconImage isActive={item.id === actionHeaderItem} src={item.icon} />
                                    <EditorMenuText isActive={item.id === actionHeaderItem}>{item?.name}</EditorMenuText>
                                </>
                            </ActionIconButton>
                        );
                    })}
                </Box>
                <Box sx={{ display: 'flex', width: 'auto', mt: { lg: 0, md: 0, sm: 3, xs: 3 }, alignItems: 'center' }}>
                    <ApperianceProvider {...{ handleChangeTextAction, handleChangeShapesAction }} type={actionProvider} />
                </Box>
                <Box sx={{ display: 'flex', width: 'auto', mt: { lg: 0, md: 0, sm: 3, xs: 3 }, alignItems: 'center' }}>
                    <DurationText>{`Duration:`}</DurationText>
                    <CommonMultiSelect
                        sx={{ mt: '4px' }}
                        handleChange={(e) => setDuration(e.target.value)}
                        options={durationArray}
                        name={'duration'}
                        style={{ width: 70, marginLeft: 8, height: 30 }}
                        value={duration}
                    />
                    <DurationText sx={{ ml: 2 }}>{`seconds`}</DurationText>
                    <Box sx={{ width: 16 }} />
                    {status ? (
                        <Button onClick={() => handleLibraryStatus(false)}>
                            <VideoAddImage src={IMAGES.RemoveCircleIcon} />
                            <VideoFieldLabel sx={{ ml: 1 }}>LINEUP</VideoFieldLabel>
                        </Button>
                    ) : (
                        <Button onClick={() => handleLibraryStatus(true)}>
                            <VideoAddImage src={IMAGES.AddCircleIcon} />
                            <VideoFieldLabel sx={{ ml: 1 }}>LINEUP</VideoFieldLabel>
                        </Button>
                    )}
                </Box>
            </ActionControlsView>
            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={checkContentStatusInLibrary(content, librarys) ? '' : handleDelete}
                    title={'Delete Creator Content'}
                    description={
                        checkContentStatusInLibrary(content, librarys)
                            ? 'This item cannot be deleted while it is in your Lineup. Remove from your Lineup and then try again'
                            : 'Are you sure you want to delete this Creator content ?'
                    }
                    isCancel={checkContentStatusInLibrary(content, librarys) ? 'OKAY' : 'No'}
                />
            ) : null}
        </MainBox>
    );
};
