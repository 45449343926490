import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import { useState } from 'react';
import { IMAGES } from '../../assets';
import { ASPECT_RATIO, SERVER_URL } from '../../utils/constants';

const MainBox = styled(Box)({
    position: 'relative'
});

const PlaceHolderImageVier = styled(Box)({
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0
});

const ThumbnailImage = styled('img')({
    height: '100%',
    width: '100%',
    position: 'absolute'
});

const PlayButtonView = styled(Box)({
    position: 'absolute',
    backgroundColor: 'rgba(0,0,0,0.3)',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
});

const VideoBox = styled('video')({
    height: '100%',
    width: '100%'
});
const PlayIcon = styled('img')({
    height: 40,
    width: 40
});
const VideoPlayer = ({ orientation, videoUrl, thumbnail, stopPropagation, width }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const playRef = useRef(null);

    const handlePlay = (e) => {
        if (stopPropagation) {
            e.stopPropagation();
        }
        if (playRef.current) {
            playRef.current?.play();
            setIsPlaying(true);
        }
    };
    const onVideoEnded = () => {
        setIsPlaying(false);
    };

    return (
        <MainBox style={{ width: width || '100%', aspectRatio: ASPECT_RATIO[orientation] }}>
            <VideoBox
                ref={playRef}
                controls={true}
                src={videoUrl}
                onEnded={onVideoEnded}
                controlsList={'noplaybackrate nodownload'}
                disablePictureInPicture={true}
            ></VideoBox>
            {!isPlaying && (
                <PlaceHolderImageVier onClick={handlePlay}>
                    {thumbnail ? <ThumbnailImage src={SERVER_URL + thumbnail} /> : null}
                    <PlayButtonView>
                        <PlayIcon src={IMAGES.PlayIcon} alt="play" />
                    </PlayButtonView>
                </PlaceHolderImageVier>
            )}
        </MainBox>
    );
};

export default VideoPlayer;
