import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page404 from '../components/ErrorPages/404';
import MainLayout from '../layout/MainLayout';
import PlanLayout from '../layout/PlanLayout';
import { useRoutes } from '../hooks/useRoutes';
import Page401 from '../components/ErrorPages/401';

const Routing = ({ baseData, ...props }) => {
    const { mainLayoutRoutes, planeLayoutRoutes, unAuthorizedRoutes } = useRoutes();

    return (
        <Routes {...props}>
            <Route element={<PlanLayout />}>
                {planeLayoutRoutes.map(({ id: key, ...otherData }, index) => (
                    <Route index key={`plane_${index}`} {...otherData} />
                ))}
            </Route>
            <Route path="/" element={<MainLayout />}>
                {mainLayoutRoutes.map(({ id: key, ...otherData }, index) => (
                    <Route index key={`main_${index}`} {...otherData} />
                ))}
            </Route>
            <Route element={<Page401 />}>
                {unAuthorizedRoutes.map(({ id: key, ...otherData }, index) => (
                    <Route index key={`un_auth_${index}`} {...otherData} />
                ))}
            </Route>
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default Routing;
