import { Box, styled, Tab, Tabs } from '@mui/material';
import { COLORS } from '../../../../utils/theme';

const CustomTabItem = styled(Tab)({
    fontWeight: 'bold',
    textTransform: 'none',
});

export const AccountTabBar = ({ tabArray, activeTab, handleChangeActiveTab }) => {
    return (
        <Box sx={{ width: '100%', backgroundColor: COLORS.white }}>
            <Tabs value={activeTab} onChange={(e, id) => handleChangeActiveTab(id)} aria-label="basic tabs example">
                {tabArray &&
                    tabArray.map((item) => {
                        return <CustomTabItem key={item.id} sx={{ minWidth: { xs: "80px", sm: '130px', md: '155px', lg: '170px' }, padding: { xs: '12px 10px', sm: '12px 12px', md: '12px 14px', lg: '12px 16px' } }} label={item.name} value={item.id} />;
                    })}
            </Tabs>
        </Box>
    );
};
