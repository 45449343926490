import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchUsers = createAsyncThunk('users/fetchUsers', async (params) => {
    const response = await api.user.get(params);
    return response.data.result;
});

const initialState = {
    users: []
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUsers.fulfilled, (state, action) => {
            state.users = action.payload;
        });
    }
});

export default userSlice.reducer;
