import { ICONS, IMAGES } from '../assets';

// export const SERVER_URL = 'http://loopos.project-demo.info:3006/';
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
// export const SERVER_URL = 'http://looposapi.dsid.com/';
// export const SERVER_URL = 'http://192.168.0.14:3006/';
// export const SERVER_URL = 'http://192.168.0.36:3006/';

export const GOOGLE_CLIENT_ID = '139794034208-ivm6pkeahnegpkcm0aqv02c5p5tmfoq2.apps.googleusercontent.com';

export const BASE_URL = SERVER_URL + 'api';
export const DRAWER_WIDTH = 248;
export const HEADER_HEIGHT = 68;
export const API_ERROR_MESSAGE = 'Something wents wrong';

export const ORIENTATION = {
    LANDSCAPE: 'landscape',
    PORTRAIT: 'portrait'
};

export const ACCESS_TYPE = {
    MAIN_SECTION: 'main-section',
    SUB_SECTION: 'sub-section',
    ITEM: 'item'
};

export const initialAccessList = [
    {
        id: 'presentation',
        type: ACCESS_TYPE.MAIN_SECTION,
        name: 'Presentation',
        items: [
            {
                id: 'locations',
                type: ACCESS_TYPE.ITEM,
                name: 'Locations',
                visibility: 1
            },
            {
                id: 'layout',
                type: ACCESS_TYPE.SUB_SECTION,
                name: 'Layout',
                items: [
                    {
                        id: 'orientation',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Orientation & Zones',
                        visibility: 1
                    },
                    {
                        id: 'resolution',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Resolution',
                        visibility: 1
                    },
                    // {
                    //     id: 'zones',
                    //     type: ACCESS_TYPE.ITEM,
                    //     name: 'Zones',
                    //     visibility: 1
                    // },
                    {
                        id: 'advanced',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Advanced',
                        visibility: 1
                    }
                ],
                visibility: 1
            },
            {
                id: 'loops',
                type: ACCESS_TYPE.SUB_SECTION,
                name: 'Loops',
                items: [
                    {
                        id: 'templates',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Templates',
                        visibility: 1
                    },
                    {
                        id: 'custom',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Custom',
                        visibility: 1
                    },
                    {
                        id: 'schedule',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Schedule',
                        visibility: 1
                    }
                ],
                visibility: 1
            },
            {
                id: 'library',
                type: ACCESS_TYPE.SUB_SECTION,
                name: 'Lineup',
                items: [
                    {
                        id: 'healthcare',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Healthcare'
                    },
                    {
                        id: 'apps',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Apps'
                    },
                    {
                        id: 'library_creator',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Creator'
                    },
                    {
                        id: 'marketing',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Marketing'
                    },
                    {
                        id: 'infotainment',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Infotainment'
                    },
                    {
                        id: 'seasonal',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Seasonal'
                    },
                    {
                        id: 'brain_bites',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Brain Bites'
                    }
                ],
                visibility: 1
            }
        ],
        visibility: 1
    },
    {
        id: 'content',
        type: ACCESS_TYPE.MAIN_SECTION,
        name: 'Content',
        items: [
            {
                id: 'browser',
                type: ACCESS_TYPE.ITEM,
                name: 'Browser',
                visibility: 1
            },
            {
                id: 'request',
                type: ACCESS_TYPE.ITEM,
                name: 'Request',
                visibility: 1
            },
            {
                id: 'content_creator',
                type: ACCESS_TYPE.ITEM,
                name: 'Creator',
                visibility: 1
            },
            {
                id: 'upload',
                type: ACCESS_TYPE.SUB_SECTION,
                name: 'Upload',
                items: [
                    {
                        id: 'video',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Video',
                        visibility: 1
                    },
                    {
                        id: 'background',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Background',
                        visibility: 1
                    },
                    {
                        id: 'placeholder',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Placeholder',
                        visibility: 1
                    }
                ],
                visibility: 1
            },
            {
                id: 'apps',
                type: ACCESS_TYPE.SUB_SECTION,
                name: 'Integrations',
                items: [
                    {
                        id: 'facebook',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Facebook',
                        hasStatus: true,
                        visibility: 1
                    },
                    {
                        id: 'instagram',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Instagram',
                        hasStatus: true,
                        visibility: 1
                    },
                    {
                        id: 'google',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Google',
                        hasStatus: true,
                        visibility: 1
                    },
                    {
                        id: 'snappic',
                        type: ACCESS_TYPE.ITEM,
                        name: 'Snappic',
                        hasStatus: true,
                        visibility: 1
                    }
                ],
                visibility: 1
            }
        ],
        visibility: 1
    },
    {
        id: 'integrations',
        type: ACCESS_TYPE.MAIN_SECTION,
        name: 'Advanced',
        items: [
            {
                id: 'scrolling_messaging',
                type: ACCESS_TYPE.ITEM,
                name: 'Scrolling Messaging',
                visibility: 1
            }
        ],
        visibility: 1
    },
    {
        id: 'help',
        type: ACCESS_TYPE.MAIN_SECTION,
        name: 'Help',
        items: [
            {
                id: 'support',
                type: ACCESS_TYPE.ITEM,
                name: 'Support',
                visibility: 1
            },
            {
                id: 'feedback',
                type: ACCESS_TYPE.ITEM,
                name: 'Feedback',
                visibility: 1
            }
        ],
        visibility: 1
    }
];

export const UPLOAD_IMAGE_MENU = [
    {
        id: 'video',
        name: 'Video',
        icon: ICONS.VideoFile
    },
    {
        id: 'placeholder',
        name: 'Placeholder',
        icon: ICONS.PlaceholderFile
    }
];

export const PORTRAITORIENTION = {
    PORTRAITLEFT: 'Portrait - Bottom Left',
    PORTRAITRIGHT: 'Portrait - Bottom Right'
};

export const PORTRAIT_BOTTOM = {
    LEFT: 'left',
    RIGHT: 'right'
};

export const ORIENTATION_DATA_KEY = {
    video: {
        [ORIENTATION.LANDSCAPE]: 'landscape_video',
        [ORIENTATION.PORTRAIT]: 'portrait_video'
    },
    video_hd: {
        [ORIENTATION.LANDSCAPE]: 'landscape_video_hd',
        [ORIENTATION.PORTRAIT]: 'portrait_video_hd'
    },
    thumbnail: {
        [ORIENTATION.LANDSCAPE]: 'landscape_thumbnail',
        [ORIENTATION.PORTRAIT]: 'portrait_thumbnail'
    },
    file_exist: {
        [ORIENTATION.LANDSCAPE]: 'landscape_file_exist',
        [ORIENTATION.PORTRAIT]: 'portrait_file_exist'
    },

    file_exist_hd: {
        [ORIENTATION.LANDSCAPE]: 'landscape_file_exist_hd',
        [ORIENTATION.PORTRAIT]: 'portrait_file_exist_hd'
    },
    share_url: {
        [ORIENTATION.LANDSCAPE]: 'landscape_share_url',
        [ORIENTATION.PORTRAIT]: 'portrait_share_url'
    }
};

export const CONTENT_MODULE_TYPE = {
    VIDEO: 'video',
    COLLECTION: 'collection',
    CUSTOM_CONTENT: 'custom_content',
    GROUP: 'group',
    SOCIAL: 'social'
};

export const CONTENT_DATA_TYPE = {
    VIDEO: 'video',
    IMAGE: 'image',
    HTML: 'html'
};

export const ASPECT_RATIO = {
    [ORIENTATION.LANDSCAPE]: 16 / 9,
    [ORIENTATION.PORTRAIT]: 9 / 16,
    LOGO: 1 / 1
};

export const USER_ROLE = {
    ADMIN: 'admin',
    CLIENT: 'client',
    VIEWER: 'viewer',
    MANAGER: 'manager'
};

// export const TIME_ZONES = [
//     { value: 'Atlantic', name: 'Atlantic' },
//     { value: 'Eastern', name: 'Eastern' },
//     { value: 'Central', name: 'Central' },
//     { value: 'Mountain', name: 'Mountain' },
//     { value: 'Pacific', name: 'Pacific' },
//     { value: 'Alaska', name: 'Alaska' },
//     { value: 'Hawaii', name: 'Hawaii' }
// ];

export const ACCOUNT_DETAILS_TAB = [
    {
        id: 1,
        name: 'General'
    },
    {
        id: 2,
        name: 'Locations'
    },
    {
        id: 3,
        name: 'Access'
    },
    {
        id: 4,
        name: 'Content'
    }
];

export const RESOLUTION_FORMAT_TYPE = {
    NTSC: 'ntsc',
    PAL: 'pal'
};

export const RESOLUTION_FORMAT_TAB = [
    {
        id: RESOLUTION_FORMAT_TYPE?.NTSC,
        name: 'NTSC (US, Canada)'
    },
    {
        id: RESOLUTION_FORMAT_TYPE.PAL,
        name: 'PAL (Europe, Asia, Africa)'
    }
];

export const RESOLUTION_TAB = [
    {
        id: '720p',
        name: '720p'
    },
    {
        id: '1080p',
        name: '1080p'
    },
    {
        id: '4k',
        name: '4K'
    }
];

export const FRAME_RATE = {
    '4k': [
        {
            id: '25p',
            name: '25p',
            format: RESOLUTION_FORMAT_TYPE.PAL
        },
        {
            id: '50p',
            name: '50p',
            format: RESOLUTION_FORMAT_TYPE.PAL
        },
        // {
        //     id: '24p',
        //     name: '24p',
        //     format: RESOLUTION_FORMAT_TYPE.PAL
        // },
        {
            id: '30p',
            name: '30p',
            format: RESOLUTION_FORMAT_TYPE.NTSC
        },
        {
            id: '60p',
            name: '60p',
            format: RESOLUTION_FORMAT_TYPE.NTSC
        }
    ],
    '1080p': [
        {
            id: '25p',
            name: '25p',
            format: RESOLUTION_FORMAT_TYPE.PAL
        },
        {
            id: '50p',
            name: '50p',
            format: RESOLUTION_FORMAT_TYPE.PAL
        },
        {
            id: '30p',
            name: '30p',
            format: RESOLUTION_FORMAT_TYPE.NTSC
        },
        {
            id: '60p',
            name: '60p',
            format: RESOLUTION_FORMAT_TYPE.NTSC
        },
        {
            id: '60i',
            name: '60i',
            format: RESOLUTION_FORMAT_TYPE.NTSC
        }
    ],
    '720p': [
        {
            id: '25p',
            name: '25p',
            format: RESOLUTION_FORMAT_TYPE.PAL
        },
        {
            id: '50p',
            name: '50p',
            format: RESOLUTION_FORMAT_TYPE.PAL
        },
        // {
        //     id: '30p',
        //     name: '30p',
        //     format: RESOLUTION_FORMAT_TYPE.NTSC
        // },
        {
            id: '60p',
            name: '60p',
            format: RESOLUTION_FORMAT_TYPE.NTSC
        }
    ]
};

export const MONITOR_OVERSCAN_TAB = [
    {
        id: 'none-fullscreen',
        name: 'None - Fullscreen'
    },
    {
        id: 'overscan-action-safe',
        name: 'Overscan - Action Safe'
    },
    {
        id: 'overscan-title-safe',
        name: 'Overscan - Title Safe'
    }
];

export const FORCE_RESOLUTION_TAB = [
    {
        id: 'no',
        name: 'No'
    },
    {
        id: 'yes',
        name: 'Yes'
    }
];

export const LOOP_TEMPLATES_TAB = {
    LOOP_TEMPLATES: 'Loop Templates',
    CUSTOM_LOOPS: 'Custom Loops'
};
export const LOOP_SCHEDULE_TAB = [
    {
        id: 1,
        name: 'Loops'
    },
    {
        id: 2,
        name: 'Schedule'
    }
];

export const LOOP_OPTIONS_TAB = [
    {
        id: 1,
        name: 'Playlists'
    },
    {
        id: 2,
        name: 'Schedule'
    }
];

export const SEQUENCE_MANUAL_TAB = {
    ADDED: 'ADDED',
    LINEUP: 'LINEUP'
};
export const PLAYLIST_MODE = {
    AUTO: 'Auto',
    MANUAL: 'Manual'
};
export const PLAYBACK_METHOD = {
    SINGLE: 'Single',
    COMPLETE: 'Complete',
    GROUPED: 'Grouped'
};

export const PLAYLIST_MODE_TAB = [
    {
        id: PLAYLIST_MODE?.AUTO,
        name: 'Auto'
    },
    {
        id: PLAYLIST_MODE?.MANUAL,
        name: 'Manual'
    }
];

export const LOOP_SCHEDULE_TYPE = {
    ALL_DAY: 'allDay',
    MORNING: 'morning',
    AFTERNOON: 'afternoon',
    EVENING: 'evening',
    CUSTOM: 'custom'
};

export const LOOP_CREATE_TYPE = {
    CUSTOM: 'custom',
    TEMPLATE: 'template'
};
export const BACKGROUND_ACTION_TYPE = {
    IMAGE: 'background-image',
    COLOR: 'color',
    GRADIENT_COLOR: 'gradient_color'
};

export const TEXT_ACTION_TYPE = {
    FONT_FAMILY: 'font-family',
    ALIGNMENT: 'alignment',
    ADD_TEXT: 'add-text',
    FONT_COLOR: 'font-color',
    FONT_SIZE: 'font-size',
    OPACITY: 'opacity'
};

export const SHAPE_ACTION_TYPE = {
    UPLOAD_IMAGE: 'upload-image',
    SHAPE_SELECTOR: 'shape-selector',
    CIRCLE: 'circle',
    TRIANGLE: 'triangle',
    RECTANGLE: 'rectangle',
    SHAPE_COLOR: 'shape-color',
    OPACITY: 'opacity'
};

export const HEADER_MENU_TYPE = {
    BACKGROUND: 'background',
    TEXT: 'text',
    SHAPE: 'shape'
};

export const EDITOR_HEADER_MENU = [
    {
        id: HEADER_MENU_TYPE.BACKGROUND,
        name: 'Background',
        icon: IMAGES.Background
    },
    {
        id: HEADER_MENU_TYPE.TEXT,
        name: 'Text',
        icon: IMAGES.Text
    },
    {
        id: HEADER_MENU_TYPE.SHAPE,
        name: 'Elements',
        icon: IMAGES.AddImage
    }
];

export const BACKGROUND_UPLOAD = {
    validationList: ['1920x1080 resolution', 'PNG or JPG format', '2MB maximum file size'],
    landscapeValidations: [
        {
            type: 'resolution',
            height: 1080,
            width: 1920,
            label: '1920x1080 resolution',
            error: 'Resolution should be 1920x1080'
        },
        {
            type: 'format',
            value: ['png', 'jpeg'],
            label: 'PNG or JPG format',
            error: 'Format should be PNG or JPG'
        },

        {
            type: 'size',
            value: 2 * 1048576,
            label: '2MB maximum file size',
            error: 'Maximum size should be 2 MB'
        }
    ],
    portraitValidations: [
        {
            type: 'resolution',
            height: 1920,
            width: 1080,
            label: '1080x1920 resolution',
            error: 'Resolution should be 1080x1920'
        },
        {
            type: 'format',
            value: ['png', 'jpeg'],
            label: 'PNG or JPG format',
            error: 'Format should be PNG or JPG'
        },

        {
            type: 'size',
            value: 2 * 1048576,
            label: '2MB maximum file size',
            error: 'Maximum size should be 2 MB'
        }
    ],
    requirementText: 'Background files must meet the following requirements:',
    accept: {
        'image/jpeg': [],
        'image/png': []
    },
    maxFileSize: { bytes: 2000000, mb: 2 },
    maxFiles: 0
};

export const CONTENT_UPLOAD = {
    VIDEO: {
        validationList: [
            'MP4 format',
            'H265 codec for 4K resolution',
            'H264 codec for HD resolution and lower',
            '5 minute maximum duration',
            '500MB maximum file size'
        ],
        title: 'Video Upload',
        description:
            'Video files are reviewed and assessed for compatibility before appearing in your account. Turnaround time is typically within 24 hours.',
        validations: [
            {
                type: 'format',
                value: ['mp4'],
                label: 'MP4 format',
                error: 'Video format should be MP4'
            },
            {
                type: 'video_resolution',
                value: '1280x720',
                error: 'Video resolution should be HD or above.'
            },
            {
                type: 'hvc1',
                value: ['hvc1'],
                label: 'H265 codec for 4K resolution',
                error: 'Video should be encoded with H.265 codec for 4K or above.'
            },
            {
                type: 'avc1',
                value: ['avc1'],
                label: 'H264 codec for HD resolution and lower',
                error: 'Video should be encoded with H.264 codec for HD or lower.'
            },
            {
                type: 'duration',
                value: 5 * 60,
                label: '5 minute maximum duration',
                error: 'Video maximum duration should be 5 minute'
            },
            {
                type: 'size',
                value: 500 * 1048576,
                label: '500MB maximum file size',
                error: 'Video maximum size should be 500 MB'
            }
        ],
        requirementText: 'Video files should meet the following requirements:',
        //   accept:{'video/mp4':[]}, accept="image/*,audio/*,video/*"
        accept: {
            'video/mp4': []
        },
        maxFileSize: { bytes: 500000000, mb: 500 },
        maxFiles: 0
    },
    BACKGROUND: {
        validationList: ['1920x1080 resolution', 'PNG or JPG format', '2MB maximum file size'],
        title: '',
        description: '',
        validations: [
            {
                type: 'resolution',
                height: 1080,
                width: 1920,
                label: '1920x1080 resolution',
                error: 'Resolution should be 1920x1080'
            },
            {
                type: 'format',
                value: ['png', 'jpeg'],
                label: 'PNG or JPG format',
                error: 'Background format should be PNG or JPG'
            },

            {
                type: 'size',
                value: 2 * 1048576,
                label: '2MB maximum file size',
                error: 'Background maximum size should be 2 MB'
            }
        ],
        requirementText: 'Zone Background files should meet the following requirements:',
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFileSize: { bytes: 2000000, mb: 2 },
        maxFiles: 1
    },
    PLACEHOLDER: {
        validationList: ['1920x1080/1080x1920 resolution', 'PNG or JPG format', '1MB maximum file size'],
        title: 'Placeholder Upload',
        description:
            'Placeholders appear as a background layer in your presentation in the event that no other content is set in your loop. Your logo is recommended.',
        landscapeValidations: [
            {
                type: 'resolution',
                height: 1080,
                width: 1920,
                label: '1920x1080 or 1080X1920 resolution',
                error: 'Resolution should be 1920x1080 or 1080x1920'
            },
            {
                type: 'format',
                value: ['png', 'jpeg'],
                label: 'PNG or JPG format',
                error: 'Placeholder format should be PNG or JPG'
            },

            {
                type: 'size',
                value: 2 * 1048576,
                label: '2MB maximum file size',
                error: 'Placeholder maximum size should be 2 MB'
            }
        ],
        portraitValidations: [
            {
                type: 'resolution',
                height: 1920,
                width: 1080,
                label: '1080x1920 resolution',
                error: 'Resolution should be 1080x1920'
            },
            {
                type: 'format',
                value: ['png', 'jpeg'],
                label: 'PNG or JPG format',
                error: 'Placeholder format should be PNG or JPG'
            },

            {
                type: 'size',
                value: 2 * 1048576,
                label: '2MB maximum file size',
                error: 'Placeholder maximum size should be 2 MB'
            }
        ],
        requirementText: 'Placeholder files should meet the following requirements:',
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFileSize: { bytes: 1000000, mb: 1 },
        maxFiles: 1
    }
};

export const LOOP_SCHEDULE_EVENT_TYPE = {
    ONE_TIME: 'one_time',
    RECURNING: 'recurring'
};

export const SCHEDULE_RECURRENCE_PATTERN_TYPE = {
    DAILY: 'daily',
    SPECIFICDAYS: 'specific_days'
};

export const checkVideo = async (url) => {
    let promise = new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.addEventListener('loadedmetadata', (event) => {
            resolve(true);
        });

        video.onerror = function () {
            resolve(false);
        };
        video.src = url;
    });
    return await promise;
};

export const UPDATE_CONFIRMATION = { title: 'Update ', description: 'Are you sure you want to make these changes?' };
export const DELETE_CONFIRMATION = { title: 'Delete ', description: 'Are you sure you want to delete this ' };
export const DELETE_MIRROR_CONFIRMATION = { title: 'Delete ', description: 'Are you sure you want to disable this location as a mirror?' };
export const CLEAR_SCHEDULE_CONFIRMATION = {
    title: 'Clear Schedule',
    description:
        'All current and scheduled Loops will be removed from the Location. Are you sure you want to clear the selected Loop Schedule?'
};
export const DELETE_CONFIRMATION_ADD_ON_ADD = { title: 'Delete ', description: 'Are you sure you want to cancel this Add-On?' };
export const CLEAR_CONFIRMATION = { title: 'Delete ', description: 'Are you sure you want to clear' };

export const SkeletonArray = [1, 2, 3, 4];

export const INITIAL_ZONES = {
    [ORIENTATION.LANDSCAPE]: [
        {
            id: 1,
            height: 10,
            width: 98,
            x_axis: 1,
            y_axis: 89,
            content: 'ticker'
        },
        {
            id: 2,
            height: 87,
            width: 70,
            x_axis: 1,
            y_axis: 1,
            content: 'content'
        },
        {
            id: 3,
            height: 87,
            width: 27,
            x_axis: 72,
            y_axis: 1,
            content: 'pass-through'
        },
        {
            id: 4,
            height: 100,
            width: 100,
            x_axis: 0,
            y_axis: 0,
            content: 'background'
        }
    ],
    [ORIENTATION.PORTRAIT]: [
        {
            id: 1,
            height: 10,
            width: 92,
            x_axis: 4,
            y_axis: 88,
            content: 'ticker'
        },
        {
            id: 2,
            height: 36,
            width: 92,
            x_axis: 4,
            y_axis: 50,
            content: 'content'
        },
        {
            id: 3,
            height: 46,
            width: 92,
            x_axis: 4,
            y_axis: 2,
            content: 'pass-through'
        },
        {
            id: 4,
            height: 100,
            width: 100,
            x_axis: 0,
            y_axis: 0,
            content: 'background'
        }
    ]
};

export const TIME_ZONES = {
    EST: 'America/New York',
    CST: 'America/Chicago',
    MST: 'America/Denver',
    PST: 'America/Los Angeles',
    AKST: 'America/Anchorage',
    HST: 'Pacific/Honolulu',
    HST1: 'Pacific/Honolulu',
    MST1: 'America/Phoenix',
    EST1: 'America/Indiana/Indianapolis',
    AST: 'Atlantic/Bermuda',
    CST2: 'America/Mexico City',
    MST2: 'America/Chihuahua',
    PST2: 'America/Tijuana',
    BRT: 'America/Sao Paulo',
    NST: 'America/St Johns',
    AZOT: 'Atlantic/Azores',
    GMTBST: 'Europe/London',
    WET: 'Europe/Lisbon',
    CET: 'Europe/Berlin',
    EET: 'Europe/Bucharest',
    MSK: 'Europe/Moscow',
    SAMT: 'Europe/Samara',
    YEKT: 'Asia/Yekaterinburg',
    IST: 'Asia/Kolkata',
    NPT: 'Asia/Kathmandu',
    OMST: 'Asia/Omsk',
    JST: 'Asia/Tokyo',
    CXT: 'Indian/Christmas',
    AWST: 'Australia/Perth',
    AWST1: 'Australia/Perth',
    ACST: 'Australia/Darwin',
    AEST: 'Australia/Sydney',
    AEST1: 'Australia/Brisbane',
    NFT: 'Pacific/Norfolk',
    NZST: 'Pacific/Auckland',
    CHAST: 'Pacific/Fiji',
    SST: 'Pacific/Pago Pago',
    GMT: 'Europe/London'
};

export const SCHEDULE_SLOTS = [
    { id: LOOP_SCHEDULE_TYPE.ALL_DAY, title: 'Primary', time: '24/7' },
    { id: LOOP_SCHEDULE_TYPE.MORNING, title: 'Mornings', time: '6AM-12PM' },
    { id: LOOP_SCHEDULE_TYPE.AFTERNOON, title: 'Afternoons', time: '12PM-6PM' },
    { id: LOOP_SCHEDULE_TYPE.EVENING, title: 'Evenings', time: '6PM-12AM' },
    { id: LOOP_SCHEDULE_TYPE.CUSTOM, title: 'Custom', time: 'Set a specific date/time range' }
];
export const REVIEW_FILTER = [
    { name: '5 start reviews only', value: '5_star' },
    { name: 'All reviews', value: 'all' }
];
export const SOCIAL_MEDIA = {
    FACEBOOK: 'facebook',
    GOOGLE: 'google',
    INSTAGRAM: 'instagram'
};

export const BG_COLORS = [
    '#000000',
    '#4f4f4f',
    '#8f8f8f',
    '#d8d8d8',
    '#ffffff',
    '#ee4f35',
    '#f0832a',
    '#edef34',
    '#3dd953',
    '#106b2e',
    '#53e7bc',
    '#35d1e4',
    '#1750af',
    '#8c4fde',
    '#e25f96'
];

export const GRADIENT_TYPE = ['vertical', 'horizontal', 'radial', 'diagonal'];

export const FONT_FAMILIES = [
    {
        font: 'Open Sans',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Space Mono',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Kaushan Script',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Comic Neue',
        fontWeight: 700,
        fontStyle: 'normal'
    },
    {
        font: 'Courgette',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Righteous',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Bungee Shade',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Amatic SC',
        fontWeight: 700,
        fontStyle: 'normal'
    },
    {
        font: 'Roboto',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Arvo',
        fontWeight: 400,
        fontStyle: 'italic'
    },
    {
        font: 'Spectral',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Bubblegum Sans',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Anton',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'PT Sans Narrow',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Neuton',
        fontWeight: 800,
        fontStyle: 'normal'
    },
    {
        font: 'Lilita One',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Fredoka One',
        fontWeight: 500,
        fontStyle: 'normal'
    },
    {
        font: 'Shrikhand',
        fontWeight: 400,
        fontStyle: 'normal'
    },
    {
        font: 'Delicious Handrawn',
        fontWeight: 500,
        fontStyle: 'normal'
    },
    {
        font: 'Carter One',
        fontWeight: 400,
        fontStyle: 'normal'
    }
];

export const durations = [
    {
        id: 'ten_to_twenty',
        label: '10-20',
        value: '10-20'
    },
    {
        id: 'thirty',
        label: '30',
        value: '30'
    },
    {
        id: 'forty_to_forty_five',
        label: '40-45',
        value: '40-45'
    },
    {
        id: 'sixty',
        label: '60',
        value: '60'
    },
    {
        id: 'gre_than_60',
        label: '60+',
        value: '60+'
    }
];

export const specialty = [
    { id: 'orthodontics', label: 'Orthodontics' },
    { id: 'general dentistry', label: 'General Dentistry' },
    { id: 'periodontics', label: 'Periodontics' },
    { id: 'pediatric dentistry', label: 'Pediatric Dentistry' },
    { id: 'cosmetic dentistry', label: 'Cosmetic Dentistry' },
    { id: 'orthopaedics', label: 'Orthopaedics' },
    { id: 'vision', label: 'Vision' },
    { id: 'podiatry', label: 'Podiatry' },
    { id: 'pediatric health', label: 'Pediatric Health' },
    { id: 'cosmetic surgery', label: 'Cosmetic Surgery' }
];

export const topics = [
    { id: 'procedure', label: 'Procedure' },
    { id: 'treatment', label: 'Treatment' },
    { id: 'service', label: 'Service' },
    { id: 'educational', label: 'Educational' },
    { id: 'preventative', label: 'Preventative' },
    { id: 'oral hygiene', label: 'Oral Hygiene' },
    { id: 'social media', label: 'Social Media' },
    { id: 'fun', label: 'Fun' },
    { id: 'celebration', label: 'Celebration' },
    { id: 'financial', label: 'Financial' }
];

export const categories_orders = [
    {
        order: 1,
        id: process.env.REACT_APP_MY_VIDEOS_CATEGORY_ID
    },
    {
        order: 2,
        id: process.env.REACT_APP_HEALTHCARE_CATEGORY_ID
    },
    {
        order: 3,
        id: process.env.REACT_APP_APPS_CATEGORY_ID
    },
    {
        order: 4,
        id: process.env.REACT_APP_INFOTAINMENT_CATEGORY_ID
    },
    {
        order: 5,
        id: process.env.REACT_APP_SEASONAL_CATEGORY_ID
    },
    {
        order: 6,
        id: process.env.REACT_APP_BRAINBITES_CATEGORY_ID
    }
];

export const ACCOUNT_TYPES = [
    { id: 'territory', name: 'Territory' },
    { id: 'Specialty', name: 'Specialty' }
];
