import { createSlice } from '@reduxjs/toolkit';
import { ORIENTATION } from '../../utils/constants';

const initialState = {
    orientation: ORIENTATION.LANDSCAPE
};

export const orientationSlice = createSlice({
    name: 'orientation',
    initialState,
    reducers: {
        setOrientation: (state, action) => {
            state.orientation = action.payload;
        }
    }
});

export const { setOrientation } = orientationSlice.actions;
export default orientationSlice.reducer;
