import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { List, styled, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';
import { COLORS } from '../../../../../utils/theme';
import { useSelector } from 'react-redux';
import { getAccessObjectFromArrayObject, handleVisibility } from '../../../../../utils/helpers';

const ItemTitleText = styled(Typography)({
    fontSize: 12,
    color: COLORS.white,
    padding: '12px 24px'
});

const NavGroup = ({ item }) => {
    const { access } = useSelector((state) => state.auth);

    const checkPermission = (item) => {
        let accessItem;
        if (access?.access) {
            accessItem = getAccessObjectFromArrayObject(access?.access, item.accessKey);
        }
        if (item.accessKey) {
            if (accessItem) {
                return handleVisibility(accessItem, item)
            } else {
                return true;
            }
        }
        return true;
    };

    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return checkPermission(menu) ? <NavCollapse key={menu.id} menu={menu} level={1} /> : <></>;
            case 'item':
                return checkPermission(menu) ? <NavItem key={menu.id} item={menu} level={1} /> : <></>;
            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <>
            <List
                subheader={
                    item.title && (
                        <ItemTitleText variant="caption" display="block" gutterBottom>
                            {item.title}
                        </ItemTitleText>
                    )
                }
            >
                {items}
            </List>
        </>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
