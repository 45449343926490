import moment from 'moment';
import WebFont from 'webfontloader';
import {
    ASPECT_RATIO,
    CONTENT_MODULE_TYPE,
    FONT_FAMILIES,
    LOOP_SCHEDULE_TYPE,
    ORIENTATION,
    SOCIAL_MEDIA,
    categories_orders
} from './constants';
const _ = require('lodash');

export const isFunction = (fn) => {
    if (typeof fn === 'function') {
        return true;
    }
    return false;
};
export const getRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return '#' + randomColor;
};

export const getRandomNumber = () => {
    return Math.floor(Math.random() * 100);
};

export const toCapitalCase = (string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1) : '';
};

export const sortCategoryList = (data) => {
    let sortData = data.map((item) => {
        return { id: item.id, name: toCapitalCase(item.name), value: item.id, logo: item.logo, mirror_of_data: item?.mirror_of_data };
    });
    return sortData;
};

export const filterMirrorLocation = (locations) => {
    return locations?.filter((item) => !item?.mirror_of_data);
};

export const getZoneSizeByLayoutValue = (zone, orientation) => {
    const fullWidth = orientation === ORIENTATION.LANDSCAPE ? 1920 : 1080;
    const fullHeight = orientation === ORIENTATION.LANDSCAPE ? 1080 : 1920;
    return {
        height: (zone.height * fullHeight) / 100,
        width: (zone.width * fullWidth) / 100
    };
};

export const getOriantationByLayout = (layout) => {
    const contentZone = layout?.layout_admin?.zones?.find((item) => item.content === 'content');
    if (contentZone) {
        const { height, width } = getZoneSizeByLayoutValue(contentZone, layout?.orientation);
        if (width < height) {
            return ORIENTATION.PORTRAIT;
        } else {
            return ORIENTATION.LANDSCAPE;
        }
    } else {
        return layout?.orientation;
    }
};

export const formateTagsData = (data) => {
    let tagArray = [];

    for (let i = 0; i < data?.length; i++) {
        tagArray = [...tagArray, ...data[i].name.split(',')];
    }
    tagArray = tagArray.map((item, index) => {
        return { id: `tag_${index}`, name: item };
    });
    return tagArray;
};

export const splitToChunks = (array, parts) => {
    let result = [];
    for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
    }
    return result;
};
export const passMultipleFilesIntoFormData = (formData, fileArray, key) => {
    [...fileArray].forEach((file) => {
        formData.append(key, file);
    });
};

export const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];
    dates.push(startDate);
    var currDate = moment(startDate).startOf('day');
    var lastDate = moment(endDate).startOf('day');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
        dates.push(currDate.clone().toDate());
    }
    dates.push(endDate);
    return dates;
};

export const groupByKeyName = (array, key) => {
    return _.groupBy(array, key);
};

export const checkFormError = (errors) => {
    return Object.keys(errors).length !== 0;
};

export const formatTime = (input) => {
    let intValidNum = input;
    let returnValue = input;

    if (intValidNum < 24 && intValidNum.length === 2) {
        returnValue = returnValue + ':';
    }
    if (intValidNum === 24 && intValidNum.length === 2) {
        returnValue = returnValue.length - 2 + '0:';
    }
    if (intValidNum > 24 && intValidNum.length === 2) {
        returnValue = '';
    }

    if (intValidNum.length === 5 && intValidNum.slice(-2) < 60) {
        returnValue = returnValue + ':';
    }
    if (intValidNum.length === 5 && intValidNum.slice(-2) > 60) {
        returnValue = returnValue.slice(0, 2) + ':';
    }
    if (intValidNum.length === 5 && intValidNum.slice(-2) === 60) {
        returnValue = returnValue.slice(0, 2) + ':00:';
    }

    if (intValidNum.length === 8 && intValidNum.slice(-2) > 60) {
        returnValue = returnValue.slice(0, 5) + ':';
    }
    if (intValidNum.length === 8 && intValidNum.slice(-2) === 60) {
        returnValue = returnValue.slice(0, 5) + ':00';
    }

    return returnValue;
}; //end function

export const crop = (url, aspectRatio) => {
    // we return a Promise that gets resolved with our canvas element
    return new Promise((resolve) => {
        // this image will hold our source image data
        const inputImage = new Image();

        // we want to wait for our image to load
        inputImage.onload = () => {
            // let's store the width and height of our image
            const inputWidth = inputImage.naturalWidth;
            const inputHeight = inputImage.naturalHeight;

            // get the aspect ratio of the input image
            const inputImageAspectRatio = inputWidth / inputHeight;

            // if it's bigger than our target aspect ratio
            let outputWidth = inputWidth;
            let outputHeight = inputHeight;
            if (inputImageAspectRatio > aspectRatio) {
                outputWidth = inputHeight * aspectRatio;
            } else if (inputImageAspectRatio < aspectRatio) {
                outputHeight = inputWidth / aspectRatio;
            }

            // calculate the position to draw the image at
            const outputX = (outputWidth - inputWidth) * 0.5;
            const outputY = (outputHeight - inputHeight) * 0.5;

            // create a canvas that will present the output image
            const outputImage = document.createElement('canvas');

            // set it to the same size as the image
            outputImage.width = outputWidth;
            outputImage.height = outputHeight;

            // draw our image at position 0, 0 on the canvas
            const ctx = outputImage.getContext('2d');
            ctx.drawImage(inputImage, outputX, outputY);
            const base64Image = outputImage.toDataURL('image/jpeg', 1);
            resolve(base64Image);
        };

        // start loading our image
        inputImage.src = url;
    });
};

export const getAspectRatioWidthFromHeight = (height, aspectRatio) => {
    return height * aspectRatio;
};
export const getAspectRatioHeightFromWidth = (width, aspectRatio) => {
    return width / aspectRatio;
};

export const getPlaceholderRenderHeight = (id, spacing) => {
    let main = document.getElementById(id);
    return (main?.offsetWidth - spacing) / (ASPECT_RATIO[ORIENTATION.LANDSCAPE] + ASPECT_RATIO[ORIENTATION.PORTRAIT]);
};

export const getElementRenderSizeById = (id) => {
    let main = document.getElementById(id);
    return {
        height: main?.offsetHeight,
        width: main?.offsetWidth
    };
};

export const getElementRenderSizeScale = (resolution, scale) => {
    const mainHeight = resolution === ORIENTATION.LANDSCAPE ? 1080 : 1920;
    const mainWidth = resolution === ORIENTATION.LANDSCAPE ? 1920 : 1080;
    return {
        height: mainHeight * scale,
        width: mainWidth * scale
    };
};
function isFileImage(file) {
    return file && file['type'].split('/')[0] === 'image';
}

function isFileVideo(file) {
    return file && file['type'].split('/')[0] === 'video';
}

export const getFileResolution = async (blobUrl, fileName, fileType) => {
    const mediaBlob = await fetch(blobUrl).then((response) => response.blob());
    return new Promise((resolve, reject) => {
        const file = new File([mediaBlob], fileName, { type: fileType });
        if (isFileImage(file)) {
            const image = new window.Image();
            let url;
            image.onload = function () {
                file.width = image.width;
                file.height = image.height;
                resolve(file);
            };
            url = URL.createObjectURL(file);
            image.src = url;
        } else if (isFileVideo(file)) {
            const video = document.createElement('video');
            video.onload = function () {
                file.width = video.width;
                file.height = video.height;
                resolve(file);
            };
            video.addEventListener('loadedmetadata', (event) => {
                file.width = video.videoWidth;
                file.height = video.videoHeight;
                file.duration = video.duration;
                resolve(file);
            });
            video.src = URL.createObjectURL(file);
        } else {
            resolve(file);
        }
    });
};

export const getVideoDuration = async (url) => {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.addEventListener('loadedmetadata', (event) => {
            resolve({
                duration: video.duration
            });
        });
        video.src = url;
    });
};

export const checkVideoFile = (url) => {
    return new Promise((resolve) => {
        const PROXY_URL = 'https://cors-anywhere.herokuapp.com/';

        fetch(PROXY_URL + url, { method: 'HEAD' })
            .then((response) => {
                if (response.status === 200) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                resolve(false);
            });
    });
};

export const formateToDateTime = (date) => {
    return moment(date).format('YYYY/MM/DD HH:mm:ss');
};

export const combineDateAndTime = (date, time) => {
    return `${moment(date).format('YYYY/MM/DD')} ${moment(time).format('HH:mm:ss')}`;
};

export const secondTohhmmss = (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours.toString().padStart(2, '0'), minutes.toString().padStart(2, '0'), seconds.toString().padStart(2, '0')].join(':');
};

export const checkAllVideoStatus = (contents, librarys) => {
    let videoInLibrary = contents.filter((item) => librarys?.findIndex((e) => e.id === item.id) !== -1);
    if (videoInLibrary.length === contents.length) {
        return true;
    }
    return false;
};

export const checkSingleVideoStatus = (content, librarys) => {
    const libraryIndex = librarys?.findIndex((e) => e.id === content?.id);
    if (libraryIndex !== -1) {
        return true;
    }
    return false;
};

export const checkContentStatusInLibrary = (data, librarys) => {
    if (data?.module_type === CONTENT_MODULE_TYPE.GROUP) {
        return checkAllVideoStatus(data?.contents, librarys);
    } else {
        return checkSingleVideoStatus(data, librarys);
    }
};

export const getVideoOptionsFromSource = (src, poster) => {
    return {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        // poster: poster,
        sources: [
            {
                src: src,
                type: 'video/mp4'
            }
        ]
    };
};

export const copyShareURL = async (text) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
};

export const isVimeoUrl = (url) => {
    if (url) {
        return url.includes('vimeo.com');
    }
    return false;
};

export const isYouTubeVideoUrl = (url) => {
    if (url) {
        let pattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/watch\?v=([^&]+)/m;
        return pattern.test(url);
    }
    return false;
};

export const getYouTubeIdFromUrl = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
};

export const getVimeoIdFromUrl = (url) => {
    let splitedData = url.split('vimeo.com/');
    return splitedData[splitedData.length - 1];
};

export const formateLoopDataToCalenderEvents = (data) => {
    let oneTimeSchedules = data?.filter((item) => item?.loop_schedule?.[0]?.type === LOOP_SCHEDULE_TYPE.CUSTOM);
    let extraCustomScheduleArr = [];
    let returnData = oneTimeSchedules?.map((item) => {
        let schedules = item?.loop_schedule;
        return schedules?.map((schedule) => {
            if (schedule?.loop_schedules?.length > 1) {
                schedule?.loop_schedules.map((schedule1) => {
                    const scheduleData = {
                        id: item?.id,
                        startDate: moment(schedule1?.start_date_time).format('YYYY-MM-DD HH:mm:ss'),
                        endDate: moment(schedule1?.end_date_time).format('YYYY-MM-DD HH:mm:ss'),
                        loop_categories: item?.loop_categories,
                        loop_type: item?.loop_type,
                        color: 'red',
                        name: item?.name,
                        is_primary: item?.is_primary,
                        template_id: item?.template_id
                    };
                    extraCustomScheduleArr.push(scheduleData);
                });
            } else {
                return {
                    id: item?.id,
                    startDate: moment(schedule?.start_date_time).utc().format('YYYY-MM-DD HH:mm:ss'),
                    endDate: moment(schedule?.end_date_time).utc().format('YYYY-MM-DD HH:mm:ss'),
                    loop_categories: item?.loop_categories,
                    loop_type: item?.loop_type,
                    color: 'red',
                    name: item?.name,
                    is_primary: item?.is_primary,
                    template_id: item?.template_id
                };
            }
        });
    });
    let dailySchedules = data?.filter((item) => item?.loop_schedule?.[0]?.type !== LOOP_SCHEDULE_TYPE.CUSTOM);
    let extra = dailySchedules?.filter((item) => item.loop_schedule.length > 1);
    let extraScheduleArr = [];
    extra?.map((extraSchedule) => {
        return extraSchedule?.loop_schedule?.map((item) => {
            const data = {
                id: extraSchedule?.id,
                endTime: item?.end_time,
                startTime: item?.start_time,
                loop_categories: extraSchedule?.loop_categories,
                loop_type: extraSchedule?.loop_type,
                type: 'daily',
                name: extraSchedule?.name,
                is_primary: extraSchedule?.is_primary,
                template_id: extraSchedule?.template_id
            };
            extraScheduleArr.push(data);
        });
    });
    dailySchedules = dailySchedules?.map((item) => {
        return {
            id: item?.id,
            endTime: item?.loop_schedule?.[0]?.end_time,
            startTime: item?.loop_schedule?.[0]?.start_time,
            loop_categories: item?.loop_categories,
            loop_type: item?.loop_type,
            type: 'daily',
            name: item?.name,
            is_primary: item?.is_primary,
            template_id: item?.template_id
        };
    });
    returnData = returnData.reduce((a, b) => a.concat(b), []);
    returnData = [...dailySchedules, ...extraScheduleArr, ...returnData, ...extraCustomScheduleArr];

    return returnData;
};

export const splitTrimStringifyTags = (arrayList) => {
    let newArray = arrayList.length > 0 ? arrayList.split(',')?.map((n) => n.trim()) : [];
    return JSON.stringify(newArray);
};

export const newPlaylistAutoPositionRule = (data) => {
    let groupByPlaylist = Object.values(groupByKeyName(data, 'id'));
    const aAngles = Array.from({ length: groupByPlaylist[0].length }, (_, i) =>
        groupByPlaylist[0].length - 1 ? (i / (groupByPlaylist[0].length - 1)) * 360 : 0
    );

    const playlistArray = aAngles.map((angle, i) => ({
        playlist: groupByPlaylist[0][i],
        priority: 0,
        angle
    }));

    for (let i = 1; i < groupByPlaylist.length; i++) {
        const numElements = groupByPlaylist[i].length;
        const angles = Array.from({ length: numElements }, (_, i) => (numElements - 1 ? (i / (numElements - 1)) * 360 : 0));
        const playlistSubArray = angles.map((angle, j) => ({
            playlist: groupByPlaylist[i][j],
            priority: j + 1,
            angle
        }));
        playlistArray.push(...playlistSubArray);
    }

    const sortedPlaylists = playlistArray.sort((a, b) => a.angle - b.angle || a.priority - b.priority);

    let finalPlaylists = sortedPlaylists.map((item) => item.playlist);

    return finalPlaylists;
};

const interleaveEqual = (a, b) => {
    if (b.length !== a.length) {
        throw new Error('The two lists must be of equal length.');
    }
    const rlist = [];
    for (let i = 0; i < a.length; i++) {
        rlist.push(a[i]);
        rlist.push(b[i]);
    }
    return rlist;
};

const interleaveSpatially = (a, b) => {
    if (b.length === a.length) {
        return interleaveEqual(a, b);
    }

    let rlist = a;
    if (b.length > a.length) {
        rlist = b;
        b = a;
    }

    const spacing = rlist.length / b.length;

    for (let i = 0; i < b.length; i++) {
        let insertionIndex = spacing * (i + 1);
        insertionIndex = insertionIndex + i;
        insertionIndex = Math.round(insertionIndex);
        rlist.splice(insertionIndex, 0, b[i]);
    }

    return rlist;
};

export const processMedia = (plist) => {
    plist.sort((a, b) => b.plays.length - a.plays.length);
    const maxPlays = plist[0].plays.length;

    let equalIndex = 0;
    let allEqual = true;
    let someEqual = false;
    for (let i = 0; i < plist.length; i++) {
        const media = plist[i];
        if (maxPlays === media.plays.length) {
            equalIndex = i;
        } else {
            allEqual = false;
        }
    }
    someEqual = equalIndex !== 0;
    if (allEqual) {
        const dlist = [];
        const plays = plist[0].plays.length;
        for (let i = 0; i < plays; i++) {
            for (let j = 0; j < plist.length; j++) {
                const media = plist[j];
                dlist.push(media.plays[i]);
            }
        }
        return dlist;
    } else {
        let partial;
        if (someEqual) {
            partial = [];
            const plays = plist[equalIndex].plays.length;
            for (let i = 0; i < plays; i++) {
                for (let j = 0; j <= equalIndex; j++) {
                    const media = plist[j];
                    partial.push(media.plays[i]);
                }
            }
        } else {
            partial = [];
            const plays = plist[0].plays.length;
            for (let i = 0; i < plays; i++) {
                for (let j = 0; j <= equalIndex; j++) {
                    const media = plist[j];
                    partial.push(media.plays[i]);
                }
            }
        }

        const ret = processMedia(plist.slice(equalIndex + 1));
        const result = interleaveSpatially(partial, ret);
        return result;
    }
};

export const playlistAutoPositionRule = (data) => {
    if (data.length) {
        let newData = [...data];
        let groupByPlaylist = Object.values(groupByKeyName([...newData], 'id'));
        const plist = groupByPlaylist?.map((item) => {
            return {
                symbol: item?.[0]?.id,
                plays: item
            };
        });
        const resule = processMedia(plist);
        return resule;
    } else {
        return [];
    }
};

export const shortColorFromHighToLow = (data) => {
    let groupByPlaylist = Object.values(groupByKeyName(data, 'color'));
    groupByPlaylist.sort((a, b) => b.length - a.length);
    let finalPlaylists = [];
    finalPlaylists = groupByPlaylist.reduce((a, b) => a.concat(b), []);

    return finalPlaylists;
};

export const sortByProperty = (array = [], keyName = '') => {
    return array?.sort((a, b) => {
        const valueA = a?.[keyName]?.toUpperCase();
        const valueB = b?.[keyName]?.toUpperCase();
        if (valueA < valueB) return -1;
        if (valueA > valueB) return 1;
        return 0;
    });
};

export const getAccessIdForPlaylistCategory = (id) => {
    return `playlist_${id}`;
};

export const getAccessObjectFromArrayObject = (arrayObject, accessKeyName) => {
    if (arrayObject) {
        const accessItem = arrayObject?.find((acc) => acc.hasOwnProperty(accessKeyName));
        if (accessItem) {
            return accessItem[accessKeyName];
        }
    }
    return { visibility: 1, status: 1 };
};

export const getAccessObjectFromArrayObjectIntegrationApp = (arrayObject, accessKeyName) => {
    if (arrayObject) {
        const accessItem = arrayObject?.find((acc) => acc.hasOwnProperty(accessKeyName));
        if (accessItem) {
            return accessItem[accessKeyName];
        }
    }
    return { visibility: 1, status: isAddedInterationApp(accessKeyName) ? 0 : 1 };
};

export const handleVisibility = (accessItem, navItem = {}) => {
    if (accessItem?.hasOwnProperty(navItem?.globalAccessKey)) {
        return accessItem?.visibility && accessItem?.[navItem?.globalAccessKey];
    } else return accessItem?.visibility;
};

export const getAllFontLinks = (data = []) => {
    if (!data && !data.length) return [];
    const apiUrls = [];
    data?.forEach((value) => {
        let font = [];
        font.push('https://fonts.googleapis.com/css?family=');
        font.push(value.family.replace(/ /g, '+'));
        if (value.variants === 'italic') {
            font.push(':');
            font.push('italic');
        }
        if (value.subsets === 'greek') {
            font.push('&subset=');
            font.push('greek');
        }
        let url = font.join('');
        apiUrls.push(url);
    });
    return apiUrls;
};

export let loadFont = function () {
    WebFont.load({
        google: {
            families: FONT_FAMILIES.map((val) => val.font)
        }
    });
};

const componentToHex = (c) => {
    var hex = c.toString(16);
    return hex.length == 1 ? '0' + hex : hex;
};

export const rgbToHex = (r, g, b) => {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export const hexToRGB = (hexCode = '') => {
    if (!hexCode) return { r: 0, g: 0, b: 0, a: 1 };
    const r = parseInt(hexCode.slice(1, 3), 16);
    const g = parseInt(hexCode.slice(3, 5), 16);
    const b = parseInt(hexCode.slice(5, 7), 16);
    return { r, g, b };
};

export const hexToHSV = (hexCode = '') => {
    if (!hexCode) return { h: 0, s: 0, v: 0, a: 1 };
    hexCode = hexCode.replace(/^#/, '');

    const r = parseInt(hexCode.slice(0, 2), 16) / 255;
    const g = parseInt(hexCode.slice(2, 4), 16) / 255;
    const b = parseInt(hexCode.slice(4, 6), 16) / 255;

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    let h, s, v;

    if (max === min) {
        h = 0; // Achromatic (grayscale)
    } else if (max === r) {
        h = 60 * ((g - b) / (max - min));
    } else if (max === g) {
        h = 60 * (2 + (b - r) / (max - min));
    } else if (max === b) {
        h = 60 * (4 + (r - g) / (max - min));
    }

    if (h < 0) {
        h += 360;
    }

    if (max === 0) {
        s = 0;
    } else {
        s = (max - min) / max;
    }

    v = max;

    h = Math.round(h);
    s = Math.round(s * 100);
    v = Math.round(v * 100);

    return { h, s, v };
};

let timerId = null;

export const debounced = (callback, wait) => {
    if (timerId) {
        clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
        callback();
    }, wait);
};

export const filteredByArray = (mainArray = [], filter = [], key = '') => {
    if (!mainArray?.length) return [];
    if (!filter?.length) return mainArray;
    const cloneData = mainArray.filter((data) => {
        if (filter.includes(data[key])) {
            return data;
        } else if (filter?.map((val) => val?.toUpperCase()).includes(data[key]?.toUpperCase())) {
            return data;
        } else if (filter?.map((val) => val?.toLowerCase()).includes(data[key]?.toLowerCase())) {
            return data;
        } else return null;
    });

    return cloneData;
};

export const changeCase = (value = '') => {
    if (!value) return '';
    return value
        ?.trim()
        ?.split('')
        ?.map((char) => char?.toUpperCase())
        .join('');
};

export const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
    });
};

export const checkResolution = ({ width, height }) => {
    if (width >= 3840 && height >= 2160) {
        // 4K or above
        return 'hvc1';
    } else return 'avc1'; // UHD, HD, or lower
};

export const extractDomain = (url = '') => {
    const domainMatch = url?.match(/^https?:\/\/[^/]+/);
    return domainMatch ? `${domainMatch?.[0]}/` : null;
};

export const activePath = () => window.location.pathname || null;

export const getSubFolders = (url = '') => {
    if (!url) return null;
    const urlArray = url?.split('/');
    urlArray?.splice(0, 3);
    urlArray?.pop();
    return urlArray?.length ? urlArray?.join('/') : null;
};

export const reorder_categories = (categories = []) => {
    const clone = [...categories];
    let orderArray = [];
    clone.forEach((data) => {
        const orderData = categories.map((item) => {
            return {
                ...item,
                order: categories_orders.find((val) => val.id == item.id)?.order
            };
        });

        const unOrderCategory = orderData?.filter((item) => !item?.order);
        orderArray = orderData
            ?.filter((item) => item?.order)
            ?.sort(function (a, b) {
                return a.order - b.order;
            });
        orderArray = [...orderArray, ...unOrderCategory];
    });
    return orderArray?.map((val) => val?.id);
};

export const isAddedInterationApp = (app) => {
    return !Object.values(SOCIAL_MEDIA)?.some((mediaType) => mediaType === app);
};
