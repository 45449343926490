import { Backdrop, Box, CircularProgress, Grid, Rating, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Slider from 'react-slick';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { api } from '../../../api';
import { getInstagramAccounts, getInstagramReviews } from '../../../api/external';
import { COLORS, theme } from '../../../utils/theme';
import { IMAGES } from '../../../assets';
import { useParams } from 'react-router-dom';

const INSTAGRAM_REVIEW_DATA = [
    {
        id: 1,
        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 2,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Integer ac gravida urna, sit amet interdum tortor. Sed et nisi malesuada, faucibus massa in, auctor nunc. Vestibulum vel dolor nec arcu gravida rutrum. Nullam tempor eros sit amet diam porttitor scelerisque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 3,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Vivamus et tortor magna. Nulla facilisi. Nam non tristique arcu, in eleifend ipsum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 4,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Donec in odio ex. Sed bibendum felis vitae mi fermentum sagittis. Proin accumsan enim eget est semper vestibulum. Nunc vulputate libero non tellus posuere efficitur. Sed non malesuada tortor, at interdum nisl. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 5,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Fusce at lectus tincidunt, efficitur ex at, bibendum arcu. Curabitur ac mi ut nisl cursus dictum. Sed hendrerit pharetra odio a facilisis. Integer in elit non arcu tempor auctor. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 6,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Quisque at lacus metus. Integer sollicitudin purus id tortor pellentesque aliquam. Etiam ac est et sapien efficitur commodo. Morbi dignissim, ipsum eu euismod auctor, odio dui luctus nunc, at malesuada lorem est sit amet lacus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 7,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Aliquam ac tincidunt nibh. Nam volutpat diam id tincidunt rutrum. In vitae nisi leo. Suspendisse ornare lorem a orci volutpat, et efficitur mauris hendrerit. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 8,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Vivamus lacinia, felis ac convallis dignissim, velit enim auctor lacus, ut feugiat sem justo vitae justo. Ut a ante eu nibh placerat tempus sed et quam. Sed et purus non orci efficitur fermentum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 9,

        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Nunc ac fermentum urna, nec viverra arcu. Nulla facilisi. Mauris volutpat augue non leo laoreet, in varius libero ultricies. Sed condimentum vestibulum tincidunt. Nullam tincidunt quam eget enim luctus pharetra.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    },
    {
        id: 10,
        doctorName: 'Dr. Orthodontist',
        doctorProfileImage: IMAGES.doctorProfile,
        likes: '225',
        review: 'Phasellus congue nisl nec ipsum vestibulum, sed finibus metus luctus. Mauris pulvinar, lacus a fringilla dignissim, risus lacus iaculis dolor, a congue. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vitae lorem odio. Suspendisse potenti. Nunc placerat rhoncus dolor, eget bibendum ligula eleifend id. Cras vitae est in mi mollis scelerisque ut a erat.'
    }
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const MainBox = styled(Box)`
    background: radial-gradient(circle at 30% 107%, #f8ec61 0%, #faee69 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
    & .slick-track {
        height: 100vh;
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 3840px) {
        width: calc(100% - 200px);
        padding: 0px 100px;
    }
    @media screen and (max-width: 3840px) {
        width: calc(100% - 200px);
        padding: 0px 100px;
    }
    @media screen and (max-width: 2560px) {
        width: calc(100% - 150px);
        padding: 0px 75px;
    }
    @media screen and (max-width: 1920px) {
        width: calc(100% - 100px);
        padding: 0px 50px;
    }
    @media screen and (max-width: 1280px) {
        width: calc(100% - 80px);
        padding: 0px 40px;
    }
    @media screen and (max-width: 720px) {
        width: calc(100% - 60px);
        padding: 0px 30px;
    }
`;

const ReviewContainer = styled(Box)`
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 3840px) {
        gap: 100px;
        height: calc(100vh - 300px);
        padding: 150px 0px;
    }
    @media screen and (max-width: 3840px) {
        gap: 100px;
        height: calc(100vh - 300px);
        padding: 150px 0px;
    }
    @media screen and (max-width: 2560px) {
        gap: 65px;
        height: calc(100vh - 200px);
        padding: 100px 0px;
    }
    @media screen and (max-width: 1920px) {
        gap: 40px;
        height: calc(100vh - 130px);
        padding: 65px 0px;
    }
    @media screen and (max-width: 1280px) {
        gap: 30px;
        height: calc(100vh - 110px);
        padding: 55px 0px;
    }
    @media screen and (max-width: 720px) {
        gap: 20px;
        height: calc(100vh - 70px);
        padding: 35px 0px;
    }
`;

const ReviewHeader = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.isPortrait ? 'center' : 'space-between')};
    flex-wrap: ${(props) => (props.isPortrait ? 'wrap' : 'noWrap')};
`;

const ReviewCard = styled(Box)`
    overflow: hidden;
    background-color: ${COLORS.white};
    height: 100%;
    width: 100%;
    @media screen and (min-width: 3840px) {
        border-radius: 60px;
        box-shadow: ${theme.shadows[14]};
    }
    @media screen and (max-width: 3840px) {
        border-radius: 50px;
        box-shadow: ${theme.shadows[12]};
    }
    @media screen and (max-width: 2560px) {
        border-radius: 40px;
        box-shadow: ${theme.shadows[10]};
    }
    @media screen and (max-width: 1920px) {
        border-radius: 30px;
        box-shadow: ${theme.shadows[8]};
    }
    @media screen and (max-width: 1280px) {
        border-radius: 20px;
        box-shadow: ${theme.shadows[6]};
    }
    @media screen and (max-width: 720px) {
        border-radius: 10px;
        box-shadow: ${theme.shadows[4]};
    }
`;

const ReviewCardContainer = styled(Box)`
    overflow: hidden;
    @media screen and (min-width: 3840px) {
        margin: 100px;
        height: calc(100% - 200px);
    }
    @media screen and (max-width: 3840px) {
        margin: 100px;
        height: calc(100% - 200px);
    }
    @media screen and (max-width: 2560px) {
        margin: 75px;
        height: calc(100% - 150px);
    }
    @media screen and (max-width: 1920px) {
        margin: 50px;
        height: calc(100% - 100px);
    }
    @media screen and (max-width: 1280px) {
        margin: 40px;
        height: calc(100% - 80px);
    }
    @media screen and (max-width: 720px) {
        margin: 30px;
        height: calc(100% - 60px);
    }
`;

const Likes = styled(Typography)`
    color: ${COLORS.white};
    text-align: ${(props) => props.isPortrait && 'center'};
    @media screen and (min-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 140px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 125px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 75px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 65px;
    }
    @media screen and (max-width: 950px) {
        font-size: 55px;
    }
    @media screen and (max-width: 800px) {
        font-size: 45px;
    }
    @media screen and (max-width: 720px) {
        font-size: 35px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.isPortrait && '200px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.isPortrait && '90px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.isPortrait && '70px'};
    }
`;

const InstagramText = styled(Typography)`
    color: ${COLORS.white};
    @media screen and (min-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 140px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 125px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 75px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 65px;
    }
    @media screen and (max-width: 950px) {
        font-size: 55px;
    }
    @media screen and (max-width: 800px) {
        font-size: 45px;
    }
    @media screen and (max-width: 720px) {
        font-size: 35px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.isPortrait && '200px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.isPortrait && '90px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.isPortrait && '70px'};
    }
`;

const InstagramImageContainer = styled(Box)`
    display: flex;
    align-items: center;
    width: ${(props) => (props.isPortrait ? '100%' : '')};
    justify-content: ${(props) => (props.isPortrait ? 'space-between' : 'center')};
`;

const InstagramImage = styled('img')`
    border-radius: 30%;
    @media screen and (min-width: 3840px) {
        height: 260px;
        border: 12px solid ${COLORS.white};
        margin-left: 50px;
    }
    @media screen and (max-width: 3840px) {
        height: 260px;
        border: 12px solid ${COLORS.white};
        margin-left: 50px;
    }
    @media screen and (max-width: 3100px) {
        height: 230px;
        border: 11px solid ${COLORS.white};
        margin-left: 55px;
    }
    @media screen and (max-width: 2560px) {
        height: 200px;
        border: 10px solid ${COLORS.white};
        margin-left: 40px;
    }
    @media screen and (max-width: 1920px) {
        height: 130px;
        border: 8px solid ${COLORS.white};
        margin-left: 30px;
    }
    @media screen and (max-width: 1280px) {
        height: 100px;
        border: 6px solid ${COLORS.white};
        margin-left: 20px;
    }
    @media screen and (max-width: 950px) {
        height: 90px;
        border: 5px solid ${COLORS.white};
        margin-left: 15px;
    }
    @media screen and (max-width: 800px) {
        height: 70px;
        border: 4px solid ${COLORS.white};
        margin-left: 12px;
    }
    @media screen and (max-width: 720px) {
        height: 50px;
        border: 3px solid ${COLORS.white};
        margin-left: 10px;
    }
    @media screen and (min-height: 3840px) {
        height: ${(props) => props.isPortrait && '300px'};
        border: ${(props) => props.isPortrait && `12px solid ${COLORS.white}`};
        margin-left: ${(props) => props.isPortrait && '0px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        height: ${(props) => props.isPortrait && '140px'};
        border: ${(props) => props.isPortrait && `8px solid ${COLORS.white}`};
        margin-left: ${(props) => props.isPortrait && '0px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        height: ${(props) => props.isPortrait && '120px'};
        border: ${(props) => props.isPortrait && `8px solid ${COLORS.white}`};
        margin-left: ${(props) => props.isPortrait && '0px'};
    }
`;

const CommentContainer = styled(Box)`
    height: calc(100% - ${(props) => props.nameContainerHeight}px);
    overflow: hidden;
`;

const DetailsText = styled(Typography)`
    color: ${COLORS.black};
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.numLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 3840px) {
        font-size: 130px;
        line-height: 145px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 130px;
        line-height: 145px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 100px;
        line-height: 125px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 90px;
        line-height: 115px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 55px;
        line-height: 70px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 45px;
        line-height: 60px;
    }
    @media screen and (max-width: 950px) {
        font-size: 35px;
        line-height: 50px;
    }
    @media screen and (max-width: 800px) {
        font-size: 25px;
        line-height: 40px;
    }
    @media screen and (max-width: 720px) {
        font-size: 25px;
        line-height: 40px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.isPortrait && '130px'};
        line-height: ${(props) => props.isPortrait && '150px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.isPortrait && '70px'};
        line-height: ${(props) => props.isPortrait && '85px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.isPortrait && '45px'};
        line-height: ${(props) => props.isPortrait && '60px'};
    }
`;

const NameContainer = styled(Box)`
    display: flex !important;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 3840px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 3840px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 2560px) {
        padding-bottom: 40px;
    }
    @media screen and (max-width: 1920px) {
        padding-bottom: 30px;
    }
    @media screen and (max-width: 1280px) {
        padding-bottom: 30px;
    }
    @media screen and (max-width: 720px) {
        padding-bottom: 10px;
    }
`;

const ProfileImage = styled('img')`
    @media screen and (min-width: 3840px) {
        height: 240px;
        margin-right: 30px;
    }
    @media screen and (max-width: 3840px) {
        height: 240px;
        margin-right: 30px;
    }
    @media screen and (max-width: 3100px) {
        height: 160px;
        margin-right: 30px;
    }
    @media screen and (max-width: 2560px) {
        height: 150px;
        margin-right: 25px;
    }
    @media screen and (max-width: 1920px) {
        height: 100px;
        margin-right: 15px;
    }
    @media screen and (max-width: 1280px) {
        height: 80px;
        margin-right: 10px;
    }
    @media screen and (max-width: 950px) {
        height: 70px;
        margin-right: 8px;
    }
    @media screen and (max-width: 800px) {
        height: 60px;
        margin-right: 7px;
    }
    @media screen and (max-width: 720px) {
        height: 50px;
        margin-right: 6px;
    }
    @media screen and (min-height: 3840px) {
        height: ${(props) => props.isPortrait && '240px'};
        margin-right: ${(props) => props.isPortrait && '30px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        height: ${(props) => props.isPortrait && '150px'};
        margin-right: ${(props) => props.isPortrait && '25px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        height: ${(props) => props.isPortrait && '80px'};
        margin-right: ${(props) => props.isPortrait && '10px'};
    }
`;

const PatientImage = styled('img')`
    width: ${(props) => (props.isPortrait ? '70%' : '100%')};
    margin-top: ${(props) => (props.isPortrait ? '20px' : '0px')};
    @media screen and (min-height: 3840px) {
        margin-top: ${(props) => (props.isPortrait ? '120px' : '0px')};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        margin-top: ${(props) => (props.isPortrait ? '60px' : '0px')};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        margin-top: ${(props) => (props.isPortrait ? '30px' : '0px')};
    }
`;

const NameText = styled(Typography)`
    color: #d6249f;
    @media screen and (min-width: 3840px) {
        font-size: 160px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 160px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 100px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 90px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 55px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 45px;
    }
    @media screen and (max-width: 950px) {
        font-size: 35px;
    }
    @media screen and (max-width: 800px) {
        font-size: 25px;
    }
    @media screen and (max-width: 720px) {
        font-size: 25px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.isPortrait && '160px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.isPortrait && '70px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.isPortrait && '45px'};
    }
`;

export const InstagramReview = () => {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const textContainerRef = useRef(null);
    const nameContainerRef = useRef(null);
    const detailsTextRef = useRef(null);
    const patientImageRef = useRef(null);
    const [numLines, setNumLines] = useState(0);
    const [nameContainerHeight, setNameContainerHeight] = useState(0);

    const [reviewDetails, setReviewDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialSlide, setInitialSlide] = useState('unset');
    const { enqueueSnackbar } = useSnackbar();

    const params = useParams();
    const client_id = params.client_id;
    const run = new URLSearchParams(window.location.search).get('run') || 0;
    const totalDuration = new URLSearchParams(window.location.search).get('duration') || 40;
    const durationPerField = new URLSearchParams(window.location.search).get('each_feed_time') || 10;
    const numberofFeedInOneRun = totalDuration / durationPerField;

    const handleResize = () => {
        setIsPortrait(window.innerHeight > window.innerWidth);

        const containerHeight = textContainerRef.current?.clientHeight;
        const lineHeightData = parseInt(getComputedStyle(detailsTextRef.current).lineHeight);
        const newNumLines = Math.floor(containerHeight / lineHeightData);
        setNumLines(newNumLines);
    };

    const handleImageLoad = () => {
        if (patientImageRef.current) {
            setNameContainerHeight(nameContainerRef.current?.clientHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        if (patientImageRef.current) {
            patientImageRef.current.addEventListener('load', handleImageLoad);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            if (patientImageRef.current) {
                patientImageRef.current.removeEventListener('load', handleImageLoad);
            }
        };
    }, []);

    useEffect(() => {
        if (reviewDetails) {
            handleResize();
        }
        setNameContainerHeight(nameContainerRef.current?.clientHeight);
    }, [nameContainerRef.current?.clientHeight, reviewDetails]);

    const getFacebookInfo = useCallback(async () => {
        try {
            setLoading(true);
            if (client_id) {
                const { data } = await api.review.instagramFeed({ params: { client_id } });
                setReviewDetails(data?.result);
                let slideToFirst = (run * numberofFeedInOneRun) % data?.result?.posts?.length;
                setInitialSlide(Math.round(slideToFirst));
                setLoading(false);
                if (!data.success) {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar('Our server error' + error.message, { variant: 'error' });
        }
    }, [enqueueSnackbar, client_id]);

    useEffect(() => {
        getFacebookInfo();
    }, [getFacebookInfo]);

    return (
        <MainBox>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {reviewDetails && initialSlide !== 'unset' && !loading && (
                <Slider
                    pauseOnHover={false}
                    arrows={false}
                    dots={false}
                    nextArrow={false}
                    initialSlide={initialSlide}
                    autoplaySpeed={durationPerField * 1000}
                    autoplay={true}
                    {...settings}
                >
                    {reviewDetails &&
                        reviewDetails?.posts.map((post) => {
                            return (
                                <ReviewContainer>
                                    <ReviewHeader isPortrait={isPortrait}>
                                        <InstagramImageContainer isPortrait={isPortrait}>
                                            <InstagramText isPortrait={isPortrait}>Find Us On</InstagramText>
                                            <InstagramImage isPortrait={isPortrait} src={IMAGES.InstagramLogo} />
                                        </InstagramImageContainer>
                                        {!isPortrait && <Likes>{post.like_count} Likes</Likes>}
                                    </ReviewHeader>
                                    <ReviewCard>
                                        <ReviewCardContainer>
                                            <Grid container sx={{ height: '100%' }} columnSpacing={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 10 }}>
                                                <Grid item xs={isPortrait ? 12 : 8} sx={{ height: '100%' }}>
                                                    <NameContainer ref={nameContainerRef}>
                                                        <Box sx={{ display: 'flex', alignSelf: 'flex-start', alignItems: 'center' }}>
                                                            <ProfileImage
                                                                isPortrait={isPortrait}
                                                                src={reviewDetails.profile}
                                                            ></ProfileImage>
                                                            <NameText isPortrait={isPortrait}>{reviewDetails.name}</NameText>
                                                        </Box>
                                                        {isPortrait && (
                                                            <PatientImage
                                                                ref={patientImageRef}
                                                                isPortrait={isPortrait}
                                                                src={post?.url}
                                                                alt="patientImage"
                                                            />
                                                        )}
                                                    </NameContainer>
                                                    <CommentContainer nameContainerHeight={nameContainerHeight} ref={textContainerRef}>
                                                        <DetailsText isPortrait={isPortrait} ref={detailsTextRef} numLines={numLines}>
                                                            {post.caption}
                                                        </DetailsText>
                                                    </CommentContainer>
                                                </Grid>
                                                {!isPortrait && (
                                                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <PatientImage src={post?.url} alt="patientImage" />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </ReviewCardContainer>
                                    </ReviewCard>
                                    {isPortrait && <Likes isPortrait={isPortrait}>{post.like_count} Likes</Likes>}
                                </ReviewContainer>
                            );
                        })}
                </Slider>
            )}
        </MainBox>
    );
};
