import { Box, Grid, IconButton, Typography } from '@mui/material'
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { CommonHeader } from '../../../widgets/CommonHeader'
import { CommonTable } from '../../../components/Table';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { COLORS } from '../../../utils/theme';
import AddEditVersionModal from './components/AddEditVersionModal';
import { fetchVersions } from '../../../redux/reducers/versionSlice';
import { ICONS } from '../../../assets';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { DELETE_CONFIRMATION } from '../../../utils/constants';
import { api } from '../../../api';
import { useSnackbar } from 'notistack';

const AccountName = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});

const VersionLabel = styled(Typography)({
    fontSize: '0.875rem',
    fontWeight: '500',
    color: COLORS.black,
    cursor: 'pointer',
    textAlign: 'left'
});

const TrashButton = styled(IconButton)({
    margin: '0px 0px 0px 8px',
    background: COLORS.primary.danger,
    borderRadius: '0.425rem',
    '&:hover': {
        background: COLORS.hover.danger
    }
})

const TrashIcon = styled(ICONS.Trash)({
    color: COLORS.white,
    width: 16
})

export const SystemVersion = () => {
    const { enqueueSnackbar } = useSnackbar()
    const [modal, setModal] = useState({ isOpen: false, type: '', data: {} });
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [versionData, setVersionData] = useState({});
    const versions = useSelector(({ version }) => version?.versions);
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchVersions()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const NameElement = ({ item, type }) => {
        return type === 'date' ? <VersionLabel>{moment(item.createdAt).format('DD/MM/YYYY')}</VersionLabel> : <AccountName>{item.name}</AccountName>;
    };

    const ActionsContainer = ({ item }) => {
        return <TrashButton onClick={() => {
            setVersionData(item)
            setIsOpen(true)
        }} >
            <TrashIcon />
        </TrashButton>
    }

    const versionColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Version Name',
            render: (row) => {
                return <NameElement item={row.rowData} />;
            }
        },
        {
            id: 'createdAt',
            fieldName: 'createdAt',
            label: 'Date Created',
            render: (row) => {
                return <NameElement item={row.rowData} type='date' />;
            }
        },
        {
            id: 'id',
            fieldName: 'id',
            label: 'Actions',
            align: 'center',
            render: (row) => {
                return <ActionsContainer item={row.rowData} />;
            }
        }
    ];

    const handleDeleteVersion = async () => {
        try {
            const { data } = await api.version.deleteVersion(versionData.id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                dispatch(fetchVersions())
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setIsOpen(false)
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    return (
        <Box>
            <CommonHeader
                title={'Versions'}
                searchbar={true}
                buttonLabel={'Add New Version'}
                searchValue={searchValue}
                handleSearch={(search) => setSearchValue(search)}
                onClick={() => setModal({ isOpen: true, type: 'add' })}
            />
            <Grid sx={{ marginTop: '24px' }} container>
                <CommonTable
                    search={searchValue}
                    // handleRowDetail={(data) => setModal({ isOpen: true, type: 'edit', data })}
                    columns={versionColumns}
                    rows={versions}
                    loading={isLoading}
                    searchableFields={['name']}
                />
            </Grid>
            <AddEditVersionModal
                open={modal.isOpen}
                type={modal.type}
                versionData={modal.data}
                handleClose={() => setModal({ isOpen: false, type: '', data: {} })}
            />
            <AlertDialog
                isOpen={isOpen}
                isClose={() => { setIsOpen(false) }}
                handleSubmit={handleDeleteVersion}
                title={DELETE_CONFIRMATION.title}
                description={DELETE_CONFIRMATION.description + 'Version'}
                isCancel={'No'}
            />
        </Box>
    )
}