import { Box, styled, useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    ASPECT_RATIO,
    BACKGROUND_ACTION_TYPE,
    HEADER_MENU_TYPE,
    ORIENTATION,
    SHAPE_ACTION_TYPE,
    TEXT_ACTION_TYPE
} from '../../utils/constants';
import { EditorHeader } from './component/EditorHeader';
import { EditorRightActions } from './component/EditorRightActions';
import { fabric } from 'fabric';
import { COLORS } from '../../utils/theme';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getAspectRatioWidthFromHeight, getElementRenderSizeScale, loadFont } from '../../utils/helpers';
import Loader from '../Loader';
import { IMAGES } from '../../assets';
import { handleChangeAction, modifyEditorConfig } from '../../redux/reducers/editorSlice';
import { useTheme } from '@mui/material/styles';

const MainBox = styled(Box)({
    flexDirection: 'column',
    backgroundColor: 'transparent',
    borderRadius: '0.425rem'
});

const ContainMainView = styled(Box)({
    display: 'flex',
    justifyContent: 'end',
    background: 'white',
    borderLeft: `1px solid ${COLORS.border}`,
    borderRight: `1px solid ${COLORS.border}`,
    borderBottom: `1px solid ${COLORS.border}`,
    borderBottomLeftRadius: '0.425rem',
    borderBottomRightRadius: '0.425rem'
});

var globalIsStopPropagation = false;

export const Editor = ({ onSave, initialData, loading, width, height, content, libraryStatus }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const fabricRef = useRef(null);
    const canvasRef = useRef(null);
    const canvasContainerRef = useRef(null);
    const dispatch = useDispatch();
    const { orientation } = useSelector((state) => state.orientation);
    const editor = useSelector((state) => state.editor);
    const { id } = useParams();
    const navigate = useNavigate();
    const EDITOR_WIDTH = '100%';
    const CANVAS_WIDTH = orientation === ORIENTATION.LANDSCAPE ? 1920 : 1080;
    const CANVAS_HEIGHT = orientation === ORIENTATION.LANDSCAPE ? 1080 : 1920;
    const [isEventInitialize, setIsEventInitialize] = useState(false);
    const [actionHeaderItem, setActionHeaderItem] = useState(HEADER_MENU_TYPE.BACKGROUND);
    const [actionProvider, setActionProvider] = useState('');
    const [unSavedChange, setUnSavedChange] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    globalIsStopPropagation = editor.isStopPropagation;

    const CANVAS_RENDER_HEIGHT = matchUpMd ? width / 2 - 250 : width / 2 - 100;
    const CANVAS_MAIN_HEIGHT = orientation === ORIENTATION.LANDSCAPE ? 1080 : 1920;

    useEffect(() => {
        const initFabric = () => {
            fabricRef.current = new fabric.Canvas(canvasRef.current, {
                width: CANVAS_WIDTH,
                height: CANVAS_HEIGHT,

                backgroundColor: '#d8d8d8',
                preserveObjectStacking: true
            });
        };

        const disposeFabric = () => {
            fabricRef.current.dispose();
        };
        initFabric();
        setIsLoading(false);

        return () => {
            disposeFabric();
        };
    }, [CANVAS_HEIGHT, CANVAS_WIDTH]);

    const handleDeleteObject = useCallback(() => {
        document.addEventListener('keydown', function (event) {
            const key = event.key; // const {key} = event; ES6+
            if (key === 'Delete') {
                const activeObject = fabricRef.current.getActiveObject();
                if (activeObject) {
                    fabricRef.current.remove(activeObject);
                    fabricRef.current.renderAll();
                    if (id) {
                        setUnSavedChange(true);
                    }
                }
            }
        });
    }, [id]);

    const renderGridLineInCanvas = useCallback(() => {
        if (!fabricRef.current) return;
        const width = fabricRef?.current?.width;
        const height = fabricRef?.current?.height;
        const option = {
            stroke: 'black',
            strokeWidth: 0,
            opacity: 0,
            selectable: false
        };
        fabricRef?.current.add(new fabric.Line([width / 2, 0, width / 2, height], option));
        fabricRef?.current.add(new fabric.Line([width, height / 2, 0, height / 2], option));
        fabricRef.current.renderAll();
    }, []);

    useEffect(() => {
        if (fabricRef.current) {
            if (!isEventInitialize) {
                if (initialData) {
                    fabricRef.current.loadFromJSON(initialData, fabricRef.current.renderAll.bind(fabricRef.current));
                }
                setIsEventInitialize(true);
                handleDeleteObject();
                fabricRef.current.on('object:modified', (event) => {
                    const canvas = fabricRef?.current;
                    const triggerElement = event.target;
                    handleLineOpacity(canvas?.getObjects('line'), 0);
                    setActionProvider(triggerElement?.type);
                    dispatch(modifyEditorConfig({ ...currentConfig(triggerElement) }));
                    setUnSavedChange(true);
                });

                fabricRef.current.on('mouse:over', (event) => {
                    const { target } = event;
                    // skip group hover
                    if (target instanceof fabric.Object && !(target instanceof Array)) {
                        const bound = target.getBoundingRect();
                        const ctx = fabricRef.current.getContext();
                        ctx.strokeStyle = COLORS.primary.light;
                        ctx.lineWidth = 3;
                        ctx.strokeRect(bound.left, bound.top, bound.width, bound.height);
                    }
                });

                fabricRef.current.on('mouse:out', (event) => {
                    const { target } = event;
                    // skip group hover
                    if (target instanceof fabric.Object && !(target instanceof Array)) {
                        fabricRef.current.renderAll(); // render all, will clear bounds box drew by mouse:over
                    }
                });
                handleCustomControlsInObject();
            }
            renderGridLineInCanvas();
        }
    }, [initialData, isEventInitialize, fabricRef, handleDeleteObject, dispatch, renderGridLineInCanvas]);

    useEffect(() => {
        if (canvasContainerRef.current) {
            function handleClickOutside(event) {
                if (canvasContainerRef.current && !canvasContainerRef.current.contains(event.target)) {
                    if (fabricRef?.current && !globalIsStopPropagation) {
                        fabricRef?.current?.discardActiveObject();
                        fabricRef.current.renderAll();
                    }
                }
            }
            document.addEventListener('click', handleClickOutside);
        }
        return () => {
            document.removeEventListener('click', () => { });
        };
    }, [canvasContainerRef, editor?.isStopPropagation]);

    const currentConfig = ({ type, width, height, radius, scaleX, scaleY, opacity, fill, textAlign, fontSize, fontFamily, ...rest }) => {
        return {
            type,
            width,
            height,
            radius,
            scaleX,
            scaleY,
            opacity: opacity * 100,
            activeColor: fill,
            activeTextAlignment: textAlign,
            fontSize,
            actionFontFamily: fontFamily
        };
    };

    const updateConfig = ({ id, ...rest }) => {
        const isIncrease = rest?.value === 'increase';
        const width = isIncrease ? editor?.width + (editor?.width * 5) / 100 : editor?.width - (editor?.width * 5) / 100;
        const height = isIncrease ? editor?.height + (editor?.height * 5) / 100 : editor?.height - (editor?.height * 5) / 100;
        const scaleX = isIncrease ? editor?.scaleX + (editor?.scaleX * 5) / 100 : editor?.scaleX - (editor?.scaleX * 5) / 100;
        const scaleY = isIncrease ? editor?.scaleY + (editor?.scaleY * 5) / 100 : editor?.scaleY - (editor?.scaleY * 5) / 100;
        switch (id) {
            case 'image':
                return { scaleX, scaleY };
            case 'rect':
            case 'triangle':
                return { width, height };
            case 'circle':
                return { width, height, radius: width / 2 };
            default:
                return {};
        }
    };

    const handleLineOpacity = (elements = [], opacity = 0) => {
        elements?.forEach((val) => {
            val.opacity = opacity
            val.strokeWidth = 2
        });
    };

    useEffect(() => {
        if (fabricRef.current) {
            loadFont();
            fabricRef.current.on('selection:updated', (event) => {
                const triggerElement = event.selected[0];
                setActionProvider(triggerElement?.type);
                dispatch(modifyEditorConfig({ ...currentConfig(triggerElement) }));
                fabricRef.current.getActiveObject().borderScaleFactor = 3;
                fabricRef.current.getActiveObject().borderColor = COLORS.primary.light;
                fabricRef.current.renderAll();
            });

            fabricRef.current.on('selection:created', (event) => {
                const triggerElement = event.selected[0];
                setActionProvider(triggerElement?.type);
                dispatch(modifyEditorConfig({ ...currentConfig(triggerElement) }));
                fabricRef.current.getActiveObject().borderScaleFactor = 3;
                fabricRef.current.getActiveObject().borderColor = COLORS.primary.light;
                fabricRef.current.renderAll();
            });

            fabricRef.current.on('selection:cleared', (event) => {
                setActionProvider(null);
            });

            fabricRef.current.on('object:moving', (event) => {
                const canvas = fabricRef?.current;
                handleLineOpacity(canvas?.getObjects('line'), 1);
            });
        }
    }, [dispatch, fabricRef]);

    const handleCustomControlsInObject = () => {
        // create a rect object
        var deleteIcon = IMAGES.RemoveCircleIcon;

        var cloneIcon = IMAGES.DuplicatedIcon;

        var sendBackIcon = IMAGES.SendBackwardIcon;
        var bringForwordIcon = IMAGES.BringForwardIcon;

        var deleteImg = document.createElement('img');
        deleteImg.src = deleteIcon;

        var cloneImg = document.createElement('img');
        cloneImg.src = cloneIcon;

        var sendBackwordImg = document.createElement('img');
        sendBackwordImg.src = sendBackIcon;

        var bringForwordImg = document.createElement('img');
        bringForwordImg.src = bringForwordIcon;

        fabric.Object.prototype.transparentCorners = false;
        fabric.Object.prototype.cornerColor = COLORS.primary.light;
        fabric.Object.prototype.cornerStyle = 'rect';
        function renderIcon(icon) {
            return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
                var size = this.cornerSize;
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                ctx.drawImage(icon, -size / 2, -size / 2, size, size);
                ctx.restore();
            };
        }

        fabric.Object.prototype.controls.deleteControl = new fabric.Control({
            x: 0.55,
            y: -0.55,
            offsetY: -16,
            offsetX: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: deleteObject,
            render: renderIcon(deleteImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
            x: 0.55,
            y: -0.55,
            offsetY: -16,
            offsetX: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: deleteObject,
            render: renderIcon(deleteImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        fabric.Object.prototype.controls.clone = new fabric.Control({
            x: -0.55,
            y: -0.55,
            offsetY: -16,
            offsetX: -16,
            cursorStyle: 'pointer',
            mouseUpHandler: cloneObject,
            render: renderIcon(cloneImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        fabric.Textbox.prototype.controls.clone = new fabric.Control({
            x: -0.55,
            y: -0.55,
            offsetY: -16,
            offsetX: -16,
            cursorStyle: 'pointer',
            mouseUpHandler: cloneObject,
            render: renderIcon(cloneImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        fabric.Object.prototype.controls.backword = new fabric.Control({
            x: -0.55,
            y: 0.55,
            offsetY: 16,
            offsetX: -16,
            cursorStyle: 'pointer',
            mouseUpHandler: backwordObject,
            render: renderIcon(sendBackwordImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        fabric.Textbox.prototype.controls.backword = new fabric.Control({
            x: -0.55,
            y: 0.55,
            offsetY: 16,
            offsetX: -16,
            cursorStyle: 'pointer',
            mouseUpHandler: backwordObject,
            render: renderIcon(sendBackwordImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        fabric.Object.prototype.controls.forword = new fabric.Control({
            x: 0.55,
            y: 0.55,
            offsetY: 16,
            offsetX: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: forwordObject,
            render: renderIcon(bringForwordImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        fabric.Textbox.prototype.controls.forword = new fabric.Control({
            x: 0.55,
            y: 0.55,
            offsetY: 16,
            offsetX: 16,
            cursorStyle: 'pointer',
            mouseUpHandler: forwordObject,
            render: renderIcon(bringForwordImg),
            cornerSize: 50,
            sizeX: 50,
            sizeY: 50
        });

        function deleteObject(eventData, transform) {
            var target = transform.target;
            var canvas = target.canvas;
            canvas.remove(target);
            canvas.requestRenderAll();
            setUnSavedChange(true);
        }

        function cloneObject(eventData, transform) {
            var target = transform.target;
            var canvas = target.canvas;
            target.clone(function (cloned) {
                cloned.left += 10;
                cloned.top += 10;
                canvas.add(cloned);
            });
            setUnSavedChange(true);
        }

        function backwordObject(eventData, transform) {
            var target = transform.target;
            var canvas = target.canvas;
            canvas.sendToBack(target);
            canvas.requestRenderAll();
        }

        function forwordObject(eventData, transform) {
            var target = transform.target;
            var canvas = target.canvas;
            canvas.bringForward(target);
            canvas.requestRenderAll();
        }
    };

    const handleSelectHeaderItem = (item) => {
        setActionHeaderItem(item.id);
    };

    const applySelectedObject = ({ name, value, ...rest }) => {
        const activeObject = fabricRef.current.getActiveObject();
        if (activeObject) {
            activeObject.set({ [name]: value, ...rest });
            fabricRef.current.renderAll();
        }
    };

    const applyAllProperty = ({ ...rest }) => {
        const activeObject = fabricRef.current.getActiveObject();
        if (activeObject) {
            activeObject.set({ ...rest });
            fabricRef.current.renderAll();
        }
    };

    const handleColorChange = (color) => {
        applySelectedObject({ name: 'fill', value: color.hex });
        dispatch(
            handleChangeAction({
                name: 'activeColor',
                value: color.hex
            })
        );
    };

    const selectColorBackground = (data) => {
        fabricRef.current.setBackgroundImage(null);
        fabricRef.current.setBackgroundColor(data.color);
        fabricRef.current.renderAll();
    };

    const handleCoords = ({ type, width, height }) => {
        switch (type) {
            case 'horizontal':
                return {
                    x1: 0,
                    y1: 0,
                    x2: orientation === ORIENTATION.LANDSCAPE ? width : height,
                    y2: 0
                };
            case 'vertical':
                return {
                    x1: 0,
                    y1: orientation === ORIENTATION.LANDSCAPE ? height / 4 : width / 2,
                    x2: 0,
                    y2: orientation === ORIENTATION.LANDSCAPE ? width / 2 : height
                };
            case 'radial':
                return {
                    x1: width / 2,
                    y1: height / 2,
                    x2: width / 2,
                    y2: height / 2,
                    r1: width / 2,
                    r2: height / 8
                };
            case 'diagonal':
                return {
                    x1: height,
                    y1: height,
                    x2: -height / 4,
                    y2: -height / 4
                };
            default:
                return {
                    x1: 0,
                    y1: 0,
                    x2: 0,
                    y2: height
                };
        }
    };

    const selectGradientColorBackground = (data) => {
        fabricRef.current.setBackgroundImage(null);
        const grad = new fabric.Gradient({
            type: data.gradient === 'radial' ? 'radial' : 'linear',
            coords: handleCoords({ type: data.gradient, width: fabricRef.current.width, height: fabricRef.current.height }),
            colorStops: [
                {
                    color: data.firstGradientColor,
                    offset: 0
                },
                {
                    color: data.secondGradientColor,
                    offset: 1
                }
            ]
        });
        fabricRef.current.setBackgroundColor(grad);
        fabricRef.current.renderAll();
    };

    const selectImageBackground = (data) => {
        fabric.Image.fromURL(data.image, function (img, isError) {
            img.setSrc(
                data.image,
                function (image) {
                    img.scaleToWidth(fabricRef.current.width);
                    img.scaleToHeight(fabricRef.current.height);
                    fabricRef.current.setBackgroundImage(img, fabricRef.current.renderAll.bind(fabricRef.current), {
                        scaleX: fabricRef.current.width / img.width,
                        scaleY: fabricRef.current.height / img.height
                    });
                },
                { crossOrigin: 'anonymous' }
            );
        });
    };

    const onAddText = () => {
        const textBoxWidth = 880;
        var text = new fabric.Textbox('Sample Text', {
            minWidth: textBoxWidth,
            width: textBoxWidth,
            top: CANVAS_HEIGHT / 2 - 40,
            left: CANVAS_WIDTH / 2 - textBoxWidth / 2,
            fontFamily: 'Open Sans',
            textAlign: 'center',
            fontSize: 120,
            fontStyle: 'normal',
            fontWeight: 400,
            opacity: 1
        });
        text.set({ fill: 'white', padding: 0 });
        setUnSavedChange(true);
        fabricRef.current.add(text);
        fabricRef.current.renderAll();
    };

    const handleBackgroundChangeAction = (data) => {
        if (data.type === BACKGROUND_ACTION_TYPE.IMAGE) {
            selectImageBackground(data);
        } else if (data.type === BACKGROUND_ACTION_TYPE.COLOR) {
            selectColorBackground(data);
        } else if (data.type === BACKGROUND_ACTION_TYPE.GRADIENT_COLOR) {
            selectGradientColorBackground(data);
            dispatch(
                modifyEditorConfig({
                    ...data
                })
            );
        }
        setUnSavedChange(true);
    };

    const handleChangeTextAction = (data) => {
        if (data.type === TEXT_ACTION_TYPE.OPACITY) {
            dispatch(handleChangeAction({ name: 'opacity', value: data.value }));
            applySelectedObject({ name: 'opacity', value: data.value / 100 });
        } else if (data.type === TEXT_ACTION_TYPE.FONT_SIZE) {
            const value = data.value === 'increase' ? editor?.fontSize + 1 : editor?.fontSize - 1;
            dispatch(handleChangeAction({ name: 'fontSize', value }));
            applySelectedObject({ name: 'fontSize', value });
        } else if (data.type === TEXT_ACTION_TYPE.FONT_COLOR) {
            dispatch(handleChangeAction({ name: 'activeColor', value: data.value }));
            applySelectedObject({ name: 'fill', value: data.value });
        } else if (data.type === TEXT_ACTION_TYPE.FONT_FAMILY) {
            const fontStyleObj = {
                actionFontFamily: data.value?.font,
                fontWeight: data?.value?.fontWeight,
                fontStyle: data?.value?.fontStyle
            };
            dispatch(modifyEditorConfig(fontStyleObj));
            applySelectedObject({ name: 'fontFamily', value: data.value?.font, ...fontStyleObj });
        } else if (data.type === TEXT_ACTION_TYPE.ALIGNMENT) {
            dispatch(handleChangeAction({ name: 'activeTextAlignment', value: data.value }));
            applySelectedObject({ name: 'textAlign', value: data.value });
        } else if (data?.type === TEXT_ACTION_TYPE.ADD_TEXT) {
            onAddText();
        }
        setUnSavedChange(true);
    };

    const handleChangeShapesAction = (data) => {
        if (data.type === SHAPE_ACTION_TYPE.OPACITY) {
            dispatch(handleChangeAction({ name: 'opacity', value: data.value }));
            applySelectedObject({ name: 'opacity', value: data.value / 100 });
        } else if (data.type === SHAPE_ACTION_TYPE.SHAPE_COLOR) {
            dispatch(handleChangeAction({ name: 'activeColor', value: data.value }));
            applySelectedObject({ name: 'fill', value: data.value });
        } else if (data.type === SHAPE_ACTION_TYPE.SHAPE_SELECTOR) {
            dispatch(modifyEditorConfig(updateConfig({ id: editor?.type, ...data })));
            applyAllProperty(updateConfig({ id: editor?.type, ...data }));
        }
        setUnSavedChange(true);
    };

    const handleAddImage = (data) => {
        fabric.Image.fromURL(data.image, function (img, isError) {
            img.scaleToWidth(200);
            img.scaleToHeight(200);
            fabricRef.current.add(img);
            fabricRef.current.centerObject(img);
        });
        setUnSavedChange(true);
    };

    const handleChangeShapeAction = (data) => {
        if (data.type === SHAPE_ACTION_TYPE.SHAPE_SELECTOR) {
            let object;
            const shapeData = {
                top: 10,
                left: 10,
                width: 80 * 6,
                height: 80 * 6,
                fill: '#000000'
            };
            if (data.shape === 'circle') {
                object = new fabric.Circle({ ...shapeData, radius: 40 * 6 });
            } else if (data.shape === 'rectangle') {
                object = new fabric.Rect(shapeData);
            } else if (data.shape === 'triangle') {
                object = new fabric.Triangle(shapeData);
            }
            if (object) {
                fabricRef.current.add(object);
                fabricRef.current.centerObject(object);
                fabricRef.current.renderAll();
            }
        } else if (data.type === SHAPE_ACTION_TYPE.UPLOAD_IMAGE) {
            handleAddImage(data);
        }
        setUnSavedChange(true);
    };
    return (
        <MainBox sx={{ width: EDITOR_WIDTH }}>
            <EditorHeader
                {...{
                    loading,
                    actionProvider,
                    actionHeaderItem,
                    handleChangeTextAction,
                    handleSelectHeaderItem,
                    handleColorChange,
                    unSavedChange,
                    setUnSavedChange,
                    content,
                    libraryStatus,
                    handleChangeShapesAction
                }}
                onSave={(name, duration, status) => {
                    const json = fabricRef.current.toJSON();
                    const image = fabricRef.current.toDataURL({ format: 'png' });
                    onSave(name, json, image, duration, status);
                }}
                clearCanvas={() => {
                    if (id) {
                        setUnSavedChange(true);
                    }
                    fabricRef.current.clear();
                }}
                inEdit={id ? true : false}
                onClose={() => navigate(-1)}
            />
            {id && isLoading && <Loader isLoading={isLoading} />}

            <ContainMainView
                sx={{
                    minHeight: getElementRenderSizeScale(orientation, CANVAS_RENDER_HEIGHT / CANVAS_MAIN_HEIGHT).height + 40,
                    minWidth: getElementRenderSizeScale(orientation, CANVAS_RENDER_HEIGHT / CANVAS_MAIN_HEIGHT).height + 40
                }}
            >
                <EditorRightActions
                    {...{ actionHeaderItem, handleBackgroundChangeAction, handleChangeTextAction, handleChangeShapeAction }}
                />
                <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <Box
                        sx={{
                            position: 'absolute',
                            height: CANVAS_RENDER_HEIGHT,
                            width: getAspectRatioWidthFromHeight(CANVAS_RENDER_HEIGHT, ASPECT_RATIO[orientation]),
                            backgroundColor: COLORS.gray,
                            marginTop: '20px'
                        }}
                    >
                        <Box
                            ref={canvasContainerRef}
                            id="canvas-view"
                            sx={{
                                transform: `scale(${CANVAS_RENDER_HEIGHT / CANVAS_MAIN_HEIGHT})`,
                                transformOrigin: '0px 0px'
                            }}
                        >
                            <canvas ref={canvasRef}></canvas>
                        </Box>
                    </Box>
                </Box>
            </ContainMainView>
        </MainBox>
    );
};
