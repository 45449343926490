import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { api } from '../../api';
import { PLAYLIST_MODE, PLAYBACK_METHOD } from '../../utils/constants';
import { playlistAutoPositionRule } from '../../utils/helpers';

export const fetchAllContent = createAsyncThunk('users/fetchAllContent', async (params) => {
    const response = await api.library.get(params);
    return response.data.result;
});

export const fetchLoopTemplate = createAsyncThunk('users/fetchLoopTemplate', async (params) => {
    const response = await api.loop.getTemplate(params);
    return response.data.result;
});

export const fetchLoopTemplateAndUpdatePlaylist = createAsyncThunk(
    'users/fetchLoopTemplateAndUpdatePlaylist',
    async (params, { dispatch }) => {
        const response = await api.loop.getTemplateById(params);
        dispatch(addPlaylistInfoFromTemplate(response.data.result));
    }
);

export const fetchCustomLoopAndUpdatePlaylist = createAsyncThunk('users/fetchCustomLoopAndUpdatePlaylist', async (params, { dispatch }) => {
    const response = await api.loop.getLoopById(params);
    dispatch(addPlaylistInfoFromCustomLoop(response.data.result));
});

export const fetchLoopDataForExistedSchedule = createAsyncThunk('users/fetchLoopDataForExistedSchedule', async (params, { dispatch }) => {
    const response = await api.loop.checkScheduleExits(params);
    dispatch(addSheduledLoopData(response.data.result));
});

export const fetchAllLoopByLocation = createAsyncThunk('users/fetchAllLoopByLocation', async (params, { dispatch }) => {
    const response = await api.loop.getLoopByLocation(params);
    return response.data.result;
});

export const syncAllLoopByLibrary = createAsyncThunk('users/syncAllLoopByLibrary', async (payload, { dispatch }) => {
    const libraryContents = payload?.libraryContents;
    // const updatableLoop = payload?.loops?.filter((loop) => identifyLoopForSync(loop, libraryContents));
    const updatableLoop = payload?.loops;

    for (let i = 0; i < updatableLoop?.length; i++) {
        dispatch(
            updateLoopSyncingStatus({
                totalSyncFound: updatableLoop?.length,
                syncDone: i,
                currentSync: {
                    name: updatableLoop[i]?.name,
                    loop_id: updatableLoop[i]?.id
                }
            })
        );
        const formatedPlaylist = formatPlaylistAsPerDevidedFunction(payload?.loops[i]?.loop_categories, libraryContents);
        let loopPlaylists = devidedContentInPlaylist(formatedPlaylist, libraryContents);
        let reqData = {
            categories: loopPlaylists.map((item) => {
                return {
                    category_id: item.id,
                    unique_id: item?.uniqId,
                    mode: item?.mode,
                    playback_method: item?.playback_method,
                    name: item?.name,
                    contents: item?.selectedContents?.map((video) => {
                        return {
                            content_id: video?.id,
                            unique_id: video?.uniqId || Math.random().toString(16).slice(2)
                        };
                    })
                };
            }),
            id: updatableLoop[i].id
        };
        await api.loop.editLoopSchedule(reqData);

        if (i === updatableLoop?.length - 1) {
            dispatch(
                updateLoopSyncingStatus({
                    totalSyncFound: 0,
                    syncDone: 0,
                    currentSync: {
                        name: '',
                        loop_id: ''
                    }
                })
            );
        }
    }
});

export const identifyLoopForSync = (loop, allContents) => {
    let contentsByCategory = {};
    for (let i = 0; i < loop?.loop_categories?.length; i++) {
        let contentInObject = contentsByCategory[loop?.loop_categories[i]?.category_id];
        if (!contentInObject) {
            contentsByCategory[loop?.loop_categories[i]?.category_id] = loop?.loop_categories[i]?.loop_category_contents;
        } else {
            let newContents = loop?.loop_categories[i]?.loop_category_contents?.filter(
                (content) => contentInObject?.findIndex((e) => e.id === content.id) === -1
            );
            contentsByCategory[loop?.loop_categories[i]?.category_id] = [
                ...contentsByCategory[loop?.loop_categories[i]?.category_id],
                ...newContents
            ];
        }
    }

    let contentsByCategoryArray = Object.values(contentsByCategory);
    for (let i = 0; i < contentsByCategoryArray.length; i++) {
        const contentInLibrary = allContents?.filter((content) => content?.category_id === contentsByCategoryArray[i][0]?.category_id);

        if (contentInLibrary?.length !== contentsByCategoryArray[i]?.length) {
            return true;
        } else {
            let unknownContent = contentInLibrary?.find((item) => {
                if (contentsByCategoryArray[i]?.findIndex((e) => e.id === item?.id) === -1) {
                    return true;
                }
                return false;
            });
            if (Boolean(unknownContent)) {
                return true;
            }
        }

        if (contentsByCategoryArray?.length - 1 === i) {
            return false;
        }
    }
};

export const formatPlaylistAsPerDevidedFunction = (playlists, libraryContents) => {
    return playlists?.map((item, index) => {
        let contents = libraryContents.filter((e) => e.category_id === item?.playlist.id);
        return {
            ...item?.playlist,
            allContents: contents,
            selectedContents: item?.loop_category_contents,
            mode: item?.mode,
            playback_method: item?.playback_method,
            index,
            uniqId: Math.random().toString(16).slice(2)
        };
    });
};

export const devidedContentInPlaylist = (playlists, libraryContents) => {
    let allPlaylist = JSON.parse(JSON.stringify(playlists));
    for (let i = 0; i < allPlaylist?.length; i++) {
        const currentPlaylist = allPlaylist[i];
        let allContents = libraryContents.filter((item) => item.category_id === currentPlaylist.id);
        let contents = [...allContents];
        let updatablePlaylist = allPlaylist.filter((item) => item.id === currentPlaylist.id && item.mode === PLAYLIST_MODE.AUTO);
        if (updatablePlaylist.length > contents.length && contents.length !== 0) {
            let duplicateContentLength = updatablePlaylist.length - contents.length;
            const duplicateWholeArrayLength = Math.floor(duplicateContentLength / contents.length);
            duplicateContentLength = duplicateContentLength - duplicateWholeArrayLength * contents.length;
            for (let i = 0; i < duplicateWholeArrayLength; i++) {
                contents = [...contents, ...allContents];
            }
            if (duplicateContentLength) {
                contents = [...contents, ...allContents.splice(0, duplicateContentLength)];
            }
        }

        const division = Math.round(contents.length / updatablePlaylist.length);
        for (let i = 0; i < updatablePlaylist.length; i++) {
            const playlistIndex = allPlaylist.findIndex((e) => e.uniqId === updatablePlaylist[i].uniqId);
            if (i !== updatablePlaylist.length - 1) {
                allPlaylist[playlistIndex] = { ...allPlaylist[playlistIndex], selectedContents: contents.splice(0, division) };
            } else {
                allPlaylist[playlistIndex] = { ...allPlaylist[playlistIndex], selectedContents: contents };
            }
        }
    }

    return allPlaylist;
};

const initialState = {
    loopFormData: {
        playList: [],
        name: 'Loop Name Here',
        activePlaylist: null,
        schedule: [],
        unsavedChanges: false
    },
    loops: [],
    allContents: [],
    adminTemplate: [],
    scheduledLoopData: [],
    syncingLoopStatus: {
        totalSyncFound: 0,
        syncDone: 0,
        currentSync: {
            name: '',
            loop_id: ''
        }
    }
};

export const loopSlice = createSlice({
    name: 'loop',
    initialState,
    reducers: {
        addLoopFormData: (state, action) => {
            state.loopFormData = action.payload;
        },
        updateLoopFormDataByKey: (state, action) => {
            const { key, value } = action.payload;
            state.loopFormData[key] = value;
            if (key !== 'activePlaylist') state.loopFormData.unsavedChanges = value ? true : false;
        },
        updateLoopSyncingStatus: (state, action) => {
            state.syncingLoopStatus = action.payload;
        },
        addPlaylistInfoFromTemplate: (state, action) => {
            let playlists = action.payload?.loop_categories?.map((item, index) => {
                let contents = state.allContents.filter((e) => e.category_id === item?.playlist.id);
                return {
                    ...item?.playlist,
                    allContents: contents,
                    selectedContents: contents,
                    mode: PLAYLIST_MODE.AUTO,
                    playback_method: PLAYBACK_METHOD.SINGLE,
                    index,
                    uniqId: Math.random().toString(16).slice(2)
                };
            });
            state.loopFormData.playList = playlists;
            state.loopFormData.name = action.payload?.name ?? 'Loop Name Here';
            state.loopFormData.location_id = action.payload?.location_id;
            state.loopFormData.is_primary = action.payload?.is_primary;
            state.loopFormData.id = action.payload?.id;
        },
        addPlaylistInfoFromCustomLoop: (state, action) => {
            let playlists = action.payload?.loop_categories?.map((item, index) => {
                let contents = state.allContents.filter((e) => e.category_id === item?.playlist.id);
                return {
                    ...item?.playlist,
                    allContents: contents,
                    selectedContents: item?.loop_category_contents,
                    mode: item?.mode,
                    playback_method: item?.playback_method,
                    index,
                    uniqId: Math.random().toString(16).slice(2)
                };
            });

            state.loopFormData.playList = playlists;
            state.loopFormData.name = action.payload?.name;
            state.loopFormData.location_id = action.payload?.location_id;
            state.loopFormData.is_primary = action.payload?.is_primary;
            state.loopFormData.id = action.payload?.id;
            if (action.payload?.loop_schedule.length) {
                if (action.payload?.loop_schedule?.[0]?.type === 'custom') {
                    const schedule = [
                        {
                            days: action.payload?.loop_schedule?.[0]?.days,
                            startDateTime: `${moment(action.payload?.loop_schedule?.[0]?.start_date_time).format('YYYY/MM/DD')} ${
                                action.payload?.loop_schedule?.[0]?.start_time
                            }`,
                            endDateTime: `${moment(action.payload?.loop_schedule?.[0]?.end_date_time).format('YYYY/MM/DD')} ${
                                action.payload?.loop_schedule?.[0]?.end_time
                            }`,
                            eventType: action.payload?.loop_schedule?.[0]?.event_type,
                            recurrencePattern: action.payload?.loop_schedule?.[0]?.recurrence_pattern,
                            type: action.payload?.loop_schedule?.[0]?.type,
                            loop_schedules: action.payload?.loop_schedule?.[0]?.loop_schedules
                        }
                    ];
                    state.loopFormData.old_schedule = schedule;
                    state.loopFormData.schedule = schedule;
                } else {
                    state.loopFormData.old_schedule = action.payload?.loop_schedule;
                    state.loopFormData.schedule = action.payload?.loop_schedule;
                }
                state.loopFormData.type = action.payload?.loop_schedule[0]?.type;
            }
        },
        syncAutoPlayListContent: (state) => {
            // let allPlaylist = JSON.parse(JSON.stringify(state?.loopFormData?.playList));

            // for (let i = 0; i < allPlaylist?.length; i++) {
            //     const currentPlaylist = allPlaylist[i];
            //     let allContents = state.allContents.filter((item) => item.category_id === currentPlaylist.id);
            //     let contents = [...allContents];
            //     let updatablePlaylist = allPlaylist.filter((item) => item.id === currentPlaylist.id && item.mode === PLAYLIST_MODE.AUTO);
            //     if (updatablePlaylist.length > contents.length && contents.length !== 0) {
            //         let duplicateContentLength = updatablePlaylist.length - contents.length;
            //         const duplicateWholeArrayLength = Math.floor(duplicateContentLength / contents.length);
            //         duplicateContentLength = duplicateContentLength - duplicateWholeArrayLength * contents.length;

            //         for (let i = 0; i < duplicateWholeArrayLength; i++) {
            //             contents = [...contents, ...allContents];
            //         }
            //         if (duplicateContentLength) {
            //             contents = [...contents, ...allContents.splice(0, duplicateContentLength)];
            //         }
            //     }

            //     const division = Math.round(contents.length / updatablePlaylist.length);
            //     for (let i = 0; i < updatablePlaylist.length; i++) {
            //         const playlistIndex = allPlaylist.findIndex((e) => e.uniqId === updatablePlaylist[i].uniqId);
            //         if (i !== updatablePlaylist.length - 1) {
            //             allPlaylist[playlistIndex] = { ...allPlaylist[playlistIndex], selectedContents: contents.splice(0, division) };
            //         } else {
            //             allPlaylist[playlistIndex] = { ...allPlaylist[playlistIndex], selectedContents: contents };
            //         }
            //     }
            // }

            state.loopFormData.playList = devidedContentInPlaylist(state?.loopFormData?.playList, state.allContents);
        },
        addNewPlaylist: (state, action) => {
            let contents = state.allContents.filter((item) => item.category_id === action.payload.id);
            let allPlaylist = state?.loopFormData?.playList;
            allPlaylist.push({
                ...action.payload,
                allContents: contents,
                selectedContents: [],
                mode: PLAYLIST_MODE.AUTO,
                playback_method: PLAYBACK_METHOD.SINGLE,
                uniqId: Math.random().toString(16).slice(2)
            });
            let autoPositionList = playlistAutoPositionRule(allPlaylist);
            state.loopFormData.playList = autoPositionList;
            state.loopFormData.unsavedChanges = true;
        },

        editPlayList: (state, action) => {
            const { uniqId, key, value } = action.payload;
            const playlistIndex = state.loopFormData.playList.findIndex((e) => e.uniqId === uniqId);
            let allPlaylist = [...state?.loopFormData?.playList];
            let updatablePlaylist = allPlaylist[playlistIndex];
            updatablePlaylist[key] = value;

            if (key === 'mode' && value === PLAYLIST_MODE.MANUAL) {
                updatablePlaylist.selectedContents = [];
            }
            allPlaylist[playlistIndex] = updatablePlaylist;
            state.loopFormData.playList = allPlaylist;
            state.loopFormData.unsavedChanges = true;
        },

        deletePlaylist: (state, action) => {
            let allPlaylist = state?.loopFormData?.playList.filter((item) => item.index !== action.payload.index);
            let autoPositionList = playlistAutoPositionRule(allPlaylist);
            state.loopFormData.playList = autoPositionList;
            state.loopFormData.unsavedChanges = true;
        },
        clearLoopFormData: (state) => {
            state.loopFormData = initialState.loopFormData;
        },
        addSheduledLoopData: (state, action) => {
            state.scheduledLoopData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllContent.fulfilled, (state, action) => {
            state.allContents = action.payload;
        });
        builder.addCase(fetchLoopTemplate.fulfilled, (state, action) => {
            state.adminTemplate = action.payload;
        });
        builder.addCase(fetchAllLoopByLocation.fulfilled, (state, action) => {
            state.loops = action.payload;
        });
    }
});

export const {
    addLoopFormData,
    updateLoopFormDataByKey,
    addNewPlaylist,
    deletePlaylist,
    syncAutoPlayListContent,
    editPlayList,
    addPlaylistInfoFromTemplate,
    clearLoopFormData,
    addPlaylistInfoFromCustomLoop,
    addSheduledLoopData,
    updateLoopSyncingStatus
} = loopSlice.actions;

export default loopSlice.reducer;
