import { Box, Grid, IconButton, styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { ICONS, IMAGES } from '../../assets';
import PlaylistDetailsTooltip from '../../containers/client/Loops/components/PlaylistDetailsTooltip';
import { SERVER_URL } from '../../utils/constants';
import { groupByKeyName, shortColorFromHighToLow } from '../../utils/helpers';
import { COLORS } from '../../utils/theme';
import CommonPopover from '../CommonPopover';
import './LoopViewWithDiv.css';
import CommonButton from '../CommonButton';
import { ViewerPermission } from '../ViewerPermission';
import { AccessControlledComponent } from '../AccessControlledComponent';
import { useSelector } from 'react-redux';

const MainBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
});

const LegendMainGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const LegendItemMain = styled(Grid)({
    display: 'flex'
});

const LegendBox = styled(Grid)({
    height: 20,
    width: 20,
    borderRadius: 4
});

const LegentName = styled(Grid)({
    fontSize: 12,
    color: COLORS.black
});
const CategoryBox = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
        outline: `4px solid ${COLORS.primary.light}`
    }
});
const Image = styled('img')({
    height: 25,
    width: 30,
    objectFit: 'contain'
});
const ResetIcon = styled('img')({
    height: 30,
    width: 30
});
const ResetIconButton = styled(IconButton)({
    backgroundColor: COLORS.gray,
    padding: 10,
    borderRadius: 8
});
const StyledButton = styled(CommonButton)({
    fontWeight: '300',
    fontSize: '16px',
    textAlign: 'center'
});

export const LoopViewWithDiv = ({
    chartData,
    options,
    onDeleteItem,
    onSelectPlaylist,
    selectable = true,
    canDeletePlaylist = true,
    onReset,
    reset,
    showTooltip,
    defaultSelect,
    edit,
    onEditClick,
    accessValue
}) => {
    const OUTSIDE_BACKGROUND_WIDTH = options?.OUTSIDE_BACKGROUND_WIDTH || 350;
    const MAIN_ROUNT_WIDTH = options?.MAIN_ROUNT_WIDTH || 270;
    const CHART_WIDTH = options?.CHART_WIDTH || 180;
    const INSIDE_WHITE_WIDTH = options?.INSIDE_WHITE_WIDTH || 160;

    const BOX_WIDTH = options?.BOX_WIDTH || 40;

    const [formatedData, setFormatedData] = useState([]);
    const [formatedColorData, setFormatedColorData] = useState([]);
    const [chartColors, setChaerColors] = useState('');
    const [boxOfItem, setBoxOfItem] = useState([]);
    const [selectedBoxIndex, setSelectedBoxIndex] = useState();
    const [selectedPlaylistUnicId, setSlectedPlaylistUnicId] = useState();
    const [legendData, setLegendData] = useState([]);
    const [actionAnchorEl, setActionAnchorEl] = useState([]);
    const { access } = useSelector((state) => state.auth);

    useEffect(() => {
        if (defaultSelect && !selectedPlaylistUnicId) {
            setSlectedPlaylistUnicId(chartData?.[0]?.uniqId);
            setSelectedBoxIndex(0);
        } else {
            let ifExist = chartData?.some((el) => el.uniqId === selectedPlaylistUnicId);
            if (!ifExist) setSlectedPlaylistUnicId(chartData?.[0]?.uniqId);
        }
    }, [chartData]);

    const formateLegendData = useCallback(() => {
        let legendData = Object.values(groupByKeyName(chartData, 'color'));
        legendData = legendData.map((item) => {
            const otherData = item[0];
            const length = item.length;
            return { ...otherData, length };
        });
        setLegendData([...legendData]);
    }, [chartData]);

    const formattoGraphData = useCallback(() => {
        let totle = 0;
        chartData?.forEach((item) => {
            totle = totle + item.value;
        });
        let formatedData = [];
        let itemLenght = chartData?.length;
        for (let i = 0; i < itemLenght; i++) {
            const itemPrRatio = chartData[i].value / totle;
            let startAngle = i === 0 ? 0 : formatedData[i - 1].endAngle;
            let endAngle = 360 * itemPrRatio + startAngle;
            formatedData.push({
                itemPrRatio,
                startAngle,
                endAngle,
                color: chartData[i].color
            });
        }
        setFormatedData([...formatedData]);
    }, [chartData]);

    const formateColorData = useCallback(() => {
        let totle = 0;
        let shortData = shortColorFromHighToLow(chartData);
        shortData?.forEach((item) => {
            totle = totle + item.value;
        });
        let formatedData = [];
        let itemLenght = shortData?.length;
        for (let i = 0; i < itemLenght; i++) {
            const itemPrRatio = shortData[i].value / totle;
            let startAngle = i === 0 ? 0 : formatedData[i - 1].endAngle;
            let endAngle = 360 * itemPrRatio + startAngle;
            formatedData.push({
                itemPrRatio,
                startAngle,
                endAngle,
                color: shortData[i].color
            });
        }
        setFormatedColorData([...formatedData]);
    }, [chartData]);

    const genrateBoxPossitionFromData = useCallback(
        (data) => {
            let boxArray = [];
            let itemLenght = chartData?.length;
            for (let i = 0; i < itemLenght; i++) {
                const angle = (data[i]?.startAngle + data[i]?.endAngle) / 2;
                var radius = MAIN_ROUNT_WIDTH / 2;
                var x = radius * Math.sin((Math.PI * 2 * angle) / 360);
                var y = radius * Math.cos((Math.PI * 2 * angle) / 360);
                const xRound = Math.round(x * 100) / 100;
                const yRound = Math.round(y * 100) / 100;
                let top;
                let left;
                let itemIndex = i;
                let itemColor = chartData[i].color;
                let itemLabel = 'P1';
                let itemIcon = chartData[i].icon;
                left = Number.isNaN(MAIN_ROUNT_WIDTH / 2 + xRound - BOX_WIDTH / 2) ? 0 : MAIN_ROUNT_WIDTH / 2 + xRound - BOX_WIDTH / 2;
                top = Number.isNaN(MAIN_ROUNT_WIDTH / 2 - yRound - BOX_WIDTH / 2) ? 0 : MAIN_ROUNT_WIDTH / 2 - yRound - BOX_WIDTH / 2;
                var contentData = {};
                if (showTooltip) {
                    contentData.loop_category_contents = chartData[i]?.loop_category_contents;
                    contentData.playback_method = chartData[i]?.playback_method;
                    contentData.mode = chartData[i]?.mode;
                    contentData.name = chartData[i]?.name;
                }
                boxArray.push({
                    top,
                    left,
                    itemIndex,
                    itemColor,
                    itemLabel,
                    itemIcon,
                    index: chartData[i].index,
                    uniqId: chartData[i]?.uniqId,
                    ...contentData
                });
            }
            setBoxOfItem([...boxArray]);
        },
        [BOX_WIDTH, MAIN_ROUNT_WIDTH, chartData]
    );

    const getChartColorFromData = (data) => {
        if (data?.length) {
            let colorArray = data.map((item) => {
                return `${item.color} ${item.startAngle}deg ${item.endAngle}deg`;
            });
            const finalColor = `conic-gradient(${colorArray.join(',')})`;
            setChaerColors(finalColor);
        } else {
            setChaerColors('');
        }
    };
    useEffect(() => {
        if (formatedData) {
            // getChartColorFromData(formatedData);
            genrateBoxPossitionFromData(formatedData);
        }
    }, [formatedData, genrateBoxPossitionFromData]);

    useEffect(() => {
        if (formatedColorData) {
            getChartColorFromData(formatedColorData);
        }
    }, [formatedColorData]);

    useEffect(() => {
        formateLegendData();
        formattoGraphData();
        formateColorData();
    }, [chartData, formattoGraphData, formateLegendData, formateColorData]);

    useEffect(() => {
        if (onSelectPlaylist && selectable) {
            onSelectPlaylist(selectedPlaylistUnicId);
        }
    }, [selectedPlaylistUnicId, onSelectPlaylist]);

    return (
        <MainBox>
            <div
                className="outside-background"
                style={{
                    height: OUTSIDE_BACKGROUND_WIDTH,
                    width: OUTSIDE_BACKGROUND_WIDTH
                }}
            >
                <div style={{ height: MAIN_ROUNT_WIDTH, width: MAIN_ROUNT_WIDTH }} className="main-background">
                    <div
                        style={{
                            background: chartColors,
                            height: CHART_WIDTH,
                            width: CHART_WIDTH
                        }}
                        className="donut"
                    >
                        <div
                            style={{
                                height: INSIDE_WHITE_WIDTH,
                                width: INSIDE_WHITE_WIDTH,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            className="hole"
                        >
                            {reset && (
                                <ResetIconButton onClick={onReset}>
                                    <ResetIcon src={IMAGES.Reset_Icon2} alt="" />
                                </ResetIconButton>
                            )}
                            <ViewerPermission>
                                <AccessControlledComponent accessValue={accessValue}>
                                    {edit && <StyledButton onClick={onEditClick} variant="contained" label={'Edit'} />}
                                </AccessControlledComponent>
                            </ViewerPermission>
                        </div>
                    </div>
                    {boxOfItem.map((item) => {
                        let index = defaultSelect ? item.uniqId : item.itemIndex;
                        let condition = defaultSelect ? selectedPlaylistUnicId : selectedBoxIndex;
                        return (
                            <div
                                style={{
                                    top: item.top,
                                    left: item.left
                                }}
                                key={item?.itemIndex}
                                className="element-possition"
                            >
                                <CategoryBox
                                    onClick={(e) => {
                                        if (selectable) {
                                            setActionAnchorEl(e.currentTarget);
                                            setSelectedBoxIndex(index);
                                            setSlectedPlaylistUnicId(item.uniqId);
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: item.itemColor,
                                        height: BOX_WIDTH,
                                        width: BOX_WIDTH,
                                        borderRadius: '8px',
                                        outline: `${condition === index ? '4px' : '0px'} solid ${COLORS.primary.light}`
                                    }}
                                >
                                    <Image src={`${SERVER_URL}${item?.itemIcon}`} alt="" />
                                </CategoryBox>
                                {showTooltip && index === selectedBoxIndex && (
                                    <CommonPopover
                                        anchorEl={actionAnchorEl}
                                        handleClose={() => {
                                            setActionAnchorEl(null);
                                            setSelectedBoxIndex(null);
                                        }}
                                        anchorOrigin={{
                                            vertical: 'center',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'center',
                                            horizontal: 'left'
                                        }}
                                        PaperProps={{
                                            style: {
                                                backgroundColor: 'transparent',
                                                boxShadow: 'none',
                                                borderRadius: 0
                                            }
                                        }}
                                    >
                                        <PlaylistDetailsTooltip data={item} />
                                    </CommonPopover>
                                )}

                                {condition === index && canDeletePlaylist && (
                                    <IconButton
                                        onClick={() => {
                                            setSelectedBoxIndex(null);
                                            setSlectedPlaylistUnicId(null);
                                            onDeleteItem(selectedPlaylistUnicId);
                                        }}
                                        style={{ height: 22, width: 22 }}
                                    >
                                        <ICONS.Delete style={{ height: 18, width: 18 }} color="error" />
                                    </IconButton>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
            <LegendMainGrid sx={{ mb: 2 }} container>
                {legendData?.map((item) => {
                    return (
                        <LegendItemMain sx={{ mr: 3, mt: 2 }} key={item.color} item>
                            <LegendBox sx={{ backgroundColor: item.color }} />
                            <LegentName sx={{ ml: 1 }}>{`${item.name} - ${Math.round(
                                (100 / chartData?.length) * item.length
                            )}%`}</LegentName>
                        </LegendItemMain>
                    );
                })}
            </LegendMainGrid>
        </MainBox>
    );
};
