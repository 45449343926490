import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { getAccessObjectFromArrayObject } from '../../utils/helpers';

export const AccessControlledComponent = ({ children, accessValue, style }) => {
    const { access } = useSelector((state) => state.auth);
    const accessObject = getAccessObjectFromArrayObject(access?.access, accessValue);
    
    useEffect(() => {
        var elements = document.getElementsByClassName('accessWrap');
        if (elements?.length) {
            Array.from(elements).forEach(function (element) {
                element.addEventListener('click', (e) => e.stopPropagation(), true);
            });
        }
    }, []);

    if (accessObject?.visibility && accessObject?.status) {
        return children;
    } else {
        return (
            <Box
                onClick={(e) => e.stopPropagation()}
                className="accessWrap"
                sx={{ opacity: 0.3, cursor: 'not-allowed !important', ...style, userSelect: 'none' }}
            >
                {children}
            </Box>
        );
    }
};
