import { useCallback, useEffect, useState } from 'react';
import './LoopViewWithDiv.css';
import { shortColorFromHighToLow } from '../../utils/helpers';
export const LoopPreview = ({ chartData, size, barWidth }) => {
    const MAIN_ROUNT_WIDTH = size || 50;
    const CHART_WIDTH = MAIN_ROUNT_WIDTH - 15;
    const INSIDE_WHITE_WIDTH = CHART_WIDTH - (barWidth || 10);

    const [formatedData, setFormatedData] = useState([]);
    const [formatedColorData, setFormatedColorData] = useState([]);
    const [chartColors, setChaerColors] = useState('');

    const formattoGraphData = useCallback(() => {
        let totle = 0;
        chartData.forEach((item) => {
            totle = totle + item.value;
        });
        let formatedData = [];
        let itemLenght = chartData?.length;
        for (let i = 0; i < itemLenght; i++) {
            const itemPrRatio = chartData[i].value / totle;
            let startAngle = i === 0 ? 0 : formatedData[i - 1].endAngle;
            let endAngle = 360 * itemPrRatio + startAngle;
            formatedData.push({
                itemPrRatio,
                startAngle,
                endAngle,
                color: chartData[i].color
            });
        }
        setFormatedData([...formatedData]);
    }, [chartData]);

    const formateColorData = useCallback(() => {
        let totle = 0;
        let shortData = shortColorFromHighToLow(chartData);
        shortData?.forEach((item) => {
            totle = totle + item.value;
        });
        let formatedData = [];
        let itemLenght = shortData?.length;
        for (let i = 0; i < itemLenght; i++) {
            const itemPrRatio = shortData[i].value / totle;
            let startAngle = i === 0 ? 0 : formatedData[i - 1].endAngle;
            let endAngle = 360 * itemPrRatio + startAngle;
            formatedData.push({
                itemPrRatio,
                startAngle,
                endAngle,
                color: shortData[i].color
            });
        }
        setFormatedColorData([...formatedData]);
    }, [chartData]);

    const getChartColorFromData = (data) => {
        if (data) {
            let colorArray = data.map((item) => {
                return `${item.color} ${item.startAngle}deg ${item.endAngle}deg`;
            });
            const finalColor = `conic-gradient(${colorArray.join(',')})`;
            setChaerColors(finalColor);
        }
    };

    useEffect(() => {
        if (formatedColorData) {
            getChartColorFromData(formatedColorData);
        }
    }, [formatedColorData]);

    useEffect(() => {
        formattoGraphData();
        formateColorData();
    }, [chartData, formattoGraphData, formateColorData]);
    return (
        <div style={{ height: MAIN_ROUNT_WIDTH, width: MAIN_ROUNT_WIDTH }} className="main-background">
            <div
                style={{
                    background: chartColors,
                    height: CHART_WIDTH,
                    width: CHART_WIDTH
                }}
                className="donut"
            >
                <div style={{ height: INSIDE_WHITE_WIDTH, width: INSIDE_WHITE_WIDTH }} className="hole"></div>
            </div>
        </div>
    );
};
