import { Box, styled, Grid, Typography, Link, Breadcrumbs } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SERVER_URL } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';
import { AccountTabBar } from './AccountTabBar';

const MainBox = styled(Box)({
    padding: 16,
    width: '100%',
    backgroundColor: COLORS.white
});

const BreadcrumbText = styled(Link)({
    fontSize: 14,
    cursor: 'pointer',
    '&:hover': {
        color: COLORS.primary.light
    }
});

const CurrentBreadcrumbText = styled(Typography)({
    fontSize: 14
});

const MainGrid = styled(Grid)({
    marginTop: 0,
    alignItems: 'center'
});

const NameElementBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const AccountImage = styled('img')({
    height: 34,
    width: 34,
    borderRadius: 4
});

const AccountName = styled(Typography)(({ theme }) => ({
    fontWeight: '500',
    color: COLORS.black,
    marginLeft: 12,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
        fontSize: 20
    },
    [theme.breakpoints.up('md')]: {
        fontSize: 22
    },

    [theme.breakpoints.down('sm')]: {
        fontSize: 18
    }
}));

const ClientIdText = styled(Typography)({
    position: 'absolute',
    top: 16,
    right: 16,
    fontWeight: 500,
    borderRadius: 8,
    padding: '4px 8px',
    background: COLORS.primary.light,
    fontSize: 16,
    color: COLORS.white
});

const Container = styled(Box)({
    width: '100%',
    position: 'relative',
    borderRadius: '.425rem',
    overflow: 'hidden',
    border: `1px solid ${COLORS.border}`
})

export const AccountDetailsHeader = ({ onClose, tabArray, activeTab, handleChangeActiveTab, clientData }) => {
    const { id } = useParams();
    return (
        <Container>
            <MainBox sx={{ p: { xs: 2, md: 2, sm: 2 } }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <BreadcrumbText onClick={onClose} underline="hover" color="inherit">
                        Accounts
                    </BreadcrumbText>
                    <CurrentBreadcrumbText color="text.primary">Account Details</CurrentBreadcrumbText>
                </Breadcrumbs>
                <MainGrid container spacing={2}>
                    <Grid item xs={12}>
                        <NameElementBox>
                            <AccountImage src={SERVER_URL + clientData?.logo} />
                            <AccountName>{clientData?.name}</AccountName>
                        </NameElementBox>
                    </Grid>
                </MainGrid>
            </MainBox>
            <ClientIdText>Client ID: {id}</ClientIdText>
            <AccountTabBar tabArray={tabArray} activeTab={activeTab} handleChangeActiveTab={handleChangeActiveTab} />
        </Container>
    );
};
