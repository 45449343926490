/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PAGES } from '../utils/urls';
import { USER_ROLE } from '../utils/constants';

//Auth Pages
import Login from '../containers/auth/Login';
import ResetPasswordForm from '../containers/auth/ResetPasswordForm';
import AccountSelection from '../containers/auth/AccountSelection';

//Admin Pages
import Accounts from '../containers/admin/Accounts';
import AccountsDetails from '../containers/admin/AccountsDetails';
import { Layout } from '../containers/admin/Layout';
import { AddLayout } from '../containers/admin/AddLayout';
import { Loops } from '../containers/admin/Loops';
import { AddLoop } from '../containers/admin/AddLoop';
import Users from '../containers/admin/Users';
import { Collections } from '../containers/admin/Collections';
import { AddCollections } from '../containers/admin/AddCollections';
import { Groups } from '../containers/admin/Groups';
import { AddGroup } from '../containers/admin/AddGroup';
import { Backgrounds } from '../containers/admin/Backgrounds';
import { AddEditBackground } from '../containers/admin/AddEditBackground';
import { Playlists } from '../containers/admin/Playlists';
import { AddEditPlaylistCategory } from '../containers/admin/AddEditPlaylistCategory';
import { Videos } from '../containers/admin/Videos';
import { FeedbackTool } from '../containers/admin/FeedbackTool';
import { SystemVersion } from '../containers/admin/SystemVersion';
import AccountTypes from '../containers/admin/AccountTypes';
import { AccountTypesDetails } from '../containers/admin/AccountTypesDetails';
import CurationProfiles from '../containers/admin/CurationProfiles';
import { AddEditCurationProfile } from '../containers/admin/AddEditCurationProfile';

//Clients
import { Librarys } from '../containers/client/Librarys';
import { Browse } from '../containers/client/Browse';
import { Locations } from '../containers/client/Locations';
import { ClientLayout } from '../containers/client/Layout';
import { CreateCustom } from '../containers/client/CreateCustom';
import { ContentEditor } from '../containers/client/ContentEditor';
import { Upload } from '../containers/client/Upload';
import NewPasswordForm from '../containers/auth/NewPasswordForm';
import { ClientLoops } from '../containers/client/Loops';
import { LoopTemplateSchedule } from '../containers/client/LoopTemplateSchedule';
import { SocialMedia } from '../containers/client/SocialMedia';
import { GoogleReview } from '../containers/client/GoogleReview';
import { FacebookReview } from '../containers/client/FacebookReview';
import { Apps } from '../containers/admin/Apps';
import { EditApps } from '../containers/admin/EditApps';
import { ClientApps } from '../containers/client/Apps';
import { RequestTool } from '../containers/admin/RequestTool';
import { RequestCategoryOverview } from '../containers/admin/RequestCategoryOverview';
import { AddEditRequestForm } from '../containers/admin/AddEditRequestForm';
import { Request } from '../containers/client/Request';
import { FormSubmissionPage } from '../containers/client/FormSubmissionPage';
import { InstagramReview } from '../containers/client/IanstagarmFeeds';
import Support from '../containers/client/Support';
import { RssTicker } from '../containers/client/RssTicker';
import { ScrollingMessaging } from '../containers/client/ScrollingMessaging';
import { getAccessObjectFromArrayObject } from '../utils/helpers';
import { Feedback } from '../containers/client/Feedback';
import { AddEditFeedbackForm } from '../containers/admin/AddEditFeedbackForm';
import MasterAccess from '../containers/admin/MasterAccess';

export const useRoutes = () => {
    const { user, access } = useSelector((state) => state.auth);
    const userType = user?.role;

    // useEffect(() => {
    //     if (user?.client) {
    //         dispatch(fetchClientAccess({ params: { client_id: user?.client.id } }));
    //     }
    // }, [user?.client, dispatch]);

    const Routes = [
        {
            id: 'login_page',
            path: PAGES.LOGIN.url,
            name: PAGES.LOGIN.name,
            element: <Login />,
            hasPlanLayout: true,
            exact: true
        },
        {
            id: 'review-google',
            path: PAGES.GOOGLE_REVIEW.url,
            name: PAGES.GOOGLE_REVIEW.name,
            element: <GoogleReview />,
            hasPlanLayout: true,
            exact: true
        },
        {
            id: 'reset_password',
            path: PAGES.RESET_PASSWORD.url,
            name: PAGES.RESET_PASSWORD.name,
            element: <ResetPasswordForm />,
            hasPlanLayout: true,
            exact: true
        },
        {
            id: 'new_password',
            path: PAGES.NEW_PASSWORD.url,
            name: PAGES.NEW_PASSWORD.name,
            element: <NewPasswordForm />,
            hasPlanLayout: true,
            exact: true
        },
        {
            id: 'account_selection',
            path: PAGES.ACCOUNT_SELECTION.url,
            name: PAGES.ACCOUNT_SELECTION.name,
            element: <AccountSelection />,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            hasPlanLayout: true,
            exact: true
        },
        {
            id: 'accounts',
            path: PAGES.ACCOUNT.url,
            name: PAGES.ACCOUNT.name,
            element: <Accounts />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'accounts-details',
            path: PAGES.ACCOUNT.url + '/:id',
            name: PAGES.ACCOUNT.name,
            role: [USER_ROLE.ADMIN],
            element: <AccountsDetails />,
            exact: true
        },
        {
            id: 'users',
            path: PAGES.USERS.url,
            name: PAGES.USERS.name,
            role: [USER_ROLE.ADMIN],
            element: <Users />,
            exact: true
        },
        {
            id: 'layout',
            path: PAGES.LAYOUTS.url,
            name: PAGES.LAYOUTS.name,
            role: [USER_ROLE.ADMIN],
            element: <Layout />,
            exact: true
        },

        {
            id: 'add-layout',
            path: PAGES.ADD_LAYOUTS.url,
            name: PAGES.ADD_LAYOUTS.name,
            role: [USER_ROLE.ADMIN],
            element: <AddLayout />,
            exact: true
        },
        {
            id: 'edit-layout',
            path: PAGES.EDIT_LAYOUT.url + '/:id',
            name: PAGES.EDIT_LAYOUT.name,
            role: [USER_ROLE.ADMIN],
            element: <AddLayout />,
            exact: true
        },

        {
            id: 'loops',
            path: PAGES.LOOPS.url,
            name: PAGES.LOOPS.name,
            role: [USER_ROLE.ADMIN],
            element: <Loops />,
            exact: true
        },

        {
            id: 'librarys',
            accessKey: 'library',
            path: PAGES.LINEUP.url,
            name: PAGES.LINEUP.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <Librarys />,
            exact: true
        },
        {
            id: 'browse',
            path: PAGES.BROWSE.url,
            accessKey: 'browser',
            name: PAGES.BROWSE.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <Browse />,
            exact: true
        },

        {
            id: 'add-loop',
            path: PAGES.ADD_LOOP.url,
            name: PAGES.ADD_LOOP.name,
            role: [USER_ROLE.ADMIN],
            element: <AddLoop />,
            exact: true
        },
        {
            id: 'edit-loop',
            path: PAGES.EDIT_LOOP.url + '/:id',
            name: PAGES.EDIT_LOOP.name,
            role: [USER_ROLE.ADMIN],
            element: <AddLoop />,
            exact: true
        },
        {
            id: 'client-locations',
            path: PAGES.LOCATIONS.url,
            accessKey: 'locations',
            name: PAGES.LOCATIONS.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <Locations />,
            exact: true
        },
        {
            id: 'client-layout',
            accessKey: 'layout',
            path: PAGES.CLIENT_LAYOUT.url,
            name: PAGES.CLIENT_LAYOUT.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <ClientLayout />,
            exact: true
        },
        {
            id: 'client-loop',
            accessKey: 'loops',
            path: PAGES.CLIENT_LOOPS.url,
            name: PAGES.CLIENT_LOOPS.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <ClientLoops />,
            exact: true
        },
        {
            id: 'client-loop-template',
            path: PAGES.CLIENT_LOOP_TEMPLATE.url + '/:id',
            name: PAGES.CLIENT_LOOP_TEMPLATE.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <LoopTemplateSchedule />,
            exact: true
        },
        {
            id: 'client-loop-custom-create',
            path: PAGES.CLIENT_LOOP_CUSTOM_CREATE.url,
            name: PAGES.CLIENT_LOOP_CUSTOM_CREATE.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <LoopTemplateSchedule />,
            exact: true
        },
        {
            id: 'client-loop-custom-edit',
            path: PAGES.CLIENT_LOOP_CUSTOM_EDIT.url + '/:id',
            name: PAGES.CLIENT_LOOP_CUSTOM_EDIT.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <LoopTemplateSchedule />,
            exact: true
        },

        {
            id: 'client-loop-template-edit',
            path: PAGES.CLIENT_LOOP_TEMPLATE_EDIT.url + '/:id',
            name: PAGES.CLIENT_LOOP_TEMPLATE_EDIT.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <LoopTemplateSchedule />,
            exact: true
        },
        {
            id: 'create-custom',
            accessKey: 'content_creator',
            path: PAGES.CREATE_CUSTOM.url,
            name: PAGES.CREATE_CUSTOM.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <CreateCustom />,
            exact: true
        },
        {
            id: 'add-custom-content',
            path: PAGES.ADD_CUSTOM_CONTENT.url,
            name: PAGES.ADD_CUSTOM_CONTENT.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <ContentEditor />,
            exact: true
        },
        {
            id: 'edit-custom-content',
            path: PAGES.EDIT_CUSTOM_CONTENT.url + '/:id',
            name: PAGES.EDIT_CUSTOM_CONTENT.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <ContentEditor />,
            exact: true
        },
        {
            id: 'admin-videos',
            path: PAGES.VIDEOS.url,
            name: PAGES.VIDEOS.name,
            role: [USER_ROLE.ADMIN],
            element: <Videos />,
            exact: true
        },
        {
            id: 'admin-collections',
            path: PAGES.COLLECTIONS.url,
            name: PAGES.COLLECTIONS.name,
            role: [USER_ROLE.ADMIN],
            element: <Collections />,
            exact: true
        },
        {
            id: 'admin-collections-add',
            path: PAGES.ADD_COLLECTIONS.url,
            name: PAGES.ADD_COLLECTIONS.name,
            role: [USER_ROLE.ADMIN],
            element: <AddCollections />,
            exact: true
        },
        {
            id: 'admin-collections-edit',
            path: PAGES.EDIT_COLLECTIONS.url + '/:id',
            name: PAGES.EDIT_COLLECTIONS.name,
            role: [USER_ROLE.ADMIN],
            element: <AddCollections />,
            exact: true
        },
        {
            id: 'admin-groups',
            path: PAGES.GROUPS.url,
            name: PAGES.GROUPS.name,
            role: [USER_ROLE.ADMIN],
            element: <Groups />,
            exact: true
        },
        {
            id: 'admin-groups-add',
            path: PAGES.ADD_GROUPS.url,
            name: PAGES.ADD_GROUPS.name,
            role: [USER_ROLE.ADMIN],
            element: <AddGroup />,
            exact: true
        },
        {
            id: 'admin-groups-edit',
            path: PAGES.EDIT_GROUPS.url + '/:id',
            name: PAGES.EDIT_GROUPS.name,
            role: [USER_ROLE.ADMIN],
            element: <AddGroup />,
            exact: true
        },
        {
            id: 'admin-playlists',
            path: PAGES.PLAYLISTS.url,
            name: PAGES.PLAYLISTS.name,
            role: [USER_ROLE.ADMIN],
            element: <Playlists />,
            exact: true
        },
        {
            id: 'admin-playlists-add',
            path: PAGES.ADD_PLAYLIST_CATEGORY.url,
            name: PAGES.ADD_PLAYLIST_CATEGORY.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditPlaylistCategory />,
            exact: true
        },
        {
            id: 'admin-playlists-edit',
            path: PAGES.EDIT_PLAYLIST_CATEGORY.url + '/:id',
            name: PAGES.EDIT_PLAYLIST_CATEGORY.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditPlaylistCategory />,
            exact: true
        },
        {
            id: 'admin-backgrounds',
            path: PAGES.BACKGROUNDS.url,
            name: PAGES.BACKGROUNDS.name,
            role: [USER_ROLE.ADMIN],
            element: <Backgrounds />,
            exact: true
        },
        {
            id: 'admin-background-add',
            path: PAGES.ADD_BACKGROUND.url,
            name: PAGES.ADD_BACKGROUND.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditBackground />,
            exact: true
        },
        {
            id: 'admin-background-edit',
            path: PAGES.EDIT_BACKGROUND.url + '/:id',
            name: PAGES.EDIT_BACKGROUND.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditBackground />,
            exact: true
        },
        {
            id: 'client-content-upload',
            accessKey: 'upload',
            path: PAGES.CONTENT_UPLOAD.url,
            name: PAGES.CONTENT_UPLOAD.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <Upload />,
            exact: true
        },
        {
            id: 'client-social-media',
            accessKey: 'social_media',
            path: PAGES.CLIENT_SOCIAL_MEDIA.url,
            name: PAGES.CLIENT_SOCIAL_MEDIA.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <SocialMedia />,
            exact: true
        },

        {
            id: 'review-facebook',
            path: PAGES.FACEBOOK_REVIEW.url,
            name: PAGES.FACEBOOK_REVIEW.name,
            element: <FacebookReview />,
            exact: true,
            hasPlanLayout: true
        },

        {
            id: 'review-facebook',
            path: PAGES.INSTAGRAM_REVIEW.url,
            name: PAGES.INSTAGRAM_REVIEW.name,
            element: <InstagramReview />,
            exact: true,
            hasPlanLayout: true
        },
        {
            id: 'admin-apps',
            path: PAGES.APPS.url,
            name: PAGES.APPS.name,
            element: <Apps />,
            exact: true,
            role: [USER_ROLE.ADMIN]
        },
        {
            id: 'admin-add-apps',
            path: PAGES.ADD_APPS.url,
            name: PAGES.ADD_APPS.name,
            element: <EditApps />,
            exact: true,
            role: [USER_ROLE.ADMIN]
        },
        {
            id: 'admin-edit-apps',
            path: PAGES.EDIT_APPS.url + '/:id',
            name: PAGES.EDIT_APPS.name,
            element: <EditApps />,
            exact: true,
            role: [USER_ROLE.ADMIN]
        },
        {
            id: 'client-apps',
            path: PAGES.CLIENT_APPS.url,
            name: PAGES.CLIENT_APPS.name,
            element: <ClientApps />,
            exact: true,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER]
        },
        {
            id: 'REQUEST_TOOL',
            path: PAGES.REQUEST_TOOL.url,
            name: PAGES.REQUEST_TOOL.name,
            element: <RequestTool />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'FEEDBACK_TOOL',
            path: PAGES.FEEDBACK_TOOL.url,
            name: PAGES.FEEDBACK_TOOL.name,
            element: <FeedbackTool />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'FEEDBACK_TOOL_EDIT',
            path: PAGES.FEEDBACK_FORM_EDIT.url + '/:id',
            name: PAGES.FEEDBACK_FORM_EDIT.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditFeedbackForm />,
            exact: true
        },
        {
            id: 'FEEDBACK_TOOL_ADD',
            path: PAGES.FEEDBACK_FORM_ADD.url,
            name: PAGES.FEEDBACK_FORM_ADD.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditFeedbackForm />,
            exact: true
        },
        {
            id: 'SYSTEM_VERSIONS',
            path: PAGES.SYSTEM_VERSIONS.url,
            name: PAGES.SYSTEM_VERSIONS.name,
            element: <SystemVersion />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'account-types',
            path: PAGES.ACCOUNT_TYPES.url,
            name: PAGES.ACCOUNT_TYPES.name,
            element: <AccountTypes />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'account-types-details',
            path: PAGES.ACCOUNT_TYPES.url + '/:typeId',
            name: PAGES.ACCOUNT_TYPES.name,
            role: [USER_ROLE.ADMIN],
            element: <AccountTypesDetails />,
            exact: true
        },
        {
            id: 'curation-profiles ',
            path: PAGES.CURATION_PROFILES.url,
            name: PAGES.CURATION_PROFILES.name,
            element: <CurationProfiles />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'curation-profiles-add',
            path: PAGES.ADD_CURATION_PROFILES.url,
            name: PAGES.ADD_CURATION_PROFILES.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditCurationProfile />,
            exact: true
        },
        {
            id: 'curation-profiles-edit',
            path: PAGES.EDIT_CURATION_PROFILES.url + '/:id',
            name: PAGES.EDIT_CURATION_PROFILES.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditCurationProfile />,
            exact: true
        },
        {
            id: 'MASTER_ACCESS',
            path: PAGES.MASTER_ACCESS.url,
            name: PAGES.MASTER_ACCESS.name,
            element: <MasterAccess />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'REQUEST_TOOL_DETAILS_OVERVIEW',
            path: PAGES.REQUEST_TOOL_DETAILS_OVERVIEW.url + '/:id',
            name: PAGES.REQUEST_TOOL_DETAILS_OVERVIEW.name,
            element: <RequestCategoryOverview />,
            role: [USER_ROLE.ADMIN],
            exact: true
        },
        {
            id: 'REQUEST_TOOL_ADD',
            path: PAGES.REQUEST_TOOL_ADD_FORM.url + '/:id/add-form',
            name: PAGES.REQUEST_TOOL_ADD_FORM.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditRequestForm />,
            exact: true
        },
        {
            id: 'REQUEST_TOOL_EDIT',
            path: PAGES.REQUEST_TOOL_EDIT_FORM.url + '/:id/edit-form/:formId',
            name: PAGES.REQUEST_TOOL_EDIT_FORM.name,
            role: [USER_ROLE.ADMIN],
            element: <AddEditRequestForm />,
            exact: true
        },
        {
            id: 'REQUEST',
            path: PAGES.REQUEST.url,
            name: PAGES.REQUEST.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <Request />,
            exact: true
        },
        {
            id: 'REQUEST_SUBMISSION',
            path: PAGES.REQUEST_SUBMISSION.url + '/:id',
            name: PAGES.REQUEST_SUBMISSION.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <FormSubmissionPage />,
            exact: true
        },
        {
            id: 'SUPPORT',
            path: PAGES.SUPPORT.url,
            name: PAGES.SUPPORT.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <Support />,
            exact: true
        },
        {
            id: 'FEEDBACK',
            path: PAGES.FEEDBACK.url,
            name: PAGES.FEEDBACK.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <Feedback />,
            exact: true
        },
        {
            id: 'FEEDBACK_SUBMISSION',
            path: PAGES.FEEDBACK_SUBMISSION.url + '/:id',
            name: PAGES.FEEDBACK_SUBMISSION.name,
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <FormSubmissionPage fixHeight for="feedback" />,
            exact: true
        },
        {
            id: 'rss-ticker',
            path: PAGES.RSS_TICKER.url,
            name: PAGES.RSS_TICKER.name,
            element: <RssTicker />,
            exact: true,
            hasPlanLayout: true
        },
        {
            id: 'scrolling_messaging',
            path: PAGES.SCROLLING_MESSAGING.url,
            name: PAGES.SCROLLING_MESSAGING.name,
            globalAccessKey: 'sidebar_visible',
            role: [USER_ROLE.MANAGER, USER_ROLE.VIEWER],
            element: <ScrollingMessaging />,
            exact: true
        }
    ];

    const checkAccessRoutesPermission = (item) => {
        let accessItem;
        if (item?.role && item?.role.includes(userType)) {
            if (access?.access) {
                accessItem = getAccessObjectFromArrayObject(access?.access, item.accessKey);
            }
            if (item.accessKey) {
                if (accessItem) {
                    return accessItem.visibility;
                } else {
                    return true;
                }
            }
            return true;
        }
        return true;
    };

    const accessibleRoutes = useMemo(() => {
        return Routes.filter((value) => checkAccessRoutesPermission(value));
    }, [Routes, access]);

    const unAuthorizedRoutes = useMemo(() => {
        return Routes.filter((value) => !checkAccessRoutesPermission(value));
    }, [Routes]);

    const planeLayoutRoutes = useMemo(() => {
        return accessibleRoutes.filter((value) => value.hasPlanLayout);
    });

    const mainLayoutRoutes = useMemo(() => {
        return accessibleRoutes.filter((value) => !value.hasPlanLayout);
    });

    return {
        mainLayoutRoutes,
        planeLayoutRoutes,
        unAuthorizedRoutes,
        allRoutes: Routes
    };
};
