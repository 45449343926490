import { TextField } from '@mui/material';
import React from 'react';
import Flatpickr from 'react-flatpickr';

const CustomDateTimePicker = ({ type, onChange, options, value }) => {
    const config = type === 'time' ? { enableTime: true, noCalendar: true, dateFormat: 'h:i K' } : {};
    return (
        <Flatpickr
            render={({ defaultValue, value, ...props }, ref) => {
                return <TextField defaultValue={defaultValue} inputRef={ref} />;
            }}
            options={{ ...config, ...options, disableMobile: true }}
            value={value}
            onChange={onChange}
        />
    );
};

export default CustomDateTimePicker;
