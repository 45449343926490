import { FormHelperText, Grid, Box, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommonButton from '../../../../components/CommonButton';
import { CommonInput } from '../../../../components/CommonInput';
import ImagePlaceholder from '../../../../widgets/ImagePlaceholder';
import { useFormik } from 'formik';
import { accountFormValidation } from '../../../../utils/validations';
import { api } from '../../../../api';
import { useSnackbar } from 'notistack';
import { fetchClines } from '../../../../redux/reducers/clientsSlice';
import { useDispatch, useSelector } from 'react-redux';
import FieldLabelView from '../../../../components/FieldLabelView';
import CommonModal from '../../../../components/CommonModal';
import { crop } from '../../../../utils/helpers';
import { ASPECT_RATIO, ORIENTATION } from '../../../../utils/constants';
import { CommonMultiSelect } from '../../../../components/CommonMultiSelect';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../../redux/reducers/accountTypesSlice';

const StyledInput = styled(CommonInput)(({ theme }) => ({
    flex: 1
}));

const ButtonContainer = styled(Box)({
    margin: '20px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end'
});
const FieldGrid = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0px'
});
const GridBox = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'flex'
    }
}));
const GridItemBox = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'flex'
    }
}));

const AddAccountModal = ({ open, handleClose, onSaveClick }) => {
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { territory, Specialty } = useSelector(({ accountTypes }) => accountTypes);

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, resetForm, setFieldValue } = useFormik({
        initialValues: {
            name: '',
            type: '',
            logo: null,
            landscape: null,
            portrait: null,
            logo_file: '',
            landscape_file: '',
            portrait_file: '',
            territory_id: '',
            speciality_id: ''
        },
        validationSchema: accountFormValidation,
        onSubmit: async (values) => {
            setLoading(true);
            try {
                let params = {};
                params['name'] = values.name;
                params['type'] = values.type;
                if (values.logo) params['logo'] = values.logo;
                if (values.landscape) params['landscape'] = values.landscape;
                if (values.portrait) params['portrait'] = values.portrait;
                if (values.territory_id) params['territory_id'] = values.territory_id;
                if (values.speciality_id) params['speciality_id'] = values.speciality_id;
                const { data } = await api.client.add(params);
                if (data.success) {
                    dispatch(fetchClines());
                    enqueueSnackbar(data.message, { variant: 'success' });
                    handleCloseEvent();
                } else {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    const handleCloseEvent = () => {
        resetForm();
        handleClose();
    };

    useEffect(() => {
        dispatch(fetchAccountTypeTerritory());
        dispatch(fetchAccountTypeSpecialty());
    }, [dispatch]);

    return (
        <CommonModal open={open} onClose={handleCloseEvent} title={'Add Account'} width={500}>
            <form onSubmit={handleSubmit}>
                <FieldGrid item md={12} sm={12} xs={12} alignItems={'center'}>
                    <FieldLabelView label={'Name'}>
                        <StyledInput
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={touched.name && errors.name}
                            name={'name'}
                            value={values?.name}
                            type={'name'}
                        />
                    </FieldLabelView>
                </FieldGrid>
                <FieldGrid item md={12} sm={12} xs={12}>
                    <FieldLabelView label={'Type'}>
                        <StyledInput
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            error={touched.type && errors.type}
                            name={'type'}
                            value={values?.type}
                            type={'type'}
                        />
                    </FieldLabelView>
                </FieldGrid>
                <FieldGrid item md={12} sm={12} xs={12}>
                    <FieldLabelView label={'Territory'}>
                        <CommonMultiSelect
                            name={'territory_id'}
                            error={touched.territory_id && errors.territory_id}
                            value={values.territory_id}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            options={territory?.map((cv) => {
                                return { id: cv?.id, name: cv?.name, value: cv?.id };
                            })}
                        />
                    </FieldLabelView>
                </FieldGrid>
                <FieldGrid item md={12} sm={12} xs={12}>
                    <FieldLabelView label={'Specialty'}>
                        <CommonMultiSelect
                            name={'speciality_id'}
                            error={touched.speciality_id && errors.speciality_id}
                            value={values.speciality_id}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            options={Specialty?.map((cv) => {
                                return { id: cv?.id, name: cv?.name, value: cv?.id };
                            })}
                        />
                    </FieldLabelView>
                </FieldGrid>

                <GridBox container item md={12} xs={12} spacing={3} justifyContent={'space-between'}>
                    <GridItemBox item md={3} lg={3} sm={3} xs={5}>
                        <FieldLabelView label={'Logo'}>
                            <Grid style={{ width: '100%' }} container>
                                <ImagePlaceholder
                                    boxDimensions={{ height: 90, width: '100%' }}
                                    handleChange={async (event) => {
                                        let image = await crop(URL.createObjectURL(event.target.files[0]), ASPECT_RATIO.LOGO);
                                        setFieldValue('logo_file', image);
                                        setFieldValue('logo', image?.split(',')[1]);
                                    }}
                                    image={values.logo_file}
                                    isAddIcon={values.logo_file ? false : true}
                                />
                            </Grid>
                        </FieldLabelView>
                        {touched.logo_file && errors.logo_file && <FormHelperText error>{errors.logo_file}</FormHelperText>}
                    </GridItemBox>
                    <Grid item md={8} sm={8} xs={12}>
                        <FieldLabelView label={'Thumbnails'}>
                            <Grid style={{ width: '100%' }} container>
                                <Grid item md={12} sm={12} xs={12}>
                                    <Grid style={{ width: '100%' }} container spacing={2} sx={{ ml: 0 }}>
                                        <Grid item lg={8} md={8} sm={8} xs={7}>
                                            <ImagePlaceholder
                                                handleChange={async (event) => {
                                                    let image = await crop(
                                                        URL.createObjectURL(event.target.files[0]),
                                                        ASPECT_RATIO[ORIENTATION.LANDSCAPE]
                                                    );
                                                    setFieldValue('landscape_file', image);
                                                    setFieldValue('landscape', image?.split(',')[1]);
                                                }}
                                                image={values.landscape_file}
                                                isAddIcon={values.landscape_file ? false : true}
                                                boxDimensions={{ height: 90, width: '100%' }}
                                            />
                                            {touched.landscape_file && errors.landscape_file && (
                                                <FormHelperText error>{errors.landscape_file}</FormHelperText>
                                            )}
                                        </Grid>
                                        <Grid item lg={3} md={3} sm={3} xs={3}>
                                            <ImagePlaceholder
                                                handleChange={async (event) => {
                                                    let image = await crop(
                                                        URL.createObjectURL(event.target.files[0]),
                                                        ASPECT_RATIO[ORIENTATION.PORTRAIT]
                                                    );

                                                    setFieldValue('portrait_file', image);
                                                    setFieldValue('portrait', image?.split(',')[1]);
                                                }}
                                                image={values.portrait_file}
                                                isAddIcon={values.portrait_file ? false : true}
                                                boxDimensions={{ height: 90, width: '100%' }}
                                            />
                                            {touched.portrait_file && errors.portrait_file && (
                                                <FormHelperText error>{errors.portrait_file}</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </FieldLabelView>
                    </Grid>
                </GridBox>
                <ButtonContainer>
                    <CommonButton onClick={handleSubmit} type={'submit'} variant="contained" loading={loading} label={'Create'} />
                </ButtonContainer>
            </form>
        </CommonModal>
    );
};

export default AddAccountModal;
