import React, { Fragment, useEffect, useState } from 'react';
import { IMAGES } from '../../../assets';
import { Box, Popover, Typography } from '@mui/material';
import CommonRangePicker from '../../CommonRangePicker';
import styled from '@emotion/styled';
import CustomTooltip from '../../../widgets/CustomTooltip';
import { COLORS } from '../../../utils/theme';
import { useDispatch } from 'react-redux';
import { stopPropagation } from '../../../redux/reducers/editorSlice';

const MainBox = styled(Box)({
    padding: 12
});

const Title = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '1rem',
    textAlign: 'center'
});

const ActionIconImage = styled('img')({
    height: 34,
    borderRadius: 4,
    border: `1px solid ${COLORS.border}`,
    width: 34,
    objectFit: 'contain',
    cursor: 'pointer'
});

const OpacityRangePicker = ({ sx, id, open, anchorEl, handleClose, value, handleOpacityChange, ...props }) => {
    return (
        <Popover
            {...props}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <MainBox sx={sx}>
                <Title>Opacity</Title>
                <CommonRangePicker {...{ size: 'small', name: 'opacity', value, handleChange: handleOpacityChange }} />
            </MainBox>
        </Popover>
    );
};

const OpacityAction = ({ handleChangeAction, type, value, isPrevent, ...props }) => {
    const [anchorElement, setAnchorElement] = useState({
        anchorOpacityPopup: null
    });
    const dispatch = useDispatch();

    const handleOpenPopup = (e, name) => {
        setAnchorElement({
            ...anchorElement,
            [name]: e?.currentTarget
        });
    };

    const handleOpacityChange = (e) => {
        const { value } = e.target;
        const actionData = {
            type,
            value
        };
        handleChangeAction(actionData);
    };

    const handleClosePopup = (name) => setAnchorElement({ ...anchorElement, [name]: null });

    useEffect(() => {
        dispatch(stopPropagation(Boolean(anchorElement.anchorOpacityPopup)));
    }, [anchorElement.anchorOpacityPopup, dispatch]);

    return (
        <Fragment>
            <CustomTooltip arrow title="Change opacity" placement="bottom">
                <ActionIconImage
                    onClick={(e) => {
                        handleOpenPopup(e, 'anchorOpacityPopup');
                        e.stopPropagation();
                    }}
                    src={IMAGES.OpacityIcon}
                />
            </CustomTooltip>
            <OpacityRangePicker
                sx={{ width: 200 }}
                {...{ value }}
                open={Boolean(anchorElement.anchorOpacityPopup)}
                anchorEl={anchorElement.anchorOpacityPopup}
                handleClose={() => handleClosePopup('anchorOpacityPopup')}
                id={Boolean(anchorElement.anchorOpacityPopup) ? 'opacity-popup' : null}
                handleOpacityChange={handleOpacityChange}
            />
        </Fragment>
    );
};

export default OpacityAction;
