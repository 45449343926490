import { styled, Typography, Box, Grid, useTheme, useMediaQuery, Input } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContentTabBar } from '../../../../components/ContentTabBar';
import { LoopViewWithDiv } from '../../../../components/LoopViewWithDiv/LoopViewWithDiv';
import { deletePlaylist, syncAutoPlayListContent, updateLoopFormDataByKey } from '../../../../redux/reducers/loopSlice';
import { LOOP_OPTIONS_TAB } from '../../../../utils/constants';
import { COLORS } from '../../../../utils/theme';
import { LoopSchedule } from './LoopSchedule';
import { PlayListDetails } from './PlayListDetails';
import { PlayLists } from './PlayLists';

const MainContainerGrid = styled(Grid)({
    position: 'relative'
});

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem',
    backgroundColor: COLORS.white
});

const LoopNameText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});

const TemplatesMainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
});

const TemplatesHeaderBox = styled(Box)({
    width: '100%',
    borderBottom: `1px solid ${COLORS.border}`,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
});

const LoopOptionHeaderBox = styled(Box)({
    width: '100%',
    borderBottom: `1px solid ${COLORS.border}`,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
});

const StyledInput = styled(Input)({
    fontSize: 26,
    color: COLORS.black,
    textDecoration: 'none'
});

const ActionText = styled(Typography)({
    color: COLORS.darkGray,
    fontWeight: 400,
    fontSize: 16,
    marginLeft: 8
});

export const LoopChart = ({ isInTemplate, onReset, reset, orientation }) => {
    const dispatch = useDispatch();
    const { loopFormData } = useSelector((state) => state.loop);
    const [isNameDisable, setNameDisable] = useState(true);
    const [activeLoopOptionTab, setActiveLoopOptionTab] = useState(isInTemplate ? 2 : 1);
    const theme = useTheme();
    const matchDowmSm = useMediaQuery(theme.breakpoints.down('sm'));
    const { access } = useSelector((state) => state.auth);

    const loopOptions = {
        OUTSIDE_BACKGROUND_WIDTH: matchDowmSm ? 280 : 350,
        MAIN_ROUNT_WIDTH: matchDowmSm ? 210 : 270,
        CHART_WIDTH: matchDowmSm ? 140 : 180,
        INSIDE_WHITE_WIDTH: matchDowmSm ? 120 : 160
    };
    const chartData = useMemo(() => {
        return loopFormData?.playList?.map?.((item) => {
            return {
                color: item.color,
                value: 1,
                label: 'PL',
                name: item.name,
                icon: item.icon,
                index: item.index,
                uniqId: item.uniqId
            };
        });
    }, [loopFormData.playList]);

    const loopOptionsTab = useMemo(() => {
        let result = LOOP_OPTIONS_TAB.map((item) => {
            return { ...item, inactive: access[[item?.name?.toLowerCase()]] ? !access[[item?.name?.toLowerCase()]]?.visibility : 0 };
        });
        return result;
    }, [access]);

    useEffect(() => {
        dispatch(syncAutoPlayListContent());
    }, [loopFormData?.playList?.length, dispatch]);

    const handleChangeLoopTab = (item) => {
        setActiveLoopOptionTab(item.id);
    };

    const onDeletePlayList = (id) => {
        let playlist = loopFormData?.playList.find((item) => item.uniqId === id);
        dispatch(deletePlaylist(playlist));
    };

    const onSelectPlaylist = (id) => {
        let playlist = loopFormData?.playList?.find((item) => item.uniqId === id);
        dispatch(updateLoopFormDataByKey({ key: 'activePlaylist', value: playlist }));
    };

    const handleChangeLoopName = (name) => {
        dispatch(updateLoopFormDataByKey({ key: 'name', value: name }));
    };

    const onRemoveFromPlaylist = (item) => {
        const playlist = chartData.find((e) => e.name === item.name);
        onDeletePlayList(playlist.uniqId);
    };

    return (
        <>
            <MainBox>
                <TemplatesHeaderBox>
                    {!isInTemplate && !isNameDisable ? (
                        <StyledInput
                            name={'name'}
                            disableUnderline={isNameDisable}
                            onFocus={() => setNameDisable(false)}
                            onBlur={() => setNameDisable(true)}
                            onChange={(e) => {
                                handleChangeLoopName(e.target.value);
                            }}
                            value={loopFormData?.name}
                            autoFocus={!isNameDisable}
                            inputProps={{ maxLength: 19 }}
                        />
                    ) : (
                        <div onClick={() => setNameDisable(false)}>
                            <Typography sx={{ fontSize: 26 }}>{loopFormData?.name || 'Loop Name Here'}</Typography>
                        </div>
                    )}
                    {!isInTemplate && isNameDisable && <ActionText>{'(Click to edit title)'}</ActionText>}
                </TemplatesHeaderBox>
                <MainContainerGrid container>
                    <Grid item xs={12} sm={6} md={6}>
                        <TemplatesMainBox>
                            <Typography sx={{ mt: 1, color: COLORS.primary.light, fontSize: 14, fontWeight: 'bold' }}>
                                {loopFormData?.is_primary ? 'PRIMARY' : null}
                            </Typography>

                            <Box sx={{ mt: 1 }}>
                                <LoopViewWithDiv
                                    canDeletePlaylist={!isInTemplate}
                                    onSelectPlaylist={onSelectPlaylist}
                                    onDeleteItem={onDeletePlayList}
                                    chartData={chartData}
                                    options={loopOptions}
                                    onReset={onReset}
                                    reset={reset}
                                    defaultSelect={true}
                                />
                            </Box>
                        </TemplatesMainBox>
                    </Grid>
                    <Grid sx={{ borderLeft: `1px solid ${COLORS.border}` }} xs={12} sm={6} item md={6}>
                        <TemplatesMainBox>
                            <LoopOptionHeaderBox>
                                <LoopNameText>{isInTemplate ? 'Schedule' : 'Loop Options'}</LoopNameText>
                            </LoopOptionHeaderBox>
                            <Box sx={{ padding: { xs: 1, sm: 1, md: 2, lg: 3 }, width: '100%' }}>
                                {!isInTemplate && (
                                    <ContentTabBar
                                        // activeColor={COLORS.border}
                                        tabArray={loopOptionsTab}
                                        activeTab={activeLoopOptionTab}
                                        inactiveStyle={{ opacity: 0.3 }}
                                        handleChangeActiveTab={handleChangeLoopTab}
                                    />
                                )}
                                {activeLoopOptionTab === 1 ? <PlayLists onDeletePlayList={onRemoveFromPlaylist} /> : <LoopSchedule />}
                            </Box>
                        </TemplatesMainBox>
                    </Grid>
                </MainContainerGrid>
            </MainBox>
            {loopFormData?.activePlaylist && <PlayListDetails orientation={orientation} />}
        </>
    );
};
