import { Box, IconButton, styled } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { COLORS } from '../../../../utils/theme';
import CommonButton from '../../../../components/CommonButton';
import { useSnackbar } from 'notistack';
import { getAccessObjectFromArrayObject } from '../../../../utils/helpers';
import { api } from '../../../../api';
import CommonModal from '../../../../components/CommonModal';
import FieldLabelView from '../../../../components/FieldLabelView';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IMAGES } from '../../../../assets';

const MainBox = styled(Box)({
    width: '100%'
});
const EyeIconStyle = styled(Box)({ display: 'flex', justifyContent: 'center' });
const IconButtonStyle = styled(IconButton)({
    width: 64,
    height: 38,
    borderRadius: 0,
    border: `1px solid ${COLORS.darkGray}`
});
const ButtonWrap = styled(Box)({
    display: 'flex',
    margin: '10px 0px',
    justifyContent: 'flex-end'
});

const StatusImage = styled('img')({
    height: 22,
    width: 22
});

const StyledButton = styled(CommonButton)(({ theme }) => ({
    color: COLORS.black,
    fontWeight: 100
}));

export const MasterAccessModel = ({ open, handleClose, access, clientAccess, fetchClientAccess, defaultAccess }) => {
    const [accessData, setAccessData] = useState({
        status: 1,
        visibility: 1
    });
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState();

    useEffect(() => {
        setAccessData({
            status: clientAccess ? getAccessObjectFromArrayObject(clientAccess, access.id).status : access.visibility,
            visibility: clientAccess ? getAccessObjectFromArrayObject(clientAccess, access.id).visibility : access.visibility
        });
    }, [clientAccess, access]);

    const handleChange = (key, value) => {
        setAccessData({ ...accessData, [key]: value });
    };

    const handleSave = async () => {
        try {
            setLoading(true);
            const reqData = {
                [access.id]: {
                    visibility: +accessData.visibility
                }
            };
            const { data } = await api.master.updateAccess({ data: reqData });
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                fetchClientAccess();
                handleClose();
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            enqueueSnackbar(error.message.error, { variant: 'error' });
        }
    };

    return (
        <CommonModal open={open} onClose={handleClose} title={'Edit Access'} width={500}>
            <MainBox>
                <FieldLabelView label={`${access?.name} will be set to:`}>
                    <EyeIconStyle sx={{ mt: 2 }}>
                        <IconButtonStyle
                            onClick={() => handleChange('visibility', true)}
                            sx={{ mx: 2, backgroundColor: accessData?.visibility ? COLORS.darkGray : COLORS.white }}
                        >
                            <Visibility sx={{ color: 'green ' }} />
                        </IconButtonStyle>
                        <IconButtonStyle
                            onClick={() => handleChange('visibility', false)}
                            sx={{ mx: 2, backgroundColor: !accessData?.visibility ? COLORS.darkGray : COLORS.white }}
                        >
                            <VisibilityOff sx={{ color: 'red' }} />
                        </IconButtonStyle>
                    </EyeIconStyle>

                    {access?.hasStatus && (
                        <EyeIconStyle sx={{ mt: 2 }}>
                            <IconButtonStyle
                                onClick={() => handleChange('status', true)}
                                sx={{ mx: 2, backgroundColor: accessData?.status ? COLORS.darkGray : COLORS.white }}
                            >
                                <StatusImage src={IMAGES.CheckMark} />
                            </IconButtonStyle>
                            <IconButtonStyle
                                onClick={() => handleChange('status', false)}
                                sx={{ mx: 2, backgroundColor: !accessData?.status ? COLORS.darkGray : COLORS.white }}
                            >
                                <StatusImage src={IMAGES.RemoveCircleIcon} />
                            </IconButtonStyle>
                        </EyeIconStyle>
                    )}
                </FieldLabelView>
                <ButtonWrap>
                    <StyledButton loading={loading} onClick={handleSave} label={'Save'} variant="contained" />
                </ButtonWrap>
            </MainBox>
        </CommonModal>
    );
};
