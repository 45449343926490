import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';
import Loader from '../../Loader';
import { MENU_ITEM } from '../../../utils/urls';
import { getAccessObjectFromArrayObject } from '../../../utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientAccess } from '../../../redux/reducers/authSlice';

export default function Page401() {
    const { user, access } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [loadingAccess, setLoadingAccess] = useState(true);

    useEffect(() => {
        if (user) {
            dispatch(fetchClientAccess({ params: { client_id: user?.client?.id } })).then(() => {
                setLoadingAccess(false);
            });
        } else {
            setLoadingAccess(false);
        }
    }, [dispatch, user, user?.client?.id]);

    const checkPermissionOfChildrenItem = (item) => {
        let accessItem;
        if (access?.access) {
            accessItem = getAccessObjectFromArrayObject(access?.access, item.accessKey);
        }
        if (item.accessKey) {
            if (accessItem) {
                return accessItem.visibility;
            } else {
                return true;
            }
        }
        return true;
    };

    const checkPermissionOfSection = (item) => {
        if (item?.role?.includes?.(user?.role)) {
            let accessItem;
            if (access?.access) {
                accessItem = getAccessObjectFromArrayObject(access?.access, item.accessKey);
            }
            if (item.accessKey) {
                if (accessItem) {
                    return accessItem.visibility;
                } else {
                    return true;
                }
            }
            return true;
        }
        return false;
    };

    const checkForCurrentLocationIsAvailableOrNot = (url) => {
        let findedItem = null;

        MENU_ITEM?.forEach((section) => {
            if (checkPermissionOfSection(section)) {
                let item = section?.children?.find((item) => item?.url === url);
                if (item) {
                    findedItem = item;
                    return true;
                }
            }
        });
        if (findedItem) {
            return checkPermissionOfChildrenItem(findedItem);
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (access?.client_id) {
            if (!checkForCurrentLocationIsAvailableOrNot(location?.pathname)) {
                navigate('/');
            }
        }
    }, [access?.client_id, loadingAccess]);

    return (
        <>
            <Container>
                <Loader isLoading={true} />
            </Container>
        </>
    );
}
