import { Box, Grid, Popover, styled, Typography } from "@mui/material";

const MainBox = styled(Box)({
  width: 200,
  padding: 12,
});

const ItemMainGrid = styled(Grid)({
  cursor: "pointer",
});

const FONTS_LIST = [
  {
    id: 0,
    name: "None",
    value: "",
  },
  {
    id: 1,
    name: "Hanalei Fill",
    value: "Hanalei Fill",
  },
];

export const FontsPopup = ({
  id,
  open,
  anchorEl,
  handleClose,
  handleSelectFontFamily,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      <MainBox>
        <Grid container spacing={2}>
          {FONTS_LIST.map((item) => {
            return (
              <ItemMainGrid
                onClick={() => handleSelectFontFamily(item)}
                key={item.id}
                item
                md={12}
              >
                <Typography style={{ fontFamily: item.value }}>
                  {item.name}
                </Typography>
              </ItemMainGrid>
            );
          })}
        </Grid>
      </MainBox>
    </Popover>
  );
};
