import { Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLocations } from '../../../redux/reducers/clientLocationSlice';
import LocationCard from '../../../widgets/LocationCard';
import { CommonHeader } from '../../../widgets/CommonHeader';
import AddLocationModel from '../../../components/Modal/AddLocationModel';
import AddLocationNameModel from '../../../components/Modal/AddLocationNameModel';
import CommonPopover from '../../../components/CommonPopover';
import MirrorModal from './components/MirrorModal';
import { ICONS } from '../../../assets';
import { ORIENTATION, SkeletonArray, USER_ROLE } from '../../../utils/constants';
import ImagePlaceholder from '../../../widgets/ImagePlaceholder';
import { api } from '../../../api';
import { syncAllLoopByLibrary } from '../../../redux/reducers/loopSlice';
import { getOriantationByLayout } from '../../../utils/helpers';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../../utils/theme';

const ActionText = styled(Typography)({
    cursor: 'pointer'
});

const MainCard = styled(Box)({
    backgroundColor: COLORS.white,
    height: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    position: 'relative',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem',
});

export const Locations = () => {
    const dispatch = useDispatch();
    const enqueueSnackbar = useSnackbar();
    const { locations } = useSelector((state) => state.clientLocation);
    const { syncingLoopStatus } = useSelector((state) => state.loop);
    const { user } = useSelector((state) => state.auth);

    const [showAddLocationModal, setShowAddLocationModal] = useState(false);
    const [showLocationNameModel, setShowLocationNameModel] = useState(false);
    const [showMirrorModal, setShowMirrorModal] = useState(false);
    const [locationData, setLocationData] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingRefresh, setLoadingRefresh] = useState(false);

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setLocationData(data);
    };

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchLocations()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch, user.client]);

    const checkForActiveSetMirror = () => {
        if (locations?.findIndex((item) => item?.mirror_of_data?.id === locationData?.id) === -1) {
            return true;
        }
        return false;
    };

    const rebootLocation = async () => {
        try {
            await api.location.rebootDevice({ params: { location_id: locationData?.id } });
            setAnchorEl(null);
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
            return error;
        }
    };

    const refreshLocation = async () => {
        try {
            let orientation = '';
            if (locations.length !== 0) {
                let activeLocationData = locations.find((item) => item?.id === locationData?.id);
                orientation = getOriantationByLayout(activeLocationData?.layout);
            } else {
                orientation = ORIENTATION.LANDSCAPE;
            }
            if (orientation) {
                setLoadingRefresh(true);

                const response = await api.loop.getLoopByLocation({
                    params: {
                        location_id: locationData?.id
                    }
                });
                const libraryResponse = await api.library.get({ params: { orientation: orientation, status: 1 } });
                setLoadingRefresh(false);
                setAnchorEl(null);

                dispatch(syncAllLoopByLibrary({ loops: response?.data?.result, libraryContents: libraryResponse?.data?.result }));
            }
        } catch (error) { }
    };

    const SyncingStatus = () => {
        return (
            <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', mr: 1 }}>
                {syncingLoopStatus?.totalSyncFound !== 0 && (
                    <span>{`${syncingLoopStatus?.currentSync?.name} is syncing... (${syncingLoopStatus?.syncDone}/${syncingLoopStatus?.totalSyncFound})`}</span>
                )}
            </Box>
        );
    };

    return (
        <Box>
            <CommonHeader customAction={() => SyncingStatus()} isXSset6={true} title={'Locations'} />
            <Box sx={{ marginTop: '24px' }} ></Box>
            <Grid container spacing={3}>
                {isLoading
                    ? SkeletonArray.map((skeleton) => {
                        return (
                            <Grid item xs={12} sm={4} lg={3} xxl={2} key={skeleton}>
                                <LocationCard loading={isLoading} Icon={ICONS.MoreVertSharpIcon} />
                            </Grid>
                        );
                    })
                    : locations &&
                    locations.map((item) => {
                        return (
                            <Grid item xs={12} sm={4} lg={3} xxl={2} key={item.id}>
                                <LocationCard
                                    data={item}
                                    handleEdit={(e) => handleClick(e, item)}
                                    Icon={ICONS.MoreVertSharpIcon}
                                    reload={() => {
                                        setIsLoading(true);
                                        dispatch(fetchLocations()).then(() => {
                                            setIsLoading(false);
                                        });
                                    }}
                                />
                            </Grid>
                        );
                    })}
                {user?.role !== USER_ROLE?.VIEWER && (
                    <Grid item xs={12} sm={4} lg={3} xxl={2}  >
                        <MainCard>
                            <ImagePlaceholder
                                onClick={() => {
                                    setShowAddLocationModal(true);
                                }}
                                isAddIcon={true}
                                minHeight={375}
                                style={{ border: 'none!important' }}
                                boxDimensions={{ height: '100%', width: '100%' }}
                                addIconDimension={{ height: 50, width: 50 }}
                            />
                        </MainCard>
                    </Grid>
                )}
            </Grid>
            <AddLocationModel open={showAddLocationModal} handleClose={() => setShowAddLocationModal(false)} />
            {showLocationNameModel && (
                <AddLocationNameModel
                    clientEdit={true}
                    locationData={locationData}
                    open={showLocationNameModel}
                    handleClose={() => {
                        setShowLocationNameModel(false);
                        setLocationData('');
                        setAnchorEl(false);
                    }}
                />
            )}
            {showMirrorModal && (
                <MirrorModal
                    clientEdit={true}
                    locations={locations}
                    fetchLocations={() => dispatch(fetchLocations())}
                    locationData={locationData}
                    open={showMirrorModal}
                    handleClose={() => {
                        setShowMirrorModal(false);
                        setLocationData('');
                        setAnchorEl(false);
                    }}
                />
            )}
            <CommonPopover
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
            >
                <Box sx={{ display: 'grid', padding: 1, fontSize: 14 }}>
                    <ActionText onClick={() => setShowLocationNameModel(true)}>Rename</ActionText>
                    <ActionText
                        sx={{ pt: 1, opacity: checkForActiveSetMirror() ? 1 : 0.3 }}
                        onClick={() => checkForActiveSetMirror() && setShowMirrorModal(true)}
                    >
                        {locationData?.mirror_of_data ? 'Edit Mirror' : 'Set as Mirror'}
                    </ActionText>
                    <div style={{ opacity: loadingRefresh ? 0.3 : 1 }}>
                        <ActionText
                            onClick={() => {
                                if (!loadingRefresh) {
                                    refreshLocation();
                                }
                            }}
                            sx={{ pt: 1 }}
                        >
                            Refresh
                            {loadingRefresh && <CircularProgress sx={{ ml: 1 }} color="primary" size={16} />}
                        </ActionText>
                    </div>
                    <ActionText onClick={() => rebootLocation()} sx={{ pt: 1 }}>
                        Reboot
                    </ActionText>
                </Box>
            </CommonPopover>
        </Box>
    );
};
