import { Box, FormHelperText, Grid, styled, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CommonHeader } from '../../../widgets/CommonHeader';
import Loader from '../../../components/Loader';
import FieldLabelView from '../../../components/FieldLabelView';
import { CommonInput } from '../../../components/CommonInput';
import { COLORS } from '../../../utils/theme';
import { useFormik } from 'formik';
import { isAddedInterationApp, sortCategoryList } from '../../../utils/helpers';
import { useSnackbar } from 'notistack';
import { CommonMultiSelect } from '../../../components/CommonMultiSelect';
import useFetch from '../../../hooks/useFetch';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import ImagePlaceholder from '../../../widgets/ImagePlaceholder';
import { appValidation, snappicValidation } from '../../../utils/validations';
import { api } from '../../../api';
import { CONTENT_DATA_TYPE, CONTENT_MODULE_TYPE, SERVER_URL } from '../../../utils/constants';
import TagsInput from '../../../components/TagsInput';
import { isEqual } from 'lodash';
import { AlertDialog } from '../../../widgets/CommonWidgets';

const FormBox = styled(Box)({
    width: '100%',
    padding: 22,
    backgroundColor: COLORS.white,
    marginTop: 24,
    borderRadius: '0.425rem',
    border: `1px solid ${COLORS.border}`
});
const StyledInput = styled(CommonInput)({
    flex: 1
});

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 20
});
const FieldLabel = styled(Typography)({
    fontSize: 14,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 4,
    fontWeight: 600
});

const StartAdormentLable = styled(Typography)({
    marginRight: '4px'
});

export const EditApps = (props) => {
    const [loading, setLoading] = useState(false);
    const [showDeletePopup, setDeletePopup] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { data } = useFetch({
        initialUrl: '/get_all_category'
    });
    const categoryList = data?.result && sortCategoryList(data?.result);
    const { data: editData, isLoading } = useFetch({
        initialUrl: `/get_media_by_id/?id=${id}`,
        skipOnStart: id ? false : true
    });
    const mediaData = useMemo(() => {
        return editData?.result || [];
    }, [editData]);

    const handleDelete = async () => {
        try {
            const { data } = await api.mediaContent.deleteIntegrationsApp(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const { handleBlur, handleChange, values, touched, errors, setFieldValue, handleSubmit, setValues } = useFormik({
        initialValues: {
            name: 'Add App',
            category_id: '',
            landscape_thumbnail: '',
            portrait_thumbnail: '',
            landscape_thumbnail_file: '',
            portrait_thumbnail_file: '',
            duration_increments: ['10'],
            max_posts: null,
            default_duration: '',
            status: 1,
            description: '',
            authorization_message: '',
            authorization_image: null,
            authorization_image_file: '',
            unSavedChange: false,
            is_default_and_authorizable: 0,
            add_on_feature: 1,
            monthly_cost: null,
            embedded_link: 0,
            url: ''
        },
        validationSchema: isAddedInterationApp(mediaData?.name_alias) ? snappicValidation : appValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                if (values.name) {
                    formData.append('name', values.name);
                }
                formData.append('category_id', values.category_id);
                if (values.landscape_thumbnail_file) {
                    formData.append('landscape_thumbnail', values.landscape_thumbnail_file);
                }
                if (values.max_posts && !isAddedInterationApp(mediaData?.name_alias)) {
                    formData.append('max_posts', values.max_posts);
                }
                if (values.portrait_thumbnail_file) {
                    formData.append('portrait_thumbnail', values.portrait_thumbnail_file);
                }
                if (values.authorization_image_file) {
                    formData.append('authorization_image', values.authorization_image_file);
                }

                formData.append('description', values.description);
                formData.append('authorization_message', values.authorization_message);
                formData.append('status', Number(values.status));
                formData.append('module_type', CONTENT_MODULE_TYPE.SOCIAL);
                formData.append('content_type', CONTENT_DATA_TYPE.HTML);
                formData.append('default_duration', +values.default_duration);

                if (!isEqual(values.duration_increments, mediaData.duration_increments)) {
                    formData.append('duration_increments', JSON.stringify(values.duration_increments));
                }
                if (values?.name_alias) {
                    formData.append('name_alias', JSON.stringify(values.name_alias));
                }
                if (values.monthly_cost && isAddedInterationApp(mediaData?.name_alias)) {
                    formData.append('monthly_cost', Boolean(values?.add_on_feature) ? values?.monthly_cost : 0);
                }

                if (isAddedInterationApp(mediaData?.name_alias)) {
                    formData.append('add_on_feature', values?.add_on_feature);
                    formData.append('embedded_link', values?.embedded_link);
                }

                if (Boolean(values?.embedded_link)) {
                    formData.append('url', values.url);
                }

                let result;
                if (id) {
                    formData.append('id', id);
                    const { data } = await api.mediaContent.edit(formData);
                    setLoading(false);
                    result = data;
                } else {
                    const { data } = await api.mediaContent.addIntegrationsApp(formData);
                    setLoading(false);
                    result = data;
                }

                if (result.success) {
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });
    useEffect(() => {
        if (mediaData && id) {
            setValues({
                ...values,
                category_id: mediaData?.category_id,
                landscape_thumbnail: mediaData?.landscape_thumbnail && SERVER_URL + mediaData?.landscape_thumbnail,
                portrait_thumbnail: mediaData?.portrait_thumbnail && SERVER_URL + mediaData?.portrait_thumbnail,
                duration_increments: mediaData?.duration_increments,
                default_duration: mediaData?.default_duration?.toString(),
                status: mediaData?.status,
                authorization_message: mediaData?.authorization_message,
                authorization_image: mediaData?.authorization_image && SERVER_URL + mediaData?.authorization_image,
                name: mediaData?.name,
                description: mediaData?.description,
                max_posts: mediaData?.max_posts,
                is_default_and_authorizable: mediaData?.is_default_and_authorizable,
                add_on_feature: mediaData?.add_on_feature,
                monthly_cost: mediaData?.monthly_cost ? mediaData?.monthly_cost : null,
                embedded_link: mediaData?.embedded_link ? 1 : 0,
                url: mediaData?.url ? mediaData?.url : ''
            });
        }
    }, [mediaData, setValues]);
    const checkUnsaveChanges = () => {
        id && !values.unSavedChange && setFieldValue('unSavedChange', true);
    };
    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };
    const durationList = useMemo(() => {
        if (values.duration_increments?.length) {
            const updatedArray =
                values.duration_increments?.length &&
                values.duration_increments?.map((item, index) => {
                    return { id: index, value: item, name: item };
                });
            return updatedArray;
        } else {
            setFieldValue('default_duration', '');
            return [];
        }
    }, [values.duration_increments]);
    useEffect(() => {
        if (
            !isEqual(mediaData.duration_increments, values.duration_increments) &&
            !values.duration_increments?.includes(values?.default_duration)
        ) {
            setFieldValue('default_duration', '');
        }
    }, [values.duration_increments]);

    return (
        <Box>
            <CommonHeader
                title={values.name}
                buttonLabel={'Save'}
                backTo={'Integrations'}
                breadcrumbText={id ? 'Edit' : 'Add'}
                unsavedChanges={values.unSavedChange}
                onClick={handleSubmit}
                inEdit={id ? true : false}
                onClose={() => navigate(-1)}
                loading={loading}
                editTitleNameFlag={true}
                editTitleName="name"
                handleBlurTitle={handleBlur}
                handleChangeTitle={checkHandlechange}
                touched={touched?.name}
                errors={errors?.name}
                onDeleteIconVisible={Boolean(values.is_default_and_authorizable)}
                onDelete={() => setDeletePopup(true)}
            />
            {isLoading && <Loader isLoading={isLoading} />}

            {!isLoading && (
                <FormBox>
                    <Grid container spacing={4}>
                        <Grid item sm={7} xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12} md={4}>
                                    <FieldLabelView label={'Category'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={checkHandlechange}
                                            options={categoryList}
                                            error={touched.category_id && errors.category_id}
                                            name={'category_id'}
                                            value={values.category_id}
                                        />
                                    </FieldLabelView>
                                </Grid>
                                <Grid item sm={12} xs={12} md={4}>
                                    <FieldLabelView label={'Duration Increments (seconds)'}>
                                        <TagsInput
                                            setFieldValue={setFieldValue}
                                            onBlur={handleBlur}
                                            error={touched.duration_increments && errors.duration_increments}
                                            name={'duration_increments'}
                                            values={values.duration_increments}
                                        />
                                        <Typography sx={{ fontSize: 12 }}>Seconds can be separate by Comma(,), If more then one</Typography>
                                        {touched.duration_increments && errors.duration_increments && (
                                            <FormHelperText error>{errors.duration_increments}</FormHelperText>
                                        )}
                                    </FieldLabelView>
                                </Grid>
                                <Grid item sm={12} xs={12} md={4}>
                                    <FieldLabelView label={'Default Duration (seconds)'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={checkHandlechange}
                                            options={durationList}
                                            error={touched.default_duration && errors.default_duration}
                                            name={'default_duration'}
                                            value={values.default_duration}
                                        />
                                    </FieldLabelView>
                                    {!isAddedInterationApp(mediaData?.name_alias) && (
                                        <FieldLabelView sx={{ mt: 1 }} label={'Default Max Posts'}>
                                            <StyledInput
                                                sx={{ width: 100 }}
                                                type="number"
                                                handleBlur={handleBlur}
                                                handleChange={checkHandlechange}
                                                error={touched.max_posts && errors.max_posts}
                                                name={'max_posts'}
                                                value={values.max_posts}
                                            />
                                        </FieldLabelView>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid item sm={12} xs={12} md={12} sx={{ mt: 1 }}>
                                <FieldLabelView label={'Description'}>
                                    <StyledInput
                                        style={{ height: 'auto' }}
                                        handleChange={checkHandlechange}
                                        handleBlur={handleBlur}
                                        error={touched.description && errors.description}
                                        name={'description'}
                                        value={values.description}
                                        multiline={true}
                                        minRows={3}
                                    />
                                </FieldLabelView>
                                <FieldLabelView
                                    label={!isAddedInterationApp(mediaData?.name_alias) ? 'Authorization Message' : 'Activation Message'}
                                    sx={{ mt: 2 }}
                                >
                                    <StyledInput
                                        style={{ height: 'auto' }}
                                        handleChange={checkHandlechange}
                                        handleBlur={handleBlur}
                                        error={touched.authorization_message && errors.authorization_message}
                                        name={'authorization_message'}
                                        value={values.authorization_message}
                                        multiline={true}
                                        minRows={3}
                                    />
                                </FieldLabelView>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} container sx={{ mt: 1 }}>
                                <Grid item xs={12} md={isAddedInterationApp(mediaData?.name_alias) ? 6 : 12}>
                                    <FieldView>
                                        <FieldLabel>Client Side Status:</FieldLabel>
                                        <IOSSwitch
                                            sx={{ m: 1 }}
                                            onChange={(e) => {
                                                setFieldValue('status', e.target.checked ? 1 : 0);
                                                checkUnsaveChanges();
                                            }}
                                            checked={values.status}
                                        />
                                    </FieldView>
                                </Grid>
                                {isAddedInterationApp(mediaData?.name_alias) ? (
                                    <Grid item container xs={12} sm={12} md={6}>
                                        <Grid item xs={12} sm={12} md={12} lg={6}>
                                            <FieldView>
                                                <FieldLabel>Add-On Feature:</FieldLabel>
                                                <IOSSwitch
                                                    sx={{ m: 1 }}
                                                    onChange={(e) => {
                                                        setFieldValue('add_on_feature', e.target.checked ? 1 : 0);
                                                        checkUnsaveChanges();
                                                    }}
                                                    checked={values.add_on_feature}
                                                />
                                            </FieldView>
                                        </Grid>
                                        {Boolean(values?.add_on_feature) && (
                                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                                <FieldView sx={{ alignItems: 'flex-start' }}>
                                                    <FieldLabel sx={{ marginTop: '12px' }}>Monthly Cost:</FieldLabel>
                                                    <StyledInput
                                                        sx={{ width: 100, ml: 1 }}
                                                        type="number"
                                                        handleBlur={handleBlur}
                                                        handleChange={checkHandlechange}
                                                        error={touched.monthly_cost && errors.monthly_cost}
                                                        name={'monthly_cost'}
                                                        value={values.monthly_cost}
                                                        startAdornment={<StartAdormentLable>$</StartAdormentLable>}
                                                    />
                                                </FieldView>
                                            </Grid>
                                        )}
                                    </Grid>
                                ) : null}
                            </Grid>
                            {isAddedInterationApp(mediaData?.name_alias) ? (
                                <Grid item xs={12} container sx={{ mt: 1 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={3} xl={2}>
                                        <FieldView>
                                            <FieldLabel>Embedded Link:</FieldLabel>
                                            <IOSSwitch
                                                sx={{ m: 1 }}
                                                onChange={(e) => {
                                                    setFieldValue('embedded_link', e.target.checked ? 1 : 0);
                                                    checkUnsaveChanges();
                                                }}
                                                checked={Boolean(values.embedded_link)}
                                            />
                                        </FieldView>
                                    </Grid>
                                    {Boolean(values?.embedded_link) && (
                                        <Grid item xs={12} sm={12} md={12} lg={9} xl={10}>
                                            <FieldView>
                                                <StyledInput
                                                    sx={{ ml: 1 }}
                                                    handleBlur={handleBlur}
                                                    handleChange={checkHandlechange}
                                                    error={touched.url && errors.url}
                                                    name={'url'}
                                                    value={values.url}
                                                />
                                            </FieldView>
                                        </Grid>
                                    )}
                                </Grid>
                            ) : null}
                        </Grid>
                        <Grid item sm={5} xs={12} md={3}>
                            <FieldLabelView label={'Thumbnails'}>
                                <Grid style={{ width: '100%' }} container spacing={1}>
                                    <Grid item sm={9} xs={9} md={9}>
                                        <ImagePlaceholder
                                            boxDimensions={{ height: 132, width: '100%', objectFit: 'contain' }}
                                            handleChange={(event) => {
                                                checkUnsaveChanges();
                                                setFieldValue('landscape_thumbnail_file', event.target.files[0]);
                                                setFieldValue('landscape_thumbnail', URL.createObjectURL(event.target.files[0]));
                                            }}
                                            image={values.landscape_thumbnail}
                                            isAddIcon={values.landscape_thumbnail ? false : true}
                                        />
                                        {touched.landscape_thumbnail && errors.landscape_thumbnail && (
                                            <FormHelperText error>{errors.landscape_thumbnail}</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sm={3} xs={3} md={3}>
                                        <ImagePlaceholder
                                            handleChange={(event) => {
                                                checkUnsaveChanges();
                                                setFieldValue('portrait_thumbnail_file', event.target.files[0]);
                                                setFieldValue('portrait_thumbnail', URL.createObjectURL(event.target.files[0]));
                                            }}
                                            image={values.portrait_thumbnail}
                                            isAddIcon={values.portrait_thumbnail ? false : true}
                                            boxDimensions={{ height: 132, width: '100%', objectFit: 'contain' }}
                                        />
                                        {touched.portrait_thumbnail && errors.portrait_thumbnail && (
                                            <FormHelperText error>{errors.portrait_thumbnail}</FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </FieldLabelView>
                            <FieldLabelView
                                label={!isAddedInterationApp(mediaData?.name_alias) ? 'Authorization Image' : 'Image'}
                                sx={{ mt: 2 }}
                            >
                                <Grid item sm={8} xs={8} md={8}>
                                    <ImagePlaceholder
                                        handleChange={(event) => {
                                            checkUnsaveChanges();
                                            setFieldValue('authorization_image_file', event.target.files[0]);
                                            setFieldValue('authorization_image', URL.createObjectURL(event.target.files[0]));
                                        }}
                                        image={values.authorization_image}
                                        isAddIcon={values.authorization_image ? false : true}
                                        boxDimensions={{ height: 132, width: '100%' }}
                                    />
                                    {touched.authorization_image && errors.authorization_image && (
                                        <FormHelperText error>{errors.authorization_image}</FormHelperText>
                                    )}
                                </Grid>
                            </FieldLabelView>
                        </Grid>
                    </Grid>
                </FormBox>
            )}
            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={handleDelete}
                    title={'Delete Integrations app'}
                    description={'Are you sure you want to delete this Integrations app ?'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
