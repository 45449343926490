import { styled, Typography, Box } from '@mui/material';
import {
    ASPECT_RATIO,
    CONTENT_DATA_TYPE,
    CONTENT_MODULE_TYPE,
    ORIENTATION,
    ORIENTATION_DATA_KEY,
    SERVER_URL
} from '../../../../utils/constants';

import { COLORS } from '../../../../utils/theme';

const MainBox = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    position: 'relative',
    padding: 12
});

const ContentItemMain = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
});

const ContentLeftView = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const ContentRightView = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

const ContentImageBox = styled(Box)({
    cursor: 'pointer'
});

const ContentImag = styled('img')({
    height: '100%',
    width: '100%',
    objectFit: 'cover'
});

const ContentNameText = styled(Typography)({
    fontSize: 16,
    color: COLORS.black
});

const ContentDurationText = styled(Typography)({
    fontSize: 12,
    color: COLORS.black
});

export const AutoContents = ({ contents, onClick, orientation }) => {
    return (
        <MainBox>
            {contents?.map((item) => {
                return (
                    <ContentItemMain sx={{ mb: 2 }} key={item.id}>
                        <ContentLeftView>
                            <ContentImageBox
                                style={{ width: orientation === ORIENTATION.LANDSCAPE ? 90 : 40, aspectRatio: ASPECT_RATIO[orientation] }}
                                onClick={() => onClick(item)}
                            >
                                <ContentImag
                                    src={
                                        item?.content_type === CONTENT_DATA_TYPE.IMAGE
                                            ? item?.[ORIENTATION_DATA_KEY?.video?.[orientation]]
                                            : `${SERVER_URL}${
                                                  item?.[ORIENTATION_DATA_KEY?.thumbnail?.[orientation]] || item?.landscape_thumbnail
                                              }`
                                    }
                                    sx={item?.module_type === CONTENT_MODULE_TYPE.SOCIAL && { objectFit: 'contain' }}
                                />
                            </ContentImageBox>
                            <ContentNameText sx={{ ml: 1 }}>{item.name}</ContentNameText>
                        </ContentLeftView>
                        <ContentRightView>
                            <ContentDurationText>{`Duration ${item.duration}`}</ContentDurationText>
                        </ContentRightView>
                    </ContentItemMain>
                );
            })}
        </MainBox>
    );
};
