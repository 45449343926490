import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import clientLocationSlice from './clientLocationSlice';
import clientsSlice from './clientsSlice';
import layoutSlice from './layoutSlice';
import backgroundSlice from './backgroundSlice';
import userSlice from './userSlice';
import categorySlice from './categorySlice';
import collectionSlice from './collectionSlice';
import videoSlice from './videoSlice';
import groupSlice from './groupSlice';
import tagsSlice from './tagsSlice';
import librarySlice from './librarySlice';
import loopSlice from './loopSlice';
import commonSlice from './commonSlice';
import orientationSlice from './orientationSlice';
import contentSlice from './contentSlice';
import socialMediaSlice from './socialMediaSlice';
import requestSlice from './requestSlice';
import requestCategorySlice from './requestCategorySlice';
import editorActionSlice from './editorSlice';
import locationsSlice from './locationsSlice';
import feedbackSlice from './feedbackSlice';
import versionSlice from './versionSlice';
import browseFilterSlice from './browseFilterSlice';
import accountTypesSlice from './accountTypesSlice';
import curationProfilesSlice from './curationProfiles';

export const reducers = combineReducers({
    auth: authSlice,
    clientLocation: clientLocationSlice,
    locations: locationsSlice,
    client: clientsSlice,
    user: userSlice,
    layout: layoutSlice,
    category: categorySlice,
    background: backgroundSlice,
    collection: collectionSlice,
    video: videoSlice,
    group: groupSlice,
    tag: tagsSlice,
    library: librarySlice,
    loop: loopSlice,
    common: commonSlice,
    orientation: orientationSlice,
    content: contentSlice,
    socialMedia: socialMediaSlice,
    request: requestSlice,
    requestCategory: requestCategorySlice,
    editor: editorActionSlice,
    feedback: feedbackSlice,
    version: versionSlice,
    browseFilter: browseFilterSlice,
    accountTypes: accountTypesSlice,
    curationProfiles: curationProfilesSlice
});
