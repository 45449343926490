import React, { useState } from 'react';
import { Box, Popover, styled, Typography } from '@mui/material';
import { CustomColorPicker } from '../../../../CustomColorPicker';
import { BACKGROUND_ACTION_TYPE, GRADIENT_TYPE } from '../../../../../utils/constants';
import { useSelector } from 'react-redux';

const MainBox = styled(Box)({
    width: 300,
    padding: 12
});

const ColorBox = styled(Box)({
    width: '100%',
    marginTop: 16
});

const Title = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '1.25rem',
    textAlign: 'center'
});

const ColorsBox = styled(Box)({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
});

const GradientActionBox = styled(Box)({
    width: 32,
    height: 32,
    borderRadius: 4,
    margin: '8px',
    cursor: 'pointer',
    backgroundImage: 'linear-gradient(0deg, white, black)'
});

const gradient = {
    linear: '0deg',
    horizontal: '90deg',
    diagonal: '45deg'
};

const gradientMixer = (type) => {
    if (type !== 'radial') {
        return `linear-gradient(${gradient[type]}, black, white)`;
    } else return 'radial-gradient(white, black)';
};

export const BackgroundGradientColorPopup = ({ id, open, anchorEl, handleClose, handleSelectBackgrounds, ...props }) => {

    const [selected, setSelected] = useState('vertical')
    const { firstGradientColor, secondGradientColor, gradient } = useSelector((state) => state?.editor)

    const handleGradientCoords = ({ name, value }) => {
        name === 'gradient' && setSelected(value)
        handleSelectBackgrounds({
            type: BACKGROUND_ACTION_TYPE.GRADIENT_COLOR,
            firstGradientColor, secondGradientColor, gradient, [name]: value
        })
    }

    return (
        <Popover
            {...props}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            <MainBox>
                <Title>Gradient Background</Title>
                <ColorBox>
                    <Typography sx={{ fontWeight: 'bold', ml: 1 }}>Gradient Colors</Typography>
                    <ColorsBox>
                        <CustomColorPicker currentcolor={firstGradientColor}
                            handleColorChange={(e) => handleGradientCoords({ name: 'firstGradientColor', value: e.hex })}
                            sx={{ padding: 0, minWidth: 'unset', width: 48, height: 48 }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        />
                        <CustomColorPicker currentcolor={secondGradientColor}
                            handleColorChange={(e) => handleGradientCoords({ name: 'secondGradientColor', value: e.hex })}
                            sx={{ padding: 0, minWidth: 'unset', width: 48, height: 48 }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left'
                            }}
                        />
                    </ColorsBox>
                </ColorBox>
                <ColorBox>
                    <Typography sx={{ fontWeight: 'bold', ml: 1 }}>Gradient Style</Typography>
                    <ColorsBox>
                        {GRADIENT_TYPE.map((type) => (
                            <GradientActionBox onClick={() => handleGradientCoords({ name: 'gradient', value: type })} key={type} sx={{ background: gradientMixer(type), border: type === selected ? '3px solid #30aed0' : '3px solid black' }} />
                        ))}
                    </ColorsBox>
                </ColorBox>
            </MainBox>
        </Popover>
    );
};
