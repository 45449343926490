import { Box, FormHelperText, Grid, styled, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { CommonInput } from '../../../components/CommonInput';
import { COLORS } from '../../../utils/theme';
import { CommonMultiSelect } from '../../../components/CommonMultiSelect';
import { IOSSwitch } from '../../../widgets/IOSSwitch';
import ImagePlaceholder from '../../../widgets/ImagePlaceholder';
import { ICONS } from '../../../assets';
import { CollectionCardWidgets } from '../../../widgets/CollectionCardWidgets';
import useFetch from '../../../hooks/useFetch';
import { useEffect, useState } from 'react';
import { SERVER_URL } from '../../../utils/constants';
import { api } from '../../../api';
import { AlertDialog } from '../../../widgets/CommonWidgets';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { groupValidation } from '../../../utils/validations';
import Loader from '../../../components/Loader';
import FieldLabelView from '../../../components/FieldLabelView';
import NoDataFound from '../../../components/NoDataFound';
import { splitTrimStringifyTags } from '../../../utils/helpers';
import { fetchAccountTypeSpecialty, fetchAccountTypeTerritory } from '../../../redux/reducers/accountTypesSlice';

const sortCategoryList = (data) => {
    let sortData = data.map((item) => {
        return { id: item.id, name: item.name, value: item.id };
    });

    return sortData;
};

const CollectionFormBox = styled(Box)({
    width: '100%',
    padding: 22,
    backgroundColor: COLORS.white,
    marginTop: 24,
    borderRadius: '0.425rem',
    border: `1px solid ${COLORS.border}`
});

const CollectionVideoGrid = styled(Grid)({
    paddingTop: 24
});

const FieldLabel = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: COLORS.black,
    marginBottom: 2,
    marginTop: 4,
    fontWeight: 600
}));

const StyledInput = styled(CommonInput)(({ theme }) => ({
    flex: 1
}));

const FieldView = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: 20
});

export const AddGroup = () => {
    const [showDeletePopup, setDeletePopup] = useState(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const isInEdit = Boolean(id);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { territory, Specialty } = useSelector(({ accountTypes }) => accountTypes);

    const { data: groupData, isLoading } = useFetch({
        initialUrl: `/get_group_by_id`,
        config: { params: { id } },
        skipOnStart: id ? false : true
    });

    const editData = groupData?.result;

    const { data: categoryData } = useFetch({
        initialUrl: '/get_all_category'
    });

    const { data: mediaData, callFetch: fetchMediaByCategory } = useFetch({
        initialUrl: `/get_all_media`,
        skipOnStart: true
    });
    const categoryList = sortCategoryList(categoryData?.result || []);

    const { handleBlur, handleChange, values, touched, errors, setFieldValue, handleSubmit, setValues, setTouched } = useFormik({
        initialValues: {
            name: '',
            category_id: '',
            landscape_thumbnail: null,
            portrait_thumbnail: null,
            landscape_thumbnail_file: null,
            portrait_thumbnail_file: null,
            tags: '',
            contents: [],
            description: '',
            status: true,
            search: '',
            unSavedChange: false,
            territory_status: false,
            territory_id: [],
            speciality_status: false,
            speciality_id: []
        },
        validationSchema: groupValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const formData = new FormData();
                formData.append('name', values.name);
                formData.append('category_id', values.category_id);
                if (values.landscape_thumbnail_file) {
                    formData.append('landscape_thumbnail', values.landscape_thumbnail_file);
                }
                if (values.portrait_thumbnail_file) {
                    formData.append('portrait_thumbnail', values.portrait_thumbnail_file);
                }
                formData.append('description', values.description);
                formData.append('status', Number(values.status));
                formData.append('tags', splitTrimStringifyTags(values.tags));
                formData.append('contents', JSON.stringify(values.contents));
                if (values.territory_status) {
                    formData.append('territory_id', JSON.stringify(values.territory_id));
                }
                if (values.speciality_status) {
                    formData.append('speciality_id', JSON.stringify(values.speciality_id));
                }

                let result;
                if (isInEdit) {
                    formData.append('id', id);
                    const { data } = await api.group.edit(formData);
                    result = data;
                } else {
                    const { data } = await api.group.add(formData);
                    result = data;
                }
                setLoading(false);

                if (result.success) {
                    enqueueSnackbar(result.message, { variant: 'success' });
                    navigate(-1);
                } else {
                    enqueueSnackbar(result.message, { variant: 'error' });
                }
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    useEffect(() => {
        if (editData && isInEdit) {
            setValues({
                name: editData.name,
                category_id: editData.category_id,
                description: editData.description,
                landscape_thumbnail: SERVER_URL + editData.landscape_thumbnail,
                portrait_thumbnail: SERVER_URL + editData.portrait_thumbnail,
                status: Boolean(editData.status),
                contents: editData.contents.map((item) => item.id),
                tags: editData.tags.map((item) => item?.tag_names?.name).join(','),
                territory_id: editData?.account_type_terriotary_accesses?.length
                    ? editData?.account_type_terriotary_accesses?.map((item) => item?.territory_id)
                    : [],
                speciality_id: editData?.account_type_speciality_accesses?.length
                    ? editData?.account_type_speciality_accesses?.map((item) => item?.speciality_id)
                    : [],
                territory_status: editData?.account_type_terriotary_accesses?.length ? true : false,
                speciality_status: editData?.account_type_speciality_accesses?.length ? true : false
            });
        }
    }, [editData, isInEdit, setValues]);

    useEffect(() => {
        if (values.category_id) {
            let params = {
                category_id: values.category_id,
                collection: 1,
                video: 1,
                search: values.search,
                territory_id: JSON.stringify(values.territory_id),
                speciality_id: JSON.stringify(values.speciality_id),
                media_by_admin: true
            };
            (!values.territory_status || !values.territory_id?.length) && delete params['territory_id'];
            (!values.speciality_status || !values.speciality_id?.length) && delete params['speciality_id'];
            fetchMediaByCategory({ params });
        }
    }, [values.category_id, fetchMediaByCategory, values.search, values.territory_id, values.speciality_id]);

    const handleToggleMedia = (item) => {
        checkUnsaveChanges();
        const index = values.contents.findIndex((e) => e === item.id);
        let currentVideos = [...values.contents];
        if (index === -1) {
            currentVideos.push(item.id);
        } else {
            currentVideos.splice(index, 1);
        }
        setFieldValue('contents', currentVideos);
    };

    const handleDelete = async () => {
        try {
            const { data } = await api.group.delete(id);
            if (data.success) {
                enqueueSnackbar(data.message, { variant: 'success' });
                navigate(-1);
            }
        } catch (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };
    const checkUnsaveChanges = () => {
        id && !values.unSavedChange && setFieldValue('unSavedChange', true);
    };

    const checkHandlechange = (data) => {
        checkUnsaveChanges();
        handleChange(data);
    };

    useEffect(() => {
        dispatch(fetchAccountTypeTerritory());
        dispatch(fetchAccountTypeSpecialty());
    }, [dispatch]);

    return (
        <Box>
            <CommonHeader
                title={'Group'}
                buttonLabel={'Save'}
                backTo={'Groups'}
                breadcrumbText={id ? 'Edit' : 'Add'}
                unsavedChanges={values.unSavedChange}
                onClick={handleSubmit}
                inEdit={id ? true : false}
                onClose={() => navigate(-1)}
                onDelete={() => setDeletePopup(true)}
                loading={loading}
                isXSset6={true}
            />
            {isLoading && <Loader isLoading={isLoading} />}

            {!isLoading && (
                <CollectionFormBox>
                    <Grid container spacing={4}>
                        <Grid item sm={7} xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item sm={12} xs={12} md={12}>
                                    <FieldLabelView label={'Name'}>
                                        <StyledInput
                                            handleChange={checkHandlechange}
                                            handleBlur={handleBlur}
                                            error={touched.name && errors.name}
                                            name={'name'}
                                            value={values.name}
                                        />
                                    </FieldLabelView>
                                </Grid>
                                <Grid item sm={12} xs={12} md={6} lg={6} xl={4}>
                                    <FieldLabelView label={'Category'}>
                                        <CommonMultiSelect
                                            handleBlur={handleBlur}
                                            handleChange={checkHandlechange}
                                            options={categoryList}
                                            error={touched.category_id && errors.category_id}
                                            name={'category_id'}
                                            value={values.category_id}
                                        />
                                    </FieldLabelView>
                                </Grid>

                                <Grid item sm={12} xs={12} md={12} container>
                                    <Grid container spacing={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Grid item sm={12} xs={12} md={6} lg={6} xl={4}>
                                            <FieldView sx={{ justifyContent: 'start' }} mt={1}>
                                                <FieldLabel>Territory:</FieldLabel>
                                                <IOSSwitch
                                                    sx={{ mx: 1 }}
                                                    checked={values?.territory_status}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setFieldValue('territory_id', []);
                                                            setTouched({ ...touched, ['territory_id']: false });
                                                        }
                                                        checkUnsaveChanges();
                                                        setFieldValue('territory_status', e.target.checked);
                                                    }}
                                                />
                                            </FieldView>
                                            {values?.territory_status && (
                                                <FieldLabelView label={''}>
                                                    <CommonMultiSelect
                                                        name={'territory_id'}
                                                        error={touched.territory_id && errors.territory_id}
                                                        value={values.territory_id}
                                                        handleChange={checkHandlechange}
                                                        handleBlur={handleBlur}
                                                        options={territory?.map((cv) => {
                                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                                        })}
                                                        multiple={true}
                                                        style={{ height: 'auto' }}
                                                        searchableMultiselect={false}
                                                        checkbox={true}
                                                    />
                                                </FieldLabelView>
                                            )}
                                        </Grid>
                                        <Grid item sm={12} xs={12} md={6} lg={6} xl={4}>
                                            <FieldView sx={{ justifyContent: 'start' }} mt={1}>
                                                <FieldLabel>Specialty:</FieldLabel>
                                                <IOSSwitch
                                                    sx={{ mx: 1 }}
                                                    checked={values?.speciality_status}
                                                    onChange={(e) => {
                                                        if (!e.target.checked) {
                                                            setFieldValue('speciality_id', []);
                                                            setTouched({ ...touched, ['speciality_id']: false });
                                                        }
                                                        checkUnsaveChanges();
                                                        setFieldValue('speciality_status', e.target.checked);
                                                    }}
                                                />
                                            </FieldView>
                                            {values?.speciality_status && (
                                                <FieldLabelView label={''}>
                                                    <CommonMultiSelect
                                                        name={'speciality_id'}
                                                        error={touched.speciality_id && errors.speciality_id}
                                                        value={values.speciality_id}
                                                        handleChange={checkHandlechange}
                                                        handleBlur={handleBlur}
                                                        options={Specialty?.map((cv) => {
                                                            return { id: cv?.id, name: cv?.name, value: cv?.id };
                                                        })}
                                                        multiple={true}
                                                        style={{ height: 'auto' }}
                                                        searchableMultiselect={false}
                                                        checkbox={true}
                                                    />
                                                </FieldLabelView>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sm={12} xs={12} md={12}>
                                    <FieldLabelView label={'Tags'}>
                                        <StyledInput
                                            style={{ height: 'auto' }}
                                            handleChange={checkHandlechange}
                                            handleBlur={handleBlur}
                                            error={touched.tags && errors.tags}
                                            name={'tags'}
                                            value={values.tags}
                                            multiline={true}
                                            minRows={4}
                                        />
                                        <Typography sx={{ fontSize: 14 }}>Separate tags with a comma.</Typography>
                                    </FieldLabelView>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={5} xs={12} md={3}>
                            <FieldLabelView label={'Thumbnails'}>
                                <Grid style={{ width: '100%' }} container spacing={1}>
                                    <Grid item sm={9} xs={9} md={9}>
                                        <ImagePlaceholder
                                            boxDimensions={{ height: 132, width: '100%' }}
                                            handleChange={(event) => {
                                                checkUnsaveChanges();
                                                setFieldValue('landscape_thumbnail', URL.createObjectURL(event.target.files[0]));
                                                setFieldValue('landscape_thumbnail_file', event.target.files[0]);
                                            }}
                                            image={values.landscape_thumbnail}
                                            isAddIcon={values.landscape_thumbnail ? false : true}
                                        />
                                        {touched.landscape_thumbnail && errors.landscape_thumbnail && (
                                            <FormHelperText error>{errors.landscape_thumbnail}</FormHelperText>
                                        )}
                                    </Grid>
                                    <Grid item sm={3} xs={3} md={3}>
                                        <ImagePlaceholder
                                            boxDimensions={{ height: 132, width: '100%' }}
                                            handleChange={(event) => {
                                                checkUnsaveChanges();
                                                setFieldValue('portrait_thumbnail', URL.createObjectURL(event.target.files[0]));
                                                setFieldValue('portrait_thumbnail_file', event.target.files[0]);
                                            }}
                                            image={values.portrait_thumbnail}
                                            isAddIcon={values.portrait_thumbnail ? false : true}
                                        />
                                        {touched.portrait_thumbnail && errors.portrait_thumbnail && (
                                            <FormHelperText error>{errors.portrait_thumbnail}</FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </FieldLabelView>
                        </Grid>

                        <Grid item sm={12} xs={12} md={12}>
                            <FieldLabelView label={'Description'}>
                                <StyledInput
                                    style={{ height: 'auto' }}
                                    handleChange={checkHandlechange}
                                    handleBlur={handleBlur}
                                    error={touched.description && errors.description}
                                    name={'description'}
                                    value={values.description}
                                    multiline={true}
                                    minRows={3}
                                />
                            </FieldLabelView>
                            <FieldView>
                                <FieldLabel>Client Side Status:</FieldLabel>
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    onChange={(e) => {
                                        setFieldValue('status', e.target.checked);
                                        checkUnsaveChanges();
                                    }}
                                    checked={values.status}
                                />
                            </FieldView>
                            <FieldLabelView label={'Selections'}>
                                <CommonInput
                                    name={'search'}
                                    label={'Search...'}
                                    endAdornment={<ICONS.Search />}
                                    type="text"
                                    value={values.search}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    sx={{ marginTop: 1 }}
                                />
                            </FieldLabelView>
                        </Grid>
                    </Grid>
                </CollectionFormBox>
            )}

            <CollectionVideoGrid container spacing={2}>
                {mediaData?.result && mediaData?.result?.length ? (
                    mediaData?.result.map((item) => {
                        return (
                            <Grid item key={item.id} xs={12} sm={6} md={4} lg={3} xl={2}>
                                <CollectionCardWidgets
                                    handleChangeSwitch={handleToggleMedia}
                                    value={values?.contents?.findIndex((e) => e === item.id) !== -1}
                                    data={item}
                                />
                            </Grid>
                        );
                    })
                ) : (
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={'center'} alignItems={'center'}>
                        {(!isLoading && values.category_id) || values.search ? (
                            <NoDataFound searchText={values.search} message={'No content found for selected category.'} />
                        ) : null}
                    </Grid>
                )}
            </CollectionVideoGrid>
            {showDeletePopup ? (
                <AlertDialog
                    isOpen={showDeletePopup}
                    isClose={() => setDeletePopup(false)}
                    handleSubmit={handleDelete}
                    title={'Delete Group'}
                    description={'Are you sure you want to delete this Group ?'}
                    isCancel={'No'}
                />
            ) : null}
        </Box>
    );
};
