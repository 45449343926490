import { Box, styled, Typography, Grid } from '@mui/material';
import { COLORS } from '../../../../utils/theme';

const MainBox = styled(Box)({
    padding: '16px 25px',
    width: '100%',
    backgroundColor: COLORS.white,
    alignItems: 'center',
    border: `1px solid ${COLORS.border}`,
    borderRadius: '.425rem',
    justifyContent: 'center'
});

const PageTitle = styled(Typography)({
    fontSize: 22,
    color: COLORS.black,
    fontWeight: '300'
});

const LeftContentView = styled(Grid)({
    display: 'flex',
    alignItems: 'center'
});

const MainGrid = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between'
});
export default function FeedbackHeader({ title, ...props }) {
    return (
        <MainBox {...props} >
            <MainGrid container>
                <LeftContentView item md={6} sx={{ marginBottom: { xs: '10px', sm: '10px', md: '0px', lg: '0px' } }}>
                    <PageTitle>{title}</PageTitle>
                </LeftContentView>
            </MainGrid>
        </MainBox>
    );
}
