import { Box, styled } from '@mui/material';
import React from 'react';
import { IMAGES } from '../../assets';

const ModalBox = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
});
const Image = styled('img')({
    height: 100,
    width: 100,
    alignSelf: 'center'
});

const Loader = ({ isLoading, sx }) => {
    return (
        <>
            {isLoading ? (
                <ModalBox>
                    <Image {...{sx}} src={IMAGES.Loader} />
                </ModalBox>
            ) : null}
        </>
    );
};

export default Loader;
