import React, { Fragment } from 'react';
import { CommonMultiSelect } from '../../CommonMultiSelect';
import { FONT_FAMILIES, SHAPE_ACTION_TYPE, TEXT_ACTION_TYPE } from '../../../utils/constants';
import { CustomColorPicker } from '../../CustomColorPicker';
import CommonIncreaseDecrease from '../../CommonIncreaseDecrease';
import { IMAGES } from '../../../assets';
import { Box, styled } from '@mui/material';
import OpacityAction from './OpacityAction';
import { useSelector } from 'react-redux';
import CustomTooltip from '../../../widgets/CustomTooltip';

const TEXT_ACTIONS_MENU = [
    {
        id: 'align-left',
        type: TEXT_ACTION_TYPE.ALIGNMENT,
        value: 'left',
        image: IMAGES.LeftAlignIcon,
        tooltip: 'Left alignment'
    },
    {
        id: 'align-center',
        type: TEXT_ACTION_TYPE.ALIGNMENT,
        value: 'center',
        image: IMAGES.CenterAlignIcon,
        tooltip: 'Middle alignment'
    },
    {
        id: 'align-right',
        type: TEXT_ACTION_TYPE.ALIGNMENT,
        value: 'right',
        image: IMAGES.RightAlignIcon,
        tooltip: 'Right alignment'
    }
];

const TextActionButton = styled('div')({
    borderRadius: 4,
    margin: '0 6px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px',
    cursor: 'pointer'
});

const ActionIconImage = styled('img')({
    height: 30,
    width: 30,
    objectFit: 'contain'
});

const ColorCodeBox = styled(Box)({
    width: 32,
    height: 32,
    cursor: 'pointer',
    border: '3px solid black',
    borderRadius: 4
});

const FontActions = ({ type, handleChangeTextAction, ...rest }) => {
    const { activeColor, opacity, activeTextAlignment, actionFontFamily } = useSelector((state) => state.editor);

    const handleFontSize = (value) => {
        const actionData = {
            type: TEXT_ACTION_TYPE.FONT_SIZE,
            value
        };
        handleChangeTextAction(actionData);
    };

    const handleFontColor = (e) => {
        const actionData = {
            type: TEXT_ACTION_TYPE.FONT_COLOR,
            value: e.hex
        };
        handleChangeTextAction(actionData);
    };

    const handleFontFamily = (e) => {
        const value = FONT_FAMILIES.find((val) => val.font === e.target.value);
        const actionData = {
            type: TEXT_ACTION_TYPE.FONT_FAMILY,
            value: value || {}
        };
        handleChangeTextAction(actionData);
    };

    return (
        <Fragment>
            <CommonMultiSelect isPrevent
                sx={{ mt: '4px' }}
                handleChange={handleFontFamily}
                options={FONT_FAMILIES?.map(({ font, ...other }) => {
                    return { id: font, name: font, value: font, ...other };
                })}
                name="fontFamily"
                placeholder="Font Family"
                style={{ margin: '2px 4px', height: 30 }}
                value={actionFontFamily}
                isApplyFontFamily
            />
            <CommonIncreaseDecrease withTooltip sx={{ ml: 1 }} handleClick={handleFontSize} />
            <CustomColorPicker isPrevent withTooltip
                component={<ColorCodeBox sx={{ background: activeColor }} />}
                currentcolor={activeColor}
                handleColorChange={handleFontColor}
            />
            <OpacityAction value={opacity} type={TEXT_ACTION_TYPE.OPACITY} {...{ handleChangeAction: handleChangeTextAction }} />
            {TEXT_ACTIONS_MENU?.map((item) => {
                return (
                    <TextActionButton
                        key={item.id}
                        sx={activeTextAlignment === item.value ? { background: 'gray' } : {}}
                        onClick={(e) => {
                            handleChangeTextAction(item)
                            e.stopPropagation()
                        }}
                        color="primary"
                        aria-label="upload picture"
                    >
                        {item.image ?
                            <CustomTooltip arrow title={item?.tooltip || ''} placement="bottom" >
                                <ActionIconImage src={item.image} />
                            </CustomTooltip>
                            : null}
                    </TextActionButton>
                );
            })}
        </Fragment>
    );
};

const ShapeActions = ({ type, handleChangeShapesAction, ...rest }) => {
    const { activeColor, opacity } = useSelector((state) => state.editor);

    const handleShapeColor = (e) => {
        const actionData = {
            type: SHAPE_ACTION_TYPE.SHAPE_COLOR,
            value: e.hex
        };
        handleChangeShapesAction(actionData);
    };

    const handleShapeSize = (value) => {
        const actionData = {
            type: SHAPE_ACTION_TYPE.SHAPE_SELECTOR,
            value
        };
        handleChangeShapesAction(actionData);
    };

    return (
        <Fragment>
            <CommonIncreaseDecrease withTooltip sx={{ ml: 1 }} mode={SHAPE_ACTION_TYPE.SHAPE_SELECTOR} handleClick={handleShapeSize} />
            <CustomColorPicker withTooltip isPrevent
                component={<ColorCodeBox sx={{ background: activeColor }} />}
                currentcolor={activeColor}
                handleColorChange={handleShapeColor}
            />
            <OpacityAction type={SHAPE_ACTION_TYPE.OPACITY} value={opacity} {...{ handleChangeAction: handleChangeShapesAction }} />
        </Fragment>
    );
};

const ApperianceProvider = ({ type, ...rest }) => {
    switch (type) {
        case 'textbox':
        case 'text':
            return <FontActions {...{ type }} {...rest} />;
        case 'rect':
        case 'triangle':
        case 'circle':
        case 'image':
            return <ShapeActions {...{ type }} {...rest} />;
        default:
            <></>;
    }
};

export default ApperianceProvider;
