import client, { clientWithHeaders, METHODS } from './client';

export const api = {
    auth: {
        login: (params) =>
            client({
                url: '/login',
                data: params,
                method: METHODS.POST
            }),
        userInfo: (params) =>
            client({
                url: '/get_user_by_id',
                method: METHODS.GET,
                ...params
            }),
        resetPassword: (params) =>
            client({
                url: `/request_password_reset`,
                ...params,
                method: METHODS.GET
            }),
        newPassword: (params) =>
            client({
                url: `/reset_password`,
                data: params,
                method: METHODS.POST
            }),
        clientAccess: (params) =>
            client({
                url: `/get_client_access`,
                ...params,
                method: METHODS.GET
            }),
        getMyClient: () =>
            client({
                url: `/get_my_clients`,
                method: METHODS.GET
            })
    },
    location: {
        getAll: (params) =>
            client({
                url: '/get_all_locations',
                ...params,
                method: METHODS.GET
            }),
        get: (params) =>
            client({
                url: '/get_location_by_client_id',
                ...params,
                method: METHODS.GET
            }),
        verifyCode: (params) =>
            client({
                url: '/verify_code',
                ...params,
                method: METHODS.POST
            }),
        delete: (id) =>
            client({
                url: `/delete_location/${id}`,
                method: METHODS.PUT
            }),

        update: (params) =>
            client({
                url: `/update_location`,
                data: params,
                method: METHODS.PUT
            }),
        setMirror: (params) =>
            client({
                url: `/set_mirror`,
                data: params,
                method: METHODS.PUT
            }),
        removeMirror: (params) =>
            client({
                url: `/remove_mirror`,
                data: params,
                method: METHODS.DELETE
            }),
        rebootDevice: (params) =>
            client({
                url: `/reboot_system`,
                ...params,
                method: METHODS.GET
            })
    },
    client: {
        get: (params) =>
            client({
                url: '/get_all_clients',
                ...params,
                method: METHODS.GET
            }),
        add: (params) =>
            client({
                url: '/add_account',
                data: params,
                method: METHODS.POST
            }),

        update: (params) =>
            client({
                url: '/update_client',
                data: params,
                method: METHODS.PUT
            }),
        updateBackground: (params) =>
            client({
                url: '/update_client_background',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_client/${id}`,
                method: METHODS.DELETE
            }),

        updateAccess: (params) =>
            client({
                url: '/update_client_access',
                ...params,
                method: METHODS.PUT
            }),
        getById: (params) =>
            client({
                url: '/get_client_by_id',
                ...params,
                method: METHODS.GET
            }),
        updateClientInterations: (params) =>
            client({
                url: '/update_client_integrations',
                ...params,
                method: METHODS.PUT
            })
    },
    master: {
        updateAccess: (params) =>
            client({
                url: '/update_master_access',
                ...params,
                method: METHODS.PUT
            })
    },
    user: {
        get: (params) =>
            client({
                url: '/get_all_users',
                ...params,
                method: METHODS.GET
            }),
        add: (params) =>
            client({
                url: '/add_user',
                data: params,
                method: METHODS.POST
            }),
        update: (params) =>
            client({
                url: '/edit_user',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_user/${id}`,
                method: METHODS.DELETE
            })
    },
    layout: {
        get: (params) =>
            client({
                url: '/get_layout_admin',
                ...params,
                method: METHODS.GET
            }),
        add: (params) =>
            client({
                url: '/add_layout_admin',
                data: params,
                method: METHODS.POST
            }),
        addClientLayoutInfo: (params) =>
            client({
                url: '/add_client_layout',
                data: params,
                method: METHODS.POST
            }),
        edit: (params) =>
            client({
                url: '/update_layout_admin',
                data: params,
                method: METHODS.PUT
            }),
        editClientLayout: (params) =>
            client({
                url: '/update_client_layout',
                data: params,
                method: METHODS.PUT
            }),
        deleteAdminLayout: (id) =>
            client({
                url: `/delete_admin_layout/${id}`,
                method: METHODS.DELETE
            }),
        deletClientLayout: (id) =>
            client({
                url: `/delete_client_layout/${id}`,
                method: METHODS.DELETE
            })
    },
    category: {
        getAllCategory: (params) =>
            client({
                url: '/get_all_category',
                method: METHODS.GET,
                ...params
            }),
        addCategory: (params) =>
            client({
                url: '/add_category',
                data: params,
                method: METHODS.POST
            }),
        getCategoryById: (id) => client({ url: `/get_category/${id}`, method: METHODS.GET }),
        updateCategoryById: (params) => client({ url: `/update_category`, data: params, method: METHODS.PUT }),
        delete: (id) =>
            client({
                url: `/delete_category/${id}`,
                method: METHODS.DELETE
            })
    },
    collection: {
        get: () =>
            client({
                url: '/get_all_collection',
                method: METHODS.GET
            }),
        add: (params) =>
            client({
                url: '/add_collection',
                data: params,
                method: METHODS.POST
            }),
        edit: (params) =>
            client({
                url: '/edit_collection',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_collection/${id}`,
                method: METHODS.DELETE
            })
    },

    background: {
        get: (params) =>
            client({
                url: '/get_theme',
                ...params,
                method: METHODS.GET
            }),
        add: (params) =>
            client({
                url: '/add_admin_theme',
                data: params,
                method: METHODS.POST
            }),
        edit: (params) =>
            client({
                url: '/add_more_theme_image',
                data: params,
                method: METHODS.POST
            }),
        delete: (id) =>
            client({
                url: `/delete_theme/${id}`,
                method: METHODS.DELETE
            }),
        imageDelete: (id) =>
            client({
                url: `/delete_image/${id}`,
                method: METHODS.DELETE
            })
    },
    video: {
        get: () =>
            client({
                url: '/get_all_videos',
                method: METHODS.GET
            }),
        add: (params) =>
            client({
                url: '/add_video',
                data: params,
                method: METHODS.POST
            }),
        edit: (params) =>
            client({
                url: '/edit_video',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_video/${id}`,
                method: METHODS.DELETE
            }),
        checkVideoExist: (params) =>
            client({
                url: '/check_url_exist',
                ...params,
                method: METHODS.GET
            })
    },
    group: {
        get: () =>
            client({
                url: '/get_group',
                method: METHODS.GET
            }),
        add: (params) =>
            client({
                url: '/add_group',
                data: params,
                method: METHODS.POST
            }),
        edit: (params) =>
            client({
                url: '/edit_group',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_group/${id}`,
                method: METHODS.DELETE
            })
    },
    tag: {
        get: (params) =>
            client({
                url: '/get_tags',
                method: METHODS.GET,
                ...params
            })
    },

    library: {
        get: (params) =>
            client({
                url: '/get_library',
                method: METHODS.GET,
                ...params
            }),
        addVideo: (params) =>
            client({
                url: '/add_library',
                data: params,
                method: METHODS.POST
            }),
        removeVideo: (params) =>
            client({
                url: '/remove_from_library',
                data: params,
                method: METHODS.DELETE
            }),
        updateCurate: (params) =>
            clientWithHeaders({
                url: '/update_curate',
                data: params,
                method: METHODS.PUT
            })
    },
    mediaContent: {
        add: (params) =>
            client({
                url: '/add_media_content',
                data: params,
                method: METHODS.POST
            }),
        get: (params) =>
            client({
                url: '/get_all_media',
                method: METHODS.GET,
                ...params
            }),
        edit: (params) =>
            client({
                url: '/edit_media_content',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_media/${id}`,
                method: METHODS.DELETE
            }),
        getMediaServer: (params) =>
            client({
                url: '/get_media_server',
                method: METHODS.GET,
                ...params
            }),
        sendConfirmationMail: (params) =>
            client({
                url: '/send_mail_to_users',
                method: METHODS.GET,
                ...params
            }),
        addIntegrationsApp: (params) =>
            client({
                url: '/add_social_app',
                data: params,
                method: METHODS.POST
            }),
        deleteIntegrationsApp: (id) =>
            client({
                url: `/delete_social_app/${id}`,
                method: METHODS.DELETE
            }),
        updateOrderIntergrationApps: (params) =>
            client({
                url: `/update_all_media`,
                data: params,
                method: METHODS.PUT
            })
    },
    content: {
        get: (params) =>
            client({
                url: '/get_content',
                method: METHODS.GET,
                ...params
            }),
        add: (params) =>
            client({
                url: '/add_content',
                data: params,
                method: METHODS.POST
            }),
        edit: (params) =>
            client({
                url: '/edit_content',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_content/${id}`,
                method: METHODS.DELETE
            })
    },
    social: {
        googleLogin: (params) =>
            client({
                url: '/data_from_code',
                ...params,
                method: METHODS.GET
            }),
        facebookLogin: (params) =>
            client({
                url: '/save_facebook_info',
                data: params,
                method: METHODS.POST
            }),
        instagramLogin: (params) =>
            client({
                url: '/get_instagram_token',
                ...params,
                method: METHODS.GET
            }),
        getGoogleInfo: (params) =>
            client({
                url: '/get_google_info',
                ...params,
                method: METHODS.GET
            }),
        updateGoogleInfo: (params) =>
            client({
                url: '/update_google_info',
                data: params,
                method: METHODS.PUT
            }),
        updateFacebookInfo: (params) =>
            client({
                url: '/update_facebook_info',
                data: params,
                method: METHODS.PUT
            }),
        deleteGoogleInfo: () =>
            client({
                url: '/delete_google_info',
                method: METHODS.DELETE
            }),

        deleteFacebookInfo: () =>
            client({
                url: '/delete_facebook_info',
                method: METHODS.DELETE
            }),

        getFacebookInfo: (params) =>
            client({
                url: '/get_facebook_info',
                ...params,
                method: METHODS.GET
            }),
        getAllSocialDetails: (params) =>
            client({
                url: '/get_all_social_details',
                ...params,
                method: METHODS.GET
            }),
        getSocialDetails: (params) =>
            client({
                url: '/get_social_data_by_id',
                ...params,
                method: METHODS.GET
            }),
        updateSocialDetails: (params) =>
            client({
                url: '/update_social_data',
                data: params,
                method: METHODS.PUT
            }),
        cancelAddOnApp: (params) =>
            client({
                url: '/cancel_integration',
                data: params,
                method: METHODS.POST
            }),
        editSocialDetails: (params) =>
            client({
                url: `/edit_social_details`,
                method: METHODS.PUT,
                data: params
            }),
        deleteSocialAccount: (id) =>
            client({
                url: `/delete_social_account/${id}`,
                method: METHODS.DELETE
            }),
        ActiveIntegratedApp: (params) =>
            client({
                url: '/activate_integration',
                data: params,
                method: METHODS.POST
            })
    },
    upload: {
        addVideos: (params) =>
            client({
                url: '/upload_to_dropbox',
                data: params,
                method: METHODS.POST
            })
    },
    review: {
        googleReview: (params) =>
            client({
                url: `/get_client_google_reviews`,
                method: METHODS.GET,
                ...params
            }),
        facebookFeed: (params) =>
            client({
                url: `/get_facebook_post`,
                method: METHODS.GET,
                ...params
            }),
        instagramFeed: (params) =>
            client({
                url: `/get_instagram_post`,
                method: METHODS.GET,
                ...params
            })
    },
    loop: {
        getTemplate: (params) =>
            client({
                url: `/get_all_loop_template`,
                method: METHODS.GET,
                ...params
            }),
        getTemplateById: (params) =>
            client({
                url: `/get_loop_template_by_id`,
                method: METHODS.GET,
                ...params
            }),
        addTemplate: (params) =>
            client({
                url: `/add_loop_template`,
                method: METHODS.POST,
                data: params
            }),
        editTemplate: (params) =>
            client({
                url: `/edit_loop_template`,
                method: METHODS.PUT,
                data: params
            }),
        deleteTemplate: (id) =>
            client({
                url: `/delete_loop_template/${id}`,
                method: METHODS.DELETE
            }),
        addLoopSchedule: (params) =>
            client({
                url: `/add_loop`,
                method: METHODS.POST,
                data: params
            }),
        editLoopSchedule: (params) =>
            client({
                url: `/edit_loop`,
                method: METHODS.PUT,
                data: params
            }),
        getLoopById: (params) =>
            client({
                url: `/get_loop_by_id`,
                method: METHODS.GET,
                ...params
            }),
        getLoopByLocation: (params) =>
            client({
                url: `/get_all_loops`,
                method: METHODS.GET,
                ...params
            }),
        deleteLoop: (id) =>
            client({
                url: `/delete_loop/${id}`,
                method: METHODS.DELETE
            }),
        clearSchedule: (id) =>
            client({
                url: `/clear_schedule/${id}`,
                method: METHODS.DELETE
            }),
        checkScheduleExits: (params) =>
            client({
                url: `/schedule_exist`,
                method: METHODS.POST,
                data: params
            })
    },
    request: {
        addCategory: (params) =>
            client({
                url: '/add_request_category',
                data: params,
                method: METHODS.POST
            }),
        getAllCategory: (params) =>
            client({
                url: '/get_all_request_category',
                method: METHODS.GET,
                ...params
            }),
        editCategory: (params) =>
            client({
                url: '/edit_request_category',
                data: params,
                method: METHODS.PUT
            }),
        deleteCategroy: (id) =>
            client({
                url: `/delete_request_category/${id}`,
                method: METHODS.DELETE
            }),
        addForm: (params) =>
            client({
                url: '/add_form',
                data: params,
                method: METHODS.POST
            }),
        getAllForms: (params) =>
            client({
                url: '/get_all_forms',
                method: METHODS.GET,
                ...params
            }),
        editForm: (params) =>
            client({
                url: '/edit_form',
                data: params,
                method: METHODS.PUT
            }),
        deleteForm: (id) =>
            client({
                url: `/delete_form/${id}`,
                method: METHODS.DELETE
            })
    },
    feedback: {
        getAllForms: (params) =>
            client({
                url: '/get_all_feedback',
                method: METHODS.GET,
                ...params
            }),
        editForm: (params) =>
            client({
                url: '/edit_feedback',
                data: params,
                method: METHODS.PUT
            }),
        addForm: (params) =>
            client({
                url: '/add_feedback',
                data: params,
                method: METHODS.POST
            }),
        deleteForm: (id) =>
            client({
                url: `/delete_feedback/${id}`,
                method: METHODS.DELETE
            })
    },
    support: {
        sendSupportMessage: (params) =>
            client({
                url: '/send_support_message',
                data: params,
                method: METHODS.POST
            })
    },
    scrollingMessage: {
        add: (params) =>
            client({
                url: '/add_message',
                data: params,
                method: METHODS.POST
            }),
        edit: (params) =>
            client({
                url: '/edit_message_order',
                data: params,
                method: METHODS.PUT
            }),
        delete: (id) =>
            client({
                url: `/delete_message/${id}`,
                method: METHODS.DELETE
            })
    },
    version: {
        getAllVersion: (params) =>
            client({
                url: '/version',
                method: METHODS.GET,
                ...params
            }),
        getVersion: (params) =>
            client({
                url: `/version/${params?.id}`,
                ...params,
                method: METHODS.GET
            }),
        deleteVersion: (id) =>
            client({
                url: `/version/${id}`,
                method: METHODS.DELETE
            }),
        addNewVersion: (params) =>
            client({
                url: '/version',
                data: params,
                method: METHODS.POST
            })
    },
    accountTypes: {
        getAllAccountTypeTerritory: () =>
            client({
                url: '/territory',
                method: METHODS.GET
            }),
        getAllAccountTypeSpecialty: () =>
            client({
                url: '/specialities',
                method: METHODS.GET
            }),
        addNewAccountTypeTerritory: (params) =>
            client({
                url: '/territory',
                data: params,
                method: METHODS.POST
            }),
        addNewAccountTypeSpecialty: (params) =>
            client({
                url: '/specialities',
                data: params,
                method: METHODS.POST
            }),
        deleteAccountTypeTerritory: (id) =>
            client({
                url: `/territory/${id}`,
                method: METHODS.DELETE
            }),
        deleteAccountTypeSpecialty: (id) =>
            client({
                url: `/specialities/${id}`,
                method: METHODS.DELETE
            })
    },
    curationProfiles: {
        getAllCurationProfiles: () =>
            client({
                url: '/curation_profile',
                method: METHODS.GET
            }),
        addCurationProfile: (params) =>
            client({
                url: '/curation_profile',
                data: params,
                method: METHODS.POST
            }),
        updateCurationProfileById: ({ params, id }) =>
            client({
                url: `/curation_profile/${id}`,
                data: params,
                method: METHODS.PUT
            }),
        deleteCurationProfile: (id) =>
            client({
                url: `/curation_profile/${id}`,
                method: METHODS.DELETE
            })
    }
};
