import { Button, Grid, styled, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VideoContentPopUp from './components/VideoContentPopUp';
import useFetch from '../../../hooks/useFetch';
import { CONTENT_DATA_TYPE, ORIENTATION, categories_orders, durations, specialty, topics } from '../../../utils/constants';
import { CollectionCardWidgets } from '../../../widgets/CollectionCardWidgets';
import BrowserHeader from './components/BrowserHeader';
import NoDataFound from '../../../components/NoDataFound';
import { fetchAddVideoToLibrary, fetchLibrary, fetchRemoveVideoToLibrary } from '../../../redux/reducers/librarySlice';
import { changeCase, checkContentStatusInLibrary, getOriantationByLayout } from '../../../utils/helpers';
import CreatorContentPopUp from '../Librarys/components/CreatorContentPopUp';
import { fetchLocations } from '../../../redux/reducers/clientLocationSlice';
import CustomFilters from '../../../widgets/CustomFilters';
import { fetchCategory } from '../../../redux/reducers/categorySlice';
import { clearFilterData, handleFilter } from '../../../redux/reducers/browseFilterSlice';
import { COLORS } from '../../../utils/theme';
import UpdateLocationLoop from '../../../components/UpdateLocationLoopButton';

const CollectionVideoGrid = styled(Grid)(({ matchUplg, matchUpMd }) => ({
    paddingTop: matchUplg ? 140 : matchUpMd ? 204 : window.innerWidth >= 507 ? 256 : 298
}));
const CenteredNoDataFound = styled(Box)({
    display: 'flex',
    marginTop: '1.25rem',
    justifyContent: 'center'
});

const FilterContainer = styled(Box)({
    margin: '12px 0px',
    display: 'flex',
    flexWrap: 'wrap'
});

const ClearButton = styled(Button)({
    background: COLORS.white,
    display: 'flex',
    textDecoration: 'underline',
    alignItems: 'center',
    // border: `1px solid ${COLORS.border}`,
    borderRadius: '0.425rem',
    fontSize: `14px !important`,
    color: `${COLORS.primary.light}!important`,
    fontWeight: 100,
    '&.MuiButtonBase-root:hover': {
        '&:hover': {
            textDecoration: 'underline',
            background: COLORS.white,
            opacity: 1
        }
    }
});

const BrowseHeaderComponent = styled(Box)(({ matchUpMd }) => ({
    position: 'fixed',
    paddingTop: matchUpMd ? 8 : 24,
    left: matchUpMd ? 268 : 0,
    width: `calc(100% - ${matchUpMd ? '284px' : '0px'})`,
    background: COLORS.background,
    paddingLeft: '6px',
    paddingRight: '6px',
    zIndex: 999
}));

const initialState = {
    category: [],
    topic: [],
    duration: [],
    specialty: []
};

export const Browse = () => {
    const [showVideoModel, setShowVideoModel] = useState(false);
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUplg = useMediaQuery(theme.breakpoints.up('lg'));
    const browseFilter = useSelector((state) => state.browseFilter);
    const [counts, setCounts] = useState(initialState);
    const [selectedContent, setSelectedContent] = useState();
    const [activeOrientation, setActiveOrientation] = useState(ORIENTATION.LANDSCAPE);
    const { locations } = useSelector((state) => state.clientLocation);
    const [search, setSearch] = useState('');
    const { librarys } = useSelector((state) => state.library);
    const { categorys, reorders } = useSelector((state) => state.category);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const {
        data: videoData,
        callFetch: fetchVideosByCategory,
        isLoading: mediaLoading
    } = useFetch({
        initialUrl: `/get_all_media`,
        skipOnStart: true
    });

    const handleData = (value, data = []) => {
        const array = [...data];
        if (array.includes(value)) {
            const index = array.findIndex((val) => val === value);
            array.splice(index, 1);
        } else array.push(value);
        return array;
    };

    const handleChangeAction = (item, mainId) => {
        const { id } = item;
        setCounts({
            ...counts,
            [mainId]: handleData(id, counts[mainId])
        });
    };

    const orientationVisiblity = useMemo(() => {
        if (locations.length !== 0) {
            let isLandscapeOnly = false;
            let isPortraitOnly = false;
            isLandscapeOnly =
                locations.filter((item) => getOriantationByLayout(item?.layout) === ORIENTATION.LANDSCAPE).length === locations.length;
            isPortraitOnly =
                locations.filter((item) => getOriantationByLayout(item?.layout) === ORIENTATION.PORTRAIT).length === locations.length;
            return {
                isLandscapeOnly,
                isPortraitOnly
            };
        }
        return {
            isLandscapeOnly: false,
            isPortraitOnly: false
        };
    }, [locations]);

    useEffect(() => {
        if (orientationVisiblity.isPortraitOnly) {
            setActiveOrientation(ORIENTATION.PORTRAIT);
        } else {
            setActiveOrientation(ORIENTATION.LANDSCAPE);
        }
    }, [orientationVisiblity]);

    useEffect(() => {
        let params = {
            orientation: activeOrientation
        };
        dispatch(fetchLibrary({ params }));
    }, [dispatch, activeOrientation, user?.client]);

    useEffect(() => {
        let params = {
            group: 1,
            video: 1,
            collection: 1,
            status: 1,
            orientation: activeOrientation,
            search: search,
            custom_content: 1,
            distinct_data: 1,
            no_custom_image: 1
        };

        if (Object.keys(browseFilter)?.length) {
            const filterParams = { ...browseFilter?.[activeOrientation] };
            if (filterParams['category'] && filterParams['category']?.length) {
                params['multiple_category'] = JSON.stringify(filterParams['category'] || []);
            }
            if (filterParams['duration'] && filterParams['duration']?.length) {
                params['multiple_duration'] = JSON.stringify(filterParams['duration'] || []);
            }

            if (filterParams?.['specialty']?.length || filterParams?.['topic']?.length) {
                params['multiple_tag'] = JSON.stringify([...filterParams?.['specialty'], ...filterParams?.['topic']]);
            }
        }

        if (reorders?.length) {
            params['content_order'] = JSON.stringify(reorders);
        }

        fetchVideosByCategory({ params });
    }, [fetchVideosByCategory, activeOrientation, search, user?.client, browseFilter, reorders]);

    useEffect(() => {
        dispatch(fetchLocations()).then(() => {});
        dispatch(fetchCategory({ params: { status: 1 } }));
    }, [dispatch, user.client]);

    useEffect(() => {
        setCounts({
            ...initialState,
            ...browseFilter[activeOrientation]
        });
    }, [activeOrientation, browseFilter]);

    const handleClickContent = useCallback((data) => {
        setSelectedContent(data);
        setShowVideoModel(true);
    }, []);

    const onChangeOrientation = useCallback(
        (value) => {
            setCounts({
                ...initialState,
                ...browseFilter[value]
            });
            if (!orientationVisiblity.isLandscapeOnly && !orientationVisiblity.isPortraitOnly) {
                setActiveOrientation(value);
            }
        },
        [browseFilter, orientationVisiblity.isLandscapeOnly, orientationVisiblity.isPortraitOnly]
    );

    const onChangeSearch = (e) => setSearch(e.target.value);

    const addSingleToLibrary = (item) => {
        dispatch(fetchAddVideoToLibrary([item]));
    };
    const removeSingleToLibrary = (item) => {
        dispatch(fetchRemoveVideoToLibrary([item]));
    };

    const categories = useMemo(() => {
        return categorys?.map(({ id, name }) => ({ id, label: name }));
    }, [categorys]);

    const handleApply = useCallback(
        (id) => {
            dispatch(
                handleFilter({
                    ...browseFilter,
                    [activeOrientation]: {
                        ...initialState,
                        ...browseFilter?.[activeOrientation],
                        [id]: counts[id] || []
                    }
                })
            );
        },
        [activeOrientation, browseFilter, counts, dispatch]
    );

    const clearFilter = useCallback(
        (orientation) => {
            dispatch(
                clearFilterData({
                    orientation,
                    data: initialState
                })
            );
        },
        [dispatch]
    );

    const isApplyFilters = useMemo(() => {
        const data = { ...browseFilter?.[activeOrientation] };
        return Boolean(
            Object.values(data)
                ?.map((val) => val?.length)
                ?.reduce((value, prev) => value + prev, 0)
        );
    }, [browseFilter, activeOrientation]);

    const updateCategories = useMemo(() => {
        const clone = [...categories];
        return clone?.filter((item) => changeCase(item?.label) !== 'CREATOR');
    }, [categories]);

    return (
        <Box sx={{ position: 'relative' }}>
            <BrowseHeaderComponent matchUpMd={matchUpMd}>
                {!matchUplg ? (
                    <Box sx={{ width: '100%', display: 'flex', marginBottom: '8px', alignItems: 'center', justifyContent: 'center' }}>
                        <UpdateLocationLoop />
                    </Box>
                ) : null}
                <BrowserHeader
                    orientation={activeOrientation}
                    orientationVisiblity={orientationVisiblity}
                    onChangeSearch={onChangeSearch}
                    onChangeOrientation={onChangeOrientation}
                    title={'Browse'}
                />
                <FilterContainer>
                    <CustomFilters
                        {...{ handleChangeAction, handleApply, checked: counts['category'], options: updateCategories }}
                        id={'category'}
                        count={browseFilter[activeOrientation]?.['category']?.length}
                        label={'Category'}
                    />
                    {/* <CustomFilters
                        {...{ handleChangeAction, handleApply, checked: counts['specialty'], options: specialty }}
                        id={'specialty'}
                        count={browseFilter[activeOrientation]?.['specialty']?.length}
                        sx={{ ml: 1 }}
                        label={'Specialty'}
                    /> */}
                    <CustomFilters
                        {...{
                            handleChangeAction,
                            handleApply,
                            checked: counts['topic'],
                            options: topics?.filter((item) => item?.id !== 'oral hygiene')
                        }}
                        id={'topic'}
                        count={browseFilter[activeOrientation]?.['topic']?.length}
                        sx={{ ml: 1 }}
                        label={'Topic'}
                    />
                    <CustomFilters
                        {...{ handleChangeAction, handleApply, checked: counts['duration'], options: durations }}
                        id={'duration'}
                        sx={{ ml: 1 }}
                        count={browseFilter[activeOrientation]?.['duration']?.length}
                        label={'Duration'}
                    />
                    {isApplyFilters ? (
                        <ClearButton onClick={() => clearFilter(activeOrientation)} sx={{ ml: 1 }}>
                            Clear Filters
                        </ClearButton>
                    ) : null}
                </FilterContainer>
            </BrowseHeaderComponent>
            <CollectionVideoGrid {...{ matchUplg, matchUpMd }} container spacing={3}>
                {mediaLoading ? (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <CollectionCardWidgets
                            orientation={activeOrientation}
                            hideToggleSwitch={true}
                            hasStatus={true}
                            loading={mediaLoading}
                        />
                    </Grid>
                ) : videoData ? (
                    videoData?.result?.map?.((item, index) => {
                        return (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={3} xl={2}>
                                <CollectionCardWidgets
                                    orientation={activeOrientation}
                                    hideToggleSwitch={true}
                                    hasStatus={true}
                                    onClick={handleClickContent}
                                    value={checkContentStatusInLibrary(item, librarys)}
                                    data={item}
                                />
                            </Grid>
                        );
                    })
                ) : null}
            </CollectionVideoGrid>
            {!mediaLoading && videoData?.result?.length === 0 && (
                <CenteredNoDataFound>
                    <NoDataFound searchText={search} message={videoData?.message} />
                </CenteredNoDataFound>
            )}

            {showVideoModel && selectedContent.content_type !== CONTENT_DATA_TYPE.IMAGE && (
                <VideoContentPopUp
                    isHide
                    orientation={activeOrientation}
                    data={selectedContent}
                    open={showVideoModel}
                    handleClose={() => setShowVideoModel(false)}
                    addSingleToLibrary={() => addSingleToLibrary(selectedContent)}
                    removeSingleToLibrary={() => removeSingleToLibrary(selectedContent)}
                />
            )}
            {showVideoModel && selectedContent.content_type === CONTENT_DATA_TYPE.IMAGE && (
                <CreatorContentPopUp
                    isHide
                    open={showVideoModel}
                    handleClose={() => setShowVideoModel(false)}
                    data={selectedContent}
                    orientation={activeOrientation}
                    addSingleToLibrary={() => addSingleToLibrary(selectedContent)}
                    removeSingleToLibrary={() => removeSingleToLibrary(selectedContent)}
                />
            )}
        </Box>
    );
};
