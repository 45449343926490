import { Grid, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ICONS } from '../../../assets';
import { CommonTable } from '../../../components/Table';
import { fetchCollection } from '../../../redux/reducers/collectionSlice';
import { COLORS } from '../../../utils/theme';
import { PAGES } from '../../../utils/urls';
import { CommonHeader } from '../../../widgets/CommonHeader';

const CollectionName = styled(Typography)({
    fontSize: 16,
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});

export const Collections = () => {
    const [searchValue, setSearchValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchCollection()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    const { collections } = useSelector((state) => state.collection);
    const collectionColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: ({ rowData }) => {
                return <CollectionName>{rowData.name}</CollectionName>;
            }
        },
        {
            id: 'content_count',
            fieldName: 'content_count',
            label: 'Videos',
            style: {
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        },

        {
            id: 'status',
            fieldName: 'status',
            label: 'Status',
            render: ({ rowData }) => {
                return (
                    <>
                        {rowData.status ? (
                            <ICONS.CheckCircleIcon variant="contained" style={{ color: 'green' }} />
                        ) : (
                            <ICONS.CancelIcon style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
            style: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];
    return (
        <Grid container direction="column" justifyContent="flex-end">
            <CommonHeader
                title={'Collections'}
                searchbar={true}
                buttonLabel={'Add Collection'}
                onClick={() => navigate(PAGES.ADD_COLLECTIONS.url)}
                searchValue={searchValue}
                handleSearch={(search) => setSearchValue(search)}
            />
            <Grid container sx={{ marginTop: '24px' }}>
                <CommonTable
                    search={searchValue}
                    handleRowDetail={(data) => navigate(`/collection/edit/${data.id}`)}
                    columns={collectionColumns}
                    rows={collections}
                    searchableFields={['name']}
                    loading={isLoading}
                />
            </Grid>
        </Grid>
    );
};
