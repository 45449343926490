import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';

export const fetchGetAllCurationProfiles = createAsyncThunk('curationProfiles/fetchGetAllCurationProfiles', async () => {
    const response = await api.curationProfiles.getAllCurationProfiles();
    return response.data.result;
});

const initialState = {
    curationProfiles: [],
    isPending: false
};

export const curationProfilesSlice = createSlice({
    name: 'curationProfiles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchGetAllCurationProfiles.fulfilled, (state, action) => {
            state.curationProfiles = action.payload;
            state.isPending = false;
        });
        builder.addCase(fetchGetAllCurationProfiles.pending, (state) => {
            state.isPending = true;
        });
        builder.addCase(fetchGetAllCurationProfiles.rejected, (state) => {
            state.curationProfiles = [];
            state.isPending = false;
        });
    }
});

export default curationProfilesSlice.reducer;
