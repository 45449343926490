import { Box, Grid, IconButton, styled, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { ICONS } from '../../../assets';
import { CommonTable } from '../../../components/Table';
import { fetchAllSocialDetails } from '../../../redux/reducers/socialMediaSlice';
import { COLORS } from '../../../utils/theme';
import { PAGES } from '../../../utils/urls';
import { CommonHeader } from '../../../widgets/CommonHeader';
import { api } from '../../../api';

const AppName = styled(Typography)({
    fontSize: 16,
    fontWeight: '500',
    color: COLORS.primary.light,
    cursor: 'pointer',
    textAlign: 'left'
});

const FlexBox = styled(Box)({
    display: 'flex',
    alignItems: 'center'
});

export const Apps = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [rows, setRows] = useState([]);

    const { socialDetails } = useSelector((state) => state.socialMedia);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchAllSocialDetails()).then(() => {
            setIsLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        if (socialDetails) {
            setRows(socialDetails);
        }
    }, [socialDetails]);

    const integrationAppColumns = [
        {
            id: 'name',
            fieldName: 'name',
            label: 'Name',
            render: ({ rowData }) => {
                return (
                    <FlexBox>
                        <IconButton>
                            <ICONS.DragIndicatorIcon />
                        </IconButton>
                        <AppName>{rowData.name}</AppName>
                    </FlexBox>
                );
            }
        },
        {
            id: 'status',
            fieldName: 'status',
            label: 'Status',

            render: ({ rowData }) => {
                return (
                    <>
                        {rowData.status ? (
                            <ICONS.CheckCircleIcon variant="contained" style={{ color: 'green' }} />
                        ) : (
                            <ICONS.CancelIcon style={{ color: 'red' }} />
                        )}
                    </>
                );
            },
            style: {
                display: 'flex',
                justifyContent: 'flex-end'
            },
            align: 'center',
            cellStyle: {
                width: 140
            }
        }
    ];

    const saveRearrangedRowsData = async (data = []) => {
        setIsLoading(true);
        const order = data
            .map((item) => {
                return { id: item.id, order: item.order };
            })
            .sort((a, b) => a.order - b.order);
        try {
            const { data } = await api.mediaContent.updateOrderIntergrationApps({ order });
            if (data.success) {
                dispatch(fetchAllSocialDetails());
                enqueueSnackbar(data.message, { variant: 'success' });
            } else {
                enqueueSnackbar(data.message, { variant: 'error' });
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            enqueueSnackbar(error.message, { variant: 'error' });
        }
    };

    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('text/plain', id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    };

    const hanldeOnDrop = (e, id) => {
        e.preventDefault();
        const draggedId = e.dataTransfer.getData('text/plain');
        const targetId = id;

        // Find the dragged row and target row
        const draggedRow = rows.find((row, id) => row.id === Number(draggedId));
        const targetRow = rows.find((row, id) => row.id === Number(targetId));

        // Find the indices of the dragged row and target row
        const draggedRowIndex = rows.indexOf(draggedRow);
        const targetRowIndex = rows.indexOf(targetRow);

        // Create a new array with the updated order of rows
        const updatedRows = [...rows];
        const [removed] = updatedRows.splice(draggedRowIndex, 1);
        updatedRows.splice(targetRowIndex, 0, removed);
        // Update the state with the new order of rows
        const update = updatedRows.map((item, index) => {
            return { ...item, order: index + 1 };
        });
        setRows(update);
        saveRearrangedRowsData(update);
    };

    return (
        <Grid container direction="column" justifyContent="flex-end">
            <CommonHeader
                title={'Integrations'}
                searchbar={true}
                buttonLabel={'Add App'}
                onClick={() => navigate(PAGES.ADD_APPS.url)}
                searchValue={searchValue}
                handleSearch={(search) => setSearchValue(search)}
            />
            <Grid sx={{ marginTop: '24px' }} container>
                <CommonTable
                    isNotDefaultSorted
                    search={searchValue}
                    handleRowDetail={(data) =>
                        navigate(`${PAGES.EDIT_APPS.url}/${data.id}`, {
                            state: { app_name: data.name }
                        })
                    }
                    columns={integrationAppColumns}
                    rows={socialDetails}
                    loading={isLoading}
                    searchableFields={['name']}
                    rowsPadding={1}
                    draggable={true}
                    onDragStart={(e, id) => {
                        handleDragStart(e, id);
                    }}
                    onDrop={(e, id) => {
                        hanldeOnDrop(e, id);
                    }}
                    onDragOver={(e) => {
                        handleDragOver(e);
                    }}
                />
            </Grid>
        </Grid>
    );
};
