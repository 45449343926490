import React, { useState } from 'react';
import { Card, Typography, Box, Checkbox, Link, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../../utils/theme';
import { CommonInput } from '../../../components/CommonInput';
import CommonButton from '../../../components/CommonButton';
import { PAGES } from '../../../utils/urls';
import { PublicLayout } from '../../../layout/PublicLayout';
import { useFormik } from 'formik';
import { loginValidation } from '../../../utils/validations';
import { api } from '../../../api';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/reducers/authSlice';
import { USER_ROLE } from '../../../utils/constants';
import { setHeader, setHeaderToken } from '../../../api/client';
import FieldLabelView from '../../../components/FieldLabelView';
import { ICONS } from '../../../assets';

const MainCard = styled(Card)({
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '40px',
    backgroundColor: 'rgb(255,255,255)',
    borderRadius: 20,
    borderWidth: 5,
    borderColor: 'red'
});

const VersionText = styled(Typography)(({ theme }) => ({
    color: COLORS.black,
    fontSize: '22px',
    fontWeight: 100
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
}));

const HeaderText = styled(Typography)(({ theme }) => ({
    color: COLORS.darkText,
    fontSize: '34px',
    fontWeight: '300',
    fontFamily: 'Comfortaa !important'
}));
const HorizontalSpacing = styled(Box)(({ theme }) => ({
    width: '100%'
}));
const ActionText = styled(Typography)(({ theme }) => ({
    color: 'gray',
    fontSize: 12,
    paddingLeft: 3
}));
const MainView = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: '10px'
}));
const flexRow = { display: 'flex', flexDirection: 'row' };
const StyledInput = styled(CommonInput)(({ theme }) => ({
    color: COLORS.vividYellow,
    fontSize: 18,
    marginBottom: 15
}));

const CheckboxContainer = styled(Link)({
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
});
const InputBox = styled(Box)({
    width: '100%'
});

const StyledButtonLogin = styled(CommonButton)({
    color: COLORS.black,
    fontWeight: 100,
    borderRadius: 0,
    width: '100%'
});

const Login = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const { handleBlur, handleChange, handleSubmit, values, touched, errors, setFieldValue } = useFormik({
        initialValues: {
            email: '',
            password: '',
            stay_sign: false
        },
        validationSchema: loginValidation,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const { data } = await api.auth.login(values);
                dispatch(updateUser(data.result));
                setHeaderToken(data.result.token);
                enqueueSnackbar('Login successful', { variant: 'success' });
                if (!Boolean(data?.result?.is_password_updated)) {
                    navigate(`${PAGES.NEW_PASSWORD.url}?token=${data.result.token}&isNewUser=true`);
                } else if (data.result.role === USER_ROLE.MANAGER || data.result.role === USER_ROLE.VIEWER) {
                    api.auth
                        .getMyClient()
                        .then((response) => {
                            const client = response.data.result?.clients;
                            if (client.length > 1) {
                                navigate(PAGES.ACCOUNT_SELECTION.url);
                            } else {
                                setHeader('client_id', client[0]?.id);
                                dispatch(updateUser({ ...data.result, client: client?.[0], clientList: client }));
                                navigate(PAGES.LOCATIONS.url);
                            }
                        })
                        .catch((err) => {
                            enqueueSnackbar(err.message, { variant: 'error' });
                        });
                } else {
                    navigate(PAGES.ACCOUNT.url);
                }
                setLoading(false);
            } catch (error) {
                setLoading(false);
                enqueueSnackbar(error.message, { variant: 'error' });
            }
        }
    });

    return (
        <PublicLayout>
            <MainCard>
                <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                    <HorizontalSpacing>
                        <MainView>
                            <Box sx={flexRow}>
                                <HeaderText>Loop</HeaderText>
                                <HeaderText sx={{ color: COLORS.primary.light }}>OS</HeaderText>
                            </Box>
                            <VersionText>v1.0</VersionText>
                        </MainView>
                        <FieldLabelView sx={{ mt: 2 }}>
                            <InputBox>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    name={'email'}
                                    value={values?.email}
                                    placeholder="Email"
                                    error={touched.email && errors.email}
                                    type={'email'}
                                    startAdornment={
                                        <ICONS.Email sx={{ color: `${COLORS.primary.light}`, fontSize: '20px', marginRight: '4px' }} />
                                    }
                                />
                            </InputBox>
                        </FieldLabelView>
                        <FieldLabelView>
                            <InputBox>
                                <StyledInput
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    name={'password'}
                                    value={values?.password}
                                    type="password"
                                    placeholder="Password"
                                    sx={{ marginBottom: '5px' }}
                                    error={touched.password && errors.password}
                                    startAdornment={
                                        <ICONS.Lock sx={{ color: `${COLORS.primary.light}`, fontSize: '20px', marginRight: '4px' }} />
                                    }
                                />
                                <ButtonContainer sx={{ marginTop: 2 }}>
                                    <CheckboxContainer sx={flexRow} onClick={() => setFieldValue('stay_sign', !values.stay_sign)}>
                                        <Checkbox
                                            label="Stay signed in"
                                            checked={values.stay_sign}
                                            size="small"
                                            sx={{ padding: 0, color: COLORS.gray }}
                                        />
                                        <ActionText>Stay signed in</ActionText>
                                    </CheckboxContainer>
                                </ButtonContainer>
                            </InputBox>
                        </FieldLabelView>
                        <StyledButtonLogin
                            sx={{ mt: 2 }}
                            key="login-Button"
                            id="login-Button"
                            onClick={handleSubmit}
                            loading={loading}
                            variant="contained"
                            label={'Sign in'}
                            title={'Login'}
                            type={'submit'}
                        />
                    </HorizontalSpacing>
                </form>
            </MainCard>
            <ButtonContainer>
                <CommonButton
                    sx={{ color: COLORS.vividYellow, fontWeight: 'normal' }}
                    key="resetpassword-Button"
                    id="resetpassword-Button"
                    label={'Can’t sign In?'}
                    title={'resetpassword'}
                    onClick={() => navigate(PAGES.RESET_PASSWORD.url)}
                />
            </ButtonContainer>
        </PublicLayout>
    );
};

export default Login;
