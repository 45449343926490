import { Popover } from '@mui/material';
import React from 'react';

const CommonPopover = ({ children, anchorEl, handleClose, anchorOrigin, transformOrigin }) => {
    return (
        <Popover
            id="over-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={anchorOrigin ? anchorOrigin :{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={transformOrigin?transformOrigin:{
                vertical: 'top',
                horizontal: 'left'
            }}
        >
            {children}
        </Popover>
    );
};

export default CommonPopover;
